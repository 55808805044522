import { FormControl, Select, MenuItem, FormHelperText, InputLabel } from '@mui/material';
import { getNested } from '../utils/common';

type FormikSelectProps = {
    formik: any;
    fieldName: string;
    label: string;
    options: string[] | number[];
};

const FormikSelect = (props: FormikSelectProps) => {
    const { formik, fieldName, label, options } = props;
    const labelId: string = `${fieldName}-label`;

    return (
        <FormControl sx={{ width: 300 }}>
            <InputLabel id={labelId}>{label}</InputLabel>
            <Select
                id={fieldName}
                name={fieldName}
                labelId={labelId}
                label={label}
                value={getNested(formik.values, fieldName, '.') || ''}
                onChange={formik.handleChange}
                error={formik.touched[fieldName] && Boolean(formik.errors[fieldName])}
                autoWidth
            >
                {options.map(item => (
                    <MenuItem value={item} key={item}>
                        {item}
                    </MenuItem>
                ))}
            </Select>
            <FormHelperText>{formik.touched[fieldName] && formik.errors[fieldName]}</FormHelperText>
        </FormControl>
    );
};

export default FormikSelect;
