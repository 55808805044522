import Grid3x3Icon from '@mui/icons-material/Grid3x3';
import { Box, Button, Card, CardContent, Grid, MenuItem, Select, Tooltip, Typography } from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';
import { Theme } from '@mui/system';
import { DataGrid, GridCell, GridCellProps, GridColumns, GridRowParams, GridSortModel, GridValueGetterParams } from '@mui/x-data-grid';
import Dinero from 'dinero.js';
import moment from 'moment';
import { useMemo } from 'react';
import { d2f, titleCase } from '../../../../utils/common';

const CustomCell = ({ height, width, ...other }: GridCellProps) => {
    const style = {
        minWidth: width,
        maxWidth: width,
        minHeight: height,
        maxHeight: height,
        lineHeight: `${height}px`,
    };

    return <GridCell {...other} height={height} width={width} style={style} />;
};

const selectAgent = (
    agents: any[],
    value: string,
    id: string,
    salesProofs: any[] | undefined,
    onChange: (
        id: string,
        assignedAgent: string,
        salesProofs: any[] | undefined,
        showWarning: boolean,
        proofId: string | null,
        overrideCommission: boolean,
        commissionPercentOverride: number | null,
    ) => void,
    theme: Theme,
) => (
    <Select
        id="agent"
        value={value}
        sx={{
            width: '100%',
            fontSize: '14px',
            ...(!value || value === '-1' ? { color: theme.palette.error.main } : null),
        }}
        onChange={(e, child) => onChange(id, e.target.value, salesProofs, true, null, false, null)}
    >
        <MenuItem value={'-1'} sx={{ fontSize: '14px' }}>
            (unclaimed)
        </MenuItem>
        {agents &&
            agents.map((agent: any) => (
                <MenuItem value={agent._id} key={agent._id} sx={{ fontSize: '14px' }}>
                    {agent.name}
                </MenuItem>
            ))}
    </Select>
);

const moneyCell = (params: any) => (
    <div style={{ fontFamily: 'monospace' }}>
        {Dinero({ amount: Math.round(d2f(params.value) * 100), currency: params.row.currency }).toFormat()}
    </div>
);

const salesProofCellColor = (salesProof: any[] | undefined, theme: Theme): string => {
    if (!salesProof) {
        return '';
    }

    if (salesProof.length === 0) {
        return '';
    }

    if (salesProof.find((proof: any) => proof.assignedTransaction)) {
        return theme.palette.success.main;
    }

    return theme.palette.warning.main;
};

const salesProofCellContent = (row: any, handleReviewSalesProofOpen: (transaction: any) => void, theme: Theme) => {
    if (!row) {
        return '';
    }

    if (!row.salesProof) {
        return '';
    }

    const { salesProof } = row;
    const length = salesProof.length;

    if (length === 0) {
        return '';
    }

    const hasSalesProofBeenApproved = salesProof.find((proof: any) => proof.assignedTransaction);

    let labelColor: string = hasSalesProofBeenApproved ? theme.palette.grey[600] : theme.palette.warning.main;

    if (!hasSalesProofBeenApproved) {
        const isStrongMatch =
            length === 1 ? moment(row.createdAt).startOf('minute').isSame(moment(salesProof[0].dateTime).startOf('minute')) : false;

        labelColor = isStrongMatch ? theme.palette.success.dark : theme.palette.warning.main;
    }

    return (
        <Grid container flex={1} alignItems="center">
            <Grid item xs sx={{ marginRight: 1, color: labelColor }}>
                <div
                    style={{
                        fontFamily: 'monospace',
                        textAlign: 'right',
                    }}
                >
                    {length}
                </div>
            </Grid>
            <Grid item xs>
                <Button
                    variant="text"
                    sx={{ color: salesProofCellColor(salesProof, theme) }}
                    onClick={() => handleReviewSalesProofOpen(row)}
                >
                    Review
                </Button>
            </Grid>
        </Grid>
    );
};

type SextforceAssignTransactionsDataGridProps = {
    transactions: any;
    limit: number;
    setLimit: React.Dispatch<React.SetStateAction<number>>;
    offset: number;
    setOffset: React.Dispatch<React.SetStateAction<number>>;
    timezone: string;
    setTimezone: React.Dispatch<React.SetStateAction<string>>;
    agents: any[];
    reportSort: any[];
    setReportSort: React.Dispatch<React.SetStateAction<any[]>>;
    transactionsLoading: boolean;
    handleReviewSalesProofOpen: (transaction: any) => void;
    handleUpdateSingleTransaction: (
        _id: string,
        assignedAgent: string,
        salesProofs: any[] | undefined,
        showWarning: boolean,
        proofId: string | null,
        overrideCommission: boolean,
        commissionPercentOverride: number | null,
    ) => void;
    selectionModel: any[];
    setSelectionModel: React.Dispatch<React.SetStateAction<any[]>>;
};

const SextforceAssignTransactionsDataGrid = (props: SextforceAssignTransactionsDataGridProps) => {
    const {
        transactions,
        limit,
        setLimit,
        offset,
        setOffset,
        reportSort,
        setReportSort,
        transactionsLoading,
        timezone,
        agents,
        handleReviewSalesProofOpen,
        handleUpdateSingleTransaction,
        selectionModel,
        setSelectionModel,
    } = props;

    const theme: Theme = useTheme();

    /**
     * DataGrid Columns
     */

    // Define agents DataGrid columns
    const columns = useMemo<GridColumns>(
        () => [
            {
                field: 'createdAt',
                headerName: 'Date & Time',
                width: 190,
                renderCell: params => (
                    <div>
                        <Grid container alignItems="center">
                            <Grid item xs>
                                <Tooltip title={params.row.transactionId}>
                                    <Grid3x3Icon fontSize="small" />
                                </Tooltip>
                            </Grid>
                            <Grid item xs sx={{ fontFamily: 'monospace' }}>
                                {moment(params.row.createdAt).tz(timezone).format('L hh:mm a')}
                            </Grid>
                        </Grid>
                    </div>
                ),
            },
            {
                field: 'fraction',
                headerName: 'Fraction',
                width: 80,
                align: 'right',
                headerAlign: 'right',
                valueGetter: (params: GridValueGetterParams) =>
                    d2f(params.row.fraction).toLocaleString(undefined, { minimumFractionDigits: 2 }),
                renderCell: (params: any) => (
                    <div style={{ fontFamily: 'monospace' }}>
                        {d2f(params.row.fraction).toLocaleString(undefined, { minimumFractionDigits: 2 })}
                    </div>
                ),
            },
            {
                field: 'user.name',
                headerName: 'Fan Name',
                width: 250,
                valueGetter: (params: GridValueGetterParams) => params.row.user && params.row.user.name,
            },
            {
                field: 'user.username',
                headerName: 'Fan Username',
                width: 160,
                valueGetter: (params: GridValueGetterParams) => params.row.user && params.row.user.username,
            },
            {
                field: 'product',
                headerName: 'Product',
                width: 100,
                align: 'left',
                headerAlign: 'left',
                renderCell: params => titleCase(params.row.product) || 'Unknown',
            },
            {
                field: 'amount',
                headerName: 'Gross',
                width: 80,
                align: 'right',
                headerAlign: 'right',
                renderCell: params => moneyCell(params),
            },
            // {
            //     field: 'fee',
            //     headerName: 'Fee',
            //     width: 80,
            //     align: 'right',
            //     headerAlign: 'right',
            //     renderCell: params => moneyCell(params),
            // },
            // {
            //     field: 'net',
            //     headerName: 'Net',
            //     width: 80,
            //     align: 'right',
            //     headerAlign: 'right',
            //     renderCell: params => moneyCell(params),
            // },
            // {
            //     field: 'vat',
            //     headerName: 'VAT',
            //     width: 80,
            //     align: 'right',
            //     headerAlign: 'right',
            //     renderCell: params => moneyCell(params),
            // },
            {
                field: 'commissionPercentOverride',
                headerName: 'Commission',
                width: 120,
                align: 'right',
                headerAlign: 'right',
                renderCell: params => (
                    <div style={{ fontFamily: 'monospace' }}>
                        {params.row.commissionPercentOverride ? (
                            <span style={{ color: theme.palette.secondary.main }}>{params.row.commissionPercentOverride}%</span>
                        ) : (
                            params.row.assignedAgent && `${agents.find(agent => agent._id === params.row.assignedAgent).commissionPercent}%`
                        )}
                    </div>
                ),
            },
            {
                field: 'salesProof',
                headerName: 'Sales Proof',
                width: 110,
                sortable: false,
                renderCell: params => salesProofCellContent(params.row, handleReviewSalesProofOpen, theme),
            },
            {
                field: 'assignedAgent',
                headerName: 'Assign To Agent',
                flex: 1,
                minWidth: 150,
                sortable: false,
                renderCell: params =>
                    selectAgent(
                        agents,
                        params.row.assignedAgent || '-1',
                        params.row._id,
                        params.row.salesProof,
                        handleUpdateSingleTransaction,
                        theme,
                    ),
            },
        ],
        [agents, handleReviewSalesProofOpen, handleUpdateSingleTransaction, theme, timezone],
    );

    return (
        <>
            <Box
                sx={{
                    width: '100%',
                    '.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
                        outline: 'none',
                    },
                    marginBottom: theme.spacing(1),
                }}
            >
                <DataGrid
                    rows={transactions && 'rows' in transactions && transactions.rows ? transactions.rows : []}
                    columns={columns}
                    density={'compact'}
                    components={{ Cell: CustomCell }}
                    autoHeight={true}
                    disableColumnFilter
                    rowCount={transactions && transactions.metadata && transactions.metadata.total ? transactions.metadata.total : 0}
                    getRowId={row => row._id}
                    pageSize={limit}
                    onPageSizeChange={newPageSize => setLimit(newPageSize)}
                    rowsPerPageOptions={[10, 20, 40, 80, 100]}
                    page={offset / limit}
                    onPageChange={newPage => setOffset(limit * newPage)}
                    paginationMode={'server'}
                    sortingMode={'server'}
                    sortModel={reportSort}
                    onSortModelChange={(model: GridSortModel) => {
                        setReportSort(model);
                    }}
                    checkboxSelection={true}
                    disableSelectionOnClick={true}
                    isRowSelectable={(params: GridRowParams) =>
                        params.row.salesProof &&
                        params.row.salesProof.length === 1 &&
                        !params.row.salesProof.find((proof: any) => proof.assignedTransaction)
                            ? true
                            : false
                    }
                    onSelectionModelChange={newSelectionModel => {
                        setSelectionModel(newSelectionModel);
                    }}
                    selectionModel={selectionModel}
                    loading={transactionsLoading}
                    sx={{ backgroundColor: '#ffffff' }}
                />
            </Box>

            <Card sx={{ marginBottom: theme.spacing(1) }}>
                <CardContent style={{ padding: theme.spacing(1) }}>
                    <Grid container flexGrow={0} spacing={1} alignItems="center">
                        <Grid item xs={12}>
                            <Typography variant="body1" color="text.secondary">
                                Sales Proof Column
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body2" color="text.secondary">
                                To help speed up the approval process, if there is only one sales proof for the transaction, the color of
                                the number signifies if the match level is <span style={{ color: theme.palette.success.dark }}>STRONG</span>{' '}
                                or <span style={{ color: theme.palette.warning.main }}>WEAK</span>. If there is more than one matching sale
                                proof, the color of the number will be black.
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body2" color={'text.secondary'}>
                                The REVIEW button will show in <span style={{ color: theme.palette.warning.main }}>orange</span> if the
                                review is pending, or in <span style={{ color: theme.palette.success.dark }}>green</span> if the review is
                                complete.
                            </Typography>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </>
    );
};

export default SextforceAssignTransactionsDataGrid;
