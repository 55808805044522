import Joi from 'joi';
import moment from 'moment';
import { useDialog } from 'muibox';
import { useContext } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { SettingsContext } from '../store/SettingsContext';
import { UserContext } from '../store/UserContext';
import { OnlyFansSubscriberMessageUsersAutoMessageDetailsTaggedReleaseForm } from '../types/messageUsersAutoSendWelcomeMessage';
import { getPostIdFromPostUrl, handleHttpError } from '../utils/common';
import useAxios from './useAxios';

export namespace OnlyFansVaultMedias {
    export interface VaultMedias {
        list: List[];
        hasMore: boolean;
    }

    export interface List {
        id: number;
        type: MediaType;
        convertedToVideo: boolean;
        canView: boolean;
        counters: Counters;
        hasError: boolean;
        createdAt: Date;
        info: Info;
        source: Source;
        squarePreview: string;
        full: string;
        preview: string;
        thumb: string;
        listStates: ListState[];
        mentionedUsers: MentionedUser[];
        files: Files;
        videoSources: { [key: string]: null };
        postId?: number;
    }

    export interface Counters {
        likesCount: number;
        tipsSumm: number;
    }

    export interface Files {
        preview: FilesPreview;
    }

    export interface FilesPreview {
        url: string;
    }

    export interface Info {
        source: Source;
        preview: InfoPreview;
    }

    export interface InfoPreview {
        width: number;
        height: number;
        size: number;
    }

    export interface Source {
        source: string;
        width: number;
        height: number;
        size: number;
        duration: number;
    }

    export interface ListState {
        id: number;
        type: ListStateType;
        name: string;
        hasMedia: boolean;
        canAddMedia: boolean;
    }

    export enum ListStateType {
        Custom = 'custom',
        Messages = 'messages',
        Posts = 'posts',
        Stories = 'stories',
        Streams = 'streams',
    }

    export enum MediaType {
        Photo = 'photo',
        Video = 'video',
        Audio = 'audio',
        GIF = 'gif',
    }

    export interface MentionedUser {
        view: string;
        avatar: string;
        avatarThumbs: AvatarThumbs;
        header: string;
        headerSize: HeaderSize;
        headerThumbs: HeaderThumbs;
        id: number;
        name: string;
        username: string;
        hasNotViewedStory: boolean;
        isVerified: boolean;
        hasStream: boolean;
        hasStories: boolean;
        isRestricted: boolean;
        hasPromotion: boolean;
        isFree: boolean;
    }

    export interface AvatarThumbs {
        c50: string;
        c144: string;
    }

    export interface HeaderSize {
        width: number;
        height: number;
    }

    export interface HeaderThumbs {
        w480: string;
        w760: string;
    }

    export interface DeleteResponse {
        success: boolean;
    }

    export interface RestructuredResponseItem {
        id: number;
        canView: boolean;
        hasError: boolean;
        createdAt: Date;
        thumb: string;
        cachedThumb: string | null;
        src?: string;
        duration?: number;
        type: string;
        counters: Counters;
        mentionedUsers?: MentionedUser[];
    }
}

export interface OnlyFansSubscriberMessageUsersAutoMessageDetailsTaggedCreator {
    id: number;
    name: string;
}

export const RestructuredResponseItemSchema = Joi.object<OnlyFansVaultMedias.RestructuredResponseItem>({
    id: Joi.number().required(),
    canView: Joi.boolean().required(),
    hasError: Joi.boolean().required(),
    createdAt: Joi.date().required(),
    thumb: Joi.string().allow('', null),
    cachedThumb: Joi.string().allow(null),
    src: Joi.string().allow('', null),
    duration: Joi.number().allow(null),
    type: Joi.string().required(),
    counters: Joi.object<OnlyFansVaultMedias.Counters>({
        likesCount: Joi.number().required(),
        tipsSumm: Joi.number().required(),
    }).required(),
    mentionedUsers: Joi.array()
        .items(
            Joi.object<OnlyFansVaultMedias.MentionedUser>({
                view: Joi.string().required(),
                avatar: Joi.string(),
                avatarThumbs: Joi.object<OnlyFansVaultMedias.AvatarThumbs>({
                    c50: Joi.string().required(),
                    c144: Joi.string().required(),
                }),
                header: Joi.string(),
                headerSize: Joi.object<OnlyFansVaultMedias.HeaderSize>({
                    width: Joi.number().required(),
                    height: Joi.number().required(),
                }),
                headerThumbs: Joi.object<OnlyFansVaultMedias.HeaderThumbs>({
                    w480: Joi.string().required(),
                    w760: Joi.string().required(),
                }),
                id: Joi.number().required(),
                name: Joi.string().required(),
                username: Joi.string().required(),
                hasNotViewedStory: Joi.boolean().required(),
                isVerified: Joi.boolean().required(),
                hasStream: Joi.boolean().required(),
                hasStories: Joi.boolean().required(),
                isRestricted: Joi.boolean().required(),
                hasPromotion: Joi.boolean().required(),
                isFree: Joi.boolean().required(),
            }),
        )
        .default([]),
});
export interface SextforceAutoCampaignReplyMessageDetails {
    lockMessagePrice: number | null;
    lockedText: boolean;
    message: string;
    replaceUsername: string | null;
    mediaFiles: OnlyFansVaultMedias.RestructuredResponseItem[];
    mediaFilesPreviews: number[];
    taggedCreators: OnlyFansSubscriberMessageUsersAutoMessageDetailsTaggedCreator[];
    taggedReleaseForms?: OnlyFansSubscriberMessageUsersAutoMessageDetailsTaggedReleaseForm[];
}

export const SextforceAutoCampaignReplyMessageDetailsSchema = Joi.object<SextforceAutoCampaignReplyMessageDetails>({
    lockMessagePrice: Joi.number().allow(null),
    lockedText: Joi.boolean().allow(null).default(false),
    message: Joi.string().trim().allow('').default(''),
    replaceUsername: Joi.string().allow(null, '').default(null),
    mediaFiles: Joi.array().items(RestructuredResponseItemSchema).default([]),
    mediaFilesPreviews: Joi.array().items(Joi.number()).default([]),
    taggedCreators: Joi.array().items(
        Joi.object<OnlyFansSubscriberMessageUsersAutoMessageDetailsTaggedCreator>({
            id: Joi.number().required(),
            name: Joi.string().required(),
        }).default([]),
    ),
    taggedReleaseForms: Joi.array().items(
        Joi.object<OnlyFansSubscriberMessageUsersAutoMessageDetailsTaggedReleaseForm>({
            id: Joi.number().required(),
            name: Joi.string().required(),
        }).default([]),
    ),
});

export interface SextforceAutoCampaignTipReply {
    _id?: string;
    subscriberId: string;
    name: string;
    description?: string;
    campaignId: number;
    campaignUrl: string;
    postDate: Date;
    postExpireDate?: Date;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    payload: any;
    tipsOptions: number[];
    replyCount?: number;
    active: boolean;
}

export const SextforceAutoCampaignTipReplySchema = Joi.object<SextforceAutoCampaignTipReply>({
    subscriberId: Joi.string().required(),
    name: Joi.string().trim().required(),
    description: Joi.string().trim().allow('').default('').optional(),
    campaignId: Joi.number().required(),
    campaignUrl: Joi.string()
        .trim()
        .custom((value, helpers) => {
            if (!value) {
                return helpers.error('string.empty');
            }

            const postId = getPostIdFromPostUrl(value);

            if (postId === 0) {
                // Invalid URL
                return helpers.error('string.uri');
            }

            return value;
        })
        .uri()
        .messages({
            'string.uri': 'The Campaign URL must be a valid URL.',
            'string.empty': 'The Campaign URL cannot be empty.',
        })
        .required(),
    postDate: Joi.date().required(),
    postExpireDate: Joi.date().optional().allow(null),
    payload: Joi.any().optional(),
    tipsOptions: Joi.array().items(Joi.number()).required(),
    replyCount: Joi.number().optional().allow(null).default(0),
    active: Joi.boolean().required(),
});

export interface SextforceAutoCampaignTipReplyMessageDetails {
    _id?: string;
    autoCampaignReplyId: string;
    tipAmount: number;
    replyCount: number;
    listId?: number;
    existingTippersProcessed?: boolean;
    messageDetails: SextforceAutoCampaignReplyMessageDetails;
    __v?: number;
}

export const SextforceAutoCampaignTipReplyMessageDetailsSchema = Joi.object<SextforceAutoCampaignTipReplyMessageDetails>({
    _id: Joi.string().optional().allow(null),
    autoCampaignReplyId: Joi.string().required(),
    tipAmount: Joi.number().required(),
    replyCount: Joi.number().required(),
    listId: Joi.number().optional().allow(null).default(null),
    existingTippersProcessed: Joi.boolean().optional().allow(null).default(false),
    messageDetails: SextforceAutoCampaignReplyMessageDetailsSchema.required(),
    __v: Joi.number().optional().allow(null),
});

export interface SextforceAutoCampaignTipReplyWithMessageDetails extends SextforceAutoCampaignTipReply {
    messageDetails: [SextforceAutoCampaignTipReplyMessageDetails];
}

export type SextforceAutoCampaignTipReplySortBy = '_id' | 'name' | 'description' | 'postDate' | 'replyCount';

const useSextforceAutoCampaignTipReply = (
    query: string,
    startDate: Date,
    endDate: Date,
    timezone: string,
    active: boolean | null,
    sortBy: SextforceAutoCampaignTipReplySortBy,
) => {
    const userContext = useContext(UserContext);
    const settingsContext = useContext(SettingsContext);
    const axios = useAxios();
    const dialog = useDialog();
    const params = useParams();

    // Fetch online users count vs. total sales report
    const fetchData = async (
        query: string,
        startDate: Date,
        endDate: Date,
        timezone: string,
        active: boolean | null,
        sortBy: SextforceAutoCampaignTipReplySortBy,
    ): Promise<{
        list: SextforceAutoCampaignTipReplyWithMessageDetails[];
        metadata: {
            totalCampaigns: number;
            totalReplies: number;
        };
    }> => {
        if (!userContext.jwtToken || !settingsContext.apiKey) {
            return {
                list: [],
                metadata: {
                    totalCampaigns: 0,
                    totalReplies: 0,
                },
            };
        }

        if (!startDate || !endDate) {
            return {
                list: [],
                metadata: {
                    totalCampaigns: 0,
                    totalReplies: 0,
                },
            };
        }

        const url: string = `${settingsContext.routes.autoCampaignTipReply.base}${params.userId}?${new URLSearchParams({
            query: query.trim(),
            startDate: moment(startDate).tz(timezone, true).toISOString(),
            endDate: moment(endDate).tz(timezone, true).toISOString(),
            active: typeof active === 'boolean' ? (active ? 'true' : 'false') : 'null',
            sortBy: sortBy,
        })}`;

        const data = await axios
            .get(url)
            .then(
                async response =>
                    response.data as {
                        list: SextforceAutoCampaignTipReplyWithMessageDetails[];
                        metadata: {
                            totalCampaigns: number;
                            totalReplies: number;
                        };
                    },
            )
            .catch(error => {
                handleHttpError(error, dialog);

                return {
                    list: [],
                    metadata: {
                        totalCampaigns: 0,
                        totalReplies: 0,
                    },
                };
            });

        return data;
    };

    // Update an auto campaign tip reply
    const updateAutoCampaignTipReply = async (
        id: string,
        autoCampaignTipReply: SextforceAutoCampaignTipReply,
    ): Promise<SextforceAutoCampaignTipReply | null> => {
        if (!userContext.jwtToken || !settingsContext.apiKey) {
            return null;
        }

        const url: string = `${settingsContext.routes.autoCampaignTipReply.base}${params.userId}/${id}`;

        const data = await axios
            .put(url, autoCampaignTipReply)
            .then(async response => response.data as SextforceAutoCampaignTipReply)
            .catch(error => {
                handleHttpError(error, dialog);

                return null;
            });

        return data;
    };

    // Delete auto campaign tip reply
    const deleteAutoCampaignTipReply = async (id: string): Promise<SextforceAutoCampaignTipReply | null> => {
        if (!userContext.jwtToken || !settingsContext.apiKey) {
            return null;
        }

        const url: string = `${settingsContext.routes.autoCampaignTipReply.base}${params.userId}/${id}`;

        const data = await axios
            .delete(url)
            .then(async response => response.data as SextforceAutoCampaignTipReply)
            .catch(error => {
                handleHttpError(error, dialog);

                return null;
            });

        return data;
    };

    // Create auto tip campaign tip reply message details
    const createAutoCampaignTipReplyTipMessageDetails = async (
        autoCampaignTipReply: SextforceAutoCampaignTipReply,
        autoCampaignTipReplyTipMessageDetails: SextforceAutoCampaignTipReplyMessageDetails,
    ): Promise<SextforceAutoCampaignTipReplyMessageDetails | null> => {
        if (!userContext.jwtToken || !settingsContext.apiKey) {
            return null;
        }

        const url: string = `${settingsContext.routes.autoCampaignTipReply.base}${params.userId}/${autoCampaignTipReply._id}/messageDetails`;

        const data = await axios
            .post(url, autoCampaignTipReplyTipMessageDetails)
            .then(async response => response.data as SextforceAutoCampaignTipReplyMessageDetails)
            .catch(error => {
                handleHttpError(error, dialog);

                return null;
            });

        return data;
    };

    // Update auto tip campaign tip reply message details
    const updateAutoCampaignTipReplyTipMessageDetails = async (
        autoCampaignTipReply: SextforceAutoCampaignTipReply,
        autoCampaignTipReplyTipMessageDetails: SextforceAutoCampaignTipReplyMessageDetails,
    ): Promise<SextforceAutoCampaignTipReplyMessageDetails | null> => {
        if (!userContext.jwtToken || !settingsContext.apiKey) {
            return null;
        }

        const url: string = `${settingsContext.routes.autoCampaignTipReply.base}${params.userId}/${autoCampaignTipReply._id}/messageDetails/${autoCampaignTipReplyTipMessageDetails._id}`;

        const data = await axios
            .put(url, autoCampaignTipReplyTipMessageDetails)
            .then(async response => response.data as SextforceAutoCampaignTipReplyMessageDetails)
            .catch(error => {
                handleHttpError(error, dialog);

                return null;
            });

        return data;
    };

    // Delete auto tip campaign tip reply message details
    const deleteAutoCampaignTipReplyTipMessageDetails = async (
        autoCampaignTipReply: SextforceAutoCampaignTipReply,
        autoCampaignTipReplyTipMessageDetails: SextforceAutoCampaignTipReplyMessageDetails,
    ): Promise<SextforceAutoCampaignTipReplyMessageDetails | null> => {
        if (!userContext.jwtToken || !settingsContext.apiKey) {
            return null;
        }

        const url: string = `${settingsContext.routes.autoCampaignTipReply.base}${params.userId}/${autoCampaignTipReply._id}/messageDetails/${autoCampaignTipReplyTipMessageDetails._id}`;

        const data = await axios
            .delete(url)
            .then(async response => response.data as SextforceAutoCampaignTipReplyMessageDetails)
            .catch(error => {
                handleHttpError(error, dialog);

                return null;
            });

        return data;
    };

    const autoCampaignTipReply = useQuery(
        ['autoCampaignTipReply', params.userId, userContext.jwtToken, query, startDate, endDate, timezone, active, sortBy],
        () => {
            return fetchData(query, startDate, endDate, timezone, active, sortBy);
        },
        {
            refetchOnWindowFocus: false,
            enabled: userContext.jwtToken && params.userId && settingsContext.apiKey ? true : false,
        },
    );

    return {
        ...autoCampaignTipReply,
        updateAutoCampaignTipReply,
        deleteAutoCampaignTipReply,
        createAutoCampaignTipReplyTipMessageDetails,
        updateAutoCampaignTipReplyTipMessageDetails,
        deleteAutoCampaignTipReplyTipMessageDetails,
    };
};

export default useSextforceAutoCampaignTipReply;
