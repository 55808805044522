import { Grid, Typography } from '@mui/material';
import { Theme } from '@mui/system';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { SettingsContext } from '../../store/SettingsContext';
import { buildURL, getServiceStatusColor, getServiceStatusName } from '../../utils/common';

type DashboardMyAccountsServicesListProps = {
    subscriber: any;
    theme: Theme;
};

const DashboardMyAccountsServicesList = (props: DashboardMyAccountsServicesListProps) => {
    const { subscriber, theme } = props;
    const settingsContext = useContext(SettingsContext);

    return (
        <>
            <Typography variant="body2" color={'text.secondary'} textAlign="center" sx={{ marginBottom: theme.spacing(1) }}>
                Services
            </Typography>
            <Grid container flexGrow={1} spacing={1}>
                {settingsContext &&
                    settingsContext.services &&
                    Object.keys(settingsContext.services)
                        .filter((key: string) => settingsContext.services[key].visible === true)
                        .map((key: string) => (
                            <Grid item xs={12} key={`${subscriber._id}_${key}`}>
                                <Grid container flexGrow={1} spacing={2} alignItems="center">
                                    <Grid item xs="auto">
                                        {settingsContext.services[key].icon(
                                            getServiceStatusColor(subscriber, key, settingsContext.services[key].alwaysActive),
                                        )}
                                    </Grid>
                                    <Grid item xs="auto" textAlign="left">
                                        {settingsContext.services[key].homeUrl ? (
                                            <Link
                                                to={`${buildURL(
                                                    `/subscribers/${subscriber._id}`,
                                                    settingsContext.services[key].homeUrl || '',
                                                )}`}
                                                style={{ textDecoration: 'none', color: theme.palette.primary.main }}
                                            >
                                                {settingsContext.services[key].name.toUpperCase()}
                                            </Link>
                                        ) : (
                                            settingsContext.services[key].name.toUpperCase()
                                        )}
                                    </Grid>
                                    <Grid item xs textAlign={'right'}>
                                        <span
                                            style={{
                                                fontSize: '10pt',
                                                color: theme.palette[
                                                    getServiceStatusColor(subscriber, key, settingsContext.services[key].alwaysActive) ||
                                                        'primary'
                                                ].main,
                                            }}
                                        >
                                            {getServiceStatusName(subscriber, key, settingsContext.services[key].alwaysActive)}
                                        </span>
                                    </Grid>
                                </Grid>
                            </Grid>
                        ))}
            </Grid>
        </>
    );
};

export default DashboardMyAccountsServicesList;
