import { Amplify } from 'aws-amplify';
import awsExports from '../aws-exports';
import { Authenticator, AmplifyProvider, Theme } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { Theme as MuiTheme } from '@mui/system';
import muiUseTheme from '@mui/material/styles/useTheme';
import { Grid } from '@mui/material';
import '@aws-amplify/ui-react/styles.css';

Amplify.configure(awsExports);

function Authentication() {
    const muiTheme: MuiTheme = muiUseTheme();
    const theme: Theme = {
        name: 'Auth Example Theme',
        tokens: {
            colors: {
                background: {
                    primary: {
                        value: muiTheme.palette.common.white,
                    },
                    secondary: {
                        value: muiTheme.palette.background.default,
                    },
                },
                border: {
                    primary: {
                        value: '#001219',
                    },
                    focus: {
                        value: '#001219',
                    },
                    disabled: {
                        value: muiTheme.palette.grey[600],
                    },
                },
                font: {
                    interactive: {
                        value: muiTheme.palette.text.primary,
                    },
                },
                brand: {
                    primary: {
                        '10': {
                            value: muiTheme.palette.secondary.main,
                        },
                        '80': {
                            value: muiTheme.palette.primary.main,
                        },
                        '90': {
                            value: muiTheme.palette.secondary.main,
                        },
                        '100': {
                            value: muiTheme.palette.primary.main,
                        },
                    },
                },
            },
            fonts: {
                default: {
                    static: {
                        value: (muiTheme.typography as any).fontFamily,
                    },
                    variable: {
                        value: (muiTheme.typography as any).fontFamily,
                    },
                },
            },
        },
    };

    return (
        <Grid container spacing={0} style={{ height: '100vh' }} alignItems="center">
            <Grid item xs>
                <AmplifyProvider theme={theme}>
                    <Authenticator />
                </AmplifyProvider>
            </Grid>
        </Grid>
    );
}

export default Authentication;
