import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import TelegramIcon from '@mui/icons-material/Telegram';
import {
    Alert,
    Box,
    Button,
    Grid,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Theme,
    Typography,
    useTheme,
} from '@mui/material';
import { useDialog } from 'muibox';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import BackNavigationButton from '../../../components/common/BackNavigationButton';
import PageContainer from '../../../components/common/PageContainter';
import StyledCard from '../../../components/common/StyledCard';
import StyledCardTitleBar from '../../../components/common/StyledCardTitleBar';
import SextforceOnlyFansTelegramNotificationAddNewForm from '../../../components/services/sextforce/bigBrother/onlyFansTelegramNotifications/SextforceOnlyFansTelegramNotificationAddNewForm';
import SextforceOnlyFansTelegramNotificationRow from '../../../components/services/sextforce/bigBrother/onlyFansTelegramNotifications/SextforceOnlyFansTelegramNotificationRow';
import useSextforceOnlyFansTelegramNotifications, {
    SextforceOnlyFansTelegramNotification,
} from '../../../hooks/useSextforceOnlyFansTelegramNotifications';
import useSubscriber from '../../../hooks/useSubscriber';
import useSubscriberSettingsNotifications, {
    OnlyFansSettingsNotificationsTransport,
} from '../../../hooks/useSubscriberSettingsNotifications';
import { SettingsContext } from '../../../store/SettingsContext';

const SextforceAutoCampaignTipReplyDescription = () => {
    return (
        <>
            <Typography variant="body1" gutterBottom>
                Receive notifications on your Telegram account when you receive a message from a fan on OnlyFans, when a tip is received, or
                when a new subscriber follows or returns to your OnlyFans account. You can choose to include the amount the fan had spent,
                ignore freeloaders or spenders, so you can focus on the most important fans.
            </Typography>
            <Typography variant="body1" gutterBottom>
                Any incoming messages handled by the Auto-Reply to Expired and Auto-Reply to Freeloaders systems will get skipped so to not
                bother you with unnecessary notifications.
            </Typography>
            <Typography variant="body1" color="text.secondary">
                You can have up to two Telegram accounts registered.
            </Typography>
        </>
    );
};

const SextforceOnlyFansTelegramNotifications = () => {
    const settingsContext = useContext(SettingsContext);
    const theme: Theme = useTheme();
    const dialog = useDialog();
    const { enqueueSnackbar } = useSnackbar();

    const { data: subscriber } = useSubscriber();
    const {
        data: sextforceOnlyFansTelegramNotifications,
        isLoading: isSextforceOnlyFansTelegramNotificationsLoading,
        refetch: refetchSextforceOnlyFansTelegramNotifications,
        createSextforceOnlyFansTelegramNotification,
        isCreatingSextforceOnlyFansTelegramNotification,
        updateSextforceOnlyFansTelegramNotification,
        isUpdatingSextforceOnlyFansTelegramNotification,
        setSextforceOnlyFansTelegramNotificationActive,
        isSetSextforceOnlyFansTelegramNotificationActive,
        deleteSextforceOnlyFansTelegramNotification,
        isDeletingSextforceOnlyFansTelegramNotification,
    } = useSextforceOnlyFansTelegramNotifications(subscriber?._id);

    const [notificationsList, setNotificationsList] = useState<SextforceOnlyFansTelegramNotification[]>([]);

    // OnlyFans Notifications Settings
    const { data: onlyFansNotificationSettings, isLoading: onlyFansNotificationSettingsLoading } = useSubscriberSettingsNotifications();
    const youAndYourPosts: OnlyFansSettingsNotificationsTransport.Response | undefined = onlyFansNotificationSettings?.find(
        n => n.code === 'you_and_your_posts',
    );
    const newSubscriberNotifications: OnlyFansSettingsNotificationsTransport.Item | undefined = youAndYourPosts?.items.find(
        n => n.code === 'new_subscriber',
    );
    const newSubscriberReturningNotifications: OnlyFansSettingsNotificationsTransport.Item | undefined = youAndYourPosts?.items.find(
        n => n.code === 'returning_subscriber',
    );
    const newSubscriberTrialNotifications: OnlyFansSettingsNotificationsTransport.Item | undefined = youAndYourPosts?.items.find(
        n => n.code === 'new_subscriber_trial',
    );
    const newTipsFundRaisingNotification: OnlyFansSettingsNotificationsTransport.Item | undefined = youAndYourPosts?.items.find(
        n => n.code === 'new_tips_fund_raising',
    );
    const newTipsNotification: OnlyFansSettingsNotificationsTransport.Item | undefined = youAndYourPosts?.items.find(
        n => n.code === 'new_tips',
    );
    const siteNotificationsOn: boolean =
        (newSubscriberNotifications?.isEnabled &&
            newSubscriberReturningNotifications?.isEnabled &&
            newSubscriberTrialNotifications?.isEnabled &&
            newTipsFundRaisingNotification?.isEnabled &&
            newTipsNotification?.isEnabled) ??
        false;

    useEffect(() => {
        if (isSextforceOnlyFansTelegramNotificationsLoading) {
            return;
        }

        if (sextforceOnlyFansTelegramNotifications) {
            setNotificationsList(sextforceOnlyFansTelegramNotifications);
        } else {
            setNotificationsList([]);
        }
    }, [isSextforceOnlyFansTelegramNotificationsLoading, sextforceOnlyFansTelegramNotifications]);

    const [showAddNewForm, setShowAddNewForm] = useState<boolean>(false);

    // Handlers
    const handleSetSextforceOnlyFansTelegramNotificationActive = async (notificationId: string, active: boolean) => {
        const result: SextforceOnlyFansTelegramNotification = await setSextforceOnlyFansTelegramNotificationActive(notificationId, active);

        if (result) {
            enqueueSnackbar('Telegram account updated', { variant: 'success' });

            await refetchSextforceOnlyFansTelegramNotifications();
        } else {
            enqueueSnackbar('Failed to update Telegram account', { variant: 'error' });
        }
    };

    const handleDeleteSextforceOnlyFansTelegramNotification = async (notificationId: string) => {
        dialog
            .confirm({
                title: 'Delete Telegram Account',
                message: 'Are you sure you want to delete this Telegram account? All notifications will be stopped.',
            })
            .then(async () => {
                const result = await deleteSextforceOnlyFansTelegramNotification(notificationId);

                if (result.success) {
                    enqueueSnackbar('Telegram account deleted', { variant: 'success' });

                    await refetchSextforceOnlyFansTelegramNotifications();
                } else {
                    enqueueSnackbar('Failed to delete Telegram account', { variant: 'error' });
                }
            })
            .catch(() => {
                // Do nothing
            });
    };

    return (
        <PageContainer>
            {subscriber && '_id' in subscriber && subscriber._id && (
                <>
                    <Typography variant="h5" sx={{ mb: 2 }}>
                        <BackNavigationButton url={`/subscribers/${subscriber._id}/${settingsContext.services.sextforce.homeUrl}`} />{' '}
                        Telegram Notifications for {subscriber.username}
                    </Typography>

                    <StyledCard noCard noBottomMargin>
                        <StyledCardTitleBar title="Telegram Notifications" theme={theme} />
                    </StyledCard>

                    <StyledCard>
                        <SextforceAutoCampaignTipReplyDescription />
                    </StyledCard>

                    {!onlyFansNotificationSettingsLoading && !siteNotificationsOn && (
                        <StyledCard noCard>
                            <Alert severity="warning" sx={{ mb: 1 }}>
                                <Typography variant="body1">
                                    <strong>Important:</strong> For this service to work, you must have the following notifications enabled
                                    in your{' '}
                                    <Link to={'https://onlyfans.com/my/settings/notifications/site'} target="_blank">
                                        OnlyFans Notifications Settings
                                    </Link>
                                    :
                                </Typography>
                                <ul>
                                    <li>
                                        <Typography variant="body1">New Subscriber</Typography>
                                    </li>
                                    <li>
                                        <Typography variant="body1">Returning Subscriber</Typography>
                                    </li>
                                    <li>
                                        <Typography variant="body1">New Subscription from Trial</Typography>
                                    </li>
                                    <li>
                                        <Typography variant="body1">New Tips Fund Raising</Typography>
                                    </li>
                                    <li>
                                        <Typography variant="body1">New Tip</Typography>
                                    </li>
                                </ul>
                            </Alert>
                        </StyledCard>
                    )}
                    {!onlyFansNotificationSettingsLoading && !onlyFansNotificationSettings && (
                        <StyledCard noCard>
                            <Alert severity="error" sx={{ mb: 1 }}>
                                <Typography variant="body1">
                                    <strong>Warning:</strong> Could not connect to your OnlyFans account. Please make sure this account is
                                    logged in.
                                </Typography>
                            </Alert>
                        </StyledCard>
                    )}

                    {!showAddNewForm && (
                        <StyledCard noCard>
                            <Button
                                variant="contained"
                                color="secondary"
                                fullWidth
                                size="large"
                                onClick={() => {
                                    setShowAddNewForm(true);
                                }}
                                startIcon={<AddIcon />}
                            >
                                Add New Telegram Account
                            </Button>
                        </StyledCard>
                    )}

                    {showAddNewForm && (
                        <SextforceOnlyFansTelegramNotificationAddNewForm
                            initialOptions={undefined}
                            isLoading={isCreatingSextforceOnlyFansTelegramNotification}
                            handleSave={createSextforceOnlyFansTelegramNotification}
                            refetchSextforceOnlyFansTelegramNotifications={refetchSextforceOnlyFansTelegramNotifications}
                            setOpen={setShowAddNewForm}
                        />
                    )}

                    <StyledCard noCard noBottomMargin>
                        <StyledCardTitleBar
                            title={`Telegram Accounts (${notificationsList ? notificationsList.length.toLocaleString() : 0}/2)`}
                            isLoading={isSextforceOnlyFansTelegramNotificationsLoading}
                            theme={theme}
                        />
                    </StyledCard>

                    <StyledCard>
                        <Alert severity="info" icon={false} sx={{ mb: 1 }}>
                            <Grid container spacing={1} alignItems="center">
                                <Grid item xs={12}>
                                    <Stack direction="column" spacing={0}>
                                        <Typography variant="body2" fontWeight="bold">
                                            Actions
                                        </Typography>
                                        <Stack direction="row" spacing={1}>
                                            <TelegramIcon fontSize="small" color="primary" />
                                            <Typography variant="body2">Go to the Telegram account</Typography>
                                        </Stack>
                                        <Stack direction="row" spacing={1}>
                                            <PowerSettingsNewIcon fontSize="small" color="success" />
                                            <Typography variant="body2">Turn notifications on/off for this Telegram account</Typography>
                                        </Stack>
                                        <Stack direction="row" spacing={1}>
                                            <EditIcon fontSize="small" color="primary" />
                                            <Typography variant="body2">Edit notifications settings</Typography>
                                        </Stack>
                                        <Stack direction="row" spacing={1}>
                                            <DeleteIcon fontSize="small" color="error" />
                                            <Typography variant="body2">Delete Auto Repost</Typography>
                                        </Stack>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Alert>

                        <Box
                            sx={{
                                display: 'block',
                                overflowX: 'auto',
                            }}
                        >
                            {!isSextforceOnlyFansTelegramNotificationsLoading &&
                                sextforceOnlyFansTelegramNotifications &&
                                sextforceOnlyFansTelegramNotifications.length === 0 && (
                                    <Typography
                                        variant="body1"
                                        sx={{
                                            textAlign: 'center',
                                            color: theme.palette.text.secondary,
                                        }}
                                    >
                                        No Telegram accounts registered
                                    </Typography>
                                )}
                            {notificationsList && notificationsList.length > 0 && (
                                <>
                                    <Table
                                        size="small"
                                        sx={{
                                            // No padding on the left and right of the table but only on the first and last cell
                                            '& td': {
                                                '&:first-of-type': {
                                                    paddingLeft: '0px',
                                                    paddingRight: '0px',
                                                },
                                                '&:last-child': {
                                                    paddingRight: '0px',
                                                },
                                                paddingLeft: 0.5,
                                                paddingRight: 0.5,
                                            },
                                            '& th': {
                                                '&:first-of-type': {
                                                    paddingLeft: '0px',
                                                    paddingRight: '0px',
                                                },
                                                '&:last-child': {
                                                    paddingRight: '0px',
                                                },
                                                // Make header cells bold
                                                fontWeight: 'bold',
                                                paddingLeft: 0.5,
                                                paddingRight: 0.5,
                                            },
                                            // remove last bottom border
                                            '& tr:last-child td': {
                                                borderBottom: 'none',
                                            },
                                        }}
                                    >
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Telegram Account</TableCell>
                                                <TableCell width={160} align="center">
                                                    Registered On
                                                </TableCell>
                                                <TableCell width={100} align="center">
                                                    Actions
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {notificationsList.map(sextforceOnlyFansTelegramNotification => (
                                                <SextforceOnlyFansTelegramNotificationRow
                                                    key={sextforceOnlyFansTelegramNotification._id}
                                                    sextforceOnlyFansTelegramNotification={sextforceOnlyFansTelegramNotification}
                                                    handleSetSextforceOnlyFansTelegramNotificationActive={
                                                        handleSetSextforceOnlyFansTelegramNotificationActive
                                                    }
                                                    isSetSextforceOnlyFansTelegramNotificationActive={
                                                        isSetSextforceOnlyFansTelegramNotificationActive
                                                    }
                                                    handleDeleteSextforceOnlyFansTelegramNotification={
                                                        handleDeleteSextforceOnlyFansTelegramNotification
                                                    }
                                                    isDeletingSextforceOnlyFansTelegramNotification={
                                                        isDeletingSextforceOnlyFansTelegramNotification
                                                    }
                                                    handleSave={updateSextforceOnlyFansTelegramNotification}
                                                    isLoading={isUpdatingSextforceOnlyFansTelegramNotification}
                                                    refetchSextforceOnlyFansTelegramNotifications={
                                                        refetchSextforceOnlyFansTelegramNotifications
                                                    }
                                                />
                                            ))}
                                        </TableBody>
                                    </Table>
                                </>
                            )}
                        </Box>
                    </StyledCard>
                </>
            )}
        </PageContainer>
    );
};

export default SextforceOnlyFansTelegramNotifications;
