import { Checkbox } from '@mui/material';
import { OnlyFansSubscriberHostessAutoReplyFreeloaders } from '../../../../types/hostessAutoReplyFreeloaders';

const FormDelayReplyCheckbox = (props: {
    formData: OnlyFansSubscriberHostessAutoReplyFreeloaders;
    setFormData: (formData: OnlyFansSubscriberHostessAutoReplyFreeloaders) => void;
}) => {
    const { formData, setFormData } = props;

    return (
        <Checkbox
            checked={formData && formData.delayBeforeSendingMessage ? true : false}
            size="medium"
            onChange={e => {
                setFormData({ ...formData, delayBeforeSendingMessage: e.target.checked });
            }}
            sx={{
                '& .MuiSvgIcon-root': {
                    fontSize: '1.5rem',
                },
            }}
        />
    );
};

export default FormDelayReplyCheckbox;
