import { useEffect, useState } from 'react';
import { Theme } from '@mui/system';
import useTheme from '@mui/material/styles/useTheme';
import dinero from 'dinero.js';
import { ResponsiveBar } from '@nivo/bar';
import { Card, CardContent, Checkbox, Divider, FormControlLabel, Grid, Radio, Skeleton, Typography } from '@mui/material';
import { d2f } from '../../../../utils/common';

const generateData = (
    transactionsMain: any[],
    transactionsCompare: any[],
    compare: boolean,
    sortDescending: boolean,
    includeAnonymous: boolean,
) => {
    // Compile a list of all the agents names;
    const agentsNames: string[] = [];

    transactionsMain.forEach(transaction => {
        const fraction = d2f(transaction.fraction);

        if ((fraction === 0 && includeAnonymous) || fraction !== 0) {
            agentsNames.push(transaction.agent ? `${transaction.agent.name} (${fraction})` : `Unknown (${fraction})`);
        }
    });

    if (compare) {
        transactionsCompare.forEach(transaction => {
            const fraction = d2f(transaction.fraction);
            const name: string = transaction.agent ? `${transaction.agent.name} (${fraction})` : `Unknown (${fraction})`;

            if (!agentsNames.includes(name)) {
                agentsNames.push();
            }
        });
    }

    let data: any[] = [];

    // Create a data group for each Agent
    agentsNames.forEach(agent => {
        const transaction = transactionsMain.find(transaction => {
            const fraction = d2f(transaction.fraction);
            const name: string = transaction.agent ? `${transaction.agent.name} (${fraction})` : `Unknown (${fraction})`;

            return name === agent;
        });

        const group: any = {
            id: agent,
            Main: transaction ? transaction.totalAmountCents : 0,
        };

        if (compare) {
            const transactionCompare = transactionsCompare.find(itemCompare => {
                const fraction = d2f(itemCompare.fraction);
                const name: string = itemCompare.agent ? `${itemCompare.agent.name} (${fraction})` : `Unknown (${fraction})`;

                return name === agent;
            });

            if (transactionCompare) {
                group.Comparisson = transactionCompare.totalAmountCents;
            }
        }

        data.push(group);
    });

    // Sort the data by main search's amount
    const sortedData = sortDescending
        ? data.sort((a: any, b: any) => (a.Main < b.Main ? 1 : -1))
        : data.sort((a: any, b: any) => (a.Main > b.Main ? 1 : -1));

    return sortedData;
};

type ReportAccountTotalsByAgentGraphProps = {
    transactionsMain: any[];
    transactionsCompare: any[];
    compare: boolean;
    isFetching: boolean;
};

const ReportAccountTotalsByAgentGraph = (props: ReportAccountTotalsByAgentGraphProps) => {
    const { transactionsMain, transactionsCompare, compare, isFetching } = props;
    const theme: Theme = useTheme();
    const [data, setData] = useState<any[]>([]);
    const [sortDescending, setSortDescending] = useState<boolean>(true);
    const [includeAnonymous, setIncludeAnonymous] = useState<boolean>(true);

    useEffect(() => {
        if (transactionsMain) {
            setData(generateData(transactionsMain, transactionsCompare, compare, sortDescending, includeAnonymous));
        }
    }, [transactionsMain, transactionsCompare, compare, theme, sortDescending, includeAnonymous]);

    return (
        <Card variant="elevation" sx={{ marginBottom: theme.spacing(4) }}>
            <CardContent>
                <Typography variant="h6" sx={{ marginBottom: theme.spacing(2) }}>
                    Agents Performance Graph
                </Typography>
                <Typography variant="body1" sx={{ marginBottom: theme.spacing(2) }}>
                    This reports shows each Agent's sales and Annonymous sales (transactions which end with X.00) as a bar graph. The graph
                    is sorted left-to-right from the highest sales to lowest. When comparing a week vs. week or and date range, this graph
                    easily shows an Agent's progress and performance.
                </Typography>

                <Grid container flexGrow={0} spacing={0} alignItems="center" sx={{ marginBottom: theme.spacing(2) }}>
                    <Grid item xs="auto">
                        <FormControlLabel
                            checked={includeAnonymous}
                            control={<Checkbox size="small" />}
                            label="Include Anonymous"
                            onChange={(e, checked) => setIncludeAnonymous(checked)}
                        />
                    </Grid>
                    <Divider flexItem orientation="vertical" />
                    <Grid item xs="auto" sx={{ paddingLeft: theme.spacing(2) }}>
                        Sort:
                    </Grid>
                    <Grid item xs="auto" sx={{ paddingLeft: theme.spacing(2) }}>
                        <FormControlLabel
                            checked={sortDescending}
                            control={<Radio size="small" />}
                            label="Descending"
                            onChange={(e, checked) => setSortDescending(checked)}
                        />
                    </Grid>
                    <Grid item xs="auto" sx={{ paddingLeft: theme.spacing(2) }}>
                        <FormControlLabel
                            checked={!sortDescending}
                            control={<Radio size="small" />}
                            label="Ascending"
                            onChange={(e, checked) => setSortDescending(checked ? false : true)}
                        />
                    </Grid>
                </Grid>

                <Grid container flexGrow={1}>
                    {isFetching ? (
                        <Skeleton />
                    ) : (
                        <Grid item xs sx={{ height: 500 }}>
                            <ResponsiveBar
                                data={data}
                                keys={compare ? ['Main', 'Comparisson'] : ['Main']}
                                valueFormat={(n: number) => dinero({ amount: n, currency: 'USD' }).toFormat()}
                                margin={{ top: 0, right: 0, bottom: 130, left: 80 }}
                                groupMode="grouped"
                                valueScale={{ type: 'linear' }}
                                indexScale={{ type: 'band', round: true }}
                                colors={{ scheme: 'set3' }}
                                borderColor={{
                                    from: 'color',
                                    modifiers: [['darker', 1.6]],
                                }}
                                axisTop={null}
                                axisRight={null}
                                axisBottom={{
                                    tickSize: 5,
                                    tickPadding: 5,
                                    tickRotation: -45,
                                    legend: 'Agents',
                                    legendPosition: 'middle',
                                    legendOffset: 120,
                                }}
                                axisLeft={{
                                    tickSize: 5,
                                    tickPadding: 5,
                                    tickRotation: 0,
                                    legend: 'Sales',
                                    legendPosition: 'middle',
                                    legendOffset: -70,
                                    format: (n: number) => dinero({ amount: n, currency: 'USD' }).toFormat(),
                                }}
                                labelSkipWidth={12}
                                labelSkipHeight={12}
                                labelTextColor={{
                                    from: 'color',
                                    modifiers: [['darker', 1.6]],
                                }}
                            />
                        </Grid>
                    )}
                </Grid>
            </CardContent>
        </Card>
    );
};

export default ReportAccountTotalsByAgentGraph;
