import { Card, CardActions, CardContent, CardHeader, Grid, Typography } from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';
import { Theme } from '@mui/system';
import moment from 'moment';
import { useContext } from 'react';
import { Link, Location, useLocation } from 'react-router-dom';
import { SettingsContext } from '../../store/SettingsContext';
import { buildURL, getServiceStatusColor, getServiceStatusName } from '../../utils/common';

type SubscriberOverviewServicesProps = {
    subscriber: any;
};

const SubscriberOverviewServices = (props: SubscriberOverviewServicesProps) => {
    const { subscriber } = props;
    const location: Location = useLocation();
    const settingsContext = useContext(SettingsContext);
    const theme: Theme = useTheme();

    return (
        <>
            <Typography variant="h5" sx={{ marginBottom: theme.spacing(2) }}>
                Services
            </Typography>
            <Grid container spacing={1} sx={{ marginBottom: theme.spacing(4) }}>
                {settingsContext &&
                    settingsContext.services &&
                    Object.keys(settingsContext.services)
                        .filter((key: string) => settingsContext.services[key].visible === true)
                        .map((key: string) => (
                            <Grid item xs={12} md={6} lg={4} xl={2} key={key}>
                                <Card
                                    sx={{
                                        height: '100%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <CardHeader
                                        disableTypography
                                        avatar={settingsContext.services[key].icon(
                                            settingsContext.services[key].alwaysActive
                                                ? 'success'
                                                : getServiceStatusColor(subscriber, key, settingsContext.services[key].alwaysActive),
                                        )}
                                        title={
                                            <Typography variant="h6">
                                                {settingsContext.services[key].homeUrl ? (
                                                    <Link
                                                        to={`${buildURL(location.pathname, settingsContext.services[key].homeUrl || '')}`}
                                                        style={{ textDecoration: 'none', color: theme.palette.primary.main }}
                                                    >
                                                        {settingsContext.services[key].name.toUpperCase()}
                                                    </Link>
                                                ) : (
                                                    settingsContext.services[key].name.toUpperCase()
                                                )}
                                            </Typography>
                                        }
                                        subheader={
                                            <Typography variant="subtitle2">
                                                {settingsContext.services[key].alwaysActive
                                                    ? 'Shared Credit'
                                                    : subscriber[key] && subscriber[key].paidDate
                                                    ? `Paid: ${moment(subscriber[key].paidDate).format('L')}`
                                                    : 'Never Purchased'}
                                            </Typography>
                                        }
                                    />
                                    <CardContent sx={{ height: '100%' }}>
                                        <Typography variant="body2" color="text.secondary">
                                            {settingsContext.services[key].shortDescription}
                                        </Typography>
                                    </CardContent>
                                    <CardActions>
                                        <Typography
                                            variant="body1"
                                            color={
                                                settingsContext.services[key].alwaysActive
                                                    ? theme.palette.success.dark
                                                    : theme.palette[
                                                          getServiceStatusColor(
                                                              subscriber,
                                                              key,
                                                              settingsContext.services[key].alwaysActive,
                                                          ) || 'primary'
                                                      ].dark
                                            }
                                        >
                                            {getServiceStatusName(subscriber, key, settingsContext.services[key].alwaysActive)}
                                        </Typography>
                                    </CardActions>
                                </Card>
                            </Grid>
                        ))}
            </Grid>
        </>
    );
};

export default SubscriberOverviewServices;
