import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid } from '@mui/material';
import { CalendarPicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import SelectTimezone from '../../../forms/helpers/SelectTimezone';

interface Props {
    open: boolean;
    onClose: () => void;
    date: Date | null;
    setDate: (date: Date | null) => void;
    timezone: string;
    setTimezone: (timezone: string) => void;
}

const SextforceMetricsClaimsTodayDateSelectorDialog = (props: Props) => {
    const { open, onClose, date, setDate, timezone, setTimezone } = props;

    return (
        <Dialog open={open} onClose={onClose} maxWidth="xs">
            <DialogTitle>Change Claimed Today Date</DialogTitle>
            <DialogContent sx={{ pb: 0 }}>
                <Grid container flexGrow={1} spacing={2} alignItems="center">
                    <Grid item xs={12}>
                        <DialogContentText>
                            Set the day and the timezone to see how many fans claimed a promo on this day.
                        </DialogContentText>
                    </Grid>
                    <Grid item xs={12}>
                        <SelectTimezone fullWidth size={'small'} timezone={timezone} setTimezone={setTimezone} />
                    </Grid>
                    <Grid item xs={12}>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <CalendarPicker date={moment(date)} onChange={newValue => setDate(newValue ? newValue.toDate() : null)} />
                        </LocalizationProvider>
                    </Grid>
                </Grid>
            </DialogContent>

            <DialogActions
                sx={{
                    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
                }}
            >
                <Button
                    color="secondary"
                    size="medium"
                    onClick={() => {
                        onClose();
                    }}
                >
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default SextforceMetricsClaimsTodayDateSelectorDialog;
