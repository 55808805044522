import CheckIcon from '@mui/icons-material/Check';
import LoadingButton from '@mui/lab/LoadingButton';
import {
    Alert,
    Button,
    ButtonGroup,
    Checkbox,
    Divider,
    FormControl,
    Grid,
    LinearProgress,
    MenuItem,
    Select,
    Stack,
    TextField,
    Typography,
    useTheme,
} from '@mui/material';
import { LocalizationProvider, MobileDatePicker, MobileDateTimePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import Joi from 'joi';
import moment from 'moment';
import { useDialog } from 'muibox';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useAxios from '../../../../hooks/useAxios';
import {
    arraySextforceAutoRepostActionPreviousPost,
    arraySextforceAutoRepostActionPreviousPostDisplay,
    schemaSextforceAutoRepost,
    SextforceAutoRepost,
    SextforceAutoRepostActionPreviousPost,
} from '../../../../hooks/useSextforceAutoRepost';
import useSubscriberPosts from '../../../../hooks/useSubscriberPosts';
import { SettingsContext } from '../../../../store/SettingsContext';
import { UserContext } from '../../../../store/UserContext';
import { getPostIdFromPostUrl, handleHttpError } from '../../../../utils/common';
import StyledCard from '../../../common/StyledCard';
import StyledCardTitleBar from '../../../common/StyledCardTitleBar';
import SextforceAutoCampaignTipReplyPostPreview from '../../hostess/autoCampaignTipReply/SextforceAutoCampaignTipReplyPostPreview';

const momentPeriods: { [key: string]: string } = {
    minutes: 'Minutes',
    hours: 'Hours',
    days: 'Days',
};

type Props = {
    subscriber: any;
    refetchAutoReposts: () => void;
    handleCancel: () => void;
    setShowAddNewForm: (show: boolean) => void;
};

const SextforceAutoRepostNewRepost = (props: Props) => {
    const { subscriber, refetchAutoReposts, handleCancel, setShowAddNewForm } = props;
    const userContext = useContext(UserContext);
    const settingsContext = useContext(SettingsContext);
    const params = useParams();
    const axios = useAxios();
    const dialog = useDialog();
    const theme = useTheme();
    const [formData, setFormData] = useState<SextforceAutoRepost>({
        subscriberId: subscriber._id,
        name: '',
        description: '',
        active: true,
        originalPostUrl: '',
        type: 'link',
        repostEveryAmount: 1,
        repostEveryUnit: 'hours',
        repostWhenCampaignHitsTarget: undefined,
        pinPost: false,
        repostCount: 0,
        actionPreviousPost: 'none',
        nextRepostDate: new Date(),
        startDate: undefined,
        endDate: undefined,
        isPostTipCampaign: false,
        repostAfterTipCount: undefined,
        postId: undefined,
    });
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [postId, setPostId] = useState<number>(0);
    const [isPostValid, setIsPostValid] = useState<boolean>(false);
    const [isPostTipCampaign, setIsPostTipCampaign] = useState<boolean>(false);
    const { data: post, isLoading: isPostLoading } = useSubscriberPosts(postId);

    // Validate the form data
    const errors: Joi.ValidationErrorItem[] | null = schemaSextforceAutoRepost.validate(formData).error?.details || null;

    // Check if the campaign URL is a valid URL and extract the post ID
    useEffect(() => {
        if (!formData.originalPostUrl) {
            return;
        }

        const parsedPostId = getPostIdFromPostUrl(formData.originalPostUrl);

        if (parsedPostId !== 0 && postId !== parsedPostId) {
            // Set the campaign ID
            setPostId(parsedPostId);
        }
    }, [formData.originalPostUrl]);

    useEffect(() => {
        if (post && !isPostLoading && post.canEdit) {
            setIsPostValid(true);

            // Is post a tip campaign?
            const isPostTipCampaign = post.fundRaising && post.fundRaising.presets && post.fundRaising.presets.length > 0 ? true : false;

            setIsPostTipCampaign(isPostTipCampaign);

            // set the post ID
            setFormData({
                ...formData,
                postId: post.id,
                isPostTipCampaign,
            });
        } else {
            setIsPostValid(false);
            setIsPostTipCampaign(false);

            // reset the post ID
            setFormData({ ...formData, postId: undefined, isPostTipCampaign: false });
        }
    }, [post, isPostLoading]);

    const autoRepostAdd = async (autoRepost: SextforceAutoRepost) => {
        if (!userContext.jwtToken || !settingsContext.apiKey) {
            return;
        }

        setIsSubmitting(true);

        const data = await axios
            .post(`${settingsContext.routes.autoRepost.base}${params.userId}`, autoRepost)
            .then(async response => {
                setIsSubmitting(false);
                return response.data as SextforceAutoRepost;
            })
            .catch(error => {
                setIsSubmitting(false);
                handleHttpError(error, dialog);

                return null;
            });

        return data;
    };

    const handleSubmit = () => {
        autoRepostAdd(formData).then(data => {
            if (!data) {
                return;
            }

            refetchAutoReposts();

            dialog
                .alert({
                    title: 'Auto Repost',
                    message: 'Auto Repost added successfully.',
                    ok: {
                        text: 'OK',
                        variant: 'contained',
                        color: 'primary',
                    },
                })
                .then(() => {
                    // Reset the form
                    setFormData({
                        subscriberId: subscriber._id,
                        name: '',
                        description: '',
                        active: true,
                        originalPostUrl: '',
                        type: 'link',
                        repostEveryAmount: 1,
                        repostEveryUnit: 'hours',
                        repostWhenCampaignHitsTarget: undefined,
                        pinPost: false,
                        repostCount: 0,
                        actionPreviousPost: 'none',
                        nextRepostDate: new Date(),
                        startDate: undefined,
                        endDate: undefined,
                    });

                    setShowAddNewForm(false);
                });
        });
    };

    return (
        <>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <StyledCard noCard noBottomMargin>
                    <StyledCardTitleBar title="Add New Repost" theme={theme} />
                </StyledCard>
                <StyledCard>
                    <Grid container alignItems={'center'} justifyContent={'center'} spacing={1}>
                        <Grid item xs={12}>
                            Original Post's URL
                            <Typography variant="body2" color={'text.secondary'} marginTop={0.5}>
                                The URL to the post you want to repost. You can get this by clicking the share button on the post and
                                copying the link.
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Stack direction={'column'} spacing={0} sx={{ mb: 1 }}>
                                <FormControl fullWidth sx={{ mb: 0 }}>
                                    <TextField
                                        id="originalPostUrl"
                                        name="originalPostUrl"
                                        placeholder="https://onlyfans.com/1234567890/username"
                                        size="medium"
                                        value={formData.originalPostUrl}
                                        onChange={e => setFormData({ ...formData, originalPostUrl: e.target.value })}
                                        error={!!errors?.find(error => error.context?.key === 'originalPostUrl')}
                                        helperText={
                                            errors ? errors.find(error => error.context?.key === 'originalPostUrl')?.message : undefined
                                        }
                                        InputProps={{
                                            endAdornment: post && post.canEdit && <CheckIcon color={'success'} />,
                                        }}
                                        sx={{ mb: 0 }}
                                    />
                                </FormControl>
                                {isPostLoading && <LinearProgress color="primary" />}
                                {post && !post.canEdit && (
                                    <Typography variant="body2" color={'error'} marginTop={0.5}>
                                        This post is not valid or you do not have permission to repost it.
                                    </Typography>
                                )}
                            </Stack>
                        </Grid>
                        {post && !isPostLoading && isPostValid && formData.postId !== 0 && (
                            <Grid item xs={12}>
                                <SextforceAutoCampaignTipReplyPostPreview post={post} />
                            </Grid>
                        )}

                        <Grid item xs={12}>
                            Name
                            <Typography variant="body2" color={'text.secondary'} marginTop={0.5}>
                                Name of this promo or the person you are promoting.{' '}
                                <em>This will be shown in the Telegram notification to your colleagues.</em>
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <TextField
                                    id="name"
                                    name="name"
                                    size="medium"
                                    value={formData.name}
                                    onChange={e => setFormData({ ...formData, name: e.target.value })}
                                    error={!!errors?.find(error => error.context?.key === 'name')}
                                    helperText={errors ? errors.find(error => error.context?.key === 'name')?.message : undefined}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            Description
                            <Typography variant="body2" color={'text.secondary'} marginTop={0.5}>
                                Any description or notes you would like to leave for this promo. These will not appear in the Telegram
                                notification to your colleagues.
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <TextField
                                    id="description"
                                    name="description"
                                    size="medium"
                                    value={formData.description}
                                    onChange={e => setFormData({ ...formData, description: e.target.value })}
                                    error={!!errors?.find(error => error.context?.key === 'description')}
                                    helperText={errors ? errors.find(error => error.context?.key === 'description')?.message : undefined}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            Repost Type
                            <Typography variant="body2" color={'text.secondary'} marginTop={0.5}>
                                Repost a link to the original post or post the whole post again.
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <ButtonGroup fullWidth>
                                    <Button
                                        variant={formData.type === 'link' ? 'contained' : 'outlined'}
                                        color="primary"
                                        size="large"
                                        onClick={() => setFormData({ ...formData, type: 'link' })}
                                    >
                                        Share Link
                                    </Button>
                                    <Button
                                        variant={formData.type === 'post' ? 'contained' : 'outlined'}
                                        color="primary"
                                        size="large"
                                        onClick={() => setFormData({ ...formData, type: 'post' })}
                                    >
                                        Repeat Post
                                    </Button>
                                </ButtonGroup>
                            </FormControl>
                        </Grid>
                        {formData.type === 'post' && (
                            <Grid item xs={12}>
                                <Alert severity="warning" icon={false}>
                                    If your original post has a Tracking Link (Campaign) or a Free Trial Link, please be mindful that there
                                    is a limit on OnlyFans of 3 new posts of campaign or free trial links per day! It is best to use a
                                    repost link for these. Also, OnlyFans require that the #ad hashtag is used on all these posts.
                                </Alert>
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            Start Date/Time
                            <Typography variant="body2" color={'text.secondary'} marginTop={0.5}>
                                When should the first repost happen? Use this to stagger your reposts so they don't all happen at the same.{' '}
                                <em>Default: now.</em>
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container alignItems={'center'} justifyContent={'center'} spacing={1}>
                                <Grid item xs="auto">
                                    <Checkbox
                                        size="medium"
                                        checked={!!formData.startDate}
                                        onChange={e => {
                                            if (e.target.checked) {
                                                setFormData({ ...formData, startDate: new Date() });
                                            } else {
                                                setFormData({ ...formData, startDate: undefined });
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs>
                                    <MobileDateTimePicker
                                        disabled={!formData.startDate}
                                        value={formData.startDate}
                                        ampm={true}
                                        onChange={date => setFormData({ ...formData, startDate: moment(date).toDate() || undefined })}
                                        renderInput={props => <TextField fullWidth size="medium" {...props} />}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            Repost Every
                            <Typography variant="body2" color={'text.secondary'} marginTop={0.5}>
                                How often should the post be reposted? There is a daily limit of 400 posts on OnlyFans.
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Alert severity="warning" icon={false}>
                                Note: There is no point in reposting posts too frequently because nobody will get a chance to see them -
                                especially if you have many auto reposts setup at the same time. They will just be fighting each other for
                                attention. Make sure to look at the statistics of each repost here to see how many users viewed the post.
                            </Alert>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container alignItems={'center'} justifyContent={'center'} spacing={1}>
                                <Grid item xs>
                                    <FormControl fullWidth>
                                        <TextField
                                            id="repostEveryAmount"
                                            name="repostEveryAmount"
                                            size="medium"
                                            value={Number.isNaN(formData.repostEveryAmount) ? '' : formData.repostEveryAmount}
                                            onChange={e =>
                                                setFormData({
                                                    ...formData,
                                                    repostEveryAmount: Number.isNaN(Number.parseInt(e.target.value, 10))
                                                        ? 0
                                                        : Number.parseInt(e.target.value, 10),
                                                })
                                            }
                                            error={!!errors?.find(error => error.context?.key === 'repostEveryAmount')}
                                            helperText={
                                                errors
                                                    ? errors.find(error => error.context?.key === 'repostEveryAmount')?.message
                                                    : undefined
                                            }
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs="auto">
                                    <FormControl fullWidth>
                                        <Select
                                            value={formData.repostEveryUnit}
                                            onChange={e => {
                                                setFormData({ ...formData, repostEveryUnit: e.target.value });
                                            }}
                                            fullWidth
                                            size="medium"
                                            sx={{ mt: '4px' }}
                                        >
                                            {Object.keys(momentPeriods).map(item => (
                                                <MenuItem value={item} key={item}>
                                                    {momentPeriods[item]}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            Repost When Tip Campaign Hits Target
                            <Typography variant="body2" color={'text.secondary'} marginTop={1}>
                                Should the post be reposted when the tip campaign hits its target and not wait for the repost time?
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <ButtonGroup fullWidth disabled={!isPostTipCampaign || formData.type !== 'post'}>
                                    <Button
                                        variant={formData.repostWhenCampaignHitsTarget ? 'contained' : 'outlined'}
                                        color="primary"
                                        size="large"
                                        onClick={() => setFormData({ ...formData, repostWhenCampaignHitsTarget: true })}
                                    >
                                        Yes
                                    </Button>
                                    <Button
                                        variant={!formData.repostWhenCampaignHitsTarget ? 'contained' : 'outlined'}
                                        color="primary"
                                        size="large"
                                        onClick={() => setFormData({ ...formData, repostWhenCampaignHitsTarget: false })}
                                    >
                                        No
                                    </Button>
                                </ButtonGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            Until Date (optional)
                            <Typography variant="body2" color={'text.secondary'} marginTop={0.5}>
                                If you want the repost to stop at a specific date, enter it here. If you don't enter a date, the repost will
                                continue indefinitely.
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container alignItems={'center'} justifyContent={'center'} spacing={1}>
                                <Grid item xs="auto">
                                    <Checkbox
                                        size="medium"
                                        checked={!!formData.endDate}
                                        onChange={e => {
                                            if (e.target.checked) {
                                                setFormData({ ...formData, endDate: new Date() });
                                            } else {
                                                setFormData({ ...formData, endDate: undefined });
                                            }
                                        }}
                                    />
                                </Grid>
                                <Grid item xs>
                                    <MobileDatePicker
                                        disabled={!formData.endDate}
                                        value={formData.endDate}
                                        onChange={date => setFormData({ ...formData, endDate: date ? moment(date).toDate() : undefined })}
                                        renderInput={props => <TextField fullWidth size="medium" {...props} />}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            Pin Post
                            <Typography variant="body2" color={'text.secondary'} marginTop={0.5}>
                                Should the new post be pinned to the top of your feed?
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <ButtonGroup fullWidth>
                                    <Button
                                        variant={formData.pinPost ? 'contained' : 'outlined'}
                                        color="primary"
                                        size="large"
                                        onClick={() => setFormData({ ...formData, pinPost: true })}
                                    >
                                        Yes
                                    </Button>
                                    <Button
                                        variant={!formData.pinPost ? 'contained' : 'outlined'}
                                        color="primary"
                                        size="large"
                                        onClick={() => setFormData({ ...formData, pinPost: false })}
                                    >
                                        No
                                    </Button>
                                </ButtonGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            What To Do With The Previous Repost?
                            <Typography variant="body2" color={'text.secondary'} marginTop={0.5}>
                                What should be done with the previous post when the new one is posted? If you choose to delete or archive
                                the post then the post will be deleted or archived after the new post is posted.{' '}
                                <em>
                                    Note: if you've chosen to repost a link, the original post will not be touched. Only the following
                                    reposts will be affected by this setting.
                                </em>
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <Select
                                    id="actionPreviousPost"
                                    size="medium"
                                    value={formData.actionPreviousPost}
                                    onChange={e =>
                                        setFormData({
                                            ...formData,
                                            actionPreviousPost: e.target.value as SextforceAutoRepostActionPreviousPost,
                                        })
                                    }
                                >
                                    {arraySextforceAutoRepostActionPreviousPost.map(action => (
                                        <MenuItem
                                            key={action}
                                            value={action}
                                            sx={{
                                                color:
                                                    action === 'delete' && formData.type === 'post' ? theme.palette.error.main : 'inherit',
                                            }}
                                        >
                                            {`${
                                                arraySextforceAutoRepostActionPreviousPostDisplay[
                                                    arraySextforceAutoRepostActionPreviousPost.indexOf(action)
                                                ]
                                            }${action === 'delete' && formData.type === 'post' ? ' (Are you sure?)' : ''}`}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        {formData.actionPreviousPost === 'delete' && (
                            <Grid item xs={12}>
                                <Alert severity="warning" icon={false}>
                                    OnlyFans has a limit of how many posts can be deleted every day. The limit is an inconvenient 20% of the
                                    total number of posts. Keep that in mind when you set the frequency of your reposts.
                                </Alert>
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={8}>
                            <LoadingButton
                                variant="contained"
                                color="secondary"
                                fullWidth
                                size="large"
                                loading={isSubmitting}
                                disabled={!!errors || !isPostValid}
                                onClick={handleSubmit}
                            >
                                Add New Repost
                            </LoadingButton>
                        </Grid>
                        <Grid item xs={4}>
                            <Button variant="outlined" color="error" fullWidth size="large" onClick={handleCancel}>
                                Cancel
                            </Button>
                        </Grid>
                    </Grid>
                </StyledCard>
            </LocalizationProvider>
        </>
    );
};

export default SextforceAutoRepostNewRepost;
