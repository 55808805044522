import { useState } from 'react';
import { Theme } from '@mui/system';
import { FormControl, Grid, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select } from '@mui/material';
import { comparrisonConditions } from '../../../../utils/common';

type Props = {
    condition: string;
    setCondition: (condition: string) => void;
    amount: number;
    setAmount: (amount: number) => void;
    theme: Theme;
};

const MessageUsersFormExcludeSpent = (props: Props) => {
    const { theme, condition, setCondition, amount, setAmount } = props;
    const [amountString, setAmountString] = useState<string>(amount.toString());

    return (
        <>
            <Grid container spacing={1} flexGrow={0} alignItems="center" justifyContent="center">
                <Grid item xs={12}>
                    <Grid
                        container
                        spacing={1}
                        flexGrow={0}
                        alignItems="center"
                        justifyContent="center"
                        sx={{ marginBottom: theme.spacing(2) }}
                    >
                        <Grid item xs="auto">
                            <FormControl fullWidth>
                                <InputLabel id="condition">Condition</InputLabel>
                                <Select
                                    labelId="condition"
                                    defaultValue={condition}
                                    value={condition}
                                    onChange={e => {
                                        setCondition(e.target.value);
                                    }}
                                    fullWidth
                                    size="medium"
                                    label="Condition"
                                >
                                    {Object.keys(comparrisonConditions).map(item => (
                                        <MenuItem value={item} key={item}>
                                            {comparrisonConditions[item]}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs>
                            <FormControl>
                                <InputLabel htmlFor="amount">Amount</InputLabel>
                                <OutlinedInput
                                    id="amount"
                                    size="medium"
                                    inputProps={{ inputMode: 'text', pattern: /([0-9]+([.][0-9]*)?|[.][0-9]+)/ }}
                                    value={amountString}
                                    onChange={e => {
                                        e.preventDefault();

                                        if (/^([\d]*[,.]?[\d]{0,2})$/.test(e.target.value)) {
                                            const parsed = parseFloat(e.target.value.replaceAll(',', '.'));

                                            if (isNaN(parsed) || parsed === 0) {
                                                setAmount(0);
                                                setAmountString('0');
                                            } else {
                                                setAmount(parsed);
                                                setAmountString(e.target.value.replace(/^0+/, ''));
                                            }
                                        }
                                    }}
                                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                    label="Amount"
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default MessageUsersFormExcludeSpent;
