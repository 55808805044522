import { useEffect } from 'react';
import { Theme } from '@mui/system';
import { Alert, AlertColor, Button, Card, CardContent, Grid } from '@mui/material';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { Link } from 'react-router-dom';
import { Stripe } from '@stripe/stripe-js';
import { useStripe } from '@stripe/react-stripe-js';

type SharedCreditCheckoutMessageProps = {
    subscriber: any;
    message: string | null | undefined;
    messageType: AlertColor;
    setMessage: (message: string | null | undefined) => void;
    setMessageType: (type: AlertColor) => void;
    theme: Theme;
};

const SharedCreditCheckoutMessage = (props: SharedCreditCheckoutMessageProps) => {
    const { subscriber, message, messageType, setMessage, setMessageType, theme } = props;
    const stripe: Stripe | null = useStripe();

    useEffect(() => {
        if (!stripe) {
            return;
        }

        const clientSecret = new URLSearchParams(window.location.search).get('payment_intent_client_secret');

        if (!clientSecret) {
            return;
        }

        stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
            if (!paymentIntent) {
                setMessage('Something went wrong.');

                return;
            }

            switch (paymentIntent.status) {
                case 'succeeded':
                    setMessage('Payment succeeded!');
                    setMessageType('success');
                    break;
                case 'processing':
                    setMessage('Your payment is processing.');
                    setMessageType('info');
                    break;
                case 'requires_payment_method':
                    setMessage('Your payment was not successful, please try again.');
                    setMessageType('warning');
                    break;
                default:
                    setMessage('Something went wrong.');
                    setMessageType('error');
                    break;
            }
        });
    }, [setMessage, setMessageType, stripe]);

    return (
        <>
            {message && (
                <Grid container spacing={1} flexGrow={0} alignItems="center" justifyContent="center">
                    <Grid item xs={12} md={6}>
                        <Card>
                            <CardContent>
                                <Grid container spacing={1} flexGrow={0} alignItems="center" justifyContent="center">
                                    <Grid item xs={12}>
                                        {/* Show any error or success messages */}
                                        <Alert severity={messageType} sx={{ marginBottom: theme.spacing(2) }}>
                                            {message}
                                        </Alert>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size="large"
                                            sx={{ width: '100%' }}
                                            component={Link}
                                            to={`/subscribers/${subscriber._id}`}
                                        >
                                            <NavigateBeforeIcon /> Back to Dashboard
                                        </Button>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            )}
        </>
    );
};

export default SharedCreditCheckoutMessage;
