import axios from 'axios';
import { useDialog } from 'muibox';
import { useContext, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { SettingsContext } from '../store/SettingsContext';
import { UserContext } from '../store/UserContext';
import { handleHttpError } from '../utils/common';

const useDashboardAccount = (enabled: boolean) => {
    const userContext = useContext(UserContext);
    const settingsContext = useContext(SettingsContext);
    const queryClient = useQueryClient();
    const dialog = useDialog();

    const [dashboardAccountIsSaveing, setSashboardAccountIsSaveing] = useState(false);

    // Fetch Follow-Back Overview
    const fetchDashboardAccount = async (): Promise<any> => {
        if (userContext.jwtToken && settingsContext.apiKey && userContext.user.username) {
            const query: string = `${settingsContext.routes.account.dashboard}${userContext.user.username}`;

            return axios
                .get(query, {
                    headers: {
                        Authorization: userContext.jwtToken,
                        apiKey: settingsContext.apiKey,
                    },
                })
                .then(async response => response.data)
                .catch(error => {
                    console.error(error);
                    handleHttpError(error, dialog);
                });
        }
    };

    const {
        data: dashboardAccount,
        isLoading: dashboardAccountLoading,
        // refetch: refetchDashboardAccount,
    } = useQuery(
        ['dashboardAccount', userContext.jwtToken],
        () => {
            return fetchDashboardAccount();
        },
        {
            refetchOnWindowFocus: false,
            staleTime: 0,
            enabled: enabled && userContext.user.username ? true : false,
        },
    );

    const dashboardAccountSave = async (data: any, callbackOnSuccess: () => void) => {
        setSashboardAccountIsSaveing(true);

        // Save data
        const query: string = `${settingsContext.routes.account.dashboard}${userContext.user.username}`;

        await axios
            .post(query, data, {
                headers: {
                    Authorization: userContext.jwtToken,
                    apiKey: settingsContext.apiKey,
                    'Content-Type': 'application/json',
                },
            })
            .then(async response => response.data)
            .then(data => {
                if (data) {
                    queryClient.setQueryData(['dashboardAccount'], data);

                    callbackOnSuccess();
                }

                setSashboardAccountIsSaveing(false);
            })
            .catch(error => {
                console.error(error);
                handleHttpError(error, dialog).then(() => {
                    setSashboardAccountIsSaveing(false);
                });
            });
    };

    return { dashboardAccount, dashboardAccountLoading, dashboardAccountSave, dashboardAccountIsSaveing };
};

export default useDashboardAccount;
