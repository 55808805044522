import { Alert, Button, Card, CardContent, Divider, Typography } from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';
import { Theme } from '@mui/system';
import { Link, useParams } from 'react-router-dom';
import BackNavigationButton from '../../../components/common/BackNavigationButton';
import PageContainer from '../../../components/common/PageContainter';
import LogoMessageUsers from '../../../components/icons/LogoMessageUsers';
import MessageUsersServiceDescription from '../../../components/services/messageUsers/MessageUsersServiceDescription';

const MessageUsersWelcome = () => {
    const theme: Theme = useTheme();
    const params = useParams();

    return (
        <PageContainer>
            <Typography variant="h6" textAlign="center" sx={{ marginBottom: theme.spacing(2) }}>
                <LogoMessageUsers
                    style={{
                        width: '100%',
                        maxWidth: '200px',
                    }}
                />
            </Typography>

            <Typography variant="h5" sx={{ marginBottom: theme.spacing(2) }}>
                <BackNavigationButton /> Message Users Welcome
            </Typography>

            <Card variant="elevation" sx={{ marginBottom: theme.spacing(2) }}>
                <CardContent>
                    <MessageUsersServiceDescription theme={theme} />
                </CardContent>
            </Card>

            <Divider sx={{ marginBottom: theme.spacing(3) }} />

            <Card variant="elevation" sx={{ marginBottom: theme.spacing(2) }}>
                <CardContent>
                    <Typography variant="h5" sx={{ marginBottom: theme.spacing(2) }}>
                        This Service vs. Mass DM
                    </Typography>

                    <Typography variant="body1" sx={{ marginBottom: theme.spacing(2) }}>
                        This service has several advantages.
                    </Typography>

                    <Typography variant="body1" sx={{ marginBottom: theme.spacing(2) }}>
                        <ol>
                            <li>It can message the users who are online right now.</li>
                            <li>
                                It's not the conventional Mass DM from the website. The system goes to each user individually and messages
                                them separately with a normal message. This ends up in their Priority inbox. It looks to them like you
                                actually messaged only them specifically - making them feel important.
                            </li>
                            <li>
                                The Exclussion options (who to not send to) are very powerful, allowing to, among other things, no to
                                double-send to someone.
                            </li>
                            <li>You can include the fan's username in the message.</li>
                            <li>The service can unsend those messages.</li>
                            <li>
                                If you have the optional{' '}
                                <Link
                                    to={`/subscribers/${'userId' in params && params.userId}/services/sextforce`}
                                    style={{ textDecoration: 'none', fontWeight: 'bold', color: theme.palette.primary.main }}
                                >
                                    Sextforce
                                </Link>{' '}
                                service, it can show you exactly how much money the users who received the message had spent after receiving
                                the message.
                            </li>
                        </ol>
                    </Typography>

                    <Typography variant="body1" sx={{ marginBottom: theme.spacing(2) }}>
                        If you combine all these features, and add a strategy to it... you get MINDBLOWING returns.
                    </Typography>
                </CardContent>
            </Card>

            <Card variant="elevation" sx={{ marginBottom: theme.spacing(2) }}>
                <CardContent>
                    <Typography variant="h5" sx={{ marginBottom: theme.spacing(2) }}>
                        Strategy Advice
                    </Typography>

                    <Typography variant="body1" sx={{ marginBottom: theme.spacing(2) }}>
                        Tests have shown that it’s most effective both for cost and engagement when it's used as a conversation started with
                        those who are online right now.
                    </Typography>

                    <Typography variant="body1" sx={{ marginBottom: theme.spacing(2) }}>
                        It's important to understand that you are not fighting here for the actual sale, but rather for their attention!
                        With fans subscribing to God knows how many free accounts, their inefficient inboxes are full of spam that they've
                        missed since the last time they were online.
                    </Typography>

                    <Typography variant="body1" sx={{ marginBottom: theme.spacing(2) }}>
                        But all of a sudden, a message arrives from you and it shows up at the top of their inbox just as they came online!
                        Sending a clickbait message to grab their attention is all it takes. Something short and sweet. Something that they
                        can’t help but reply to - so you or the sexters can take over and sell them something.
                    </Typography>

                    <Typography variant="body1" sx={{ marginBottom: theme.spacing(2) }}>
                        For example, “<span style={{ fontStyle: 'italic' }}>You won’t believe what I’m wearing right now!</span>”
                    </Typography>
                </CardContent>
            </Card>

            <Card variant="elevation" sx={{ marginBottom: theme.spacing(2) }}>
                <CardContent>
                    <Typography variant="h5" sx={{ marginBottom: theme.spacing(2) }}>
                        Get Started Quickly
                    </Typography>

                    <Typography variant="body1" sx={{ marginBottom: theme.spacing(2) }}>
                        It's generally self explanitory. It's only a matter of strategy.
                    </Typography>

                    <Typography variant="body1" sx={{ marginBottom: theme.spacing(2) }}>
                        The system can send one message at a time. So while one is going out to all the target users, you can only schedule
                        the next messages. Even if one is scheduled for two minutes from now, it won't start until the current session
                        finishes.
                    </Typography>

                    <Typography variant="body1" sx={{ marginBottom: theme.spacing(2) }}>
                        I generally recommend mostly only sending these messages to those who are online right now.
                    </Typography>

                    <Typography variant="body1" sx={{ marginBottom: theme.spacing(2) }}>
                        What's important is who to exclude from the message. The people online on the website change all the time, but some
                        stay a bit lonegr than others. If you are sending the same message again and again through the day, you should
                        exclude those whom the system had messaged in the previous X hours so it doesn't double send to the same people. And
                        of course, you are welcome to exclude any lists of people you don't like.
                    </Typography>

                    <Typography variant="body1" sx={{ marginBottom: theme.spacing(2) }}>
                        Following the strategy mentioned above, I would avoid attaching a photo or video. I think it's spammy. But it's your
                        call. Similarly, I would avoid adding each user's username. For people with the default u23409583745987 usernames,
                        it looks like an obvious bot and not a genuine message.
                    </Typography>
                </CardContent>
            </Card>

            <Card variant="elevation" sx={{ marginBottom: theme.spacing(2) }}>
                <CardContent>
                    <Typography variant="h5" sx={{ marginBottom: theme.spacing(2) }}>
                        How Can I Unsend a Message
                    </Typography>

                    <Typography variant="body1" sx={{ marginBottom: theme.spacing(2) }}>
                        Please head to the{' '}
                        <Link
                            to={`/subscribers/${'userId' in params && params.userId}/services/messageUsers/history`}
                            style={{ textDecoration: 'none', fontWeight: 'bold', color: theme.palette.primary.main }}
                        >
                            History
                        </Link>{' '}
                        page, find the session of the message you would like to unsend in the list, click on the row, and click on{' '}
                        <Button
                            variant="contained"
                            color="warning"
                            disableFocusRipple
                            disableRipple
                            disableTouchRipple
                            disableElevation
                            sx={{ cursor: 'text' }}
                        >
                            Unsend Message
                        </Button>
                    </Typography>

                    <Typography variant="body1" sx={{ marginBottom: theme.spacing(2) }}>
                        <Alert severity="info">Please note that only messages that were send less than 24 hours ago can be unsent!</Alert>
                    </Typography>
                </CardContent>
            </Card>

            <Card variant="elevation" sx={{ marginBottom: theme.spacing(2) }}>
                <CardContent>
                    <Typography variant="h5" sx={{ marginBottom: theme.spacing(2) }}>
                        Auto "Welcome" Message
                    </Typography>

                    <Typography variant="body1" sx={{ marginBottom: theme.spacing(2) }}>
                        Send an automatic welcome message when a fan subscribes to you, resubscribes to you, or when a fan consumes a free
                        trial. This is a powerful feature, allowing to start a personal conversation or ask for a fan's name and interests,
                        or inroduce yourself. Similarly, you can send a freeby or a thank you to a resubscribing fan.
                    </Typography>

                    <Typography variant="body1">
                        Like with Message Users, you can use the user's username in the message! Also, include any media and even lock the
                        message with a price (PPV).
                    </Typography>
                </CardContent>
            </Card>
        </PageContainer>
    );
};

export default MessageUsersWelcome;
