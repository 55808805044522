import { Checkbox, FormControlLabel, Grid, TextField } from '@mui/material';

type Props = {
    saveTemplate: boolean;
    setSaveTemplate: (saveTemplate: boolean) => void;
    saveTemplateName: string;
    setSaveTemplateName: (templateName: string) => void;
    disabled?: boolean;
};

const MessageUsersFormEditorTemplateSave = (props: Props) => {
    const { saveTemplate, setSaveTemplate, saveTemplateName: templateName, setSaveTemplateName: setTemplateName, disabled } = props;

    return (
        <Grid container spacing={2} alignItems={'center'}>
            <Grid item xs="auto">
                <FormControlLabel
                    control={<Checkbox size="medium" checked={saveTemplate} onChange={() => setSaveTemplate(!saveTemplate)} />}
                    label="Save as Template"
                    disabled={disabled}
                />
            </Grid>
            <Grid item xs>
                <TextField
                    label="Template Name"
                    value={templateName}
                    onChange={e => setTemplateName(e.currentTarget.value)}
                    disabled={!saveTemplate || disabled}
                    fullWidth
                    error={saveTemplate && templateName.trim().length === 0}
                />
            </Grid>
        </Grid>
    );
};

export default MessageUsersFormEditorTemplateSave;
