import dinero from 'dinero.js';
import { useContext } from 'react';
import { Theme } from '@mui/system';
import { Grid } from '@mui/material';
import { SettingsContext } from '../../../../store/SettingsContext';
import { d2f } from '../../../../utils/common';
import OverviewCard from '../../../common/OverviewCard';

type SextforceOverviewStatsProps = {
    overview: any;
    loading: boolean;
    theme: Theme;
};

const SextforceOvewviewStats = (props: SextforceOverviewStatsProps) => {
    const { overview, loading, theme } = props;
    const settingsContext = useContext(SettingsContext);

    return (
        <Grid container flexGrow={0} justifyContent="center" spacing={1} sx={{ paddingBottom: theme.spacing(2) }}>
            <Grid item xs>
                <OverviewCard
                    title="Agents"
                    value={overview && overview.agents && overview.agents.count}
                    maxValue={settingsContext.services.sextforce.totalAllowedAgents}
                    loading={loading}
                    theme={theme}
                />
            </Grid>
            <Grid item xs>
                <OverviewCard
                    title="Transactions Processed"
                    value={
                        overview &&
                        overview.transactions &&
                        overview.transactions.count.toLocaleString(undefined, { minimumFractionDigits: 0 })
                    }
                    loading={loading}
                    theme={theme}
                />
            </Grid>
            <Grid item xs>
                <OverviewCard
                    title="Transactions Total"
                    value={
                        overview &&
                        overview.transactions &&
                        dinero({
                            amount: Math.round(d2f(overview.transactions.total) * 100),
                            currency: 'USD',
                        }).toFormat()
                    }
                    loading={loading}
                    theme={theme}
                />
            </Grid>
        </Grid>
    );
};

export default SextforceOvewviewStats;
