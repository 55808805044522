import { Container } from '@mui/material';
import AdminUsersList from '../../components/admin/AdminUsersList';

const AdminSubscribers = () => {
    return (
        <Container maxWidth={'xl'} sx={{ pt: 4 }}>
            <AdminUsersList />
        </Container>
    );
};

export default AdminSubscribers;
