export namespace OnlyFansLists {
    export interface Response {
        hasMore: boolean;
        list: List[];
    }

    export interface List {
        id: Type | number;
        type: Type;
        name: string;
        usersCount: number;
        postsCount: number;
        canUpdate: boolean;
        canDelete: boolean;
        canManageUsers: boolean;
        canAddUsers: boolean;
        canPinnedToFeed: boolean;
        isPinnedToFeed: boolean;
        canPinnedToChat: boolean;
        isPinnedToChat: boolean;
        order: Order;
        direction: Direction;
        users: User[];
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        customOrderUsersIds: any[];
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        posts: any[];
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        sortList: any[] | SortListClass;
    }

    export enum Direction {
        Asc = 'asc',
        Desc = 'desc',
    }

    export enum Type {
        Bookmarks = 'bookmarks',
        CloseFriends = 'close_friends',
        Custom = 'custom',
        Fans = 'fans',
        Following = 'following',
        Friends = 'friends',
        Muted = 'muted',
        Recent = 'recent',
        Tagged = 'tagged',
        RebillOn = 'rebill_on',
        RebillOff = 'rebill_off',
    }

    export enum Order {
        Default = 'default',
    }

    export interface SortListClass {
        expired?: Expired;
        order?: Order;
        direction?: Direction;
    }

    export interface Expired {
        order: string;
        direction: Direction;
    }

    export interface User {
        id: number;
        _view: View;
    }

    export enum View {
        T = 't',
    }
}

export namespace OnlyFansVaultMedias {
    export interface VaultMedias {
        list: List[];
        hasMore: boolean;
    }

    export interface List {
        id: number;
        type: MediaType;
        convertedToVideo: boolean;
        canView: boolean;
        counters: Counters;
        hasError: boolean;
        createdAt: Date;
        info: Info;
        source: Source;
        squarePreview: string;
        full: string;
        preview: string;
        thumb: string;
        listStates: ListState[];
        mentionedUsers: MentionedUser[];
        files: Files;
        videoSources: { [key: string]: null };
        postId?: number;
    }

    export interface Counters {
        likesCount: number;
        tipsSumm: number;
    }

    export interface Files {
        preview: FilesPreview;
    }

    export interface FilesPreview {
        url: string;
    }

    export interface Info {
        source: Source;
        preview: InfoPreview;
    }

    export interface InfoPreview {
        width: number;
        height: number;
        size: number;
    }

    export interface Source {
        source: string;
        width: number;
        height: number;
        size: number;
        duration: number;
    }

    export interface ListState {
        id: number;
        type: ListStateType;
        name: string;
        hasMedia: boolean;
        canAddMedia: boolean;
    }

    export enum ListStateType {
        Custom = 'custom',
        Messages = 'messages',
        Posts = 'posts',
        Stories = 'stories',
        Streams = 'streams',
    }

    export enum MediaType {
        Photo = 'photo',
        Video = 'video',
        Audio = 'audio',
        GIF = 'gif',
    }

    export interface MentionedUser {
        view: string;
        avatar: string;
        avatarThumbs: AvatarThumbs;
        header: string;
        headerSize: HeaderSize;
        headerThumbs: HeaderThumbs;
        id: number;
        name: string;
        username: string;
        hasNotViewedStory: boolean;
        isVerified: boolean;
        hasStream: boolean;
        hasStories: boolean;
        isRestricted: boolean;
        hasPromotion: boolean;
        isFree: boolean;
    }

    export interface AvatarThumbs {
        c50: string;
        c144: string;
    }

    export interface HeaderSize {
        width: number;
        height: number;
    }

    export interface HeaderThumbs {
        w480: string;
        w760: string;
    }

    export interface DeleteResponse {
        success: boolean;
    }

    export interface RestructuredResponseItem {
        id: number;
        canView: boolean;
        hasError: boolean;
        createdAt: Date;
        thumb: string;
        cachedThumb: string | null;
        src?: string;
        duration?: number;
        type: string;
        counters: Counters;
        mentionedUsers?: MentionedUser[];
    }

    export enum SortOrder {
        Asc = 'asc',
        Desc = 'desc',
    }

    export enum SortType {
        Recent = 'recent',
        MostLiked = 'most-liked',
        HighestTips = 'highest-tips',
    }
}
