import { Button, Grid, Skeleton, Theme, Typography } from '@mui/material';
import { EditorState } from 'draft-js';
import { useDialog } from 'muibox';
import { useRef, useState } from 'react';
import useMessageUsersCurrentSessions from '../../../../hooks/useMessageUsersCurrentSessions';
import { OnlyFansSubscriptionsUserType } from '../../../../hooks/useSubscriber';
import { OnlyFansLists, OnlyFansVaultMedias } from '../../../../types/onlyFansTypes';
import MessageUsersScheduledList from '../scheduled/MessageUsersScheduledList';
import MessageUsersAutoUnsendTimer from './MessageUsersAutoUnsendTimer';
import MessageUsersCurrentSession from './MessageUsersCurrentSession';
import MessageUsersDelayBetweenMessages from './MessageUsersDelayBetweenMessages';
import MessageUsersFormAddMedias from './MessageUsersFormAddMedias';
import MessageUsersFormEditor from './MessageUsersFormEditor';
import MessageUsersFormExclude from './MessageUsersFormExclude';
import MessageUsersFormTargetList from './MessageUsersFormTargetList';
import MessageUsersFormTargetRecentSpenders from './MessageUsersFormTargetRecentSpenders';
import MessageUsersFormTargetUsers from './MessageUsersFormTargetUsers';
import MessageUsersPriceLock from './MessageUsersPriceLock';
import MessageUsersSchedule from './MessageUsersSchedule';
import MessageUsersTagCreators from './MessageUsersTagCreators';

type MessageUsersFormProps = {
    subscriber: any;
    session?: any;
    unitCost: number;
    loading: boolean;
    lists: OnlyFansLists.List[] | void | undefined;
    listsLoading: boolean;
    refetchLists: () => void;

    scheduleMessage: boolean;
    setScheduleMessage: (scheduleMessage: boolean) => void;
    scheduleMessageDate: Date | null;
    setScheduleMessageDate: (date: Date | null) => void;
    repeatMessage: boolean;
    setRepeatMessage: (repeatMessage: boolean) => void;
    repeatEveryAmount: number;
    setRepeatEveryAmount: (amount: number) => void;
    repeatEveryUnit: string;
    setRepeatEveryUnit: (unit: string) => void;
    repeatEndDate: Date | null;
    setRepeatEndDate: (date: Date | null) => void;

    targetUsers: OnlyFansSubscriptionsUserType;
    setTargetUsers: (targetUsers: OnlyFansSubscriptionsUserType) => void;
    targetListId: number | OnlyFansLists.Type | undefined;
    setTargetListId: (targetList: number | OnlyFansLists.Type | undefined) => void;
    setTargetListName: (targetList: string | undefined) => void;
    targetRecentSpendersDateFrom: Date | null;
    setTargetRecentSpendersDateFrom: (date: Date | null) => void;
    targetRecentSpendersDateTo: Date | null;
    setTargetRecentSpendersDateTo: (date: Date | null) => void;

    excludeLimitMessage: boolean;
    setExcludeLimitMessage: (excludeLimitMessage: boolean) => void;
    excludeLimitMessageCount: number;
    setExcludeLimitMessageCount: (count: number) => void;

    excludeUsersList: boolean;
    setExcludeUsersList: (excludeUsersList: boolean) => void;
    excludedLists: any[];
    setExcludedLists: (excludedLists: any[]) => void;

    excludeUsersSpent: boolean;
    setExcludeUsersSpent: (excludeUsersSpent: boolean) => void;
    excludeSpentCondition: string;
    setExcludeSpentCondition: (condition: string) => void;
    excludeSpentAmount: number;
    setExcludeSpentAmount: (amount: number) => void;

    excludeUsersSpentPeriod: boolean;
    setExcludeUsersSpentPeriod: (excludeUsersSpentPeriod: boolean) => void;
    excludeSpentPeriod: string;
    setExcludeSpentPeriod: (period: string) => void;
    excludeSpentPeriodAmount: number;
    setExcludeSpentPeriodAmount: (amount: number) => void;

    excludeUsersMessagedIn: boolean;
    setExcludeUsersMessagedIn: (excludeUsersMessagedIn: boolean) => void;
    excludeUsersMessagedInPeriod: string;
    setExcludeUsersMessagedInPeriod: (period: string) => void;
    excludeUsersMessagedInPeriodAmount: number;
    setExcludeUsersMessagedInPeriodAmount: (amount: number) => void;

    excludeFans: boolean;
    setExcludeFans: (excludeFans: boolean) => void;

    includeMedia: boolean;
    setIncludeMedia: (includeMedia: boolean) => void;
    medias: OnlyFansVaultMedias.RestructuredResponseItem[];
    setMedias: React.Dispatch<React.SetStateAction<OnlyFansVaultMedias.RestructuredResponseItem[]>>;
    thumbnails: any[];
    mediasPreviews: number[];
    setMediasPreviews: React.Dispatch<React.SetStateAction<number[]>>;

    setThumbnails: React.Dispatch<React.SetStateAction<any[]>>;
    getThumbnail: (thumbId: number, thumbUrl: string) => void;
    getAudio: (url: string) => Promise<Blob | null>;

    tagCreators: boolean;
    setTagCreators: (tagCreators: boolean) => void;
    taggedCreators: { id: number; name: string }[];
    setTaggedCreators: React.Dispatch<React.SetStateAction<{ id: number; name: string }[]>>;
    taggedReleaseForms: { id: number; name: string }[];
    setTaggedReleaseForms: React.Dispatch<React.SetStateAction<{ id: number; name: string }[]>>;

    lockMessage: boolean;
    setLockMessage: (lockMessage: boolean) => void;
    lockMessagePrice: number;
    setLockMessagePrice: (price: number) => void;
    lockedText: boolean;
    setLockedText: (lockedText: boolean) => void;

    autoUnsendTimer: boolean;
    setAutoUnsendTimer: (autoUnsendMessage: boolean) => void;
    autoUnsendTimerPeriodAmount: number;
    setAutoUnsendTimerPeriodAmount: (autoUnsendTimerPeriodAmount: number) => void;

    delayBetweenMessages: number;
    setDelayBetweenMessages: (delayBetweenMessages: number) => void;

    message: string;
    setMessage: (message: string) => void;
    editorState: EditorState;
    setEditorState: React.Dispatch<React.SetStateAction<EditorState>>;
    messageHasRestrictedWords: boolean;
    messageFoundRestrictedWords: string[];
    checkForRestrictedWords: (text: string) => void;
    usernameReplacement: string;
    setUsernameReplacement: (usernameReplacement: string) => void;
    saveTemplate: boolean;
    setSaveTemplate: (saveTemplate: boolean) => void;
    saveTemplateName: string;
    setSaveTemplateName: (templateName: string) => void;

    isSendingMessage: boolean;
    sendMessage: () => void;
    resetForm: () => void;
    theme: Theme;
};

const MessageUsersForm = (props: MessageUsersFormProps) => {
    const {
        subscriber,
        session,
        unitCost,
        lists,
        listsLoading,
        refetchLists,
        scheduleMessage,
        setScheduleMessage,
        scheduleMessageDate,
        setScheduleMessageDate,
        repeatMessage,
        setRepeatMessage,
        repeatEveryAmount,
        setRepeatEveryAmount,
        repeatEveryUnit,
        setRepeatEveryUnit,
        repeatEndDate,
        setRepeatEndDate,
        targetUsers,
        setTargetUsers,
        targetListId,
        setTargetListId,
        setTargetListName,
        targetRecentSpendersDateFrom,
        setTargetRecentSpendersDateFrom,
        targetRecentSpendersDateTo,
        setTargetRecentSpendersDateTo,
        excludeLimitMessage,
        setExcludeLimitMessage,
        excludeLimitMessageCount,
        setExcludeLimitMessageCount,
        excludeUsersList,
        setExcludeUsersList,
        excludedLists,
        setExcludedLists,
        excludeUsersSpent,
        setExcludeUsersSpent,
        excludeSpentCondition,
        setExcludeSpentCondition,
        excludeSpentAmount,
        setExcludeSpentAmount,
        excludeUsersSpentPeriod,
        setExcludeUsersSpentPeriod,
        excludeSpentPeriod,
        setExcludeSpentPeriod,
        excludeSpentPeriodAmount,
        setExcludeSpentPeriodAmount,
        excludeUsersMessagedIn,
        setExcludeUsersMessagedIn,
        excludeUsersMessagedInPeriod,
        setExcludeUsersMessagedInPeriod,
        excludeUsersMessagedInPeriodAmount,
        setExcludeUsersMessagedInPeriodAmount,
        excludeFans,
        setExcludeFans,
        loading,
        includeMedia,
        setIncludeMedia,
        medias,
        setMedias,
        thumbnails,
        mediasPreviews,
        setMediasPreviews,
        setThumbnails,
        getThumbnail,
        getAudio,
        tagCreators,
        setTagCreators,
        taggedCreators,
        setTaggedCreators,
        taggedReleaseForms,
        setTaggedReleaseForms,
        lockMessage,
        setLockMessage,
        lockMessagePrice,
        setLockMessagePrice,
        lockedText,
        setLockedText,
        autoUnsendTimer,
        setAutoUnsendTimer,
        autoUnsendTimerPeriodAmount,
        setAutoUnsendTimerPeriodAmount,
        delayBetweenMessages,
        setDelayBetweenMessages,
        message,
        setMessage,
        editorState,
        setEditorState,
        messageHasRestrictedWords,
        messageFoundRestrictedWords,
        checkForRestrictedWords,
        usernameReplacement,
        setUsernameReplacement,
        saveTemplate,
        setSaveTemplate,
        saveTemplateName,
        setSaveTemplateName,
        isSendingMessage,
        sendMessage,
        resetForm,
        theme,
    } = props;
    const dialog = useDialog();

    const sendMeessageHeaderRef = useRef<HTMLDivElement>(null);

    const { isMessageUsersSendSessionRunning, isMessageUsersSendJobInQueue } = useMessageUsersCurrentSessions();

    const [vaultAlbum, setVaultAlbum] = useState<number>(-1);
    const [vaultAlbumName, setVaultAlbumName] = useState<string | undefined>();

    return (
        <>
            <Grid container spacing={1} flexGrow={0} alignItems="center" justifyContent="center" sx={{ marginBottom: theme.spacing(2) }}>
                <Grid item xs={12} md={6}>
                    {(isMessageUsersSendSessionRunning || isMessageUsersSendJobInQueue) && <MessageUsersCurrentSession />}

                    <MessageUsersScheduledList />

                    <Typography
                        ref={sendMeessageHeaderRef}
                        variant="h5"
                        sx={{ marginTop: theme.spacing(2), marginBottom: theme.spacing(2) }}
                    >
                        Send Message
                    </Typography>

                    {loading ? (
                        <Skeleton />
                    ) : (
                        <>
                            <MessageUsersFormTargetUsers
                                targetUsers={targetUsers}
                                setTargetUsers={setTargetUsers}
                                unitCost={unitCost}
                                session={session}
                                theme={theme}
                            />

                            {targetUsers === 'lists' && (
                                <MessageUsersFormTargetList
                                    lists={lists}
                                    listsLoading={listsLoading}
                                    listId={targetListId}
                                    setListId={setTargetListId}
                                    setListName={setTargetListName}
                                    refetchLists={refetchLists}
                                    session={session}
                                    theme={theme}
                                />
                            )}

                            {targetUsers === 'recentSpenders' && (
                                <MessageUsersFormTargetRecentSpenders
                                    usersType={targetUsers}
                                    targetRecentSpendersDateFrom={targetRecentSpendersDateFrom}
                                    setTargetRecentSpendersDateFrom={setTargetRecentSpendersDateFrom}
                                    targetRecentSpendersDateTo={targetRecentSpendersDateTo}
                                    setTargetRecentSpendersDateTo={setTargetRecentSpendersDateTo}
                                    session={session}
                                />
                            )}

                            <MessageUsersFormExclude
                                lists={lists}
                                listsLoading={listsLoading}
                                refetchLists={refetchLists}
                                targetUsers={targetUsers}
                                excludeLimitMessage={excludeLimitMessage}
                                setExcludeLimitMessage={setExcludeLimitMessage}
                                excludeLimitMessageCount={excludeLimitMessageCount}
                                setExcludeLimitMessageCount={setExcludeLimitMessageCount}
                                excludeUsersList={excludeUsersList}
                                setExcludeUsersList={setExcludeUsersList}
                                excludedLists={excludedLists}
                                setExcludedLists={setExcludedLists}
                                excludeUsersSpent={excludeUsersSpent}
                                setExcludeUsersSpent={setExcludeUsersSpent}
                                excludeSpentCondition={excludeSpentCondition}
                                setExcludeSpentCondition={setExcludeSpentCondition}
                                excludeSpentAmount={excludeSpentAmount}
                                setExcludeSpentAmount={setExcludeSpentAmount}
                                excludeUsersSpentPeriod={excludeUsersSpentPeriod}
                                setExcludeUsersSpentPeriod={setExcludeUsersSpentPeriod}
                                excludeSepntPeriod={excludeSpentPeriod}
                                setExcludeSpentPeriod={setExcludeSpentPeriod}
                                excludeSpentPeriodAmount={excludeSpentPeriodAmount}
                                setExcludeSpentPeriodAmount={setExcludeSpentPeriodAmount}
                                excludeUsersMessagedIn={excludeUsersMessagedIn}
                                setExcludeUsersMessagedIn={setExcludeUsersMessagedIn}
                                excludeUsersMessagedInPeriod={excludeUsersMessagedInPeriod}
                                setExcludeUsersMessagedInPeriod={setExcludeUsersMessagedInPeriod}
                                excludeUsersMessagedInPeriodAmount={excludeUsersMessagedInPeriodAmount}
                                setExcludeUsersMessagedInPeriodAmount={setExcludeUsersMessagedInPeriodAmount}
                                excludeFans={excludeFans}
                                setExcludeFans={setExcludeFans}
                                session={session}
                                theme={theme}
                            />

                            <MessageUsersFormAddMedias
                                serviceName="messageUsers"
                                includeMedia={includeMedia}
                                setIncludeMedia={setIncludeMedia}
                                vaultAlbum={vaultAlbum}
                                setVaultAlbum={setVaultAlbum}
                                vaultAlbumName={vaultAlbumName}
                                setVaultAlbumName={setVaultAlbumName}
                                medias={medias}
                                setMedias={setMedias}
                                mediasPreviews={mediasPreviews}
                                setMediasPreviews={setMediasPreviews}
                                thumbnails={thumbnails}
                                getThumbnail={getThumbnail}
                                getAudio={getAudio}
                                tagCreators={tagCreators}
                                setTagCreators={setTagCreators}
                                taggedCreators={taggedCreators}
                                setTaggedCreators={setTaggedCreators}
                                taggedReleaseForms={taggedReleaseForms}
                                setTaggedReleaseForms={setTaggedReleaseForms}
                                session={session}
                            />

                            {includeMedia && (
                                <MessageUsersTagCreators
                                    subscriber={subscriber}
                                    tagCreators={tagCreators}
                                    setTagCreators={setTagCreators}
                                    taggedCreators={taggedCreators}
                                    setTaggedCreators={setTaggedCreators}
                                    taggedReleaseForms={taggedReleaseForms}
                                    setTaggedReleaseForms={setTaggedReleaseForms}
                                    session={session}
                                    theme={theme}
                                />
                            )}

                            <MessageUsersPriceLock
                                lockMessage={lockMessage}
                                setLockMessage={setLockMessage}
                                lockMessagePrice={lockMessagePrice}
                                setLockMessagePrice={setLockMessagePrice}
                                lockedText={lockedText}
                                setLockedText={setLockedText}
                                medias={medias}
                                thumbnails={thumbnails}
                                mediasPreviews={mediasPreviews}
                                setMediasPreviews={setMediasPreviews}
                                getAudio={getAudio}
                                session={session}
                                theme={theme}
                            />

                            <MessageUsersAutoUnsendTimer
                                autoUnsendTimer={autoUnsendTimer}
                                setAutoUnsendTimer={setAutoUnsendTimer}
                                autoUnsendTimerPeriodAmount={autoUnsendTimerPeriodAmount}
                                setAutoUnsendTimerPeriodAmount={setAutoUnsendTimerPeriodAmount}
                                session={session}
                                theme={theme}
                            />

                            <MessageUsersDelayBetweenMessages
                                delayBetweenMessages={delayBetweenMessages}
                                setDelayBetweenMessages={setDelayBetweenMessages}
                                session={session}
                                theme={theme}
                            />

                            <MessageUsersFormEditor
                                subscriber={subscriber}
                                message={message}
                                setMessage={setMessage}
                                editorState={editorState}
                                setEditorState={setEditorState}
                                messageHasRestrictedWords={messageHasRestrictedWords}
                                messageFoundRestrictedWords={messageFoundRestrictedWords}
                                checkForRestrictedWords={checkForRestrictedWords}
                                usernameReplacement={usernameReplacement}
                                setUsernameReplacement={setUsernameReplacement}
                                taggedCreators={taggedCreators}
                                taggedReleaseForms={taggedReleaseForms}
                                lockMessage={lockMessage}
                                lockMessagePrice={lockMessagePrice}
                                lockedText={lockedText}
                                saveTemplate={saveTemplate}
                                setSaveTemplate={setSaveTemplate}
                                saveTemplateName={saveTemplateName}
                                setSaveTemplateName={setSaveTemplateName}
                                session={session}
                                theme={theme}
                            />

                            <MessageUsersSchedule
                                isSessionOrJobRunning={isMessageUsersSendSessionRunning || isMessageUsersSendJobInQueue}
                                scheduleMessage={scheduleMessage}
                                setScheduleMessage={setScheduleMessage}
                                dateTime={scheduleMessageDate}
                                setDateTime={setScheduleMessageDate}
                                repeatMessage={repeatMessage}
                                setRepeatMessage={setRepeatMessage}
                                repeatEveryAmount={repeatEveryAmount}
                                setRepeatEveryAmount={setRepeatEveryAmount}
                                repeatEveryUnit={repeatEveryUnit}
                                setRepeatEveryUnit={setRepeatEveryUnit}
                                repeatEndDate={repeatEndDate}
                                setRepeatEndDate={setRepeatEndDate}
                                session={session}
                                theme={theme}
                                key={isSendingMessage || isMessageUsersSendJobInQueue ? 'yes' : 'no'}
                            />

                            <Grid container spacing={1} flexGrow={0} alignItems="center" justifyContent="center" sx={{ marginBottom: 1 }}>
                                <Grid item xs={8}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                        fullWidth
                                        disabled={
                                            (scheduleMessage && !scheduleMessageDate) ||
                                            (repeatMessage && repeatEveryAmount === 0) ||
                                            (excludeUsersList && (!excludedLists || excludedLists.length === 0)) ||
                                            (excludeLimitMessage && excludeLimitMessageCount === 0) ||
                                            (excludeUsersSpentPeriod && excludeSpentPeriodAmount === 0) ||
                                            (tagCreators && taggedCreators.length === 0 && taggedReleaseForms.length === 0) ||
                                            (lockMessage && lockMessagePrice < 3) ||
                                            (lockMessage && !lockedText && medias.length === 0) ||
                                            (autoUnsendTimer && autoUnsendTimerPeriodAmount === 0) ||
                                            (message.trim().length === 0 && medias.length === 0 && lockMessage) ||
                                            (message.trim().length === 0 && !lockMessage && medias.length === 0) ||
                                            messageHasRestrictedWords ||
                                            (saveTemplate && (!saveTemplateName || saveTemplateName.trim().length === 0)) ||
                                            (includeMedia && (medias.length === 0 || medias.length > 40)) ||
                                            (targetUsers === 'lists' && !targetListId) ||
                                            (targetUsers === 'recentSpenders' &&
                                                (!targetRecentSpendersDateFrom || !targetRecentSpendersDateTo)) ||
                                            isSendingMessage
                                        }
                                        onClick={() => {
                                            dialog
                                                .confirm({
                                                    message: `Are you sure you want to ${
                                                        scheduleMessage ? 'schedule' : 'send'
                                                    } this message?`,
                                                    title: 'Confirm',
                                                    ok: { text: scheduleMessage ? 'Schedule' : 'Send!', variant: 'contained' },
                                                    cancel: { text: 'Cancel' },
                                                })
                                                .then(async () => {
                                                    // Send
                                                    await sendMessage();
                                                })
                                                .catch(() => {});
                                        }}
                                    >
                                        {scheduleMessage ? 'Schedule Message' : 'Send Message'}
                                    </Button>
                                </Grid>
                                <Grid item xs={4}>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        size="large"
                                        fullWidth
                                        onClick={() => {
                                            dialog
                                                .confirm({
                                                    message: `Are you sure you want to reset the whole form? Everything you've entered will be cleared.`,
                                                    title: 'Confirm',
                                                    ok: {
                                                        text: 'Reset',
                                                        variant: 'contained',
                                                        color: 'secondary',
                                                    },
                                                    cancel: { text: 'Cancel' },
                                                })
                                                .then(() => {
                                                    // Send
                                                    resetForm();

                                                    sendMeessageHeaderRef.current?.scrollIntoView({ behavior: 'smooth' });
                                                })
                                                .catch(() => {});
                                        }}
                                    >
                                        Reset
                                    </Button>
                                </Grid>
                            </Grid>
                        </>
                    )}
                </Grid>
            </Grid>
        </>
    );
};

export default MessageUsersForm;
