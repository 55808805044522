import { SvgIcon, SxProps, Theme } from '@mui/material';

type IconOnlyFansProps = {
    fontSize?: 'small' | 'inherit' | 'large' | 'medium' | undefined;
    sx?: SxProps<Theme>;
};

const IconOnlyFansDone = (props: IconOnlyFansProps) => {
    const { fontSize, sx } = props;

    return (
        <SvgIcon fontSize={fontSize} sx={sx}>
            <path
                xmlns="http://www.w3.org/2000/svg"
                d="M9 19.42l-5.71-5.71A1 1 0 0 1 3 13a1 1 0 0 1 1-1 1 1 0 0 1 .71.29L9 16.59l10.29-10.3A1 1 0 0 1 20 6a1 1 0 0 1 1 1 1 1 0 0 1-.29.71z"
            />
        </SvgIcon>
    );
};

export default IconOnlyFansDone;
