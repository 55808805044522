import { useDialog } from 'muibox';
import { useContext } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { SettingsContext } from '../store/SettingsContext';
import { UserContext } from '../store/UserContext';
import { handleHttpError } from '../utils/common';
import useAxios from './useAxios';
import { SextforceAutoRepost, SextforceAutoRepostActionPreviousPost } from './useSextforceAutoRepost';

export interface SextforceAutoRepostLog {
    _id: string;
    autoRepostId: string;
    postId: number;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    stats?: any;
    actionTaken?: SextforceAutoRepostActionPreviousPost;
    dateActionTaken?: Date;
}

const useSextforceAutoRepostLogs = (autoRepost: SextforceAutoRepost) => {
    const userContext = useContext(UserContext);
    const settingsContext = useContext(SettingsContext);
    const axios = useAxios();
    const dialog = useDialog();
    const params = useParams();

    // Fetch online users count vs. total sales report
    const fetchData = async (autoRepost: SextforceAutoRepost): Promise<SextforceAutoRepostLog[]> => {
        if (!userContext.jwtToken || !settingsContext.apiKey) {
            return [];
        }

        const query: string = `${settingsContext.routes.autoRepost.base}${params.userId}/${autoRepost._id}/logs`;

        const data = await axios
            .get(query)
            .then(async response => response.data as SextforceAutoRepostLog[])
            .catch(error => {
                handleHttpError(error, dialog);

                return [];
            });

        return data;
    };

    return useQuery(
        ['autoRepostLogs', params.userId, userContext.jwtToken, autoRepost._id],
        () => {
            return fetchData(autoRepost);
        },
        {
            refetchOnWindowFocus: false,
            enabled: userContext.jwtToken && params.userId && settingsContext.apiKey ? true : false,
        },
    );
};

export default useSextforceAutoRepostLogs;
