import { IconButton, Tooltip } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

const BackNavigationButton = (props: { url?: string }) => {
    const { url } = props;
    const navigate = useNavigate();

    return (
        <Tooltip title="Navigate Back">
            <IconButton
                onClick={() => {
                    if (url) {
                        navigate(url);
                    } else {
                        navigate(-1);
                    }
                }}
            >
                <NavigateBeforeIcon />
            </IconButton>
        </Tooltip>
    );
};

export default BackNavigationButton;
