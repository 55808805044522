import moment from 'moment';
import { Theme } from '@mui/system';
import useTheme from '@mui/material/styles/useTheme';
import { Card, CardContent, Grid, Skeleton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { targetUsersName } from '../../../../utils/common';

type FollowBackReportSessionsListProps = {
    data: any[];
    isFetching: boolean;
    timezone: string;
};

const FollowBackReportSessionsList = (props: FollowBackReportSessionsListProps) => {
    const { data, isFetching, timezone } = props;
    const theme: Theme = useTheme();

    return (
        <Card variant="elevation" sx={{ marginBottom: theme.spacing(4) }}>
            <CardContent>
                <Typography variant="h6" sx={{ marginBottom: theme.spacing(2) }}>
                    Sessions
                </Typography>

                <Typography variant="body1" sx={{ marginBottom: theme.spacing(2) }}>
                    This report shows all the session that ran during the searched period. Each row represents a single session and it shows
                    the target users it was scanning, the number of users that were followed in that session and the last status the session
                    ended with.
                </Typography>

                <Grid container flexGrow={1}>
                    {isFetching ? (
                        <Skeleton />
                    ) : (
                        <>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell width={150} sx={{ fontWeight: 'bold' }}>
                                            Date
                                        </TableCell>
                                        <TableCell width={200} sx={{ fontWeight: 'bold' }}>
                                            Users
                                        </TableCell>
                                        <TableCell width={140} sx={{ fontWeight: 'bold' }} align="right">
                                            Total Followed
                                        </TableCell>
                                        <TableCell sx={{ fontWeight: 'bold', display: { xs: 'none', sm: 'table-cell' } }}>
                                            Last Status
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data.map(row => (
                                        <TableRow key={row._id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                            <TableCell component="th" scope="row">
                                                {moment(row.startedAt).tz(timezone).format('L LT')}
                                            </TableCell>
                                            <TableCell>
                                                {row.target === 'lists' ? 'Follow Me First' : targetUsersName(row.targetUsers, row.filter)}
                                            </TableCell>
                                            <TableCell align="right">{row.followed ? row.followed.toLocaleString() : 0}</TableCell>
                                            <TableCell
                                                sx={{
                                                    whiteSpace: 'nowrap',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    display: { xs: 'none', sm: 'table-cell' },
                                                }}
                                            >
                                                {row.status}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </>
                    )}
                </Grid>
            </CardContent>
        </Card>
    );
};

export default FollowBackReportSessionsList;
