import { TextField } from '@mui/material';
import { getNested } from '../utils/common';

type FormikTextFieldProps = {
    formik: any;
    fieldName: string;
    label: string;
    multiline?: boolean;
    rows?: number;
};

const FormikTextField = (props: FormikTextFieldProps) => {
    const { formik, fieldName, label, multiline = false, rows = 4 } = props;

    return (
        <TextField
            fullWidth
            multiline={multiline}
            rows={rows}
            id={fieldName}
            name={fieldName}
            label={label}
            value={getNested(formik.values, fieldName, '.') || ''}
            onChange={formik.handleChange}
            error={formik.touched[fieldName] && Boolean(formik.errors[fieldName])}
            helperText={formik.touched[fieldName] && formik.errors[fieldName]}
        />
    );
};

export default FormikTextField;
