import { faFileCsv, faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    AppBar,
    Box,
    Card,
    CardContent,
    Checkbox,
    FormControlLabel,
    Grid,
    IconButton,
    Tab,
    Tabs,
    Theme,
    Tooltip,
    Typography,
    useTheme,
} from '@mui/material';
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro';
import Dinero from 'dinero.js';
import { parse as parseCsv } from 'json2csv';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import * as XLSX from 'xlsx';
import { SextforceReportAccountTotalsByFraction, SextforceReportAccountTotalsByFractionByDay } from '../../../../types/transactions';
import { d2f, trendColor, trendIcon } from '../../../../utils/common';

/**
 * Transforms the transactions data rows from the server to a format ready for export to CSV or Excel
 * @param rows Transactions rows
 * @param timezone Timezone string to convert the transaction time to
 * @returns Transformed data for export
 */
const transformTransactionsForExport = (
    mainData: SextforceReportAccountTotalsByFraction[],
    compareData: SextforceReportAccountTotalsByFraction[] | null,
    uniqueDatesInData: string[],
    mainByDateData: SextforceReportAccountTotalsByFractionByDay[],
    compareByDateData: SextforceReportAccountTotalsByFractionByDay[] | null,
    compare: boolean,
): {
    headers: {
        label: string;
        value: string;
    }[];
    rows: {
        tab: string;
        data: any[];
    }[];
} => {
    const headers = [
        {
            label: 'Agent Name',
            value: 'agentName',
        },
        {
            label: 'Agent Type',
            value: 'agentType',
        },
        {
            label: 'Agent Role',
            value: 'agentRole',
        },
        {
            label: 'Fractions',
            value: 'fraction',
        },
        {
            label: 'Total Amount ($USD)',
            value: 'totalAmount',
        },
        {
            label: 'Total Fee ($USD)',
            value: 'totalFee',
        },
        {
            label: 'Total Net ($USD)',
            value: 'totalNet',
        },
        {
            label: 'Total Commission ($USD)',
            value: 'totalCommission',
        },
    ];

    if (compare) {
        headers.push({
            label: 'Total Amount ($USD) - Compare',
            value: 'totalAmountCompare',
        });
        headers.push({
            label: 'Total Fee ($USD) - Compare',
            value: 'totalFeeCompare',
        });
        headers.push({
            label: 'Total Net ($USD) - Compare',
            value: 'totalNetCompare',
        });
        headers.push({
            label: 'Total Commission ($USD) - Compare',
            value: 'totalCommissionCompare',
        });
    }

    const fractionDateFromCompare = (
        c: SextforceReportAccountTotalsByFraction[] | SextforceReportAccountTotalsByFractionByDay[] | null,
        fraction: number,
    ) => {
        if (!c) {
            return {};
        }

        const compareAgent = c.find(row => d2f(row.fraction) === fraction);

        if (!compareAgent) {
            return {};
        }

        return {
            totalAmountCompare: Dinero({ amount: compareAgent.totalAmountCents, currency: 'USD' }).toFormat(),
            totalFeeCompare: Dinero({ amount: compareAgent.totalFeeCents, currency: 'USD' }).toFormat(),
            totalNetCompare: Dinero({
                amount: compareAgent.totalNetCents,
                currency: 'USD',
            }).toFormat(),
            totalCommissionCompare: Dinero({
                amount: compareAgent.totalCommissionCents,
                currency: 'USD',
            }).toFormat(),
        };
    };

    const data: { tab: string; data: any[] }[] = [];

    const totalData: any[] = [];

    mainData.forEach(row => {
        totalData.push({
            agentName: row.agent ? row.agent.name : 'Unknown',
            fraction: d2f(row.fraction).toLocaleString(undefined, { minimumFractionDigits: 2 }),
            agentType: row.agent && row.agent.type ? row.agent.type : '',
            agentRole: row.agent && row.agent.role ? row.agent.role : '',
            totalAmount: Dinero({ amount: row.totalAmountCents, currency: 'USD' }).toFormat(),
            totalFee: Dinero({ amount: row.totalFeeCents, currency: 'USD' }).toFormat(),
            totalNet: Dinero({ amount: row.totalNetCents, currency: 'USD' }).toFormat(),
            totalCommission: Dinero({ amount: row.totalCommissionCents, currency: 'USD' }).toFormat(),
            ...(compare && fractionDateFromCompare(compareData, d2f(row.fraction))),
        });
    });

    data.push({ tab: 'Total', data: totalData });

    uniqueDatesInData.forEach(date => {
        const dataByDate: any[] = [];
        const mainByDate = mainByDateData.filter(t => (t.date ? moment(t.date).format() === date : false));

        if (mainByDate.length === 0) {
            return;
        }

        // sort by agent name
        mainByDate.sort((a, b) => (a.agent && b.agent ? a.agent.name.localeCompare(b.agent.name) : 0));

        mainByDate.forEach(row => {
            dataByDate.push({
                agentName: row.agent ? row.agent.name : 'Unknown',
                fraction: d2f(row.fraction).toLocaleString(undefined, { minimumFractionDigits: 2 }),
                agentType: row.agent && row.agent.type ? row.agent.type : '',
                agentRole: row.agent && row.agent.role ? row.agent.role : '',
                totalAmount: Dinero({ amount: row.totalAmountCents, currency: 'USD' }).toFormat(),
                totalFee: Dinero({ amount: row.totalFeeCents, currency: 'USD' }).toFormat(),
                totalNet: Dinero({ amount: row.totalNetCents, currency: 'USD' }).toFormat(),
                totalCommission: Dinero({ amount: row.totalCommissionCents, currency: 'USD' }).toFormat(),
                ...(compare && fractionDateFromCompare(compareByDateData, d2f(row.fraction))),
            });
        });

        data.push({ tab: moment(date).format('L'), data: dataByDate });
    });

    return { headers, rows: data };
};

/**
 * Generate DataGrid rows for the transactions
 * @param transactionsMain Main transactions data
 * @param transactionsCompare Compare transactions data
 * @param compare Compare data flag
 * @param theme Material-UI theme
 * @returns DataGrid rows
 */
const generateDataRows = (transactionsMain: any[], transactionsCompare: any[], compare: boolean, theme: Theme) => {
    return transactionsMain.map(transactionMain => {
        let compareTotals = {};

        const totalAmount = Dinero({ amount: transactionMain.totalAmountCents, currency: 'USD' });
        const totalFee = Dinero({ amount: transactionMain.totalFeeCents, currency: 'USD' });
        const totalNet = Dinero({ amount: transactionMain.totalNetCents, currency: 'USD' });
        const totalCommission = Dinero({ amount: transactionMain.totalCommissionCents, currency: 'USD' });
        // const totalCommission = calculateAgentCommision(transactionMain);

        if (compare) {
            // Find a compare transaction with the same fraction
            const transactionCompare = transactionsCompare.find(transaction => d2f(transaction.fraction) === d2f(transactionMain.fraction));

            if (transactionCompare) {
                compareTotals = {
                    totalAmount: Dinero({ amount: transactionCompare.totalAmountCents, currency: 'USD' }),
                    totalAmountCents: transactionCompare.totalAmountCents,
                    totalAmountColor: trendColor(
                        totalAmount,
                        Dinero({ amount: transactionCompare.totalAmountCents, currency: 'USD' }),
                        compare,
                        theme,
                    ),
                    totalFee: Dinero({ amount: transactionCompare.totalFeeCents, currency: 'USD' }),
                    totalFeeCents: transactionCompare.totalFeeCents,
                    totalFeeColor: trendColor(
                        totalFee,
                        Dinero({ amount: transactionCompare.totalFeeCents, currency: 'USD' }),
                        compare,
                        theme,
                    ),
                    totalNet: Dinero({ amount: transactionCompare.totalNetCents, currency: 'USD' }),
                    totalNetCents: transactionCompare.totalNetCents,
                    totalNetColor: trendColor(
                        totalNet,
                        Dinero({ amount: transactionCompare.totalNetCents, currency: 'USD' }),
                        compare,
                        theme,
                    ),
                    totalCommission: Dinero({ amount: transactionCompare.totalCommissionCents, currency: 'USD' }),
                    totalCommissionCents: transactionCompare.totalCommissionCents,
                    totalCommissionColor: trendColor(
                        totalCommission,
                        Dinero({ amount: transactionCompare.totalCommissionCents, currency: 'USD' }),
                        compare,
                        theme,
                    ),
                };
            }
        }

        // Construct a DataGrid row
        const row: any = {
            id: d2f(transactionMain.fraction) * 100,
            agentId: transactionMain.agent ? transactionMain.agent._id : null,
            agentName: transactionMain.agent ? transactionMain.agent.name : d2f(transactionMain.fraction) === 0 ? 'Anonymous' : 'Unknown',
            agentType: transactionMain.agent && transactionMain.agent.type ? transactionMain.agent.type : '',
            agentRole: transactionMain.agent && transactionMain.agent.role ? transactionMain.agent.role : '',
            fraction: d2f(transactionMain.fraction),
            totalAmount,
            totalAmountCents: transactionMain.totalAmountCents,
            totalFee,
            totalFeeCents: transactionMain.totalFeeCents,
            totalNet,
            totalNetCents: transactionMain.totalNetCents,
            totalCommission,
            compare,
            compareTotals,
        };

        return row;
    });
};

/**
 * Generate DataGrid rows for the transactions by day
 * @param transactionsMain Main transactions data
 * @param transactionsCompare Compare transactions data
 * @param uniqueFractionsInData Unique fractions in the data
 * @param compare Compare data flag
 * @param theme Material-UI theme
 * @returns DataGrid rows
 */
const generateByDayDataRows = (
    transactionsMain: any[],
    transactionsCompare: any[],
    uniqueFractionsInData: number[],
    compare: boolean,
    theme: Theme,
) => {
    const data: any[] = [];

    uniqueFractionsInData.forEach(fraction => {
        const transactionMain = transactionsMain.find(t => d2f(t.fraction) === fraction);
        const transactionCompare = transactionsCompare.find(t => d2f(t.fraction) === fraction);

        const totalAmount = Dinero({ amount: transactionMain?.totalAmountCents || 0, currency: 'USD' });
        const totalFee = Dinero({ amount: transactionMain?.totalFeeCents || 0, currency: 'USD' });
        const totalNet = Dinero({ amount: transactionMain?.totalNetCents || 0, currency: 'USD' });
        const totalCommission = Dinero({ amount: transactionMain?.totalCommissionCents || 0, currency: 'USD' });
        // const totalCommission = calculateAgentCommision(transactionMain);

        let compareTotals = {};

        if (compare) {
            if (transactionCompare) {
                compareTotals = {
                    totalAmount: Dinero({ amount: transactionCompare.totalAmountCents, currency: 'USD' }),
                    totalAmountCents: transactionCompare?.totalAmountCents,
                    totalAmountColor: trendColor(
                        totalAmount,
                        Dinero({ amount: transactionCompare.totalAmountCents, currency: 'USD' }),
                        compare,
                        theme,
                    ),
                    totalFee: Dinero({ amount: transactionCompare.totalFeeCents, currency: 'USD' }),
                    totalFeeCents: transactionCompare.totalFeeCents,
                    totalFeeColor: trendColor(
                        totalFee,
                        Dinero({ amount: transactionCompare.totalFeeCents, currency: 'USD' }),
                        compare,
                        theme,
                    ),
                    totalNet: Dinero({ amount: transactionCompare.totalNetCents, currency: 'USD' }),
                    totalNetCents: transactionCompare.totalNetCents,
                    totalNetColor: trendColor(
                        totalNet,
                        Dinero({ amount: transactionCompare.totalNetCents, currency: 'USD' }),
                        compare,
                        theme,
                    ),
                    totalCommission: Dinero({ amount: transactionCompare.totalCommissionCents, currency: 'USD' }),
                    totalCommissionCents: transactionCompare.totalCommissionCents,
                    totalCommissionColor: trendColor(
                        totalCommission,
                        Dinero({ amount: transactionCompare.totalCommissionCents, currency: 'USD' }),
                        compare,
                        theme,
                    ),
                };
            } else {
                compareTotals = {
                    totalAmount: Dinero({ amount: 0, currency: 'USD' }),
                    totalAmountCents: 0,
                    totalAmountColor: theme.palette.grey[500],
                    totalFee: Dinero({ amount: 0, currency: 'USD' }),
                    totalFeeCents: 0,
                    totalFeeColor: theme.palette.grey[500],
                    totalNet: Dinero({ amount: 0, currency: 'USD' }),
                    totalNetCents: 0,
                    totalNetColor: theme.palette.grey[500],
                    totalCommission: Dinero({ amount: 0, currency: 'USD' }),
                    totalCommissionCents: 0,
                    totalCommissionColor: theme.palette.grey[500],
                };
            }
        }

        data.push({
            id: fraction * 100,
            agentId: transactionMain?.agent ? transactionMain.agent._id : null,
            agentName: transactionMain?.agent ? transactionMain.agent.name : fraction === 0 ? 'Anonymous' : 'Unknown',
            agentType: transactionMain?.agent && transactionMain.agent.type ? transactionMain.agent.type : '',
            agentRole: transactionMain?.agent && transactionMain.agent.role ? transactionMain.agent.role : '',
            fraction,
            totalAmount,
            totalAmountCents: transactionMain?.totalAmountCents || 0,
            totalFee,
            totalFeeCents: transactionMain?.totalFeeCents || 0,
            totalNet,
            totalNetCents: transactionMain?.totalNetCents || 0,
            totalCommission,
            compare,
            compareTotals,
        });
    });

    return data;

    // return transactionsMain.map(transactionMain => {
    //     let compareTotals = {};

    //     const totalAmount = Dinero({ amount: transactionMain.totalAmountCents, currency: 'USD' });
    //     const totalFee = Dinero({ amount: transactionMain.totalFeeCents, currency: 'USD' });
    //     const totalNet = Dinero({ amount: transactionMain.totalNetCents, currency: 'USD' });
    //     const totalCommission = Dinero({ amount: transactionMain.totalCommissionCents, currency: 'USD' });
    //     // const totalCommission = calculateAgentCommision(transactionMain);

    //     if (compare) {
    //         // Find a compare transaction with the same fraction
    //         const transactionCompare = transactionsCompare.find(transaction => d2f(transaction.fraction) === d2f(transactionMain.fraction));

    //         if (transactionCompare) {
    //             compareTotals = {
    //                 totalAmount: Dinero({ amount: transactionCompare.totalAmountCents, currency: 'USD' }),
    //                 totalAmountCents: transactionCompare.totalAmountCents,
    //                 totalAmountColor: trendColor(
    //                     totalAmount,
    //                     Dinero({ amount: transactionCompare.totalAmountCents, currency: 'USD' }),
    //                     compare,
    //                     theme,
    //                 ),
    //                 totalFee: Dinero({ amount: transactionCompare.totalFeeCents, currency: 'USD' }),
    //                 totalFeeCents: transactionCompare.totalFeeCents,
    //                 totalFeeColor: trendColor(
    //                     totalFee,
    //                     Dinero({ amount: transactionCompare.totalFeeCents, currency: 'USD' }),
    //                     compare,
    //                     theme,
    //                 ),
    //                 totalNet: Dinero({ amount: transactionCompare.totalNetCents, currency: 'USD' }),
    //                 totalNetCents: transactionCompare.totalNetCents,
    //                 totalNetColor: trendColor(
    //                     totalNet,
    //                     Dinero({ amount: transactionCompare.totalNetCents, currency: 'USD' }),
    //                     compare,
    //                     theme,
    //                 ),
    //                 totalCommission: Dinero({ amount: transactionCompare.totalCommissionCents, currency: 'USD' }),
    //                 totalCommissionCents: transactionCompare.totalCommissionCents,
    //                 totalCommissionColor: trendColor(
    //                     totalCommission,
    //                     Dinero({ amount: transactionCompare.totalCommissionCents, currency: 'USD' }),
    //                     compare,
    //                     theme,
    //                 ),
    //             };
    //         }
    //     }

    //     // Construct a DataGrid row
    //     const row: any = {
    //         id: d2f(transactionMain.fraction) * 100,
    //         agentId: transactionMain.agent ? transactionMain.agent._id : null,
    //         agentName: transactionMain.agent ? transactionMain.agent.name : 'Unknown',
    //         agentType: transactionMain.agent && transactionMain.agent.type ? transactionMain.agent.type : '',
    //         agentRole: transactionMain.agent && transactionMain.agent.role ? transactionMain.agent.role : '',
    //         fraction: d2f(transactionMain.fraction),
    //         totalAmount,
    //         totalAmountCents: transactionMain.totalAmountCents,
    //         totalFee,
    //         totalFeeCents: transactionMain.totalFeeCents,
    //         totalNet,
    //         totalNetCents: transactionMain.totalNetCents,
    //         totalCommission,
    //         compare,
    //         compareTotals,
    //     };

    //     return row;
    // });
};

const renderCell = (mainPrice: Dinero.Dinero, comparePrice: Dinero.Dinero, compare: boolean, color: string) => {
    return (
        <>
            <Grid container flexGrow={1} alignItems="center">
                <Grid item xs={1}>
                    {compare && <FontAwesomeIcon icon={trendIcon(mainPrice, comparePrice, compare)} color={color} />}
                </Grid>
                <Grid item xs>
                    <Typography variant="body1" align="right" color={color} sx={{ fontFamily: 'monospace' }}>
                        {mainPrice.toFormat()}
                    </Typography>
                    {compare && comparePrice && (
                        <Typography variant="body1" align="right" sx={{ fontFamily: 'monospace' }}>
                            {comparePrice.toFormat()}
                        </Typography>
                    )}
                </Grid>
            </Grid>
        </>
    );
};

const columns = (subscriberId: string, searchParams: string, theme: Theme): GridColDef[] => [
    {
        field: 'agentName',
        headerName: 'Agent',
        minWidth: 150,
        flex: 1,
        renderCell: params => (
            <Link
                to={`/subscribers/${subscriberId}/services/sextforce/transactions?${searchParams}&agent=${params.row.agentId}&runReport=true`}
                style={{ color: theme.palette.primary.main, textDecoration: 'none' }}
            >
                <strong>{params.value}</strong>
            </Link>
        ),
    },
    { field: 'agentType', headerName: 'Type', flex: 0.5, minWidth: 100 },
    { field: 'agentRole', headerName: 'Role', flex: 0.5, minWidth: 100 },
    {
        field: 'fraction',
        headerName: 'Fraction',
        width: 100,
        minWidth: 100,
        align: 'right',
        headerAlign: 'right',
        renderCell: params => (
            <div style={{ fontFamily: 'monospace' }}>{params.row.fraction.toLocaleString(undefined, { minimumFractionDigits: 2 })}</div>
        ),
    },
    {
        field: 'totalAmountCents',
        headerName: 'Gross',
        width: 150,
        align: 'right',
        headerAlign: 'right',
        renderCell: params =>
            renderCell(
                params.row.totalAmount,
                params.row.compareTotals.totalAmount,
                params.row.compare,
                params.row.compareTotals.totalAmountColor,
            ),
    },
    {
        field: 'totalFeeCents',
        headerName: 'OF Fee',
        width: 150,
        align: 'right',
        headerAlign: 'right',
        renderCell: params =>
            renderCell(params.row.totalFee, params.row.compareTotals.totalFee, params.row.compare, params.row.compareTotals.totalFeeColor),
    },
    {
        field: 'totalNetCents',
        headerName: 'Net',
        width: 130,
        align: 'right',
        headerAlign: 'right',
        renderCell: params =>
            renderCell(params.row.totalNet, params.row.compareTotals.totalNet, params.row.compare, params.row.compareTotals.totalNetColor),
    },
    {
        field: 'totalCommissionCents',
        headerName: 'Commission',
        align: 'right',
        headerAlign: 'right',
        width: 130,
        renderCell: params =>
            renderCell(
                params.row.totalCommission,
                params.row.compareTotals.totalCommission,
                params.row.compare,
                params.row.compareTotals.totalCommissionColor,
            ),
    },
];

type ReportAccountTotalsByAgentProps = {
    subscriberId: string;
    searchParams: string;
    transactionsMain: SextforceReportAccountTotalsByFraction[];
    transactionsCompare: SextforceReportAccountTotalsByFraction[];
    transactionsByDayMain: SextforceReportAccountTotalsByFractionByDay[];
    transactionsByDayCompare: SextforceReportAccountTotalsByFractionByDay[];
    compare: boolean;
    isFetchingTotalsByFraction: boolean;
    isFetchingTotalsByFractionByDay: boolean;
};

const ReportAccountTotalsByAgentByDay = (props: ReportAccountTotalsByAgentProps) => {
    const {
        subscriberId,
        searchParams,
        transactionsMain,
        transactionsCompare,
        transactionsByDayMain,
        transactionsByDayCompare,
        compare,
        isFetchingTotalsByFraction,
        isFetchingTotalsByFractionByDay,
    } = props;
    const theme: Theme = useTheme();

    const [includeUnknownAgents, setIncludeUnknownAgents] = useState<boolean>(true);
    const timezone: string = moment.tz.guess();

    // Total transactions by fraction
    const [rows, setRows] = useState<any[]>([]);
    const [filteredTransactionsMain, setFilteredTransactionsMain] = useState<SextforceReportAccountTotalsByFraction[]>([]);
    const [filteredTransactionsCompare, setFilteredTransactionsCompare] = useState<SextforceReportAccountTotalsByFraction[]>([]);

    // Total transactions by fraction by day
    const [rowsByDay, setRowsByDay] = useState<SextforceReportAccountTotalsByFractionByDay[]>([]);
    const [filteredTransactionsByDayMain, setFilteredTransactionsByDayMain] = useState<SextforceReportAccountTotalsByFractionByDay[]>([]);
    const [filteredTransactionsByDayCompare, setFilteredTransactionsByDayCompare] = useState<SextforceReportAccountTotalsByFractionByDay[]>(
        [],
    );

    const [uniqueDatesInData, setUniqueDatesInData] = useState<string[]>([]);
    const [uniqueFractionsInData, setUniqueFractionsInData] = useState<number[]>([]);
    const [tabValue, setTabValue] = useState<string | 'total'>('total');

    // set default moment locale to the browser locale
    // moment.locale(window.navigator.language);

    const handleTabChange = (event: React.SyntheticEvent, newValue: string | 'total') => {
        setTabValue(newValue);
    };

    useEffect(() => {
        if (transactionsMain) {
            setFilteredTransactionsMain(includeUnknownAgents ? transactionsMain : transactionsMain.filter(t => t.agent !== null));
        } else {
            setFilteredTransactionsMain([]);
        }

        if (transactionsByDayMain) {
            setFilteredTransactionsByDayMain(
                includeUnknownAgents ? transactionsByDayMain : transactionsByDayMain.filter(t => t.agent !== null),
            );
        } else {
            setFilteredTransactionsByDayMain([]);
        }
    }, [transactionsByDayMain, includeUnknownAgents, tabValue, transactionsMain]);

    useEffect(() => {
        if (transactionsCompare) {
            setFilteredTransactionsCompare(includeUnknownAgents ? transactionsCompare : transactionsCompare.filter(t => t.agent !== null));
        } else {
            setFilteredTransactionsCompare([]);
        }

        if (transactionsByDayCompare) {
            setFilteredTransactionsByDayCompare(
                includeUnknownAgents ? transactionsByDayCompare : transactionsByDayCompare.filter(t => t.agent !== null),
            );
        } else {
            setFilteredTransactionsByDayCompare([]);
        }
    }, [transactionsByDayCompare, includeUnknownAgents, tabValue, transactionsCompare]);

    useEffect(() => {
        setRows(generateDataRows(filteredTransactionsMain, filteredTransactionsCompare, compare, theme));

        if (filteredTransactionsByDayMain.length > 0) {
            // Get unique dates from the transactions
            const dates = filteredTransactionsByDayMain
                .map(t => (t.date ? moment(t.date).format() : undefined))
                .filter(d => d !== undefined) as string[];

            const uniqueDates = [...new Set(dates)];

            setUniqueDatesInData(uniqueDates);

            if (!tabValue && dates.length > 0) {
                setTabValue(uniqueDates[0]);
            }

            // Get unique fractions from the transactions
            const fractions = filteredTransactionsByDayMain.map(t => d2f(t.fraction));

            setUniqueFractionsInData([...new Set(fractions)]);
        } else {
            setUniqueDatesInData([]);
            setUniqueFractionsInData([]);
            setTabValue('total');
        }
    }, [
        filteredTransactionsByDayMain,
        filteredTransactionsByDayCompare,
        tabValue,
        filteredTransactionsMain,
        filteredTransactionsCompare,
        compare,
        theme,
    ]);

    useEffect(() => {
        if (uniqueDatesInData.length === 0) {
            setTabValue('total');
        }

        setRowsByDay(
            generateByDayDataRows(
                filteredTransactionsByDayMain.filter(t => (t.date ? moment(t.date).format() === tabValue : false)),
                filteredTransactionsByDayCompare,
                uniqueFractionsInData,
                compare,
                theme,
            ),
        );
    }, [
        compare,
        filteredTransactionsByDayCompare,
        filteredTransactionsByDayMain,
        tabValue,
        theme,
        uniqueDatesInData.length,
        uniqueFractionsInData,
    ]);

    // useEffect(() => {
    //     if (!tabValue) {
    //         setRowsByDay([]);

    //         return;
    //     }

    //     setRowsByDay(
    //         generateByDayDataRows(
    //             filteredTransactionsByDayMain.filter(t => (t.date ? moment(t.date).format() === tabValue : false)),
    //             filteredTransactionsByDayCompare,
    //             uniqueFractionsInData,
    //             compare,
    //             theme,
    //         ),
    //     );
    // }, [compare, filteredTransactionsByDayCompare, filteredTransactionsByDayMain, tabValue, theme]);

    // Convert report to CSV format and start file download
    const handleDownloadReportCsv = () => {
        const transformedData = transformTransactionsForExport(
            filteredTransactionsMain,
            filteredTransactionsCompare,
            uniqueDatesInData,
            filteredTransactionsByDayMain,
            filteredTransactionsByDayCompare,
            compare,
        );

        const flatRows: any[] = [];

        // Add the totals rows
        const totalData = transformedData.rows.find(row => row.tab === 'Total');

        if (totalData) {
            flatRows.push({ agentName: totalData.tab });

            totalData.data.forEach((data: any) => {
                // Create a separator row with the name of the tab
                flatRows.push(data);
            });
        }

        // Add the rest of the rows and add a a separator row with the name of the tab
        const datesData = transformedData.rows.filter(row => row.tab !== 'Total');

        if (datesData) {
            datesData.forEach(rows => {
                flatRows.push({ agentName: rows.tab });

                rows.data.forEach((data: any) => {
                    flatRows.push(data);
                });
            });
        }

        // Format JSON data to CSV
        const csv = parseCsv(flatRows, {
            fields: transformedData.headers,
            transforms: [],
        });

        // Convert CSV to Blob
        const blob: Blob = new Blob([csv], { type: 'text/csv' });

        // Create a descriptive filename
        const filename: string = `sales_report_by_agent_${moment().tz(timezone, true).format('YYYY-MM-DD')}.csv`;

        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode && link.parentNode.removeChild(link);
    };

    // Convert to Excel format and start file download
    const handleDownloadReportExcel = () => {
        const transformedData = transformTransactionsForExport(
            filteredTransactionsMain,
            filteredTransactionsCompare,
            uniqueDatesInData,
            filteredTransactionsByDayMain,
            filteredTransactionsByDayCompare,
            compare,
        );

        const workbook = XLSX.utils.book_new();

        transformedData.rows.forEach(group => {
            const worksheet = XLSX.utils.json_to_sheet([]);

            XLSX.utils.sheet_add_aoa(worksheet, [transformedData.headers.map((header: any) => header.label)]);

            XLSX.utils.sheet_add_json(worksheet, group.data, {
                origin: 'A2',
                skipHeader: true,
                header: transformedData.headers.map((header: any) => header.value),
            });

            XLSX.utils.book_append_sheet(workbook, worksheet, group.tab.replace(/[/\\?%*:|"<>]/g, '_'));
        });

        // Create a descriptive filename
        const filename: string = `sales_report_by_agent_${moment().tz(timezone, true).format('YYYY-MM-DD')}.xls`;

        XLSX.writeFile(workbook, filename);
    };

    return (
        <Card variant="elevation" sx={{ marginBottom: 4 }}>
            <CardContent
                sx={{
                    padding: 0,
                    '&:last-child': {
                        paddingBottom: 0,
                    },
                }}
            >
                <Grid container spacing={2} alignItems="center" sx={{ padding: 2 }}>
                    <Grid item xs>
                        <Typography variant="h6">Agents Performance</Typography>
                    </Grid>
                    <Grid item xs="auto">
                        <Tooltip title="Include Unknown Agents">
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={includeUnknownAgents}
                                        onChange={event => setIncludeUnknownAgents(event.target.checked)}
                                    />
                                }
                                label="Include Unknown Agents"
                            />
                        </Tooltip>
                    </Grid>
                    <Grid item xs="auto">
                        <Tooltip title="Download as CSV">
                            <span>
                                <IconButton
                                    disabled={
                                        !transactionsByDayMain ||
                                        (!transactionsByDayCompare && !compare) ||
                                        filteredTransactionsByDayMain.length === 0
                                    }
                                    onClick={() => {
                                        handleDownloadReportCsv();
                                    }}
                                >
                                    <FontAwesomeIcon
                                        icon={faFileCsv}
                                        size="2x"
                                        color={
                                            !transactionsByDayMain ||
                                            (!transactionsByDayCompare && !compare) ||
                                            filteredTransactionsByDayMain.length === 0
                                                ? theme.palette.grey[500]
                                                : theme.palette.info.main
                                        }
                                    />
                                </IconButton>
                            </span>
                        </Tooltip>
                    </Grid>
                    <Grid item xs="auto">
                        <Tooltip title="Download as Excel">
                            <span>
                                <IconButton
                                    disabled={
                                        !transactionsByDayMain ||
                                        (!transactionsByDayCompare && !compare) ||
                                        filteredTransactionsByDayMain.length === 0
                                    }
                                    onClick={() => {
                                        handleDownloadReportExcel();
                                    }}
                                >
                                    <FontAwesomeIcon
                                        icon={faFileExcel}
                                        size="2x"
                                        color={
                                            !transactionsByDayMain ||
                                            (!transactionsByDayCompare && !compare) ||
                                            filteredTransactionsByDayMain.length === 0
                                                ? theme.palette.grey[500]
                                                : theme.palette.info.main
                                        }
                                    />
                                </IconButton>
                            </span>
                        </Tooltip>
                    </Grid>
                    <Grid item xs={12}>
                        See the total sales by agent and by day. The "Total" tab shows the totals for the searched period, while the dated
                        tabs show the totals for each day respectfully. Click on the agent name to see the detailed transactions.
                    </Grid>
                </Grid>

                <Box>
                    <AppBar position="static">
                        <Tabs
                            value={tabValue}
                            onChange={handleTabChange}
                            indicatorColor="secondary"
                            textColor="inherit"
                            variant="scrollable"
                            scrollButtons="auto"
                        >
                            <Tab key={'total'} label={'Total'} value={'total'} />
                            {uniqueDatesInData.map(date => (
                                <Tab key={date} label={moment(date).format('L')} value={date} />
                            ))}
                        </Tabs>
                    </AppBar>
                </Box>

                <Box
                    component={'div'}
                    sx={{
                        p: 0,
                        bgcolor: 'grey.100',
                        borderBottomColor: theme.palette.primary.main,
                        borderBottomStyle: 'solid',
                        borderBottomWidth: 1,
                    }}
                >
                    <span>
                        {tabValue === 'total' && (
                            <DataGridPro
                                rows={rows}
                                columns={columns(subscriberId, searchParams, theme)}
                                initialState={{ sorting: { sortModel: [{ field: 'totalAmountCents', sort: 'desc' }] } }}
                                hideFooter={true}
                                disableRowSelectionOnClick
                                disableColumnFilter
                                disableColumnSelector
                                autoHeight={true}
                                loading={isFetchingTotalsByFraction}
                                rowCount={transactionsMain ? filteredTransactionsMain.length : 0}
                                // pin name column to the left
                                pinnedColumns={{
                                    left: ['agentName'],
                                }}
                                sx={{
                                    '& .MuiDataGrid-columnHeaderTitle': { fontWeight: 'bold' },
                                    backgroundColor: theme.palette.grey[50],
                                    // make pinned columns background white
                                    '& .MuiDataGrid-cell': {
                                        backgroundColor: theme.palette.grey[50],
                                    },
                                    '& .MuiDataGrid-columnHeader': {
                                        backgroundColor: theme.palette.grey[50],
                                    },
                                }}
                            />
                        )}
                        {tabValue !== 'total' && (
                            <DataGridPro
                                rows={rowsByDay}
                                columns={columns(subscriberId, searchParams, theme)}
                                initialState={{ sorting: { sortModel: [{ field: 'agentName', sort: 'asc' }] } }}
                                hideFooter={true}
                                disableRowSelectionOnClick
                                disableColumnFilter
                                disableColumnSelector
                                disableColumnMenu
                                autoHeight={true}
                                loading={isFetchingTotalsByFractionByDay}
                                rowCount={transactionsByDayMain ? filteredTransactionsByDayMain.length : 0}
                                // pin name column to the left
                                pinnedColumns={{
                                    left: ['agentName'],
                                }}
                                sx={{
                                    '& .MuiDataGrid-columnHeaderTitle': { fontWeight: 'bold' },
                                    backgroundColor: theme.palette.grey[50],
                                    // make pinned columns background white
                                    '& .MuiDataGrid-cell': {
                                        backgroundColor: theme.palette.grey[50],
                                    },
                                    '& .MuiDataGrid-columnHeader': {
                                        backgroundColor: theme.palette.grey[50],
                                    },
                                }}
                            />
                        )}
                    </span>
                </Box>
            </CardContent>
        </Card>
    );
};

export default ReportAccountTotalsByAgentByDay;
