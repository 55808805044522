import CheckIcon from '@mui/icons-material/Check';
import LoadingButton from '@mui/lab/LoadingButton';
import { Button, Divider, FormControl, Grid, LinearProgress, Stack, TextField, Typography, useTheme } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import Joi from 'joi';
import { useDialog } from 'muibox';
import { useContext, useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import useAxios from '../../../../hooks/useAxios';
import { SextforceAutoCampaignTipReply, SextforceAutoCampaignTipReplySchema } from '../../../../hooks/useSextforceAutoCampaignTipReply';
import { SextforceAutoRepost } from '../../../../hooks/useSextforceAutoRepost';
import useSubscriberPosts from '../../../../hooks/useSubscriberPosts';
import { SettingsContext } from '../../../../store/SettingsContext';
import { UserContext } from '../../../../store/UserContext';
import { getPostIdFromPostUrl, handleHttpError } from '../../../../utils/common';
import StyledCard from '../../../common/StyledCard';
import StyledCardTitleBar from '../../../common/StyledCardTitleBar';
import SextforceAutoCampaignTipReplyPostPreview from './SextforceAutoCampaignTipReplyPostPreview';

type Props = {
    subscriber: any;
    refetchAutoCampaignTipRepllies: () => void;
    handleCancel: () => void;
    showAddNewForm: boolean;
    setShowAddNewForm: (show: boolean) => void;
};

const SextforceAutoCampaignTipReplyNewCampaignForm = (props: Props) => {
    const { subscriber, refetchAutoCampaignTipRepllies, handleCancel, showAddNewForm, setShowAddNewForm } = props;
    const userContext = useContext(UserContext);
    const settingsContext = useContext(SettingsContext);
    const params = useParams();
    const [searchParams] = useSearchParams();
    const axios = useAxios();
    const dialog = useDialog();
    const theme = useTheme();
    const [formData, setFormData] = useState<SextforceAutoCampaignTipReply>({
        subscriberId: subscriber._id,
        name: '',
        description: '',
        active: true,
        campaignId: 0,
        campaignUrl: searchParams.get('campaignUrl') || '',
        postDate: new Date(),
        postExpireDate: undefined,
        payload: undefined,
        tipsOptions: [],
        replyCount: 0,
    });
    const [isSubmitting, setIsSubmitting] = useState(false);

    // Validate the form data
    const errors: Joi.ValidationErrorItem[] | null = SextforceAutoCampaignTipReplySchema.validate(formData).error?.details || null;

    const { data: post, isLoading: isPostLoading } = useSubscriberPosts(formData.campaignId);
    const [isPostACampaign, setIsPostACampaign] = useState(false);

    const resetForm = () => {
        setFormData({
            subscriberId: subscriber._id,
            name: '',
            description: '',
            active: true,
            campaignId: 0,
            campaignUrl: searchParams.get('campaignUrl') || '',
            postDate: new Date(),
            postExpireDate: undefined,
            payload: undefined,
            tipsOptions: [],
            replyCount: 0,
        });
    };

    useEffect(() => {
        if (!showAddNewForm) {
            resetForm();
        } else {
            setFormData({
                ...formData,
                campaignUrl: searchParams.get('campaignUrl') || '',
            });
        }
    }, [showAddNewForm]);

    // Check if the campaign URL is a valid URL and extract the post ID
    useEffect(() => {
        if (!formData.campaignUrl) {
            return;
        }

        const postId = getPostIdFromPostUrl(formData.campaignUrl);

        if (postId !== 0 && postId !== formData.campaignId) {
            // Set the campaign ID
            setFormData({
                ...formData,
                campaignId: postId,
            });
        }
    }, [formData.campaignUrl]);

    // Check if the post is a campaign
    useEffect(() => {
        if (post && !isPostLoading && post.canEdit) {
            if (post.fundRaising && post.fundRaising.presets.length > 0) {
                setIsPostACampaign(true);

                // Set the post ID, post date and post expire date
                setFormData({
                    ...formData,
                    postDate: new Date(post.postedAt),
                    postExpireDate: post.expiredAt ? new Date(post.expiredAt) : undefined,
                    tipsOptions: post.fundRaising.presets.map(preset => parseFloat(preset)),
                });
            } else {
                setIsPostACampaign(false);

                // Reset the post ID, post date and post expire date
                setFormData({
                    ...formData,
                    postDate: new Date(),
                    postExpireDate: undefined,
                    tipsOptions: [],
                });
            }
        } else if (!post && !isPostLoading && formData.campaignId !== 0) {
            // Reset the post ID, post date and post expire date
            setFormData({
                ...formData,
                postDate: new Date(),
                postExpireDate: undefined,
                tipsOptions: [],
            });
        }
    }, [post, isPostLoading]);

    const autoCampaignTipReplyAdd = async (autoCampaignTipReply: SextforceAutoCampaignTipReply) => {
        if (!userContext.jwtToken || !settingsContext.apiKey) {
            return;
        }

        setIsSubmitting(true);

        const data = await axios
            .post(`${settingsContext.routes.autoCampaignTipReply.base}${params.userId}`, autoCampaignTipReply)
            .then(async response => {
                setIsSubmitting(false);
                return response.data as SextforceAutoRepost;
            })
            .catch(error => {
                setIsSubmitting(false);
                handleHttpError(error, dialog);

                return null;
            });

        return data;
    };

    const handleSubmit = () => {
        autoCampaignTipReplyAdd(formData).then(data => {
            if (!data) {
                return;
            }

            refetchAutoCampaignTipRepllies();

            dialog
                .alert({
                    title: 'Auto Campaign Tip Reply',
                    message: 'Auto Campaign Tip Reply added successfully. Now add the reply messages for each tip amount.',
                    ok: {
                        text: 'OK',
                        variant: 'contained',
                        color: 'primary',
                    },
                })
                .then(() => {
                    // Reset the form
                    setShowAddNewForm(false);
                });
        });
    };

    return (
        <>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <StyledCard noCard noBottomMargin>
                    <StyledCardTitleBar title="Add New Tip Campaign" theme={theme} />
                </StyledCard>
                <StyledCard>
                    <Grid container alignItems={'center'} justifyContent={'center'} spacing={1}>
                        <Grid item xs={12}>
                            Campaign Post's URL
                            <Typography variant="body2" color={'text.secondary'} marginTop={0.5}>
                                The URL to the campaign post you want to monitor.
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Stack direction={'column'} spacing={0} sx={{ mb: 1 }}>
                                <FormControl fullWidth sx={{ mb: 0 }}>
                                    <TextField
                                        id="campaignUrl"
                                        name="campaignUrl"
                                        placeholder="https://onlyfans.com/1234567890/username"
                                        size="medium"
                                        disabled={isPostLoading}
                                        value={formData.campaignUrl}
                                        onChange={e => setFormData({ ...formData, campaignUrl: e.target.value })}
                                        error={!!errors?.find(error => error.context?.key === 'campaignUrl')}
                                        helperText={
                                            errors ? errors.find(error => error.context?.key === 'campaignUrl')?.message : undefined
                                        }
                                        InputProps={{
                                            endAdornment: post && isPostACampaign && formData.campaignId !== 0 && (
                                                <CheckIcon color={'success'} />
                                            ),
                                        }}
                                        sx={{ mb: 0 }}
                                    />
                                </FormControl>
                                {isPostLoading && <LinearProgress color="primary" />}
                                {post && !isPostACampaign && formData.campaignId !== 0 && (
                                    <Typography variant="body2" color={'error'} marginTop={0.5}>
                                        The post is not a campaign
                                    </Typography>
                                )}
                                {post && isPostACampaign && formData.campaignId !== 0 && (
                                    <Typography variant="body2" color={'success'} marginTop={0.5}>
                                        Post is a campaign
                                    </Typography>
                                )}
                                {post && !post.canEdit && (
                                    <Typography variant="body2" color={'error'} marginTop={0.5}>
                                        This post is not valid or you do not have permission to repost it.
                                    </Typography>
                                )}
                            </Stack>
                        </Grid>
                        {post && isPostACampaign && formData.campaignId !== 0 && (
                            <Grid item xs={12}>
                                <SextforceAutoCampaignTipReplyPostPreview post={post} />
                            </Grid>
                        )}

                        <Grid item xs={12}>
                            Name
                            <Typography variant="body2" color={'text.secondary'} marginTop={0.5}>
                                Name of this campaign.
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <TextField
                                    id="name"
                                    name="name"
                                    size="medium"
                                    value={formData.name}
                                    onChange={e => setFormData({ ...formData, name: e.target.value })}
                                    error={!!errors?.find(error => error.context?.key === 'name')}
                                    helperText={errors ? errors.find(error => error.context?.key === 'name')?.message : undefined}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            Description
                            <Typography variant="body2" color={'text.secondary'} marginTop={0.5}>
                                Any description or notes you would like to leave for this campaign.
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <TextField
                                    id="description"
                                    name="description"
                                    size="medium"
                                    value={formData.description}
                                    onChange={e => setFormData({ ...formData, description: e.target.value })}
                                    error={!!errors?.find(error => error.context?.key === 'description')}
                                    helperText={errors ? errors.find(error => error.context?.key === 'description')?.message : undefined}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={8}>
                            <LoadingButton
                                variant="contained"
                                color="secondary"
                                fullWidth
                                size="large"
                                loading={isSubmitting}
                                disabled={!!errors || !isPostACampaign}
                                onClick={handleSubmit}
                            >
                                Add New Tip Campaign
                            </LoadingButton>
                        </Grid>
                        <Grid item xs={4}>
                            <Button variant="outlined" color="error" fullWidth size="large" onClick={handleCancel}>
                                Cancel
                            </Button>
                        </Grid>
                    </Grid>
                </StyledCard>
            </LocalizationProvider>
        </>
    );
};

export default SextforceAutoCampaignTipReplyNewCampaignForm;
