import axios from 'axios';
import { useDialog } from 'muibox';
import { useContext } from 'react';
import { useQuery } from 'react-query';
import { SettingsContext } from '../store/SettingsContext';
import { UserContext } from '../store/UserContext';
import { handleHttpError } from '../utils/common';
import useSubscriber from './useSubscriber';

const useSubscriberVaultAlbums = (enabled: boolean = true) => {
    const userContext = useContext(UserContext);
    const settingsContext = useContext(SettingsContext);
    const { data: subscriber } = useSubscriber();
    const dialog = useDialog();

    // Fetch Subscriber's Lists
    const fetchVaultAlbums = async (): Promise<any> => {
        const query: string = `${settingsContext.routes.onlyFans.base}${subscriber._id}/vaultAlbums`;

        return axios
            .get(query, {
                headers: {
                    Authorization: userContext.jwtToken,
                    apiKey: settingsContext.apiKey,
                },
            })
            .then(response => response.data)
            .catch(error => {
                console.error(error);
                handleHttpError(error, dialog);
            });
    };

    return useQuery(['vaultAlbums', subscriber], () => fetchVaultAlbums(), {
        refetchOnWindowFocus: false,
        staleTime: Infinity,
        enabled: userContext.jwtToken && settingsContext.apiKey && subscriber && enabled ? true : false,
    });
};

export default useSubscriberVaultAlbums;
