import dinero from 'dinero.js';
import { styled } from '@mui/material/styles';
import { Theme } from '@mui/system';
import { tableCellClasses } from '@mui/material/TableCell';
import { Box, Grid, IconButton, Table, TableBody, TableContainer, TableHead, TableRow, TableCell, Tooltip } from '@mui/material';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import ChatIcon from '@mui/icons-material/Chat';
import { d2f } from '../../../../utils/common';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        fontWeight: 'bold',
    },
    [`&.${tableCellClasses.body}`]: {
        // fontSize: 14,
    },
}));

const UserRow = (data: any, compareData: any[] | null, index: number, theme: Theme) => {
    const isFound = compareData ? compareData.find(item => item.user.id === data.user.id) : false;

    return (
        <TableRow
            key={data._id}
            sx={{
                '&:last-child td, &:last-child th': { border: 0 },
            }}
        >
            <StyledTableCell component="th" scope="row">
                <span
                    {...(isFound && {
                        style: {
                            backgroundColor: theme.palette.info.main,
                            color: theme.palette.common.white,
                            padding: '.25em 0',
                            boxShadow: `.5em 0 0 ${theme.palette.info.main}, -.5em 0 0 ${theme.palette.info.main}`,
                        },
                    })}
                >
                    {index + 1}
                </span>
            </StyledTableCell>
            <StyledTableCell>
                <span
                    {...(isFound && {
                        style: {
                            backgroundColor: theme.palette.info.main,
                            color: theme.palette.common.white,
                            padding: '.25em 0',
                            boxShadow: `.5em 0 0 ${theme.palette.info.main}, -.5em 0 0 ${theme.palette.info.main}`,
                        },
                    })}
                >
                    {data.user.name}
                </span>
            </StyledTableCell>
            <StyledTableCell>
                <span
                    {...(isFound && {
                        style: {
                            backgroundColor: theme.palette.info.main,
                            color: theme.palette.common.white,
                            padding: '.25em 0',
                            boxShadow: `.5em 0 0 ${theme.palette.info.main}, -.5em 0 0 ${theme.palette.info.main}`,
                        },
                    })}
                >
                    {data.user.username}
                </span>
            </StyledTableCell>
            <StyledTableCell align="right">
                <span
                    style={{
                        fontFamily: 'monospace',
                        ...(isFound && {
                            backgroundColor: theme.palette.info.main,
                            color: theme.palette.common.white,
                            padding: '.25em 0',
                            boxShadow: `.5em 0 0 ${theme.palette.info.main}, -.5em 0 0 ${theme.palette.info.main}`,
                        }),
                    }}
                >
                    {dinero({ amount: d2f(data.totalCents), currency: 'USD' }).toFormat()}
                </span>
            </StyledTableCell>
            <StyledTableCell align="center">
                <Grid container flexGrow={0} spacing={1} alignItems="center" justifyContent="center">
                    <Grid item>
                        <Tooltip title="Visit user's profile on OnlyFans">
                            <IconButton href={`https://onlyfans.com/${data.user.username}`} target="_blank">
                                <AccountBoxIcon color="primary" />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                    <Grid item>
                        <Tooltip title="Chat with user on OnlyFans">
                            <IconButton href={`https://onlyfans.com/my/chats/chat/${data.user.id}/`} target="_blank">
                                <ChatIcon color="secondary" />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
            </StyledTableCell>
        </TableRow>
    );
};

type ReportAccountTotalByUserTopSpendersTableProps = {
    topSpenders: any[] | null;
    compareSpenders: any[] | null;
    theme: Theme;
};

const ReportAccountTotalByUserTopSpendersTable = (props: ReportAccountTotalByUserTopSpendersTableProps) => {
    const { topSpenders, compareSpenders, theme } = props;

    return (
        <TableContainer component={Box} sx={{ marginBottom: theme.spacing(1) }}>
            <Table sx={{ width: '100%' }} size="small">
                <TableHead>
                    <TableRow>
                        <StyledTableCell>Rank</StyledTableCell>
                        <StyledTableCell>Name</StyledTableCell>
                        <StyledTableCell>Username</StyledTableCell>
                        <StyledTableCell align="right">Total</StyledTableCell>
                        <StyledTableCell width={120} align="center">
                            Actions
                        </StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {topSpenders && topSpenders.map((data: any, index: number) => UserRow(data, compareSpenders, index, theme))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default ReportAccountTotalByUserTopSpendersTable;
