import LoadingButton from '@mui/lab/LoadingButton';
import { Card, CardContent, FormControlLabel, Grid, MenuItem, Radio, RadioGroup, Select, useMediaQuery } from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';
import { Theme } from '@mui/system';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { d2f } from '../../../../utils/common';
import SelectTimezone from '../../../forms/helpers/SelectTimezone';
import { ReportSearchBarPropsSeachParams, SearchGroup } from '../SearchGroup';

type SextforceAssignTransactionsSearchBarProps = {
    timezone: string;
    setTimezone: (timezone: string) => void;
    agents: any[];
    mainSearch: ReportSearchBarPropsSeachParams;
    filter: string;
    setFilter: React.Dispatch<React.SetStateAction<string>>;
    agent: string;
    setAgent: React.Dispatch<React.SetStateAction<string>>;
    filterOnlySaleProofs: string;
    setFilterOnlySaleProofs: React.Dispatch<React.SetStateAction<string>>;
    isFetchingReport: boolean;
    requestReport: () => void;
};

const SextforceAssignTransactionsSearchBar = (props: SextforceAssignTransactionsSearchBarProps) => {
    const {
        timezone,
        setTimezone,
        mainSearch,
        filter,
        setFilter,
        agent,
        setAgent,
        filterOnlySaleProofs,
        setFilterOnlySaleProofs,
        isFetchingReport,
        requestReport,
        agents,
    } = props;
    const theme: Theme = useTheme();
    const largeScreen = useMediaQuery(theme.breakpoints.up('sm'));

    const RowFilter = () => (
        <RadioGroup row defaultValue={filter} value={filter} name="filter" onChange={e => setFilter(e.target.value)}>
            <Grid container spacing={1} alignItems={'center'} sx={{ marginBottom: theme.spacing(1) }}>
                <Grid item xs={12} md="auto" sx={{ minWidth: '130px' }}>
                    Transactions:
                </Grid>
                <Grid item xs={12} md={'auto'}>
                    <FormControlLabel value="all" control={<Radio size="small" />} label="All" />
                </Grid>
                <Grid item xs={12} md={'auto'}>
                    <FormControlLabel value="unassigned" control={<Radio size="small" />} label="Only Unassigned" />
                </Grid>
                <Grid item xs={12} md={'auto'}>
                    <FormControlLabel value="agent" control={<Radio size="small" />} label="Only Assigned to" />
                </Grid>
                <Grid item xs={12} md={2}>
                    <Select
                        id="agent"
                        value={agents && agents.length > 0 ? agent : ''}
                        disabled={filter !== 'agent'}
                        sx={{ width: '100%' }}
                        size="small"
                        onChange={(e, child) => setAgent(e.target.value)}
                    >
                        {agents &&
                            agents
                                .sort((a, b) => (a.name.toUpperCase() < b.name.toUpperCase() ? -1 : 1))
                                .map((agent: any) => (
                                    <MenuItem value={agent._id} key={agent._id}>
                                        {agent.name} ({d2f(agent.fraction).toFixed(2)})
                                    </MenuItem>
                                ))}
                    </Select>
                </Grid>
            </Grid>
        </RadioGroup>
    );

    const RowFilterOnlySaleProofs = () => (
        <RadioGroup
            row
            defaultValue={filterOnlySaleProofs}
            value={filterOnlySaleProofs}
            name="filterOnlySaleProofs"
            onChange={e => setFilterOnlySaleProofs(e.target.value)}
        >
            <Grid container spacing={1} alignItems={'center'} sx={{ marginBottom: theme.spacing(1) }}>
                <Grid item xs={12} md="auto" sx={{ minWidth: '130px' }}>
                    Sale Proofs:
                </Grid>
                <Grid item xs={12} md={'auto'}>
                    <FormControlLabel value="all" control={<Radio size="small" />} label="Any" />
                </Grid>
                <Grid item xs={12} md={'auto'}>
                    <FormControlLabel value="saleProofs" control={<Radio size="small" />} label="Only with Sale Proofs" />
                </Grid>
                <Grid item xs={12} md={'auto'}>
                    <FormControlLabel value="pendingSaleProofs" control={<Radio size="small" />} label="Only with Pending Sale Proofs" />
                </Grid>
            </Grid>
        </RadioGroup>
    );

    return (
        <Card variant="elevation" sx={{ marginBottom: theme.spacing(2), displayPrint: 'none' }}>
            <CardContent style={{ padding: theme.spacing(1) }}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <Grid container spacing={1} alignItems={'center'} justifyContent={'center'}>
                        <Grid item xs={12}>
                            <SelectTimezone timezone={timezone} setTimezone={setTimezone} size="small" fullWidth={!largeScreen} />
                        </Grid>

                        <Grid item xs={12}>
                            <SearchGroup params={mainSearch} enabled={true} theme={theme} />
                        </Grid>

                        <Grid item xs={12}>
                            <RowFilter />
                        </Grid>

                        <Grid item xs={12}>
                            <RowFilterOnlySaleProofs />
                        </Grid>

                        <Grid item xs={12}>
                            <LoadingButton
                                variant="contained"
                                disabled={isFetchingReport || (filter === 'agent' && agent === '')}
                                loading={isFetchingReport}
                                onClick={requestReport}
                                size="large"
                                fullWidth
                            >
                                Show
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </LocalizationProvider>
            </CardContent>
        </Card>
    );
};

export default SextforceAssignTransactionsSearchBar;
