import { Theme } from '@mui/system';
import { Grid } from '@mui/material';
import OverviewCard from '../../../common/OverviewCard';

// const FollowBackFilterDescription = (followBackFilter: any) => {
//     return (
//         <Grid container flexGrow={1} spacing={1}>
//             {Object.keys(followBackFilter).map((filter: string) => {
//                 const value: number | string = followBackFilter[filter];
//                 let name: string = '';

//                 switch (filter) {
//                     case 'total_spent': {
//                         name = 'Minimum Total Spent';

//                         break;
//                     }

//                     case 'tips': {
//                         name = 'Minimum Tips';

//                         break;
//                     }

//                     case 'duration': {
//                         name = 'Subscription Days';

//                         break;
//                     }

//                     case 'inactive': {
//                         name = 'Inactive';

//                         break;
//                     }

//                     case 'recommenders': {
//                         name = 'Recommenders';

//                         break;
//                     }

//                     case 'online': {
//                         name = 'Online';

//                         break;
//                     }

//                     case 'thresholdMonths': {
//                         name = 'Minimum Subscription Months';

//                         break;
//                     }

//                     case 'thresholdMinTotalSpent': {
//                         name = 'Minimum Total Spent';

//                         break;
//                     }
//                 }

//                 return (
//                     <Grid container flexGrow={1} spacing={0} key={filter}>
//                         <Grid item xs="auto">
//                             {name}
//                         </Grid>
//                         <Grid item xs>
//                             {value}
//                         </Grid>
//                     </Grid>
//                 );
//             })}
//         </Grid>
//     );
// };

type FollowBackOverviewSettingsProps = {
    subscriber: any;
    loading: boolean;
    theme: Theme;
};

const FollowBackOverviewSettings = (props: FollowBackOverviewSettingsProps) => {
    const { subscriber, loading, theme } = props;
    // const settingsContext = useContext(SettingsContext);

    return (
        <Grid container flexGrow={0} justifyContent="center" spacing={1} sx={{ paddingBottom: theme.spacing(0) }}>
            <Grid item xs={12} md={6} lg={4}>
                <OverviewCard
                    title="Hard Follow Limit"
                    value={
                        subscriber && subscriber.followBack && subscriber.followBack.followBackLimit
                            ? subscriber.followBack.followBackLimit === -1
                                ? 'OFF'
                                : subscriber.followBack.followBackLimit
                            : 'OFF'
                    }
                    loading={loading}
                    theme={theme}
                />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
                <OverviewCard
                    title="Follow Active Fans"
                    value={subscriber && subscriber.followBack && subscriber.followBack.followFansActive ? 'YES' : 'NO'}
                    loading={loading}
                    theme={theme}
                />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
                <OverviewCard
                    title={
                        subscriber &&
                        subscriber.followBack &&
                        subscriber.followBack.followBackFilter &&
                        Object.keys(subscriber.followBack.followBackFilter).find((filter: string) => filter.includes('threshold'))
                            ? 'Filter Users'
                            : 'Priority Filter'
                    }
                    value={
                        subscriber &&
                        subscriber.followBack &&
                        subscriber.followBack.followBackFilter &&
                        Object.keys(subscriber.followBack.followBackFilter).length > 0
                            ? 'YES'
                            : 'NO'
                    }
                    loading={loading}
                    theme={theme}
                />
            </Grid>
        </Grid>
    );
};

export default FollowBackOverviewSettings;
