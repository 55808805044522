import dinero from 'dinero.js';
import { Theme } from '@mui/system';
import { Card, CardContent, FormControlLabel, Grid, Radio, RadioGroup, Skeleton, Typography, useMediaQuery } from '@mui/material';
import { Bar, CartesianGrid, ComposedChart, Legend, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import React, { useEffect, useState } from 'react';
import { d2f, titleCase, zeroPad } from '../../../../../utils/common';

const findMaximumProductTotal = (d: any, products: string[]) => {
    let max: number = 0;

    products.forEach(product => {
        if (d[product] && d[product].total && d[product].total > max) {
            max = d[product].total;
        }
    });

    return max;
};

const findMaximumProductAverage = (d: any, products: string[]) => {
    let max: number = 0;

    products.forEach(product => {
        if (d[product] && d[product].average && d[product].average > max) {
            max = d[product].average;
        }
    });

    return max;
};

const generateData = (data: any[]) => {
    if (data.length === 0) {
        return [];
    }

    // Create an array of unique product names in the results
    const uniqueProdcuts: string[] = [];

    data.forEach(d => {
        if (d.products) {
            d.products.forEach((p: any) => {
                if (p.product && !uniqueProdcuts.includes(p.product)) {
                    uniqueProdcuts.push(p.product);
                }
            });
        }
    });

    const productsTotals: number[] = [];
    const productsAverages: number[] = [];

    const result = {
        data: data.map(d => {
            const products: any = {};

            uniqueProdcuts.forEach(uniqueProdcut => {
                if (!d.products) {
                    products[uniqueProdcut] = {
                        total: 0,
                        average: 0,
                    };
                } else {
                    const foundProduct = d.products.find((p: any) => p.product === uniqueProdcut);

                    if (!foundProduct) {
                        products[uniqueProdcut] = {
                            total: 0,
                            average: 0,
                        };
                    } else {
                        products[uniqueProdcut] = {
                            total: foundProduct.total ? d2f(foundProduct.total) : 0,
                            average: foundProduct.average ? d2f(foundProduct.average) : 0,
                        };
                    }
                }
            });

            const productTotal: number = d2f(d.total);
            const productAverage: number = d2f(d.average);

            productsTotals.push(productTotal);
            productsAverages.push(productAverage);

            return {
                date: `${zeroPad(d._id.year, 4)}-${zeroPad(d._id.month, 2)}`,
                ...products,
                total: productTotal,
                average: productAverage,
            };
        }),
        products: uniqueProdcuts,
        productsTotals,
        productsAverages,
    };

    return result;
};

const CustomTooltip = ({ payload, products, productColors, mode, theme }: any) => {
    if (payload && payload.length) {
        return (
            <div>
                <Grid
                    container
                    flexGrow={0}
                    alignItems="center"
                    spacing={1}
                    sx={{
                        backgroundColor: '#fff',
                        borderRadius: 4,
                        border: '1px solid #999',
                        maxWidth: 250,
                    }}
                >
                    <Grid item xs={12}>
                        <span style={{ fontWeight: 'bold' }}>{payload[0].payload.date}</span>
                    </Grid>
                    <Grid item xs={12}>
                        {products &&
                            products.map((product: string, index: number) => {
                                const productPayload = payload.find((p: any) => p.dataKey === `${product}.${mode}`);

                                return (
                                    <Grid container flexGrow={1} alignItems="center" key={index}>
                                        <Grid item xs={4} sx={{ color: productColors[index] }}>
                                            {titleCase(product)}
                                        </Grid>
                                        <Grid
                                            item
                                            xs={8}
                                            textAlign={'right'}
                                            sx={{ color: productColors[index], paddingRight: '8px', fontFamily: 'monospace' }}
                                        >
                                            {dinero({
                                                amount: productPayload ? Math.trunc(productPayload.value * 100) : 0,
                                                currency: 'USD',
                                            }).toFormat()}
                                        </Grid>
                                    </Grid>
                                );
                            })}

                        {payload.map((item: any, index: number) => (
                            <React.Fragment key={index}>
                                {item.dataKey === 'total' && (
                                    <Grid container flexGrow={1} alignItems="center" sx={{ marginTop: theme.spacing(1) }}>
                                        <Grid item xs={6}>
                                            Total Sales:
                                        </Grid>
                                        <Grid item xs={6} textAlign={'right'} sx={{ paddingRight: '8px', fontFamily: 'monospace' }}>
                                            {dinero({
                                                amount: item.value ? Math.trunc(item.value * 100) : 0,
                                                currency: 'USD',
                                            }).toFormat()}
                                        </Grid>
                                    </Grid>
                                )}

                                {item.dataKey === 'average' && (
                                    <Grid container flexGrow={1} alignItems="center" sx={{ marginTop: theme.spacing(1) }}>
                                        <Grid item xs={6}>
                                            Average Sales:
                                        </Grid>
                                        <Grid item xs={6} textAlign={'right'} sx={{ paddingRight: '8px', fontFamily: 'monospace' }}>
                                            {dinero({
                                                amount: item.value ? Math.trunc(item.value * 100) : 0,
                                                currency: 'USD',
                                            }).toFormat()}
                                        </Grid>
                                    </Grid>
                                )}
                            </React.Fragment>
                        ))}
                    </Grid>
                </Grid>
            </div>
        );
    }

    return null;
};

type Props = {
    monthlySales: any;
    monthlySalesLoading: boolean;
    theme: Theme;
};

const SextforceMetricsAccountMonthlySalesGraph = (props: Props) => {
    const { monthlySales, monthlySalesLoading, theme } = props;
    const [dataMain, setDataMain] = useState<any>({ data: [], min: 0, max: 0, average: 0 });
    const largeScreen = useMediaQuery(theme.breakpoints.up('sm'));
    const [mode, setMode] = useState<string>('total');

    const barColors = [
        theme.palette.primary.main,
        theme.palette.secondary.main,
        theme.palette.success.main,
        theme.palette.info.main,
        theme.palette.warning.main,
        theme.palette.error.main,
    ];

    useEffect(() => {
        if (!monthlySalesLoading && monthlySales && monthlySales.length > 0) {
            setDataMain(generateData(monthlySales));
        } else {
            setDataMain([]);
        }
    }, [monthlySales, monthlySalesLoading]);

    const Chart = (chartData: any) => (
        <ResponsiveContainer width="100%" height="100%">
            <ComposedChart
                data={chartData && chartData.data ? chartData.data : []}
                margin={{
                    top: 0,
                    right: largeScreen ? 10 : 10,
                    left: largeScreen ? 20 : 10,
                    bottom: 40,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" stroke={theme.palette.grey[400]} />
                <XAxis
                    dataKey="date"
                    name="Time"
                    domain={chartData && chartData.data && chartData.data.length > 0 ? ['dataMin', 'dataMax'] : ['auto', 'auto']}
                    interval={'preserveStartEnd'}
                    tick={{ fontSize: 13 }}
                    angle={-90}
                    dy={35}
                    dx={-5}
                />
                {mode === 'total' && (
                    <>
                        <YAxis
                            yAxisId="totals"
                            dataKey={d =>
                                findMaximumProductTotal(
                                    d,
                                    chartData && chartData.data && chartData.data.length > 0 ? chartData.products : [],
                                )
                            }
                            scale={'linear'}
                            name="Product Total"
                            domain={[0, (dataMax: number) => Math.abs(dataMax * 1.1)]}
                            tickFormatter={(value: number) => dinero({ amount: Math.trunc(value * 100), currency: 'USD' }).toFormat('$0,0')}
                            tick={{ fill: theme.palette.primary.main, fontSize: 13 }}
                        />
                        {chartData &&
                            chartData.data &&
                            chartData.data.length > 0 &&
                            chartData.products.map((product: string, index: number) => (
                                <Bar
                                    key={product}
                                    yAxisId={'totals'}
                                    type="monotone"
                                    dataKey={`${product}.total`}
                                    maxBarSize={20}
                                    fill={barColors[index]}
                                />
                            ))}
                        <YAxis
                            yAxisId="total"
                            dataKey={'total'}
                            scale={'linear'}
                            name="Total Sales"
                            domain={[0, (dataMax: number) => Math.abs(dataMax * 1.1)]}
                            tickFormatter={(value: number) => dinero({ amount: Math.trunc(value * 100), currency: 'USD' }).toFormat('$0,0')}
                            tick={{ fill: theme.palette.primary.main, fontSize: 13 }}
                            orientation="right"
                        />
                        <Line
                            yAxisId="total"
                            type="monotone"
                            dataKey="total"
                            stroke={theme.palette.common.black}
                            activeDot={{ r: 3 }}
                            dot={{ r: 2 }}
                        />
                    </>
                )}
                {mode === 'average' && (
                    <>
                        <YAxis
                            yAxisId="averages"
                            dataKey={d =>
                                findMaximumProductAverage(
                                    d,
                                    chartData && chartData.data && chartData.data.length > 0 ? chartData.products : [],
                                )
                            }
                            scale={'linear'}
                            name="Product Average"
                            domain={[0, (dataMax: number) => Math.abs(dataMax * 1.1)]}
                            tickFormatter={(value: number) => dinero({ amount: Math.trunc(value * 100), currency: 'USD' }).toFormat('$0,0')}
                            tick={{ fill: theme.palette.primary.main, fontSize: 13 }}
                        />{' '}
                        {chartData &&
                            chartData.data &&
                            chartData.data.length > 0 &&
                            chartData.products.map((product: string, index: number) => (
                                <Bar
                                    key={product}
                                    yAxisId={'averages'}
                                    type="monotone"
                                    dataKey={`${product}.average`}
                                    maxBarSize={20}
                                    fill={barColors[index]}
                                />
                            ))}
                        <YAxis
                            yAxisId="average"
                            dataKey={'average'}
                            scale={'linear'}
                            name="Average Sales"
                            domain={[0, (dataMax: number) => Math.abs(dataMax * 1.1)]}
                            tickFormatter={(value: number) => dinero({ amount: Math.trunc(value * 100), currency: 'USD' }).toFormat()}
                            tick={{ fill: theme.palette.secondary.main, fontSize: 13 }}
                            orientation="right"
                        />
                        <Line
                            yAxisId="average"
                            type="monotone"
                            dataKey="average"
                            stroke={theme.palette.secondary.main}
                            activeDot={{ r: 4 }}
                            dot={{ r: 3 }}
                        />
                    </>
                )}
                <Tooltip
                    content={
                        <CustomTooltip
                            payload={chartData.data}
                            mode={mode}
                            products={chartData && chartData.data && chartData.data.length > 0 && chartData.products}
                            productColors={barColors}
                            theme={theme}
                        />
                    }
                />
                <Legend
                    verticalAlign="top"
                    height={largeScreen ? 30 : 50}
                    formatter={(value: string) => <span style={{ fontSize: 13 }}>{titleCase(value.split('.')[0])}</span>}
                />
            </ComposedChart>
        </ResponsiveContainer>
    );

    return (
        <Card sx={{ width: '100%', marginBottom: theme.spacing(4) }}>
            <CardContent>
                <Grid container flexGrow={1} spacing={0} sx={{ marginBottom: theme.spacing(1) }}>
                    <Grid item xs={12}>
                        <Typography variant="h5" sx={{ marginBottom: theme.spacing(1) }}>
                            Monthly Sales (Last 12 Months)
                        </Typography>
                        <Typography variant="body1" sx={{ marginBottom: theme.spacing(1) }}>
                            The following graph shows a monthly comparisson of sales by product (bars) and as a total (line).
                        </Typography>
                        <Typography variant="body1">
                            Select between showing the total sales or as an average sales amount for each product and monthly average total.
                            Use the average numbers to track how the value of your products has progressed over time.
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <RadioGroup
                            value={mode}
                            onChange={e => {
                                setMode(e.currentTarget.value);
                            }}
                            sx={{ paddingBottom: theme.spacing(1) }}
                        >
                            <Grid container flexGrow={1} spacing={1} alignItems="center" sx={{ marginBottom: theme.spacing(2) }}>
                                <Grid item xs="auto">
                                    Show graph as:
                                </Grid>
                                <Grid item xs="auto">
                                    <FormControlLabel value={'total'} control={<Radio size="medium" />} label="Total" />
                                </Grid>
                                <Grid item xs="auto">
                                    <FormControlLabel value={'average'} control={<Radio size="medium" />} label="Average" />
                                </Grid>
                            </Grid>
                        </RadioGroup>
                    </Grid>
                </Grid>
                <Grid container flexGrow={1} spacing={0} sx={{ padding: 0 }}>
                    <Grid item xs={12} sx={{ height: { xs: 300, sm: 500 } }}>
                        {monthlySalesLoading ? <Skeleton /> : Chart(dataMain)}
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default SextforceMetricsAccountMonthlySalesGraph;
