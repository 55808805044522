import LoadingButton from '@mui/lab/LoadingButton';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, Grid, Skeleton, Theme, useTheme } from '@mui/material';
import { useDialog } from 'muibox';
import { useEffect, useState } from 'react';
import useSubscriberRelogin, { LoginSessionStatePayload } from '../../hooks/useSubscriberRelogin';
import useSubscribers from '../../hooks/useSubscribers';
import StyledCardTitleBar from '../common/StyledCardTitleBar';
import OnlyFansOtpCode from './OnlyFansOtpCode';
import OnlyFansOtpSelection from './OnlyFansOtpSelection';
import OnlyFansReloginEmailPassword from './OnlyFansReloginEmailPassword';
import OnlyFansReloginStatus from './OnlyFansReloginStatus';
import OnlyFansSelfieIDLink from './OnlyFansSelfieIDLink';

type Props = {
    subscriber: any;
    refetchSubscriber: () => void;
    open: boolean;
    onClose: () => void;
};

const OnlyFansReloginDialog = (props: Props) => {
    const { subscriber, refetchSubscriber, open, onClose } = props;
    const theme: Theme = useTheme();
    const dialog = useDialog();

    // Subscribers
    const { refetch: refetchSubscribers } = useSubscribers();

    const [loginMode, setLoginMode] = useState<'email' | 'advanced'>('email');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [userAgent, setUserAgent] = useState('');
    const [cookie, setCookie] = useState('');
    const [xBc, setXBc] = useState('');
    const [selectedOtpOption, setSelectedOtpOption] = useState<string | null>(null);
    const [otpCode, setOtpCode] = useState<string | null>(null);
    const [selfieLink, setSelfieLink] = useState<string | null>(null);

    // Relogin
    const [isReloginSessionInProgress, setIsReloginSessionInProgress] = useState(false);
    const [session, setSession] = useState<LoginSessionStatePayload | null>(null);
    const {
        data: subscriberRelogin,
        isLoading: isSubscriberReloginLoading,
        refetch: refetchSubscriberRelogin,
        subscriberReloginStart,
        isSubscriberReloginStartSubmitting,
        subscriberReloginCancel,
        subscriberReloginRequestOtpCode,
        subscriberReloginSubmitOtpCode,
    } = useSubscriberRelogin(subscriber._id, isReloginSessionInProgress);

    // Reset the form
    useEffect(() => {
        if (!open) {
            setEmail('');
            setPassword('');
            setUserAgent('');
            setCookie('');
            setXBc('');
            setSelectedOtpOption(null);
            setOtpCode(null);
            setSelfieLink(null);
            setIsReloginSessionInProgress(false);
            setSession(null);
            setLoginMode('email');
        }

        return () => {
            setEmail('');
            setPassword('');
            setUserAgent('');
            setCookie('');
            setXBc('');
            setSelectedOtpOption(null);
            setOtpCode(null);
            setSelfieLink(null);
            setIsReloginSessionInProgress(false);
            setSession(null);
            setLoginMode('email');
        };
    }, [open]);

    useEffect(() => {
        if (loginMode === 'email') {
            setUserAgent('');
            setCookie('');
            setXBc('');
        } else {
            setEmail('');
            setPassword('');
        }
    }, [loginMode]);

    const handleCloseDialog = (skipConfirmation: boolean) => {
        if (isReloginSessionInProgress) {
            if (skipConfirmation) {
                subscriberReloginCancel(subscriber._id).then(response => {
                    if (response.success) {
                        setIsReloginSessionInProgress(false);
                        onClose();
                    } else {
                        // show error
                        dialog.alert({
                            title: 'Error',
                            message: 'Failed to cancel relogin session. Please try again or contact Admin.',
                        });
                    }
                });
            } else {
                dialog
                    .confirm({
                        title: 'Warning',
                        message: 'Relogin session is still in progress. Are you sure you want to cancel?',
                    })
                    .then(() => {
                        subscriberReloginCancel(subscriber._id).then(response => {
                            if (response.success) {
                                setIsReloginSessionInProgress(false);
                                onClose();
                            } else {
                                // show error
                                dialog.alert({
                                    title: 'Error',
                                    message: 'Failed to cancel relogin session. Please try again or contact Admin.',
                                });
                            }
                        });
                    })
                    .catch(() => {});
            }
        } else {
            setIsReloginSessionInProgress(false);

            if (session) {
                subscriberReloginCancel(subscriber._id)
                    .then(response => {
                        if (response.success) {
                            setIsReloginSessionInProgress(false);
                            onClose();
                        }
                    })
                    .catch(() => {});
            }

            onClose();
        }
    };

    const handleReloginStart = async () => {
        if (subscriber && subscriber._id) {
            if (!selectedOtpOption) {
                await subscriberReloginStart(subscriber._id, email, password, userAgent, cookie, xBc).then(response => {
                    if (response.success && response.session) {
                        setSession(response.session);

                        if (response.session.result) {
                            if (response.session.result === 'success') {
                                setIsReloginSessionInProgress(false);
                                refetchSubscriber();
                                refetchSubscribers();
                                onClose();
                            } else if (response.session.result === 'wrong_password') {
                                dialog
                                    .alert({
                                        title: 'Error',
                                        message: 'Wrong email or password. Please try again.',
                                    })
                                    .then(() => {
                                        setTimeout(() => {
                                            handleCloseDialog(true);
                                        }, 500);
                                    });
                            } else if (response.session.result === 'wrong_email') {
                                dialog
                                    .alert({
                                        title: 'Error',
                                        message: 'Wrong email or password. Please try again.',
                                    })
                                    .then(() => {
                                        setTimeout(() => {
                                            handleCloseDialog(true);
                                        }, 500);
                                    });
                            } else if (response.session.result === 'invalid_credentials' || response.session.result === 'access_denied') {
                                dialog
                                    .alert({
                                        title: 'Error',
                                        message: 'Invalid session values (User-Agent, Cookie or x-bc). Please try again.',
                                    })
                                    .then(() => {
                                        setTimeout(() => {
                                            handleCloseDialog(true);
                                        }, 500);
                                    });
                            } else if (response.session.result === 'wrong_user') {
                                dialog
                                    .alert({
                                        title: 'Error',
                                        message: 'Login details do not match this account. Please try again.',
                                    })
                                    .then(() => {
                                        setTimeout(() => {
                                            handleCloseDialog(true);
                                        }, 500);
                                    });
                            } else if (
                                response.session.result === 'captcha_fail' ||
                                response.session.result === 'error' ||
                                response.session.result === 'failed' ||
                                response.session.result === 'timeout'
                            ) {
                                dialog
                                    .alert({
                                        title: 'Error',
                                        message: 'Could not login to OnlyFans. Please wait a minute and try again, or contact Admin.',
                                    })
                                    .then(() => {
                                        setTimeout(() => {
                                            handleCloseDialog(true);
                                        }, 500);
                                    });
                            } else if (response.session.result === 'unknown_subscriber') {
                                dialog
                                    .alert({
                                        title: 'Error',
                                        message: 'Subscriber not found. Please try again or contact Admin.',
                                    })
                                    .then(() => {
                                        setTimeout(() => {
                                            handleCloseDialog(true);
                                        }, 500);
                                    });
                            } else {
                                setIsReloginSessionInProgress(true);
                            }
                        }

                        setIsReloginSessionInProgress(true);
                    } else {
                        setSession(null);
                        setIsReloginSessionInProgress(false);

                        // show error
                        dialog
                            .alert({
                                title: 'Error',
                                message: 'Failed to relogin to OnlyFans. Please try again or contact Admin.',
                            })
                            .then(() => {
                                setTimeout(() => {
                                    handleCloseDialog(true);
                                }, 500);
                            });
                    }
                });
            } else {
                if (!otpCode) {
                    dialog.alert({
                        title: 'Error',
                        message: 'Please enter the 2FA code.',
                    });

                    return;
                }

                await subscriberReloginSubmitOtpCode(subscriber._id, selectedOtpOption, otpCode)
                    .then(async response => {
                        if (!response.success) {
                            // show error
                            dialog
                                .alert({
                                    title: 'Error',
                                    message: 'Failed to relogin to OnlyFans. Please try again or contact Admin.',
                                })
                                .then(() => {});
                        }

                        if (response.session?.result === 'success') {
                            setIsReloginSessionInProgress(false);
                            await refetchSubscriber();
                            await refetchSubscribers();
                            onClose();
                        } else {
                            refetchSubscriberRelogin();
                        }
                    })
                    .catch(() => {
                        refetchSubscriberRelogin();
                    });
            }
        }
    };

    useEffect(() => {
        if (subscriberRelogin) {
            setSession(subscriberRelogin.session);

            if (subscriberRelogin.running) {
                if (subscriberRelogin.session?.result === 'success') {
                    setIsReloginSessionInProgress(false);
                    refetchSubscriber();
                    refetchSubscribers();
                    onClose();
                } else if (subscriberRelogin.session?.result === 'wrong_password') {
                    setIsReloginSessionInProgress(false);

                    dialog
                        .alert({
                            title: 'Error',
                            message: 'Wrong email or password. Please try again.',
                        })
                        .then(() => {
                            setTimeout(() => {
                                handleCloseDialog(true);
                            }, 500);
                        });
                } else if (subscriberRelogin.session?.result === 'wrong_user') {
                    setIsReloginSessionInProgress(false);

                    dialog
                        .alert({
                            title: 'Error',
                            message: 'Login details do not match this account. Please try again.',
                        })
                        .then(() => {
                            setTimeout(() => {
                                handleCloseDialog(true);
                            }, 500);
                        });
                } else if (subscriberRelogin.session?.result === 'wrong_email') {
                    setIsReloginSessionInProgress(false);

                    dialog
                        .alert({
                            title: 'Error',
                            message: 'Wrong email or password. Please try again.',
                        })
                        .then(() => {
                            setTimeout(() => {
                                handleCloseDialog(true);
                            }, 500);
                        });
                } else if (
                    subscriberRelogin.session?.result === 'invalid_credentials' ||
                    subscriberRelogin.session?.result === 'access_denied'
                ) {
                    setIsReloginSessionInProgress(false);

                    dialog
                        .alert({
                            title: 'Error',
                            message: 'Invalid session values (User-Agent, Cookie or x-bc). Please try again.',
                        })
                        .then(() => {
                            setTimeout(() => {
                                handleCloseDialog(true);
                            }, 500);
                        });
                } else if (
                    subscriberRelogin.session?.result === 'captcha_fail' ||
                    subscriberRelogin.session?.result === 'error' ||
                    subscriberRelogin.session?.result === 'failed' ||
                    subscriberRelogin.session?.result === 'timeout'
                ) {
                    setIsReloginSessionInProgress(false);

                    dialog
                        .alert({
                            title: 'Error',
                            message: 'Could not login to OnlyFans. Please wait a minute and try again, or contact Admin.',
                        })
                        .then(() => {
                            setTimeout(() => {
                                handleCloseDialog(true);
                            }, 500);
                        });
                } else if (subscriberRelogin.session?.result === 'unknown_subscriber') {
                    setIsReloginSessionInProgress(false);

                    dialog
                        .alert({
                            title: 'Error',
                            message: 'Subscriber not found. Please try again or contact Admin.',
                        })
                        .then(() => {
                            setTimeout(() => {
                                handleCloseDialog(true);
                            }, 500);
                        });
                } else if (subscriberRelogin.session?.result === 'selfie_expired') {
                    setIsReloginSessionInProgress(false);

                    dialog
                        .alert({
                            title: 'Error',
                            message: 'Selfie ID link has expired. Please try again.',
                        })
                        .then(() => {
                            setTimeout(() => {
                                handleCloseDialog(true);
                            }, 500);
                        });
                } else if (subscriberRelogin.session?.result === 'selfie') {
                    if (subscriberRelogin.session.otpLink) {
                        setSelfieLink(subscriberRelogin.session.otpLink);
                    } else {
                        setSelfieLink(null);
                    }
                } else {
                    console.log('Relogin session in progress');
                    setIsReloginSessionInProgress(true);
                }
            } else {
                setIsReloginSessionInProgress(false);
            }
        } else {
            setSession(null);
            setIsReloginSessionInProgress(false);
        }
    }, [subscriberRelogin]);

    const handleRequestOtpCode = async (otpOption: string) => {
        subscriberReloginRequestOtpCode(subscriber._id, otpOption)
            .then(response => {
                setSession(response.session);
            })
            .catch(() => {});
    };

    const processingOtp =
        isReloginSessionInProgress &&
        session &&
        session.result === '2fa' &&
        session.loginResponse &&
        session.loginResponse.error.payload.otpState;

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth scroll="paper" disableEscapeKeyDown>
            <StyledCardTitleBar title={`Relogin to OnlyFans - ${subscriber.username}`} theme={theme} />
            <DialogContent>
                <DialogContentText>
                    Enter your new email and password to relogin to OnlyFans. These need to be the details of this actual account. The
                    system will not switch over to a linked page. If you haven't changed your email or password, leave both fileds blank.
                </DialogContentText>

                <Grid container spacing={1} alignItems={'center'} justifyContent={'center'}>
                    {isSubscriberReloginLoading && (
                        <Grid item xs={12}>
                            <Grid container justifyContent={'center'} sx={{ mt: 1 }}>
                                <Grid item xs={12}>
                                    <Skeleton variant="text" width={200} />
                                </Grid>
                            </Grid>
                        </Grid>
                    )}

                    {!isSubscriberReloginLoading && !processingOtp && !selfieLink && (
                        <Grid item xs={12}>
                            <OnlyFansReloginEmailPassword
                                loginMode={loginMode}
                                setLoginMode={setLoginMode}
                                email={email}
                                setEmail={setEmail}
                                password={password}
                                setPassword={setPassword}
                                userAgent={userAgent}
                                setUserAgent={setUserAgent}
                                cookie={cookie}
                                setCookie={setCookie}
                                xBc={xBc}
                                setXBc={setXBc}
                                loading={isSubscriberReloginStartSubmitting || isReloginSessionInProgress}
                            />
                        </Grid>
                    )}

                    {processingOtp && (
                        <Grid item xs={12}>
                            <OnlyFansOtpSelection
                                selectedOtpOption={selectedOtpOption}
                                setSelectedOtpOption={setSelectedOtpOption}
                                otpState={processingOtp}
                                handleRequestOtpCode={handleRequestOtpCode}
                            />
                        </Grid>
                    )}

                    {(selectedOtpOption === 'appOtp' || selectedOtpOption === 'phoneOtp' || selectedOtpOption === 'email') &&
                        processingOtp && (
                            <Grid item xs={12}>
                                <OnlyFansOtpCode
                                    selectedOtpOption={selectedOtpOption}
                                    otpState={processingOtp}
                                    otpCode={otpCode}
                                    setOtpCode={setOtpCode}
                                    handleRequestOtpCode={handleRequestOtpCode}
                                    loading={isSubscriberReloginStartSubmitting}
                                />
                            </Grid>
                        )}

                    {selfieLink && (
                        <Grid item xs={12}>
                            <OnlyFansSelfieIDLink selfieIdLink={selfieLink} />
                        </Grid>
                    )}
                </Grid>
            </DialogContent>

            {isReloginSessionInProgress && <OnlyFansReloginStatus session={session} submitting={isSubscriberReloginStartSubmitting} />}

            <DialogActions
                sx={{
                    backgroundColor: theme.palette.grey[100],
                }}
            >
                <Button
                    color="error"
                    onClick={() => {
                        handleCloseDialog(false);
                    }}
                >
                    {isReloginSessionInProgress ? 'Cancel' : 'Close'}
                </Button>
                <LoadingButton
                    variant="contained"
                    type="submit"
                    disabled={
                        ((selectedOtpOption === 'appOtp' || selectedOtpOption === 'phoneOtp' || selectedOtpOption === 'email') &&
                            !otpCode) ||
                        (!selectedOtpOption && processingOtp ? true : false) ||
                        (loginMode === 'advanced' && (!userAgent || !cookie || !xBc))
                    }
                    loading={isSubscriberReloginStartSubmitting}
                    onClick={() => {
                        handleReloginStart();
                    }}
                >
                    Relogin
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};

export default OnlyFansReloginDialog;
