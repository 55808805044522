import React from 'react';

export type UserContextType = {
    route: string;
    user: any;
    jwtToken: string;
    refreshToken: () => void;
    signOut: () => any;
    isAdmin: boolean;
};

export const UserContext = React.createContext<UserContextType>({
    route: '',
    user: {},
    jwtToken: '',
    refreshToken: () => {},
    signOut: () => {},
    isAdmin: false,
});
