import { Checkbox, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import StyledCard from '../../../../components/common/StyledCard';
import {
    arrayOnlyFansSubscriberHostessAutoReplyFreeloadersAction,
    OnlyFansSubscriberHostessAutoReplyExpired,
} from '../../../../types/hostessAutoReplyFreeloaders';
import { titleCase } from '../../../../utils/common';

const AutoReplyToExpiredSettingsCard = (props: {
    formData: OnlyFansSubscriberHostessAutoReplyExpired;
    setFormData: (data: OnlyFansSubscriberHostessAutoReplyExpired) => void;
    autoReplyExpired: OnlyFansSubscriberHostessAutoReplyExpired | null;
}) => {
    const { formData, setFormData } = props;

    return (
        <StyledCard>
            <Grid container spacing={1} flexGrow={0} alignItems="center" justifyContent="center" sx={{ mt: 1, mb: 2 }}>
                <Grid item xs={12}>
                    <Typography variant="h6">Whom to Skip</Typography>
                </Grid>
                <Grid item xs>
                    <Typography variant="body1">Skip Creators?</Typography>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        If checked, auto-reply will not reply to creators
                    </Typography>
                </Grid>
                <Grid item xs="auto">
                    <Checkbox
                        checked={formData && formData.excludeCreators ? true : false}
                        size="medium"
                        onChange={e => {
                            setFormData({ ...formData, excludeCreators: e.target.checked });
                        }}
                        sx={{
                            '& .MuiSvgIcon-root': {
                                fontSize: '1.5rem',
                            },
                        }}
                    />
                </Grid>
            </Grid>

            <Grid container spacing={1} flexGrow={0} alignItems="center" justifyContent="center" sx={{ mt: 1, mb: 2 }}>
                <Grid item xs={12}>
                    <Typography variant="h6">Action to Take</Typography>
                </Grid>
                <Grid item xs={12} md>
                    <Typography variant="body1">Action to Take on Last Reply</Typography>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        What to do when the freeloader has received the maximum number of strikes (the last reply)
                    </Typography>
                </Grid>
                <Grid item xs={12} md="auto">
                    <FormControl fullWidth>
                        <InputLabel id="action">Action</InputLabel>
                        <Select
                            labelId="action"
                            defaultValue={formData ? formData.action : 'none'}
                            value={formData && formData.action ? formData.action : 'none'}
                            onChange={e => {
                                setFormData({ ...formData, action: e.target.value });
                            }}
                            fullWidth
                            size="medium"
                            label="Action"
                        >
                            {arrayOnlyFansSubscriberHostessAutoReplyFreeloadersAction.map(item => (
                                <MenuItem value={item} key={item}>
                                    {titleCase(item)}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>

            <Grid container spacing={1} flexGrow={0} alignItems="center" justifyContent="center" sx={{ mb: 2 }}>
                <Grid item xs>
                    <Typography variant="body1">Auto Unmute/Unrestrict on Follow/Spending?</Typography>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        If checked, fans will be unmuted or unrestricted as necessary when they re-follow you or spend money.
                    </Typography>
                </Grid>
                <Grid item xs="auto">
                    <Checkbox
                        checked={formData && formData.autoUnrestrict ? true : false}
                        disabled={formData && formData.action !== 'restrict' && formData.action !== 'mute' ? true : false}
                        size="medium"
                        onChange={e => {
                            setFormData({ ...formData, autoUnrestrict: e.target.checked });
                        }}
                        sx={{
                            '& .MuiSvgIcon-root': {
                                fontSize: '1.5rem',
                            },
                        }}
                    />
                </Grid>
            </Grid>

            <Grid container spacing={1} flexGrow={0} alignItems="center" justifyContent="center" sx={{ mt: 1, mb: 2 }}>
                <Grid item xs={12}>
                    <Typography variant="h6">Message Settings</Typography>
                </Grid>
                <Grid item xs>
                    <Typography variant="body1">Delay Before Sending Reply?</Typography>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        If checked, the reply will be sent a random 1-2 minutes after the fan sent the message.
                    </Typography>
                </Grid>
                <Grid item xs="auto">
                    <Checkbox
                        checked={formData && formData.delayBeforeSendingMessage ? true : false}
                        size="medium"
                        onChange={e => {
                            setFormData({ ...formData, delayBeforeSendingMessage: e.target.checked });
                        }}
                        sx={{
                            '& .MuiSvgIcon-root': {
                                fontSize: '1.5rem',
                            },
                        }}
                    />
                </Grid>
            </Grid>

            <Grid container spacing={1} flexGrow={0} alignItems="center" justifyContent="center" sx={{ mb: 2 }}>
                <Grid item xs>
                    <Typography variant="body1">Send Reply On Purchases?</Typography>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        Normally, the system does not reply when the fan purchases something in the chat, but if checked, the system will
                        send a reply. This is useful if you want to remind the user that he is current expired and missing out on the good
                        stuff.
                    </Typography>
                </Grid>
                <Grid item xs="auto">
                    <Checkbox
                        checked={formData && formData.sendReplyOnPurchase ? true : false}
                        size="medium"
                        onChange={e => {
                            setFormData({ ...formData, sendReplyOnPurchase: e.target.checked });
                        }}
                        sx={{
                            '& .MuiSvgIcon-root': {
                                fontSize: '1.5rem',
                            },
                        }}
                    />
                </Grid>
            </Grid>

            <Grid container spacing={1} flexGrow={0} alignItems="center" justifyContent="center" sx={{ mb: 2 }}>
                <Grid item xs>
                    <Typography variant="body1">Repeat Replies When Script Finished?</Typography>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        If checked, the script will be repeated from the start when the fan has received the last reply and they message
                        again.
                    </Typography>
                </Grid>
                <Grid item xs="auto">
                    <Checkbox
                        checked={formData && formData.repeatScriptWhenFinished ? true : false}
                        size="medium"
                        onChange={e => {
                            setFormData({ ...formData, repeatScriptWhenFinished: e.target.checked });
                        }}
                        sx={{
                            '& .MuiSvgIcon-root': {
                                fontSize: '1.5rem',
                            },
                        }}
                    />
                </Grid>
            </Grid>
        </StyledCard>
    );
};

export default AutoReplyToExpiredSettingsCard;
