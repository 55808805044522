import { faMoneyBillTransfer } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AddIcon from '@mui/icons-material/Add';
import CurrencyPoundIcon from '@mui/icons-material/CurrencyPound';
import { Button, Card, Grid, Skeleton, Stack, Typography } from '@mui/material';
import { green, orange, red } from '@mui/material/colors';
import dinero from 'dinero.js';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import useSubscriber from '../../hooks/useSubscriber';
import { d2f } from '../../utils/common';
import SharedCreditTransferDialog from './SharedCreditTransferDialog';

const creditStatus = (amount: number): string => {
    if (amount === 0) {
        return 'none';
    }

    if (amount < 5) {
        return 'low';
    }

    return 'good';
};

type SharedCreditCardProps = {
    showTopUpButton: boolean;
};

const SharedCreditCard = (props: SharedCreditCardProps) => {
    const { showTopUpButton } = props;
    const { data: subscriber, isFetching: loading } = useSubscriber();
    const sharedCredit: number =
        !loading && subscriber.financial && subscriber.financial.sharedCredit ? d2f(subscriber.financial.sharedCredit) : 0;
    const creditStatusName: string = creditStatus(sharedCredit);
    const [transferCreditDialogOpen, setTransferCreditDialogOpen] = useState<boolean>(false);

    const status: any = {
        good: {
            color: green[900],
            background: green[100],
        },
        low: {
            color: orange[900],
            background: orange[100],
        },
        none: {
            color: red[900],
            background: red[100],
        },
    };

    return (
        <Card
            variant="elevation"
            sx={{
                backgroundColor: status[creditStatusName].background,
                height: '100%',
                display: 'flex',
                alignItems: 'stretch',
                justifyContent: 'space-between',
                padding: '16px',
            }}
        >
            {subscriber ? (
                <>
                    <Grid container flexGrow={1} alignItems="center" spacing={2}>
                        {!showTopUpButton && (
                            <Grid item xs="auto">
                                <CurrencyPoundIcon fontSize="large" htmlColor={status[creditStatusName].color} />
                            </Grid>
                        )}
                        {showTopUpButton && !loading && (
                            <Grid item xs="auto">
                                <Stack direction="column" spacing={1} alignItems="center">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                        endIcon={<AddIcon />}
                                        component={Link}
                                        to={`/subscribers/${subscriber._id}/account/sharedCreditCheckout`}
                                    >
                                        Top Up
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        size="large"
                                        endIcon={<FontAwesomeIcon icon={faMoneyBillTransfer} />}
                                        onClick={() => {
                                            setTransferCreditDialogOpen(true);
                                        }}
                                        disabled={sharedCredit === 0}
                                    >
                                        Move
                                    </Button>
                                </Stack>
                            </Grid>
                        )}
                        <Grid item xs>
                            <Grid container flexGrow={0} alignItems="center" spacing={2}>
                                <Grid item xs={12} alignSelf={'center'}>
                                    <Typography variant="subtitle1" align="right">
                                        Available Shared Credit
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} alignSelf={'center'}>
                                    {loading ? (
                                        <Skeleton />
                                    ) : (
                                        <Typography variant="h5" color={status[creditStatusName].color} textAlign="right">
                                            {dinero({
                                                amount: Math.trunc(sharedCredit * 100),
                                                currency: 'GBP',
                                            }).toFormat()}
                                        </Typography>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <SharedCreditTransferDialog
                        open={transferCreditDialogOpen}
                        onClose={() => {
                            setTransferCreditDialogOpen(false);
                        }}
                        sourceSubscriber={subscriber}
                    />
                </>
            ) : (
                <Skeleton />
            )}
        </Card>
    );
};

export default SharedCreditCard;
