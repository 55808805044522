import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import { Button, IconButton, Stack, TableCell, TableRow, Theme, Tooltip, Typography } from '@mui/material';
import dinero from 'dinero.js';
import { useDialog } from 'muibox';
import { useContext, useState } from 'react';
import {
    SextforceAutoCampaignTipReply,
    SextforceAutoCampaignTipReplyMessageDetails,
    SextforceAutoCampaignTipReplyWithMessageDetails,
} from '../../../../hooks/useSextforceAutoCampaignTipReply';
import { SettingsContext } from '../../../../store/SettingsContext';
import { UserContext } from '../../../../store/UserContext';
import { handleHttpError } from '../../../../utils/common';
import SextforceAutoCampaignTipReplyMessageDetailsDialog from './SextforceAutoCampaignTipReplyMessageDetailsDialog';
import SextforceAutoCampaignTipReplyTippersListDialog from './SextforceAutoCampaignTipReplyTippersListDialog';

type Props = {
    subscriber: any;
    autoCampaignTipReply: SextforceAutoCampaignTipReplyWithMessageDetails;
    tipOption: number;
    theme: Theme;
    createAutoCampaignTipReplyTipMessageDetails: (
        autoCampaignTipReply: SextforceAutoCampaignTipReply,
        autoCampaignTipReplyTipMessageDetails: SextforceAutoCampaignTipReplyMessageDetails,
    ) => Promise<SextforceAutoCampaignTipReplyMessageDetails | null>;
    updateAutoCampaignTipReplyTipMessageDetails: (
        autoCampaignTipReply: SextforceAutoCampaignTipReply,
        autoCampaignTipReplyTipMessageDetails: SextforceAutoCampaignTipReplyMessageDetails,
    ) => Promise<SextforceAutoCampaignTipReplyMessageDetails | null>;
    deleteAutoCampaignTipReplyTipMessageDetails: (
        autoCampaignTipReply: SextforceAutoCampaignTipReply,
        autoCampaignTipReplyTipMessageDetails: SextforceAutoCampaignTipReplyMessageDetails,
    ) => Promise<SextforceAutoCampaignTipReplyMessageDetails | null>;
    refetchAutoCampaignTipReplies: () => void;
};

const SextforceAutoCampaignTipReplyTableTipRow = (props: Props) => {
    const {
        subscriber,
        autoCampaignTipReply,
        tipOption,
        theme,
        refetchAutoCampaignTipReplies,
        createAutoCampaignTipReplyTipMessageDetails,
        updateAutoCampaignTipReplyTipMessageDetails,
        deleteAutoCampaignTipReplyTipMessageDetails,
    } = props;
    const userContext = useContext(UserContext);
    const settingsContext = useContext(SettingsContext);
    const dialog = useDialog();

    const [newMessageDetailsDialogOpen, setNewMessageDetailsDialogOpen] = useState(false);
    const [editMessageDetailsDialogOpen, setEditMessageDetailsDialogOpen] = useState(false);
    const [tippersListDialogOpen, setTippersListDialogOpen] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);

    // Tip amount formatted string
    const tipAmountFormatted = dinero({ amount: Math.trunc(tipOption * 100), currency: 'USD' }).toFormat();

    // Check if the message details exists for the tip option
    const messageDetailsExists = autoCampaignTipReply.messageDetails.find(messageDetail => messageDetail.tipAmount === tipOption);

    // Delte Auto Campaign Tip Reply
    const handleDelete = async (messageDetails: SextforceAutoCampaignTipReplyMessageDetails) => {
        if (!userContext.jwtToken || !settingsContext.apiKey) {
            return;
        }

        setIsDeleting(true);

        const data = await deleteAutoCampaignTipReplyTipMessageDetails(autoCampaignTipReply, messageDetails)
            .then(async response => {
                setIsDeleting(false);

                return response;
            })
            .catch(error => {
                setIsDeleting(false);
                handleHttpError(error, dialog);

                return null;
            });

        return data;
    };

    const handleDeleteAutoCampaignTipReply = async (messageDetails: SextforceAutoCampaignTipReplyMessageDetails) => {
        handleDelete(messageDetails).then(data => {
            if (!data) {
                return;
            }

            refetchAutoCampaignTipReplies();
        });
    };

    return (
        <TableRow>
            <TableCell>
                <Stack direction={'row'} spacing={0.5}>
                    <AttachMoneyIcon fontSize="small" />
                    <Typography variant="inherit" color="textPrimary" noWrap>
                        {tipAmountFormatted}
                    </Typography>
                </Stack>
            </TableCell>
            <TableCell align="center">
                {(
                    autoCampaignTipReply.messageDetails.find(messageDetail => messageDetail.tipAmount === tipOption)?.replyCount || 0
                ).toLocaleString()}
            </TableCell>
            <TableCell>
                <Stack direction="row" spacing={0.5} justifyContent="center">
                    {messageDetailsExists ? (
                        <>
                            <Tooltip title={`Edit Reply for Tip Amount ${tipAmountFormatted}`}>
                                <IconButton
                                    size="small"
                                    disabled={!autoCampaignTipReply.active}
                                    onClick={e => {
                                        e.stopPropagation();
                                        setEditMessageDetailsDialogOpen(true);
                                    }}
                                >
                                    <EditIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                            {editMessageDetailsDialogOpen && (
                                <SextforceAutoCampaignTipReplyMessageDetailsDialog
                                    subscriber={subscriber}
                                    autoCampaignTipReply={autoCampaignTipReply}
                                    initialValues={messageDetailsExists}
                                    tipOption={tipOption}
                                    theme={theme}
                                    open={editMessageDetailsDialogOpen}
                                    onClose={() => {
                                        setEditMessageDetailsDialogOpen(false);
                                    }}
                                    refetchAutoCampaignTipReplies={refetchAutoCampaignTipReplies}
                                    createAutoCampaignTipReplyTipMessageDetails={createAutoCampaignTipReplyTipMessageDetails}
                                    updateAutoCampaignTipReplyTipMessageDetails={updateAutoCampaignTipReplyTipMessageDetails}
                                />
                            )}
                        </>
                    ) : (
                        <>
                            <Tooltip title={`Create Reply for Tip Amount ${tipAmountFormatted}`}>
                                <Button
                                    size="small"
                                    variant="contained"
                                    disabled={!autoCampaignTipReply.active}
                                    onClick={e => {
                                        e.stopPropagation();
                                        setNewMessageDetailsDialogOpen(true);
                                    }}
                                >
                                    Create
                                </Button>
                            </Tooltip>
                            {newMessageDetailsDialogOpen && (
                                <SextforceAutoCampaignTipReplyMessageDetailsDialog
                                    subscriber={subscriber}
                                    autoCampaignTipReply={autoCampaignTipReply}
                                    initialValues={null}
                                    tipOption={tipOption}
                                    theme={theme}
                                    open={newMessageDetailsDialogOpen}
                                    onClose={() => {
                                        setNewMessageDetailsDialogOpen(false);
                                    }}
                                    refetchAutoCampaignTipReplies={refetchAutoCampaignTipReplies}
                                    createAutoCampaignTipReplyTipMessageDetails={createAutoCampaignTipReplyTipMessageDetails}
                                    updateAutoCampaignTipReplyTipMessageDetails={updateAutoCampaignTipReplyTipMessageDetails}
                                />
                            )}
                        </>
                    )}

                    {messageDetailsExists && (
                        <>
                            <Tooltip title="List of Tippers">
                                <IconButton
                                    size="small"
                                    color="info"
                                    onClick={e => {
                                        e.stopPropagation();
                                        setTippersListDialogOpen(true);
                                    }}
                                >
                                    <PeopleAltIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                            {tippersListDialogOpen && (
                                <SextforceAutoCampaignTipReplyTippersListDialog
                                    subscriber={subscriber}
                                    autoCampaignTipReply={autoCampaignTipReply}
                                    amount={tipOption}
                                    open={tippersListDialogOpen}
                                    onClose={() => setTippersListDialogOpen(false)}
                                    theme={theme}
                                />
                            )}

                            <Tooltip title={`Delete Tip Campaign Reply for Tip Amount ${tipAmountFormatted}`}>
                                <span>
                                    <IconButton
                                        color="error"
                                        size="small"
                                        disabled={!autoCampaignTipReply.active || isDeleting}
                                        onClick={e => {
                                            e.stopPropagation();

                                            dialog
                                                .confirm({
                                                    title: `Delete Tip Campaign Reply`,
                                                    message: 'Are you sure you want to delete this tip reply?',
                                                    cancel: {
                                                        text: 'Cancel',
                                                        variant: 'outlined',
                                                    },
                                                    ok: {
                                                        text: 'Delete',
                                                        variant: 'contained',
                                                        color: 'error',
                                                    },
                                                })
                                                .then(() => {
                                                    if (autoCampaignTipReply._id) {
                                                        handleDeleteAutoCampaignTipReply(messageDetailsExists);
                                                    }
                                                })
                                                .catch(() => {});
                                        }}
                                    >
                                        <DeleteIcon fontSize="small" />
                                    </IconButton>
                                </span>
                            </Tooltip>
                        </>
                    )}
                </Stack>
            </TableCell>
        </TableRow>
    );
};

export default SextforceAutoCampaignTipReplyTableTipRow;
