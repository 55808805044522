import { Card, CardContent, Grid, Skeleton, Typography, useMediaQuery } from '@mui/material';
import { Theme } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { CartesianGrid, ComposedChart, Legend, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { zeroPad } from '../../../../../utils/common';

const generateData = (data: any[]) => {
    if (data.length === 0) {
        return [];
    }

    const result = {
        data: data.map(d => {
            return {
                date: `${zeroPad(d._id.year, 4)}-${zeroPad(d._id.month, 2)}-${zeroPad(d._id.day, 2)}`,
                counters: d.counters,
            };
        }),
    };

    return result;
};

const CustomTooltip = ({ payload, products, productColors, mode, theme }: any) => {
    if (payload && payload.length) {
        return (
            <div>
                <Grid
                    container
                    flexGrow={0}
                    alignItems="center"
                    spacing={1}
                    sx={{
                        backgroundColor: '#fff',
                        borderRadius: 4,
                        border: '1px solid #999',
                        maxWidth: 230,
                    }}
                >
                    <Grid item xs={12}>
                        <span style={{ fontWeight: 'bold' }}>{payload[0].payload.date}</span>
                    </Grid>
                    <Grid item xs={12}>
                        {payload.map((item: any, index: number) => (
                            <React.Fragment key={index}>
                                {item.dataKey === 'counters.subscriptions.active' && (
                                    <Grid
                                        container
                                        flexGrow={1}
                                        alignItems="center"
                                        sx={{ color: theme.palette.secondary.main, marginTop: theme.spacing(1) }}
                                    >
                                        <Grid item xs={6}>
                                            Following:
                                        </Grid>
                                        <Grid item xs={6} textAlign={'right'} sx={{ paddingRight: '8px' }}>
                                            {item.value.toLocaleString()}
                                        </Grid>
                                    </Grid>
                                )}

                                {item.dataKey === 'counters.subscribers.active' && (
                                    <Grid
                                        container
                                        flexGrow={1}
                                        alignItems="center"
                                        sx={{ color: theme.palette.primary.main, marginTop: theme.spacing(1) }}
                                    >
                                        <Grid item xs={6}>
                                            Fans:
                                        </Grid>
                                        <Grid item xs={6} textAlign={'right'} sx={{ paddingRight: '8px' }}>
                                            {item.value.toLocaleString()}
                                        </Grid>
                                    </Grid>
                                )}
                            </React.Fragment>
                        ))}
                    </Grid>
                </Grid>
            </div>
        );
    }

    return null;
};

type Props = {
    counters: any;
    countersLoading: boolean;
    theme: Theme;
};

const SextforceMetricsAccountFansCounters = (props: Props) => {
    const { counters, countersLoading, theme } = props;
    const [dataMain, setDataMain] = useState<any>({ data: [], min: 0, max: 0, average: 0 });
    const largeScreen = useMediaQuery(theme.breakpoints.up('sm'));

    useEffect(() => {
        if (!countersLoading && counters && counters.length > 0) {
            setDataMain(generateData(counters));
        } else {
            setDataMain([]);
        }
    }, [counters, countersLoading]);

    const Chart = (chartData: any) => (
        <ResponsiveContainer width="100%" height="100%">
            <ComposedChart
                data={chartData && chartData.data ? chartData.data : []}
                margin={{
                    top: 0,
                    right: largeScreen ? 10 : 0,
                    left: largeScreen ? 20 : 10,
                    bottom: 50,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" stroke={theme.palette.grey[400]} />
                <XAxis
                    dataKey="date"
                    name="Date"
                    domain={chartData && chartData.data && chartData.data.length > 0 ? ['dataMin', 'dataMax'] : ['auto', 'auto']}
                    interval={'preserveStartEnd'}
                    tick={{ fontSize: 13 }}
                    angle={-90}
                    dy={35}
                    dx={-5}
                />
                <YAxis
                    yAxisId="fans"
                    dataKey="counters.subscribers.active"
                    scale={'linear'}
                    name="Fans"
                    domain={[(dataMin: number) => Math.abs(dataMin * 0.9), (dataMax: number) => Math.abs(dataMax * 1.1)]}
                    tickFormatter={(value: number) => value.toLocaleString()}
                    tick={{ fill: theme.palette.primary.main, fontSize: 13 }}
                />
                <Line
                    yAxisId="fans"
                    type="monotone"
                    dataKey="counters.subscribers.active"
                    stroke={theme.palette.primary.main}
                    activeDot={{ r: 3 }}
                    dot={{ r: 0 }}
                />
                <YAxis
                    yAxisId="following"
                    dataKey={'counters.subscriptions.active'}
                    scale={'linear'}
                    name="Following"
                    domain={[(dataMin: number) => Math.abs(dataMin * 0.9), (dataMax: number) => Math.abs(dataMax * 1.1)]}
                    tickFormatter={(value: number) => value.toLocaleString()}
                    tick={{ fill: theme.palette.secondary.main, fontSize: 13 }}
                    orientation="right"
                />
                <Line
                    yAxisId="following"
                    type="monotone"
                    dataKey="counters.subscriptions.active"
                    stroke={theme.palette.secondary.main}
                    activeDot={{ r: 3 }}
                    dot={{ r: 0 }}
                />
                <Tooltip content={<CustomTooltip payload={chartData.data} theme={theme} />} />
                <Legend
                    verticalAlign="top"
                    height={36}
                    formatter={(value: string) => (
                        <span style={{ fontSize: 13 }}>{value === 'counters.subscriptions.active' ? 'Following' : 'Fans'}</span>
                    )}
                />
            </ComposedChart>
        </ResponsiveContainer>
    );

    return (
        <Card sx={{ width: '100%', marginBottom: theme.spacing(4) }}>
            <CardContent>
                <Grid container flexGrow={1} spacing={0} sx={{ marginBottom: theme.spacing(1) }}>
                    <Grid item xs={12}>
                        <Typography variant="h5" sx={{ marginBottom: theme.spacing(1) }}>
                            Daily Fans and Following (Last 12 Months)
                        </Typography>
                        <Typography variant="body1" sx={{ marginBottom: theme.spacing(1) }}>
                            The following graph shows a daily progress of your active fans and following count.
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container flexGrow={1} spacing={0} sx={{ padding: 0 }}>
                    <Grid item xs={12} sx={{ height: { xs: 300, sm: 500 } }}>
                        {countersLoading ? <Skeleton /> : Chart(dataMain)}
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default SextforceMetricsAccountFansCounters;
