import { Card, Grid, Typography } from '@mui/material';
import { Theme } from '@mui/system';
// import WarningIcon from '@mui/icons-material/Warning';
import PeopleIcon from '@mui/icons-material/People';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useSextforceMetricsCrossReferenceMetricEarnings from '../../../../hooks/useSextforceMetricsCrossReferenceMetricEarnings';
import useSubscribers from '../../../../hooks/useSubscribers';
import { decimal128OrNumberAdd } from '../../../../utils/common';
import SextforceMetricsCrossReferenceEarningsAmounts from './SextforceMetricsCrossReferenceEarningsAmounts';
import SextforceMetricsCrossReferenceEarningsSubscriberSelector from './SextforceMetricsCrossReferenceEarningsSubscriberSelector';

type Props = {
    metric: any;
    metricType: 'trial' | 'campaign';
    showEarningsWithSubscriptions: boolean;
    showEarningsAsGross: boolean;
    theme: Theme;
};

const SextforceMetricsCrossReferenceEarnings = (props: Props) => {
    const { metric, metricType, showEarningsAsGross, showEarningsWithSubscriptions, theme } = props;
    const params = useParams();
    const { data: subscribers, isLoading: subscribersLoading } = useSubscribers();
    const [crossReferenceSubscriberId, setCrossReferenceSubscriberId] = useState<string>('none');
    const [totalBefore, setTotalBefore] = useState<number>(0);
    const [totalAfter, setTotalAfter] = useState<number>(0);
    const [totalBeforeAndAfter, setTotalBeforeAndAfter] = useState<number>(0);
    const { data: sextforceMetricsCrossReferenceMetricEarnings, isLoading: sextforceMetricsCrossReferenceMetricEarningsLoading } =
        useSextforceMetricsCrossReferenceMetricEarnings(
            metric?._id || '',
            metricType,
            crossReferenceSubscriberId === 'none' ? '' : crossReferenceSubscriberId,
        );

    useEffect(() => {
        if (subscribers && Array.isArray(subscribers) && crossReferenceSubscriberId === 'none' && subscribers.length === 2) {
            const subscriber = subscribers.find(subscriber => subscriber._id !== params.userId);

            if (subscriber) {
                setCrossReferenceSubscriberId(subscriber._id);
            }
        }
    }, [subscribers, crossReferenceSubscriberId, params.userId]);

    useEffect(() => {
        if (sextforceMetricsCrossReferenceMetricEarnings) {
            if (showEarningsWithSubscriptions) {
                setTotalBefore(
                    showEarningsAsGross
                        ? sextforceMetricsCrossReferenceMetricEarnings.totalBefore
                        : sextforceMetricsCrossReferenceMetricEarnings.totalBeforeNet,
                );
                setTotalAfter(
                    showEarningsAsGross
                        ? sextforceMetricsCrossReferenceMetricEarnings.total
                        : sextforceMetricsCrossReferenceMetricEarnings.totalNet,
                );
                setTotalBeforeAndAfter(
                    showEarningsAsGross
                        ? decimal128OrNumberAdd(
                              sextforceMetricsCrossReferenceMetricEarnings.totalBefore,
                              sextforceMetricsCrossReferenceMetricEarnings.total,
                          )
                        : decimal128OrNumberAdd(
                              sextforceMetricsCrossReferenceMetricEarnings.totalBeforeNet,
                              sextforceMetricsCrossReferenceMetricEarnings.totalNet,
                          ),
                );
            } else {
                setTotalBefore(
                    showEarningsAsGross
                        ? sextforceMetricsCrossReferenceMetricEarnings.totalBeforeWithoutSubscription
                        : sextforceMetricsCrossReferenceMetricEarnings.totalBeforeNetWithoutSubscription,
                );
                setTotalAfter(
                    showEarningsAsGross
                        ? sextforceMetricsCrossReferenceMetricEarnings.totalWithoutSubscription
                        : sextforceMetricsCrossReferenceMetricEarnings.totalNetWithoutSubscription,
                );
                setTotalBeforeAndAfter(
                    showEarningsAsGross
                        ? decimal128OrNumberAdd(
                              sextforceMetricsCrossReferenceMetricEarnings.totalBeforeWithoutSubscription,
                              sextforceMetricsCrossReferenceMetricEarnings.totalWithoutSubscription,
                          )
                        : decimal128OrNumberAdd(
                              sextforceMetricsCrossReferenceMetricEarnings.totalBeforeNetWithoutSubscription,
                              sextforceMetricsCrossReferenceMetricEarnings.totalNetWithoutSubscription,
                          ),
                );
            }
        } else {
            setTotalBefore(0);
            setTotalAfter(0);
        }
    }, [sextforceMetricsCrossReferenceMetricEarnings, showEarningsWithSubscriptions, showEarningsAsGross]);

    return (
        <>
            <Grid container spacing={0} flexGrow={0} justifyContent="center" sx={{ mt: 1, mb: 1 }}>
                <Grid item xs={12}>
                    <Card
                        variant="elevation"
                        sx={{
                            marginBottom: 0,
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            p: 2,
                        }}
                    >
                        <Grid container flexGrow={1} alignItems="center" spacing={2}>
                            <Grid item xs={12}>
                                <Grid container flexGrow={1} alignItems="center" spacing={0}>
                                    <Grid item xs={'auto'}>
                                        <PeopleIcon fontSize="large" htmlColor={theme.palette.success.dark} />
                                    </Grid>
                                    <Grid item xs md>
                                        <Typography variant="subtitle1" align="right">
                                            Cross-Pollination Earnings{' '}
                                            <small style={{ color: theme.palette.text.secondary }}>
                                                ({showEarningsAsGross ? 'gross' : 'net'})
                                            </small>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item xs={12}>
                                <Typography variant="inherit" alignItems={'center'}>
                                    How much you've earned from the fans who came through this {metricType} on another account you have
                                    Sextforce on <strong>before</strong> the time they came through this {metricType} and{' '}
                                    <strong>after</strong> until now.
                                </Typography>
                            </Grid>

                            <Grid item xs={12} sm>
                                <SextforceMetricsCrossReferenceEarningsSubscriberSelector
                                    subscribers={subscribers}
                                    subscribersLoading={subscribersLoading}
                                    crossReferenceSubscriberId={crossReferenceSubscriberId}
                                    setCrossReferenceSubscriberId={setCrossReferenceSubscriberId}
                                />
                            </Grid>

                            <Grid item xs={12} sm="auto">
                                <SextforceMetricsCrossReferenceEarningsAmounts
                                    sextforceMetricsCrossReferenceMetricEarningsLoading={
                                        sextforceMetricsCrossReferenceMetricEarningsLoading
                                    }
                                    totalBefore={totalBefore}
                                    totalAfter={totalAfter}
                                    totalBeforeAndAfter={totalBeforeAndAfter}
                                />
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
            </Grid>
        </>
    );
};

export default SextforceMetricsCrossReferenceEarnings;
