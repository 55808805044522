import { EditorState } from 'draft-js';
import moment from 'moment';

/**
 * Parses the raw follow-back data and filters it out by user group
 * @param targetUsers Target users group
 * @param data Raw data array from the server
 * @returns Parased array of data filtered by given group
 */
export const parseMessageUsersSessionData = (targetUsers: string, data: any[]): any[] => {
    let mainFiltered: any[] = [];

    if (targetUsers === 'all') {
        mainFiltered = data;
    } else {
        mainFiltered = data.filter(item => {
            if (targetUsers === 'fansExpiredPriority') {
                return item.targetUsers === 'fansExpired' && item.filter;
            } else if (targetUsers === 'fansExpired') {
                return item.targetUsers === 'fansExpired' && !item.filter;
            } else {
                return item.targetUsers === targetUsers;
            }
        });
    }

    const result = mainFiltered.map(item => {
        return {
            time: moment(item.startedAt).valueOf(),
            targetUsers: item.targetUsers,
            priority: item.filter ? true : false,
            messagesSent: item.messagesSent || 0,
        };
    });

    return result;
};

/**
 * Sums up the total number of users followed in the given parsed data array
 * @param data Parsed array of follow back data
 * @returns Total followed number
 */
export const messagesSentParsedDataTotalSent = (data: any[]): number => {
    let total = 0;

    data.forEach(item => (total += item.messagesSent ? item.messagesSent : 0));

    return total;
};

/**
 * Identifies the type of a given wallet address
 * @param word Wallet address to identify
 * @returns Type of the wallet address
 */
export const identifyWalletType = (word: string): string | null => {
    // Ethereum, Polygon, and ERC20/ERC721 tokens (including USDT and USDC on these networks)
    if (/^0x[a-fA-F0-9]{40}$/.test(word)) {
        return 'Ethereum/Polygon compatible (could be ETH, MATIC, USDT, USDC, or other ERC20/ERC721 tokens)';
    }

    // Bitcoin
    if (/^[13][a-km-zA-HJ-NP-Z1-9]{25,34}$/.test(word) || /^bc1[a-z0-9]{39,59}$/.test(word)) {
        return 'Bitcoin';
    }

    // Tron (34 characters, starts with T)
    if (/^T[a-zA-Z0-9]{33}$/.test(word)) {
        return 'Tron (could be TRX or TRC20 tokens like USDT)';
    }

    // Solana (32-44 characters, base58 encoded)
    if (/^[1-9A-HJ-NP-Za-km-z]{32,44}$/.test(word)) {
        return 'Solana (could be SOL, USDC, or other SPL tokens)';
    }

    // Algorand (58 characters, starts with A)
    if (/^A[A-Z2-7]{57}$/.test(word)) {
        return 'Algorand (could be ALGO, USDC, or other ASA tokens)';
    }

    // Ripple (25-35 characters, starts with r)
    if (/^r[0-9a-zA-Z]{24,34}$/.test(word)) {
        return 'Ripple';
    }

    // Litecoin (26-35 characters, starts with L, M, or ltc1)
    if (/^[LM3][a-km-zA-HJ-NP-Z1-9]{26,33}$/.test(word) || /^ltc1[a-z0-9]{39,59}$/.test(word)) {
        return 'Litecoin';
    }

    // Dogecoin (34 characters, starts with D)
    if (/^D[5-9A-HJ-NP-U][1-9A-HJ-NP-Za-km-z]{32}$/.test(word)) {
        return 'Dogecoin';
    }

    // Cardano (58-103 characters, starts with addr1)
    if (/^addr1[a-z0-9]{58,103}$/.test(word)) {
        return 'Cardano';
    }

    // Polkadot (47-48 characters, starts with 1)
    if (/^1[a-zA-Z0-9]{46,47}$/.test(word)) {
        return 'Polkadot';
    }

    // Stellar (56 characters, starts with G)
    if (/^G[A-Z2-7]{55}$/.test(word)) {
        return 'Stellar';
    }

    // Monero (95 characters, starts with 4)
    if (/^4[0-9AB][1-9A-HJ-NP-Za-km-z]{93}$/.test(word)) {
        return 'Monero';
    }

    // Tezos (36 characters, starts with tz1, tz2, or tz3)
    if (/^tz[1-3][a-zA-Z0-9]{33}$/.test(word)) {
        return 'Tezos';
    }

    // If no match is found
    return null;
};

// Function to extract words while ignoring OnlyFans URLs
export const extractWordsFromTextWithoutOnlyFansURL = (text: string): string[] => {
    const words: string[] = [];
    const regex = /\S+/g;
    let match: RegExpExecArray | null;

    do {
        match = regex.exec(text);
        if (match !== null) {
            const word = match[0];
            if (!word.toLowerCase().startsWith('https://onlyfans.com/')) {
                words.push(word);
            }
        }
    } while (match !== null);

    return words;
};

/**
 * Checks if the given message contains any restricted words
 * @param subscriber Subscriber object
 * @param message Message to check for restricted words
 * @param restrictedWords Set of restricted words
 * @returns Array of restricted words found in the message
 */
export const getRestrictedWordsInMessage = (subscriber: any, message: string, restrictedWords: Set<string>): string[] => {
    // Extract words, ignoring OnlyFans URLs
    const wordsInMessage = extractWordsFromTextWithoutOnlyFansURL(message.toLowerCase());

    // Remove emojis from each word
    const noEmojis = wordsInMessage.map(word => word.replace(/[\u{1F600}-\u{1F64F}]/gu, ''));

    // Filter out empty strings after emoji removal
    const cleanWords = noEmojis.filter(word => word.length > 0);

    const restrictedWordsFound = cleanWords.filter(word => restrictedWords.has(word));

    if (subscriber.sextforce?.bigBrother?.restrictedWordsWhiteList?.includes(restrictedWordsFound)) {
        // Remove any words that are in the whitelist
        restrictedWordsFound.filter(word => !subscriber.sextforce?.bigBrother?.restrictedWordsWhiteList?.includes(word));
    }

    if (subscriber.sextforce?.bigBrother?.restrictedWordsBlackList?.checkCryptoWallets) {
        // Test all words for mentions of a crypto wallet
        const walletWords = wordsInMessage.filter(word => identifyWalletType(word) !== null);

        if (walletWords.length > 0) {
            restrictedWordsFound.push(...walletWords);
        }
    }

    return restrictedWordsFound;
};

/**
 * Checks if the given editor state contains any restricted words
 * @param subscriber Subscriber object
 * @param editorState Editor state
 * @param restrictedWords Set of restricted words
 * @returns Array of restricted words found in the editor state
 */
export const getRestrictedWordsInEditorState = (subscriber: any, editorState: EditorState, restrictedWords: Set<string>): string[] => {
    const rawText = editorState.getCurrentContent().getPlainText();

    return getRestrictedWordsInMessage(subscriber, rawText, restrictedWords);
};
