import moment from 'moment';

/**
 * Parses the raw follow-back data and filters it out by user group
 * @param targetUsers Target users group
 * @param data Raw data array from the server
 * @returns Parased array of data filtered by given group
 */
export const parseFollowBackSessionData = (targetUsers: string, data: any[]): any[] => {
    let mainFiltered: any[] = [];

    if (targetUsers === 'all') {
        mainFiltered = data;
    } else {
        mainFiltered = data.filter(item => {
            if (targetUsers === 'fansExpiredPriority') {
                return item.targetUsers === 'fansExpired' && item.filter;
            } else if (targetUsers === 'fansExpired') {
                return item.targetUsers === 'fansExpired' && !item.filter;
            } else {
                return item.targetUsers === targetUsers;
            }
        });
    }

    const result = mainFiltered.map(item => {
        return {
            time: moment(item.startedAt).valueOf(),
            targetUsers: item.targetUsers,
            priority: item.filter ? true : false,
            followed: item.followed,
            following: item.followingAfter,
        };
    });

    return result;
};

/**
 * Sums up the total number of users followed in the given parsed data array
 * @param data Parsed array of follow back data
 * @returns Total followed number
 */
export const followBackParsedDatatotalFollowed = (data: any[]): number => {
    let total = 0;

    data.forEach(item => (total += item.followed ? item.followed : 0));

    return total;
};
