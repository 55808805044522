import * as yup from 'yup';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Typography, Grid, InputAdornment, Button, Dialog, DialogActions, DialogContent, DialogTitle, Alert } from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';
import { Theme } from '@mui/system';
import FormTextField from '../../../forms/FormTextField';
import { AgentInputs } from '../../../../pages/services/sextforce/SextforceManageAgents';
import FormSelect from '../../../forms/FormSelect';
import { d2f } from '../../../../utils/common';

const agentInputsSchema = yup
    .object({
        name: yup.string().required(),
        fraction: yup
            .number()
            .min(0)
            .max(99)
            .required()
            .transform((value, originalValue, schema) => value / 100),
        type: yup.string(),
        role: yup.string(),
        commissionPercent: yup.number().min(0).max(100).required(),
    })
    .required();

type SextforceManageAgentsEditAgentDialogProps = {
    agent: any | null;
    onSubmit: (id: string, data: any) => void;
    availableFractions: number[];
    open: boolean;
    onCancel: () => void;
};

const SextforceManageAgentEditAgentDialog = (props: SextforceManageAgentsEditAgentDialogProps) => {
    const { agent, onSubmit, availableFractions, open, onCancel } = props;
    const theme: Theme = useTheme();
    const {
        control,
        setValue,
        handleSubmit,
        formState: { errors },
    } = useForm<AgentInputs>({ defaultValues: agent, resolver: yupResolver(agentInputsSchema) });
    const [editedAgent, setEditedAgent] = useState<any | null>(null);

    useEffect(() => {
        if (agent) {
            setEditedAgent({
                name: agent.name,
                fraction: d2f(agent.fraction) * 100,
                type: agent.type,
                role: agent.role,
                commissionPercent: agent.commissionPercent,
            });
        }
    }, [agent, setEditedAgent]);

    useEffect(() => {
        if (editedAgent) {
            setValue('name', editedAgent.name);
            setValue('fraction', editedAgent.fraction);
            setValue('type', editedAgent.type);
            setValue('role', editedAgent.role);
            setValue('commissionPercent', editedAgent.commissionPercent);
        }
    }, [editedAgent, setValue]);

    const handleFormSubmit: SubmitHandler<AgentInputs> = (data: any) => {
        onSubmit(agent._id, data);
    };

    return (
        <Dialog fullWidth={true} maxWidth="md" open={open} onClose={onCancel}>
            <DialogTitle>Edit Agent</DialogTitle>
            <form onSubmit={handleSubmit(handleFormSubmit)}>
                <DialogContent>
                    <Grid container flexGrow={1} alignItems="center" spacing={2} sx={{ marginBottom: theme.spacing(4) }}>
                        <Grid item xs={12}>
                            <Alert variant="filled" color="warning">
                                <Typography variant="body1">
                                    WARNING: Be careful when changing the fraction value of an agent! All historic data will be reassigned
                                    to the new fraction value!
                                </Typography>
                            </Alert>
                        </Grid>
                    </Grid>
                    <input type="hidden" name="_id" value={editedAgent && editedAgent._id} />
                    <Grid
                        container
                        flexGrow={1}
                        alignItems="center"
                        spacing={2}
                        sx={{ marginTop: theme.spacing(4), marginBottom: theme.spacing(4) }}
                    >
                        <Grid item xs={12} md={6} lg={2}>
                            <FormSelect
                                name="fraction"
                                label="Fraction"
                                control={control}
                                errors={errors}
                                required={true}
                                fullWidth
                                value={editedAgent && 'fraction' in editedAgent ? editedAgent.fraction : ''}
                                options={[...availableFractions, ...[editedAgent && 'fraction' in editedAgent && editedAgent.fraction]].map(
                                    item => {
                                        return {
                                            value: item,
                                            label: (item / 100).toLocaleString(undefined, { minimumFractionDigits: 2 }),
                                        };
                                    },
                                )}
                                sx={{ minWidth: 120 }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <FormTextField
                                name="name"
                                label="Agent Name"
                                fullWidth
                                control={control}
                                errors={errors}
                                required={true}
                                value={editedAgent && editedAgent.name}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={2}>
                            <FormTextField
                                name="type"
                                label="Type"
                                fullWidth
                                control={control}
                                errors={errors}
                                {...(editedAgent && 'type' in editedAgent && { value: editedAgent.type })}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={2}>
                            <FormTextField
                                name="role"
                                label="Role"
                                fullWidth
                                control={control}
                                errors={errors}
                                {...(editedAgent && 'role' in editedAgent && { value: editedAgent.role })}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={2}>
                            <FormTextField
                                name="commissionPercent"
                                label="Commission"
                                fullWidth
                                control={control}
                                errors={errors}
                                value={editedAgent && editedAgent.commissionPercent}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                }}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCancel}>Cancel</Button>
                    <Button type="submit" variant="contained">
                        Update
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default SextforceManageAgentEditAgentDialog;
