import dinero from 'dinero.js';
import { useState } from 'react';
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { Theme } from '@mui/system';
import { AlertColor, Card, CardContent, Grid, Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { Stripe, StripeElements } from '@stripe/stripe-js';

type SharedCreditCheckoutFormProps = {
    theme: Theme;
    returnUrl: string;
    amount: number;
    email: string;
    isEmailValid: boolean;
    setMessage: (message: string | null | undefined) => void;
    setMessageType: (type: AlertColor) => void;
};

const SharedCreditCheckoutForm = (props: SharedCreditCheckoutFormProps) => {
    const { theme, returnUrl, amount, email, isEmailValid, setMessage, setMessageType } = props;
    const stripe: Stripe | null = useStripe();
    const elements: StripeElements | null = useElements();

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleSubmit = async (e: any) => {
        e.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        setIsLoading(true);

        const { error } = await stripe.confirmPayment({
            elements,
            confirmParams: {
                // Make sure to change this to your payment completion page
                return_url: window.location.href,
                receipt_email: email,
            },
        });

        // This point will only be reached if there is an immediate error when
        // confirming the payment. Otherwise, your customer will be redirected to
        // your `return_url`. For some payment methods like iDEAL, your customer will
        // be redirected to an intermediate site first to authorize the payment, then
        // redirected to the `return_url`.
        if (error.type === 'card_error' || error.type === 'validation_error') {
            setMessage(error.message);
            setMessageType('error');
        } else {
            setMessage('An unexpected error occurred.');
            setMessageType('error');
        }

        setIsLoading(false);
    };

    return (
        <Card variant="elevation">
            <CardContent>
                <form id="payment-form" onSubmit={handleSubmit}>
                    <Grid container spacing={2} flexGrow={0} justifyContent="center" sx={{ marginBottom: theme.spacing(2) }}>
                        <Grid item xs={12}>
                            <Typography variant="h6">Checkout</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body1">
                                You are buying <strong>{dinero({ amount: amount * 100, currency: 'GBP' }).toFormat()}</strong> of credit
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <PaymentElement id="payment-element" />
                        </Grid>
                        <Grid item xs={12}>
                            <LoadingButton
                                type="submit"
                                variant="contained"
                                disabled={isLoading || !stripe || !elements || !isEmailValid}
                                size="large"
                                loading={isLoading}
                                sx={{ width: '100%' }}
                            >
                                Pay Now
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </form>
            </CardContent>
        </Card>
    );
};

export default SharedCreditCheckoutForm;
