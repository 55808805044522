import { Alert, AlertColor, Backdrop, Card, CardContent, CircularProgress, Grid, Typography } from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';
import { Theme } from '@mui/system';
import moment from 'moment';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import BackNavigationButton from '../../../components/common/BackNavigationButton';
import PageContainer from '../../../components/common/PageContainter';
import ServiceActionsBlocks from '../../../components/common/ServiceActions';
import LogoSextforce from '../../../components/icons/LogoSextforce';
import SextforceOvewviewStats from '../../../components/services/sextforce/overview/SextforceOverviewStats';
import SextforceServiceDescription from '../../../components/services/sextforce/SextforceServiceDescription';
import useSextforceOverview from '../../../hooks/useSextforceOverview';
import useSubscriber from '../../../hooks/useSubscriber';
import { SettingsContext } from '../../../store/SettingsContext';
import { getServiceStatusColor, getServiceStatusName } from '../../../utils/common';

const SextforceOverview = () => {
    const settings = useContext(SettingsContext);
    const theme: Theme = useTheme();
    const { data: subscriber, isFetching: subscriberLoading } = useSubscriber();
    const { data: overview, isLoading: overviewLoading } = useSextforceOverview();

    return (
        <PageContainer>
            {subscriber && '_id' in subscriber && subscriber._id && (
                <>
                    <Typography variant="h6" textAlign="center" sx={{ marginBottom: theme.spacing(2) }}>
                        <LogoSextforce
                            style={{
                                width: '100%',
                                maxWidth: '550px',
                            }}
                        />
                        <br />
                        Make Love, Not War
                    </Typography>

                    <Typography variant="h5" sx={{ marginBottom: theme.spacing(2) }}>
                        <BackNavigationButton {...(subscriber && { url: `/subscribers/${subscriber._id}` })} /> Sextforce Overview for{' '}
                        {subscriber.username}
                    </Typography>

                    {subscriber.sextforce ? (
                        <>
                            <Alert severity="info" sx={{ marginBottom: theme.spacing(2) }}>
                                Please make sure you read the{' '}
                                <Link
                                    to={`/subscribers/${subscriber._id}/services/sextforce/welcome`}
                                    style={{ color: theme.palette.primary.main, textDecoration: 'none' }}
                                >
                                    <strong>Welcome</strong>
                                </Link>{' '}
                                information first!
                            </Alert>

                            <Card variant="elevation" sx={{ marginBottom: theme.spacing(2) }}>
                                <CardContent>
                                    <Grid container flexGrow={1} spacing={1} alignItems="start">
                                        <Grid item xs>
                                            <Grid container spacing={1} alignItems="center">
                                                <Grid item xs={12}>
                                                    Paid Date:{' '}
                                                    {subscriber.sextforce.paidDate
                                                        ? moment(subscriber.sextforce.paidDate).format('L')
                                                        : 'Payment not found'}
                                                </Grid>
                                                <Grid item xs={12}>
                                                    Paid Until:{' '}
                                                    {subscriber.sextforce.paidDate
                                                        ? moment(subscriber.sextforce.paidDate).add(1, 'month').format('L')
                                                        : 'Payment not found'}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={'auto'}>
                                            <Alert severity={getServiceStatusColor(subscriber, 'sextforce', false) as AlertColor}>
                                                {getServiceStatusName(subscriber, 'sextforce', false)}
                                            </Alert>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>

                            <SextforceOvewviewStats overview={overview} loading={overviewLoading} theme={theme} />

                            <Alert severity="info" sx={{ marginBottom: theme.spacing(2) }}>
                                Please note: All the pages below are best viewed on a large screen like a laptop or a desktop computer. They
                                feature a lot of data and options. A phone screen is just not adequate to display all that data efficiently.
                            </Alert>

                            <ServiceActionsBlocks actions={settings.services.sextforce.actions} subscriber={subscriber} theme={theme} />
                        </>
                    ) : (
                        <Card variant="elevation" sx={{ marginBottom: theme.spacing(2) }}>
                            <CardContent>
                                <SextforceServiceDescription theme={theme} />
                            </CardContent>
                        </Card>
                    )}
                </>
            )}
            <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={subscriberLoading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </PageContainer>
    );
};

export default SextforceOverview;
