import EditIcon from '@mui/icons-material/Edit';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ShareIcon from '@mui/icons-material/Share';
import WavingHandIcon from '@mui/icons-material/WavingHand';
import { Badge, Divider, IconButton, MenuItem } from '@mui/material';
import { GridRenderCellParams, GridTreeNodeWithRender } from '@mui/x-data-grid-pro';
import { useState } from 'react';
import StyledLocalMenu from '../../../../../common/StyledLocalMenu';

const SextfprceMetricsTrialsOverviewGridLocalMenu = ({
    params,
    openEditDialog,
    openWelcomeMessageDialog,
    openShareDialog,
}: {
    params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>;
    openEditDialog: (trialId: string) => void;
    openWelcomeMessageDialog: (trialId: string) => void;
    openShareDialog: (trialId: string) => void;
}) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const sortMenuOpen = Boolean(anchorEl);

    const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <IconButton onClick={e => handleMenuClick(e)}>
                <Badge color="info" variant="dot" invisible={!params.row.welcomeMessage}>
                    <MoreHorizIcon />
                </Badge>
            </IconButton>
            <StyledLocalMenu
                id="trial-menu"
                MenuListProps={{
                    'aria-labelledby': 'trial-button',
                }}
                anchorEl={anchorEl}
                open={sortMenuOpen}
                onClose={handleMenuClose}
            >
                <MenuItem
                    color="primary"
                    onClick={() => {
                        openEditDialog(params.row._id);
                        handleMenuClose();
                    }}
                    disableRipple
                >
                    <EditIcon color="primary" />
                    Edit
                </MenuItem>
                <Divider />
                <MenuItem
                    onClick={() => {
                        openWelcomeMessageDialog(params.row._id);
                        handleMenuClose();
                    }}
                    disableRipple
                >
                    <WavingHandIcon color={params.row.welcomeMessage ? 'info' : 'disabled'} />
                    Welcome Message
                </MenuItem>
                <Divider />
                <MenuItem
                    onClick={() => {
                        openShareDialog(params.row._id);
                        handleMenuClose();
                    }}
                    disableRipple
                >
                    <ShareIcon color="secondary" />
                    Share...
                </MenuItem>
            </StyledLocalMenu>
        </>
    );
};

export default SextfprceMetricsTrialsOverviewGridLocalMenu;
