import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Card, CardContent, Typography, Grid, InputAdornment, Button } from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';
import { Theme } from '@mui/system';
import FormTextField from '../../../forms/FormTextField';
import { AgentInputs } from '../../../../pages/services/sextforce/SextforceManageAgents';
import FormSelect from '../../../forms/FormSelect';
import { useEffect } from 'react';

const agentInputsSchema = yup
    .object({
        name: yup.string().required(),
        fraction: yup
            .number()
            .min(0)
            .max(99)
            .required()
            .transform((value, originalValue, schema) => value / 100),
        type: yup.string(),
        role: yup.string(),
        commissionPercent: yup.number().min(0).max(100).required(),
    })
    .required();

type SextforceManageAgentsAddAgentProps = {
    onSubmit: (data: any) => void;
    availableFractions: number[];
    totalAgents: number;
    totalAllowedAgents: number;
};

const SextforceManageAgentsAddAgent = (props: SextforceManageAgentsAddAgentProps) => {
    const { onSubmit, availableFractions, totalAgents, totalAllowedAgents } = props;
    const theme: Theme = useTheme();
    const {
        control,
        handleSubmit,
        reset,
        formState: { errors, isSubmitSuccessful },
    } = useForm<AgentInputs>({ resolver: yupResolver(agentInputsSchema) });

    useEffect(() => {
        reset();
    }, [isSubmitSuccessful, reset]);

    return (
        <Card variant="elevation" sx={{ marginBottom: theme.spacing(4) }}>
            <CardContent style={{ padding: theme.spacing(1) }}>
                <Typography variant="h6" sx={{ marginBottom: theme.spacing(1) }}>
                    Add Agent
                </Typography>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid
                        container
                        flexGrow={0}
                        alignItems="center"
                        alignSelf={'center'}
                        spacing={1}
                        sx={{ marginBottom: theme.spacing(4) }}
                    >
                        <Grid item xs={12} md={6} lg={2}>
                            <FormSelect
                                name="fraction"
                                label="Fraction"
                                control={control}
                                errors={errors}
                                required={true}
                                value={''}
                                fullWidth
                                options={availableFractions.map(item => {
                                    return { value: item, label: (item / 100).toLocaleString(undefined, { minimumFractionDigits: 2 }) };
                                })}
                                sx={{ minWidth: 120 }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={2}>
                            <FormTextField
                                name="name"
                                label="Agent Name"
                                value=""
                                fullWidth
                                control={control}
                                errors={errors}
                                required={true}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={2}>
                            <FormTextField name="type" label="Type" value="" fullWidth control={control} errors={errors} />
                        </Grid>
                        <Grid item xs={12} md={6} lg={2}>
                            <FormTextField name="role" label="Role" value="" fullWidth control={control} errors={errors} />
                        </Grid>
                        <Grid item xs={12} md={6} lg={2}>
                            <FormTextField
                                name="commissionPercent"
                                label="Commission"
                                value="0"
                                fullWidth
                                control={control}
                                errors={errors}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={2}>
                            <Button type="submit" variant="contained" disabled={totalAllowedAgents <= totalAgents}>
                                Add
                            </Button>
                        </Grid>
                        {totalAllowedAgents <= totalAgents && (
                            <Grid item xs={12}>
                                <Typography variant="body1" color="error">
                                    Maximum number of Agents has been reached.
                                </Typography>
                            </Grid>
                        )}
                    </Grid>
                </form>
            </CardContent>
        </Card>
    );
};

export default SextforceManageAgentsAddAgent;
