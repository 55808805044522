import axios from 'axios';
import { useContext } from 'react';
import { SettingsContext } from '../store/SettingsContext';
import { UserContext } from '../store/UserContext';

const useSubscriberMedia = () => {
    const userContext = useContext(UserContext);
    const settingsContext = useContext(SettingsContext);
    // const dialog = useDialog();
    // const params = useParams();

    const getThumbnail = (subscriber: any, thumbId: number, thumbUrl: string) => {
        if (subscriber && userContext.jwtToken && settingsContext.apiKey) {
            const query: string = `${settingsContext.routes.onlyFans.base}${subscriber._id}/vaultMediaThumb?${new URLSearchParams({
                thumbUrl,
            })}`;

            return axios
                .get(query, {
                    headers: {
                        Authorization: userContext.jwtToken,
                        apiKey: settingsContext.apiKey,
                    },
                    responseType: 'blob',
                })
                .then(response => response.data as Blob)
                .catch(error => {
                    console.error(error);

                    return null;
                });
        }

        return null;
    };

    const getMediaCahcedUrl = async (subscriber: any, mediaId: number): Promise<{ mediaId: number; url: string } | null> => {
        if (subscriber && userContext.jwtToken && settingsContext.apiKey) {
            const query: string = `${settingsContext.routes.onlyFans.base}${subscriber._id}/vaultMediaThumb/${mediaId}`;

            return axios
                .get(query, {
                    headers: {
                        Authorization: userContext.jwtToken,
                        apiKey: settingsContext.apiKey,
                    },
                })
                .then(response => response.data as { mediaId: number; url: string })
                .catch(error => {
                    console.error(error);

                    return null;
                });
        }

        return null;
    };

    const getAudio = async (subscriber: any, url: string): Promise<Blob | null> => {
        if (subscriber && userContext.jwtToken && settingsContext.apiKey) {
            const query: string = `${settingsContext.routes.onlyFans.base}${subscriber._id}/vaultMediaThumb?${new URLSearchParams({
                thumbUrl: url,
            })}`;

            return axios
                .get(query, {
                    headers: {
                        Authorization: userContext.jwtToken,
                        apiKey: settingsContext.apiKey,
                    },
                    responseType: 'blob',
                })
                .then(response => response.data as Blob)
                .catch(error => {
                    console.error(error);
                    return null;
                });
        }

        return null;
    };

    return {
        getThumbnail,
        getAudio,
        getMediaCahcedUrl,
    };
};

export default useSubscriberMedia;
