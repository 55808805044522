import { Alert } from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';
import { Theme } from '@mui/system';

type ErrorMessageProps = {
    error: string | null;
    refProp?: React.MutableRefObject<HTMLDivElement | null>;
};

const ErrorMessage = (props: ErrorMessageProps) => {
    const theme: Theme = useTheme();

    if (props.error) {
        return (
            <Alert severity="error" variant="standard" ref={props.refProp} style={{ marginBottom: theme.spacing(4) }}>
                ERROR {props.error}
            </Alert>
        );
    } else {
        return null;
    }
};

export default ErrorMessage;
