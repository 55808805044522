import { Box, Card, Grid, Stack, styled, Theme, Tooltip, Typography, useTheme } from '@mui/material';
import { useEffect, useMemo, useRef, useState } from 'react';
import { getPrettyDateDiff } from '../../../../utils/common';
import { normalizeDateToPercentage } from '../../../common/RangeProgress';

const TimelineBar = styled(Box)(({ theme }) => ({
    height: 8,
    backgroundColor: theme.palette.grey[200],
    borderRadius: theme.shape.borderRadius * 2,
    position: 'relative',
    width: '100%',
}));

const ActivePeriod = styled(Box)(({ theme }) => ({
    height: '100%',
    backgroundColor: theme.palette.primary.main,
    borderRadius: theme.shape.borderRadius * 2,
    position: 'absolute',
}));

const MarkerContainer = styled(Box)({
    position: 'absolute',
    transform: 'translateX(-50%)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
});

const MarkerDot = styled(Box, {
    shouldForwardProp: prop => prop !== 'isActive',
})<{ isActive?: boolean }>(({ theme, isActive }) => ({
    width: 16,
    height: 16,
    borderRadius: '50%',
    backgroundColor: isActive ? theme.palette.primary.main : theme.palette.grey[400],
    border: `2px solid ${theme.palette.background.paper}`,
    position: 'absolute',
    top: -4,
    cursor: 'help',
}));

const LabelContainer = styled(Box)({
    position: 'absolute',
    width: '100px',
    left: '50%',
    transform: 'translateX(-50%)',
});

const DurationLabel = styled(Box, {
    shouldForwardProp: prop => prop !== 'warningLevel',
})<{ warningLevel?: 'none' | 'warning' | 'danger' }>(({ theme, warningLevel = 'none' }) => {
    const getBackgroundColor = () => {
        switch (warningLevel) {
            case 'warning':
                return theme.palette.warning.light;
            case 'danger':
                return theme.palette.error.light;
            default:
                return theme.palette.grey[100];
        }
    };

    return {
        position: 'absolute',
        top: -12,
        transform: 'translateX(-50%)',
        backgroundColor: getBackgroundColor(),
        color: theme.palette.getContrastText(getBackgroundColor()),
        padding: theme.spacing(0.5, 1),
        borderRadius: theme.shape.borderRadius,
        whiteSpace: 'nowrap',
        zIndex: 1,
    };
});

// Update formatDuration to handle "time ago" format
const formatDuration = (start: Date, end: Date): string => {
    const diff = end.getTime() - start.getTime();
    const minutes = Math.floor(diff / (1000 * 60));
    const hours = Math.floor(diff / (1000 * 60 * 60));
    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    const weeks = Math.floor(days / 7);

    let duration: string;
    if (weeks > 0) {
        duration = `${weeks} ${weeks === 1 ? 'week' : 'weeks'}`;
    } else if (days > 0) {
        duration = `${days} ${days === 1 ? 'day' : 'days'}`;
    } else if (hours > 0) {
        duration = `${hours} ${hours === 1 ? 'hour' : 'hours'}`;
    } else {
        duration = `${minutes} ${minutes === 1 ? 'minute' : 'minutes'}`;
    }

    return duration;
};

interface TimelineProps {
    promoStartDate: Date;
    firstSpendDate: Date;
    lastSpendDate: Date;
}

const ResponsiveTimeline = ({ promoStartDate, firstSpendDate, lastSpendDate }: TimelineProps) => {
    const theme: Theme = useTheme();
    const timelineRef = useRef<HTMLDivElement>(null);

    const now = useMemo(() => new Date(), []);

    const getPosition = useMemo(() => {
        const totalDays = Math.max(1, Math.ceil((now.getTime() - promoStartDate.getTime()) / (1000 * 60 * 60 * 24)));

        return (date: Date): number => {
            const diff = date.getTime() - promoStartDate.getTime();
            const days = diff / (1000 * 60 * 60 * 24);
            return Math.max(0, Math.min(100, (days / totalDays) * 100));
        };
    }, [now, promoStartDate]);

    const initialMarkers = useMemo(
        () => [
            {
                position: 0,
                date: promoStartDate,
                label: 'Promo Start',
                isActive: false,
                type: 'start' as const,
                tooltip: 'The date when this promo started',
            },
            {
                position: getPosition(firstSpendDate),
                date: firstSpendDate,
                label: 'First Spend',
                isActive: true,
                type: 'firstSpend' as const,
                tooltip: 'The date one of the users first spent',
            },
            {
                position: getPosition(lastSpendDate),
                date: lastSpendDate,
                label: 'Last Spend',
                isActive: true,
                type: 'lastSpend' as const,
                tooltip: 'The date one of the users last spent',
            },
            {
                position: 100,
                date: now,
                label: 'Now',
                isActive: false,
                type: 'end' as const,
                tooltip: 'The current date',
            },
        ],
        [promoStartDate, firstSpendDate, lastSpendDate, getPosition],
    );

    const [markers, setMarkers] = useState(initialMarkers);

    useEffect(() => {
        if (!timelineRef.current) return;

        const updatePositions = () => {
            const timelineWidth = timelineRef.current?.offsetWidth ?? 0;
            if (timelineWidth === 0) return;

            const minSpace = 120;
            const minSpacePercent = (minSpace / timelineWidth) * 100;

            const adjustedMarkers = initialMarkers.map(marker => ({ ...marker }));

            // Forward pass
            for (let i = 1; i < adjustedMarkers.length; i++) {
                const prev = adjustedMarkers[i - 1];
                const curr = adjustedMarkers[i];

                if (curr.position - prev.position < minSpacePercent) {
                    if (i < adjustedMarkers.length - 1) {
                        curr.position = Math.min(100, prev.position + minSpacePercent);
                    }
                }
            }

            // Backward pass
            for (let i = adjustedMarkers.length - 2; i >= 0; i--) {
                const next = adjustedMarkers[i + 1];
                const curr = adjustedMarkers[i];

                if (next.position - curr.position < minSpacePercent) {
                    if (i > 0) {
                        curr.position = Math.max(0, next.position - minSpacePercent);
                    }
                }
            }

            setMarkers(adjustedMarkers);
        };

        const resizeObserver = new ResizeObserver(() => {
            window.requestAnimationFrame(updatePositions);
        });

        resizeObserver.observe(timelineRef.current);
        updatePositions();

        return () => {
            resizeObserver.disconnect();
        };
    }, [initialMarkers]);

    const activePeriod = useMemo(() => {
        const firstSpendMarker = markers.find(m => m.type === 'firstSpend');
        const lastSpendMarker = markers.find(m => m.type === 'lastSpend');
        return {
            start: firstSpendMarker?.position ?? 0,
            end: lastSpendMarker?.position ?? 0,
        };
    }, [markers]);

    /// Calculate warning level for the "time since last spent" label
    const getWarningLevel = useMemo(() => {
        const totalDuration = now.getTime() - promoStartDate.getTime();
        const sinceLastSpent = now.getTime() - lastSpendDate.getTime();
        const percentage = (sinceLastSpent / totalDuration) * 100;

        if (percentage > 20) return 'danger';
        if (percentage > 10) return 'warning';
        return 'none';
    }, [now, promoStartDate, lastSpendDate]);

    // Calculate the duration label positions
    const durationLabelPositions = useMemo(() => {
        const startMarker = markers.find(m => m.type === 'start');
        const firstSpendMarker = markers.find(m => m.type === 'firstSpend');
        const lastSpendMarker = markers.find(m => m.type === 'lastSpend');
        const endMarker = markers.find(m => m.type === 'end');

        return {
            timeToFirstSpend: startMarker && firstSpendMarker ? (startMarker.position + firstSpendMarker.position) / 2 : 0,
            timeSinceLastSpend: lastSpendMarker && endMarker ? (lastSpendMarker.position + endMarker.position) / 2 : 0,
        };
    }, [markers]);

    const getBackgroundColor = (warningLevel: 'warning' | 'danger' | 'none') => {
        switch (warningLevel) {
            case 'warning':
                return theme.palette.warning.dark;
            case 'danger':
                return theme.palette.error.dark;
            default:
                return theme.palette.text.primary;
        }
    };

    const formatDate = (date: Date): string => {
        return new Intl.DateTimeFormat('en-US', {
            month: 'short',
            day: 'numeric',
            year: 'numeric',
        }).format(date);
    };

    return (
        <Card sx={{ p: 1 }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="subtitle1">Spending Timeline</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Stack direction="column" spacing={0}>
                        <Typography variant="subtitle1" textAlign="right">
                            Spending % of Promo Duration
                        </Typography>
                        <Typography variant="h5" textAlign="right">
                            {(
                                normalizeDateToPercentage(promoStartDate, new Date(), lastSpendDate) -
                                normalizeDateToPercentage(promoStartDate, new Date(), firstSpendDate)
                            ).toLocaleString(undefined, {
                                maximumFractionDigits: 2,
                                minimumFractionDigits: 2,
                            })}
                            %
                        </Typography>
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <Stack direction="column" spacing={0}>
                        <Typography variant="subtitle1" textAlign="right">
                            Time to First Spend
                        </Typography>
                        <Typography variant="h5" textAlign="right">
                            {getPrettyDateDiff(promoStartDate, firstSpendDate)}
                        </Typography>
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <Stack direction="column" spacing={0}>
                        <Typography variant="subtitle1" textAlign="right">
                            Time Since Last Spend
                        </Typography>
                        <Typography variant="h5" textAlign="right" color={getBackgroundColor(getWarningLevel)}>
                            {getPrettyDateDiff(lastSpendDate, now)}
                        </Typography>
                    </Stack>
                </Grid>

                <Grid item xs={12}>
                    <Box
                        ref={timelineRef}
                        sx={{
                            position: 'relative',
                            pt: 4,
                            pb: 6,
                            px: 6,
                        }}
                    >
                        <TimelineBar>
                            {/* Time to first spend label */}
                            <Tooltip title="Time from the promo start to the first spend">
                                <DurationLabel
                                    sx={{
                                        left: `${durationLabelPositions.timeToFirstSpend}%`,
                                        cursor: 'help',
                                    }}
                                >
                                    <Typography variant="caption" color="textSecondary">
                                        {formatDuration(promoStartDate, firstSpendDate)}
                                    </Typography>
                                </DurationLabel>
                            </Tooltip>

                            {/* Time since last spend label */}
                            <Tooltip title="Time from the last spend to now">
                                <DurationLabel
                                    warningLevel={getWarningLevel}
                                    sx={{
                                        left: `${durationLabelPositions.timeSinceLastSpend}%`,
                                        cursor: 'help',
                                    }}
                                >
                                    <Typography
                                        variant="caption"
                                        // color="textSecondary"
                                        sx={
                                            {
                                                // color: getWarningLevel !== 'none' ? 'rgba(0, 0, 0, 0.7)' : 'inherit',
                                            }
                                        }
                                    >
                                        {formatDuration(lastSpendDate, now)}
                                    </Typography>
                                </DurationLabel>
                            </Tooltip>

                            <ActivePeriod
                                sx={{
                                    left: `${activePeriod.start}%`,
                                    width: `${activePeriod.end - activePeriod.start}%`,
                                }}
                            />

                            {markers.map((marker, index) => (
                                <MarkerContainer
                                    key={index}
                                    sx={{
                                        left: `${marker.position}%`,
                                    }}
                                >
                                    <LabelContainer
                                        sx={{
                                            top: -40,
                                        }}
                                    >
                                        <Typography
                                            variant="caption"
                                            color="textSecondary"
                                            sx={{
                                                display: 'block',
                                                textAlign: 'center',
                                                whiteSpace: 'nowrap',
                                            }}
                                        >
                                            {marker.label}
                                        </Typography>
                                    </LabelContainer>

                                    <Tooltip title={marker.tooltip}>
                                        <MarkerDot isActive={marker.isActive} />
                                    </Tooltip>

                                    <LabelContainer
                                        sx={{
                                            top: 30,
                                        }}
                                    >
                                        <Typography
                                            variant="body2"
                                            sx={{
                                                display: 'block',
                                                textAlign: 'center',
                                                whiteSpace: 'nowrap',
                                                fontWeight: 500,
                                            }}
                                        >
                                            {formatDate(marker.date)}
                                        </Typography>
                                    </LabelContainer>
                                </MarkerContainer>
                            ))}
                        </TimelineBar>
                    </Box>
                </Grid>
            </Grid>
        </Card>
    );
};

export default ResponsiveTimeline;
