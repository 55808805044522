import { Card, Grid, Skeleton, Typography } from '@mui/material';
import { Theme } from '@mui/system';

type Props = {
    title?: string;
    value: number | string | React.ReactNode;
    format: 'plain' | 'number' | 'price';
    maxValue?: number;
    loading: boolean;
    icon?: React.ReactNode;
    allowOverflow?: boolean;
    theme: Theme;
};

const OverviewCardSmall = (props: Props) => {
    const { title, value, format, maxValue, loading, icon, allowOverflow, theme } = props;

    return (
        <Card
            variant="elevation"
            sx={{
                marginBottom: theme.spacing(0),
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                padding: theme.spacing(1),
            }}
        >
            <Grid container flexGrow={1} alignItems="center" spacing={2}>
                {icon && (
                    <Grid item xs={'auto'}>
                        {icon}
                    </Grid>
                )}
                <Grid item xs>
                    <Grid container flexGrow={1} alignItems="center" spacing={2}>
                        {title && (
                            <Grid item xs>
                                <Typography variant="subtitle1" align="left">
                                    {title}
                                </Typography>
                            </Grid>
                        )}
                        <Grid item xs={title ? 'auto' : 12}>
                            <Typography
                                variant="body1"
                                align={title ? 'right' : 'left'}
                                {...((!value || typeof value === 'number') && format !== 'plain' && { fontFamily: 'monospace' })}
                                sx={{
                                    textOverflow: allowOverflow ? 'clip' : 'ellipsis',
                                    whiteSpace: allowOverflow ? 'normal' : 'nowrap',
                                    overflow: allowOverflow ? 'visible' : 'hidden',
                                }}
                            >
                                {loading ? (
                                    <Skeleton />
                                ) : typeof value === 'number' || typeof value === 'string' ? (
                                    `${typeof value === 'number' && format === 'number' ? value.toLocaleString() : value}`
                                ) : value ? (
                                    value
                                ) : (
                                    0
                                )}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Card>
    );
};

export default OverviewCardSmall;
