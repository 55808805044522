import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import EditIcon from '@mui/icons-material/Edit';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import { Alert, AlertTitle, Backdrop, Box, Card, CardContent, CircularProgress, Typography } from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';
import { Theme } from '@mui/system';
import { DataGridPro, GridActionsCellItem, GridColDef, GridRowParams } from '@mui/x-data-grid-pro';
import { useDialog } from 'muibox';
import { useContext, useEffect, useMemo, useState } from 'react';
import { Link, NavigateFunction, useNavigate, useParams } from 'react-router-dom';
import PageContainer from '../../../components/common/PageContainter';
import LogoSextforce from '../../../components/icons/LogoSextforce';
import { SettingsContext } from '../../../store/SettingsContext';
import { UserContext } from '../../../store/UserContext';
import { handleHttpError, handleHttpErrorResponse } from '../../../utils/common';

type FractionProps = {
    theme: Theme;
};

const Fraction: React.FC<FractionProps> = ({ children, theme }) => (
    <span style={{ fontFamily: 'monospace', background: theme.palette.info.light }}>{children}</span>
);

const agentsExample = [
    {
        _id: '1',
        fraction: '0.35',
        name: 'Orange',
        type: '',
        role: '',
        commissionPercent: 20,
    },
    {
        _id: '2',
        fraction: '0.50',
        name: 'Red',
        type: '',
        role: '',
        commissionPercent: 20,
    },
    {
        _id: '3',
        fraction: '0.14',
        name: 'Smith',
        type: '',
        role: '',
        commissionPercent: 20,
    },
    {
        _id: '4',
        fraction: '0.99',
        name: 'Subscriptions',
        type: 'Account',
        role: '',
        commissionPercent: 0,
    },
];

const SextforceWelcome = () => {
    const userContext = useContext(UserContext);
    const settingsContext = useContext(SettingsContext);
    const theme: Theme = useTheme();
    const navigate: NavigateFunction = useNavigate();
    const dialog = useDialog();
    const params = useParams();
    const [showBackdrop, setShowBackdrop] = useState<boolean>(false);

    const [subscriber, setSubscriber] = useState<any | null>(null);

    useEffect(() => {
        if (userContext.jwtToken && settingsContext.apiKey && 'userId' in params && params.userId) {
            const query: string = `${settingsContext.routes.subscribers.find}${params.userId}`;

            fetch(query, {
                method: 'get',
                headers: {
                    Authorization: userContext.jwtToken,
                    apiKey: settingsContext.apiKey,
                },
            })
                .then(async response => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        handleHttpErrorResponse(response, dialog);
                    }
                })
                .then(data => {
                    setSubscriber(data);
                    setShowBackdrop(false);

                    if (!data || !('_id' in data) || !data._id) {
                        navigate('/');
                    }
                })
                .catch(error => {
                    console.error(error);
                    handleHttpError(error, dialog);
                });
        }
    }, [userContext, settingsContext, params, dialog, navigate]);

    // Define agents DataGrid columns
    const columns = useMemo<GridColDef[]>(
        () => [
            {
                field: 'fraction',
                headerName: 'Fraction',
                width: 120,
                align: 'right',
                headerAlign: 'right',
                renderCell: (params: any) => (
                    <span style={{ fontFamily: 'monospace' }}>
                        {params.row.fraction.toLocaleString(undefined, { minimumFractionDigits: 2 })}
                    </span>
                ),
            },
            { field: 'name', headerName: 'Agent', flex: 1 },
            { field: 'type', headerName: 'Type', flex: 0.5 },
            { field: 'role', headerName: 'Role', flex: 0.5 },
            {
                field: 'commissionPercent',
                headerName: 'Commission %',
                width: 150,
                align: 'right',
                headerAlign: 'right',
                renderCell: (params: any) => (
                    <span style={{ fontFamily: 'monospace' }}>
                        {params.row.commissionPercent.toLocaleString(undefined, { minimumFractionDigits: 2 })}%
                    </span>
                ),
            },
            {
                field: 'actions',
                type: 'actions',
                width: 120,
                getActions: (params: GridRowParams) => [
                    /* @ts-ignore */
                    <GridActionsCellItem icon={<EditIcon color="primary" />} label="Edit" color="primary" />,
                    /* @ts-ignore */
                    <GridActionsCellItem icon={<VpnKeyIcon color="secondary" />} label="Create Link" />,
                    /* @ts-ignore */
                    <GridActionsCellItem icon={<DoNotDisturbIcon color="error" />} label="Revoke Link" />,
                ],
            },
        ],
        [],
    );

    return (
        <PageContainer>
            {subscriber && '_id' in subscriber && subscriber._id && (
                <>
                    <Typography variant="h6" textAlign="center" sx={{ marginBottom: theme.spacing(2) }}>
                        <LogoSextforce
                            style={{
                                width: '100%',
                                maxWidth: '550px',
                            }}
                        />
                        <br />
                        Make Love, Not War
                    </Typography>

                    <Card variant="elevation" sx={{ marginBottom: theme.spacing(2) }}>
                        <CardContent>
                            <Typography variant="body1">
                                Sextforce is an assistants managements and sales analytics system. The system automatically tracks sales,
                                calculated commission, generates live reports, and measures performance. Crucially, it allows for multiple
                                assistants/sexters to work on the account at the same time without clashing with each other. No more
                                clocking in and out, or working in shifts! Everything is done using a clean and friendly dashboard website.
                            </Typography>
                        </CardContent>
                    </Card>

                    <Card variant="elevation" sx={{ marginBottom: theme.spacing(2) }}>
                        <CardContent>
                            <Typography variant="h5" sx={{ marginBottom: theme.spacing(2) }}>
                                Concept
                            </Typography>

                            <Typography variant="h6" sx={{ marginBottom: theme.spacing(2) }}>
                                Agents
                            </Typography>

                            <Typography variant="body1" sx={{ marginBottom: theme.spacing(2) }}>
                                Sextforce monitors and analyses the account's sales. Assistants/sexters are called Sextforce{' '}
                                <strong>Agents</strong>. To differentiate between the different Agents’ sales, each Agent is assigned a
                                unique <strong>fraction</strong> to use when generating tips. A fraction is the{' '}
                                <Fraction theme={theme}>.xx</Fraction> part of the price. For example, if you create an Agent called Orange,
                                and assign the fraction <Fraction theme={theme}>.35</Fraction> to this agent, you would tell them to try to
                                only generate tips who’s price ends with <Fraction theme={theme}>.35</Fraction> (i.e. $5.35, $9.35, $20.35,
                                etc.) This way, when the system analyses the sales end sees a transaction that ends with{' '}
                                <Fraction theme={theme}>.35</Fraction>, it will automatically get assigned to the Agent called Claire and
                                their commission will be calculated automatically.
                            </Typography>

                            <Box
                                sx={{
                                    width: '100%',
                                    '.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
                                        outline: 'none',
                                    },
                                    paddingBottom: theme.spacing(2),
                                }}
                            >
                                <DataGridPro
                                    rows={agentsExample}
                                    columns={columns}
                                    getRowId={row => row._id}
                                    initialState={{ sorting: { sortModel: [{ field: 'name', sort: 'asc' }] } }}
                                    hideFooter={true}
                                    disableRowSelectionOnClick
                                    disableColumnFilter
                                    disableColumnSelector
                                    autoHeight={true}
                                    density="compact"
                                    rowCount={agentsExample ? agentsExample.length : 0}
                                    sx={{ '& .MuiDataGrid-columnHeaderTitle': { fontWeight: 'bold' }, backgroundColor: '#ffffff' }}
                                />
                            </Box>

                            <Alert severity="info" sx={{ marginBottom: theme.spacing(3) }}>
                                <AlertTitle>Tip</AlertTitle>
                                <Typography variant="body1">
                                    If, for example, all your subscriptions sell for $x.99, then you can create a “fake” Agent with a .99
                                    fraction and a commission of 0% called Subscriptions. This way, all the subscription sales will appear
                                    labelled as Subscriptions in the reports without commission and they will be easy to track.
                                </Typography>
                            </Alert>

                            <Typography variant="body1" sx={{ marginBottom: theme.spacing(2) }}>
                                Sextforce supports up to 99 Agents because there are 99 possible fractions available (.01-.99). This means
                                that up to 99 different people can work on the website at the same time without clashing with each other!
                                The fraction <Fraction theme={theme}>.00</Fraction> is reserved for general sales.
                            </Typography>

                            <Typography variant="body1">
                                You can manage Agents in the{' '}
                                <Link
                                    to={`/subscribers/${subscriber._id}/services/sextforce/agents`}
                                    style={{ color: theme.palette.primary.main, textDecoration: 'none' }}
                                >
                                    <strong>Manage Agents</strong>
                                </Link>{' '}
                                page. <strong>This is where you should start.</strong> Create an Agent for each assistant/sexter you have
                                working on the account. Each Agent has a name, a fraction and a <strong>default</strong> commission
                                percentage. You can also enter an optional Agent Type and Role. These are purely for your use.
                            </Typography>
                        </CardContent>
                    </Card>

                    <Card variant="elevation" sx={{ marginBottom: theme.spacing(2) }}>
                        <CardContent>
                            <Typography variant="h6" sx={{ marginBottom: theme.spacing(2) }}>
                                Anonymous Transactions
                            </Typography>

                            <Typography variant="body1" sx={{ marginBottom: theme.spacing(2) }}>
                                Sales that end with <Fraction theme={theme}>.00</Fraction> are treated as{' '}
                                <strong>Anonymous Transactions</strong>. These could be sales of subscriptions, general content, promos,
                                etc. Many times, an Agent will be selling pre-existing content which already has a price that ends with .00.
                                Anonymous Sales can be assigned to any Agent via the{' '}
                                <Link
                                    to={`/subscribers/${subscriber._id}/services/sextforce/assign`}
                                    style={{ color: theme.palette.primary.main, textDecoration: 'none' }}
                                >
                                    <strong>Assign Transactions</strong>
                                </Link>{' '}
                                page. There are two ways to assign a sale to an Agent:
                            </Typography>

                            <ol>
                                <li style={{ marginBottom: theme.spacing(1) }}>
                                    Manually select the appropriate Agent from the drop down list to the right of the transaction
                                </li>
                                <li style={{ marginBottom: theme.spacing(1) }}>
                                    Approve an Agent’s <strong>Sale Proof</strong>
                                </li>
                            </ol>
                        </CardContent>
                    </Card>

                    <Card variant="elevation" sx={{ marginBottom: theme.spacing(2) }}>
                        <CardContent>
                            <Typography variant="h6" sx={{ marginBottom: theme.spacing(2) }}>
                                Sale Proof
                            </Typography>

                            <Typography variant="body1" sx={{ marginBottom: theme.spacing(2) }}>
                                One of the key premises of the Sextforce system is the <strong>Sale Proof</strong> mechanism. When an Agent
                                sells something that does not end with their assigned fraction, they can simply submit a Sales Proof via a
                                special link that is unique and private to each Agent. The form automatically selects the Agent’s time zone,
                                so all they need to enter is the date, time and amount of sale, and include a screenshot of the chat where
                                the sale took place as proof. That’s it. The system will automatically match the Sale Proof to an
                                appropriate Anonymous Transaction and display a REVIEW button next to the transaction in the{' '}
                                <Link
                                    to={`/subscribers/${subscriber._id}/services/sextforce/assign`}
                                    style={{ color: theme.palette.primary.main, textDecoration: 'none' }}
                                >
                                    <strong>Assign Transactions</strong>
                                </Link>{' '}
                                page. Clicking on it will let you review the transaction’s details and the sale proof showing the time of
                                the sale in both your local time zone and the Agent’s time zone. Clicking APPROVE will automatically assign
                                the transaction to the Agent that submitted the Sale Proof and it will be added to the Agent’s commission.
                                This is also where you can choose to override the Agent’s commission for this particular sale. For example,
                                if by default the Agent gets 20% commission for tips, but only 10% commission for selling existing content.
                            </Typography>

                            <Typography variant="body1" sx={{ marginBottom: theme.spacing(2) }}>
                                When submitting a Sale Proof, the Agent cannot see any details about anything. No transactions, no history,
                                no sales figures. They can only submit the sale proof.
                            </Typography>

                            <Typography variant="body1" sx={{ marginBottom: theme.spacing(2) }}>
                                You can see all the sale proofs submitted and delete ones that were submitted incorrectly in the{' '}
                                <Link
                                    to={`/subscribers/${subscriber._id}/services/sextforce/saleproofs`}
                                    style={{ color: theme.palette.primary.main, textDecoration: 'none' }}
                                >
                                    <strong>Manage Sale Proofs</strong>{' '}
                                </Link>
                                page.
                            </Typography>

                            <Typography variant="body1">
                                If an Agent stops working for you, you can revoke their unique link in the{' '}
                                <Link
                                    to={`/subscribers/${subscriber._id}/services/sextforce/agents`}
                                    style={{ color: theme.palette.primary.main, textDecoration: 'none' }}
                                >
                                    <strong>Manage Agents</strong>
                                </Link>{' '}
                                page, and generate a new link for the next Agent who will use the same fraction.
                            </Typography>
                        </CardContent>
                    </Card>

                    <Card variant="elevation" sx={{ marginBottom: theme.spacing(2) }}>
                        <CardContent>
                            <Typography variant="h5" sx={{ marginBottom: theme.spacing(2) }}>
                                Reports
                            </Typography>

                            <Typography variant="body1">
                                The <strong>Sales Reports</strong> page is where everything comes together. Simply choose a Week and press
                                SHOW to see the report. The system automatically pre-selects your local time zone, but you can change it to
                                any time zone you like. You can also choose any range of dates instead of just choosing a specific week,
                                though most do work on a week-by-week basis. Ticking the Compare option allows you to choose another date to
                                compare the main report to. Most of the reports will be shows as a side-by-side comparison between the main
                                dates and the comparison dates for measuring performance and tracking progress.
                            </Typography>
                        </CardContent>
                    </Card>

                    <Card variant="elevation" sx={{ marginBottom: theme.spacing(2) }}>
                        <CardContent>
                            <Typography variant="h6" sx={{ marginBottom: theme.spacing(2) }}>
                                Overview
                            </Typography>

                            <Typography variant="body1">
                                The overview boxes show the totals for the report period as Gross (what you sold in total), Total OF Fees
                                (the fees OF takes from the Gross), Total Net (what you keep after OnlyFans’ fees), Total VAT (how much VAT
                                was charged in total by OF), Total Commission (the total commission your Agents made and its percentage of
                                the Total Net).
                            </Typography>
                        </CardContent>
                    </Card>

                    <Card variant="elevation" sx={{ marginBottom: theme.spacing(2) }}>
                        <CardContent>
                            <Typography variant="h6" sx={{ marginBottom: theme.spacing(2) }}>
                                Agents Performance
                            </Typography>

                            <Typography variant="body1" sx={{ marginBottom: theme.spacing(2) }}>
                                The main report shows a breakdown of the sales separated by Agent/fraction. This is where you can see each
                                Agent’s total commission for this period and if there are any sales made with a fraction that does not have
                                an Agent associated with it.
                            </Typography>

                            <Typography variant="body1">
                                The next reports show the Agents’ performance as a bar graph and a pie chart. These make it easy to see the
                                Agent’s “leader board” and make week-by-week comparisons for each Agent and track their progress.
                            </Typography>
                        </CardContent>
                    </Card>

                    <Card variant="elevation" sx={{ marginBottom: theme.spacing(2) }}>
                        <CardContent>
                            <Typography variant="h6" sx={{ marginBottom: theme.spacing(2) }}>
                                Top Spenders
                            </Typography>

                            <Typography variant="body1">
                                A list of the top 20 spenders of the selected period with direct links to their profiles and chats.
                            </Typography>
                        </CardContent>
                    </Card>

                    <Card variant="elevation" sx={{ marginBottom: theme.spacing(2) }}>
                        <CardContent>
                            <Typography variant="h6" sx={{ marginBottom: theme.spacing(2) }}>
                                Online Users Count
                            </Typography>

                            <Typography variant="body1">
                                A very special feature of Sextforce is tracking how many of your fans are online on the website! When
                                generating a report for any Week, the line chart will show how many users were online at 30-minute intervals
                                throughout the week. The is perfect for knowing when to commit your resources and making sure your Agents
                                are online, as well as deriving correlation between the number of users online and sales performance.
                            </Typography>
                        </CardContent>
                    </Card>

                    <Card variant="elevation" sx={{ marginBottom: theme.spacing(2) }}>
                        <CardContent>
                            <Typography variant="h5" sx={{ marginBottom: theme.spacing(2) }}>
                                General
                            </Typography>

                            <Typography variant="body1" sx={{ marginBottom: theme.spacing(2) }}>
                                You can find more detailed instructions at the top of each page by clicking on "How It Works".
                            </Typography>

                            <Typography variant="body1">
                                More reports, metrics and pages are added regularly. If you have any suggestions or ideas, please speak to
                                Admin.
                            </Typography>
                        </CardContent>
                    </Card>
                </>
            )}
            <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={showBackdrop}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </PageContainer>
    );
};

export default SextforceWelcome;
