import HelpIcon from '@mui/icons-material/Help';
import { Stack, Tooltip } from '@mui/material';
import { GridColumnHeaderParams } from '@mui/x-data-grid-pro';
import { OnlyFansMetrics } from '../../hooks/useSextforceMetricsCampaignsOverview';

const DataGridColumnHeaderCell = (params: GridColumnHeaderParams<OnlyFansMetrics>, tooltipTitle?: string) => (
    <div
        style={{
            fontWeight: 'bold',
        }}
    >
        {tooltipTitle ? (
            <Tooltip title={tooltipTitle} placement="top">
                <Stack direction="row" spacing={1} alignItems={'center'} justifyContent={'flex-start'}>
                    <span style={{ textDecoration: 'underline', textDecorationStyle: 'dotted' }}>{params.colDef.headerName}</span>
                    <HelpIcon fontSize="small" />
                </Stack>
            </Tooltip>
        ) : (
            params.colDef.headerName
        )}
    </div>
);

export default DataGridColumnHeaderCell;
