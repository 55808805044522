import { useAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { Amplify } from 'aws-amplify';
import { useEffect, useState } from 'react';
import awsExports from '../aws-exports';
import { SettingsContextProvider } from '../store/SettingsContext';
import { UserContext } from '../store/UserContext';
import Authenticated from './Authenticated';
import NotAuthenticated from './NotAuthenticated';

// import { ComponentsProviderProps } from '@aws-amplify/ui';

Amplify.configure(awsExports);

function Dashboard() {
    const { route } = useAuthenticator(context => [context.route]);
    const { user, signOut } = useAuthenticator(context => [context.user]);
    const [isAdmin, setIsAdmin] = useState<boolean>(false);
    const [jwtToken, setJwtToken] = useState<string>('');

    const session: CognitoUserSession | null = user ? user.getSignInUserSession() : null;

    useEffect(() => {
        if (session) {
            setJwtToken(session.getAccessToken().getJwtToken());

            const groups = session.getAccessToken().payload['cognito:groups'];

            setIsAdmin(Array.isArray(groups) && groups.includes('admin'));
        }
    }, [session]);

    const refreshToken = async () => {
        if (session) {
            setJwtToken(session.getAccessToken().getJwtToken());

            const groups = session.getAccessToken().payload['cognito:groups'];

            setIsAdmin(Array.isArray(groups) && groups.includes('admin'));
        }
    };

    return (
        <>
            <UserContext.Provider value={{ route, user, jwtToken, refreshToken, signOut, isAdmin }}>
                <SettingsContextProvider>
                    {route === 'authenticated' && session ? <Authenticated /> : <NotAuthenticated />}
                </SettingsContextProvider>
            </UserContext.Provider>
        </>
    );
}

export default Dashboard;
