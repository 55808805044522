import { useEffect, useState } from 'react';
import { Theme } from '@mui/system';
import useTheme from '@mui/material/styles/useTheme';
import { ResponsivePie } from '@nivo/pie';
import { Card, CardContent, Grid, Skeleton, Typography } from '@mui/material';
import { d2f } from '../../../../utils/common';

const generateData = (transactionsMain: any[], transactionsCompare: any[], compare: boolean, theme: Theme) => {
    const filtered = transactionsMain.filter(item => d2f(item.fraction) !== 0);

    // Sum up the total of the Agents' transactions
    let totalAmount: number = 0;

    filtered.forEach(item => {
        totalAmount += item.totalAmountCents;
    });

    const data = filtered.map(transactionMain => {
        const fraction = d2f(transactionMain.fraction);
        const transactionCompare = transactionsCompare.find(transaction => d2f(transaction.fraction) === fraction);

        return {
            agent: transactionMain.agent ? `${transactionMain.agent.name} (${fraction})` : `Unknown (${fraction})`,
            mainAmount: transactionMain.totalAmountCents / totalAmount,
            ...(compare && { compareAmount: transactionCompare ? transactionCompare.totalAmountCents / totalAmount : 0 }),
        };
    });

    return data;
};

type ReportAccountTotalsByAgentShareGraphProps = {
    transactionsMain: any[];
    transactionsCompare: any[];
    compare: boolean;
    isFetching: boolean;
};

const ReportAccountTotalsByAgentShareGraph = (props: ReportAccountTotalsByAgentShareGraphProps) => {
    const { transactionsMain, transactionsCompare, compare, isFetching } = props;
    const theme: Theme = useTheme();
    const [data, setData] = useState<any[]>([]);

    useEffect(() => {
        if (transactionsMain) {
            setData(generateData(transactionsMain, transactionsCompare, compare, theme));
        }
    }, [transactionsMain, transactionsCompare, compare, theme]);

    return (
        <Card variant="elevation" sx={{ marginBottom: theme.spacing(4) }}>
            <CardContent>
                <Typography variant="h6" sx={{ marginBottom: theme.spacing(2) }}>
                    Agents Share Graph
                </Typography>
                <Typography variant="body1" sx={{ marginBottom: theme.spacing(2) }}>
                    This reports shows each Agent's sales as a share of a pie. The pie chart does not include Annonymous transactions which
                    end with X.00
                </Typography>
                <Grid container flexGrow={1} sx={{ paddingBottom: theme.spacing(1) }}>
                    <Grid item {...(compare ? { xs: 12, md: 6 } : { xs: 12 })} sx={{ height: 500 }}>
                        {isFetching ? (
                            <Skeleton />
                        ) : (
                            <ResponsivePie
                                data={data}
                                id={'agent'}
                                value={'mainAmount'}
                                valueFormat={(n: number) => `${(n * 100).toFixed(2)}%`}
                                sortByValue={true}
                                margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
                                innerRadius={0.0}
                                padAngle={0.7}
                                cornerRadius={3}
                                activeOuterRadiusOffset={8}
                                colors={{ scheme: 'set3' }}
                                borderWidth={1}
                                borderColor={{
                                    from: 'color',
                                    modifiers: [['darker', 0.2]],
                                }}
                                arcLinkLabelsSkipAngle={10}
                                arcLinkLabelsTextColor="#333333"
                                arcLinkLabelsThickness={2}
                                arcLinkLabelsColor={{ from: 'color' }}
                                arcLabelsSkipAngle={10}
                                arcLabelsTextColor={{
                                    from: 'color',
                                    modifiers: [['darker', 2]],
                                }}
                            />
                        )}
                        {compare && (
                            <Typography variant="subtitle1" textAlign="center" color={theme.palette.text.secondary}>
                                Main Search
                            </Typography>
                        )}
                    </Grid>
                    {compare && (
                        <>
                            <Grid item xs={12} md={6} sx={{ height: 500 }}>
                                {isFetching ? (
                                    <Skeleton />
                                ) : (
                                    <ResponsivePie
                                        data={data}
                                        id={'agent'}
                                        value={'compareAmount'}
                                        valueFormat={(n: number) => `${(n * 100).toFixed(2)}%`}
                                        sortByValue={true}
                                        margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
                                        innerRadius={0.0}
                                        padAngle={0.7}
                                        cornerRadius={3}
                                        activeOuterRadiusOffset={8}
                                        borderWidth={1}
                                        borderColor={{
                                            from: 'color',
                                            modifiers: [['darker', 0.2]],
                                        }}
                                        arcLinkLabelsSkipAngle={10}
                                        arcLinkLabelsTextColor="#333333"
                                        arcLinkLabelsThickness={2}
                                        arcLinkLabelsColor={{ from: 'color' }}
                                        arcLabelsSkipAngle={10}
                                        arcLabelsTextColor={{
                                            from: 'color',
                                            modifiers: [['darker', 2]],
                                        }}
                                    />
                                )}
                                <Typography variant="subtitle1" textAlign="center" color={theme.palette.text.secondary}>
                                    Comparisson Search
                                </Typography>
                            </Grid>
                        </>
                    )}
                </Grid>
            </CardContent>
        </Card>
    );
};

export default ReportAccountTotalsByAgentShareGraph;
