import { Card, CardContent, Grid, Skeleton, Typography, useMediaQuery } from '@mui/material';
import { Theme } from '@mui/system';
import dinero from 'dinero.js';
import moment from 'moment';
import { useDialog } from 'muibox';
import { useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import {
    Area,
    CartesianGrid,
    ComposedChart,
    Label,
    Legend,
    Line,
    ReferenceLine,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts';
import { OnlyFansMetricType } from '../../../../../../src/libs/onlyFans/onlyFansDbTypes';
import { SettingsContext } from '../../../../store/SettingsContext';
import { UserContext } from '../../../../store/UserContext';
import { d2f, handleHttpError, handleHttpErrorResponse } from '../../../../utils/common';

export interface MetricEarningsGroupedByIntervalResponse {
    _id: {
        interval: string;
        format: string;
    };
    count: number;
    total: number;
    totalNet: number;
    totalWithoutSubscription: number;
    totalNetWithoutSubscription: number;
}

const generateData = (
    data: MetricEarningsGroupedByIntervalResponse[],
    showEarningsWithSubscriptions: boolean,
    showEarningsAsGross: boolean,
    timezone: string,
): {
    data: { time: string; count: number; total: number }[];
    min: number;
    max: number;
    average: number;
} => {
    if (data.length === 0) {
        return {
            data: [],
            min: 0,
            max: 0,
            average: 0,
        };
    }

    const result = {
        data: data.map(d => {
            let total: number = 0;

            if (showEarningsWithSubscriptions) {
                if (showEarningsAsGross) {
                    total = d.total ? (typeof d.total === 'number' ? d.total : d2f(d.total)) : 0;
                } else {
                    total = d.totalNet ? (typeof d.totalNet === 'number' ? d.totalNet : d2f(d.totalNet)) : 0;
                }
            } else {
                if (showEarningsAsGross) {
                    total = d.totalWithoutSubscription
                        ? typeof d.totalWithoutSubscription === 'number'
                            ? d.totalWithoutSubscription
                            : d2f(d.totalWithoutSubscription)
                        : 0;
                } else {
                    total = d.totalNetWithoutSubscription
                        ? typeof d.totalNetWithoutSubscription === 'number'
                            ? d.totalNetWithoutSubscription
                            : d2f(d.totalNetWithoutSubscription)
                        : 0;
                }
            }

            // Convert date format from MongoDB to MomentJS
            let momentFormatOutput: string = 'L';

            if (d._id.format === '%Y-%m-%d') {
                momentFormatOutput = 'YYYY-MM-DD';
            } else if (d._id.format === '%Y-%V') {
                momentFormatOutput = 'YYYY-[W]ww';
            } else if (d._id.format === '%Y-%m') {
                momentFormatOutput = 'YYYY-MM';
            } else if (d._id.format === '%Y') {
                momentFormatOutput = 'YYYY';
            }

            // Convert date format from MongoDB to MomentJS
            let momentFormatInput: string = 'YYYY-MM-DD';

            if (d._id.format === '%Y-%m-%d') {
                momentFormatInput = 'YYYY-MM-DD';
            } else if (d._id.format === '%Y-%V') {
                momentFormatInput = 'YYYY-WW';
            } else if (d._id.format === '%Y-%m') {
                momentFormatInput = 'YYYY-MM';
            } else if (d._id.format === '%Y') {
                momentFormatInput = 'YYYY';
            }

            return {
                time: moment(d._id.interval, momentFormatInput).tz(timezone).format(momentFormatOutput),
                count: d.count,
                total,
            };
        }),
        // min: Math.round(Math.min(...data.map((d: any) => d.count))),
        // max: Math.round(Math.max(...data.map((d: any) => d.count))),
        // average: Math.round(data.reduce((a: any, b: any) => a + b.count, 0) / data.length),
    };

    return { data: result.data, min: 0, max: 0, average: 0 };
};

const findPeriodWhenSalesDroppedBelowHalf = (data: { time: string; count: number; total: number }[]) => {
    // Calculate total sales for all periods
    const totalSales = data.reduce((sum, d) => {
        return sum + d.total;
    }, 0);

    let runningTotal = 0;
    for (let i = data.length - 1; i >= 0; i--) {
        const d = data[i];
        runningTotal += d.total;
        if (runningTotal > totalSales / 2) {
            // Return the period after which the total sales went below 50%
            try {
                return data.length - 1 > i + 1 ? data[i + 1].time : null;
            } catch (e) {
                return null;
            }
        }
    }

    // If sales never went below 50%, return null
    return null;
};

const CustomTooltip = ({ active, payload, showEarningsAsGross, showEarningsWithSubscriptions, theme }: any) => {
    if (active && payload && payload.length) {
        return (
            <div>
                <Grid
                    container
                    flexGrow={0}
                    alignItems="center"
                    spacing={1}
                    sx={{
                        backgroundColor: '#fff',
                        borderRadius: 4,
                        border: '1px solid #999',
                        maxWidth: 280,
                    }}
                >
                    <Grid item xs={12}>
                        <span style={{ fontWeight: 'bold' }}>{payload[0].payload.time}</span>
                    </Grid>
                    {payload.map((item: any, index: number) => (
                        <Grid item xs={12} key={index}>
                            {item.dataKey === 'count' && (
                                <Grid container flexGrow={1} alignItems="center">
                                    <Grid item xs={4} sx={{ color: theme.palette.primary.main }}>
                                        Spenders:
                                    </Grid>
                                    <Grid item xs={8} textAlign={'right'} sx={{ color: theme.palette.primary.main, paddingRight: '8px' }}>
                                        {item.value && typeof item.value === 'number' ? item.value.toLocaleString() : 0}
                                    </Grid>
                                </Grid>
                            )}

                            {item.dataKey === 'total' && (
                                <Grid container flexGrow={1} alignItems="center">
                                    <Grid item xs={4} sx={{ color: theme.palette.secondary.main }}>
                                        Total Sales:
                                    </Grid>
                                    <Grid
                                        item
                                        xs={8}
                                        textAlign={'right'}
                                        sx={{ color: theme.palette.primary.main, paddingRight: '8px', fontFamily: 'monospace' }}
                                    >
                                        {dinero({
                                            amount: item.value ? Math.trunc(item.value * 100) : 0,
                                            currency: 'USD',
                                        }).toFormat()}{' '}
                                        <small>({showEarningsAsGross ? 'gross' : 'net'})</small>
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                    ))}
                </Grid>
            </div>
        );
    }

    return null;
};

type Props = {
    subscriber: any;
    metricId: string;
    metricType: OnlyFansMetricType;
    showEarningsWithSubscriptions: boolean;
    showEarningsAsGross: boolean;
    timezone: string;
    theme: Theme;
};

const SextforceMetricsEarningsByIntervalGraph = (props: Props) => {
    const { subscriber, metricId, metricType, showEarningsAsGross, showEarningsWithSubscriptions, timezone, theme } = props;
    const userContext = useContext(UserContext);
    const settingsContext = useContext(SettingsContext);
    const params = useParams();
    const dialog = useDialog();

    const [dataMain, setDataMain] = useState<{
        data: { time: string; count: number; total: number }[];
        min: number;
        max: number;
        average: number;
    }>({ data: [], min: 0, max: 0, average: 0 });
    const largeScreen = useMediaQuery(theme.breakpoints.up('sm'));

    // Fetch Promo Campaign Subscribed At vs. Sales details
    const fetchMetricEarningsByInterval = async (): Promise<any> => {
        if (userContext.jwtToken && settingsContext.apiKey && 'userId' in params && params.userId && subscriber) {
            const query: string = `${settingsContext.routes.metrics.base}${params.userId}/${
                metricType === 'promoCampaign' ? 'promocampaigns' : 'trials'
            }/${metricId}/earningsByInterval`;

            const data = await fetch(query, {
                method: 'get',
                headers: {
                    Authorization: userContext.jwtToken,
                    apiKey: settingsContext.apiKey,
                },
            })
                .then(async response => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        handleHttpErrorResponse(response, dialog);
                    }
                })
                .catch(error => {
                    console.error(error);
                    handleHttpError(error, dialog);
                });

            return data;
        }

        return [];
    };

    const { data: metricEarningsByInterval, isLoading: metricEarningsByIntervalLoading } = useQuery(
        [`metricsEarningsByInterval`, metricId, params.userId],
        () => fetchMetricEarningsByInterval(),
        {
            refetchOnWindowFocus: false,
            // Stale time 1 hour
            staleTime: 60 * 1000 * 60,
            enabled: subscriber && metricId !== '' ? true : false,
        },
    );

    useEffect(() => {
        if (!metricEarningsByIntervalLoading && metricEarningsByInterval && metricEarningsByInterval.length > 0) {
            setDataMain(generateData(metricEarningsByInterval, showEarningsWithSubscriptions, showEarningsAsGross, timezone));
        } else {
            setDataMain({ data: [], min: 0, max: 0, average: 0 });
        }
    }, [metricEarningsByInterval, metricEarningsByIntervalLoading, showEarningsAsGross, showEarningsWithSubscriptions, timezone]);

    const periodWhenSalesDroppedBelowHalf = findPeriodWhenSalesDroppedBelowHalf(dataMain.data);

    const Chart = (chartData: any) => (
        <ResponsiveContainer width="100%" height="100%">
            <ComposedChart
                data={chartData && chartData.data ? chartData.data : []}
                margin={{
                    top: 0,
                    left: largeScreen ? 10 : 0,
                    right: largeScreen ? -10 : -20,
                    bottom: 60,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" stroke={theme.palette.grey[400]} />
                <XAxis
                    xAxisId="time"
                    dataKey="time"
                    name="Time"
                    type="category"
                    scale={'auto'}
                    // tickFormatter={value => (value && value !== 'auto' ? moment(value).tz(timezone).format('L') : '')}
                    domain={chartData && chartData.data && chartData.data.length > 0 ? ['dataMin', 'dataMax'] : ['auto', 'auto']}
                    interval={'preserveStartEnd'}
                    tick={{ fontSize: 13 }}
                    angle={-90}
                    dy={35}
                    dx={-5}
                />
                <YAxis
                    yAxisId="total"
                    dataKey={'total'}
                    scale={'linear'}
                    name="Total Sales"
                    domain={['dataMin', (dataMax: number) => Math.abs(dataMax * 1.1)]}
                    tickFormatter={(value: number) =>
                        dinero({
                            amount: value && value !== Infinity ? Math.trunc(value * 100) : 0,
                            currency: 'USD',
                        }).toFormat()
                    }
                    orientation="left"
                    tick={{ fill: theme.palette.secondary.main, fontSize: 13 }}
                />
                <Area
                    yAxisId="total"
                    xAxisId={'time'}
                    type="monotone"
                    dataKey="total"
                    // barSize={20}
                    // maxBarSize={20}
                    fill={theme.palette.secondary.light}
                    stroke={theme.palette.secondary.main}
                />
                <YAxis
                    yAxisId="count"
                    dataKey={'count'}
                    scale={'linear'}
                    name="Spenders Count"
                    domain={['dataMin', (dataMax: number) => Math.abs(dataMax * 1.1)]}
                    tickFormatter={(value: number) => value.toLocaleString()}
                    tick={{ fill: theme.palette.primary.main, fontSize: 13 }}
                    orientation="right"
                />
                <Line
                    xAxisId={'time'}
                    yAxisId="count"
                    type="linear"
                    dataKey="count"
                    stroke={theme.palette.primary.main}
                    activeDot={{ r: 0 }}
                    dot={{ r: 0 }}
                />
                {periodWhenSalesDroppedBelowHalf && (
                    <>
                        <ReferenceLine
                            xAxisId="time"
                            yAxisId={'total'}
                            x={periodWhenSalesDroppedBelowHalf}
                            stroke={theme.palette.error.main}
                            label={
                                <Label
                                    value="Below 50% Earnings"
                                    position="insideTopRight"
                                    angle={-90}
                                    offset={13}
                                    fill={theme.palette.error.main}
                                    fontSize={13}
                                />
                            }
                        />
                    </>
                )}
                <Tooltip
                    content={
                        <CustomTooltip
                            payload={chartData.data}
                            showEarningsAsGross={showEarningsAsGross}
                            showEarningsWithSubscriptions={showEarningsWithSubscriptions}
                            theme={theme}
                        />
                    }
                />
                <Legend
                    verticalAlign="top"
                    height={36}
                    formatter={(value: string) => (
                        <span style={{ fontSize: 13 }}>{value === 'count' ? 'Spenders Count' : 'Total Sales'}</span>
                    )}
                />
            </ComposedChart>
        </ResponsiveContainer>
    );

    return (
        <Card sx={{ width: '100%', marginBottom: theme.spacing(2) }}>
            <CardContent>
                <Grid container flexGrow={1} spacing={0} sx={{ marginBottom: theme.spacing(1) }}>
                    <Grid item xs={12}>
                        <Typography variant="h5" gutterBottom>
                            Earnings Lifecycle
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            The following graph shows the earnings lifecycle of this promo/campaign since it began until now. Use this graph
                            to identify the longevity and loyalty of the fans that subscribed to this promo/campaign. The interval of the
                            graph changes automatically based on the age of the promo/campaign. A vertical line is drawn to mark the period
                            when sales dropped below 50% of the total sales.
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container flexGrow={1} spacing={0} sx={{ padding: 0 }}>
                    <Grid item xs={12} sx={{ height: { xs: 300, sm: 500 } }}>
                        {metricEarningsByIntervalLoading ? <Skeleton /> : Chart(dataMain)}
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default SextforceMetricsEarningsByIntervalGraph;
