// HeatMap.tsx
import { Theme, useTheme } from '@mui/material';
import * as d3 from 'd3';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';

// Fills missing hours for a given array of objects that belong to the same day
export const fillMissingHours = (dayGroup: { _id: string; count: number }[]) => {
    const filledDayGroup = [];
    let existingHours = dayGroup.map(obj => new Date(obj._id).getUTCHours());

    for (let i = 0; i < 24; i++) {
        if (!existingHours.includes(i)) {
            const dateWithMissingHour = new Date(dayGroup[0]._id);
            dateWithMissingHour.setUTCHours(i);
            filledDayGroup.push({ _id: dateWithMissingHour.toISOString(), count: 0 });
        }
    }

    dayGroup.push(...filledDayGroup);
    dayGroup.sort((a, b) => new Date(a._id).valueOf() - new Date(b._id).valueOf()); // Sort by date
};

export const groupByDay = (inputArray: { _id: string; count: number }[]) => {
    const groupedByDay = [];

    let currentDayGroup: { _id: string; count: number }[] = [];
    let currentDay = null;

    inputArray.sort((a, b) => new Date(a._id).valueOf() - new Date(b._id).valueOf()); // Sort by date

    for (let obj of inputArray) {
        const objDate = new Date(obj._id);

        const objDay = objDate.toISOString().split('T')[0]; // Extract day (YYYY-MM-DD)

        if (currentDay !== objDay) {
            if (currentDayGroup.length > 0) {
                fillMissingHours(currentDayGroup);
                groupedByDay.push(currentDayGroup);
            }

            currentDayGroup = [obj];
            currentDay = objDay;
        } else {
            currentDayGroup.push(obj);
        }
    }

    // Push the last group if not empty, after filling in missing hours
    if (currentDayGroup.length > 0) {
        fillMissingHours(currentDayGroup);
        groupedByDay.push(currentDayGroup);
    }

    return groupedByDay;
};

//Convert a number from 0-23 to a 12-hour format string
export const convertTo12HourFormat = (hour: number) => {
    if (hour === 0) {
        return '12 am';
    } else if (hour < 12) {
        return `${hour} am`;
    } else if (hour === 12) {
        return '12 pm';
    } else {
        return `${hour - 12} pm`;
    }
};

const OnlineCountHeatMapGraph = ({
    data,
}: {
    data: {
        time: number;
        count: number;
    }[];
}) => {
    const theme: Theme = useTheme();
    const ref = useRef<SVGSVGElement>(null);
    const tooltipRef = useRef<HTMLDivElement>(null);
    const [containerWidth, setContainerWidth] = useState<number | null>(null);
    const [containerHeight, setContainerHeight] = useState<number | null>(null);

    useEffect(() => {
        const updateDimensions = () => {
            if (ref.current) {
                setContainerWidth(ref.current.clientWidth);
                setContainerHeight(ref.current.clientHeight);
            }
        };

        window.addEventListener('resize', updateDimensions);
        updateDimensions();

        return () => {
            window.removeEventListener('resize', updateDimensions);
        };
    }, []);

    useEffect(() => {
        if (!containerWidth || !containerHeight) {
            return;
        }

        const width = containerWidth;
        const height = containerHeight;
        const margin = { top: 0, right: 0, bottom: 20, left: 40 };

        // SVG setup
        const svg = d3.select(ref.current);

        // clear any previous renders
        svg.selectAll('*').remove();

        // Prepare data to separate the day and the hour
        const modifiedData = data.map(d => {
            return {
                day: new Date(d.time).setHours(0, 0, 0, 0),
                hour: new Date(d.time).getHours(),
                count: d.count,
            };
        });

        // scales
        const yScale = d3
            .scaleBand<number>()
            .domain(Array.from(new Set(modifiedData.map(d => d.day))))
            .range([height - margin.bottom, margin.top]);

        const xScale = d3
            .scaleBand<number>()
            .domain(d3.range(0, 24))
            .range([margin.left, width - margin.right]);

        // const colorScale = d3.scaleSequential(d3.interpolateBlues).domain([0, d3.max(modifiedData, d => d.count) || 0]);
        const colorScale = d3
            .scaleSequential(d3.interpolateRgb(d3.rgb('#005f7322'), d3.rgb('#00424F')))
            .domain([0, d3.max(modifiedData, d => d.count) || 0]);

        // axis
        const yAxis = d3.axisLeft(yScale).tickFormat(d => d3.timeFormat('%b %d')(new Date(d)));

        const xAxis = d3.axisBottom(xScale).tickFormat(d => convertTo12HourFormat(d));

        svg.append('g').attr('transform', `translate(${margin.left}, 0)`).call(yAxis);

        svg.append('g')
            .attr('transform', `translate(0, ${height - margin.bottom})`)
            .call(xAxis);

        // Compute the width of each rectangle
        const rectHeight = (height - margin.top - margin.bottom) / Array.from(new Set(modifiedData.map(d => d.day))).length;

        // heat map
        svg.selectAll('rect')
            .data(modifiedData)
            .join('rect')
            .attr('y', d => yScale(d.day) || 0)
            .attr('x', d => xScale(d.hour) || 0)
            .attr('height', rectHeight)
            .attr('width', xScale.bandwidth())
            .attr('fill', d => (d.count === 0 ? '#ffffff' : colorScale(d.count)));
        // .attr('stroke', 'black')
        // .attr('stroke-width', 0.2);

        // Tooltips
        const tooltip = d3.select(tooltipRef.current);

        svg.selectAll('rect')
            .on('mouseover', (event, d) => {
                const tooltipWidth = 100; // Approximate width of the tooltip, adjust as needed
                let xPos = event.pageX + 10;
                if (xPos + tooltipWidth > window.innerWidth) {
                    xPos = event.pageX - tooltipWidth - 10;
                }

                const data: { day: number; hour: number; count: number } = d as any;
                const dateFromDayAndHouse = new Date(data.day).setHours(data.hour, 0, 0, 0);

                tooltip
                    .style('opacity', 1)
                    .style('left', `${xPos}px`)
                    .style('top', `${event.pageY + 10}px`)
                    .html(
                        `<strong>${moment(dateFromDayAndHouse).format(
                            'ddd Do, hh:mm A',
                        )}</strong><br /><br />Count: ${data.count.toLocaleString()}`,
                    );
            })
            .on('mouseout', function () {
                tooltip.style('opacity', 0);
            });
    }, [containerHeight, containerWidth, data]);

    return (
        <>
            <svg ref={ref} width="100%" height="100%"></svg>
            <div
                ref={tooltipRef}
                style={{
                    position: 'absolute',
                    opacity: 0,
                    background: 'white',
                    border: '1px solid black',
                    padding: '5px',
                    pointerEvents: 'none',
                }}
            ></div>
        </>
    );
};

export default OnlineCountHeatMapGraph;
