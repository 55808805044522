import AddIcon from '@mui/icons-material/Add';
import CircleIcon from '@mui/icons-material/Circle';
import ClearIcon from '@mui/icons-material/Clear';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EditIcon from '@mui/icons-material/Edit';
import PersonIcon from '@mui/icons-material/Person';
import RefreshIcon from '@mui/icons-material/Refresh';
import SearchIcon from '@mui/icons-material/Search';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Button, Divider, Grid, IconButton, InputBase, Paper, Theme, useTheme } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress';
import { DataGridPro, GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import useHostessStatus from '../../hooks/useHostessStatus';
import useSubscribersAdmin from '../../hooks/useSubscribersAdmin';

const UsernameCell = (props: { subscriber: any }) => {
    const { subscriber } = props;
    const [showCopy, setShowCopy] = useState<boolean>(false);

    return (
        <div onMouseOver={() => setShowCopy(true)} onMouseOut={() => setShowCopy(false)} style={{ overflow: 'hidden' }}>
            <Grid container spacing={1} alignItems="center">
                <Grid item xs="auto">
                    <CircleIcon color={subscriber.isLoggedIn ? 'success' : 'error'} sx={{ fontSize: 8 }} />
                </Grid>
                <Grid item xs>
                    <span onClick={() => navigator.clipboard.writeText(subscriber.username)} style={{ cursor: 'pointer' }}>
                        {subscriber.username}
                    </span>
                </Grid>
                <Grid item xs>
                    <ContentCopyIcon sx={{ fontSize: 12, visibility: showCopy ? 'visible' : 'hidden' }} />
                </Grid>
            </Grid>
        </div>
    );
};

const timestampToTimeLapsedString = (timestamp: number) => {
    return moment(timestamp).fromNow();
};

const isHostessRequired = (subscriber: any): boolean => {
    if (!subscriber.isLoggedIn) {
        return false;
    }

    if (
        subscriber.sextforce &&
        subscriber.sextforce.active &&
        moment(subscriber.sextforce.paidDate).isAfter(moment().subtract(1, 'month').subtract(1, 'day'))
    ) {
        return true;
    }

    if (
        subscriber.housekeeping &&
        subscriber.housekeeping.active &&
        moment(subscriber.housekeeping.paidDate).isAfter(moment().subtract(1, 'month').subtract(1, 'day'))
    ) {
        return true;
    }

    if (
        subscriber.inCommonFans &&
        subscriber.inCommonFans.active &&
        moment(subscriber.inCommonFans.paidDate).isAfter(moment().subtract(1, 'month').subtract(1, 'day'))
    ) {
        return true;
    }

    if (
        subscriber.messageUsers &&
        subscriber.messageUsers.autoMessageNewSubscriber &&
        subscriber.messageUsers.autoMessageNewSubscriber.active &&
        subscriber.messageUsers.autoMessageNewSubscriber.message !== ''
    ) {
        return true;
    }

    if (
        subscriber.messageUsers &&
        subscriber.messageUsers.autoMessageReturningSubscriber &&
        subscriber.messageUsers.autoMessageReturningSubscriber.active &&
        subscriber.messageUsers.autoMessageReturningSubscriber.message !== ''
    ) {
        return true;
    }

    if (
        subscriber.messageUsers &&
        subscriber.messageUsers.autoMessageNewTrialSubscriber &&
        subscriber.messageUsers.autoMessageNewTrialSubscriber.active &&
        subscriber.messageUsers.autoMessageNewTrialSubscriber.message !== ''
    ) {
        return true;
    }

    return false;
};

const HostessTimestamp = (props: { subscriber: any }) => {
    const { subscriber } = props;
    const hostessKeys: { connected: string; lastMessageTimestamp: string } | null = subscriber._hostess || null;
    const { setSubscriberHostessStatus, setSubscriberHostessStatusLoading } = useHostessStatus();

    const [color, setColor] = useState<'inherit' | 'error' | 'success' | 'primary' | 'secondary' | 'info' | 'warning'>('success');
    const [required, setRequired] = useState<'text' | 'outlined' | 'contained'>('outlined');

    useEffect(() => {
        let c: 'inherit' | 'error' | 'success' | 'primary' | 'secondary' | 'info' | 'warning' = 'success';
        let r: 'text' | 'outlined' | 'contained' = 'outlined';

        if (!hostessKeys || !hostessKeys.connected || hostessKeys.connected === '0') {
            c = 'error';
        } else if (
            !hostessKeys.lastMessageTimestamp ||
            moment(parseInt(hostessKeys.lastMessageTimestamp)).isBefore(moment().subtract(5, 'minute'))
        ) {
            c = 'warning';
        }

        if (isHostessRequired(subscriber)) {
            r = 'contained';
        }

        if (r === 'outlined' && c === 'error') {
            c = 'inherit';
        }

        setColor(c);
        setRequired(r);
    }, [hostessKeys, subscriber]);

    const handleHostessStatus = async (action: 'add' | 'remove') => {
        const doChangge = async () => {
            setSubscriberHostessStatus(subscriber._id, action)
                .then(result => {
                    if (result.res && result.res === 'success') {
                        setColor(action === 'add' ? 'success' : 'error');
                    }
                })
                .catch(() => {});
        };

        doChangge();
    };

    return (
        <div style={{ textAlign: 'center' }}>
            {hostessKeys && hostessKeys.connected && hostessKeys.connected === '1' ? (
                <LoadingButton
                    variant="contained"
                    color={color}
                    loading={setSubscriberHostessStatusLoading}
                    onClick={() => {
                        handleHostessStatus('remove');
                    }}
                >
                    {timestampToTimeLapsedString(parseInt(hostessKeys.lastMessageTimestamp))}
                </LoadingButton>
            ) : (
                <LoadingButton
                    variant={required}
                    color={color}
                    loading={setSubscriberHostessStatusLoading}
                    onClick={() => {
                        handleHostessStatus('add');
                    }}
                >
                    Not Connected
                </LoadingButton>
            )}
        </div>
    );
};

const columns: GridColDef[] = [
    {
        field: 'username',
        headerName: 'Username',
        width: 200,
        headerClassName: 'boldHeader',
        renderCell: (params: GridRenderCellParams) => <UsernameCell subscriber={params.row} />,
    },
    {
        field: '_id',
        headerName: 'ID',
        width: 210,
        renderCell: (params: GridRenderCellParams) => <span style={{ fontFamily: 'monospace' }}>{params.id}</span>,
    },
    { field: 'email', headerName: 'Email', width: 250 },
    { field: 'password', headerName: 'Password', width: 250 },
    { field: 'realName', headerName: 'Real Name', width: 250 },
    { field: 'notes', headerName: 'Notes', width: 400 },
    {
        field: 'lastHostessTimestamp',
        headerName: 'Hostess Last Message',
        width: 180,
        align: 'center',
        renderCell: (params: GridRenderCellParams) => <HostessTimestamp subscriber={params.row} />,
        sortable: true,
        sortComparator: (v1, v2, params) => {
            return moment(v1).diff(moment(v2));
        },
    },
    {
        field: 'actions',
        headerName: 'Actions',
        width: 100,
        renderCell: (params: GridRenderCellParams) => (
            <>
                <IconButton component={Link} to={`/subscribers/${params.id}`}>
                    <PersonIcon color="primary" />
                </IconButton>
                <IconButton component={Link} to={`/subscribers/${params.id}/edit`}>
                    <EditIcon color="primary" />
                </IconButton>
            </>
        ),
        sortable: false,
    },
];

const AdminUsersList = () => {
    const theme: Theme = useTheme();
    const [searchParams, setSearchParams] = useSearchParams();
    const [offset, setOffset] = useState<number>(0);
    const [limit, setLimit] = useState<number>(20);
    const [filter, setFilter] = useState<string>(searchParams.get('filter') || '');
    const [filterInput, setFilterInput] = useState<string>(searchParams.get('filter') || '');
    const refFilter = useRef(null);
    const { data: subscribers, isFetching: subscribersLoading, refetch: subscribersRefetch } = useSubscribersAdmin(offset, limit, filter);

    const handleFilterSubmit = (e: React.FormEvent) => {
        e.preventDefault();

        if (refFilter && refFilter.current) {
            setFilter(refFilter.current['value']);

            // Set search params
            setSearchParams(new URLSearchParams({ filter: refFilter.current['value'] }));
        }
    };

    const resetFilter = () => {
        setFilterInput('');

        if (filter && typeof filter === 'string' && filter !== '') {
            setFilter('');

            // Set search params
            setSearchParams(new URLSearchParams({ filter: '' }));
        }
    };

    return (
        <>
            <Grid container spacing={1} sx={{ width: '100%', mb: 2 }} alignItems="center">
                <Grid item xs={12} md>
                    <Paper component="form" variant="outlined" sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: '100%' }}>
                        <InputBase
                            sx={{ ml: 1, flex: 1 }}
                            id="filter"
                            value={filterInput}
                            onChange={e => setFilterInput(e.target.value)}
                            inputRef={refFilter}
                            placeholder="Search"
                        />
                        <IconButton type="submit" onClick={handleFilterSubmit}>
                            <SearchIcon />
                        </IconButton>
                        <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                        <IconButton color="primary" sx={{ p: '10px' }} onClick={resetFilter}>
                            <ClearIcon />
                        </IconButton>
                    </Paper>
                </Grid>
                <Grid item xs md="auto">
                    <Button variant="contained" endIcon={<AddIcon />} size="large" fullWidth component={Link} to="/subscribers/new">
                        Add Subscriber
                    </Button>
                </Grid>
                <Grid item xs="auto" md="auto">
                    <IconButton
                        size="large"
                        onClick={() => {
                            subscribersRefetch();
                        }}
                    >
                        <RefreshIcon />
                    </IconButton>
                </Grid>
            </Grid>

            <Box
                style={{
                    height: 'calc(100vh - 200px)',
                    width: '100%',
                }}
                sx={{
                    '.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
                        outline: 'none',
                    },
                }}
            >
                <DataGridPro
                    rows={subscribers && subscribers.rows ? subscribers.rows : []}
                    columns={columns}
                    density={'compact'}
                    autoHeight
                    disableColumnFilter
                    rowCount={subscribers && subscribers.metadata && subscribers.metadata.total ? subscribers.metadata.total : 0}
                    getRowId={row => row._id}
                    pageSizeOptions={[10, 20, 40, 80, 100]}
                    pagination
                    paginationMode={'server'}
                    onPaginationModelChange={model => {
                        setOffset(model.pageSize * model.page);
                        setLimit(model.pageSize);
                    }}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: limit,
                                page: Math.trunc(offset / limit),
                            },
                        },
                        pinnedColumns: {
                            left: ['username'],
                            right: ['lastHostessTimestamp', 'actions'],
                        },
                    }}
                    loading={subscribersLoading}
                    disableRowSelectionOnClick
                    slots={{
                        loadingOverlay: LinearProgress,
                    }}
                    sx={{
                        '& .boldHeader': {
                            fontWeight: 'bold',
                        },
                        '& .MuiDataGrid-cell': {
                            backgroundColor: theme.palette.common.white,
                        },
                        '& .MuiDataGrid-columnHeader': {
                            backgroundColor: theme.palette.common.white,
                        },
                        '& .MuiDataGrid-virtualScroller': {
                            backgroundColor: theme.palette.common.white,
                        },
                        '& .MuiDataGrid-footerContainer': {
                            backgroundColor: theme.palette.common.white,
                        },
                    }}
                />
            </Box>
        </>
    );
};

export default AdminUsersList;
