import { useContext } from 'react';
import { useQuery } from 'react-query';
import { SettingsContext } from '../store/SettingsContext';
import { UserContext } from '../store/UserContext';
import useAxios from './useAxios';
import useSubscriber, { OnlyFansSubscriptionsUserType } from './useSubscriber';

const useSubscriberUsersCount = (type: OnlyFansSubscriptionsUserType, dateFrom: Date | null, dateTo: Date | null) => {
    const userContext = useContext(UserContext);
    const settingsContext = useContext(SettingsContext);
    const { data: subscriber } = useSubscriber();
    const axios = useAxios();

    // Fetch
    const fetchUsersCount = async (): Promise<{ count: number }> => {
        const query: string = `${settingsContext.routes.onlyFans.base}${subscriber._id}/users/count?${new URLSearchParams({
            type,
            dateFrom: dateFrom ? dateFrom.toISOString() : '',
            dateTo: dateTo ? dateTo.toISOString() : '',
        })}`;

        return axios
            .get(query)
            .then(response => response.data)
            .catch(() => {
                return { count: 0 };
            });
    };

    const subscriberAuthId = useQuery(['subscriberUsersCount', subscriber?._id, type, dateFrom, dateTo], () => fetchUsersCount(), {
        refetchOnWindowFocus: false,
        retry: false,
        staleTime: 1000 * 60 * 5,
        enabled: userContext.jwtToken && settingsContext.apiKey && subscriber && type && dateFrom && dateTo ? true : false,
    });

    return { ...subscriberAuthId };
};

export default useSubscriberUsersCount;
