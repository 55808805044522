import {
    Box,
    Card,
    CardContent,
    Checkbox,
    FormControl,
    FormControlLabel,
    Grid,
    Stack,
    Theme,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import * as d3 from 'd3';
import { useEffect, useRef, useState } from 'react';

// Type for user data
type User = {
    id: number;
    date?: Date;
    userName: string;
};

// Props for the UserScatterChart component
type UserScatterChartProps = {
    users: User[];
    closeUsers: { [key: number]: boolean }; // Close users
    hideNonCloseUsers: boolean; // Hide non-close users
    onlyShowRecentUsers: boolean; // Only show recent users
};

const UserScatterPlot = (props: UserScatterChartProps) => {
    const { users, closeUsers, hideNonCloseUsers, onlyShowRecentUsers } = props;
    const theme: Theme = useTheme();
    const svgRef = useRef(null);
    const containerRef = useRef<any>(null); // Container ref
    const largeScreen = useMediaQuery(theme.breakpoints.up('sm'));
    const [containerWidth, setContainerWidth] = useState(0); // State to hold the container width
    const [containerHeight, setContainerHeight] = useState(0); // State to hold the container height
    const [filteredUsersList, setFilteredUsersList] = useState<User[]>([]);

    useEffect(() => {
        let filteredUsers: User[] = users;

        if (hideNonCloseUsers) {
            filteredUsers = filteredUsers.filter(user => closeUsers[user.id]);
        }

        if (onlyShowRecentUsers) {
            // Filter users who's user IDs are greater than 300,000,000
            filteredUsers = filteredUsers.filter(user => user.id > 300000000);
        }

        setFilteredUsersList(filteredUsers);
    }, [closeUsers, hideNonCloseUsers, onlyShowRecentUsers, users]);

    // Function to update the container width
    const updateWidth = () => {
        if (containerRef.current) {
            setContainerWidth(containerRef.current.offsetWidth);
            setContainerHeight(containerRef.current.offsetHeight);
        }
    };

    useEffect(() => {
        // Update width on mount and add resize event listener
        updateWidth();
        window.addEventListener('resize', updateWidth);

        return () => {
            // Cleanup listener on component unmount
            window.removeEventListener('resize', updateWidth);
        };
    }, []);

    // useEffect(() => {
    //     if (containerWidth === 0) return; // Don't draw if width is not yet set

    //     // Continue with D3 drawing logic, using `containerWidth` for the width
    // }, [filteredUsersList, containerWidth]); // Redraw chart if users data or container width changes

    // Clustering function
    const clusterUsers = (users: User[], epsilon: number, minPoints: number) => {
        const clusters: User[][] = [];
        const visited = new Set<number>();

        const regionQuery = (point: User, epsilon: number) => {
            return users.filter(
                q =>
                    Math.abs(point.id - q.id) <= epsilon &&
                    Math.abs(new Date(point.date!).getTime() - new Date(q.date!).getTime()) <= epsilon * 24 * 60 * 60 * 1000,
            );
        };

        const expandCluster = (point: User, neighbors: User[]) => {
            const cluster: User[] = [point];
            for (let i = 0; i < neighbors.length; i++) {
                const neighbor = neighbors[i];
                if (!visited.has(neighbor.id)) {
                    visited.add(neighbor.id);
                    const neighborNeighbors = regionQuery(neighbor, epsilon);
                    if (neighborNeighbors.length >= minPoints) {
                        neighbors.push(...neighborNeighbors.filter(n => !visited.has(n.id)));
                    }
                }
                if (!clusters.some(c => c.includes(neighbor))) {
                    cluster.push(neighbor);
                }
            }
            return cluster;
        };

        for (const point of users) {
            if (visited.has(point.id)) continue;
            visited.add(point.id);
            const neighbors = regionQuery(point, epsilon);
            if (neighbors.length < minPoints) continue;
            const cluster = expandCluster(point, neighbors);
            clusters.push(cluster);
        }

        return clusters;
    };

    useEffect(() => {
        if (containerWidth === 0 || !svgRef.current) return;

        // Size and margins for the chart
        const width = containerWidth;
        const height = containerHeight;
        const margin = { top: 0, right: 20, bottom: 20, left: largeScreen ? 60 : 40 };

        // Scales
        const xScale = d3
            .scaleLinear()
            .domain(d3.extent(filteredUsersList, d => d.id) as [number, number])
            .range([margin.left, width - margin.right]);

        const yScale = d3
            .scaleTime()
            .domain(d3.extent(filteredUsersList, d => new Date(d.date!)) as [Date, Date])
            .range([height - margin.bottom, margin.top]);

        // Create SVG element
        const svg = d3.select(svgRef.current).attr('width', width).attr('height', height);

        // Clear SVG
        svg.selectAll('*').remove();

        // Axes
        svg.append('g')
            .attr('transform', `translate(0,${height - margin.bottom})`)
            .call(d3.axisBottom(xScale).ticks(width / 200))
            .attr('font-size', '13');

        svg.append('g')
            .attr('transform', `translate(${margin.left},0)`)
            .call(d3.axisLeft(yScale).ticks(height / 100))
            .attr('font-size', '13');

        // Add a grid.
        svg.append('g')
            .attr('stroke', 'currentColor')
            .attr('stroke-opacity', 0.1)
            .call(g =>
                g
                    .append('g')
                    .selectAll('line')
                    .data(xScale.ticks())
                    .join('line')
                    .attr('x1', d => 0.5 + xScale(d))
                    .attr('x2', d => 0.5 + xScale(d))
                    .attr('y1', margin.top)
                    .attr('y2', height - margin.bottom),
            )
            .call(g =>
                g
                    .append('g')
                    .selectAll('line')
                    .data(yScale.ticks())
                    .join('line')
                    .attr('y1', d => 0.5 + yScale(d))
                    .attr('y2', d => 0.5 + yScale(d))
                    .attr('x1', margin.left)
                    .attr('x2', width - margin.right),
            );

        // Tooltip group
        const tooltip = d3
            .select(containerRef.current)
            .append('div')
            .style('position', 'absolute')
            .style('visibility', 'hidden')
            .style('background-color', 'white')
            .style('border', 'solid')
            .style('border-width', '1px')
            .style('border-radius', theme.shape.borderRadius)
            .style('padding', '10px');

        // Cluster the users
        const clusters = clusterUsers(
            filteredUsersList.filter(u => closeUsers[u.id]),
            1000000,
            5,
        );

        // Draw rectangles around clusters
        svg.selectAll('.cluster-rect')
            .data(clusters)
            .enter()
            .append('rect')
            .attr('class', 'cluster-rect')
            .attr('x', d => d3.min(d, u => xScale(u.id))!)
            .attr('y', d => d3.min(d, u => yScale(new Date(u.date!)))!)
            .attr('width', d => d3.max(d, u => xScale(u.id))! - d3.min(d, u => xScale(u.id))!)
            .attr('height', d => d3.max(d, u => yScale(new Date(u.date!)))! - d3.min(d, u => yScale(new Date(u.date!)))!)
            .attr('fill', `${theme.palette.error.main}22`)
            .attr('stroke', theme.palette.warning.main)
            .attr('stroke-width', 2)
            .attr('rx', 5)
            .attr('ry', 5);

        // Drawing the circles
        svg.selectAll('.user')
            .data(filteredUsersList)
            .join('circle')
            .attr('class', 'user')
            .attr('cx', d => xScale(d.id))
            .attr('cy', d => yScale(new Date(d.date!)))
            .attr('r', d => (closeUsers[d.id] ? 3 : 2))
            .attr('fill', d => (closeUsers[d.id] ? theme.palette.error.main : theme.palette.primary.main))
            .on('mouseover', function (event, d) {
                if (closeUsers[d.id]) {
                    d3.select(this).attr('r', 3).style('fill', theme.palette.warning.main);

                    tooltip
                        .style('visibility', 'visible')
                        .html(`User ID: ${d.id}<br>Subscribed Date: ${new Date(d.date!).toLocaleDateString()}`)
                        .style('left', `${event.pageX + 10}px`) // Offset by 10px from cursor
                        .style('top', `${event.pageY + 10}px`);
                }
            })
            .on('mousemove', function (event) {
                tooltip.style('top', event.pageY + 20 + 'px').style('left', event.pageX + 20 + 'px');
            })
            .on('mouseout', function (d) {
                d3.select(this)
                    .attr('r', d => (closeUsers[(d as User).id] ? 3 : 2))
                    .style('fill', d => (closeUsers[(d as User).id] ? theme.palette.error.main : theme.palette.primary.main));

                tooltip.style('visibility', 'hidden');
            });
    }, [
        closeUsers,
        containerHeight,
        containerWidth,
        largeScreen,
        theme.palette.error.main,
        theme.palette.primary.main,
        theme.palette.warning.main,
        theme.shape.borderRadius,
        filteredUsersList,
    ]); // Redraw chart if users data changes

    return (
        <Box id="usersIdsScatterGraph" ref={containerRef} sx={{ width: '100%', height: { xs: 300, sm: 400 } }}>
            {/* Container with ref */}
            <svg ref={svgRef} width={containerWidth} height={400}></svg> {/* Use dynamic width */}
        </Box>
    );
};

type Props = {
    users: User[];
    closeUsers: { [key: number]: boolean };
};

const SextforceMetricsUsersIdScatterGraph = (props: Props) => {
    const { users, closeUsers } = props;
    const theme: Theme = useTheme();

    const [hideNonCloseUsers, setHideNonCloseUsers] = useState(false);
    const [onlyShowRecentUsers, setOnlyShowRecentUsers] = useState(false);

    // Sort users by User ID
    const sortedUserIds = users.slice().sort((a, b) => a.id - b.id);

    // Remove users with invalid dates
    sortedUserIds.forEach((user, index) => {
        if (!user.date) {
            sortedUserIds.splice(index, 1);
        }
    });

    const closeUsersCount = Object.keys(closeUsers).length;
    const totalUsersCount = users.length;
    const closeUsersPercentage = (closeUsersCount / totalUsersCount) * 100;
    const closeUsersPercentageRounded = Math.round(closeUsersPercentage * 100) / 100; // Round to 2 decimal places

    return (
        <Card sx={{ width: '100%', marginBottom: 2 }}>
            <CardContent>
                <Grid container flexGrow={1} spacing={0} sx={{ marginBottom: 1 }}>
                    <Grid item xs={12}>
                        <Typography variant="h5" gutterBottom>
                            User IDs Distribution
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            The following graph shows a distribution of the user IDs and their subscription dates. When a group of user IDs
                            are close together, it is a red flag that suggests that these users were registered in a short period of time.
                            This could be a sign of fake accounts or bots. These suspicious users are marked in{' '}
                            <span style={{ color: theme.palette.error.main }}>red</span>, and their strong groupings are surrounded by{' '}
                            <span
                                style={{
                                    color: theme.palette.warning.main,
                                    backgroundColor: `${theme.palette.warning.main}22`,
                                }}
                            >
                                orange rectangles
                            </span>
                            . It is important to note that a few suspicious users on their own may not be a cause for concern, but a large
                            number of them in a short period of time is a red flag, espcially if they have not spent any money.
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        {Object.keys(closeUsers).length > 0 && (
                            <Stack direction="row" spacing={1} alignItems={'center'}>
                                <Typography variant="body1" color={theme.palette.error.main}>
                                    Found {closeUsersCount.toLocaleString()} close user IDs ({closeUsersPercentageRounded}% of total users)
                                </Typography>
                                <FormControl>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={hideNonCloseUsers}
                                                onChange={e => setHideNonCloseUsers(e.target.checked)}
                                                color="primary"
                                            />
                                        }
                                        label="Hide non-close users"
                                    />
                                </FormControl>
                                <FormControl>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={onlyShowRecentUsers}
                                                onChange={e => setOnlyShowRecentUsers(e.target.checked)}
                                                color="primary"
                                            />
                                        }
                                        label="Only show recent users"
                                    />
                                </FormControl>
                            </Stack>
                        )}

                        {Object.keys(closeUsers).length === 0 && (
                            <Typography variant="body1" color={theme.palette.success.dark} gutterBottom>
                                No close users found
                            </Typography>
                        )}
                    </Grid>
                </Grid>
                <Grid container flexGrow={1} spacing={0} sx={{ padding: 0 }}>
                    <Grid item xs={12}>
                        <UserScatterPlot
                            users={sortedUserIds}
                            closeUsers={closeUsers}
                            hideNonCloseUsers={hideNonCloseUsers}
                            onlyShowRecentUsers={onlyShowRecentUsers}
                        />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default SextforceMetricsUsersIdScatterGraph;
