import LoadingButton from '@mui/lab/LoadingButton';
import { Card, CardContent, FormControlLabel, Grid, MenuItem, Radio, RadioGroup, Select, Skeleton, useMediaQuery } from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';
import { Theme } from '@mui/system';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import React from 'react';
import { useParams } from 'react-router-dom';
import useSextforceAgents from '../../../../hooks/useSextforceAgents';
import { d2f } from '../../../../utils/common';
import SelectTimezone from '../../../forms/helpers/SelectTimezone';
import { ReportSearchBarPropsSeachParams, SearchGroup } from '../SearchGroup';

type SextforceAssignTransactionsSearchBarProps = {
    timezone: string;
    setTimezone: (timezone: string) => void;
    mainSearch: ReportSearchBarPropsSeachParams;
    filter: string;
    setFilter: React.Dispatch<React.SetStateAction<string>>;
    agent: string;
    setAgent: React.Dispatch<React.SetStateAction<string>>;
    isFetchingReport: boolean;
    requestReport: () => void;
};

const SextforceTransactionsSearchBar = (props: SextforceAssignTransactionsSearchBarProps) => {
    const { timezone, setTimezone, mainSearch, filter, setFilter, agent, setAgent, isFetchingReport, requestReport } = props;
    const theme: Theme = useTheme();
    const params = useParams();
    const largeScreen = useMediaQuery(theme.breakpoints.up('sm'));
    const { data: agents, isLoading: agentsLoading } = useSextforceAgents(params.userId);

    const RowFilter = () => (
        <Grid container spacing={1} alignItems={'center'}>
            <Grid item xs={'auto'}>
                <RadioGroup row defaultValue={filter} value={filter} name="filter" onChange={e => setFilter(e.target.value)}>
                    <FormControlLabel value="all" control={<Radio size="small" />} label="All Transactions" />
                    <FormControlLabel value="unassigned" control={<Radio size="small" />} label="Only Unassigned Transactions" />
                    <FormControlLabel value="agent" control={<Radio size="small" />} label="Only Transactions Assigned to" />
                </RadioGroup>
            </Grid>
            <Grid item xs={2}>
                {agentsLoading ? (
                    <Skeleton width={'100%'} />
                ) : (
                    <Select
                        id="agent"
                        value={agents && agents.length > 0 ? agent : ''}
                        disabled={filter !== 'agent'}
                        sx={{ width: '100%' }}
                        size="small"
                        onChange={(e, child) => setAgent(e.target.value)}
                    >
                        {agents &&
                            agents
                                .sort((a, b) => (a.name.toUpperCase() < b.name.toUpperCase() ? -1 : 1))
                                .map((agent: any) => (
                                    <MenuItem value={agent._id} key={agent._id}>
                                        {agent.name} ({d2f(agent.fraction).toFixed(2)})
                                    </MenuItem>
                                ))}
                    </Select>
                )}
            </Grid>
        </Grid>
    );

    return (
        <Card variant="elevation" sx={{ marginBottom: 2, displayPrint: 'none' }}>
            <CardContent>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <Grid container spacing={2} alignItems={'center'} justifyContent={'center'}>
                        <Grid item xs={12}>
                            <SelectTimezone timezone={timezone} setTimezone={setTimezone} size="small" fullWidth={!largeScreen} />
                        </Grid>

                        <Grid item xs={12}>
                            <SearchGroup params={mainSearch} enabled={true} theme={theme} />
                        </Grid>

                        <Grid item xs={12}>
                            <RowFilter />
                        </Grid>

                        <Grid item xs={12}>
                            <LoadingButton
                                variant="contained"
                                disabled={isFetchingReport || (filter === 'agent' && agent === '')}
                                loading={isFetchingReport}
                                onClick={requestReport}
                                size="large"
                                fullWidth
                            >
                                Show
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </LocalizationProvider>
            </CardContent>
        </Card>
    );
};

export default SextforceTransactionsSearchBar;
