import SquareIcon from '@mui/icons-material/Square';
import LoadingButton from '@mui/lab/LoadingButton';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    List,
    ListItemButton,
    Paper,
    Stack,
    Theme,
    Typography,
    useTheme,
} from '@mui/material';
import { useEffect, useState } from 'react';
import useSubscriber from '../../hooks/useSubscriber';
import useSubscriberCategories from '../../hooks/useSubscriberCategories';

type Props = {
    subscriber: any;
    open: boolean;
    onClose: () => void;
    refetchSubscribers: () => void;
};

const DashboardMyAccountsSetCategoryDialog = (props: Props) => {
    const { subscriber, open, onClose, refetchSubscribers } = props;
    const theme: Theme = useTheme();
    const { data: categories, isFetching: categoriesLoading } = useSubscriberCategories();
    const { setSubscriberCategory, setSubscriberCategoryLoading } = useSubscriber();
    const [selectedCategory, setSelectedCategory] = useState<string | null>(null);

    const handleSetCategory = () => {
        setSubscriberCategory(subscriber._id, selectedCategory).then(() => {
            refetchSubscribers();
            onClose();
        });
    };

    useEffect(() => {
        if (open) {
            setSelectedCategory(subscriber && subscriber.cognito && subscriber.cognito.category ? subscriber.cognito.category : null);
        }
    }, [open, subscriber]);

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
            <DialogTitle>Set Categories</DialogTitle>
            <DialogContent>
                <Typography variant="body1" gutterBottom sx={{ mb: 2 }}>
                    Move <strong>{subscriber.username}</strong> to a category:
                </Typography>
                <DialogContentText>
                    {categoriesLoading
                        ? 'Loading categories...'
                        : `You have ${categories?.length ?? 0} ${categories?.length === 1 ? 'category' : 'categories'}`}
                </DialogContentText>
                <Grid container spacing={2} alignItems="center" sx={{ mb: 2 }}>
                    <Grid item xs={12} textAlign="center">
                        <Paper variant="outlined">
                            <List
                                sx={{
                                    width: '100%',
                                    maxHeight: 300,
                                }}
                            >
                                <ListItemButton
                                    selected={selectedCategory === null}
                                    onClick={() => setSelectedCategory(null)}
                                    sx={{
                                        pt: 2,
                                        pb: 2,
                                    }}
                                >
                                    <Stack direction="row" spacing={1} alignItems="center">
                                        <SquareIcon
                                            sx={{
                                                color: theme.palette.background.paper,
                                                border: `1px solid ${theme.palette.divider}`,
                                                mr: 1,
                                            }}
                                        />
                                        <Typography variant="body1" color="text.secondary">
                                            No Category
                                        </Typography>
                                    </Stack>
                                </ListItemButton>
                                {categories?.map(category => (
                                    <ListItemButton
                                        key={category._id}
                                        selected={selectedCategory === category._id}
                                        onClick={() => setSelectedCategory(category._id!)}
                                        sx={{
                                            pt: 2,
                                            pb: 2,
                                        }}
                                    >
                                        <Stack direction="row" spacing={1} alignItems="center">
                                            {!category.color || category.color === 'none' ? (
                                                <SquareIcon
                                                    sx={{
                                                        color: theme.palette.background.paper,
                                                        border: `1px solid ${theme.palette.divider}`,
                                                        mr: 1,
                                                    }}
                                                />
                                            ) : (
                                                <SquareIcon sx={{ color: category.color, mr: 1 }} />
                                            )}
                                            <Typography variant="body1">{category.name}</Typography>
                                        </Stack>
                                    </ListItemButton>
                                ))}
                            </List>
                        </Paper>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <LoadingButton
                    onClick={handleSetCategory}
                    variant="contained"
                    color="primary"
                    disabled={setSubscriberCategoryLoading}
                    loading={setSubscriberCategoryLoading || categoriesLoading}
                >
                    Set
                </LoadingButton>
                <Button onClick={onClose} color="secondary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DashboardMyAccountsSetCategoryDialog;
