import { Card, CardContent, CardHeader, Grid, Typography } from '@mui/material';
import { Theme } from '@mui/system';
import React from 'react';
import { Link } from 'react-router-dom';
import { ServiceActions } from '../../store/SettingsContext';

type Props = {
    subscriber: any;
    actions: ServiceActions[] | undefined;
    theme: Theme;
};

const ServiceActionsBlocks = (props: Props) => {
    const { subscriber, actions, theme } = props;

    return (
        <>
            <Typography variant="h5" sx={{ marginTop: theme.spacing(4), marginBottom: theme.spacing(2) }}>
                Actions
            </Typography>

            <Grid container spacing={1} sx={{ marginBottom: theme.spacing(2) }}>
                {actions &&
                    actions
                        .filter((action: ServiceActions) => action.visible !== false)
                        .map((action: ServiceActions, index: number) => (
                            <React.Fragment key={index}>
                                {action.type === 'category' && (
                                    <Grid item xs={12}>
                                        <Typography variant="h6" sx={{ mt: 2 }}>
                                            {action.title}
                                        </Typography>
                                        {action.description && (
                                            <Typography variant="body2" color="text.secondary">
                                                {action.description}
                                            </Typography>
                                        )}
                                    </Grid>
                                )}

                                {action.type === 'action' && (
                                    <Grid item xs={12} md={6} lg={4} xl={2}>
                                        <Card
                                            sx={{
                                                height: '100%',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'space-between',
                                            }}
                                        >
                                            <CardHeader
                                                avatar={action.icon}
                                                title={
                                                    <Link
                                                        to={action.url(subscriber._id)}
                                                        style={{ textDecoration: 'none', color: theme.palette.primary.main }}
                                                    >
                                                        {action.title}
                                                    </Link>
                                                }
                                                titleTypographyProps={{ variant: 'h6' }}
                                                subheaderTypographyProps={{ variant: 'body2' }}
                                                sx={{ pb: 0 }}
                                            />
                                            <CardContent sx={{ height: '100%' }}>
                                                <Typography variant="body1" color="text.secondary">
                                                    {action.description}
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                )}
                            </React.Fragment>
                        ))}
            </Grid>
        </>
    );
};

export default ServiceActionsBlocks;
