import { parse as parseCsv } from 'json2csv';
import * as XLSX from 'xlsx';
import { Theme } from '@mui/system';
import useTheme from '@mui/material/styles/useTheme';
import { Card, CardContent, Grid, Typography, Skeleton, Tooltip, IconButton } from '@mui/material';
import ReportAccountTotalByUserTopSpendersTable from './ReportAccountTotalByUserTopSpendersTable';
import { d2f } from '../../../../utils/common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileCsv, faFileExcel } from '@fortawesome/free-solid-svg-icons';

/**
 * Transforms the transactions data rows from the server to a format ready for export to CSV or Excel
 * @param rows Transactions rows
 * @param timezone Timezone string to convert the transaction time to
 * @returns Transformed data for export
 */
const transformTransactionsForExport = (rowsMain: any[] | null, rowsCompare: any[] | null) => {
    const headers = [
        {
            label: `Rank`,
            value: 'rank',
        },
        {
            label: 'Fan Name',
            value: 'name',
        },
        {
            label: 'Fan Username',
            value: 'userName',
        },
        {
            label: 'Total Spent ($USD)',
            value: 'total',
        },
    ];

    if (!rowsMain) {
        return [];
    }

    const data = rowsMain.map((rowMain: any, index: number) => {
        return {
            rank: index + 1,
            name: rowMain.user.name,
            userName: rowMain.user.username,
            total: Number.parseFloat((d2f(rowMain.totalCents) / 100).toFixed(2)),
        };
    });

    return { headers, rows: data };
};

type ReportAccountTotalsByUserProps = {
    topSpendersMain: any[] | null;
    compare: boolean;
    topSpendersCompare: any[] | null;
    isFetching: boolean;
};

const ReportAccountTotalsByUser = (props: ReportAccountTotalsByUserProps) => {
    const { topSpendersMain, compare, topSpendersCompare, isFetching } = props;
    const theme: Theme = useTheme();

    // Convert report to CSV format and start file download
    const handleDownloadReportCsv = () => {
        const transformedData: any = transformTransactionsForExport(topSpendersMain, topSpendersCompare);

        // Format JSON data to CSV
        const csv = parseCsv(transformedData.rows, {
            fields: transformedData.headers,
            transforms: [],
        });

        // Convert CSV to Blob
        const blob: Blob = new Blob([csv], { type: 'text/csv' });

        // Create a descriptive filename
        const filename: string = `top_spenders.csv`;

        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode && link.parentNode.removeChild(link);
    };

    // Convert to Excel format and start file download
    const handleDownloadReportExcel = () => {
        const transformedData: any = transformTransactionsForExport(topSpendersMain, topSpendersCompare);

        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet([]);

        XLSX.utils.sheet_add_aoa(worksheet, [transformedData.headers.map((header: any) => header.label)]);

        XLSX.utils.sheet_add_json(worksheet, transformedData.rows, {
            origin: 'A2',
            skipHeader: true,
            header: transformedData.headers.map((header: any) => header.value),
        });

        XLSX.utils.book_append_sheet(workbook, worksheet, 'Transactions');

        // Create a descriptive filename
        const filename: string = `top_spenders.xlsx`;

        XLSX.writeFile(workbook, filename);
    };

    return (
        <Card variant="elevation" sx={{ marginBottom: theme.spacing(4) }}>
            <CardContent>
                <Grid container spacing={2} alignItems="center" sx={{ marginBottom: theme.spacing(2) }}>
                    <Grid item xs>
                        <Typography variant="h6">Top Spenders</Typography>
                    </Grid>
                    <Grid item xs="auto">
                        <Tooltip title="Download as CSV">
                            <IconButton
                                onClick={() => {
                                    handleDownloadReportCsv();
                                }}
                            >
                                <FontAwesomeIcon icon={faFileCsv} size="2x" color={theme.palette.info.main} />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                    <Grid item xs="auto">
                        <Tooltip title="Download as Excel">
                            <IconButton
                                onClick={() => {
                                    handleDownloadReportExcel();
                                }}
                            >
                                <FontAwesomeIcon icon={faFileExcel} size="2x" color={theme.palette.info.main} />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body1" sx={{ marginTop: theme.spacing(1) }}>
                            This reports shows the top spenders for the searched week or date range. When comparing two weeks or date
                            ranges, the{' '}
                            <span
                                style={{
                                    backgroundColor: theme.palette.info.main,
                                    color: theme.palette.common.white,
                                    padding: '.25em 0',
                                }}
                            >
                                highlighted
                            </span>{' '}
                            names are the ones that appear in both periods. Use the buttons to the right of the user to go directly to the
                            user's profile on OnlyFans or to go directly to their chat page.
                        </Typography>
                    </Grid>
                </Grid>

                <Grid container flexGrow={1} sx={{ paddingBottom: theme.spacing(1) }}>
                    <Grid item {...(compare ? { xs: 12, md: 6 } : { xs: 12 })}>
                        {isFetching ? (
                            <Skeleton />
                        ) : (
                            <ReportAccountTotalByUserTopSpendersTable
                                topSpenders={topSpendersMain}
                                compareSpenders={compare ? topSpendersCompare : null}
                                theme={theme}
                            />
                        )}
                        {compare && (
                            <Typography variant="subtitle1" textAlign="center" color={theme.palette.text.secondary}>
                                Main Search
                            </Typography>
                        )}
                    </Grid>
                    {compare && (
                        <>
                            <Grid
                                item
                                xs={12}
                                md={6}
                                sx={{
                                    [theme.breakpoints.up('md')]: {
                                        borderLeftWidth: '1px',
                                        borderLeftStyle: 'solid',
                                        borderLeftColor: theme.palette.grey[300],
                                    },
                                }}
                            >
                                {isFetching ? (
                                    <Skeleton />
                                ) : (
                                    <ReportAccountTotalByUserTopSpendersTable
                                        topSpenders={topSpendersCompare}
                                        compareSpenders={topSpendersMain}
                                        theme={theme}
                                    />
                                )}
                                <Typography variant="subtitle1" textAlign="center" color={theme.palette.text.secondary}>
                                    Comparisson Search
                                </Typography>
                            </Grid>
                        </>
                    )}
                </Grid>
            </CardContent>
        </Card>
    );
};

export default ReportAccountTotalsByUser;
