import { Theme } from '@mui/system';
import { convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import moment from 'moment';
import { useState } from 'react';
import { EditorState } from 'react-draft-wysiwyg';
import IconOnlyFansAccount from '../../../icons/onlyfans/IconOnlyFansAccount';
import IconOnlyFansDone from '../../../icons/onlyfans/IconOnlyFansDone';
import IconOnlyFansPrice from '../../../icons/onlyfans/IconOnlyFansPrice';

const Creator = (props: { creator: { id: number; name: string } }) => {
    const { name } = props.creator;
    const [hover, setHover] = useState(false);

    return (
        <span
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            style={{ cursor: 'pointer', textDecoration: hover ? 'underline' : 'none' }}
        >
            <span
                style={{
                    boxSizing: 'border-box',
                    fontSize: '14px',
                    lineHeight: '20px',
                    textAlign: 'center',
                }}
            >
                {name}
            </span>
        </span>
    );
};

// Detect all occurances of a mentions of a username beginning with @ in a string and create a link to their profile
const wrapUsernamesWithLinks = (input: string): string => {
    // Regular Expression to find usernames, starting with @ and followed by alphanumeric characters or underscores
    let usernameRegex = /@\w+/g;

    return input.replace(usernameRegex, match => {
        let username = match.slice(1); // remove the @ at the beginning
        return `<a href="https://onlyfans.com/${username}" target="_blank" style="color:#00aff0; text-decoration: none">${match}</a>`;
    });
};

interface Props {
    editorState: EditorState;
    taggedCreators: { id: number; name: string }[];
    taggedReleaseForms: { id: number; name: string }[];
    lockMessage: boolean;
    lockMessagePrice: number;
    lockedText: boolean;
    theme: Theme;
    disabled?: boolean;
}

const MessageUsersFormEditiorPreview = (props: Props) => {
    const { editorState, taggedCreators, taggedReleaseForms, lockMessage, lockMessagePrice, lockedText, theme, disabled } = props;

    return (
        <div
            id="bubble-preview"
            style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginBottom: '2px',
                flexWrap: 'wrap',
                font: '16px/1.3334 Roboto,sans-serif',
                color: theme.palette.common.black,
                ...(disabled ? { opacity: 0.5 } : {}),
            }}
        >
            <div
                id="bubble-preview-content"
                style={{
                    paddingLeft: '28px',
                    display: 'flex',
                    flexDirection: 'row',
                    alignContent: 'flex-end',
                    alignItems: 'flex-end',
                    position: 'relative',
                }}
            >
                <div
                    id="bubble-preview-body"
                    style={{
                        maxWidth: '100%',
                        position: 'relative',
                        display: 'flex',
                        flexDirection: 'column',
                        overflow: 'hidden',
                    }}
                >
                    <div id="bubble-preview-titles" style={{}}>
                        <div
                            id="bubble-preview-titles-content"
                            style={{
                                backgroundColor: 'rgba(0,175,240,.12)',
                                padding: '10px 0 10px 12px',
                                display: 'flex',
                                width: '100%',
                                color: '#8a96a3',
                            }}
                        >
                            <button
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center',
                                    alignContent: 'center',
                                    alignItems: 'center',
                                    marginRight: '7px',
                                    border: 'none',
                                    fontSize: '14px',
                                    lineHeight: '20px',
                                    textAlign: 'center',
                                    textTransform: 'uppercase',
                                    backgroundColor: 'transparent',
                                    cursor: 'pointer',
                                }}
                            >
                                <IconOnlyFansAccount
                                    fontSize="small"
                                    sx={{
                                        width: '16px',
                                        height: '16px',
                                        color: '#00aff0',
                                        flex: '0 0 16px',
                                        transition: 'transform .15s ease',
                                        fill: 'currentColor',
                                        lineHeight: 1,
                                        ':hover': {
                                            color: '#0091ea',
                                            transition: 'transform .15s ease',
                                        },
                                    }}
                                />
                            </button>
                            <div
                                id="swiper-container"
                                style={{
                                    paddingRight: '16px',
                                    flex: 1,
                                    width: '100%',
                                    position: 'relative',
                                    overflow: 'hidden',
                                    listStyle: 'none',
                                    color: '#8a96a3',
                                }}
                            >
                                <div
                                    id="swiper-wrapper"
                                    style={{
                                        transform: 'translate3d(0px, 0px, 0px)',
                                        position: 'relative',
                                        width: '100%',
                                        height: '100%',
                                        zIndex: 1,
                                        display: 'flex',
                                        transitionProperty: 'transform',
                                        boxSizing: 'content-box',
                                        color: '#8a96a3',
                                    }}
                                >
                                    <div
                                        id="swiper-slide-active"
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignContent: 'center',
                                            alignItems: 'center',
                                            width: 'auto',
                                            flexShrink: 0,
                                            height: '100%',
                                            position: 'relative',
                                            transitionProperty: 'transform',
                                        }}
                                    >
                                        <span style={{ cursor: 'pointer' }}>
                                            <span
                                                style={{
                                                    boxSizing: 'border-box',
                                                    fontSize: '14px',
                                                    lineHeight: '20px',
                                                    textAlign: 'center',
                                                }}
                                            >
                                                Tags
                                            </span>
                                        </span>
                                    </div>
                                    <div
                                        id="swipter-slide-next"
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignContent: 'center',
                                            alignItems: 'center',
                                            width: 'auto',
                                            flexShrink: 0,
                                            gap: '8px',
                                            height: '100%',
                                            position: 'relative',
                                            transitionProperty: 'transform',
                                        }}
                                    >
                                        <span
                                            style={{
                                                marginLeft: '8px',
                                            }}
                                        >
                                            &middot;
                                        </span>
                                        {taggedCreators.map(creator => (
                                            <Creator creator={creator} key={creator.id} />
                                        ))}
                                        {taggedReleaseForms.map(releaseForm => (
                                            <Creator creator={releaseForm} key={releaseForm.id} />
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        id="bubble-preview-text-wrapper"
                        style={{
                            flexDirection: 'row-reverse',
                            display: 'flex',
                            position: 'relative',
                        }}
                    >
                        <div
                            style={{
                                backgroundColor: 'rgba(0,175,240,.12)',
                                marginLeft: 'auto',
                                padding: '10px 12px',
                                overflowWrap: 'break-word',
                                wordBreak: 'break-word',
                                hyphens: 'auto',
                                textAlign: 'start',
                                lineHeight: '20px',
                                boxSizing: 'border-box',
                            }}
                            dangerouslySetInnerHTML={{
                                __html: wrapUsernamesWithLinks(draftToHtml(convertToRaw(editorState.getCurrentContent()))),
                            }}
                        />
                    </div>
                </div>
            </div>
            <span
                style={{
                    justifyContent: 'flex-end',
                    lineHeight: '16px',
                    color: '#8a96a3',
                    whiteSpace: 'nowrap',
                    width: '100%',
                    margin: '4px 0 2px',
                    paddingLeft: '3px',
                    paddingRight: '3px',
                    display: 'flex',
                    alignContent: 'center',
                    alignItems: 'center',
                    font: '16px/1.3334 Roboto,sans-serif',
                }}
            >
                <span
                    style={{
                        fontSize: '11px',
                    }}
                >
                    {moment().format('h:mm a')}
                </span>
                {lockMessage && lockMessagePrice > 0 && (
                    <span
                        style={{
                            order: -1,
                            fontWeight: 'bold',
                            fontSize: '11px',
                            marginRight: '4px',
                        }}
                    >
                        ${lockMessagePrice} not paid yet,
                    </span>
                )}
                {lockedText && (
                    <span
                        style={{
                            order: -1,
                            fontWeight: 'bold',
                            fontSize: '11px',
                            marginRight: '4px',
                        }}
                    >
                        <IconOnlyFansPrice
                            fontSize="small"
                            sx={{ width: '14px', height: '14px', color: '#8a96a3', flex: '0 0 18px', margin: '-2px 0 -3px 3px' }}
                        />{' '}
                        Locked Text
                    </span>
                )}
                <IconOnlyFansDone
                    fontSize="small"
                    sx={{ width: '18px', height: '18px', color: '#8a96a3', flex: '0 0 18px', margin: '-2px 0 -2px 3px' }}
                />
            </span>
        </div>
    );
};

export default MessageUsersFormEditiorPreview;
