import { faFileCsv, faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AddIcon from '@mui/icons-material/Add';
import {
    Button,
    Card,
    CardContent,
    Grid,
    IconButton,
    Stack,
    Theme,
    ToggleButton,
    ToggleButtonGroup,
    Tooltip,
    Typography,
    useMediaQuery,
} from '@mui/material';
import { GridPaginationModel, GridSortModel } from '@mui/x-data-grid-pro';
import { parse as parseCsv } from 'json2csv';
import moment from 'moment';
import { useDialog } from 'muibox';
import { useContext, useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { Link, useParams } from 'react-router-dom';
import * as XLSX from 'xlsx';
import useAxios from '../../../../../../hooks/useAxios';
import useSextforceMetricsCampaignsOverview from '../../../../../../hooks/useSextforceMetricsCampaignsOverview';
import useSubscribers from '../../../../../../hooks/useSubscribers';
import { SettingsContext } from '../../../../../../store/SettingsContext';
import { UserContext } from '../../../../../../store/UserContext';
import { d2f, handleHttpError, metricTypeName } from '../../../../../../utils/common';
import SelectTimezone from '../../../../../forms/helpers/SelectTimezone';
import SextforceMetricsCrossReferenceEarningsSubscriberSelector from '../../SextforceMetricsCrossReferenceEarningsSubscriberSelector';
import SextforceMetricsGroups from '../../SextforceMetricsGroups';
import SextforceMetricsCampaignsCreateCampaignDialog from '../SextforceMetricsCampaignsCreateCampaignDialog';
import SextforceMetricsCampaignsClaimsTodayOverview from './SextforceMetricsCampaignsClaimsTodayOverview';
import SextforceMetricsCampaignsOverviewGrid from './SextforceMetricsCampaignsOverviewGrid';
import SextforceMetricsCampaignsOverviewSelector from './SextforceMetricsCampaignsOverviewSelector';

/**
 * Transforms the transactions data rows from the server to a format ready for export to CSV or Excel
 * @param rows Transactions rows
 * @param timezone Timezone string to convert the transaction time to
 * @returns Transformed data for export
 */
const transformTransactionsForExport = (rows: any[], amountType: 'gross' | 'net', timezone: string) => {
    const headers = [
        {
            label: 'ID',
            value: 'foreignId',
        },
        {
            label: 'Type',
            value: 'type',
        },
        {
            label: 'Active',
            value: 'active',
        },
        {
            label: `Created At (${timezone})`,
            value: 'createdAt',
        },
        {
            label: 'OnlyFans Name',
            value: 'name',
        },
        {
            label: 'Platform',
            value: 'platform',
        },
        {
            label: '@ Username',
            value: 'associatedUsername',
        },
        {
            label: 'Claims Today',
            value: 'claimedToday',
        },
        {
            label: 'Claims Total',
            value: 'claimsCount',
        },
        {
            label: 'Claims Goal',
            value: 'goalClaims',
        },
        {
            label: 'Cost Per Fan Net',
            value: 'cpf',
        },
        {
            label: `Earnings ${amountType === 'gross' ? 'Gross' : 'Net'} Total (USD)`,
            value: 'earningsTotal',
        },
        {
            label: `Earning ${amountType === 'gross' ? 'Gross' : 'Net'} Goal (USD)`,
            value: 'goalSpent',
        },
    ];

    const data = rows.map(row => {
        const earningsTotal =
            row.payload && row.payload.earningsTotal && typeof row.payload.earningsTotal === 'object'
                ? d2f(row.payload.earningsTotal) * (amountType === 'gross' ? 1 : 0.8)
                : 0;
        const claimsCount = (row.payload && row.payload.counters && row.payload.counters.countSubscribers) || 0;
        const cpf = row.payload && row.payload.cpf ? Math.round(row.payload.cpf * 100) / 100 : 0;
        const recentEarnings = typeof row.recentEarnings === 'object' ? d2f(row.recentEarnings) * (amountType === 'gross' ? 1 : 0.8) : 0;

        return {
            foreignId: row.foreignId,
            type: metricTypeName(row.type),
            active: row.payload.active || false,
            createdAt: (row.payload && moment(row.payload.createdAt).tz(timezone).format('L hh:mm a')) || '',
            name: (row.payload && row.payload.name) || '',
            platform: (row.settings && row.settings.platform) || '',
            associatedUsername: row.associatedUsername || '',
            claimedToday: row.subscribedToday || 0,
            claimsCount,
            goalClaims: (row.settings && row.settings.goalSubs) || 0,
            recentEarnings,
            cpf,
            earningsTotal,
            goalSpent: (row.settings && row.settings.goalSpent && d2f(row.settings.goalSpent) * (amountType === 'gross' ? 1 : 0.8)) || 0,
        };
    });

    return { headers, rows: data };
};

type Props = {
    subscriber: any | null;
    timezone: string;
    setTimezone: (timezone: string) => void;
    theme: Theme;
};

const SextforceMetricsCampaignsOverview = (props: Props) => {
    const { subscriber, timezone, setTimezone, theme } = props;

    const userContext = useContext(UserContext);
    const settingsContext = useContext(SettingsContext);
    const params = useParams();
    const dialog = useDialog();
    const queryClient = useQueryClient();
    const axios = useAxios();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'));

    const [isSavingSettings, setIsSavingSettings] = useState<boolean>(false);
    const [amountType, setAmountType] = useState<'gross' | 'net'>(
        localStorage.getItem('showEarningsAsGross') !== null && localStorage.getItem('showEarningsAsGross') === 'true' ? 'gross' : 'net',
    );

    // Campaigns Overview
    const [createCampaignDialogOpen, setCreateCampaignDialogOpen] = useState<boolean>(false);
    const [claimedTodayDate, setClaimedTodayDate] = useState<Date | null>(moment().startOf('day').add(1, 'hour').toDate());
    const [trialsSelectorType, setTrialsSelectorType] = useState<{ includeInactive: boolean }>({
        includeInactive: localStorage.getItem('sextforceMetricsCampaignsOverviewSelectorType') === 'true',
    });
    const [reportSort, setReportSort] = useState<GridSortModel>([
        {
            field: 'payload.createdAt',
            sort: 'desc',
        },
    ]);
    // const [filter, setFilter] = useState<GridFilterModel>({ items: [] });
    const [search, setSearch] = useState<string>('');
    const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
        pageSize: 20,
        page: 0,
    });

    const {
        data: campaigns,
        isLoading: campaignslsLoading,
        refetch: campaignsRefetch,
    } = useSextforceMetricsCampaignsOverview(
        subscriber,
        timezone,
        claimedTodayDate,
        search,
        paginationModel.page,
        paginationModel.pageSize,
        reportSort && reportSort.length > 0 ? `${reportSort[0].field}=${reportSort[0].sort}` : '',
        trialsSelectorType.includeInactive,
    );

    // Download
    const [campaingsFullForDownloadLoading, setCampaignsFullForDownloadLoading] = useState<boolean>(false);

    // Cross Reference Subscriber
    const [crossReferenceSubscriberId, setCrossReferenceSubscriberId] = useState<string>('none');

    // Subscribers
    const { data: subscribers, isLoading: subscribersLoading } = useSubscribers();

    // Claims Today Overview
    const [hoveredClaimsTodayOverviewMetricId, setHoveredClaimsTodayOverviewMetricId] = useState<string>('');

    useEffect(() => {
        if (subscribers && Array.isArray(subscribers) && crossReferenceSubscriberId === 'none' && subscribers.length === 2) {
            const subscriber = subscribers.find(subscriber => subscriber._id !== params.userId);

            if (subscriber) {
                setCrossReferenceSubscriberId(subscriber._id);
            }
        }
    }, [subscribers, crossReferenceSubscriberId, params.userId]);

    // Fetch full report for download
    const metricsCampaignsOverviewFullForDownload = async (includeInactive: boolean): Promise<any> => {
        if (userContext.jwtToken && settingsContext.apiKey && 'userId' in params && params.userId && subscriber) {
            const query: string = `${settingsContext.routes.metrics.base}${params.userId}/campaignsOverview?${new URLSearchParams({
                includeInactive: includeInactive ? 'true' : 'false',
                startDate: moment(claimedTodayDate).tz(timezone, true).startOf('day').format(),
                endDate: moment(claimedTodayDate).tz(timezone, true).endOf('day').format(),
                search,
                sort: reportSort && reportSort.length > 0 ? `${reportSort[0].field}=${reportSort[0].sort}` : '',
            })}`;

            const data = await axios
                .get(query)
                .then(response => response.data as any[])
                .catch(error => {
                    console.error(error);
                    handleHttpError(error, dialog);
                });

            return data;
        }

        return [];
    };

    // *** SETTINGS ***

    const saveSettings = (
        campaignId: string,
        platform: string,
        associatedUsername: string,
        goalSpent: number,
        goalSubs: number,
        callBack: () => void,
    ) => {
        const doUpdate = async () => {
            if (userContext.jwtToken && settingsContext.apiKey && 'userId' in params && params.userId && subscriber) {
                const query: string = `${settingsContext.routes.metrics.base}${params.userId}/promocampaigns/${campaignId}`;

                setIsSavingSettings(true);

                const body: any = {
                    ...(platform !== '' && { platform }),
                    ...(associatedUsername !== '' && { associatedUsername }),
                    ...(goalSpent !== 0 && { goalSpent }),
                    ...(goalSubs !== 0 && { goalSubs }),
                };

                // Update campaign settings
                const updatedMetric = await axios
                    .put(query, body)
                    .then(response => response.data)
                    .catch(error => {
                        console.error(error);
                        setIsSavingSettings(false);
                        handleHttpError(error, dialog);
                    });

                if (updatedMetric) {
                    campaignsRefetch();
                }

                setIsSavingSettings(false);
                callBack();
            }
        };

        doUpdate();
    };

    // Convert report to CSV format and start file download
    const handleDownloadReportCsv = (data: any[]) => {
        if (!data) {
            return;
        }

        const transformedData: any = transformTransactionsForExport(data, amountType, timezone);

        // Format JSON data to CSV
        const csv = parseCsv(transformedData.rows, {
            fields: transformedData.headers,
            transforms: [],
        });

        // Convert CSV to Blob
        const blob: Blob = new Blob([csv], { type: 'text/csv' });

        // Create a descriptive filename
        const filename: string = `campaigns_${moment().tz(timezone, true).format('YYYY-MM-DD')}.csv`;

        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode && link.parentNode.removeChild(link);
    };

    // Convert to Excel format and start file download
    const handleDownloadReportExcel = (data: any[]) => {
        if (!data) {
            return;
        }

        const transformedData: any = transformTransactionsForExport(data, amountType, timezone);

        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet([]);

        XLSX.utils.sheet_add_aoa(worksheet, [transformedData.headers.map((header: any) => header.label)]);

        XLSX.utils.sheet_add_json(worksheet, transformedData.rows, {
            origin: 'A2',
            skipHeader: true,
            header: transformedData.headers.map((header: any) => header.value),
        });

        XLSX.utils.book_append_sheet(workbook, worksheet, 'Transactions');

        // Create a descriptive filename
        const filename: string = `campaigns_${moment(claimedTodayDate).tz(timezone, true).format('YYYY-MM-DD')}.xlsx`;

        XLSX.writeFile(workbook, filename);
    };

    return (
        <>
            <Card sx={{ width: '100%', marginBottom: 1 }}>
                <CardContent>
                    <Grid container alignItems="center">
                        <Grid item xs={12}>
                            <Grid container spacing={1} alignItems="center">
                                <Grid item xs>
                                    <Typography variant="h6">Free Trial Links</Typography>
                                </Grid>
                                <Grid item xs="auto">
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        size="small"
                                        component={Link}
                                        to={`/subscribers/${
                                            'userId' in params && params.userId
                                        }/services/sextforce/metrics/trials/overview`}
                                    >
                                        Switch to Free Trial Links
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <ul style={{ paddingLeft: theme.spacing(2), marginBottom: 0, marginTop: 0 }}>
                                <li>
                                    Select the date for which to show that day's gains. The total amount earned won't change. It is showing
                                    the latest total for the entire trial.
                                </li>
                                <li>
                                    Select another account with an active Sextforce subscription to see how much you've earned from the fans
                                    who came through each campaign on another account from the time they came through that campaign until
                                    now.
                                </li>
                                <li>Click on the CSV and Excel icons to download the report.</li>
                                <li>Use the search field to filter through the results.</li>
                                <li>Click on the Edit icon in the results to change the settings for that trial.</li>
                                <li>Click on the Share icon in the results to send automatic updates to your colleagues.</li>
                            </ul>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>

            <SextforceMetricsGroups subscriber={subscriber} metricType="campaign" amountType={amountType} />

            <SextforceMetricsCampaignsClaimsTodayOverview
                subscriber={subscriber}
                timezone={timezone}
                hoveredMetricId={hoveredClaimsTodayOverviewMetricId}
                setHoveredMetricId={setHoveredClaimsTodayOverviewMetricId}
            />

            <Card sx={{ width: '100%', marginBottom: 1 }}>
                <CardContent sx={{ padding: 2 }}>
                    <Grid
                        container
                        flexGrow={1}
                        spacing={1}
                        alignItems="center"
                        justifyContent={isLargeScreen ? 'flex-start' : 'space-between'}
                    >
                        <Grid item xs={12}>
                            <Grid container spacing={2} alignItems="center">
                                <Grid item xs>
                                    <Typography variant="h6">Metrics</Typography>
                                </Grid>
                                <Grid item xs="auto">
                                    <Stack direction="row" spacing={1} alignItems="center">
                                        <Tooltip title="Download as CSV">
                                            <span>
                                                <IconButton
                                                    disabled={campaingsFullForDownloadLoading || !campaigns}
                                                    onClick={async () => {
                                                        setCampaignsFullForDownloadLoading(true);

                                                        try {
                                                            await queryClient
                                                                .fetchQuery(
                                                                    [
                                                                        'metricsCampaignsOverviewFullForDownload',
                                                                        timezone,
                                                                        claimedTodayDate,
                                                                        trialsSelectorType,
                                                                        subscriber,
                                                                        search,
                                                                    ],
                                                                    () =>
                                                                        metricsCampaignsOverviewFullForDownload(
                                                                            trialsSelectorType.includeInactive,
                                                                        ),
                                                                    {
                                                                        // Stale time 5 minutes
                                                                        staleTime: 60 * 1000 * 5,
                                                                    },
                                                                )
                                                                .then(data => {
                                                                    handleDownloadReportCsv(data);
                                                                });
                                                        } catch (error) {
                                                            console.error(error);
                                                        }

                                                        setCampaignsFullForDownloadLoading(false);
                                                    }}
                                                >
                                                    <FontAwesomeIcon
                                                        icon={faFileCsv}
                                                        size="2x"
                                                        color={
                                                            campaingsFullForDownloadLoading
                                                                ? theme.palette.text.disabled
                                                                : theme.palette.info.main
                                                        }
                                                    />
                                                </IconButton>
                                            </span>
                                        </Tooltip>
                                        <Tooltip title="Download as Excel">
                                            <span>
                                                <IconButton
                                                    disabled={campaingsFullForDownloadLoading || !campaigns}
                                                    onClick={async () => {
                                                        setCampaignsFullForDownloadLoading(true);

                                                        try {
                                                            await queryClient
                                                                .fetchQuery(
                                                                    [
                                                                        'metricsCampaignsOverviewFullForDownload',
                                                                        timezone,
                                                                        claimedTodayDate,
                                                                        trialsSelectorType,
                                                                        subscriber,
                                                                        search,
                                                                    ],
                                                                    {
                                                                        queryFn: () =>
                                                                            metricsCampaignsOverviewFullForDownload(
                                                                                trialsSelectorType.includeInactive,
                                                                            ),
                                                                        // Stale time 5 minutes
                                                                        staleTime: 60 * 1000 * 5,
                                                                    },
                                                                )
                                                                .then(data => {
                                                                    handleDownloadReportExcel(data);
                                                                });
                                                        } catch (error) {
                                                            console.error(error);
                                                        }

                                                        setCampaignsFullForDownloadLoading(false);
                                                    }}
                                                >
                                                    <FontAwesomeIcon
                                                        icon={faFileExcel}
                                                        size="2x"
                                                        color={
                                                            campaingsFullForDownloadLoading
                                                                ? theme.palette.text.disabled
                                                                : theme.palette.info.main
                                                        }
                                                    />
                                                </IconButton>
                                            </span>
                                        </Tooltip>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} sm="auto">
                            <SelectTimezone fullWidth size={'small'} timezone={timezone} setTimezone={setTimezone} />
                        </Grid>
                        <Grid item xs={12} sm="auto">
                            <SextforceMetricsCampaignsOverviewSelector
                                loading={campaignslsLoading}
                                selectorType={trialsSelectorType}
                                setSelectorType={setTrialsSelectorType}
                            />
                        </Grid>
                        <Grid item xs={12} sm="auto">
                            <ToggleButtonGroup
                                value={amountType}
                                exclusive
                                fullWidth
                                color="secondary"
                                size={'small'}
                                onChange={(_event, newValue) => {
                                    if (!newValue) {
                                        return;
                                    }

                                    setAmountType(newValue);
                                    localStorage.setItem('showEarningsAsGross', newValue === 'gross' ? 'true' : 'false');
                                }}
                                sx={{ marginTop: '4px', height: '39px' }}
                            >
                                <ToggleButton value="gross" fullWidth sx={{ minWidth: '80px' }}>
                                    GROSS
                                </ToggleButton>
                                <ToggleButton value="net" fullWidth sx={{ minWidth: '80px' }}>
                                    NET
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </Grid>
                        <Grid item xs={12} sm="auto">
                            <SextforceMetricsCrossReferenceEarningsSubscriberSelector
                                subscribers={subscribers}
                                subscribersLoading={subscribersLoading}
                                crossReferenceSubscriberId={crossReferenceSubscriberId}
                                setCrossReferenceSubscriberId={setCrossReferenceSubscriberId}
                            />
                        </Grid>
                        <Grid item xs={12} sm="auto">
                            <Button
                                onClick={() => {
                                    setCreateCampaignDialogOpen(true);
                                }}
                                variant="contained"
                                color="secondary"
                                size="medium"
                                fullWidth
                                startIcon={
                                    <AddIcon
                                        sx={{
                                            animation: 'spin-pause 10s linear infinite',
                                            animationTimingFunction: 'linear',
                                            '@keyframes spin-pause': {
                                                '0%': { transform: 'rotate(0deg)' },
                                                '4%': { transform: 'rotate(360deg)' },
                                                '100%': { transform: 'rotate(360deg)' },
                                            },
                                        }}
                                    />
                                }
                                sx={{ marginTop: '4px', height: '39px' }}
                            >
                                Create Tracking Link
                            </Button>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>

            <SextforceMetricsCampaignsOverviewGrid
                subscriber={subscriber}
                metrics={campaigns}
                amountType={amountType}
                isLoading={campaignslsLoading}
                saveSettings={saveSettings}
                isSavingSettings={isSavingSettings}
                reportSort={reportSort}
                setReportSort={setReportSort}
                // filter={filter}
                // setFilter={setFilter}
                search={search}
                setSearch={setSearch}
                paginationModel={paginationModel}
                setPaginationModel={setPaginationModel}
                crossReferenceSubscriberId={crossReferenceSubscriberId}
                startDate={moment(claimedTodayDate).startOf('day').toDate()}
                endDate={moment(claimedTodayDate).endOf('day').toDate()}
                claimedTodayDate={claimedTodayDate}
                setClaimedTodayDate={setClaimedTodayDate}
                timezone={timezone}
                setTimezone={setTimezone}
                hoverMetricId={hoveredClaimsTodayOverviewMetricId}
            />

            <SextforceMetricsCampaignsCreateCampaignDialog
                open={createCampaignDialogOpen}
                onClose={() => setCreateCampaignDialogOpen(false)}
                campaignsRefetch={campaignsRefetch}
            />
        </>
    );
};

export default SextforceMetricsCampaignsOverview;
