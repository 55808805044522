import { FormControl, InputLabel, OutlinedInput } from '@mui/material';
import { OnlyFansSubscriberHostessAutoReplyFreeloaders } from '../../../../types/hostessAutoReplyFreeloaders';

const FormFollowThresholdAmount = ({
    followingThresholdEnabled,
    followingThresholdAmountString,
    setFollowingThresholdAmountString,
    autoReplyFreeloaders,
    formData,
    setFormData,
}: {
    followingThresholdEnabled: boolean;
    followingThresholdAmountString: string;
    setFollowingThresholdAmountString: (followingThresholdAmountString: string) => void;
    autoReplyFreeloaders: OnlyFansSubscriberHostessAutoReplyFreeloaders | null;
    formData: OnlyFansSubscriberHostessAutoReplyFreeloaders;
    setFormData: (formData: OnlyFansSubscriberHostessAutoReplyFreeloaders) => void;
}) => {
    return (
        <FormControl>
            <InputLabel htmlFor="periodAmount">Length</InputLabel>
            <OutlinedInput
                id="periodAmount"
                size="medium"
                inputProps={{ inputMode: 'text', pattern: /^\d*$$/ }}
                disabled={!followingThresholdEnabled}
                value={followingThresholdAmountString}
                onChange={e => {
                    e.preventDefault();

                    if (/^\d*$/.test(e.target.value)) {
                        const parsed = parseFloat(e.target.value.replaceAll(',', '.'));

                        if (isNaN(parsed) || parsed === 0) {
                            setFormData({ ...formData, followingThresholdAmount: 0 });
                            setFollowingThresholdAmountString('0');
                        } else {
                            setFormData({ ...formData, followingThresholdAmount: parsed });
                            setFollowingThresholdAmountString(e.target.value.replace(/^0+/, ''));
                        }
                    }
                }}
                label="Length"
                error={autoReplyFreeloaders && autoReplyFreeloaders.followingThresholdAmount === 0 ? true : false}
                sx={{
                    width: '80px',
                }}
            />
        </FormControl>
    );
};

export default FormFollowThresholdAmount;
