import axios from 'axios';
import { useDialog } from 'muibox';
import { useContext } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { MetricOverviewRecentEarnings } from '../components/services/sextforce/metrics/statsHelpers';
import { SettingsContext } from '../store/SettingsContext';
import { UserContext } from '../store/UserContext';
import { handleHttpError } from '../utils/common';

const useSextforceMetricsRecentEarnings = (metricId: string, metricType: string) => {
    const userContext = useContext(UserContext);
    const settingsContext = useContext(SettingsContext);
    const dialog = useDialog();
    const params = useParams();

    const fetchData = async () => {
        const query: string = `${settingsContext.routes.metrics.base}${params.userId}/${
            metricType === 'promoCampaign' ? 'promocampaigns' : 'trials'
        }/${metricId}/recentEarnings`;

        const data = await axios
            .get(query, {
                headers: {
                    Authorization: userContext.jwtToken,
                    apiKey: settingsContext.apiKey,
                },
            })
            .then(response => response.data as MetricOverviewRecentEarnings)
            .catch(error => {
                console.error(error);
                handleHttpError(error, dialog);
            });

        return data;
    };

    return useQuery(['metricsTrialsRecentEarnings', params.userId, metricId, metricType], () => fetchData(), {
        refetchOnWindowFocus: false,
        // 5 minutes
        staleTime: 60 * 1000 * 5,
        enabled: userContext.jwtToken && settingsContext.apiKey && params.userId ? true : false,
    });
};

export default useSextforceMetricsRecentEarnings;
