import { useContext } from 'react';
import { useQuery } from 'react-query';
import { SettingsContext } from '../store/SettingsContext';
import { UserContext } from '../store/UserContext';
import useAxios from './useAxios';
import useSubscriber from './useSubscriber';

const useSubscriberPerformerTop = () => {
    const userContext = useContext(UserContext);
    const settingsContext = useContext(SettingsContext);
    const { data: subscriber } = useSubscriber();
    const axios = useAxios();

    // Fetch
    const fetchData = async (): Promise<{ performerTop: number; isLoggedIn: boolean }> => {
        const query: string = `${settingsContext.routes.onlyFans.base}${subscriber._id}/performerTop`;

        return axios
            .get(query)
            .then(response => response.data)
            .catch(() => {
                return { performerTop: 0, isLoggedIn: false };
            });
    };

    const subscriberPerformerTop = useQuery(['subscriberPerformerTop', subscriber?._id], () => fetchData(), {
        refetchOnWindowFocus: false,
        retry: false,
        enabled: userContext.jwtToken && settingsContext.apiKey && subscriber ? true : false,
    });

    return { ...subscriberPerformerTop };
};

export default useSubscriberPerformerTop;
