import {
    Checkbox,
    CircularProgress,
    FormControl,
    FormControlLabel,
    Grid,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import countryList from 'country-list';
import { UserContext } from '../../store/UserContext';
import { SettingsContext } from '../../store/SettingsContext';
import { handleHttpError, handleHttpErrorResponse } from '../../utils/common';
import { useDialog } from 'muibox';

const euCountries = [
    'AT',
    'BE',
    'BG',
    'HR',
    'CY',
    'CZ',
    'DK',
    'EE',
    'FI',
    'FR',
    'DE',
    'GR',
    'HU',
    'IE',
    'IT',
    'LV',
    'LT',
    'LU',
    'MT',
    'NL',
    'PL',
    'PT',
    'RO',
    'SK',
    'SI',
    'ES',
    'SE',
];

const countryVatRegex = {
    AT: /^ATU\d{8}$/i,
    BE: /^BE0?\d{9}$/i,
    BG: /^BG\d{9,10}$/i,
    HR: /^HR\d{11}$/i,
    CY: /^CY\d{8}[A-Z]$/i,
    CZ: /^CZ\d{8,10}$/i,
    DK: /^DK\d{8}$/i,
    EE: /^EE\d{9}$/i,
    FI: /^FI\d{8}$/i,
    FR: /^FR[A-Z0-9]{2}\d{9}$/i,
    DE: /^DE\d{9}$/i,
    GR: /^EL\d{9}$/i,
    HU: /^HU\d{8}$/i,
    IE: /^IE\d[A-Z\d*+]{5,6}[A-Z]$/i,
    IT: /^IT\d{11}$/i,
    LV: /^LV\d{11}$/i,
    LT: /^LT(\d{9}|\d{12})$/i,
    LU: /^LU\d{8}$/i,
    MT: /^MT\d{8}$/i,
    NL: /^NL\d{9}B\d{2}$/i,
    PL: /^PL\d{10}$/i,
    PT: /^PT\d{9}$/i,
    RO: /^RO\d{2,10}$/i,
    SK: /^SK\d{10}$/i,
    SI: /^SI\d{8}$/i,
    ES: /^ES[A-Z]\d{8}$/i,
    SE: /^SE\d{12}$/i,
};

type Props = {
    name: string;
    setName: (name: string) => void;
    address: string;
    setAddress: (address: string) => void;
    city: string;
    setCity: (city: string) => void;
    county: string;
    setCounty: (county: string) => void;
    postcode: string;
    setPostcode: (postcode: string) => void;
    country: string;
    setCountry: (country: string) => void;
    isCompany: boolean;
    setIsCompany: (isCompany: boolean) => void;
    companyName: string;
    setCompanyName: (companyName: string) => void;
    companyVatNumber: string;
    setCompanyVatNumber: (companyVatNumber: string) => void;
    isCompanyVatNumberValid: boolean;
    setIsCompanyVatNumberValid: (isCompanyVatNumberValid: boolean) => void;
};

const ProfileDialogBillingDetails = (props: Props) => {
    const {
        name,
        setName,
        address,
        setAddress,
        city,
        setCity,
        county,
        setCounty,
        postcode,
        setPostcode,
        country,
        setCountry,
        isCompany,
        setIsCompany,
        companyName,
        setCompanyName,
        companyVatNumber,
        setCompanyVatNumber,
        isCompanyVatNumberValid,
        setIsCompanyVatNumberValid,
    } = props;

    const userContext = useContext(UserContext);
    const settingsContext = useContext(SettingsContext);
    const dialog = useDialog();

    const [showVatField, setShowVatField] = useState(false);
    const [isValidVatNumberFormat, setIsValidVatNumberFormat] = useState(false);
    const [isLoadingVatNumber, setIsLoadingVatNumber] = useState(false);

    useEffect(() => {
        setShowVatField(isCompany && euCountries.includes(country));
    }, [isCompany, country]);

    useEffect(() => {
        const validateVatNumber = async () => {
            if (showVatField && companyVatNumber.trim().length > 0) {
            }
        };

        validateVatNumber();
    }, [showVatField, companyVatNumber, country]);

    // Check that the VAT number format is valid
    useEffect(() => {
        if (euCountries.includes(country) && companyVatNumber.trim().length > 0) {
            const regex = countryVatRegex[country as keyof typeof countryVatRegex];

            setIsValidVatNumberFormat(regex.test(`${country}${companyVatNumber}`));
        } else {
            setIsValidVatNumberFormat(true);
        }
    }, [companyVatNumber, country]);

    // If company VAT format is vald, check that the number itself is valid with the API
    useEffect(() => {
        const regex = countryVatRegex[country as keyof typeof countryVatRegex];

        if (isValidVatNumberFormat && companyVatNumber.trim().length > 0) {
            if (regex.test(`${country}${companyVatNumber}`)) {
                // Post VAT number to the API
                const validateVatNumber = async () => {
                    setIsLoadingVatNumber(true);
                    setIsCompanyVatNumberValid(false);

                    const query: string = `${settingsContext.routes.account.dashboard}${userContext.user.username}/vatNumberValidation`;

                    await fetch(query, {
                        method: 'post',
                        headers: {
                            Authorization: userContext.jwtToken,
                            apiKey: settingsContext.apiKey,
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            countryCode: country,
                            vatNumber: companyVatNumber,
                        }),
                    })
                        .then(async response => {
                            if (response.ok) {
                                return response.json();
                            } else {
                                handleHttpErrorResponse(response, dialog);
                                setIsLoadingVatNumber(false);
                            }
                        })
                        .then(data => {
                            setIsCompanyVatNumberValid(data.valid && isValidVatNumberFormat);
                            setIsLoadingVatNumber(false);
                        })
                        .catch(error => {
                            console.error(error);
                            handleHttpError(error, dialog).then(() => {
                                setIsLoadingVatNumber(false);
                            });
                        });

                    setIsLoadingVatNumber(false);
                };

                validateVatNumber();
            } else {
                setIsCompanyVatNumberValid(false);
            }
        }
        if (!isValidVatNumberFormat) {
            setIsCompanyVatNumberValid(false);
        } else {
            setIsCompanyVatNumberValid(true);
        }
    }, [
        companyVatNumber,
        country,
        dialog,
        isValidVatNumberFormat,
        setIsCompanyVatNumberValid,
        settingsContext.apiKey,
        settingsContext.routes.account.dashboard,
        userContext.jwtToken,
        userContext.user.username,
    ]);

    // sort string array
    const sortArray = (array: string[]) => {
        return array.sort((a, b) => {
            if (a < b) {
                return -1;
            }

            if (a > b) {
                return 1;
            }

            return 0;
        });
    };

    return (
        <Grid container spacing={1} alignItems="center">
            <Grid item xs={12}>
                <Typography variant="h6">Billing Details</Typography>
            </Grid>
            <Grid item xs={12}>
                <TextField autoFocus label="Name" type="text" fullWidth value={name} onChange={e => setName(e.target.value)} />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label="Address"
                    type="text"
                    fullWidth
                    multiline
                    rows={3}
                    value={address}
                    onChange={e => setAddress(e.target.value)}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField label="City" type="text" fullWidth value={city} onChange={e => setCity(e.target.value)} />
            </Grid>
            <Grid item xs={12}>
                <TextField label="County" type="text" fullWidth value={county} onChange={e => setCounty(e.target.value)} />
            </Grid>
            <Grid item xs={12}>
                <TextField label="Postcode" type="text" fullWidth value={postcode} onChange={e => setPostcode(e.target.value)} />
            </Grid>
            <Grid item xs={12}>
                <FormControl fullWidth margin="dense">
                    <InputLabel id="country-selector-label">Country</InputLabel>
                    <Select
                        labelId="country-selector-label"
                        value={country}
                        label="Country"
                        onChange={e => setCountry(e.target.value as string)}
                    >
                        {sortArray(countryList.getNames()).map(countryName => (
                            <MenuItem key={countryList.getCode(countryName)} value={countryList.getCode(countryName)}>
                                {countryName}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <FormControlLabel
                    control={<Checkbox checked={isCompany} onChange={e => setIsCompany(e.target.checked)} color="primary" />}
                    label="Is This a Company?"
                />
            </Grid>
            {isCompany && (
                <>
                    <Grid item xs={12}>
                        <TextField
                            label="Company Name"
                            type="text"
                            fullWidth
                            value={companyName}
                            onChange={e => setCompanyName(e.target.value)}
                        />
                    </Grid>
                    {showVatField && (
                        <Grid item xs={12}>
                            <TextField
                                label="Company VAT Number"
                                type="text"
                                fullWidth
                                value={companyVatNumber}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">{euCountries.includes(country) ? country : 'XX'}</InputAdornment>
                                    ),
                                    ...(companyVatNumber.trim().length > 0 && {
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                {isLoadingVatNumber ? (
                                                    <CircularProgress size={13} />
                                                ) : isValidVatNumberFormat && isCompanyVatNumberValid ? (
                                                    <DoneIcon color="success" />
                                                ) : (
                                                    <CloseIcon color="error" />
                                                )}
                                            </InputAdornment>
                                        ),
                                    }),
                                }}
                                error={!isValidVatNumberFormat || !isCompanyVatNumberValid}
                                helperText={
                                    !isValidVatNumberFormat
                                        ? 'Invalid VAT number format'
                                        : !isCompanyVatNumberValid
                                        ? 'Invalid VAT number'
                                        : ''
                                }
                                onKeyPress={(event: React.KeyboardEvent<HTMLInputElement>) => {
                                    const keyValue = event.key; // get the key value from the event
                                    const code = event.code; // get the code value from the event
                                    const regex = /^[0-9a-zA-Z]+$/; // regex to allow only digits and letters
                                    if (code !== 'Backspace' && code !== 'Delete' && !regex.test(keyValue)) {
                                        // prevent any other key except backspace and delete
                                        event.preventDefault();
                                    }
                                }}
                                onChange={e => {
                                    setCompanyVatNumber(e.target.value);
                                }}
                            />
                        </Grid>
                    )}
                </>
            )}
        </Grid>
    );
};

export default ProfileDialogBillingDetails;
