import { Card, CardContent, Grid, Skeleton, Theme, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Stack } from '@mui/system';
import moment from 'moment-timezone';
import { useMemo, useState } from 'react';
import { Line, LineChart, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import useMetricsOnlineUsersCount from '../../hooks/useMetricsOnlineUsersCount';
import ToggleGraphType from '../common/ToggleGraphType';
import OnlineCountHeatMapGraph, { groupByDay } from '../dashboard/OnlineCountHeatMapGraph';

const CustomizedAxisTick = (props: any) => {
    const { x, y, payload } = props;

    return (
        <g transform={`translate(${x},${y})`}>
            <text x={x} y={0} dy={16} textAnchor="end" fill="#666">
                {moment(payload.value).format('ddd Do')}
            </text>
            <text x={x} y={0} dy={32} textAnchor="end" fill="#666">
                {moment(payload.value).format('hh a')}
            </text>
        </g>
    );
};

const CustomTooltip = ({ active, payload, theme }: any) => {
    if (active && payload && payload.length) {
        return (
            <Grid
                container
                flexGrow={0}
                alignItems="center"
                spacing={1}
                sx={{
                    backgroundColor: '#fff',
                    borderRadius: 4,
                    borderWidth: '1px',
                    borderStyle: 'solid',
                    borderColor: theme.palette.primary.main,
                    maxWidth: 200,
                }}
            >
                <Grid item xs={12}>
                    <span style={{ fontWeight: 'bold' }}>{moment(payload[0].payload.time).format('ddd Do, hh:mm A')}</span>
                </Grid>
                {payload.map((item: any, index: number) => (
                    <Grid item xs={12} key={index}>
                        {item.dataKey === 'count' && (
                            <Grid container flexGrow={1} alignItems="center">
                                <Grid item xs={4} sx={{ color: theme.palette.primary.main }}>
                                    Count:
                                </Grid>
                                <Grid item xs={8} textAlign={'right'} sx={{ color: theme.palette.primary.main, paddingRight: '8px' }}>
                                    {item.value && typeof item.value === 'number' ? item.value.toLocaleString() : 0}
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                ))}
            </Grid>
        );
    }

    return null;
};

const Chart = (props: { data: { time: number; count: number }[]; color: string }) => {
    const { data, color } = props;
    const theme: Theme = useTheme();

    // Detect screen size using media query and set the interval accordingly
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    // Calculate the average count
    const average = useMemo(() => {
        if (!data) {
            return 0;
        }

        const sum = data.reduce((a, b) => a + b.count, 0);
        const avg = sum / data.length;

        return Math.round(avg);
    }, [data]);

    return (
        <ResponsiveContainer width="100%" height="100%">
            <LineChart
                data={data || []}
                margin={{
                    top: 0,
                    right: 0,
                    left: -10,
                    bottom: 13,
                }}
            >
                {/* <CartesianGrid strokeDasharray="3 3" /> */}
                <XAxis
                    dataKey="time"
                    type="number"
                    scale={'time'}
                    name="Time"
                    tick={<CustomizedAxisTick />}
                    domain={['auto', 'auto']}
                    interval={isSmallScreen ? 'preserveStart' : 12}
                    angle={0}
                    dy={70}
                />
                <YAxis
                    yAxisId="count"
                    dataKey={'count'}
                    scale={'linear'}
                    name="Count"
                    domain={[
                        (dataMin: number) => Math.floor(dataMin - dataMin * 0.1),
                        (dataMax: number) => Math.ceil(dataMax + dataMax * 0.1),
                    ]}
                    tickFormatter={(value: number) => value.toLocaleString()}
                />
                <Line
                    yAxisId="count"
                    type="monotone"
                    dataKey="count"
                    stroke={color}
                    activeDot={{ r: 3 }}
                    dot={{ r: 0 }}
                    isAnimationActive={false}
                />
                <ReferenceLine
                    yAxisId="count"
                    y={average}
                    label={{
                        value: `Averange - ${average.toLocaleString()}`,
                        fontSize: '0.75rem',
                        position: 'insideTopLeft',
                    }}
                    fontSizeAdjust={0.5}
                    stroke={theme.palette.warning.main}
                    strokeDasharray="3 3"
                />
                <Tooltip content={<CustomTooltip payload={data} theme={theme} />} />
            </LineChart>
        </ResponsiveContainer>
    );
};

const Last7DaysOnlineUsersCountGraph = () => {
    const theme: Theme = useTheme();
    const timezone: string = useMemo(() => moment.tz.guess(), []);
    const startDate: Date = useMemo(() => moment().subtract(7, 'days').startOf('day').toDate(), []);
    const endDate: Date = useMemo(() => moment().endOf('day').toDate(), []);
    const { data: onlineUsersCount, isFetching: onlineUsersCountLoading } = useMetricsOnlineUsersCount(startDate, endDate, timezone, false);
    const [graphType, setGraphType] = useState<'line' | 'heatmap'>('heatmap');

    const parsedData = useMemo(() => {
        return onlineUsersCount
            ? onlineUsersCount.map(d => {
                  return {
                      time: moment(d._id).valueOf(),
                      count: Math.round(d.count),
                  };
              })
            : [];
    }, [onlineUsersCount]);

    const onlineHeatMapData = useMemo(() => {
        if (!onlineUsersCount) {
            return [];
        }

        const groupedByDay = groupByDay(onlineUsersCount as { _id: string; count: number }[]);

        const refactoredData: { time: number; count: number }[] = [];

        for (let i = 0; i < groupedByDay.length; i++) {
            const day = groupedByDay[i];

            for (let j = 0; j < day.length; j++) {
                const hour = day[j];

                refactoredData.push({
                    time: moment(hour._id).valueOf(),
                    count: Math.round(hour.count),
                });
            }
        }

        // Remove members from refactoredData from the front and the end that have 0 count
        while (refactoredData.length > 0 && refactoredData[0].count === 0) {
            refactoredData.shift();
        }

        while (refactoredData.length > 0 && refactoredData[refactoredData.length - 1].count === 0) {
            refactoredData.pop();
        }

        return refactoredData;
    }, [onlineUsersCount]);

    return (
        <div style={{ marginTop: theme.spacing(4) }}>
            <Stack direction="row" spacing={1} alignItems="center" sx={{ marginBottom: theme.spacing(2) }}>
                <Typography variant="h5">Online Users</Typography>
                {/* <Typography variant="body2">Last 7 Days</Typography> */}
                <ToggleGraphType graphType={graphType} setGraphType={setGraphType} />
            </Stack>

            <Card variant="elevation" sx={{ marginBottom: theme.spacing(4) }}>
                <CardContent sx={{ height: 150 }}>
                    {onlineUsersCountLoading ? (
                        <Skeleton />
                    ) : (
                        <>
                            {graphType === 'line' ? (
                                <Chart data={parsedData} color={theme.palette.primary.main} />
                            ) : (
                                <OnlineCountHeatMapGraph data={onlineHeatMapData} />
                            )}
                        </>
                    )}
                </CardContent>
            </Card>
        </div>
    );
};

export default Last7DaysOnlineUsersCountGraph;
