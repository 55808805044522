import { Grid, Theme, useMediaQuery, useTheme } from '@mui/material';
import moment from 'moment';
import { Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';

const CustomTooltip = ({ active, payload, theme }: any) => {
    if (active && payload && payload.length) {
        return (
            <Grid
                container
                flexGrow={0}
                alignItems="center"
                spacing={1}
                sx={{
                    backgroundColor: '#fff',
                    borderRadius: 4,
                    borderWidth: '1px',
                    borderStyle: 'solid',
                    borderColor: theme.palette.primary.main,
                    maxWidth: 200,
                }}
            >
                <Grid item xs={12}>
                    <span style={{ fontWeight: 'bold' }}>{moment(payload[0].payload.time).format('ddd Do')}</span>
                </Grid>
                {payload.map((item: any, index: number) => (
                    <Grid item xs={12} key={index}>
                        {item.dataKey === 'count' && (
                            <Grid container flexGrow={1} alignItems="center">
                                <Grid item xs={12} textAlign={'right'} sx={{ color: theme.palette.primary.main, paddingRight: '8px' }}>
                                    {item.value && typeof item.value === 'number' ? item.value.toLocaleString() : 0}
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                ))}
            </Grid>
        );
    }

    return null;
};

const StatsChart = (props: { data: any; color: string }) => {
    const { data, color } = props;
    const theme: Theme = useTheme();

    // Detect screen size using media query and set the interval accordingly
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <ResponsiveContainer width="100%" height="100%">
            <AreaChart
                data={data || []}
                margin={{
                    top: 0,
                    right: 0,
                    left: -10,
                    bottom: 0,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                    dataKey="time"
                    type="number"
                    scale={'linear'}
                    name="Time"
                    tickFormatter={(value: number) => moment(value).format('ddd Do')}
                    // domain={data && data.length > 0 ? [data[0].time, data[data.length - 1].time] : ['auto', 'auto']}
                    domain={['dataMin', 'dataMax']}
                    interval={isSmallScreen ? 'preserveStart' : 1}
                    angle={0}
                    dx={-30}
                    hide={true}
                />
                <YAxis
                    yAxisId="count"
                    dataKey={'count'}
                    scale={'linear'}
                    name="Count"
                    // set the domain as +/- 10% of the min/max values
                    domain={[(dataMin: number) => dataMin - dataMin * 0.1, (dataMax: number) => dataMax + dataMax * 0.1]}
                    tickFormatter={(value: number) => value.toLocaleString()}
                    hide={true}
                />
                <Area
                    yAxisId="count"
                    type="monotone"
                    dataKey="count"
                    stroke={color}
                    fill={color}
                    strokeWidth={2}
                    activeDot={{ r: 3 }}
                    dot={{ r: 0 }}
                    isAnimationActive={false}
                />
                <Tooltip content={<CustomTooltip payload={data} theme={theme} />} />
            </AreaChart>
        </ResponsiveContainer>
    );
};

export default StatsChart;
