import { FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import { getNested } from '../utils/common';

type FormikCheckboxProps = {
    formik: any;
    fieldName: string;
    label: string;
};

const FormikCheckbox = (props: FormikCheckboxProps) => {
    const { formik, fieldName, label } = props;

    return (
        <FormGroup>
            <FormControlLabel
                id={fieldName}
                name={fieldName}
                control={
                    <Checkbox
                        checked={getNested(formik.values, fieldName, '.') || false}
                        onChange={(e, checked) => formik.setFieldValue(fieldName, checked)}
                    />
                }
                label={label}
            />
        </FormGroup>
    );
};

export default FormikCheckbox;
