import { CircularProgress, CssBaseline, Grid, ThemeOptions } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { LicenseInfo } from '@mui/x-license-pro';
import { ConfirmProvider } from 'material-ui-confirm';
import { DialogProvider } from 'muibox';
import { SnackbarProvider } from 'notistack';
import { useEffect, useState } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ScrollToTop from './components/common/ScrollToTop';
import useGlobalSettings from './hooks/useGlobalSettings';
import Dashboard from './pages/Dashboard';
import SextforceAgentSubmit from './pages/services/sextforce/SextforceAgentSubmit';
import SextforceMetricsPublic from './pages/services/sextforce/SextforceMetricsPublic';

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_X_PRO_LICENSE || '');

const queryClient = new QueryClient();

/* CSS HEX */
// https://coolors.co/palette/001219-005f73-0a9396-94d2bd-e9d8a6-ee9b00-ca6702-bb3e03-ae2012-9b2226
// --rich-black-fogra-29: #001219ff;
// --blue-sapphire: #005f73ff;
// --viridian-green: #0a9396ff;
// --middle-blue-green: #94d2bdff;
// --medium-champagne: #e9d8a6ff;
// --gamboge: #ee9b00ff;
// --alloy-orange: #ca6702ff;
// --rust: #bb3e03ff;
// --rufous: #ae2012ff;
// --ruby-red: #9b2226ff;

export const themeOptions: ThemeOptions = {
    palette: {
        // type: 'light',
        primary: {
            main: '#005f73',
        },
        secondary: {
            main: '#CA6702',
        },
        error: {
            main: '#AE2012',
        },
        info: {
            main: '#0A9396',
        },
        warning: {
            main: '#EE9B00',
        },
        success: {
            main: '#09953A',
        },
        background: {
            default: 'rgba(0,66,80,0.08)',
            paper: '#FFF',
        },
        action: {
            active: '#005f73',
            activatedOpacity: 1,
        },
    },
    typography: {
        fontFamily: '"Poppins", "Helvetica", "Arial", sans-serif',
        fontSize: 13,
    },
    shape: {
        borderRadius: 0,
    },
    components: {
        MuiList: {
            defaultProps: {
                dense: true,
            },
        },
        MuiMenuItem: {
            defaultProps: {
                dense: true,
            },
        },
        MuiTable: {
            defaultProps: {
                size: 'small',
            },
        },
        MuiButton: {
            defaultProps: { size: 'small' },
        },
        MuiButtonGroup: {
            defaultProps: { size: 'small' },
        },
        MuiCheckbox: {
            defaultProps: { size: 'small' },
        },
        MuiFab: {
            defaultProps: { size: 'small' },
        },
        MuiFormControl: {
            defaultProps: {
                margin: 'dense',
                size: 'small',
            },
        },
        MuiFormHelperText: {
            defaultProps: { margin: 'dense' },
        },
        MuiIconButton: {
            defaultProps: { size: 'small' },
        },
        MuiInputBase: {
            defaultProps: { margin: 'dense' },
        },
        MuiInputLabel: {
            defaultProps: { margin: 'dense' },
        },
        MuiRadio: {
            defaultProps: { size: 'small' },
        },
        MuiSwitch: {
            defaultProps: { size: 'small' },
        },
        MuiTextField: {
            defaultProps: {
                margin: 'dense',
                size: 'small',
            },
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    borderRadius: '4px',
                    // no shadow
                    boxShadow: 'none',
                    // light border
                    border: '1px solid rgba(0, 0, 0, 0.12)',
                },
            },
        },
        MuiCardContent: {
            styleOverrides: {
                root: {
                    ':last-child': { paddingBottom: '16px' },
                },
            },
        },
        MuiTypography: {
            styleOverrides: {
                gutterBottom: {
                    marginBottom: '16px',
                },
            },
        },
        MuiAlertTitle: {
            styleOverrides: {
                root: {
                    marginBottom: 0,
                },
            },
        },
    },
};

const theme = createTheme(themeOptions);

const secondsToTime = (seconds: number) => {
    const me = (seconds: number) => {
        const date = new Date(seconds * 1000);
        const mm = date.getUTCMinutes();
        const ss = date.getSeconds();

        return `${mm.toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })}:${ss.toLocaleString('en-US', {
            minimumIntegerDigits: 2,
            useGrouping: false,
        })}`;
    };

    return me(seconds);
};

const Loader = () => {
    const { data: globalSettings, isLoading: globalSettingsLoading } = useGlobalSettings();
    const [maintenenceMode, setMaintenenceMode] = useState<boolean>(false);
    const [countdownTimer, setCountdownTimer] = useState<number>(0);

    useEffect(() => {
        if (globalSettings) {
            setMaintenenceMode(globalSettings.find((x: any) => x.dashboardMaintenance && x.dashboardMaintenance === true));
        }
    }, [globalSettings]);

    // Refresh the page every 5 minutes if in maintenance mode and update the countdown timer
    useEffect(() => {
        if (maintenenceMode) {
            const interval = setInterval(() => {
                setCountdownTimer(countdownTimer + 1);
            }, 1000);

            return () => clearInterval(interval);
        }
    }, [countdownTimer, maintenenceMode]);

    useEffect(() => {
        if (maintenenceMode && countdownTimer > 300) {
            window.location.reload();
        }
    }, [countdownTimer, maintenenceMode]);

    return (
        <>
            {globalSettingsLoading ? (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100vh',
                    }}
                >
                    <CircularProgress />
                </div>
            ) : (
                <>
                    {maintenenceMode ? (
                        <Grid container justifyContent={'center'} alignItems="center" sx={{ height: '100vh' }}>
                            <Grid item xs={12} textAlign="center">
                                <h1>OnlyStrugges Dashboard</h1>
                                {globalSettings.find((x: any) => x.dashboardMaintenanceMessage) && (
                                    <p>{globalSettings.find((x: any) => x.dashboardMaintenanceMessage).dashboardMaintenanceMessage}</p>
                                )}
                                <p>Refreshing in {secondsToTime(300 - countdownTimer >= 0 ? 300 - countdownTimer : 0)}</p>
                            </Grid>
                        </Grid>
                    ) : (
                        <BrowserRouter>
                            <ScrollToTop />
                            <Routes>
                                <Route path="/sextforce/agent/:agentId" element={<SextforceAgentSubmit />} />
                                <Route path="/sextforce/metrics/:metricShareLinkId" element={<SextforceMetricsPublic />} />
                                <Route path="*" element={<Dashboard />} />
                            </Routes>
                        </BrowserRouter>
                    )}
                </>
            )}
        </>
    );
};

function App() {
    return (
        <QueryClientProvider client={queryClient}>
            <ThemeProvider theme={theme}>
                <ConfirmProvider>
                    <DialogProvider>
                        <SnackbarProvider autoHideDuration={5000} anchorOrigin={{ horizontal: 'center', vertical: 'top' }}>
                            <CssBaseline />
                            <Loader />
                        </SnackbarProvider>
                    </DialogProvider>
                </ConfirmProvider>
            </ThemeProvider>
        </QueryClientProvider>
    );
}

export default App;
