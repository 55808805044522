import moment from 'moment';
import { Box, Card, CardContent, Grid, Skeleton, Typography } from '@mui/material';
import { Theme } from '@mui/system';
import { LabelList, Line, LineChart, ResponsiveContainer, XAxis } from 'recharts';
import { useEffect, useState } from 'react';

const CustomizedDot = (props: any) => {
    const { cx, cy, payload, theme, active } = props;
    let color: string = 'black';

    switch (payload.targetUsers) {
        case 'followingExpired':
            color = theme.palette.primary.main;
            break;

        case 'fansExpired':
            color = payload.priority ? theme.palette.secondary.main : theme.palette.warning.main;
            break;

        case 'fansActive':
            color = theme.palette.error.main;
            break;
    }

    return <circle cx={cx} cy={cy} r={4} stroke={color} strokeWidth={2} fill={active ? color : 'white'} />;
};

type FollowBackGraphCardProps = {
    title: string;
    data: any[];
    loading: boolean;
    theme: Theme;
};

const FollowBackGraphCard = (props: FollowBackGraphCardProps) => {
    const { title, data, loading, theme } = props;
    const [ticks, setTicks] = useState<number[]>([]);
    const timezone: string = moment.tz.guess();

    useEffect(() => {
        if (!data || data.length === 0) {
            return;
        }

        const firstDay = moment(data[0].time).startOf('day');
        const lastDay = moment(data[data.length - 1].time);

        const totalDays: number = lastDay.diff(firstDay, 'days');

        const result: number[] = [firstDay.valueOf()];

        for (let i = 0; i <= totalDays; i += 1) {
            firstDay.add(1, 'days');

            result.push(firstDay.valueOf());
        }

        setTicks(result);
    }, [data]);

    return (
        <Card
            variant="elevation"
            sx={{
                marginBottom: theme.spacing(0),
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
            }}
        >
            <CardContent>
                <Grid container flexGrow={1} alignItems="center" spacing={2}>
                    <Grid item xs>
                        <Typography variant="subtitle1" align="right">
                            {title}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container flexGrow={1} alignItems="center" spacing={2}>
                    <Grid item xs>
                        {loading ? (
                            <Skeleton />
                        ) : (
                            <Box display={'flex'} height={'100px'}>
                                {data && data.length === 0 ? (
                                    <Box m={'auto'}>
                                        <span style={{ color: theme.palette.grey[600] }}>None</span>
                                    </Box>
                                ) : (
                                    <ResponsiveContainer width="100%" height="100%">
                                        <LineChart
                                            data={data || []}
                                            margin={{
                                                top: 30,
                                                right: 20,
                                                left: 20,
                                                bottom: 0,
                                            }}
                                        >
                                            <XAxis
                                                dataKey="time"
                                                type="number"
                                                scale={'time'}
                                                name="Time"
                                                ticks={ticks}
                                                tickFormatter={value => moment(value).tz(timezone).format('L').substring(0, 5)}
                                                domain={['auto', 'auto']}
                                                interval={'preserveStartEnd'}
                                                fontSize={12}
                                            />
                                            <Line
                                                yAxisId="followed"
                                                type="linear"
                                                dataKey="followed"
                                                connectNulls
                                                stroke={theme.palette.primary.main}
                                                dot={<CustomizedDot theme={theme} active={false} />}
                                                activeDot={<CustomizedDot theme={theme} active={true} />}
                                            >
                                                <LabelList
                                                    dataKey="followed"
                                                    position="top"
                                                    offset={10}
                                                    formatter={(value: number) => value.toLocaleString()}
                                                    fontSize={12}
                                                />
                                            </Line>
                                        </LineChart>
                                    </ResponsiveContainer>
                                )}
                            </Box>
                        )}
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default FollowBackGraphCard;
