import MapIcon from '@mui/icons-material/Map';
import TimelineIcon from '@mui/icons-material/Timeline';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';

const ToggleGraphType = (props: { graphType: 'line' | 'heatmap'; setGraphType: (graphType: 'line' | 'heatmap') => void }) => {
    return (
        <ToggleButtonGroup
            value={props.graphType}
            size="small"
            exclusive
            onChange={(event, newType) => {
                if (newType) {
                    props.setGraphType(newType);
                }
            }}
        >
            <ToggleButton value="heatmap">
                <MapIcon />
            </ToggleButton>
            <ToggleButton value="line">
                <TimelineIcon />
            </ToggleButton>
        </ToggleButtonGroup>
    );
};

export default ToggleGraphType;
