import { SvgIcon } from '@mui/material';

type Props = {
    fontSize?: 'small' | 'inherit' | 'large' | 'medium' | undefined;
};

const IconReddit = (props: Props) => {
    const { fontSize } = props;

    return (
        <SvgIcon width="1000" height="1000" x="0" y="0" version="1.1" viewBox="-194 -104 1000 1000" xmlSpace="preserve" fontSize={fontSize}>
            <g>
                <circle cx="306" cy="396" r="500" fill="#FF4500"></circle>
                <path
                    fill="#FFF"
                    d="M639.332 396c0-40.351-32.746-73.099-73.101-73.099-19.88 0-37.425 7.603-50.292 20.467-49.705-35.673-118.714-59.065-194.735-61.988l33.333-156.141 108.188 22.809c1.168 27.484 23.976 49.707 52.047 49.707 28.654 0 52.046-23.392 52.046-52.047 0-28.654-23.392-52.046-52.046-52.046-20.471 0-38.013 11.696-46.2 29.24L347.521 97.169c-3.509-.585-7.017 0-9.941 1.754s-4.68 4.679-5.85 8.188L294.889 281.38c-77.778 2.339-147.368 25.146-197.66 61.988C84.362 331.086 66.232 322.9 46.935 322.9c-40.351 0-73.099 32.749-73.099 73.099 0 29.825 17.544 54.971 43.275 66.667-1.17 7.017-1.754 14.62-1.754 22.221 0 112.28 130.408 202.924 291.813 202.924 161.402 0 291.812-90.644 291.812-202.924 0-7.601-.583-14.62-1.754-21.638 23.975-11.694 42.104-37.425 42.104-67.249zm-499.999 52.046c0-28.654 23.392-52.046 52.046-52.046 28.655 0 52.046 23.392 52.046 52.046s-23.392 52.047-52.046 52.047-52.046-23.393-52.046-52.047zm290.643 137.427c-35.671 35.675-103.509 38.013-123.393 38.013-19.882 0-88.303-2.925-123.391-38.013-5.263-5.263-5.263-14.034 0-19.297 5.265-5.263 14.036-5.263 19.298 0 22.223 22.222 70.176 30.409 104.68 30.409 34.501 0 81.872-8.188 104.677-30.409 5.263-5.263 14.037-5.263 19.3 0 4.091 5.846 4.091 14.034-1.171 19.297zm-9.358-85.38c-28.655 0-52.047-23.393-52.047-52.047S391.963 396 420.618 396c28.657 0 52.05 23.392 52.05 52.046s-23.393 52.047-52.05 52.047z"
                ></path>
            </g>
        </SvgIcon>
    );
};

export default IconReddit;
