import { Button, CircularProgress, DialogActions, Stack, Theme, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { d2f, metricTypeName } from '../../../../../../utils/common';
import DialogAdaptive from '../../../../../common/DialogAdaptive';
import DialogContentAdaptive from '../../../../../common/DialogContentAdaptive';
import StyledCardTitleBar from '../../../../../common/StyledCardTitleBar';
import { SextforceMetricsBoughtFromSettingsContent } from '../../SextforceMetricsBoughtFromSettings';

interface SextforceMetricsTrialsOverviewGridEditProps {
    metricData: any;
    open: boolean;
    onClose: () => void;
    saveSettings: (
        trialId: string,
        platform: string,
        associatedUsername: string,
        goalSpent: number,
        goalSubs: number,
        callBack: () => void,
    ) => void;
    isSavingSettings: boolean;
}

const SextforceMetricsTrialsOverviewGridEdit = (props: SextforceMetricsTrialsOverviewGridEditProps) => {
    const { metricData, open, onClose, saveSettings, isSavingSettings } = props;
    const theme: Theme = useTheme();
    const [platform, setPlatform] = useState<string>('');
    const [associatedUsername, setAssociatedUsername] = useState<string>('');
    const [amountType, setAmountType] = useState<'gross' | 'net'>(
        localStorage.getItem('showEarningsAsGross') !== null && localStorage.getItem('showEarningsAsGross') === 'true' ? 'gross' : 'net',
    );
    const [goalSpent, setGoalSpent] = useState<number>(0);
    const [goalSpentString, setGoalSpentString] = useState<string>('');
    const [goalSubs, setGoalSubs] = useState<number>(0);
    const [goalSubsString, setGoalSubsString] = useState<string>('');
    const [hasChanged, setHasChanged] = useState<boolean>(false);

    useEffect(() => {
        if (metricData) {
            if (metricData.associatedUsername) {
                setAssociatedUsername(metricData.associatedUsername);
            } else {
                setAssociatedUsername('');
            }

            if (metricData.settings) {
                setPlatform(metricData.settings.platform || '');
                setGoalSpent(metricData.settings.goalSpent ? d2f(metricData.settings.goalSpent) : 0);
                setGoalSpentString(metricData.settings.goalSpent ? d2f(metricData.settings.goalSpent).toString() : '');
                setGoalSubs(metricData.settings.goalSubs || 0);
                setGoalSubsString(
                    metricData.settings.goalSubs && metricData.settings.goalSubs !== 0 ? metricData.settings.goalSubs.toString() : '',
                );
            } else {
                setPlatform('');
                setGoalSpent(0);
                setGoalSpentString('');
                setGoalSubs(0);
                setGoalSubsString('');
            }

            setHasChanged(false);
        } else {
            setPlatform('');
            setGoalSpent(0);
            setGoalSpentString('');
            setGoalSubs(0);
            setGoalSubsString('');
        }
    }, [metricData]);

    const handleSaveSettings = () => {
        saveSettings(
            metricData._id,
            platform.trim(),
            associatedUsername.trim(),
            amountType === 'gross' ? goalSpent : goalSpent / 0.8,
            goalSubs,
            onClose,
        );
    };

    const handleResetSettings = () => {
        setAssociatedUsername('');
        setPlatform('');
        setGoalSpent(0);
        setGoalSpentString('');
        setGoalSubs(0);
        setGoalSubsString('');
        setHasChanged(true);
    };

    return (
        <DialogAdaptive open={open} onClose={onClose} fullWidth maxWidth="xs">
            <StyledCardTitleBar
                title={`Edit ${metricData && metricTypeName(metricData.type)} Settings - ${
                    (metricData && metricData.payload && metricData.payload.name) || 'No Name'
                }`}
                theme={theme}
            />
            <DialogContentAdaptive sx={{ margin: 1 }}>
                <SextforceMetricsBoughtFromSettingsContent
                    metricData={metricData}
                    platform={platform}
                    associatedUsername={associatedUsername}
                    setHasChanged={setHasChanged}
                    amountType={amountType}
                    setAmountType={setAmountType}
                    goalSpentString={goalSpentString}
                    goalSubsString={goalSubsString}
                    setAssociatedUsername={setAssociatedUsername}
                    setGoalSpent={setGoalSpent}
                    setGoalSpentString={setGoalSpentString}
                    setGoalSubs={setGoalSubs}
                    setGoalSubsString={setGoalSubsString}
                    setPlatform={setPlatform}
                />
            </DialogContentAdaptive>
            <DialogActions
                sx={{
                    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
                }}
            >
                <Stack direction="row" spacing={1} flexGrow={1} alignItems="center" justifyContent="space-between">
                    <Button
                        variant="contained"
                        color="secondary"
                        size="medium"
                        disabled={isSavingSettings || !metricData}
                        onClick={() => {
                            handleResetSettings();
                        }}
                    >
                        Clear
                    </Button>
                    <div>
                        <Stack direction="row" spacing={2} flexGrow={1} alignItems="center">
                            <Button size="medium" onClick={onClose}>
                                Cancel
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                size="medium"
                                disabled={isSavingSettings || !metricData || !hasChanged}
                                onClick={() => {
                                    handleSaveSettings();
                                }}
                            >
                                Save
                                {isSavingSettings && <CircularProgress size={20} />}
                            </Button>
                        </Stack>
                    </div>
                </Stack>
            </DialogActions>
        </DialogAdaptive>
    );
};

export default SextforceMetricsTrialsOverviewGridEdit;
