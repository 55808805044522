import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import LinkIcon from '@mui/icons-material/Link';
import { Card, Stack, Theme, Tooltip, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { copyTextToClipboard } from '../../../../../../utils/common';

type Props = {
    trialUrl: string | null;
    theme: Theme;
};

const SextforceMetricsTrialLink = (props: Props) => {
    const { trialUrl, theme } = props;
    const [copied, setCopied] = useState<boolean>(false);

    useEffect(() => {
        setCopied(false);
    }, [trialUrl]);

    return trialUrl ? (
        <Tooltip title="Copy Link to Clipboard">
            <Card
                variant="elevation"
                sx={{
                    marginBottom: theme.spacing(0),
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    padding: theme.spacing(1),
                    background: copied ? theme.palette.success.light : theme.palette.background.paper,
                    ':hover': { backgroundColor: copied ? theme.palette.success.light : theme.palette.info.light },
                    cursor: 'pointer',
                }}
                onClick={async () => {
                    await copyTextToClipboard(trialUrl);
                    setCopied(true);

                    setTimeout(() => {
                        setCopied(false);
                    }, 2000);
                }}
            >
                <Stack direction="row" spacing={1} alignItems={'center'}>
                    <LinkIcon fontSize="small" />
                    <Typography
                        sx={{
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            // Take up the remaining space
                            flexGrow: 1,
                        }}
                    >
                        {trialUrl}
                    </Typography>
                    <ContentCopyIcon fontSize="small" />
                </Stack>
            </Card>
        </Tooltip>
    ) : (
        <></>
    );
};

export default SextforceMetricsTrialLink;
