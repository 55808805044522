import axios from 'axios';
import moment from 'moment';
import { useDialog } from 'muibox';
import { useContext } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { SettingsContext } from '../store/SettingsContext';
import { UserContext } from '../store/UserContext';
import { handleHttpError } from '../utils/common';

const useMetricsOnlineUsersCount = (startDate: Date, endDate: Date, timezone: string, includeSales: boolean) => {
    const userContext = useContext(UserContext);
    const settingsContext = useContext(SettingsContext);
    const dialog = useDialog();
    const params = useParams();

    // Fetch online users count vs. total sales report
    const fetchOnlineUsersCountVsTotalSales = async (
        startDate: Date,
        endDate: Date,
        timezone: string,
        includeSales: boolean,
    ): Promise<{ _id: string; count: number }[] | void> => {
        if (userContext.jwtToken && settingsContext.apiKey && 'userId' in params && params.userId) {
            const query: string = `${settingsContext.routes.metrics.base}${params.userId}/onlineuserscount?${new URLSearchParams({
                startDate: moment(startDate).tz(timezone, true).toISOString(),
                endDate: moment(endDate).tz(timezone, true).toISOString(),
                includeSales: includeSales.toString(),
            })}`;

            const data = await axios
                .get(query, {
                    headers: {
                        Authorization: userContext.jwtToken,
                        apiKey: settingsContext.apiKey,
                    },
                })
                .then(async response => response.data as { _id: string; count: number }[])
                .catch(error => {
                    handleHttpError(error, dialog);
                });

            return data;
        }

        return [];
    };

    return useQuery(
        ['metricsOnlineUsersCount', params.userId, userContext.jwtToken, startDate, endDate, timezone, includeSales],
        () => {
            return fetchOnlineUsersCountVsTotalSales(startDate, endDate, timezone, includeSales);
        },
        {
            refetchOnWindowFocus: false,
            // Stale time 1 hour
            staleTime: 1000 * 60 * 60,
            enabled: userContext.jwtToken && params.userId ? true : false,
        },
    );
};

export default useMetricsOnlineUsersCount;
