import Grid3x3Icon from '@mui/icons-material/Grid3x3';
import { Box, Grid, Tooltip } from '@mui/material';
import { Theme } from '@mui/system';
import {
    DataGrid,
    GridColumns,
    GridSortModel,
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridValueGetterParams,
} from '@mui/x-data-grid';
import dinero from 'dinero.js';
import moment from 'moment';
import { useMemo } from 'react';
import { d2f, titleCase } from '../../../../utils/common';

const moneyCell = (params: any) => (
    <div style={{ fontFamily: 'monospace' }}>
        {dinero({ amount: Math.round(d2f(params.value) * 100), currency: params.row.currency }).toFormat()}
    </div>
);

const CustomToolbar = () => {
    return (
        <GridToolbarContainer>
            {/* @ts-ignore */}
            <GridToolbarColumnsButton />
        </GridToolbarContainer>
    );
};

type Props = {
    rows: any[];
    loading: boolean;
    rowsCount: number;
    limit: number;
    setLimit: (limit: number) => void;
    offset: number;
    setOffset: (offset: number) => void;
    reportSort: any[];
    setReportSort: (reportSort: any[]) => void;
    timezone: string;
    theme: Theme;
};

const SextforceTransactionsResultsGrid = (props: Props) => {
    const { rows, loading, rowsCount, limit, setLimit, offset, setOffset, reportSort, setReportSort, timezone, theme } = props;

    /**
     * DataGrid Columns
     */

    // Define agents DataGrid columns
    const columns = useMemo<GridColumns>(
        () => [
            {
                field: 'createdAt',
                headerName: 'Date & Time',
                width: 210,
                renderCell: params => (
                    <div
                        style={{
                            whiteSpace: 'nowrap',
                        }}
                    >
                        <Grid container alignItems="center">
                            <Grid item xs>
                                <Tooltip title={params.row.transactionId}>
                                    <Grid3x3Icon fontSize="small" />
                                </Tooltip>
                            </Grid>
                            <Grid
                                item
                                xs
                                sx={{
                                    fontFamily: 'monospace',
                                    whiteSpace: 'nowrap',
                                }}
                            >
                                {moment(params.row.createdAt).tz(timezone).format('L hh:mm a')}
                            </Grid>
                        </Grid>
                    </div>
                ),
            },
            {
                field: 'fraction',
                headerName: 'Fraction',
                width: 80,
                align: 'right',
                headerAlign: 'right',
                valueGetter: (params: GridValueGetterParams) =>
                    (d2f(params.row.amount) % 1).toLocaleString(undefined, { minimumFractionDigits: 2 }),
                renderCell: (params: any) => (
                    <div style={{ fontFamily: 'monospace' }}>
                        {(d2f(params.row.amount) % 1).toLocaleString(undefined, { minimumFractionDigits: 2 })}
                    </div>
                ),
            },
            {
                field: 'user.name',
                headerName: 'Fan Name',
                width: 200,
                valueGetter: (params: GridValueGetterParams) => params.row.user && params.row.user.name,
            },
            {
                field: 'user.username',
                headerName: 'Fan Username',
                width: 180,
                valueGetter: (params: GridValueGetterParams) => params.row.user && params.row.user.username,
            },
            {
                field: 'product',
                headerName: 'Product',
                width: 120,
                valueGetter: (params: GridValueGetterParams) => (params.row.product ? titleCase(params.row.product as string) : 'unknown'),
            },
            {
                field: 'amount',
                headerName: 'Gross',
                width: 80,
                align: 'right',
                headerAlign: 'right',
                renderCell: params => moneyCell(params),
            },
            {
                field: 'fee',
                headerName: 'Fee',
                width: 80,
                align: 'right',
                headerAlign: 'right',
                renderCell: params => moneyCell(params),
            },
            {
                field: 'net',
                headerName: 'Net',
                width: 80,
                align: 'right',
                headerAlign: 'right',
                renderCell: params => moneyCell(params),
            },
            {
                field: 'vat',
                headerName: 'VAT',
                width: 80,
                align: 'right',
                headerAlign: 'right',
                renderCell: params => moneyCell(params),
            },
            {
                field: 'commissionPercentOverride',
                headerName: 'Commission %',
                width: 120,
                align: 'right',
                headerAlign: 'right',
                renderCell: params => (
                    <div style={{ fontFamily: 'monospace' }}>
                        {params.row.commissionPercentOverride ? (
                            <span style={{ color: theme.palette.secondary.main }}>{params.row.commissionPercentOverride}%</span>
                        ) : (
                            params.row.agent && `${params.row.agent.commissionPercent}%`
                        )}
                    </div>
                ),
            },
            {
                field: 'commission',
                headerName: 'Commission',
                width: 120,
                align: 'right',
                headerAlign: 'right',
                renderCell: params => (
                    <div style={{ fontFamily: 'monospace' }}>
                        {dinero({
                            amount: Math.round(
                                (params.row.commissionPercentOverride
                                    ? params.row.commissionPercentOverride
                                    : params.row.agent
                                    ? params.row.agent.commissionPercent
                                    : 0) * d2f(params.row.net),
                            ),
                            currency: params.row.currency,
                        }).toFormat()}
                    </div>
                ),
            },
            {
                field: 'agent',
                headerName: 'Agent',
                width: 200,
                sortable: false,
                renderCell: params =>
                    params.row.agent && (
                        <div
                            style={{
                                display: 'block',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}
                        >{`${params.row.agent.name} (${d2f(params.row.agent.fraction).toFixed(2)})`}</div>
                    ),
            },
        ],
        [theme, timezone],
    );

    return (
        <Box
            sx={{
                width: '100%',
                '.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
                    outline: 'none',
                },
                marginBottom: theme.spacing(4),
            }}
        >
            <DataGrid
                rows={rows || []}
                columns={columns}
                components={{
                    Toolbar: CustomToolbar,
                }}
                density={'compact'}
                autoHeight={true}
                disableColumnFilter
                rowCount={rowsCount || 0}
                getRowId={row => row._id}
                pageSize={limit}
                onPageSizeChange={newPageSize => setLimit(newPageSize)}
                rowsPerPageOptions={[10, 20, 40, 80, 100]}
                page={offset / limit}
                onPageChange={newPage => setOffset(limit * newPage)}
                paginationMode={'server'}
                sortingMode={'server'}
                sortModel={reportSort}
                onSortModelChange={(model: GridSortModel) => {
                    setReportSort(model);
                }}
                loading={loading}
                sx={{
                    backgroundColor: '#ffffff',
                    display: 'block',
                    overflowX: 'auto',
                }}
            />
        </Box>
    );
};

export default SextforceTransactionsResultsGrid;
