import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Button, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useState } from 'react';
import OnlyFansReloginDialog from '../login/OnlyFansReloginDialog';

type Props = {
    subscriber: any;
    refetchSubscriber: () => void;
    pulsating?: boolean;
};

const ReloginAlertCard = (props: Props) => {
    const { subscriber, refetchSubscriber } = props;
    const [openReloginDialog, setOpenReloginDialog] = useState(false);

    const handleReloginDialogClosed = () => {
        setOpenReloginDialog(false);
    };

    const onClickRelogin = () => {
        setOpenReloginDialog(true);
    };

    return (
        <Box>
            <Button
                onClick={e => {
                    e.stopPropagation();
                    onClickRelogin();
                }}
                fullWidth
                variant="outlined"
                color="error"
                sx={{
                    backgroundColor: '#FEEFEE',
                    padding: 1,
                    '& .MuiButton-label': {
                        justifyContent: 'space-between',
                    },
                    ...(props.pulsating && {
                        // make the button pulsate to draw attention
                        animation: 'pulse 1.5s infinite',
                        '@keyframes pulse': {
                            '0%': {
                                transform: 'scale(1)',
                            },
                            '50%': {
                                transform: 'scale(1.1)',
                            },
                            '100%': {
                                transform: 'scale(1)',
                            },
                        },
                    }),
                }}
            >
                <Stack direction={'row'} spacing={2} alignItems="center" justifyContent="space-between">
                    <Stack direction={'row'} spacing={1} alignItems="center">
                        <ErrorOutlineIcon sx={{ color: '#AE2012' }} />
                        <Typography variant="inherit">Click here to relogin to this account</Typography>
                    </Stack>
                </Stack>
            </Button>
            {openReloginDialog && (
                <OnlyFansReloginDialog
                    subscriber={subscriber}
                    refetchSubscriber={refetchSubscriber}
                    open={openReloginDialog}
                    onClose={handleReloginDialogClosed}
                />
            )}
        </Box>
    );
};

export default ReloginAlertCard;
