import { Backdrop, Card, CardContent, CircularProgress, Typography } from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';
import { Theme } from '@mui/system';
import { useDialog } from 'muibox';
import { useContext, useEffect, useState } from 'react';
import { NavigateFunction, useNavigate, useParams } from 'react-router-dom';
import BackNavigationButton from '../../../components/common/BackNavigationButton';
import PageContainer from '../../../components/common/PageContainter';
import { SettingsContext } from '../../../store/SettingsContext';
import { UserContext } from '../../../store/UserContext';
import { handleHttpError, handleHttpErrorResponse } from '../../../utils/common';

const HostessWelcome = () => {
    const userContext = useContext(UserContext);
    const settingsContext = useContext(SettingsContext);
    const theme: Theme = useTheme();
    const navigate: NavigateFunction = useNavigate();
    const dialog = useDialog();
    const params = useParams();
    const [showBackdrop, setShowBackdrop] = useState<boolean>(false);

    const [subscriber, setSubscriber] = useState<any | null>(null);

    useEffect(() => {
        if (userContext.jwtToken && settingsContext.apiKey && 'userId' in params && params.userId) {
            const query: string = `${settingsContext.routes.subscribers.find}${params.userId}`;

            fetch(query, {
                method: 'get',
                headers: {
                    Authorization: userContext.jwtToken,
                    apiKey: settingsContext.apiKey,
                },
            })
                .then(async response => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        handleHttpErrorResponse(response, dialog);
                    }
                })
                .then(data => {
                    setSubscriber(data);
                    setShowBackdrop(false);

                    if (!data || !('_id' in data) || !data._id) {
                        navigate('/');
                    }
                })
                .catch(error => {
                    console.error(error);
                    handleHttpError(error, dialog);
                });
        }
    }, [userContext, settingsContext, params, dialog, navigate]);

    return (
        <PageContainer>
            {subscriber && '_id' in subscriber && subscriber._id && (
                <>
                    <Typography variant="h5" sx={{ marginBottom: theme.spacing(2) }}>
                        <BackNavigationButton {...(subscriber && { url: `/subscribers/${subscriber._id}/services/hostess` })} /> Hostess
                        Welcome
                    </Typography>

                    <Card variant="elevation" sx={{ marginBottom: theme.spacing(2) }}>
                        <CardContent>
                            <Typography variant="body1">Hostess</Typography>
                        </CardContent>
                    </Card>

                    <Card variant="elevation" sx={{ marginBottom: theme.spacing(2) }}>
                        <CardContent>
                            <Typography variant="h5" sx={{ marginBottom: theme.spacing(2) }}>
                                Concept
                            </Typography>

                            <Typography variant="h6" sx={{ marginBottom: theme.spacing(2) }}>
                                Agents
                            </Typography>

                            <Typography variant="body1" sx={{ marginBottom: theme.spacing(2) }}>
                                asdf
                            </Typography>
                        </CardContent>
                    </Card>

                    <Card variant="elevation" sx={{ marginBottom: theme.spacing(2) }}>
                        <CardContent>
                            <Typography variant="h6" sx={{ marginBottom: theme.spacing(2) }}>
                                Anonymous Transactions
                            </Typography>

                            <Typography variant="body1" sx={{ marginBottom: theme.spacing(2) }}>
                                sdf
                            </Typography>
                        </CardContent>
                    </Card>
                </>
            )}
            <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={showBackdrop}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </PageContainer>
    );
};

export default HostessWelcome;
