import { Checkbox, FormControlLabel, Grid } from '@mui/material';
import { Theme } from '@mui/system';
import { ChangeEvent, useEffect, useState } from 'react';

type OptionsProps = {
    setOptions: (options: any) => void;
    theme: Theme;
};

export const PromoCampaignOptions = (props: OptionsProps) => {
    const { setOptions, theme } = props;
    const [countSubscribers, setCountSubscribers] = useState<boolean>(true);
    const [countTransitions, setCountTransitions] = useState<boolean>(true);
    const [suspiciousUsers, setSuspiciousUsers] = useState<boolean>(true);
    const [totalEarnings, setTotalEarnings] = useState<boolean>(false);
    const [subscribersGraph, setSubscribersGraph] = useState<boolean>(true);

    useEffect(() => {
        setOptions({
            ...(countSubscribers && { countSubscribers }),
            ...(countTransitions && { countTransitions }),
            ...(suspiciousUsers && { suspiciousUsers }),
            ...(totalEarnings && { totalEarnings }),
            ...(subscribersGraph && { subscribersGraph }),
        });
    }, [countSubscribers, countTransitions, setOptions, subscribersGraph, suspiciousUsers, totalEarnings]);

    return (
        <Grid container spacing={0} alignItems="center" flexGrow={1} sx={{ marginBottom: theme.spacing(2) }}>
            <Grid item xs={12}>
                <FormControlLabel
                    control={
                        <Checkbox
                            size="medium"
                            checked={countSubscribers}
                            onChange={(e: ChangeEvent, checked: boolean) => {
                                setCountSubscribers(checked);

                                if (!checked) {
                                    setSubscribersGraph(false);
                                }
                            }}
                        />
                    }
                    label="Total Subscribed"
                />
            </Grid>
            <Grid item xs={12}>
                <FormControlLabel
                    control={
                        <Checkbox
                            size="medium"
                            checked={countTransitions}
                            onChange={(e: ChangeEvent, checked: boolean) => {
                                setCountTransitions(checked);
                            }}
                        />
                    }
                    label="Total Clicked"
                />
            </Grid>
            {/* <Grid item xs={12}>
                <FormControlLabel
                    control={
                        <Checkbox
                            size="medium"
                            checked={suspiciousUsers}
                            onChange={(e: ChangeEvent, checked: boolean) => {
                                setSuspiciousUsers(checked);
                            }}
                        />
                    }
                    label="Suspicious Users"
                />
            </Grid> */}
            <Grid item xs={12}>
                <FormControlLabel
                    control={
                        <Checkbox
                            size="medium"
                            checked={totalEarnings}
                            onChange={(e: ChangeEvent, checked: boolean) => {
                                setTotalEarnings(checked);
                            }}
                        />
                    }
                    label={
                        <span>
                            Total Earned (<strong style={{ color: theme.palette.error.main }}>Careful!</strong>)
                        </span>
                    }
                />
            </Grid>
            <Grid item xs={12}>
                <FormControlLabel
                    control={
                        <Checkbox
                            size="medium"
                            disabled={!countSubscribers}
                            checked={subscribersGraph}
                            onChange={(e: ChangeEvent, checked: boolean) => {
                                setSubscribersGraph(checked);
                            }}
                        />
                    }
                    label="Subscribtions Count and Clicks Count by Date Graph"
                />
            </Grid>
        </Grid>
    );
};

export const TrialOrPromoOptions = (props: OptionsProps) => {
    const { setOptions, theme } = props;
    const [claimsCount, setClaimsCount] = useState<boolean>(true);
    const [subscribeCounts, setSubscribeCounts] = useState<boolean>(true);
    const [suspiciousUsers, setSuspiciousUsers] = useState<boolean>(true);
    const [totalEarnings, setTotalEarnings] = useState<boolean>(false);
    const [subscribersGraph, setSubscribersGraph] = useState<boolean>(true);

    useEffect(() => {
        setOptions({
            ...(claimsCount && { claimsCount }),
            ...(subscribeCounts && { subscribeCounts }),
            ...(suspiciousUsers && { suspiciousUsers }),
            ...(totalEarnings && { totalEarnings }),
            ...(subscribersGraph && { subscribersGraph }),
        });
    }, [claimsCount, setOptions, subscribeCounts, subscribersGraph, suspiciousUsers, totalEarnings]);

    return (
        <Grid container spacing={0} alignItems="center" flexGrow={1} sx={{ marginBottom: theme.spacing(2) }}>
            <Grid item xs={12}>
                <FormControlLabel
                    control={
                        <Checkbox
                            size="medium"
                            checked={claimsCount}
                            onChange={(e: ChangeEvent, checked: boolean) => {
                                setClaimsCount(checked);

                                if (!checked) {
                                    setSubscribersGraph(false);
                                }
                            }}
                        />
                    }
                    label="Total Claimed"
                />
            </Grid>
            <Grid item xs={12}>
                <FormControlLabel
                    control={
                        <Checkbox
                            size="medium"
                            checked={subscribeCounts}
                            onChange={(e: ChangeEvent, checked: boolean) => {
                                setSubscribeCounts(checked);
                            }}
                        />
                    }
                    label="Total Subscribed"
                />
            </Grid>
            {/* <Grid item xs={12}>
                <FormControlLabel
                    control={
                        <Checkbox
                            size="medium"
                            checked={suspiciousUsers}
                            onChange={(e: ChangeEvent, checked: boolean) => {
                                setSuspiciousUsers(checked);
                            }}
                        />
                    }
                    label="Suspicious Users"
                />
            </Grid> */}
            <Grid item xs={12}>
                <FormControlLabel
                    control={
                        <Checkbox
                            size="medium"
                            checked={totalEarnings}
                            onChange={(e: ChangeEvent, checked: boolean) => {
                                setTotalEarnings(checked);
                            }}
                        />
                    }
                    label={
                        <span>
                            Total Earned (<strong style={{ color: theme.palette.error.main }}>Careful!</strong>)
                        </span>
                    }
                />
            </Grid>
            <Grid item xs={12}>
                <FormControlLabel
                    control={
                        <Checkbox
                            size="medium"
                            disabled={!claimsCount}
                            checked={subscribersGraph}
                            onChange={(e: ChangeEvent, checked: boolean) => {
                                setSubscribersGraph(checked);
                            }}
                        />
                    }
                    label="Claims Count and Subscribtions Count by Date Graph"
                />
            </Grid>
        </Grid>
    );
};
