import SextforceSVG from '../../assets/sextforce/sextforce.svg';

type IconOnlyFansProps = {
    width?: string | number;
    height?: string | number;
    style?: React.CSSProperties;
};

const LogoSextforce = (props: IconOnlyFansProps) => {
    const { width, height, style } = props;

    return <img src={SextforceSVG} width={width} height={height} alt="Sextforce Logo" style={style} />;
};

export default LogoSextforce;
