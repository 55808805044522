import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { TextField } from '@mui/material';
import moment from 'moment';
import { getNested } from '../utils/common';

type FormikDateTimeProps = {
    formik: any;
    fieldName: string;
    label: string;
};

const FormikDateTime = (props: FormikDateTimeProps) => {
    const { formik, fieldName, label } = props;

    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <DesktopDatePicker
                label={label}
                inputFormat="DD/MM/yyyy"
                showDaysOutsideCurrentMonth={true}
                value={getNested(formik.values, fieldName, '.') || null}
                onChange={(value: moment.Moment | null) => {
                    if (value) {
                        formik.setFieldValue(fieldName, value.startOf('day').utc().format());
                    } else {
                        formik.setFieldValue(fieldName, null);
                    }
                }}
                renderInput={(params: any) => <TextField {...params} />}
            />
        </LocalizationProvider>
    );
};

export default FormikDateTime;
