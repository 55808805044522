import Joi from 'joi';
import moment from 'moment';
import { useDialog } from 'muibox';
import { useContext } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { SettingsContext } from '../store/SettingsContext';
import { UserContext } from '../store/UserContext';
import { handleHttpError } from '../utils/common';
import useAxios from './useAxios';
import { SextforceAutoRepostLog } from './useSextforceAutoRepostLogs';

// Array of all possible values for the SextforceAutoRepostActionPreviousPost type
export const arraySextforceAutoRepostActionPreviousPost = ['none', 'delete', 'archive', 'privateArchive'];

// Type for the SextforceAutoRepostActionPreviousPost
export type SextforceAutoRepostActionPreviousPost = typeof arraySextforceAutoRepostActionPreviousPost[number];

export const arraySextforceAutoRepostActionPreviousPostDisplay = ['Nothing', 'Delete', 'Archive', 'Private Archive'];

export const arraySextforceAutoRepostType = ['link', 'post'];

export type SextforceAutoRepostType = typeof arraySextforceAutoRepostType[number];

export interface SextforceAutoRepost {
    _id?: string;
    subscriberId: string;
    name: string;
    description: string;
    active: boolean;
    originalPostUrl: string;
    type: SextforceAutoRepostType;
    startDate?: Date;
    endDate?: Date;
    repostEveryAmount: number;
    repostEveryUnit: string;
    repostWhenCampaignHitsTarget?: boolean;
    pinPost: boolean;
    repostCount: number;
    actionPreviousPost: SextforceAutoRepostActionPreviousPost;
    nextRepostDate: Date;
    latestRepostDate?: Date;
    deletedDate?: Date;
    hasErrors?: boolean;
    isPostTipCampaign?: boolean;
    repostAfterTipCount?: number;
    postId?: number;
}

export const schemaSextforceAutoRepost = Joi.object<SextforceAutoRepost>({
    subscriberId: Joi.string().required(),
    originalPostUrl: Joi.string().trim().required().uri().messages({
        'string.uri': 'The Original Post URL must be a valid URL.',
        'string.empty': 'The Original Post URL cannot be empty.',
    }),
    name: Joi.string().trim().required().default('').messages({
        'string.empty': 'The Name cannot be empty.',
        'string.default': 'The Name cannot be empty.',
    }),
    description: Joi.string().trim().optional().allow(''),
    active: Joi.boolean().required(),
    type: Joi.string()
        .valid(...arraySextforceAutoRepostType)
        .required(),
    startDate: Joi.date().optional(),
    endDate: Joi.date().optional(),
    repostEveryAmount: Joi.number()
        .min(1)
        .required()
        .messages({
            'number.min': 'The Repost Every Amount must be at least 10 minutes.',
            'number.base': 'The Repost Every Amount must be a number.',
            'number.empty': 'The Repost Every Amount cannot be empty.',
            'number.default': 'The Repost Every Amount cannot be empty.',
        })
        .custom((value, helpers) => {
            // Check that the amount is no less than 10 when repostEveryUnit is set to 'minutes'
            if (value < 10 && helpers.state.ancestors[0].repostEveryUnit === 'minutes') {
                return helpers.error('number.min');
            }

            return true;
        }),
    repostEveryUnit: Joi.string().required(),
    repostWhenCampaignHitsTarget: Joi.boolean().optional().default(false),
    pinPost: Joi.boolean().required().default(false),
    repostCount: Joi.number().required(),
    actionPreviousPost: Joi.string()
        .valid(...arraySextforceAutoRepostActionPreviousPost)
        .required(),
    nextRepostDate: Joi.date().required(),
    latestRepostDate: Joi.date().optional(),
    deletedDate: Joi.date().optional(),
    hasErrors: Joi.boolean().optional().default(false),
    isPostTipCampaign: Joi.boolean().optional().default(false),
    repostAfterTipCount: Joi.number().optional(),
    postId: Joi.number().optional(),
});

export interface SextforceAutoRepostWithLatestRepostLog extends SextforceAutoRepost {
    latestRepostLog?: SextforceAutoRepostLog;
}

const useSextforceAutoRepost = (
    query: string,
    startDate: Date,
    endDate: Date,
    timezone: string,
    active: boolean | null,
    sortBy: '_id' | 'name' | 'description' | 'nextRepostDate',
) => {
    const userContext = useContext(UserContext);
    const settingsContext = useContext(SettingsContext);
    const axios = useAxios();
    const dialog = useDialog();
    const params = useParams();

    // Fetch online users count vs. total sales report
    const fetchData = async (
        query: string,
        startDate: Date,
        endDate: Date,
        timezone: string,
        active: boolean | null,
        sortBy: '_id' | 'name' | 'description' | 'nextRepostDate',
    ): Promise<SextforceAutoRepostWithLatestRepostLog[]> => {
        if (!userContext.jwtToken || !settingsContext.apiKey) {
            return [];
        }

        if (!startDate || !endDate) {
            return [];
        }

        const url: string = `${settingsContext.routes.autoRepost.base}${params.userId}?${new URLSearchParams({
            query: query.trim(),
            startDate: moment(startDate).tz(timezone, true).toISOString(),
            endDate: moment(endDate).tz(timezone, true).toISOString(),
            active: typeof active === 'boolean' ? (active ? 'true' : 'false') : 'null',
            sortBy: sortBy,
        })}`;

        const data = await axios
            .get(url)
            .then(async response => response.data as SextforceAutoRepostWithLatestRepostLog[])
            .catch(error => {
                handleHttpError(error, dialog);

                return [];
            });

        return data;
    };

    return useQuery(
        ['autoRepost', params.userId, userContext.jwtToken, query, startDate, endDate, timezone, active, sortBy],
        () => {
            return fetchData(query, startDate, endDate, timezone, active, sortBy);
        },
        {
            refetchOnWindowFocus: false,
            enabled: userContext.jwtToken && params.userId && settingsContext.apiKey ? true : false,
        },
    );
};

export default useSextforceAutoRepost;
