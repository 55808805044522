import { useContext } from 'react';
import { useQuery } from 'react-query';
import { SettingsContext } from '../store/SettingsContext';
import { UserContext } from '../store/UserContext';
import useAxios from './useAxios';

export interface SystemStats {
    data: {
        totalStorage: number;
        usedStorage: number;
        useStorage: number;
        availableStorage: number;
    };
}

const useSystemStats = () => {
    const userContext = useContext(UserContext);
    const settingsContext = useContext(SettingsContext);
    const axios = useAxios();

    // Fetch Subscriber's Lists
    const fetchSystemInfo = async (): Promise<SystemStats | null> => {
        const query: string = `${settingsContext.routes.system.stats}`;

        return axios
            .get(query)
            .then(response => response.data)
            .catch(() => {
                return null;
            });
    };

    const systemStats = useQuery(['systemStats'], () => fetchSystemInfo(), {
        refetchOnWindowFocus: false,
        retry: false,
        // stale time 1 hour
        staleTime: 3600000,
        enabled: userContext.jwtToken && settingsContext.apiKey && userContext.isAdmin ? true : false,
    });

    return { ...systemStats };
};

export default useSystemStats;
