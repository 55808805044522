import LoadingButton from '@mui/lab/LoadingButton';
import {
    Alert,
    Button,
    ButtonGroup,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    FormControl,
    Grid,
    MenuItem,
    Select,
    TextField,
    Typography,
    useTheme,
} from '@mui/material';
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import Joi from 'joi';
import moment from 'moment';
import { useDialog } from 'muibox';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useAxios from '../../../../hooks/useAxios';
import {
    arraySextforceAutoRepostActionPreviousPost,
    arraySextforceAutoRepostActionPreviousPostDisplay,
    schemaSextforceAutoRepost,
    SextforceAutoRepost,
    SextforceAutoRepostActionPreviousPost,
} from '../../../../hooks/useSextforceAutoRepost';
import { SettingsContext } from '../../../../store/SettingsContext';
import { UserContext } from '../../../../store/UserContext';
import { handleHttpError } from '../../../../utils/common';
import StyledCardTitleBar from '../../../common/StyledCardTitleBar';

const momentPeriods: { [key: string]: string } = {
    minutes: 'Minutes',
    hours: 'Hours',
    days: 'Days',
};

type Props = {
    subscriber: any;
    autoRepost: SextforceAutoRepost;
    onClose: () => void;
    open: boolean;
    refetchAutoReposts: () => void;
};

const SextforceAutoRepostEditRepostDialog = (props: Props) => {
    const { subscriber, autoRepost, onClose, open, refetchAutoReposts } = props;
    const userContext = useContext(UserContext);
    const settingsContext = useContext(SettingsContext);
    const params = useParams();
    const dialog = useDialog();
    const axios = useAxios();
    const theme = useTheme();

    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [formData, setFormData] = useState<SextforceAutoRepost>({
        subscriberId: subscriber._id,
        name: autoRepost.name,
        description: autoRepost.description,
        active: autoRepost.active,
        originalPostUrl: autoRepost.originalPostUrl,
        type: autoRepost.type,
        repostEveryAmount: autoRepost.repostEveryAmount,
        repostEveryUnit: autoRepost.repostEveryUnit,
        repostWhenCampaignHitsTarget: autoRepost.repostWhenCampaignHitsTarget,
        pinPost: autoRepost.pinPost || false,
        repostCount: autoRepost.repostCount,
        actionPreviousPost: autoRepost.actionPreviousPost,
        nextRepostDate: autoRepost.nextRepostDate,
        startDate: autoRepost.startDate,
        endDate: autoRepost.endDate,
    });

    // Validate the form data
    const errors: Joi.ValidationErrorItem[] | null = schemaSextforceAutoRepost.validate(formData).error?.details || null;

    useEffect(() => {
        if (!open) {
            setIsSaving(false);
        } else {
            setFormData({
                subscriberId: subscriber._id,
                name: autoRepost.name,
                description: autoRepost.description,
                active: autoRepost.active,
                originalPostUrl: autoRepost.originalPostUrl,
                type: autoRepost.type,
                repostEveryAmount: autoRepost.repostEveryAmount,
                repostEveryUnit: autoRepost.repostEveryUnit,
                repostWhenCampaignHitsTarget: autoRepost.repostWhenCampaignHitsTarget,
                pinPost: autoRepost.pinPost || false,
                repostCount: autoRepost.repostCount,
                actionPreviousPost: autoRepost.actionPreviousPost,
                nextRepostDate: autoRepost.nextRepostDate,
                startDate: autoRepost.startDate,
                endDate: autoRepost.endDate,
            });
        }
    }, [
        autoRepost.actionPreviousPost,
        autoRepost.active,
        autoRepost.description,
        autoRepost.endDate,
        autoRepost.name,
        autoRepost.nextRepostDate,
        autoRepost.originalPostUrl,
        autoRepost.pinPost,
        autoRepost.repostCount,
        autoRepost.repostEveryAmount,
        autoRepost.repostEveryUnit,
        autoRepost.repostWhenCampaignHitsTarget,
        autoRepost.startDate,
        autoRepost.type,
        open,
        subscriber._id,
    ]);

    const handleClose = (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => {
        if (reason && reason === 'backdropClick') {
            return;
        }

        onClose();
    };

    const handleSave = () => {
        const updateAutoRepost = async () => {
            if (userContext.jwtToken && settingsContext.apiKey && 'userId' in params && params.userId && subscriber && autoRepost) {
                const query: string = `${settingsContext.routes.autoRepost.base}${params.userId}/${autoRepost._id}`;

                setIsSaving(true);

                // Update campaign settings
                const updatedAutoRepost = await axios
                    .put(query, formData)
                    .then(async response => response.data)
                    .catch(error => {
                        console.error(error);
                        setIsSaving(false);
                        handleHttpError(error, dialog);
                    });

                if (updatedAutoRepost && updatedAutoRepost._id) {
                    dialog
                        .alert({
                            title: 'Auto Repost',
                            message: 'Auto Repost updated successfully.',
                        })
                        .then(() => {
                            onClose();
                            refetchAutoReposts();
                        })
                        .catch(() => {});
                } else {
                    dialog
                        .alert({
                            title: 'Auto Repost',
                            message: 'Auto Repost could not be updated.',
                        })
                        .then(() => {})
                        .catch(() => {});
                }

                setIsSaving(false);
            }
        };

        updateAutoRepost();
    };

    return (
        <>
            <Dialog open={open} onClose={handleClose} disableEscapeKeyDown>
                <StyledCardTitleBar title="Edit Auto Repost" theme={theme} />
                <DialogContent>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <Grid container spacing={0.5}>
                            <Grid item xs={12}>
                                Name
                                <Typography variant="body2" color={'text.secondary'} marginTop={1}>
                                    Name of this promo or the person you are promoting.{' '}
                                    <em>This will be shown in the Telegram notification to your colleagues.</em>
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <TextField
                                        id="name"
                                        name="name"
                                        size="medium"
                                        value={formData.name}
                                        onChange={e => setFormData({ ...formData, name: e.target.value })}
                                        error={!!errors?.find(error => error.context?.key === 'name')}
                                        helperText={errors ? errors.find(error => error.context?.key === 'name')?.message : undefined}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                Description
                                <Typography variant="body2" color={'text.secondary'} marginTop={1}>
                                    Any description or notes you would like to leave for this promo. These will not appear in the Telegram
                                    notification to your colleagues.
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <TextField
                                        id="description"
                                        name="description"
                                        size="medium"
                                        value={formData.description}
                                        onChange={e => setFormData({ ...formData, description: e.target.value })}
                                        error={!!errors?.find(error => error.context?.key === 'description')}
                                        helperText={
                                            errors ? errors.find(error => error.context?.key === 'description')?.message : undefined
                                        }
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                Repost Every
                                <Typography variant="body2" color={'text.secondary'} marginTop={1}>
                                    How often should the post be reposted? A small random amount of minutes will be added automatically so
                                    to not look like a bot.
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Alert severity="warning" icon={false}>
                                    Note: There is no point in reposting posts too frequently because nobody will get a chance to see them -
                                    especially if you have many auto reposts setup at the same time. They will just be fighting each other
                                    for attention. Make sure to look at the statistics of each repost here to see how many users viewed the
                                    post.
                                </Alert>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container alignItems={'center'} justifyContent={'center'} spacing={1}>
                                    <Grid item xs>
                                        <FormControl fullWidth>
                                            <TextField
                                                id="repostEveryAmount"
                                                name="repostEveryAmount"
                                                size="medium"
                                                value={Number.isNaN(formData.repostEveryAmount) ? '' : formData.repostEveryAmount}
                                                onChange={e =>
                                                    setFormData({
                                                        ...formData,
                                                        repostEveryAmount: Number.isNaN(Number.parseInt(e.target.value, 10))
                                                            ? 0
                                                            : Number.parseInt(e.target.value, 10),
                                                    })
                                                }
                                                error={!!errors?.find(error => error.context?.key === 'repostEveryAmount')}
                                                helperText={
                                                    errors
                                                        ? errors.find(error => error.context?.key === 'repostEveryAmount')?.message
                                                        : undefined
                                                }
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs="auto">
                                        <FormControl fullWidth>
                                            <Select
                                                value={formData.repostEveryUnit}
                                                onChange={e => {
                                                    setFormData({ ...formData, repostEveryUnit: e.target.value });
                                                }}
                                                fullWidth
                                                size="medium"
                                                sx={{ mt: '4px' }}
                                            >
                                                {Object.keys(momentPeriods).map(item => (
                                                    <MenuItem value={item} key={item}>
                                                        {momentPeriods[item]}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                Repost When Tip Campaign Hits Target
                                <Typography variant="body2" color={'text.secondary'} marginTop={1}>
                                    Should the post be reposted when the tip campaign hits its target and not wait for the repost time?
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <ButtonGroup fullWidth disabled={!autoRepost.isPostTipCampaign || autoRepost.type !== 'post'}>
                                        <Button
                                            variant={formData.repostWhenCampaignHitsTarget ? 'contained' : 'outlined'}
                                            color="primary"
                                            size="large"
                                            onClick={() => setFormData({ ...formData, repostWhenCampaignHitsTarget: true })}
                                        >
                                            Yes
                                        </Button>
                                        <Button
                                            variant={!formData.repostWhenCampaignHitsTarget ? 'contained' : 'outlined'}
                                            color="primary"
                                            size="large"
                                            onClick={() => setFormData({ ...formData, repostWhenCampaignHitsTarget: false })}
                                        >
                                            No
                                        </Button>
                                    </ButtonGroup>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                Until Date (optional)
                                <Typography variant="body2" color={'text.secondary'} marginTop={1}>
                                    If you want the repost to stop at a specific date, enter it here. If you don't enter a date, the repost
                                    will continue indefinitely.
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container alignItems={'center'} justifyContent={'center'} spacing={1}>
                                    <Grid item xs="auto">
                                        <Checkbox
                                            size="medium"
                                            checked={typeof formData.endDate !== 'undefined'}
                                            onChange={e => {
                                                if (e.target.checked) {
                                                    setFormData({ ...formData, endDate: new Date() });
                                                } else {
                                                    setFormData({ ...formData, endDate: undefined });
                                                }
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs>
                                        <MobileDatePicker
                                            disabled={!formData.endDate}
                                            value={formData.endDate}
                                            onChange={date =>
                                                setFormData({ ...formData, endDate: date ? moment(date).toDate() : undefined })
                                            }
                                            renderInput={props => <TextField fullWidth size="medium" {...props} />}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                Pin Post
                                <Typography variant="body2" color={'text.secondary'} marginTop={1}>
                                    Should the new post be pinned to the top of your feed?
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <ButtonGroup fullWidth>
                                        <Button
                                            variant={formData.pinPost ? 'contained' : 'outlined'}
                                            color="primary"
                                            size="large"
                                            onClick={() => setFormData({ ...formData, pinPost: true })}
                                        >
                                            Yes
                                        </Button>
                                        <Button
                                            variant={!formData.pinPost ? 'contained' : 'outlined'}
                                            color="primary"
                                            size="large"
                                            onClick={() => setFormData({ ...formData, pinPost: false })}
                                        >
                                            No
                                        </Button>
                                    </ButtonGroup>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                What To Do With The Previous Post?
                                <Typography variant="body2" color={'text.secondary'} marginTop={1}>
                                    What should be done with the previous post when the new one is posted? If you choose to delete or
                                    archive the post then the post will be deleted or archived after the new post is posted.
                                    <em>
                                        Note: if you've chosen to repost a link, the original post will not be touched. Only the following
                                        reposts will be affected by this setting.
                                    </em>
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <Select
                                        id="actionPreviousPost"
                                        size="medium"
                                        value={formData.actionPreviousPost}
                                        onChange={e =>
                                            setFormData({
                                                ...formData,
                                                actionPreviousPost: e.target.value as SextforceAutoRepostActionPreviousPost,
                                            })
                                        }
                                    >
                                        {arraySextforceAutoRepostActionPreviousPost.map(action => (
                                            <MenuItem
                                                key={action}
                                                value={action}
                                                sx={{
                                                    color:
                                                        action === 'delete' && formData.type === 'post'
                                                            ? theme.palette.error.main
                                                            : 'inherit',
                                                }}
                                            >
                                                {`${
                                                    arraySextforceAutoRepostActionPreviousPostDisplay[
                                                        arraySextforceAutoRepostActionPreviousPost.indexOf(action)
                                                    ]
                                                }${action === 'delete' && formData.type === 'post' ? ' (Are you sure?)' : ''}`}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            {formData.actionPreviousPost === 'delete' && (
                                <Grid item xs={12}>
                                    <Alert severity="warning" icon={false}>
                                        OnlyFans has a limit of how many posts can be deleted every day. The limit is an inconvenient 20% of
                                        the total number of posts. Keep that in mind when you set the frequency of your reposts.
                                    </Alert>
                                </Grid>
                            )}
                        </Grid>
                    </LocalizationProvider>
                </DialogContent>
                <DialogActions>
                    <Button
                        color="error"
                        size="medium"
                        disabled={isSaving}
                        onClick={() => {
                            handleClose({}, 'escapeKeyDown');
                        }}
                    >
                        Close
                    </Button>
                    <LoadingButton
                        color="secondary"
                        variant="contained"
                        size="medium"
                        loading={isSaving}
                        disabled={!!errors}
                        onClick={() => {
                            handleSave();
                        }}
                    >
                        Save Settings
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default SextforceAutoRepostEditRepostDialog;
