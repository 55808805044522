import { Box, Skeleton } from '@mui/material';
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis } from 'recharts';
import { OnlyFansStreamStats, SextforceLiveStreamWithAmount } from '../../../../../hooks/useSextforceLiveStreamAddTippersToList';
import useSextforceLiveStreamAddTippersToListStats from '../../../../../hooks/useSextforceLiveStreamAddTippersToListStats';
import { secondsToTimeString } from '../../../../../utils/common';

const SextforceLiveStreamStatsGraph = (props: { liveStream: SextforceLiveStreamWithAmount }) => {
    const { liveStream } = props;
    const { data: stats, isLoading: isLoadingStats } = useSextforceLiveStreamAddTippersToListStats(liveStream.streamId);

    const data =
        !isLoadingStats && stats && stats.stream_look_chart
            ? stats.stream_look_chart.map((data: OnlyFansStreamStats.StreamChart, index: number) => {
                  return {
                      time: stats.stream_look_chart ? stats.stream_look_chart[index].point : 0,
                      looks: stats.stream_look_chart ? stats.stream_look_chart[index].count : 0,
                      likes: stats.stream_like_chart ? stats.stream_like_chart[index].count : 0,
                      comments: stats.stream_comment_chart ? stats.stream_comment_chart[index].count : 0,
                      tips: stats.stream_tip_chart ? stats.stream_tip_chart[index].count : 0,
                  };
              })
            : [];

    return (
        <Box component={'div'} sx={{ width: '100%', aspectRatio: '624/200', pl: 2, pr: 2, pt: 2 }}>
            {isLoadingStats && <Skeleton variant="rectangular" width="100%" height="100%" />}
            {!isLoadingStats && (
                <ResponsiveContainer width="100%" height="100%">
                    <LineChart
                        data={data}
                        margin={{
                            top: 0,
                            right: 0,
                            left: 0,
                            bottom: 0,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                            dataKey="time"
                            scale={'auto'}
                            tickFormatter={(value: number) => secondsToTimeString(value)}
                            domain={['dataMin', 'dataMax']}
                            tick={{ fontSize: 11 }}
                        />
                        {/* <YAxis
                        domain={[
                            (dataMin: number) => Math.round(dataMin - dataMin * 0.1),
                            (dataMax: number) => Math.round(dataMax + dataMax * 0.1),
                        ]}
                        allowDecimals={false}
                        tickFormatter={(value: number) => value.toLocaleString()}
                        type="number"
                    /> */}
                        <Tooltip
                            labelFormatter={(value: number) => secondsToTimeString(value)}
                            formatter={(value: number) => value.toLocaleString()}
                        />
                        <Line type="linear" dataKey="likes" stroke="#f8749d" dot={false} />
                        <Line type="linear" dataKey="looks" stroke="#58c3f0" dot={false} />
                        <Line type="linear" dataKey="tips" stroke="#af97d9" dot={false} />
                        <Line type="linear" dataKey="comments" stroke="#ffb968" dot={false} />
                    </LineChart>
                </ResponsiveContainer>
            )}
        </Box>
    );
};

export default SextforceLiveStreamStatsGraph;
