import {
    Checkbox,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    MenuItem,
    Radio,
    Select,
    SelectChangeEvent,
    TextField,
} from '@mui/material';
import { Theme } from '@mui/system';
import { DatePicker, MobileTimePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { getWeekRange } from '../../../utils/common';

export type ReportSearchBarPropsSeachParams = {
    freeRange: boolean;
    setFreeRange: React.Dispatch<React.SetStateAction<boolean>>;
    week: number;
    setWeek: React.Dispatch<React.SetStateAction<number>>;
    dateFrom: Date;
    setDateFrom: React.Dispatch<React.SetStateAction<Date>>;
    timeFrom: Date;
    setTimeFrom: React.Dispatch<React.SetStateAction<Date>>;
    dateTo: Date;
    setDateTo: React.Dispatch<React.SetStateAction<Date>>;
    timeTo: Date;
    setTimeTo: React.Dispatch<React.SetStateAction<Date>>;
    limitTime: boolean;
    setLimitTime: React.Dispatch<React.SetStateAction<boolean>>;
};

// Generate Select menu items for year
const YearMenuItems = () => {
    const menuItems = [];

    for (let i = moment().year(); i > 2000; i -= 1) {
        menuItems.push(
            <MenuItem value={i} key={i}>
                {i}
            </MenuItem>,
        );
    }

    return menuItems;
};

// Generate Select menu items for week of the year
const YearWeekMenuItems = () => {
    const menuItems = [];

    for (let i = 1; i <= 52; i += 1) {
        menuItems.push(
            <MenuItem value={i} key={i}>
                {i}
            </MenuItem>,
        );
    }

    return menuItems;
};

const WeekStartsOnSelect = (props: {
    weekStarsOn: 'sunday' | 'monday';
    setWeekStartsOn: (value: 'sunday' | 'monday') => void;
    freeRange: boolean;
    disabled: boolean;
}) => {
    const { weekStarsOn, setWeekStartsOn, freeRange, disabled } = props;

    return (
        <FormControl fullWidth>
            <InputLabel id="select-weekStartsOn-label">Week Starts On</InputLabel>
            <Select
                labelId="select-weekStartsOn-label"
                id="weekStartsOn"
                value={weekStarsOn}
                label="Week Starts On"
                size="small"
                disabled={disabled || freeRange}
                onChange={(e: SelectChangeEvent<string>) => setWeekStartsOn(e.target.value as 'sunday' | 'monday')}
            >
                <MenuItem value={'sunday'}>Sunday</MenuItem>
                <MenuItem value={'monday'}>Monday</MenuItem>
            </Select>
        </FormControl>
    );
};

const YearSelector = (props: { year: number; setYear: (year: number) => void; disabled: boolean }) => {
    const { year, setYear, disabled } = props;

    return (
        <FormControl fullWidth>
            <InputLabel id="select-year-label">Year</InputLabel>
            <Select
                labelId="select-year-label"
                id="reportYear"
                value={year}
                label="Year"
                margin="dense"
                size="small"
                disabled={disabled}
                onChange={(e: SelectChangeEvent<number>) => setYear(e.target.value as number)}
            >
                {YearMenuItems()}
            </Select>
        </FormControl>
    );
};

const WeekNumberSelector = (props: { week: number; setWeek: (week: number) => void; disabled: boolean }) => {
    const { week, setWeek, disabled } = props;

    return (
        <FormControl fullWidth>
            <InputLabel id="select-week-label">Week</InputLabel>
            <Select
                labelId="select-week-label"
                id="reportWeek"
                value={week}
                label="Week"
                size="small"
                disabled={disabled}
                onChange={(e: SelectChangeEvent<number>) => setWeek(e.target.value as number)}
            >
                {YearWeekMenuItems()}
            </Select>
        </FormControl>
    );
};

const DatePickerSelector = (props: { label: string; date: Date; setDate: (date: Date) => void; disabled: boolean }) => {
    const { label, date, setDate, disabled } = props;

    return (
        <DatePicker
            label={label}
            inputFormat="DD/MM/YYYY"
            mask={'__/__/____'}
            disabled={disabled}
            value={date}
            onChange={newValue => {
                if (newValue) {
                    setDate(moment(newValue).startOf('day').toDate());
                }
            }}
            renderInput={params => <TextField size="small" {...params} />}
            showDaysOutsideCurrentMonth={true}
        />
    );
};

const TimePickerSelector = (props: { label: string; time: Date; setTime: (time: Date) => void; disabled: boolean; theme: Theme }) => {
    const { label, time, setTime, disabled, theme } = props;

    return (
        <MobileTimePicker
            label={label}
            ampm={true}
            value={moment(time)}
            disabled={disabled}
            onChange={newValue => {
                if (newValue) {
                    setTime(moment(newValue).toDate());
                }
            }}
            renderInput={params => <TextField {...params} />}
            DialogProps={{
                sx: {
                    '.MuiPickersToolbar-content .Mui-selected': { color: theme.palette.secondary.main },
                },
            }}
        />
    );
};

export const SearchGroup = (props: { params: ReportSearchBarPropsSeachParams; enabled: boolean; theme: Theme }) => {
    const { enabled, theme } = props;
    const {
        freeRange,
        setFreeRange,
        week,
        setWeek,
        dateFrom,
        setDateFrom,
        timeFrom,
        setTimeFrom,
        dateTo,
        setDateTo,
        timeTo,
        setTimeTo,
        limitTime,
        setLimitTime,
    } = props.params;

    const [weekStartsOn, setWeekStartsOn] = useState<'sunday' | 'monday'>('monday');
    const [reportYear, setReportYear] = useState<number>(moment().year());

    useEffect(() => {
        if (!freeRange) {
            // Set global MomentJS locale
            // moment.updateLocale('en-gb', {
            //     week: {
            //         dow: weekStartsOn,
            //         doy: 0,
            //     },
            // });

            // const startOfWeek = moment().year(reportYear).week(week);

            // setDateFrom(startOfWeek.startOf('week').toDate());
            // setDateTo(startOfWeek.endOf('week').toDate());
            const weekRange = getWeekRange(reportYear, week, weekStartsOn);

            setDateFrom(weekRange.firstDayOfWeek);
            setDateTo(weekRange.lastDayOfWeek);
        }
    }, [freeRange, reportYear, setDateFrom, setDateTo, week, weekStartsOn]);

    return (
        <Grid container spacing={1} alignItems={'center'}>
            <Grid item xs={12}>
                <Grid container spacing={1} alignItems={'center'}>
                    <Grid item xs={12}>
                        <FormControlLabel
                            checked={!freeRange}
                            control={<Radio size="small" />}
                            label="By Week"
                            disabled={!enabled}
                            onChange={() => setFreeRange(false)}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Grid container spacing={1} alignItems={'center'}>
                            <Grid item xs={'auto'}>
                                <WeekStartsOnSelect
                                    weekStarsOn={weekStartsOn}
                                    setWeekStartsOn={setWeekStartsOn}
                                    freeRange={freeRange}
                                    disabled={!enabled}
                                />
                            </Grid>
                            <Grid item xs={'auto'}>
                                <YearSelector year={reportYear} setYear={setReportYear} disabled={!enabled || freeRange} />
                            </Grid>
                            <Grid item xs={'auto'}>
                                <WeekNumberSelector week={week} setWeek={setWeek} disabled={!enabled || freeRange} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12}>
                <Grid container spacing={1} alignItems={'center'}>
                    <Grid item xs={12}>
                        <FormControlLabel
                            checked={freeRange}
                            control={<Radio size="small" />}
                            label="Free Range"
                            disabled={!enabled}
                            onChange={() => setFreeRange(true)}
                        />
                    </Grid>
                    <Grid item xs={12} lg="auto">
                        <Grid container spacing={1} alignItems={'center'}>
                            <Grid item xs={6} sm="auto">
                                <DatePickerSelector
                                    label="Start Date"
                                    date={dateFrom}
                                    setDate={date => {
                                        setDateFrom(limitTime ? date : moment(date).startOf('day').toDate());
                                    }}
                                    disabled={!enabled || !freeRange}
                                />
                            </Grid>
                            <Grid item xs={6} sm="auto">
                                <DatePickerSelector
                                    label="End Date"
                                    date={dateTo}
                                    setDate={date => {
                                        setDateTo(limitTime ? date : moment(date).endOf('day').toDate());
                                    }}
                                    disabled={!enabled || !freeRange}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12}>
                <Grid container spacing={1} alignItems={'center'}>
                    <Grid item xs={12}>
                        <FormControlLabel
                            checked={limitTime}
                            control={<Checkbox size="medium" />}
                            disabled={!enabled || !freeRange}
                            label="Limit Results To"
                            onChange={(e, checked) => setLimitTime(checked)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={1} alignItems={'center'}>
                            <Grid item xs={6} sm="auto">
                                <TimePickerSelector
                                    label="Time From"
                                    time={timeFrom}
                                    setTime={setTimeFrom}
                                    disabled={!enabled || !freeRange || !limitTime}
                                    theme={theme}
                                />
                            </Grid>
                            <Grid item xs={6} sm="auto">
                                <TimePickerSelector
                                    label="Time To"
                                    time={timeTo}
                                    setTime={setTimeTo}
                                    disabled={!enabled || !freeRange || !limitTime}
                                    theme={theme}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};
