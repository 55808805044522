import { useDialog } from 'muibox';
import { useContext } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { SettingsContext } from '../store/SettingsContext';
import { UserContext } from '../store/UserContext';
import { handleHttpError } from '../utils/common';
import useAxios from './useAxios';

export interface OnlyFansAutoRepostTelegramNotifications {
    _id?: string;
    autoRepostId: string;
    inviteCode: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    options: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    lastPayload: any;
    active: boolean;
    telegramChatId?: number;
    telegramUsername?: string;
    registerDate?: Date;
    lastSentDate?: Date;
    deletedDate?: Date;
}

const useSextforceAutoRepostTelegramInvitationLink = (autoRepostId: string) => {
    const userContext = useContext(UserContext);
    const settingsContext = useContext(SettingsContext);
    const dialog = useDialog();
    const params = useParams();
    const axios = useAxios();

    // Fetch Fans Counters
    const fetchInvitationLinks = async (autoRepostId: string): Promise<OnlyFansAutoRepostTelegramNotifications[]> => {
        const query: string = `${settingsContext.routes.autoRepost.base}${params.userId}/${autoRepostId}/invite`;

        // Update campaign settings
        const data = await axios
            .get(query)
            .then(async response => response.data as OnlyFansAutoRepostTelegramNotifications[])
            .catch(error => {
                console.error(error);
                handleHttpError(error, dialog);

                return [];
            });

        return data;
    };

    const metricsTelegramInvitationLinks = useQuery(
        ['autoRepostTelegramInvitationLinks', userContext.jwtToken, params.userId, autoRepostId],
        () => fetchInvitationLinks(autoRepostId),
        {
            refetchOnWindowFocus: false,
            staleTime: 0,
            enabled: userContext.jwtToken && settingsContext.apiKey && params.userId ? true : false,
        },
    );

    return { ...metricsTelegramInvitationLinks };
};

export default useSextforceAutoRepostTelegramInvitationLink;
