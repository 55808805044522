import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import HomeIcon from '@mui/icons-material/Home';
import { Collapse, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { ServiceActions, SettingsContext } from '../store/SettingsContext';
import { getServiceStatusColor } from '../utils/common';

type Props = {
    subscriber: any;
    service: string;
    handleDrawerToggle: (event: React.KeyboardEvent | React.MouseEvent) => void;
};

const HeaderSideMenuSubscriberServiceActions = (props: Props) => {
    const { subscriber, service, handleDrawerToggle } = props;
    const settingsContext = useContext(SettingsContext);
    const [menuOpen, setMenuOpen] = useState<boolean>(false);

    const serviceHasActions: boolean =
        settingsContext.services[service].actions && settingsContext.services[service].actions!.length > 0 ? true : false;

    return (
        <>
            <ListItemButton
                onClick={() => {
                    setMenuOpen(!menuOpen);
                }}
            >
                <ListItemIcon
                    sx={{
                        minWidth: '32px',
                    }}
                >
                    {settingsContext.services[service].icon(
                        settingsContext.services[service].alwaysActive
                            ? 'success'
                            : getServiceStatusColor(subscriber, service, settingsContext.services[service].alwaysActive),
                    )}
                </ListItemIcon>
                <ListItemText primary={settingsContext.services[service].name} />
                {serviceHasActions && (menuOpen ? <ExpandLess /> : <ExpandMore />)}
            </ListItemButton>

            {serviceHasActions && (
                <Collapse in={menuOpen} timeout="auto" unmountOnExit>
                    <List dense disablePadding sx={{ pl: 2 }}>
                        <ListItemButton
                            component={Link}
                            to={`/subscribers/${subscriber._id}/${settingsContext.services[service].homeUrl}`}
                            onClick={handleDrawerToggle}
                            onKeyDown={handleDrawerToggle}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: '32px',
                                }}
                            >
                                <HomeIcon />
                            </ListItemIcon>
                            <ListItemText primary="Overview" />
                        </ListItemButton>
                    </List>
                    <List dense disablePadding sx={{ pl: 2 }}>
                        {settingsContext.services[service].actions &&
                            settingsContext.services[service]
                                .actions!.filter(
                                    (action: ServiceActions) =>
                                        action.type === 'category' || (action.type === 'action' && action.visible !== false),
                                )
                                .map((action: ServiceActions, index: number) => (
                                    <React.Fragment key={index}>
                                        {action.type === 'category' && (
                                            <ListItem
                                                disablePadding
                                                sx={{
                                                    pl: 2,
                                                    '&:hover': {
                                                        background: 'transparent',
                                                    },
                                                }}
                                            >
                                                <ListItemText
                                                    primary={action.title}
                                                    primaryTypographyProps={{ variant: 'body2', color: 'text.secondary' }}
                                                />
                                            </ListItem>
                                        )}
                                        {action.type === 'action' && (
                                            <ListItemButton
                                                component={Link}
                                                to={action.url(subscriber._id)}
                                                onClick={handleDrawerToggle}
                                                onKeyDown={handleDrawerToggle}
                                                key={index}
                                            >
                                                <ListItemIcon
                                                    sx={{
                                                        minWidth: '32px',
                                                    }}
                                                >
                                                    {action.icon}
                                                </ListItemIcon>
                                                <ListItemText primary={action.title} />
                                            </ListItemButton>
                                        )}
                                    </React.Fragment>
                                ))}
                    </List>
                </Collapse>
            )}
        </>
    );
};

export default HeaderSideMenuSubscriberServiceActions;
