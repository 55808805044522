import { Checkbox } from '@mui/material';
import { OnlyFansSubscriberHostessAutoReplyFreeloaders } from '../../../../types/hostessAutoReplyFreeloaders';

const FormFollowingThesholdEnabledCheckbox = ({
    followingThresholdEnabled,
    setFollowingThresholdEnabled,
    setFollowingThresholdAmountString,
    formData,
    setFormData,
}: {
    followingThresholdEnabled: boolean;
    setFollowingThresholdEnabled: (followingThresholdEnabled: boolean) => void;
    setFollowingThresholdAmountString: (followingThresholdAmountString: string) => void;
    formData: OnlyFansSubscriberHostessAutoReplyFreeloaders;
    setFormData: (formData: OnlyFansSubscriberHostessAutoReplyFreeloaders) => void;
}) => {
    return (
        <Checkbox
            checked={followingThresholdEnabled}
            size="medium"
            onChange={e => {
                setFollowingThresholdEnabled(e.target.checked);

                if (!e.target.checked) {
                    setFormData({
                        ...formData,
                        followingThresholdAmount: undefined,
                        followingThresholdPeriod: undefined,
                    });
                    setFollowingThresholdAmountString('0');
                } else {
                    setFormData({
                        ...formData,
                        followingThresholdAmount: 2,
                        followingThresholdPeriod: 'months',
                    });
                    setFollowingThresholdAmountString('2');
                }
            }}
            sx={{
                '& .MuiSvgIcon-root': {
                    fontSize: '1.5rem',
                },
            }}
        />
    );
};

export default FormFollowingThesholdEnabledCheckbox;
