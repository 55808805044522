import { Grid, ToggleButton, ToggleButtonGroup, useMediaQuery } from '@mui/material';
import { Theme } from '@mui/system';
type Props = {
    trialsLoading: boolean;
    trialsSelectorType: { includeInactive: boolean; type: string };
    setTrialsSelectorType: (types: { includeInactive: boolean; type: string }) => void;
    theme: Theme;
};

const SextforceMetricsTrialsOverviewSelector = (props: Props) => {
    const { trialsLoading, trialsSelectorType, setTrialsSelectorType, theme } = props;
    const largeScreen = useMediaQuery(theme.breakpoints.up('sm'));

    return (
        <Grid container spacing={1} alignItems="center">
            <Grid item xs={12} md="auto">
                <Grid container spacing={1} alignItems="center">
                    <Grid item xs>
                        <ToggleButtonGroup
                            color="secondary"
                            size={'small'}
                            fullWidth
                            disabled={trialsLoading}
                            exclusive={true}
                            value={trialsSelectorType.type}
                            onChange={(e, value: string) => {
                                setTrialsSelectorType({ includeInactive: trialsSelectorType.includeInactive, type: value });
                            }}
                            sx={{ marginTop: '4px', height: '39px' }}
                        >
                            <ToggleButton value="all" key="left" sx={{ minWidth: '70px' }}>
                                All
                            </ToggleButton>
                            <ToggleButton value="trialLinkTrial" key="center" sx={{ minWidth: '70px' }}>
                                Trials
                            </ToggleButton>
                            <ToggleButton value="trialLinkPromo" key="right" sx={{ minWidth: '70px' }}>
                                Promos
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Grid>
                    <Grid item xs>
                        <ToggleButtonGroup
                            value={trialsSelectorType.includeInactive ? 'true' : 'false'}
                            exclusive
                            fullWidth
                            color="secondary"
                            size={'small'}
                            disabled={trialsLoading}
                            onChange={(e, value: string) => {
                                if (!value) {
                                    return;
                                }

                                setTrialsSelectorType({
                                    includeInactive: value === 'true',
                                    type: trialsSelectorType.type,
                                });

                                localStorage.setItem('sextforceMetricsCampaignsOverviewSelectorType', value);
                            }}
                            sx={{ marginTop: '4px', height: '39px' }}
                        >
                            <ToggleButton color="secondary" fullWidth value={'false'} sx={{ minWidth: '120px' }}>
                                Hide Deleted
                            </ToggleButton>
                            <ToggleButton color="secondary" fullWidth value={'true'} sx={{ minWidth: '120px' }}>
                                Show Deleted
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default SextforceMetricsTrialsOverviewSelector;
