import ClearIcon from '@mui/icons-material/Clear';
import FolderCopyIcon from '@mui/icons-material/FolderCopy';
import SquareIcon from '@mui/icons-material/Square';
import {
    Alert,
    Card,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Theme,
    ToggleButton,
    Tooltip,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { Stack } from '@mui/system';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { OnlyFansSubscriberCategory } from '../../hooks/useSubscriberCategories';
import useSubscribers, { SubscribersAdmin } from '../../hooks/useSubscribers';
import { UserContext } from '../../store/UserContext';
import { d2f } from '../../utils/common';
import SharedCreditTransferDialog from '../common/SharedCreditTransferDialog';
import DashboardMyAccountsManageCategoriesDialog from './DashboardMyAccountsManageCategoriesDialog';
import DashboardMyAccountsSubscriberCard from './DashboardMyAccountsSubscriberCard';
import DashboardMyAccountsSubscriberCardLarge from './DashboardMyAccountsSubscriberCardLarge';

// Sort the subscribers based on the sortBy value and the value in the stats array
const sortSubscribers = (
    subscribers: any[] | SubscribersAdmin | undefined,
    stats: any[],
    filter: string,
    sortBy: string,
    sortDirection: 'asc' | 'desc',
) => {
    if (!subscribers || !stats || !Array.isArray(subscribers) || subscribers.length === 0) {
        return [];
    }

    const copyOfSubscribers = [...subscribers];

    if (sortBy === 'isLoggedIn') {
        copyOfSubscribers.sort((a: any, b: any) => {
            // compare two booleans isLoggedIn
            return a.isLoggedIn - b.isLoggedIn;
        });
    } else if (sortBy === 'email') {
        copyOfSubscribers.sort((a: any, b: any) => {
            // compare two strings
            return a.email.localeCompare(b.email);
        });
    } else if (sortBy === 'username') {
        copyOfSubscribers.sort((a: any, b: any) => {
            // compare two strings
            return a.username.localeCompare(b.username);
        });
    } else if (sortBy === 'credit') {
        copyOfSubscribers.sort((a: any, b: any) => {
            // compare two numbers
            return (
                (a.financial && a.financial.sharedCredit ? d2f(a.financial.sharedCredit) : 0) -
                (b.financial && b.financial.sharedCredit ? d2f(b.financial.sharedCredit) : 0)
            );
        });
    } else if (sortBy === 'category') {
        copyOfSubscribers.sort((a: any, b: any) => {
            // Get the category name for each subscriber
            const categoryA: string | undefined = a.cognito && 'category' in a.cognito ? a.cognito.category : undefined;
            const categoryB: string | undefined = b.cognito && 'category' in b.cognito ? b.cognito.category : undefined;

            // If the category is not set, then set it to an empty string
            if (!categoryA) {
                return 0;
            }

            if (!categoryB) {
                return 0;
            }

            // compare two strings
            return categoryA.localeCompare(categoryB);
        });
    } else if (sortBy === 'fans') {
        // Use the fans data in the stats array to sort the subscribers
        copyOfSubscribers.sort((a: any, b: any) => {
            // Find the subscriber in the stats array and compare the fans
            const subA = stats[a._id];
            const subB = stats[b._id];

            if (!subA || !subA[0] || !subA[0].counters) {
                return 0;
            }

            if (!subB || !subB[0] || !subB[0].counters) {
                return 0;
            }

            return subA[0].counters.subscribers.active - subB[0].counters.subscribers.active;
        });
    } else if (sortBy === 'following') {
        // Use the following data in the stats array to sort the subscribers
        copyOfSubscribers.sort((a: any, b: any) => {
            // Find the subscriber in the stats array and compare the following
            const subA = stats[a._id];
            const subB = stats[b._id];

            if (!subA || !subA[0] || !subA[0].counters) {
                return 0;
            }

            if (!subB || !subB[0] || !subB[0].counters) {
                return 0;
            }

            return subA[0].counters.subscriptions.active - subB[0].counters.subscriptions.active;
        });
    } else if (sortBy === 'ranking') {
        // Use the ranking data in the stats array to sort the subscribers
        copyOfSubscribers.sort((a: any, b: any) => {
            // Find the subscriber in the stats array and compare the ranking
            const subA = stats[a._id];
            const subB = stats[b._id];

            if (!subA || !subA[0] || !subA[0].performerTop) {
                return 0;
            }

            if (!subB || !subB[0] || !subB[0].performerTop) {
                return 0;
            }

            return subA[0].performerTop - subB[0].performerTop;
        });
    } else if (sortBy === 'earnings') {
        // Use the earnings data in the stats array to sort the subscribers
        copyOfSubscribers.sort((a: any, b: any) => {
            // Find the subscriber in the stats array and compare the earnings
            const subA = stats[a._id];
            const subB = stats[b._id];

            if (!subA || !subA[0] || !subA[0].earnings || !subA[0].earnings.list || !subA[0].earnings.list.total.all.total_gross) {
                return 0;
            }

            if (!subB || !subB[0] || !subB[0].earnings || !subB[0].earnings.list || !subB[0].earnings.list.total.all.total_gross) {
                return 0;
            }

            return subA[0].earnings.list.total.all.total_gross - subB[0].earnings.list.total.all.total_gross;
        });
    }

    const filteredSubscribers = copyOfSubscribers.filter(subscriber => subscriber.username.toLowerCase().includes(filter.toLowerCase()));

    if (sortDirection === 'desc') {
        filteredSubscribers.reverse();
    }

    return filteredSubscribers;
};

const FilterTextFiled = ({ filter, setFilter }: any) => {
    const theme: Theme = useTheme();

    return (
        <FormControl fullWidth>
            <InputLabel id="search-label"></InputLabel>
            <TextField
                id="search"
                label="Search"
                value={filter}
                fullWidth
                size="medium"
                onChange={e => setFilter(e.target.value)}
                onKeyDown={e => {
                    if (e.key === 'Escape') {
                        setFilter('');
                    }
                }}
                InputProps={{
                    endAdornment: (
                        <ClearIcon
                            onClick={() => setFilter('')}
                            color="error"
                            sx={{
                                cursor: 'pointer',
                                visibility: filter.length > 0 ? 'visible' : 'hidden',
                            }}
                        />
                    ),
                }}
                sx={{
                    backgroundColor: theme.palette.background.paper,
                    mb: 0,
                    mt: 0,
                }}
            />
        </FormControl>
    );
};

const SelectSortBy = ({ sortBy, setSortBy }: any) => {
    const theme: Theme = useTheme();

    return (
        <FormControl fullWidth>
            <InputLabel id="sort-by-label">Sort By</InputLabel>
            <Select
                labelId="sort-by-label"
                label="Sort By"
                value={sortBy}
                size="medium"
                fullWidth
                onChange={e => setSortBy(e.target.value)}
                sx={{
                    backgroundColor: theme.palette.background.paper,
                    mb: 0,
                    mt: 0,
                }}
            >
                <MenuItem value="username">Username</MenuItem>
                <MenuItem value="email">Email</MenuItem>
                <MenuItem value="credit">Credit</MenuItem>
                <MenuItem value="fans">Fans</MenuItem>
                <MenuItem value="following">Following</MenuItem>
                <MenuItem value="ranking">Ranking</MenuItem>
                <MenuItem value="earnings">Earnings</MenuItem>
                <MenuItem value="isLoggedIn">Logged In</MenuItem>
                {/* <MenuItem value="category">Category</MenuItem> */}
            </Select>
        </FormControl>
    );
};

const SelectSortDirection = ({ sortDirection, setSortDirection }: any) => {
    const theme: Theme = useTheme();

    return (
        <FormControl fullWidth>
            <InputLabel id="sort-direction-label">Order</InputLabel>
            <Select
                labelId="sort-direction-label"
                label="Order"
                value={sortDirection}
                size="medium"
                fullWidth
                onChange={e => setSortDirection(e.target.value)}
                sx={{
                    backgroundColor: theme.palette.background.paper,
                    mb: 0,
                    mt: 0,
                }}
            >
                <MenuItem value="asc">Ascending</MenuItem>
                <MenuItem value="desc">Descending</MenuItem>
            </Select>
        </FormControl>
    );
};

const GroupByCategoryToggle = ({ groupByCategory, setGroupByCategory }: any) => {
    const theme: Theme = useTheme();

    return (
        <FormControl fullWidth>
            <ToggleButton
                color="primary"
                size="medium"
                value="check"
                selected={groupByCategory}
                onChange={() => setGroupByCategory(!groupByCategory)}
                sx={{
                    backgroundColor: theme.palette.background.paper,
                    mb: 0,
                    mt: 0,
                }}
            >
                Group By Category
            </ToggleButton>
        </FormControl>
    );
};

const SubscribersTableForLargeScreens = ({
    subscribers,
    setStats,
    refetchSubscribers,
    openTransferCreditDialog,
}: {
    subscribers: any[];
    setStats: any;
    refetchSubscribers: () => void;
    categoryName?: string;
    openTransferCreditDialog: (subscriber: any) => void;
}) => {
    return (
        <>
            {subscribers.length > 0 ? (
                <Card
                    sx={{
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        width: '100%',
                        mb: 2,
                    }}
                >
                    <div style={{ overflow: 'hidden' }}>
                        <TableContainer>
                            <Table sx={{ display: 'table', width: '100%', minWidth: '1230px' }} size="medium">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center"></TableCell>
                                        <TableCell align="center">Credit</TableCell>
                                        <TableCell align="center">Fans</TableCell>
                                        <TableCell align="center">Following</TableCell>
                                        <TableCell align="center">Ranking</TableCell>
                                        <TableCell align="center">Earnings</TableCell>
                                        <TableCell align="center">Services</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {subscribers.map((subscriber: any) => (
                                        <DashboardMyAccountsSubscriberCardLarge
                                            subscriber={subscriber}
                                            setStats={setStats}
                                            refetchSubscribers={refetchSubscribers}
                                            openTransferCreditDialog={openTransferCreditDialog}
                                            key={subscriber._id}
                                        />
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </Card>
            ) : null}
        </>
    );
};

const DashboardMyAccounts = () => {
    const userContext = useContext(UserContext);
    const { data: subscribers, isLoading: subscribersLoading, refetch: refetchSubscribers } = useSubscribers();
    const theme: Theme = useTheme();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));
    const [filter, setFilter] = useState('');
    const [sortBy, setSortBy] = useState('username');
    const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
    const [groupByCategory, setGroupByCategory] = useState(true);
    const [stats, setStats] = useState<any[]>([]);
    const [openManageCategoriesDialog, setOpenManageCategoriesDialog] = useState(false);
    const [transferCreditDialogOpen, setTransferCreditDialogOpen] = useState<boolean>(false);
    const [transferCreditSourceSubscriber, setTransferCreditSourceSubscriber] = useState<any>(null);

    useEffect(() => {
        if (!userContext.isAdmin && subscribers && Array.isArray(subscribers)) {
            // Create an empty array to store the stats with the subscriber ID as the key
            const newStats: any[] = [];

            // Loop through the subscribers and add the stats to the newStats array
            subscribers.forEach((subscriber: any) => {
                newStats[subscriber._id] = {};
            });

            setStats(newStats);
        }
    }, [subscribers, userContext.isAdmin]);

    const categoriesOfSortedSubscribers = useMemo(() => {
        if (subscribers && Array.isArray(subscribers) && subscribers.length > 0) {
            // Extract all the categories from the subscribers
            const groupedCategoriesNames: OnlyFansSubscriberCategory[] = [];

            subscribers.forEach((subscriber: any) => {
                if (
                    subscriber.cognito &&
                    'categoryDetails' in subscriber.cognito &&
                    subscriber.cognito.categoryDetails &&
                    subscriber.cognito.categoryDetails.length > 0 &&
                    !groupedCategoriesNames.includes(subscriber.cognito.categoryDetails[0].name)
                ) {
                    groupedCategoriesNames.push(subscriber.cognito.categoryDetails[0]);
                }
            });

            return groupedCategoriesNames;
        }

        return [];
    }, [subscribers]);

    const handleManageCategotiesDialogClosed = () => {
        setOpenManageCategoriesDialog(false);
    };

    const openTransferCreditDialog = (sourceSubscriber: any) => {
        setTransferCreditSourceSubscriber(sourceSubscriber);
        setTransferCreditDialogOpen(true);
    };

    return (
        <>
            <Typography variant="h6" sx={{ mb: 2 }}>
                My Accounts
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
                Select an account below to see the service status and options.
            </Typography>

            <Grid container spacing={1} alignItems="center" sx={{ mb: 2 }}>
                <Grid item xs={12} sm>
                    <FilterTextFiled filter={filter} setFilter={setFilter} />
                </Grid>
                <Grid item xs sm="auto">
                    <SelectSortBy sortBy={sortBy} setSortBy={setSortBy} />
                </Grid>
                <Grid item xs sm="auto">
                    <SelectSortDirection sortDirection={sortDirection} setSortDirection={setSortDirection} />
                </Grid>
                <Grid item xs={10} sm="auto">
                    <GroupByCategoryToggle groupByCategory={groupByCategory} setGroupByCategory={setGroupByCategory} />
                </Grid>
                <Grid item xs="auto" sm="auto">
                    <Tooltip title="Manage Categories">
                        <IconButton
                            color="primary"
                            size="large"
                            onClick={() => {
                                setOpenManageCategoriesDialog(true);
                            }}
                        >
                            <FolderCopyIcon />
                        </IconButton>
                    </Tooltip>
                </Grid>
            </Grid>

            {subscribersLoading ? (
                <Skeleton />
            ) : (
                <>
                    {subscribers && Array.isArray(subscribers) && subscribers.length > 0 ? (
                        <>
                            {groupByCategory ? (
                                <>
                                    {isLargeScreen ? (
                                        <SubscribersTableForLargeScreens
                                            subscribers={sortSubscribers(
                                                subscribers.filter(
                                                    (subscriber: any) =>
                                                        !subscriber.cognito ||
                                                        !subscriber.cognito.category ||
                                                        !subscriber.cognito.categoryDetails,
                                                ),
                                                stats,
                                                filter,
                                                sortBy,
                                                sortDirection,
                                            )}
                                            setStats={setStats}
                                            refetchSubscribers={refetchSubscribers}
                                            openTransferCreditDialog={openTransferCreditDialog}
                                        />
                                    ) : (
                                        <Grid container>
                                            {sortSubscribers(
                                                subscribers.filter(
                                                    (subscriber: any) =>
                                                        !subscriber.cognito ||
                                                        !subscriber.cognito.category ||
                                                        !subscriber.cognito.categoryDetails,
                                                ),
                                                stats,
                                                filter,
                                                sortBy,
                                                sortDirection,
                                            ).map((subscriber: any) => (
                                                <Grid item xs={12} sm={6} md={4} sx={{ mb: 1 }} key={subscriber._id}>
                                                    <DashboardMyAccountsSubscriberCard
                                                        subscriber={subscriber}
                                                        setStats={setStats}
                                                        refetchSubscribers={refetchSubscribers}
                                                        openTransferCreditDialog={openTransferCreditDialog}
                                                    />
                                                </Grid>
                                            ))}
                                        </Grid>
                                    )}

                                    {categoriesOfSortedSubscribers.map((category: OnlyFansSubscriberCategory) => (
                                        <React.Fragment key={category._id}>
                                            <Typography variant="h6" sx={{ mb: 2, mt: 2 }}>
                                                <Stack direction="row" alignItems="center" spacing={1}>
                                                    <SquareIcon
                                                        sx={{
                                                            ...(!category.color || category.color === 'none'
                                                                ? {
                                                                      color: theme.palette.background.paper,
                                                                      border: `1px solid ${theme.palette.divider}`,
                                                                  }
                                                                : {
                                                                      color: category.color,
                                                                  }),
                                                            mr: 1,
                                                        }}
                                                    />
                                                    {category.name}
                                                </Stack>
                                            </Typography>

                                            {isLargeScreen ? (
                                                <SubscribersTableForLargeScreens
                                                    subscribers={sortSubscribers(
                                                        subscribers.filter(
                                                            (subscriber: any) =>
                                                                subscriber.cognito &&
                                                                subscriber.cognito.category &&
                                                                subscriber.cognito.category === category._id,
                                                        ),
                                                        stats,
                                                        filter,
                                                        sortBy,
                                                        sortDirection,
                                                    )}
                                                    setStats={setStats}
                                                    refetchSubscribers={refetchSubscribers}
                                                    openTransferCreditDialog={openTransferCreditDialog}
                                                />
                                            ) : (
                                                <Grid container>
                                                    {sortSubscribers(
                                                        subscribers.filter(
                                                            (subscriber: any) =>
                                                                subscriber.cognito &&
                                                                subscriber.cognito.category &&
                                                                subscriber.cognito.category === category._id,
                                                        ),
                                                        stats,
                                                        filter,
                                                        sortBy,
                                                        sortDirection,
                                                    ).map((subscriber: any) => (
                                                        <Grid item xs={12} sm={6} md={4} sx={{ mb: 1 }} key={subscriber._id}>
                                                            <DashboardMyAccountsSubscriberCard
                                                                subscriber={subscriber}
                                                                setStats={setStats}
                                                                refetchSubscribers={refetchSubscribers}
                                                                openTransferCreditDialog={openTransferCreditDialog}
                                                            />
                                                        </Grid>
                                                    ))}
                                                </Grid>
                                            )}
                                        </React.Fragment>
                                    ))}
                                </>
                            ) : (
                                <>
                                    {isLargeScreen ? (
                                        <SubscribersTableForLargeScreens
                                            subscribers={sortSubscribers(subscribers, stats, filter, sortBy, sortDirection)}
                                            setStats={setStats}
                                            refetchSubscribers={refetchSubscribers}
                                            openTransferCreditDialog={openTransferCreditDialog}
                                        />
                                    ) : (
                                        <Grid container>
                                            {sortSubscribers(subscribers, stats, filter, sortBy, sortDirection).map((subscriber: any) => (
                                                <Grid item xs={12} sm={6} md={4} sx={{ mb: 1 }} key={subscriber._id}>
                                                    <DashboardMyAccountsSubscriberCard
                                                        subscriber={subscriber}
                                                        setStats={setStats}
                                                        refetchSubscribers={refetchSubscribers}
                                                        openTransferCreditDialog={openTransferCreditDialog}
                                                    />
                                                </Grid>
                                            ))}
                                        </Grid>
                                    )}
                                </>
                            )}

                            <DashboardMyAccountsManageCategoriesDialog
                                open={openManageCategoriesDialog}
                                onClose={handleManageCategotiesDialogClosed}
                            />
                        </>
                    ) : (
                        <Alert severity="warning">No accounts are currently setup.</Alert>
                    )}
                </>
            )}

            {transferCreditSourceSubscriber && (
                <SharedCreditTransferDialog
                    open={transferCreditDialogOpen}
                    onClose={() => {
                        setTransferCreditDialogOpen(false);
                    }}
                    sourceSubscriber={transferCreditSourceSubscriber}
                />
            )}

            <Grid container spacing={1} sx={{ marginBottom: theme.spacing(2) }}>
                <Grid item xs={12}>
                    <Alert severity="info">
                        If you don't see your account (or one of your accounts) on this list, please contact Admin.
                    </Alert>
                </Grid>
            </Grid>
        </>
    );
};

export default DashboardMyAccounts;
