import { Container, Typography } from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';
import { Theme } from '@mui/system';
import { GridSelectionModel } from '@mui/x-data-grid';
import { useConfirm } from 'material-ui-confirm';
import moment from 'moment-timezone';
import 'moment/locale/en-gb';
import { useDialog } from 'muibox';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import AlertCollapsable from '../../../components/common/AlertCollapsable';
import BackNavigationButton from '../../../components/common/BackNavigationButton';
import SextforceAssignTransactionsActionsBar from '../../../components/services/sextforce/assignTransactions/SextforceAssignTransactionsActionsBar';
import SextforceAssignTransactionsDataGrid from '../../../components/services/sextforce/assignTransactions/SextforceAssignTransactionsDataGrid';
import SextforceAssignTransactionsReviewProofDialog from '../../../components/services/sextforce/assignTransactions/SextforceAssignTransactionsReviewProofDialog';
import SextforceAssignTransactionsSearchBar from '../../../components/services/sextforce/assignTransactions/SextforceAssignTransactionsSearchBar';
import useDashboardAccount from '../../../hooks/useDashboardAccount';
import useSubscriber from '../../../hooks/useSubscriber';
import { SettingsContext } from '../../../store/SettingsContext';
import { UserContext } from '../../../store/UserContext';
import { handleHttpError, handleHttpErrorResponse } from '../../../utils/common';

type ReportParams = {
    timezone: string;
    dateFrom: Date | null;
    dateTo: Date | null;
    isFreeRange: boolean;
    filter: string;
    agent: string;
    filterOnlySaleProofs: string;
};

const SextforceAssignTransactions = () => {
    const userContext = useContext(UserContext);
    const settingsContext = useContext(SettingsContext);
    const { dashboardAccount, dashboardAccountLoading } = useDashboardAccount(true);
    const theme: Theme = useTheme();
    const dialog = useDialog();
    const params = useParams();
    const queryClient = useQueryClient();
    const confirm = useConfirm();
    const { data: subscriber, isFetching: subscriberLoading } = useSubscriber();

    const [agents, setAgents] = useState<any[]>([]);

    // Search Bar
    const [searchFreeRange, setSearchFreeRange] = useState<boolean>(false);
    const [searchWeek, setSearchWeek] = useState<number>(moment().week());
    const [searchDateFrom, setSearchDateFrom] = useState<Date>(moment().startOf('week').toDate());
    const [searchDateTo, setSearchDateTo] = useState<Date>(moment().endOf('week').toDate());
    const [searchLimitTime, setSearchLimitTime] = useState<boolean>(false);
    const [searchTimeFrom, setSearchTimeFrom] = useState<Date>(moment().startOf('week').toDate());
    const [searchTimeTo, setSearchTimeTo] = useState<Date>(moment().hours(0).minutes(0).toDate());
    const [searchFilter, setSearchFilter] = useState<string>('all');
    const [searchAgent, setSearchAgent] = useState<string>('');
    const [searchFilterOnlySaleProofs, setSearchFilterOnlySaleProofs] = useState<string>('all');
    const [searchTimezone, setSearchTimezone] = useState<string>(moment.tz.guess());

    // Report
    const [offset, setOffset] = useState<number>(0);
    const [limit, setLimit] = useState<number>(20);
    const [reportParams, setReportParams] = useState<ReportParams>({
        timezone: moment.tz.guess(),
        dateFrom: null,
        dateTo: null,
        isFreeRange: false,
        filter: 'all',
        agent: '',
        filterOnlySaleProofs: 'all',
    });
    const [reportSort, setReportSort] = useState<any[]>([{ field: 'createdAt', sort: 'desc' }]);
    const [timezone, setTimezone] = useState<string>(moment.tz.guess());
    const [reportResultsMessage, setReportResultsMessage] = useState<Array<JSX.Element | string>>([]);

    // Review Sales Proof
    const [reviewSalesProofDialogOpen, setReviewSalesProofDialogOpen] = useState<boolean>(false);
    const [reviewSalesProofTransaction, setReviewSalesProofTransaction] = useState<any | null>(null);
    const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);

    // Set global MomentJS locale
    moment.locale('en-gb');

    // Close Create Sales Proof Link dialog
    const handleCloseReviewSalesProofDialog = () => setReviewSalesProofDialogOpen(false);

    // Open Review Sales Proof Dialog
    const handleReviewSalesProofOpen = (transaction: any) => {
        setReviewSalesProofTransaction(transaction);
        setReviewSalesProofDialogOpen(true);
    };

    useEffect(() => {
        if (!dashboardAccountLoading && dashboardAccount && dashboardAccount.timezone) {
            setSearchTimezone(dashboardAccount.timezone);
        }
    }, [dashboardAccountLoading, dashboardAccount]);

    /**
     * Sextforce Agents
     */

    // Fetch Sextforce Agents
    useEffect(() => {
        if (userContext.jwtToken.length > 0 && settingsContext.apiKey.length > 0 && 'userId' in params) {
            fetch(`${settingsContext.routes.sextforce.base}${params.userId}/agents`, {
                method: 'get',
                headers: {
                    Authorization: userContext.jwtToken,
                    apiKey: settingsContext.apiKey,
                },
            })
                .then(async response => {
                    if (response.ok) {
                        setAgents(await response.json());
                    } else {
                        handleHttpErrorResponse(response, dialog);
                    }
                })
                .catch(error => {
                    console.error(error);
                    handleHttpError(error, dialog);

                    setAgents([]);
                });
        } else {
            setAgents([]);
        }
    }, [dialog, params, settingsContext.apiKey, settingsContext.routes.sextforce.base, userContext.jwtToken]);

    // Reset report when switching subscriber accounts
    useEffect(() => {
        setReportParams({
            timezone: moment.tz.guess(),
            dateFrom: null,
            dateTo: null,
            isFreeRange: false,
            filter: 'all',
            agent: '',
            filterOnlySaleProofs: 'all',
        });

        queryClient.setQueryData('transactions', null);
        queryClient.setQueryData('agents', null);
    }, [queryClient, subscriber]);

    // Fetch Report
    const fetchReport = async (reportParams: ReportParams, sort: any[], dataOffset: number, dataLimit: number): Promise<any> => {
        if (userContext.jwtToken && settingsContext.apiKey && 'userId' in params && params.userId && subscriber) {
            const query: string = `${settingsContext.routes.transactions.base}${params.userId}/anonymous?${new URLSearchParams({
                startDate: reportParams.dateFrom ? reportParams.dateFrom.toISOString() : '',
                endDate: reportParams.dateTo ? reportParams.dateTo.toISOString() : '',
                filter: reportParams.filter,
                filterOnlySaleProofs: reportParams.filterOnlySaleProofs,
                agent: reportParams.agent,
                offset: dataOffset.toString(),
                limit: dataLimit.toString(),
                ...(sort && sort.length > 0 && { sortField: sort[0].field, sort: sort[0].sort }),
            })}`;

            const data = await fetch(query, {
                method: 'get',
                headers: {
                    Authorization: userContext.jwtToken,
                    apiKey: settingsContext.apiKey,
                },
            })
                .then(async response => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        handleHttpErrorResponse(response, dialog);
                    }
                })
                .catch(error => {
                    console.error(error);
                    handleHttpError(error, dialog);
                });

            return data;
        }

        return { rows: [], metadata: { total: 0 } };
    };

    const {
        data: transactions,
        isLoading: transactionsLoading,
        refetch: refetchReport,
    } = useQuery(
        ['transactions', reportParams, reportSort, offset, limit, subscriber],
        () => {
            if (reportParams.dateFrom && reportParams.dateTo) {
                return fetchReport(reportParams, reportSort, offset, limit);
            }
        },
        {
            refetchOnWindowFocus: false,
            refetchOnMount: false,
            refetchOnReconnect: false,
            staleTime: 0,
            enabled:
                userContext.jwtToken &&
                settingsContext.apiKey &&
                'userId' in params &&
                params.userId &&
                reportParams.dateFrom &&
                reportParams.dateTo
                    ? true
                    : false,
        },
    );

    // Retrieve the sales report from the server
    const requestReport = useCallback(async () => {
        const prepareParams = async () => {
            // Reset the offset
            setOffset(0);

            const calculatedMainDateFrom: Date = searchLimitTime
                ? moment(searchDateFrom).hours(searchTimeFrom.getHours()).minutes(searchTimeFrom.getMinutes()).toDate()
                : searchDateFrom;
            const calculatedMainDateTo: Date = searchLimitTime
                ? moment(searchDateTo).hours(searchTimeTo.getHours()).minutes(searchTimeTo.getMinutes()).toDate()
                : searchDateTo;

            // Update report date range
            setReportParams({
                timezone: searchTimezone,
                dateFrom: moment(calculatedMainDateFrom).tz(searchTimezone, true).toDate(),
                dateTo: moment(calculatedMainDateTo).tz(searchTimezone, true).toDate(),
                isFreeRange: searchFreeRange,
                filter: searchFilter,
                agent: searchAgent,
                filterOnlySaleProofs: searchFilterOnlySaleProofs,
            });

            const resultsMessage: Array<JSX.Element | string> = [];

            if (searchFreeRange) {
                resultsMessage.push(' Results for');
            } else {
                resultsMessage.push(`Results for Week ${moment(searchDateFrom).week()} `);
            }

            resultsMessage.push(<br key={1} />);

            if (searchDateFrom && searchDateTo) {
                resultsMessage.push(
                    <React.Fragment key={2}>
                        {searchDateFrom.toLocaleDateString()} {searchDateFrom.toLocaleTimeString(undefined, { hour12: true })} -{' '}
                        {searchDateTo.toLocaleDateString()} {searchDateTo.toLocaleTimeString(undefined, { hour12: true })}
                    </React.Fragment>,
                );
            } else {
                resultsMessage.push('No Dates Selected');
            }

            setReportResultsMessage(resultsMessage);
        };

        await prepareParams();
        // console.log('refetching report');
        // refetchReport();
    }, [
        searchLimitTime,
        searchDateFrom,
        searchTimeFrom,
        searchDateTo,
        searchTimeTo,
        searchTimezone,
        searchFreeRange,
        searchFilter,
        searchAgent,
        searchFilterOnlySaleProofs,
        // refetchReport,
    ]);

    /**
     * Update transaction when assigning an agent
     */
    type TransactionUpdateDetails = {
        _id: string;
        assignedAgent: string;
        proofId: string | null;
        overrideCommission: boolean;
        commissionPercentOverride: number | null;
    };

    const updateTransaction = async (transactionUpdateDetails: TransactionUpdateDetails[]) => {
        if (userContext.jwtToken && settingsContext.apiKey && 'userId' in params && params.userId) {
            const query: string = `${settingsContext.routes.transactions.base}${params.userId}/anonymous`;

            return fetch(query, {
                method: 'put',
                headers: {
                    Authorization: userContext.jwtToken,
                    apiKey: settingsContext.apiKey,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(transactionUpdateDetails),
            })
                .then(async response => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        handleHttpErrorResponse(response, dialog);
                    }
                })
                .catch(error => {
                    console.error(error);
                    handleHttpError(error, dialog);
                });
        }

        return null;
    };

    const mutateTransaction = useMutation(updateTransaction, {
        onSuccess: updatedTransactions => {
            if (updatedTransactions && Array.isArray(updatedTransactions) && updatedTransactions.length > 0) {
                const newTransactions = {
                    rows: [...transactions.rows],
                    metadata: {
                        total: transactions.metadata.total,
                    },
                };

                updatedTransactions.forEach(data => {
                    // Find the transaction that was just updated, and update it in the transactions array
                    let found: boolean = false;

                    for (let i = 0; i < newTransactions.rows.length && !found; i += 1) {
                        if (newTransactions.rows[i]._id === data._id) {
                            const salesProof = newTransactions.rows[i].salesProof;

                            // Check if the returned transaction record includes sales proof information
                            if ('salesProof' in data && data.salesProof) {
                                // If it does, keep the transaction data as is
                                newTransactions.rows[i] = data;
                            } else {
                                // If not, add the sales proof data from before the transaction was updated
                                newTransactions.rows[i] = {
                                    ...data,
                                    salesProof,
                                };
                            }
                        }
                    }
                });

                // Update the transactions cache and rerender components
                queryClient.setQueryData(['transactions', reportParams, reportSort, offset, limit, subscriber], newTransactions);
            }
        },
        onError: error => {
            dialog.alert((error as any).message as string);
        },
    });

    const handleApproveBulkTransaction = useCallback(
        (transactionIds: string[]) => {
            const doUpdate = () => {
                const transactionUpdateDetails: TransactionUpdateDetails[] = [];

                transactionIds.forEach(transactionId => {
                    const transaction = transactions.rows.find((item: any) => item._id === transactionId);

                    if (
                        transaction &&
                        transaction.salesProof &&
                        transaction.salesProof.length === 1 &&
                        !transaction.salesProof[0].assignedTransaction
                    ) {
                        transactionUpdateDetails.push({
                            _id: transaction._id,
                            assignedAgent: transaction.salesProof[0].agentId,
                            proofId: transaction.salesProof[0]._id,
                            overrideCommission: false,
                            commissionPercentOverride: null,
                        });
                    }
                });

                mutateTransaction.mutate(transactionUpdateDetails);
            };

            // Update
            doUpdate();
        },
        [mutateTransaction, transactions],
    );

    const handleUpdateSingleTransaction = useCallback(
        (
            _id: string,
            assignedAgent: string,
            salesProofs: any[] | undefined,
            showWarning: boolean,
            proofId: string | null,
            overrideCommission: boolean,
            commissionPercentOverride: number | null,
        ) => {
            const doUpdate = () => {
                const newData: TransactionUpdateDetails[] = [
                    { _id, assignedAgent, proofId, overrideCommission, commissionPercentOverride },
                ];

                mutateTransaction.mutate(newData);
            };

            // Check if there are any sales proofs matching to this transaction
            if (showWarning && salesProofs && salesProofs.length > 0) {
                confirm({
                    title: 'Assign New Agent',
                    description: 'This transaction has a proof of sale. Are you sure you want to assign an agent manually?',
                })
                    .then(() => {
                        // Update
                        doUpdate();
                    })
                    .catch(() => {});
            } else {
                // Update
                doUpdate();
            }
        },
        [confirm, mutateTransaction],
    );

    return (
        <Container
            maxWidth={false}
            sx={{ paddingTop: theme.spacing(4), paddingBottom: theme.spacing(4), backgroundColor: '#e7ebf0', minHeight: '100%' }}
        >
            <div style={{ paddingBottom: theme.spacing(2) }}>
                <Typography variant="h5">
                    <BackNavigationButton
                        {...(subscriber && { url: `/subscribers/${subscriber._id}/${settingsContext.services.sextforce.homeUrl}` })}
                    />{' '}
                    Assign Annonymous Sales for {subscriber?.username}
                    {reportParams.isFreeRange || !reportParams.dateFrom ? '' : ` - Week ${moment(reportParams.dateFrom).week()}`}
                </Typography>
            </div>
            <AlertCollapsable
                openInitially={false}
                title="How It Works"
                variant="filled"
                severity="info"
                sx={{
                    marginBottom: theme.spacing(2),
                }}
            >
                <p>
                    Anonymous transactions are ones which end in X.00 and hence are not automatically assigned to an Agent. Use the search
                    to show a list of transactions that need reviewing. Use the dropdown list to the right of the transaction to select the
                    Agent to assign the sale to. The sale will automatically get added to the Agent's totals and commission which is also
                    reflect in the Sextforce Sales Report
                </p>
                <p>
                    Agents can be located anywhere in the world, you can choose to see the transactions time in the time zone they are at by
                    selecting their time zone from the "Translate transactions time to time zone" dropdown list. By default, transaction
                    time is shown in your auto-detected time zone.
                </p>
                <p>
                    For efficient workflow, have the Agents submit a proof of sale using the Agent's unique link. A transaction that matches
                    a submitted sales proof will have an amber{' '}
                    <Typography variant="body1" component="span" sx={{ color: theme.palette.warning.main, display: 'inline' }}>
                        REVIEW
                    </Typography>{' '}
                    button and a number of sales proofs found that matched this transaction next to the button.
                </p>
                <p>
                    Click the REVIEW button to see the details of the sale proof. Approving the sale proof automatically assigns the sale to
                    the Agent that submitted the sale proof and turns the REVIEW button green. Note: You can still re-assign the transaction
                    from the drop-down list which will override the reviewed sale proof. What determines who gets the commission is the name
                    in the dropdown list.
                </p>
                <p>
                    When reviewing the sale proof, you can choose to use the Agent's default commission for this sale, or override it and
                    enter any other percentage which will only be applied to this specific transaction. The new commission will appear in
                    amber in the Commission column while the Agent's default commission will appear in black. NOTE: Changing the assigned
                    agent manually, will reset the commission to the agent's default.
                </p>
                <p>
                    You can also use the APPROVE SELECTED and the APPROVE ALL VISIBLE buttons to bulk-approve sale proofs. Use the tick
                    boxes next to each transaction with a sale proof to select the sale proofs to be bulk-approved using the APPROVE
                    SELECTED button; or click on APPROVE ALL VISIBLE to bulk-approve all the yet-to-be-approved sale proofs on the current
                    page. NOTE: Only transactions that have <strong>one</strong> sale proof can be bulk-approved or selected, and only
                    transactions with a sale proof that has not been approved so far.
                </p>
            </AlertCollapsable>

            <SextforceAssignTransactionsSearchBar
                timezone={searchTimezone}
                setTimezone={setSearchTimezone}
                mainSearch={{
                    freeRange: searchFreeRange,
                    setFreeRange: setSearchFreeRange,
                    week: searchWeek,
                    setWeek: setSearchWeek,
                    dateFrom: searchDateFrom,
                    setDateFrom: setSearchDateFrom,
                    dateTo: searchDateTo,
                    setDateTo: setSearchDateTo,
                    limitTime: searchLimitTime,
                    setLimitTime: setSearchLimitTime,
                    timeFrom: searchTimeFrom,
                    setTimeFrom: setSearchTimeFrom,
                    timeTo: searchTimeTo,
                    setTimeTo: setSearchTimeTo,
                }}
                filter={searchFilter}
                setFilter={setSearchFilter}
                agent={searchAgent}
                setAgent={setSearchAgent}
                filterOnlySaleProofs={searchFilterOnlySaleProofs}
                setFilterOnlySaleProofs={setSearchFilterOnlySaleProofs}
                isFetchingReport={transactionsLoading}
                agents={agents ? agents : []}
                requestReport={requestReport}
            />

            <Typography variant="subtitle1" sx={{ paddingBottom: theme.spacing(2) }}>
                {reportResultsMessage}
            </Typography>

            <SextforceAssignTransactionsActionsBar
                transactions={transactions}
                timezone={timezone}
                transactionsLoading={transactionsLoading}
                setTimezone={setTimezone}
                selectionModel={selectionModel}
                handleApproveBulkTransaction={handleApproveBulkTransaction}
            />

            <SextforceAssignTransactionsDataGrid
                transactions={transactions}
                limit={limit}
                setLimit={setLimit}
                offset={offset}
                setOffset={setOffset}
                reportSort={reportSort}
                setReportSort={setReportSort}
                transactionsLoading={transactionsLoading}
                timezone={timezone}
                setTimezone={setTimezone}
                agents={agents}
                handleReviewSalesProofOpen={handleReviewSalesProofOpen}
                handleUpdateSingleTransaction={handleUpdateSingleTransaction}
                selectionModel={selectionModel}
                setSelectionModel={setSelectionModel}
            />

            <SextforceAssignTransactionsReviewProofDialog
                open={reviewSalesProofDialogOpen}
                onCancel={handleCloseReviewSalesProofDialog}
                onApprove={handleUpdateSingleTransaction}
                transaction={reviewSalesProofTransaction}
                timezone={timezone}
                agents={agents}
            />
        </Container>
    );
};

export default SextforceAssignTransactions;
