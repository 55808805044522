import AccountBoxIcon from '@mui/icons-material/AccountBox';
import CommentIcon from '@mui/icons-material/Comment';
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Grid,
    IconButton,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Theme,
    Tooltip,
    Typography,
} from '@mui/material';
import dinero from 'dinero.js';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { SextforceAutoCampaignTipReply } from '../../../../hooks/useSextforceAutoCampaignTipReply';
import useSextforceAutoCampaignTipReplyLogs from '../../../../hooks/useSextforceAutoCampaignTipReplyLogs';
import StyledCardTitleBar from '../../../common/StyledCardTitleBar';

type Props = {
    subscriber: any;
    autoCampaignTipReply: SextforceAutoCampaignTipReply;
    amount: number | null;
    open: boolean;
    onClose: () => void;
    theme: Theme;
};

const SextforceAutoCampaignTipReplyTippersListDialog = (props: Props) => {
    const { subscriber, autoCampaignTipReply, amount, open, onClose, theme } = props;

    const { data: logs, isLoading: isLogsLoading } = useSextforceAutoCampaignTipReplyLogs(autoCampaignTipReply._id, amount);

    const handleClose = (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => {
        if (reason && reason === 'backdropClick') {
            return;
        }

        onClose();
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth disableEscapeKeyDown scroll="paper">
            <StyledCardTitleBar
                title={`Auto Tip Campaign Tippers${!isLogsLoading && logs && ` (${logs.length.toLocaleString()})`}`}
                theme={theme}
            />
            <DialogContent>
                <DialogContentText>
                    The following users have tipped the campaign <strong>{autoCampaignTipReply.name}</strong>
                    {amount && (
                        <span>
                            {' '}
                            for amount <strong>{dinero({ amount: Math.trunc(amount * 100), currency: 'USD' }).toFormat()}</strong>
                        </span>
                    )}
                    .
                </DialogContentText>
                <Grid container alignItems={'center'} justifyContent={'center'} spacing={1} sx={{ mt: 1 }}>
                    {isLogsLoading && (
                        <Grid item xs={12} textAlign="center">
                            <Stack direction={'column'} spacing={1}>
                                <CircularProgress />
                                <Typography variant="body1">Loading...</Typography>
                            </Stack>
                        </Grid>
                    )}
                    {!isLogsLoading && logs && logs.length === 0 && (
                        <Grid item xs={12} textAlign="center">
                            <Typography variant="body1" color={theme.palette.text.disabled}>
                                No tippers found
                            </Typography>
                        </Grid>
                    )}
                    {!isLogsLoading && logs && logs.length > 0 && (
                        <Grid item xs={12}>
                            <Box sx={{ overflowX: 'auto' }}>
                                <Table
                                    width="100%"
                                    sx={{
                                        // no left/right padding on the first and last cells
                                        '& td:first-of-type, & th:first-of-type': {
                                            paddingLeft: 0,
                                        },
                                        // Bold header
                                        '& th': {
                                            fontWeight: 'bold',
                                        },
                                        // No wrapping
                                        whiteSpace: 'nowrap',
                                        // make last column sticky
                                        '& th, & td': {
                                            '&:last-child': {
                                                position: 'sticky',
                                                right: 0,
                                                backgroundColor: theme.palette.background.paper,
                                            },
                                        },
                                    }}
                                >
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Date</TableCell>
                                            <TableCell>User ID</TableCell>
                                            <TableCell>Username</TableCell>
                                            <TableCell>Name</TableCell>
                                            <TableCell>Tip</TableCell>
                                            <TableCell align="center">Actions</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {logs.map((log, index) => (
                                            <TableRow key={index}>
                                                <TableCell>{moment(log.notificationDate).format('L LT')}</TableCell>
                                                <TableCell align="right">{log.user.id}</TableCell>
                                                <TableCell>{log.user.username}</TableCell>
                                                <TableCell>{log.user.name}</TableCell>
                                                <TableCell align="right">
                                                    {dinero({ amount: Math.trunc(log.tipAmount * 100), currency: 'USD' }).toFormat()}
                                                </TableCell>
                                                <TableCell align="center">
                                                    <Stack direction={'row'} spacing={0.5}>
                                                        <Tooltip title="Go to message in OnlyFans chat" arrow placement="top">
                                                            <IconButton
                                                                component={Link}
                                                                to={
                                                                    log.messageId
                                                                        ? `https://onlyfans.com/my/chats/chat/${log.user.id}?firstId=${log.messageId}`
                                                                        : ''
                                                                }
                                                                target="_blank"
                                                                disabled={!log.messageId}
                                                                color="primary"
                                                            >
                                                                <CommentIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                        <Tooltip title="Go to user's profile" arrow placement="top">
                                                            <IconButton
                                                                component={Link}
                                                                to={`https://onlyfans.com/${log.user.username}`}
                                                                target="_blank"
                                                                color="secondary"
                                                            >
                                                                <AccountBoxIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Stack>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Box>
                        </Grid>
                    )}
                </Grid>
            </DialogContent>
            <DialogActions
                sx={{
                    backgroundColor: theme.palette.grey[100],
                }}
            >
                <Button
                    color="error"
                    size="medium"
                    onClick={() => {
                        handleClose({}, 'escapeKeyDown');
                    }}
                >
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default SextforceAutoCampaignTipReplyTippersListDialog;
