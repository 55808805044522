import { Link } from 'react-router-dom';
import { Typography } from '@mui/material';
import { Theme } from '@mui/system';

type SextforceServiceDescriptionProps = {
    theme: Theme;
};

const SextforceServiceDescription = (props: SextforceServiceDescriptionProps) => {
    const { theme } = props;

    return (
        <>
            <Typography variant="body1" sx={{ marginBottom: theme.spacing(2) }}>
                The system automatically tracks sales, calculated commission, generates live reports, and measures performance! Everything
                is done using a clean and friendly dashboard website.
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: theme.spacing(2) }}>
                No more clocking in and out! Assistants, called <strong>Sextforce Agents</strong>, are tracked using their unique sale
                identifier. Multiple Agents can work concurrently on the same account without clashing! Commission is calculated
                automatically based on the Agent's default commission rate. Commission can also be overridden on a sale level (if you have a
                different rate for selling certain content as opposed to tips, for example).
            </Typography>

            <Typography variant="body1" sx={{ marginBottom: theme.spacing(2) }}>
                Sales of existing content and products are not tracked automatically - these are called <strong>Anonymous Sales</strong>.
                Instead, a simple and elegant "sale proof" mechanism is used. Each Agent has gets a unique link for submitting a screenshot
                sale proof. The Agents' submissions are matched to the accounts' sales transactions and await the account owner's approval
                of the sale proof. Once approved, the sale is assigned to the Agent and commission is calculated automatically.
            </Typography>

            <Typography variant="body1" sx={{ marginBottom: theme.spacing(2) }}>
                Full support throughout for multiple time zones! It doesn't matter if your Agent is on the other side of the globe, the
                system adjusts everything automatically and allows you to view all the data in your local and the Agent's time zone.
            </Typography>

            <Typography variant="body1" sx={{ marginBottom: theme.spacing(2) }}>
                Reports are generated live which means that, for example, updating an Agent's commission rate, automatically updates the
                sales report calculations. Every report is a snapshot of the current settings.
            </Typography>

            <Typography variant="body1" sx={{ marginBottom: theme.spacing(2) }}>
                Reports include, sales overview by account, sales and commission by Agent, share of sales by Agent, top spenders, and a
                special report that tracks <strong>how many fans are online on the website and how that correlates to sales</strong>!!! You
                can see the best time to be active on the website and when to schedule your Agents shifts. Compare reports week vs. week,
                month vs. month, or any date range to track performance. More reports are added regularly based on clients feedback.
            </Typography>

            <Typography variant="h6" sx={{ marginBottom: theme.spacing(2) }}>
                <strong>Features</strong>
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: theme.spacing(2) }}>
                <ul>
                    <li style={{ marginBottom: theme.spacing(1) }}>Supports up to 99 Agents per account</li>
                    <li style={{ marginBottom: theme.spacing(1) }}>
                        Setup default commission rate for each Agent, or override commission per sale
                    </li>
                    <li style={{ marginBottom: theme.spacing(1) }}>Elegant "Sale Proof" solution for Anonymous Sales</li>
                    <li style={{ marginBottom: theme.spacing(1) }}>Support for multi-time-zone operation!</li>
                    <li style={{ marginBottom: theme.spacing(1) }}>Track account sales performance</li>
                    <li style={{ marginBottom: theme.spacing(1) }}>Track Agents sales performance</li>
                    <li style={{ marginBottom: theme.spacing(1) }}>See when fans are most active on the website</li>
                    <li style={{ marginBottom: theme.spacing(1) }}>Friendly dashboard user interface</li>
                    <li style={{ marginBottom: theme.spacing(1) }}>Evolving reports and analytics</li>
                </ul>
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: theme.spacing(2) }}>
                You can see some feedback in my{' '}
                <Link
                    to="https://t.me/+4o6EKLNYq3M0NjRk"
                    target={'_blank'}
                    rel="noreferrer"
                    style={{ textDecoration: 'none', fontWeight: 'bold', color: theme.palette.primary.main }}
                >
                    Telegram review group
                </Link>
                .
            </Typography>
        </>
    );
};

export default SextforceServiceDescription;
