import { useDialog } from 'muibox';
import { useContext } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { SettingsContext } from '../store/SettingsContext';
import { UserContext } from '../store/UserContext';
import { handleHttpError } from '../utils/common';
import useAxios from './useAxios';

export interface SextforceLiveStreamTopTipper {
    user: { id: number; username: string; name?: string };
    streamTotal: number;
    tipsTotal: number;
}

const useSextforceLiveStreamAddTippersToListTopTippers = (streamId: number | undefined | null) => {
    const userContext = useContext(UserContext);
    const settingsContext = useContext(SettingsContext);
    const dialog = useDialog();
    const params = useParams();
    const axios = useAxios();

    // Fetch the restricted words
    const fetchLiveStreamTippers = async (): Promise<SextforceLiveStreamTopTipper[]> => {
        if (userContext.jwtToken && settingsContext.apiKey && userContext.user.username) {
            const query: string = `${settingsContext.routes.sextforce.base}${params.userId}/liveStream/addTippersToList/${streamId}/tippers/top`;

            return axios
                .get(query)
                .then(response => response.data as SextforceLiveStreamTopTipper[])
                .catch(error => {
                    console.error(error);
                    handleHttpError(error, dialog);

                    return [];
                });
        }

        return [];
    };

    const liveStreams = useQuery(
        ['liveStreamTopTippers', userContext.jwtToken, params.userId, streamId],
        () => {
            return fetchLiveStreamTippers();
        },
        {
            refetchOnWindowFocus: false,
            // Stake time 5 minutes
            staleTime: 5 * 60 * 1000,
            enabled:
                userContext.jwtToken && settingsContext.apiKey && userContext.user.username && params.userId && streamId ? true : false,
        },
    );

    return {
        ...liveStreams,
    };
};

export default useSextforceLiveStreamAddTippersToListTopTippers;
