import { FormControl, InputAdornment, InputLabel, OutlinedInput } from '@mui/material';
import { useEffect, useState } from 'react';
import { OnlyFansSubscriberHostessAutoReplyFreeloaders } from '../../../../types/hostessAutoReplyFreeloaders';

const FormSpentThresholdAmount = ({
    formData,
    setFormData,
    autoReplyFreeloaders,
}: {
    formData: OnlyFansSubscriberHostessAutoReplyFreeloaders;
    setFormData: (formData: OnlyFansSubscriberHostessAutoReplyFreeloaders) => void;
    autoReplyFreeloaders: OnlyFansSubscriberHostessAutoReplyFreeloaders | null;
}) => {
    const [spentThresholdString, setSpentThresholdString] = useState<string>(
        autoReplyFreeloaders ? autoReplyFreeloaders.spentThreshold.toString() : '0',
    );

    useEffect(() => {
        if (autoReplyFreeloaders) {
            setSpentThresholdString(autoReplyFreeloaders.spentThreshold.toString());
        } else {
            setSpentThresholdString('0');
        }
    }, [autoReplyFreeloaders]);

    return (
        <FormControl fullWidth>
            <InputLabel htmlFor="amount">Amount</InputLabel>
            <OutlinedInput
                id="amount"
                size="medium"
                inputProps={{ inputMode: 'text', pattern: /([0-9]+([.][0-9]*)?|[.][0-9]+)/ }}
                value={spentThresholdString}
                onChange={e => {
                    e.preventDefault();

                    if (/^([\d]*[,.]?[\d]{0,2})$/.test(e.target.value)) {
                        const parsed = parseFloat(e.target.value.replaceAll(',', '.'));

                        if (isNaN(parsed) || parsed === 0) {
                            setFormData({ ...formData, spentThreshold: 0 });
                            setSpentThresholdString('0');
                        } else {
                            setFormData({ ...formData, spentThreshold: parsed });
                            setSpentThresholdString(e.target.value.replace(/^0+/, ''));
                        }
                    }
                }}
                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                label="Amount"
            />
        </FormControl>
    );
};

export default FormSpentThresholdAmount;
