import { DialogContent, DialogContentProps, styled } from '@mui/material';

const DialogContentAdaptive = styled((props: DialogContentProps) => <DialogContent {...props} />)(({ theme }) => ({
    // is small screen?
    [theme.breakpoints.down('sm')]: {
        padding: 2,
        '& .MuiDialogContentText-root': {
            marginBottom: 2,
        },
    },
}));

export default DialogContentAdaptive;
