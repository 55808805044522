import axios from 'axios';
import moment from 'moment';
import { useDialog } from 'muibox';
import { useContext } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { SettingsContext } from '../store/SettingsContext';
import { UserContext } from '../store/UserContext';
import { handleHttpError } from '../utils/common';

const useSextforceMetricsClaimsCountToday = (metricId: string, metricType: string, startDate: Date, endDate: Date, timezone: string) => {
    const userContext = useContext(UserContext);
    const settingsContext = useContext(SettingsContext);
    const dialog = useDialog();
    const params = useParams();

    // Fetch Fans Counters
    const fetchData = async () => {
        const query: string = `${settingsContext.routes.metrics.base}${params.userId}/${
            metricType === 'promoCampaign' ? 'campaignsSubscribedToday' : 'trialsClaimedToday'
        }/${metricId}?${new URLSearchParams({
            startDate: moment(startDate).tz(timezone, true).toISOString(),
            endDate: moment(endDate).tz(timezone, true).toISOString(),
        })}`;

        const data = await axios
            .get(query, {
                headers: {
                    Authorization: userContext.jwtToken,
                    apiKey: settingsContext.apiKey,
                },
            })
            .then(async response => response.data as { metricId: string; claimedToday: number | null })
            .catch(error => {
                console.error(error);
                handleHttpError(error, dialog);

                return { metricId, claimedToday: null };
            });

        return data;
    };

    return useQuery(['metricsClaimsCountToday', params.userId, metricId, startDate, endDate, timezone], () => fetchData(), {
        refetchOnWindowFocus: false,
        // 5 minutes
        staleTime: 60 * 1000 * 5,
        enabled: userContext.jwtToken && settingsContext.apiKey && params.userId ? true : false,
    });
};

export default useSextforceMetricsClaimsCountToday;
