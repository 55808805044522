import { Alert, Chip, Grid, Stack, Typography } from '@mui/material';
import dinero from 'dinero.js';
import moment from 'moment';
import { OnlyFansPosts } from '../../../../hooks/useSubscriberPosts';

const SextforceAutoCampaignTipReplyPostPreview = ({ post }: { post: OnlyFansPosts.List }) => {
    // console.log(post.fundRaising?.presets.map(preset => parseFloat(preset)));
    // console.log(
    //     post.fundRaising?.presets.map(preset => dinero({ amount: Math.trunc(parseFloat(preset) * 100), currency: 'USD' }).toFormat()),
    // );

    return (
        <Alert icon={false} severity="success">
            <Grid container alignItems="center" spacing={1}>
                <Grid item xs={12}>
                    <Typography variant="subtitle1">Post Preview</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body2">{post.rawText}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Stack direction="column" spacing={0}>
                        <div>Posted At: {moment(post.postedAt).format('LLL')}</div>
                        <div>Expired At: {post.expiredAt ? moment(post.expiredAt).format('LLL') : 'Does not expire'}</div>
                    </Stack>
                </Grid>
                {post.fundRaising && post.fundRaising.presets.length > 0 && (
                    <Grid item xs={12}>
                        Tip Options:{' '}
                        {post.fundRaising?.presets.map(preset => (
                            <Chip
                                color="success"
                                label={dinero({ amount: Math.trunc(parseFloat(preset) * 100), currency: 'USD' }).toFormat()}
                                key={preset}
                            />
                        ))}
                    </Grid>
                )}
            </Grid>
        </Alert>
    );
};

export default SextforceAutoCampaignTipReplyPostPreview;
