import { styled, Switch } from '@mui/material';

// Create a custom style for a very large Switch
export const LargeMaterialUISwitch = styled(Switch)(({ theme, checked }) => ({
    width: 87,
    height: 51,
    padding: 7,
    '& .MuiSwitch-switchBase': {
        margin: 1,
        padding: 0,
        transform: 'translateX(6px)',
        '&.Mui-checked': {
            color: '#fff',
            transform: 'translateX(38px)',
        },
    },
    '& .MuiSwitch-thumb': {
        width: 48,
        height: 48,
        backgroundColor: checked ? theme.palette.success.dark : theme.palette.warning.light,
        // Write "on" and "off" on the thumb
        '&::after': {
            content: checked ? '"ON"' : '"OFF"',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            textAlign: 'center',
            color: checked ? theme.palette.common.white : theme.palette.common.black,
            fontSize: 16,
            fontWeight: 'bold',
            lineHeight: '48px',
        },
    },
    '& .MuiSwitch-track': {
        borderRadius: 48 / 2,
    },
}));

export default LargeMaterialUISwitch;
