import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { Theme } from '@mui/system';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Tabs, Tab, useTheme, Skeleton } from '@mui/material';
import ProfileDialogBillingDetails from './ProfileDialogBillingDetails';
import ProfileDialogAccount from './ProfileDialogAccount';
import ProfileDialogChangePassword from './ProfileDialogChangePassword';
import useDashboardAccount from '../../hooks/useDashboardAccount';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';

type CustomerProfileFormProps = {
    open: boolean;
    handleClose: () => void;
};

const ProfileDialog: React.FC<CustomerProfileFormProps> = ({ open, handleClose }) => {
    const { dashboardAccount, dashboardAccountLoading, dashboardAccountSave, dashboardAccountIsSaveing } = useDashboardAccount(open);
    const theme: Theme = useTheme();
    const [tab, setTab] = useState(0);

    const [timezone, setTimezone] = useState<string>(moment.tz.guess());

    const [billingName, setBillingName] = useState('');
    const [billingAddress, setBillingAddress] = useState('');
    const [billingCity, setBillingCity] = useState('');
    const [billingCounty, setBillingCounty] = useState('');
    const [billingPostcode, setBillingPostcode] = useState('');
    const [billingCountry, setBillingCountry] = useState('');
    const [billingIsCompany, setBillingIsCompany] = useState(false);
    const [billingCompanyName, setBillingCompanyName] = useState('');
    const [billingVatNumber, setBillingVatNumber] = useState('');
    const [isCompanyVatNumberValid, setIsCompanyVatNumberValid] = useState(false);

    // Reset tab to 0 when dialog is opened
    useEffect(() => {
        setTab(0);
    }, [open]);

    // Load account data when dashboard data is loaded
    useEffect(() => {
        if (dashboardAccount && dashboardAccount.timezone) {
            setTimezone(dashboardAccount.timezone);
        }

        const { financial } = dashboardAccount || {};

        setBillingName((financial && financial.billingName) || '');
        setBillingAddress((financial && financial.billingAddress) || '');
        setBillingCity((financial && financial.billingCity) || '');
        setBillingCounty((financial && financial.billingCounty) || '');
        setBillingPostcode((financial && financial.billingPostcode) || '');
        setBillingCountry((financial && financial.billingCountry) || '');
        setBillingIsCompany((financial && financial.billingIsCompany) || false);
        setBillingCompanyName((financial && financial.billingCompanyName) || '');
        setBillingVatNumber((financial && financial.billingVatNumber) || '');
    }, [dashboardAccount]);

    const handleSubmit = () => {
        // Handle form submission
        dashboardAccountSave(
            {
                timezone,
                financial: {
                    billingName: billingName.trim(),
                    billingAddress: billingAddress.trim(),
                    billingCity: billingCity.trim(),
                    billingCounty: billingCounty.trim(),
                    billingPostcode: billingPostcode.trim(),
                    billingCountry: billingCountry,
                    billingIsCompany: billingIsCompany,
                    billingCompanyName: billingCompanyName.trim(),
                    billingVatNumber: billingVatNumber.length > 0 ? `${billingCountry}${billingVatNumber}` : '',
                },
            },
            handleClose,
        );
    };

    return (
        <Dialog maxWidth={'sm'} fullWidth open={open} onClose={handleClose}>
            <DialogTitle sx={{ backgroundColor: theme.palette.grey[200], marginBottom: theme.spacing(2) }}>
                <Tabs value={tab} onChange={(e, newValue) => setTab(newValue)}>
                    <Tab label="Account" value={0} disableRipple />
                    <Tab label="Billing Details" value={1} disableRipple />
                    <Tab label="Change Password" value={2} disableRipple />
                </Tabs>
            </DialogTitle>
            <DialogContent>
                {dashboardAccountLoading ? (
                    <Skeleton />
                ) : (
                    <>{tab === 0 && <ProfileDialogAccount timezone={timezone} setTimezone={setTimezone} />}</>
                )}
                {dashboardAccountLoading ? (
                    <Skeleton />
                ) : (
                    <>
                        {tab === 1 && (
                            <ProfileDialogBillingDetails
                                name={billingName}
                                setName={setBillingName}
                                address={billingAddress}
                                setAddress={setBillingAddress}
                                city={billingCity}
                                setCity={setBillingCity}
                                county={billingCounty}
                                setCounty={setBillingCounty}
                                postcode={billingPostcode}
                                setPostcode={setBillingPostcode}
                                country={billingCountry}
                                setCountry={setBillingCountry}
                                isCompany={billingIsCompany}
                                setIsCompany={setBillingIsCompany}
                                companyName={billingCompanyName}
                                setCompanyName={setBillingCompanyName}
                                companyVatNumber={billingVatNumber}
                                setCompanyVatNumber={setBillingVatNumber}
                                isCompanyVatNumberValid={isCompanyVatNumberValid}
                                setIsCompanyVatNumberValid={setIsCompanyVatNumberValid}
                            />
                        )}
                    </>
                )}
                {dashboardAccountLoading ? <Skeleton /> : <>{tab === 2 && <ProfileDialogChangePassword />}</>}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                {tab !== 2 && (
                    <LoadingButton
                        onClick={handleSubmit}
                        variant="contained"
                        color="primary"
                        loading={dashboardAccountIsSaveing}
                        disabled={billingVatNumber.length > 0 && !isCompanyVatNumberValid}
                    >
                        Save
                    </LoadingButton>
                )}
            </DialogActions>
        </Dialog>
    );
};

export default ProfileDialog;
