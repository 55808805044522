import { LinearProgress, LinearProgressProps, styled, SxProps } from '@mui/material';

/**
 * Normalizes a date within a range to a percentage value (0-100)
 * @param startDate The start of the date range
 * @param endDate The end of the date range
 * @param valueDate The date to normalize
 * @returns number between 0 and 100 representing the position in the range, or null if dates are invalid
 */
export const normalizeDateToPercentage = (startDate: Date, endDate: Date, valueDate: Date): number => {
    // Convert to timestamps for calculation
    const start = startDate.getTime();
    const end = endDate.getTime();
    const value = valueDate.getTime();

    // Validate dates
    if (end <= start) {
        return 0; // Invalid range
    }

    // Calculate percentage
    const totalRange = end - start;
    const valuePosition = value - start;
    const percentage = (valuePosition / totalRange) * 100;

    // Clamp between 0 and 100
    return Math.max(0, Math.min(100, percentage));
};

// Define interface for custom props
interface RangeProgressProps extends LinearProgressProps {
    minValue?: number;
    maxValue?: number;
}

// Custom styled component with proper typing
const RangeProgress = styled(LinearProgress, {
    shouldForwardProp: prop => prop !== 'minValue' && prop !== 'maxValue',
})<RangeProgressProps>(({ theme, minValue = 0, maxValue = 100 }) => ({
    height: 10,
    borderRadius: 5,
    [`& .MuiLinearProgress-bar`]: {
        borderRadius: 5,
        transform: `translateX(${minValue}%) !important`,
        width: `${maxValue - minValue}% !important`,
    },
}));

const RangeProgressBar = ({ minValue, maxValue, sx }: { minValue: number; maxValue: number; sx?: SxProps }) => {
    return (
        <RangeProgress
            variant="determinate"
            value={100}
            minValue={minValue}
            maxValue={maxValue}
            sx={{
                backgroundColor: 'grey.200',
                '& .MuiLinearProgress-bar': {
                    backgroundColor: 'primary.main',
                },
                ...sx,
            }}
        />
    );
};

export default RangeProgressBar;
