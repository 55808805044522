import { Dialog, Skeleton } from '@mui/material';
import { useEffect, useState } from 'react';
import AudioPlayer from 'react-h5-audio-player';

type PlayerDialogProps = {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    url: string;
    getAudio: (url: string) => Promise<Blob | null>;
};

const AudioPlayerDialog = (props: PlayerDialogProps) => {
    const { open, setOpen, url, getAudio } = props;
    const [audioBlob, setAudioBlob] = useState<Blob | null>(null);
    const [loading, setLoading] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        const loadBlob = async () => {
            setLoading(true);

            getAudio(url)
                .then(blob => {
                    setAudioBlob(blob);
                    setLoading(false);
                })
                .catch(() => {
                    setLoading(false);
                });
        };

        if (open && url && url !== '') {
            loadBlob();
        } else {
            setAudioBlob(null);
        }
    }, [getAudio, open, url]);

    return (
        <Dialog onClose={handleClose} open={open} maxWidth="sm" fullWidth>
            {loading ? (
                <Skeleton />
            ) : (
                <AudioPlayer
                    autoPlay
                    {...(audioBlob && { src: URL.createObjectURL(audioBlob) })}
                    // onPlay={e => console.log('onPlay')}
                    showJumpControls={false}
                    showSkipControls={false}
                    hasDefaultKeyBindings={false}
                    style={{ width: '100%' }}
                />
            )}
        </Dialog>
    );
};

export default AudioPlayerDialog;
