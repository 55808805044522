import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import { Grid, Typography } from '@mui/material';
import { Theme } from '@mui/system';
import dinero from 'dinero.js';
import moment from 'moment';
import SextforceMetricsSpendingTimeline from '../../SextforceMetricsSpendingTimeline';
// import WarningIcon from '@mui/icons-material/Warning';
import PersonIcon from '@mui/icons-material/Person';
import StarIcon from '@mui/icons-material/Star';
import { useDialog } from 'muibox';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import useAxios from '../../../../../../hooks/useAxios';
import { OnlyFansMetrics } from '../../../../../../hooks/useSextforceMetricsCampaignsOverview';
import { SettingsContext } from '../../../../../../store/SettingsContext';
import { UserContext } from '../../../../../../store/UserContext';
import { d2f, handleHttpError } from '../../../../../../utils/common';
import OverviewCard from '../../../../../common/OverviewCard';
import OverviewCardSmall from '../../../../../common/OverviewCardSmall';
import PriceCardWithGoal from '../../../../../common/PriceCardWithGoal';
import { TotalSales } from '../../campaigns/details/SextforceMetricsCampaign';
import SextforceMetricsEarningsSettingsCard from '../../SextforceMetricsEarningsSettingsCard';
import SextforceMetricsShareDialog from '../../SextforceMetricsShareDialog';
import { parseCampaignStats } from '../../statsHelpers';
import SextforceMetricsTrialsOverviewGridEdit from '../overview/SextforceMetricsTrialsOverviewGridEdit';
import SextforceMetricsTrialsWelcomeMessageDialog from '../SextforceMetricsTrialsWelcomeMessageDialog';

type Props = {
    subscriber: any;
    trialData: OnlyFansMetrics;
    trialCounters: any;
    trialCountersLoading: boolean;
    trialTotalSales: TotalSales;
    trialSales: any;
    trialSalesLoading: boolean;
    showEarningsWithSubscriptions: boolean;
    setShowEarningsWithSubscriptions: (value: boolean) => void;
    showEarningsAsGross: boolean;
    setShowEarningsAsGross: (value: boolean) => void;
    suspiciousUsersCount: number;
    theme: Theme;
};

const SextforceMetricsTrialOverview = (props: Props) => {
    const {
        subscriber,
        trialData,
        trialCounters,
        trialCountersLoading,
        trialTotalSales,
        trialSales,
        trialSalesLoading,
        showEarningsWithSubscriptions,
        setShowEarningsWithSubscriptions,
        showEarningsAsGross,
        setShowEarningsAsGross,
        suspiciousUsersCount,
        theme,
    } = props;

    const userContext = useContext(UserContext);
    const settingsContext = useContext(SettingsContext);
    const params = useParams();
    const dialog = useDialog();
    const axios = useAxios();

    const [metricData, setMetricData] = useState<OnlyFansMetrics | null>(null);

    useEffect(() => {
        setMetricData(trialData);
    }, [trialData]);

    const [editSettingsDialogOpen, setEditSettingsDialogOpen] = useState(false);
    const [welcomeMessageDialogOpen, setWelcomeMessageDialogOpen] = useState(false);
    const [shareDialogOpen, setShareDialogOpen] = useState(false);

    const stats = useMemo(
        () => parseCampaignStats(trialSales, trialCounters, trialTotalSales, showEarningsWithSubscriptions),
        [trialSales, trialCounters, trialTotalSales, showEarningsWithSubscriptions],
    );

    // ** SAVE SETTINGS **
    const [isSavingSettings, setIsSavingSettings] = useState<boolean>(false);

    const saveSettings = (
        trialId: string,
        platform: string,
        associatedUsername: string,
        goalSpent: number,
        goalSubs: number,
        callBack: () => void,
    ) => {
        const doUpdate = async () => {
            if (userContext.jwtToken && settingsContext.apiKey && 'userId' in params && params.userId && subscriber) {
                const query: string = `${settingsContext.routes.metrics.base}${params.userId}/trials/${trialId}`;

                setIsSavingSettings(true);

                const body: any = {
                    ...(platform !== '' && { platform }),
                    ...(associatedUsername !== '' && { associatedUsername }),
                    ...(goalSpent !== 0 && { goalSpent }),
                    ...(goalSubs !== 0 && { goalSubs }),
                };

                // Update campaign settings
                const updatedMetric: OnlyFansMetrics | void = await axios
                    .put(query, body)
                    .then(response => response.data)
                    .catch(error => {
                        console.error(error);
                        setIsSavingSettings(false);
                        handleHttpError(error, dialog);
                    });

                if (updatedMetric && metricData) {
                    if (updatedMetric.settings) {
                        metricData.settings = updatedMetric.settings;
                    } else {
                        metricData.settings = undefined;
                    }

                    if (updatedMetric.payload) {
                        metricData.payload = updatedMetric.payload;
                    } else {
                        metricData.payload = null;
                    }

                    if (updatedMetric.roi) {
                        metricData.roi = updatedMetric.roi;
                    } else {
                        metricData.roi = undefined;
                    }

                    if (updatedMetric.associatedUsername) {
                        metricData.associatedUsername = updatedMetric.associatedUsername;
                    } else {
                        metricData.associatedUsername = undefined;
                    }

                    setMetricData(metricData);
                }

                setIsSavingSettings(false);
                callBack();
            }
        };

        doUpdate();
    };

    return (
        <>
            <Grid container spacing={1} flexGrow={0} justifyContent="center" sx={{ marginBottom: theme.spacing(1) }}>
                <Grid item xs={12}>
                    <SextforceMetricsEarningsSettingsCard
                        metricData={metricData}
                        showEarningsWithSubscriptions={showEarningsWithSubscriptions}
                        setShowEarningsWithSubscriptions={setShowEarningsWithSubscriptions}
                        showEarningsAsGross={showEarningsAsGross}
                        setShowEarningsAsGross={setShowEarningsAsGross}
                        setEditSettingsDialogOpen={setEditSettingsDialogOpen}
                        setWelcomeMessageDialogOpen={setWelcomeMessageDialogOpen}
                        setShareDialogOpen={setShareDialogOpen}
                    />

                    {editSettingsDialogOpen && metricData && (
                        <SextforceMetricsTrialsOverviewGridEdit
                            metricData={metricData}
                            onClose={() => {
                                setEditSettingsDialogOpen(false);
                            }}
                            open={editSettingsDialogOpen}
                            saveSettings={saveSettings}
                            isSavingSettings={isSavingSettings}
                        />
                    )}

                    {welcomeMessageDialogOpen && metricData && (
                        <SextforceMetricsTrialsWelcomeMessageDialog
                            subscriber={subscriber}
                            trial={metricData}
                            open={welcomeMessageDialogOpen}
                            onClose={() => {
                                setWelcomeMessageDialogOpen(false);
                            }}
                        />
                    )}

                    {shareDialogOpen && metricData && (
                        <SextforceMetricsShareDialog
                            open={shareDialogOpen}
                            onClose={() => {
                                setShareDialogOpen(false);
                            }}
                            subscriber={subscriber}
                            metricData={metricData}
                        />
                    )}
                </Grid>

                {metricData && metricData.payload && metricData.payload.lastUpdateAt && (
                    <Grid item xs={12}>
                        <OverviewCardSmall
                            title="Last Updated"
                            value={
                                <span
                                    style={{
                                        color:
                                            moment(metricData.payload.lastUpdateAt).diff(moment(), 'days') < 0
                                                ? theme.palette.error.dark
                                                : theme.palette.success.dark,
                                    }}
                                >
                                    {moment(metricData.payload.lastUpdateAt).format('L hh:mm a')}
                                </span>
                            }
                            format="plain"
                            theme={theme}
                            loading={!metricData}
                        />
                    </Grid>
                )}
                <Grid item xs={12} sm={4}>
                    <OverviewCard
                        title="Total Claimed"
                        value={
                            trialCounters && trialCounters.metricsDatas && trialCounters.metricsDatas.length > 0
                                ? trialCounters.metricsDatas[trialCounters.metricsDatas.length - 1].payload.claimsCount
                                : 0
                        }
                        showOutOfSymbol={false}
                        maxValue={
                            <span>
                                <Typography variant="subtitle1" display={'inline'}>
                                    {stats.subscribed} discovered
                                </Typography>
                            </span>
                        }
                        {...(metricData && metricData.settings && metricData.settings.goalSubs && { goal: metricData.settings.goalSubs })}
                        loading={trialCountersLoading}
                        icon={<GroupAddIcon fontSize="large" htmlColor={theme.palette.success.dark} />}
                        theme={theme}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <OverviewCard
                        title="Total Subscribed"
                        value={stats.clicked}
                        loading={trialCountersLoading}
                        icon={<SubscriptionsIcon fontSize="large" htmlColor={theme.palette.success.dark} />}
                        theme={theme}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    {/* <OverviewCard
                    title="Suspicious Users"
                    value={suspiciousUsersCount}
                    loading={trialCountersLoading}
                    icon={<WarningIcon fontSize="large" htmlColor={theme.palette.warning.dark} />}
                    theme={theme}
                /> */}
                    <OverviewCard
                        title="Total Spenders"
                        value={stats.spenders}
                        loading={trialSalesLoading}
                        icon={<StarIcon fontSize="large" htmlColor={theme.palette.success.dark} />}
                        theme={theme}
                    />
                </Grid>
            </Grid>

            <Grid container spacing={1} flexGrow={0} justifyContent="center" sx={{ marginBottom: theme.spacing(1) }}>
                <Grid item xs={12} sm={4}>
                    {stats.earningsGross === 0 && (
                        <OverviewCard
                            title={
                                <span>
                                    Cost Per Fan <small style={{ color: theme.palette.text.secondary }}>(net)</small>
                                </span>
                            }
                            value={<span style={{ color: theme.palette.text.secondary }}>n/a</span>}
                            loading={trialSalesLoading}
                            icon={<PersonIcon fontSize="large" htmlColor={theme.palette.success.dark} />}
                            theme={theme}
                        />
                    )}
                    {stats.earningsGross !== 0 && (
                        <PriceCardWithGoal
                            title={
                                <span>
                                    Cost Per Fan <small style={{ color: theme.palette.text.secondary }}>(net)</small>
                                </span>
                            }
                            value={dinero({
                                amount: stats.cpf,
                                currency: 'USD',
                            })}
                            loading={trialSalesLoading}
                            icon={<PersonIcon fontSize="large" htmlColor={theme.palette.success.dark} />}
                            theme={theme}
                        />
                    )}
                </Grid>
                <Grid item xs={12} sm={4}>
                    <OverviewCard
                        title={<span>Fan To Spenders Conversion</span>}
                        value={stats.conversionRateSubscriberToSale.toFixed(2) + '%'}
                        loading={trialSalesLoading}
                        icon={<PersonIcon fontSize="large" htmlColor={theme.palette.success.dark} />}
                        theme={theme}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <PriceCardWithGoal
                        title={
                            <Typography variant="inherit" textAlign="right">
                                {showEarningsWithSubscriptions ? 'Earnings with Subs.' : 'Earnings without Subs.'}{' '}
                                <small style={{ color: theme.palette.text.secondary }}>{showEarningsAsGross ? '(gross)' : '(net)'}</small>
                            </Typography>
                        }
                        value={dinero({
                            amount: Math.trunc(showEarningsAsGross ? stats.earningsGross : stats.earningsNet),
                            currency: 'USD',
                        })}
                        {...(metricData &&
                            metricData.settings &&
                            metricData.settings.goalSpent && {
                                goal: dinero({
                                    amount: Math.trunc(d2f(metricData.settings.goalSpent) * 100 * (showEarningsAsGross ? 1 : 0.8)),
                                    currency: 'USD',
                                }),
                            })}
                        loading={trialSalesLoading}
                        icon={<AttachMoneyIcon fontSize="large" htmlColor={theme.palette.success.dark} />}
                        theme={theme}
                    />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <OverviewCardSmall
                        title={metricData && metricData.type && metricData.type === 'trialLinkTrial' ? 'Name' : 'Price'}
                        value={
                            metricData && metricData.payload && metricData.type
                                ? metricData.type === 'trialLinkTrial'
                                    ? metricData.payload.name
                                    : dinero({ amount: Math.trunc((metricData.payload.price || 0) * 100), currency: 'USD' }).toFormat()
                                : null
                        }
                        format={'plain'}
                        loading={!metricData}
                        theme={theme}
                    />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <OverviewCardSmall
                        title="Created On"
                        value={
                            metricData &&
                            metricData.payload &&
                            metricData.payload.createdAt &&
                            moment(metricData.payload.createdAt).format('L')
                        }
                        format={'plain'}
                        loading={!metricData}
                        theme={theme}
                    />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <OverviewCardSmall
                        title="ID"
                        value={metricData && metricData.foreignId}
                        format={'plain'}
                        loading={!metricData}
                        theme={theme}
                    />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <OverviewCardSmall
                        title="Days"
                        value={metricData && metricData.payload && metricData.payload.subscribeDays}
                        format={'plain'}
                        loading={!metricData}
                        theme={theme}
                    />
                </Grid>

                {metricData && metricData.payload && metricData.payload.firstSaleDate && metricData.payload.lastSaleDate && (
                    <Grid item xs={12}>
                        <SextforceMetricsSpendingTimeline
                            promoStartDate={new Date(metricData.payload.createdAt)}
                            firstSpendDate={new Date(metricData.payload.firstSaleDate)}
                            lastSpendDate={new Date(metricData.payload.lastSaleDate)}
                        />
                    </Grid>
                )}

                {metricData && metricData.type && metricData.type === 'trialLinkPromo' && (
                    <Grid item xs={12}>
                        <OverviewCardSmall
                            value={metricData && metricData.payload && metricData.payload.message}
                            format={'plain'}
                            loading={!metricData}
                            allowOverflow={true}
                            theme={theme}
                        />
                    </Grid>
                )}
            </Grid>
        </>
    );
};

export default SextforceMetricsTrialOverview;
