import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import EditIcon from '@mui/icons-material/Edit';
import PeopleIcon from '@mui/icons-material/People';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import { Alert, Backdrop, Box, Card, CardContent, CircularProgress, Grid, Snackbar, Tooltip, Typography } from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';
import { Theme } from '@mui/system';
import { DataGrid, GridActionsCellItem, GridColumns, GridRowParams, GridValueGetterParams } from '@mui/x-data-grid';
import { useConfirm } from 'material-ui-confirm';
import { useDialog } from 'muibox';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { OnlyFansSubscriber } from '../../../../../src/libs/onlyFans/onlyFansDbTypes';
import AlertCollapsable from '../../../components/common/AlertCollapsable';
import BackNavigationButton from '../../../components/common/BackNavigationButton';
import PageContainer from '../../../components/common/PageContainter';
import SextforceManageAgentsAddAgent from '../../../components/services/sextforce/manageAgents/SextforceManageAgentsAddAgent';
import SextforceManageAgentEditAgentDialog from '../../../components/services/sextforce/manageAgents/SextforceManageAgentsEditAgent';
import SextforceManageAgentsSalesProofLinkDialog from '../../../components/services/sextforce/manageAgents/SextforceManageAgentsSalesProofLinkDialog';
import { SettingsContext } from '../../../store/SettingsContext';
import { UserContext } from '../../../store/UserContext';
import { d2f, handleHttpError, handleHttpErrorResponse } from '../../../utils/common';

export type AgentInputs = {
    _id?: string;
    name: string;
    fraction: number;
    type?: string;
    role?: string;
    commissionPercent: number;
};

const SextforceManageAgents = () => {
    const userContext = useContext(UserContext);
    const settingsContext = useContext(SettingsContext);
    const theme: Theme = useTheme();
    const dialog = useDialog();
    const pageParams = useParams();
    const confirm = useConfirm();
    const [showBackdrop, setShowBackdrop] = useState<boolean>(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [showEditAgentDialog, setShowEditAgentDialog] = useState<boolean>(false);
    const [showSalesProofLinkDialog, setShowSalesProofLinkDialog] = useState<boolean>(false);

    const [subscriber, setSubscriber] = useState<OnlyFansSubscriber | null>(null);
    const [agents, setAgents] = useState<any[]>([]);
    const [availableFractions, setAvailableFractions] = useState<number[]>([]);
    const [agentToEdit, setAgentToEdit] = useState<any | null>(null);

    const handleCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSnackbar(false);
    };

    useEffect(() => {
        if (userContext.jwtToken && settingsContext.apiKey && 'userId' in pageParams && pageParams.userId) {
            const query: string = `${settingsContext.routes.subscribers.find}${pageParams.userId}`;

            fetch(query, {
                method: 'get',
                headers: {
                    Authorization: userContext.jwtToken,
                    apiKey: settingsContext.apiKey,
                },
            })
                .then(async response => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        handleHttpErrorResponse(response, dialog);
                    }
                })
                .then(data => {
                    setSubscriber(data);
                    setShowBackdrop(false);
                })
                .catch(error => {
                    console.error(error);
                    handleHttpError(error, dialog);
                });
        }
    }, [userContext, settingsContext, pageParams, dialog]);

    // Open the Edit Agent dialog
    const editAgent = useCallback(
        (agentData: any) => () => {
            setAgentToEdit(agentData);
            setShowEditAgentDialog(true);
        },
        [setAgentToEdit],
    );

    // Open the Edit Agent dialog
    const createSalesProofLink = useCallback(
        (agentData: any) => () => {
            setAgentToEdit(agentData);
            setShowSalesProofLinkDialog(true);
        },
        [setAgentToEdit],
    );

    // Update Agent data
    const handleUpdateAgent = (id: string, updateAgent: any) => {
        setShowEditAgentDialog(false);
        setShowBackdrop(true);

        const query: string = `${settingsContext.routes.sextforce.base}${pageParams.userId}/agents`;

        fetch(query, {
            method: 'put',
            headers: {
                Authorization: userContext.jwtToken,
                apiKey: settingsContext.apiKey,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ _id: id, ...updateAgent }),
        })
            .then(async response => {
                if (response.ok) {
                    return response.json();
                } else {
                    handleHttpErrorResponse(response, dialog);
                }
            })
            .then(data => {
                if (data) {
                    setAgents(agents => [...agents, data]);
                    setShowBackdrop(false);
                } else {
                    dialog.alert(`Agent not added!`);
                }
            })
            .catch(error => {
                console.error(error);
                handleHttpError(error, dialog);
            });
    };

    // Close Edit Agent dialog
    const handleCloseEditAgentDialog = () => setShowEditAgentDialog(false);

    // Close Create Sales Proof Link dialog
    const handleCloseSalesProofLinkDialog = () => setShowSalesProofLinkDialog(false);

    // Delete Agent's
    const handleDeleteAgentSalesProofLink = useCallback(
        (agentId: string) => {
            if (userContext.jwtToken && settingsContext.apiKey && 'userId' in pageParams && pageParams.userId) {
                const query: string = `${settingsContext.routes.sextforce.base}${pageParams.userId}/agents/${agentId}/urlId`;

                setShowBackdrop(true);

                fetch(query, {
                    method: 'delete',
                    headers: {
                        Authorization: userContext.jwtToken,
                        apiKey: settingsContext.apiKey,
                    },
                })
                    .then(async response => {
                        if (response.ok) {
                            return response.json();
                        } else {
                            handleHttpErrorResponse(response, dialog);
                        }
                    })
                    .then(data => {
                        if (data.urlId) {
                            dialog.alert(`Could not revoke agent's unique sales proof submission link!`);
                        } else {
                            setOpenSnackbar(true);
                        }
                    })
                    .catch(error => {
                        console.error(error);
                        handleHttpError(error, dialog);
                    });

                setShowBackdrop(false);
            }
        },
        [dialog, pageParams, settingsContext.apiKey, settingsContext.routes.sextforce.base, userContext.jwtToken],
    );

    // Define agents DataGrid columns
    const columns = useMemo<GridColumns>(
        () => [
            {
                field: 'fraction',
                headerName: 'Fraction',
                width: 120,
                align: 'right',
                headerAlign: 'right',
                valueGetter: (params: GridValueGetterParams) =>
                    d2f(params.row.fraction).toLocaleString(undefined, { minimumFractionDigits: 2 }),
                renderCell: (params: any) => (
                    <div style={{ fontFamily: 'monospace' }}>
                        {d2f(params.row.fraction).toLocaleString(undefined, { minimumFractionDigits: 2 })}
                    </div>
                ),
            },
            { field: 'name', headerName: 'Agent', flex: 1 },
            { field: 'type', headerName: 'Type', flex: 0.5 },
            { field: 'role', headerName: 'Role', flex: 0.5 },
            {
                field: 'commissionPercent',
                headerName: 'Commission %',
                width: 150,
                align: 'right',
                headerAlign: 'right',
                renderCell: params => (
                    <div style={{ fontFamily: 'monospace' }}>
                        {params.row.commissionPercent.toLocaleString(undefined, { minimumFractionDigits: 2 })}%
                    </div>
                ),
            },
            {
                field: 'actions',
                type: 'actions',
                width: 120,
                getActions: (params: GridRowParams) => [
                    /* @ts-ignore */
                    <GridActionsCellItem
                        icon={
                            <Tooltip title="Edit">
                                <EditIcon color="primary" />
                            </Tooltip>
                        }
                        label="Edit"
                        onClick={editAgent(params.row)}
                    />,
                    /* @ts-ignore */
                    <GridActionsCellItem
                        icon={
                            <Tooltip title="Create unique proof submission link">
                                <VpnKeyIcon color="secondary" />
                            </Tooltip>
                        }
                        label="Create Link"
                        onClick={createSalesProofLink(params.row)}
                    />,
                    /* @ts-ignore */
                    <GridActionsCellItem
                        icon={
                            <Tooltip title="Revoke proof submission link">
                                <DoNotDisturbIcon color="error" />
                            </Tooltip>
                        }
                        label="Revoke Link"
                        onClick={() => {
                            confirm({
                                title: 'Warming!',
                                description: "Are you sure you want to revoke this agent's unique sales proof submission link?",
                                confirmationText: 'Delete',
                            })
                                .then(() => {
                                    // Delete agent's unique sales proof submission link
                                    handleDeleteAgentSalesProofLink(params.row._id);
                                })
                                .catch(() => {});
                        }}
                    />,
                ],
            },
        ],
        [confirm, createSalesProofLink, editAgent, handleDeleteAgentSalesProofLink],
    );

    // Fetch all agents
    useEffect(() => {
        if (userContext.jwtToken && settingsContext.apiKey && 'userId' in pageParams && pageParams.userId) {
            const query: string = `${settingsContext.routes.sextforce.base}${pageParams.userId}/agents`;

            fetch(query, {
                method: 'get',
                headers: {
                    Authorization: userContext.jwtToken,
                    apiKey: settingsContext.apiKey,
                },
            })
                .then(async response => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        handleHttpErrorResponse(response, dialog);
                    }
                })
                .then(data => {
                    setAgents(data);
                })
                .catch(error => {
                    console.error(error);
                    handleHttpError(error, dialog);
                });

            setShowBackdrop(false);
        }
    }, [userContext, settingsContext, pageParams, dialog]);

    // Update available fractions to use when adding a new agent
    useEffect(() => {
        const fractions: number[] = [];

        for (let i = 1; i < 100; i += 1) {
            const agent = agents.find((agent: any) => d2f(agent.fraction) === i / 100);

            if (!agent) {
                fractions.push(i);
            }
        }

        setAvailableFractions(fractions);
    }, [agents]);

    const onSubmit: SubmitHandler<AgentInputs> = newAgent => {
        const query: string = `${settingsContext.routes.sextforce.base}${pageParams.userId}/agents`;

        fetch(query, {
            method: 'post',
            headers: {
                Authorization: userContext.jwtToken,
                apiKey: settingsContext.apiKey,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(newAgent),
        })
            .then(async response => {
                if (response.ok) {
                    return response.json();
                } else {
                    handleHttpErrorResponse(response, dialog);
                }
            })
            .then(data => {
                if (data) {
                    setAgents(agents => [...agents, data]);
                    setShowBackdrop(false);
                } else {
                    dialog.alert(`Agent not added!`);
                }
            })
            .catch(error => {
                handleHttpError(error, dialog);
            });
    };

    return (
        <PageContainer>
            <Typography variant="h5" sx={{ marginBottom: theme.spacing(4) }}>
                <BackNavigationButton
                    {...(subscriber && { url: `/subscribers/${subscriber._id}/${settingsContext.services.sextforce.homeUrl}` })}
                />{' '}
                Manage Agents {subscriber && `for ${subscriber.username}`}
            </Typography>

            <AlertCollapsable
                openInitially={false}
                title="How It Works"
                variant="filled"
                severity="info"
                sx={{
                    marginBottom: theme.spacing(4),
                }}
            >
                <p>
                    Each agent is assigned a <strong>unique .XX fraction</strong> as well as a Name and an optional Type and Role names
                    (these are completely arbitrary and are only used by you to identify the Agent's job). Two agents cannot have the same
                    fraction assigned to them.
                </p>
                <p>
                    What's absolutely important is that each agent knows that everything they sell must have a price with their unique .XX
                    fraction. For example, if Agent Sally has the fraction 0.22 assigned to them, then they must sell tips or PPVs for the
                    price of $X.22 (i.e. $9.22, $4.22, $50.22, etc.) for the sale to be automatically counted towards their commission.
                </p>
                <p>
                    You can add new agents using the form at the bottom, and you can edit the details of an existing agent by clicking on
                    the <EditIcon color="primary" fontSize="small" /> icon next to the agent in the list. But once a fraction has been
                    assigned to an agent, that fraction can be given a different name but it cannot be deleted as it would conflict with any
                    existing historic sales records.
                </p>
                <p>
                    Agents will sometimes need to sell an existing product like a video or bundle for a price that does not end with their
                    unique fraction. It is recommended that all these general sales are sold with a $X.00 fraction (i.e. $4.00, $10.00,
                    $12.00, etc.) as the X.00 fraction is reserved for this purpose and cannot be assigned to any agent. These sales are
                    called <strong>Anonymous Sales</strong> and are treated differently to other transactions.
                </p>
                <p>
                    You can assign Anonymous Sales to their respective agents manually in the <strong>Assign Sales</strong> page (see main
                    menu) or you can (and should) have the Agents submit a proof of sale with the date and time of the sale and a screenshot
                    of the chat. The proof of sale will appear alongside the transaction in the Assign Sales page awaiting your approval,
                    saving you precious time.
                </p>
                <p>
                    Click on the <VpnKeyIcon color="secondary" fontSize="small" /> icon to generate a <strong>unique and private</strong>{' '}
                    Sales Proof Submission Link. <strong>Only share this link with its respective Agent!</strong> If an agent stops working
                    for you, revoke their unique link by clicking on the <DoNotDisturbIcon color="error" fontSize="small" /> icon. You can
                    generate a new link for the next Agent using this fraction by clicking the{' '}
                    <VpnKeyIcon color="secondary" fontSize="small" /> icon again.
                </p>
            </AlertCollapsable>

            <Grid container flexGrow={0} spacing={1} alignItems="center" sx={{ marginBottom: theme.spacing(1) }}>
                <Grid item xs>
                    <Card
                        variant="elevation"
                        sx={{
                            marginBottom: theme.spacing(1),
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                        }}
                    >
                        <CardContent>
                            <Grid container flexGrow={1} alignItems="center" spacing={2}>
                                <Grid item xs={'auto'}>
                                    <PeopleIcon
                                        fontSize="large"
                                        color={
                                            agents && settingsContext.services.sextforce.totalAllowedAgents <= agents.length
                                                ? 'error'
                                                : 'success'
                                        }
                                    />
                                </Grid>
                                <Grid item xs>
                                    <Grid container flexGrow={1} alignItems="center" spacing={0}>
                                        <Grid item xs={12}>
                                            <Typography
                                                variant="subtitle1"
                                                textAlign={'right'}
                                                color={
                                                    agents && settingsContext.services.sextforce.totalAllowedAgents <= agents.length
                                                        ? theme.palette.error.main
                                                        : theme.palette.success.main
                                                }
                                            >
                                                Total Agents
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography
                                                variant="h6"
                                                textAlign={'right'}
                                                color={
                                                    agents && settingsContext.services.sextforce.totalAllowedAgents <= agents.length
                                                        ? theme.palette.error.main
                                                        : theme.palette.success.main
                                                }
                                                sx={{ fontFamily: 'monospace' }}
                                            >
                                                {agents && agents.length}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item xs>
                    <Card
                        variant="elevation"
                        sx={{
                            marginBottom: theme.spacing(1),
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                        }}
                    >
                        <CardContent>
                            <Grid container flexGrow={1} alignItems="center" spacing={2}>
                                <Grid item xs={'auto'}>
                                    <PeopleOutlineIcon fontSize="large" />
                                </Grid>
                                <Grid item xs>
                                    <Grid container flexGrow={1} alignItems="center" spacing={0}>
                                        <Grid item xs={12}>
                                            <Typography variant="subtitle1" textAlign={'right'}>
                                                Maximum Agents
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="h6" textAlign={'right'} sx={{ fontFamily: 'monospace' }}>
                                                {settingsContext.services.sextforce.totalAllowedAgents}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>

            <Box
                sx={{
                    width: '100%',
                    '.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
                        outline: 'none',
                    },
                    paddingBottom: theme.spacing(4),
                }}
            >
                {' '}
                <DataGrid
                    rows={agents}
                    columns={columns}
                    getRowId={row => row._id}
                    initialState={{ sorting: { sortModel: [{ field: 'name', sort: 'asc' }] } }}
                    hideFooter={true}
                    disableSelectionOnClick
                    disableColumnFilter
                    disableColumnSelector
                    autoHeight={true}
                    density="compact"
                    rowCount={agents ? agents.length : 0}
                    sx={{ '& .MuiDataGrid-columnHeaderTitle': { fontWeight: 'bold' }, backgroundColor: '#ffffff' }}
                />
            </Box>

            <SextforceManageAgentsAddAgent
                onSubmit={onSubmit}
                availableFractions={availableFractions}
                totalAgents={agents ? agents.length : 0}
                totalAllowedAgents={settingsContext.services.sextforce.totalAllowedAgents}
            />

            <SextforceManageAgentEditAgentDialog
                open={showEditAgentDialog}
                onSubmit={handleUpdateAgent}
                availableFractions={availableFractions}
                agent={agentToEdit}
                onCancel={handleCloseEditAgentDialog}
            />

            <SextforceManageAgentsSalesProofLinkDialog
                open={showSalesProofLinkDialog}
                agent={agentToEdit}
                onCancel={handleCloseSalesProofLinkDialog}
            />

            <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={showBackdrop}>
                <CircularProgress color="inherit" />
            </Backdrop>

            <Snackbar
                open={openSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
            >
                <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
                    Link revoked successfully
                </Alert>
            </Snackbar>
        </PageContainer>
    );
};

export default SextforceManageAgents;
