import axios from 'axios';
import { useDialog } from 'muibox';
import { useContext } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { SettingsContext } from '../store/SettingsContext';
import { UserContext } from '../store/UserContext';
import { handleHttpError } from '../utils/common';

const useSextforceMetricsCrossReferenceMetricEarnings = (
    metricId: string,
    type: 'campaign' | 'trial',
    crossReferenceSubscriberId: string,
) => {
    const userContext = useContext(UserContext);
    const settingsContext = useContext(SettingsContext);
    const dialog = useDialog();
    const params = useParams();

    // Fetch Fans Counters
    const fetchMetricCrossReferenceEarnings = async (
        metricId: string,
        type: 'campaign' | 'trial',
        crossReferenceSubscriberId: string,
    ): Promise<any> => {
        const query: string = `${settingsContext.routes.metrics.base}${params.userId}/${
            type === 'campaign' ? 'promocampaigns' : 'trials'
        }/${metricId}/crossEarnings/${crossReferenceSubscriberId !== 'all' ? crossReferenceSubscriberId : 'all'}`;

        const data = await axios
            .get(query, {
                headers: {
                    Authorization: userContext.jwtToken,
                    apiKey: settingsContext.apiKey,
                },
            })
            .then(async response => response.data)
            .catch(error => {
                console.error(error);
                handleHttpError(error, dialog);

                return [];
            });

        return data;
    };

    return useQuery(
        ['metricsCrossReferenceMetricEarnings', userContext.jwtToken, params.userId, metricId, crossReferenceSubscriberId],
        () => fetchMetricCrossReferenceEarnings(metricId, type, crossReferenceSubscriberId),
        {
            refetchOnWindowFocus: false,
            // 5 minutes
            staleTime: 1000 * 60 * 5,
            enabled:
                userContext.jwtToken && settingsContext.apiKey && params.userId && metricId !== '' && crossReferenceSubscriberId !== ''
                    ? true
                    : false,
        },
    );
};

export default useSextforceMetricsCrossReferenceMetricEarnings;
