import { Theme } from '@mui/system';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { Card, CardContent, Grid, Skeleton, Typography } from '@mui/material';
import { trendColor, trendIcon } from '../../../../utils/common';

const OverviewPriceCard = (
    title: string,
    mainPrice: Dinero.Dinero,
    comparePrice: Dinero.Dinero,
    compare: boolean,
    isFetching: boolean,
    theme: Theme,
) => {
    const color: string = trendColor(mainPrice, comparePrice, compare, theme);
    let icon: IconDefinition = trendIcon(mainPrice, comparePrice, compare);

    return (
        <Card variant="elevation">
            <CardContent>
                <Grid container flexGrow={1} alignItems="center" spacing={2}>
                    <Grid item xs={1}>
                        {compare && <FontAwesomeIcon icon={icon} color={color} />}
                    </Grid>
                    <Grid item xs>
                        <Typography variant="subtitle1" align="right">
                            {title}
                        </Typography>
                    </Grid>
                </Grid>
                <Typography variant="h6" align="right" fontFamily={'monospace'} sx={{ ...(compare && { color }) }}>
                    {isFetching ? <Skeleton /> : mainPrice.toFormat()}
                </Typography>
                {compare && (
                    <Typography variant="h6" align="right" fontFamily={'monospace'}>
                        {isFetching ? <Skeleton /> : comparePrice.toFormat()}
                    </Typography>
                )}
            </CardContent>
        </Card>
    );
};

export default OverviewPriceCard;
