import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import {
    arrayOnlyFansSubscriberHostessAutoReplyFreeloadersAction,
    OnlyFansSubscriberHostessAutoReplyFreeloaders,
} from '../../../../types/hostessAutoReplyFreeloaders';
import { titleCase } from '../../../../utils/common';

const FormActionToTakeSelect = (props: {
    formData: OnlyFansSubscriberHostessAutoReplyFreeloaders;
    setFormData: (formData: OnlyFansSubscriberHostessAutoReplyFreeloaders) => void;
}) => {
    const { formData, setFormData } = props;

    return (
        <FormControl fullWidth>
            <InputLabel id="action">Action</InputLabel>
            <Select
                labelId="action"
                defaultValue={formData ? formData.action : 'none'}
                value={formData && formData.action ? formData.action : 'none'}
                onChange={e => {
                    setFormData({ ...formData, action: e.target.value });
                }}
                fullWidth
                size="medium"
                label="Action"
            >
                {arrayOnlyFansSubscriberHostessAutoReplyFreeloadersAction.map(item => (
                    <MenuItem value={item} key={item}>
                        {titleCase(item)}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default FormActionToTakeSelect;
