import LoadingButton from '@mui/lab/LoadingButton';
import { Alert, Button, Grid, Theme, Typography, useTheme } from '@mui/material';
import { useDialog } from 'muibox';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import BackNavigationButton from '../../../components/common/BackNavigationButton';
import PageContainer from '../../../components/common/PageContainter';
import SharedCreditCard from '../../../components/common/SharedCreditCard';
import StyledCard from '../../../components/common/StyledCard';
import StyledCardTitleBar from '../../../components/common/StyledCardTitleBar';
import AutoSendWelcomeMessageDetailsForm from '../../../components/services/messageUsers/autoSendWelcomeMessage/AutoSendWelcomeMessageDetailsForm';
import MessageUsersPriceBar from '../../../components/services/messageUsers/MessageUsersPriceBar';
import useSubscriber from '../../../hooks/useSubscriber';
import useSubscriberSettingsMessageUsersAutoWelcomeMessage from '../../../hooks/useSubscriberSettingsMessageUsersAutoWelcomeMessage';
import useSubscriberSettingsNotifications, {
    OnlyFansSettingsNotificationsTransport,
} from '../../../hooks/useSubscriberSettingsNotifications';
import { SettingsContext } from '../../../store/SettingsContext';
import { OnlyFansSubscriberMessageUsersAutoMessageDetails } from '../../../types/messageUsersAutoSendWelcomeMessage';
import { d2f, isSextforceActive } from '../../../utils/common';

const MessageUsersAutoSendWelcomeMessageNewTrialSubscriber = () => {
    const settingsContext = useContext(SettingsContext);
    const theme: Theme = useTheme();
    const { enqueueSnackbar } = useSnackbar();
    const dialog = useDialog();

    const { data: subscriber, isLoading: subscriberLoading } = useSubscriber();
    const {
        data: serviceSettings,
        isLoading: serviceSettingsLoading,
        setServiceSettingsMessageUsersAutoWelcomeMessageNewTrialSubscriber,
        setServiceSettingsMessageUsersAutoWelcomeMessageLoading,
    } = useSubscriberSettingsMessageUsersAutoWelcomeMessage();
    const [unitCost, setUnitCost] = useState<number>(0);

    // OnlyFans Notifications Settings
    const { data: onlyFansNotificationSettings, isLoading: onlyFansNotificationSettingsLoading } = useSubscriberSettingsNotifications();
    const youAndYourPosts: OnlyFansSettingsNotificationsTransport.Response | undefined = onlyFansNotificationSettings?.find(
        n => n.code === 'you_and_your_posts',
    );
    const newSubscriberNotifications: OnlyFansSettingsNotificationsTransport.Item | undefined = youAndYourPosts?.items.find(
        n => n.code === 'new_subscriber_trial',
    );
    const siteNotificationsOn: boolean = newSubscriberNotifications?.isEnabled ?? false;

    // New Trial Subscriber data
    const [autoMessageNewTrialSubscriber, setAutoMessageNewTrialSubscriber] =
        useState<OnlyFansSubscriberMessageUsersAutoMessageDetails | null>(null);
    const [autoMessageNewTrialSubscriberInitialValues, setAutoMessageNewTrialSubscriberInitialValues] =
        useState<OnlyFansSubscriberMessageUsersAutoMessageDetails | null>(null);
    const [vaultAlbumNameNewTrialSubscriber, setVaultAlbumNameNewTrialSubscriber] = useState<string | undefined>();
    const [saveTemplateNewTrialSubscriber, setSaveTemplateNewTrialSubscriber] = useState<boolean>(false);
    const [saveTemplateNameNewTrialSubscriber, setSaveTemplateNameNewTrialSubscriber] = useState<string>('');
    const [autoMessageNewTrialSubscriberIsFormValid, setAutoMessageNewTrialSubscriberIsFormValid] = useState<boolean>(false);

    useEffect(() => {
        if (subscriber) {
            setUnitCost(isSextforceActive(subscriber) ? 0 : d2f(subscriber._config.messageUsers.prices.unitCost));
        }
    }, [subscriber]);

    useEffect(() => {
        if (serviceSettings) {
            if (!autoMessageNewTrialSubscriber) {
                setAutoMessageNewTrialSubscriber(serviceSettings.autoMessageNewTrialSubscriber);
            }

            if (!autoMessageNewTrialSubscriberInitialValues) {
                setAutoMessageNewTrialSubscriberInitialValues(serviceSettings.autoMessageNewTrialSubscriber || null);
            }
        } else {
            setAutoMessageNewTrialSubscriber(null);
            setAutoMessageNewTrialSubscriberInitialValues(null);
        }
    }, [autoMessageNewTrialSubscriber, autoMessageNewTrialSubscriberInitialValues, serviceSettings]);

    const handleSaveSettings = () => {
        const doSave = async () => {
            await setServiceSettingsMessageUsersAutoWelcomeMessageNewTrialSubscriber(
                autoMessageNewTrialSubscriber,
                saveTemplateNewTrialSubscriber,
                saveTemplateNameNewTrialSubscriber,
            )
                .then(newData => {
                    enqueueSnackbar('Settings saved successfully!', { variant: 'success' });

                    setAutoMessageNewTrialSubscriber(newData.autoMessageNewTrialSubscriber);
                    setAutoMessageNewTrialSubscriberInitialValues(newData.autoMessageNewTrialSubscriber);

                    // Scroll to top
                    window.scrollTo(0, 0);
                })
                .catch(() => {
                    enqueueSnackbar('Failed to save settings!', { variant: 'error' });

                    setAutoMessageNewTrialSubscriberInitialValues(null);
                });
        };

        doSave();
    };

    const resetForm = () => {
        setAutoMessageNewTrialSubscriber(null);
    };

    return (
        <PageContainer>
            {subscriber && '_id' in subscriber && subscriber._id && (
                <>
                    <Typography variant="h5" sx={{ mb: 2 }}>
                        <BackNavigationButton url={`/subscribers/${subscriber._id}/${settingsContext.services.messageUsers.homeUrl}`} />{' '}
                        Auto Welcome Message for {subscriber.username}
                    </Typography>

                    {unitCost !== 0 && (
                        <Grid container spacing={1} flexGrow={0} justifyContent="center" sx={{ marginBottom: theme.spacing(2) }}>
                            <Grid item xs={12} md={6}>
                                <SharedCreditCard showTopUpButton={false} />
                            </Grid>
                        </Grid>
                    )}

                    <StyledCard noCard noBottomMargin>
                        <StyledCardTitleBar title="Auto Message New Trial Subscriber" theme={theme} />
                    </StyledCard>
                    <StyledCard>
                        <Typography gutterBottom>
                            Use the form below to setup a <strong>general</strong> message that will be sent automatically to a fan when
                            they consume a free trial. The fan will receive a regular message from you which will appear in their Priority
                            inbox at a random time 1 to 2 minutes after one of these events. Creators will be skipped automatically.
                        </Typography>
                        <Typography gutterBottom>
                            You can setup a <strong>specific welcome message for each Trial Link</strong> in the{' '}
                            <Link
                                to={`/subscribers/${subscriber._id}/services/sextforce/metrics/trials/overview`}
                                style={{ color: theme.palette.primary.main, textDecoration: 'none', fontWeight: 'bold' }}
                            >
                                Trial Links Metrics page
                            </Link>
                            . For all trial links that don't have a specific welcome message, this general message will be sent.
                        </Typography>
                        <Typography gutterBottom>
                            Use this service to send a welcome message to your new subscribers, or to send a thank you message to your
                            returning fans.
                        </Typography>
                        <MessageUsersPriceBar unitCost={unitCost} includedWith="Sextforce" theme={theme} />
                    </StyledCard>

                    <StyledCard noCard>
                        {!onlyFansNotificationSettingsLoading && !siteNotificationsOn && (
                            <Alert severity="warning" sx={{ mb: 2 }}>
                                <Typography variant="body1">
                                    <strong>Important:</strong> For this service to work, you must have the "New Subscriber" notification
                                    turned on in{' '}
                                    <Link to={'https://onlyfans.com/my/settings/notifications/site'} target="_blank">
                                        OnlyFans Notifications Settings
                                    </Link>
                                    .
                                </Typography>
                            </Alert>
                        )}
                    </StyledCard>

                    <StyledCard noCard noBottomMargin>
                        <StyledCardTitleBar title="Message Settings" theme={theme} />
                    </StyledCard>

                    {/* {serviceSettingsLoading ? <Skeleton height={400} sx={{ mb: 1 }} /> : null} */}
                    {autoMessageNewTrialSubscriber && (
                        <AutoSendWelcomeMessageDetailsForm
                            title="Auto Message New Trial Subscriber"
                            description="This message will be sent to each new user that consumes one of your free trial links, as soon as they claim the trial. You can use this message to thank them for subscribing, and to send them some teaser content for free or a PPV to unlock."
                            subscriber={subscriber}
                            initialValues={autoMessageNewTrialSubscriberInitialValues}
                            messageDetails={autoMessageNewTrialSubscriber}
                            setMessageDetails={setAutoMessageNewTrialSubscriber}
                            vaultAlbumName={vaultAlbumNameNewTrialSubscriber}
                            setVaultAlbumName={setVaultAlbumNameNewTrialSubscriber}
                            saveTemplate={saveTemplateNewTrialSubscriber}
                            setSaveTemplate={setSaveTemplateNewTrialSubscriber}
                            saveTemplateName={saveTemplateNameNewTrialSubscriber}
                            setSaveTemplateName={setSaveTemplateNameNewTrialSubscriber}
                            setIsFormValid={setAutoMessageNewTrialSubscriberIsFormValid}
                        />
                    )}

                    <StyledCard noCard>
                        <Grid
                            container
                            spacing={1}
                            flexGrow={0}
                            alignItems="center"
                            justifyContent="center"
                            sx={{ marginBottom: theme.spacing(2) }}
                        >
                            <Grid item xs={8}>
                                <LoadingButton
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    loading={
                                        setServiceSettingsMessageUsersAutoWelcomeMessageLoading ||
                                        subscriberLoading ||
                                        serviceSettingsLoading
                                    }
                                    fullWidth
                                    disabled={
                                        setServiceSettingsMessageUsersAutoWelcomeMessageLoading ||
                                        subscriberLoading ||
                                        serviceSettingsLoading ||
                                        !autoMessageNewTrialSubscriberIsFormValid
                                    }
                                    onClick={() => {
                                        dialog
                                            .confirm({
                                                message: `Are you sure you want to save these settings?`,
                                                title: 'Confirm',
                                                ok: { text: 'Save', variant: 'contained' },
                                                cancel: { text: 'Cancel' },
                                            })
                                            .then(async () => {
                                                await handleSaveSettings();
                                            })
                                            .catch(() => {});
                                    }}
                                >
                                    Save Settings
                                </LoadingButton>
                            </Grid>
                            <Grid item xs={4}>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    size="large"
                                    fullWidth
                                    disabled={
                                        setServiceSettingsMessageUsersAutoWelcomeMessageLoading ||
                                        subscriberLoading ||
                                        serviceSettingsLoading
                                    }
                                    onClick={() => {
                                        dialog
                                            .confirm({
                                                message: `Are you sure you want to reset the whole form? Everything you've entered will be cleared.`,
                                                title: 'Confirm',
                                                ok: {
                                                    text: 'Reset',
                                                    variant: 'contained',
                                                    color: 'secondary',
                                                },
                                                cancel: { text: 'Cancel' },
                                            })
                                            .then(() => {
                                                resetForm();
                                            })
                                            .catch(() => {});
                                    }}
                                >
                                    Clear
                                </Button>
                            </Grid>
                        </Grid>
                    </StyledCard>
                </>
            )}
        </PageContainer>
    );
};

export default MessageUsersAutoSendWelcomeMessageNewTrialSubscriber;
