import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Theme } from '@mui/material';
import { useEffect, useState } from 'react';
import StyledCardTitleBar from '../../../common/StyledCardTitleBar';
import SextforceAutoRepostShareDialogTelegram from './SextforceAutoRepostShareDialogTelegram';

type Props = {
    open: boolean;
    onClose: () => void;
    subscriber: any | null;
    autoRepost: any;
    theme: Theme;
};

const SextforceAutoRepostShareDialog = (props: Props) => {
    const { open, onClose, subscriber, autoRepost, theme } = props;

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [telegramLink, setTelegramLink] = useState<string | null>(null);

    useEffect(() => {
        if (!open) {
            setIsLoading(false);
            setTelegramLink(null);
        }
    }, [open]);

    const handleClose = (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => {
        if (reason && reason === 'backdropClick') {
            return;
        }

        onClose();
    };

    return (
        <>
            {subscriber && autoRepost ? (
                <Dialog open={open} onClose={handleClose} disableEscapeKeyDown>
                    <DialogTitle>Share Auto Repost Stats</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Share repost statistics with your colleagues every time a repost is published. They will see the same stats you
                            see excluding the earnings.
                        </DialogContentText>
                    </DialogContent>

                    <StyledCardTitleBar title="Telegram" theme={theme} />
                    <Box
                        sx={{
                            p: 3,
                            bgcolor: 'grey.100',
                            borderBottomColor: theme.palette.primary.main,
                            borderBottomStyle: 'solid',
                            borderBottomWidth: 1,
                        }}
                    >
                        <SextforceAutoRepostShareDialogTelegram
                            subscriber={subscriber}
                            autoRepost={autoRepost}
                            telegramLink={telegramLink}
                            setTelegramLink={setTelegramLink}
                            theme={theme}
                        />
                    </Box>

                    <DialogActions>
                        <Button
                            color="secondary"
                            size="medium"
                            disabled={isLoading}
                            onClick={() => {
                                handleClose({}, 'escapeKeyDown');
                            }}
                        >
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            ) : (
                <Dialog open={open} onClose={handleClose}>
                    No Data
                    <DialogActions>
                        <Button
                            size="medium"
                            onClick={() => {
                                handleClose({}, 'escapeKeyDown');
                            }}
                        >
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </>
    );
};

export default SextforceAutoRepostShareDialog;
