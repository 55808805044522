import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Collapse, IconButton, Stack, TableCell, TableRow, Theme, useTheme } from '@mui/material';
import moment from 'moment';
import { useState } from 'react';
import { ScheduledTask } from '../../../../hooks/useMessageUsersScheduledSessions';
import { stripHtml, targetUsersName } from '../../../../utils/common';
import MessageUsersScheduledListRowDetails from './MessageUsersScheduledListRowDetails';
import MessageUsersScheduledListRowLocalMenu from './MessageUsersScheduledListRowLocalMenu';

const MessageUsersScheduledListRow = (props: { row: ScheduledTask; timezone: string; smallScreen: boolean }) => {
    const { row, timezone, smallScreen } = props;
    const theme: Theme = useTheme();

    const [open, setOpen] = useState(false);

    return (
        <>
            <TableRow
                key={row._id}
                sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                    cursor: 'pointer',
                }}
            >
                <TableCell padding="none" onClick={() => setOpen(!open)}>
                    <IconButton size="small">{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</IconButton>
                </TableCell>
                <TableCell onClick={() => setOpen(!open)}>{moment(row.scheduleDateTime).tz(timezone).format('L LT')}</TableCell>
                <TableCell onClick={() => setOpen(!open)}>{targetUsersName(row.payload.usersType, {})}</TableCell>
                {smallScreen && (
                    <TableCell
                        onClick={() => setOpen(!open)}
                        sx={{
                            display: { xs: 'none', sm: 'table-cell' },
                        }}
                    >
                        {stripHtml(row.payload.message)
                            .split('\n')
                            .map((i: string, key: number) => {
                                return <div key={key}>{i}</div>;
                            })}
                    </TableCell>
                )}
                <TableCell align="center">
                    <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
                        <MessageUsersScheduledListRowLocalMenu scheduledTask={row} />
                    </Stack>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell
                    style={{
                        paddingBottom: 1,
                        paddingTop: 0,
                        paddingLeft: 1,
                        paddingRight: 1,
                        backgroundColor: theme.palette.grey[50],
                        borderBottom: open ? `1px solid ${theme.palette.divider}` : 'none',
                    }}
                    colSpan={6}
                >
                    <Collapse
                        in={open}
                        timeout="auto"
                        unmountOnExit
                        sx={{ maxWidth: { sm: theme.breakpoints.values.sm, md: theme.breakpoints.values.md } }}
                    >
                        {open && <MessageUsersScheduledListRowDetails row={row} timezone={timezone} smallScreen={smallScreen} />}
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};

export default MessageUsersScheduledListRow;
