import { Box, Card, CardContent, Grid, SxProps } from '@mui/material';

type Props = {
    children: React.ReactNode;
    noCard?: boolean;
    noBottomMargin?: boolean;
    sx?: SxProps;
};

const StyledCard = (props: Props) => {
    return (
        <Grid container spacing={1} flexGrow={0} alignItems="center" justifyContent="center" sx={{ mb: props.noBottomMargin ? 0 : 1 }}>
            <Grid item xs={12} md={6}>
                {props.noCard ? (
                    <Box sx={props.sx}>{props.children}</Box>
                ) : (
                    <Card variant="elevation">
                        <CardContent sx={props.sx}>{props.children}</CardContent>
                    </Card>
                )}
            </Grid>
        </Grid>
    );
};

export default StyledCard;
