import { Skeleton } from '@mui/material';
import dinero from 'dinero.js';
import useSextforceMetricsCrossReferenceMetricEarnings from '../../../../hooks/useSextforceMetricsCrossReferenceMetricEarnings';
import { d2f } from '../../../../utils/common';

type Props = {
    metricId: string;
    type: 'campaign' | 'trial';
    crossReferenceSubscriberId: string;
    displayMode: 'gross' | 'net';
};

const SextforceCrossReferenceEarningsLabel = (props: Props) => {
    const { metricId, type, crossReferenceSubscriberId } = props;

    const { data: sextforceMetricsCrossReferenceMetricEarnings, isLoading: sextforceMetricsCrossReferenceMetricEarningsLoading } =
        useSextforceMetricsCrossReferenceMetricEarnings(
            metricId,
            type,
            crossReferenceSubscriberId === 'none' ? '' : crossReferenceSubscriberId,
        );

    const factor: number = props.displayMode === 'net' ? 0.8 : 1;

    return (
        <>
            {sextforceMetricsCrossReferenceMetricEarningsLoading ? (
                <Skeleton width={'100%'} height={30} />
            ) : (
                <span style={{ fontFamily: 'monospace' }}>
                    {dinero({
                        amount: sextforceMetricsCrossReferenceMetricEarnings
                            ? Math.trunc(d2f(sextforceMetricsCrossReferenceMetricEarnings.total) * 100 * factor)
                            : 0,
                        currency: 'USD',
                    }).toFormat()}
                </span>
            )}
        </>
    );
};

export default SextforceCrossReferenceEarningsLabel;
