import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { Typography } from '@mui/material';
import { Theme } from '@mui/system';

type Props = {
    theme: Theme;
};

const MessageUsersServiceDescription = (props: Props) => {
    const { theme } = props;
    const params = useParams();

    return (
        <>
            <Typography variant="body1" sx={{ marginBottom: theme.spacing(2) }}>
                OnlyStruggles Message Users service sends a normal message to each target user individually, instead of via a “Mass DM”.
                This way the message ends up in their <strong>priority inbox</strong> and definitely gets delivered. Most importantly, they
                look more genuine – like you intentionally messaged that specific fan – which increases engagement!
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: theme.spacing(2) }}>
                The service is operated from a user-friendly dashboard, allowing you to send and schedule messages, see real-time progress,
                manage scheduled messages, see reports, repeat old messages and unsend messages.
            </Typography>

            <Typography variant="h6" sx={{ marginBottom: theme.spacing(2) }}>
                <strong>Message Online Fans</strong>
            </Typography>

            <Typography variant="body1" sx={{ marginBottom: theme.spacing(2) }}>
                The service allows you to target different groups of fans, but crucially, you can message{' '}
                <strong>all those who are online RIGHT NOW!</strong> They are on the website, they are in the mood, and suddenly they get a
                message from you --- that's a conversation starter! It works extremely well, and test have shown it generates significant
                sales!
            </Typography>

            <Typography variant="h6" sx={{ marginBottom: theme.spacing(2) }}>
                <strong>Smart Exclusion</strong>
            </Typography>

            <Typography variant="body1" sx={{ marginBottom: theme.spacing(2) }}>
                Versatile exclusion options allow you to target only just those fans you want. Control spending by limit the number of
                messages sent, exclude fans in any of your lists (select multiple lists!), exclude those who spend over or less any amount
                and within any period of following you, and exclude those who the service already messaged!
            </Typography>

            <Typography variant="h6" sx={{ marginBottom: theme.spacing(2) }}>
                <strong>Send Now or Later</strong>
            </Typography>

            <Typography variant="body1" sx={{ marginBottom: theme.spacing(2) }}>
                Send now, or schedule as many messages for later as you want. Schedule messages for your sexters shifts so they have strong
                engagement to work with while you’re busy managing your business.
            </Typography>

            <Typography variant="h6" sx={{ marginBottom: theme.spacing(2) }}>
                <strong>Include Photos/Videos</strong>
            </Typography>

            <Typography variant="body1" sx={{ marginBottom: theme.spacing(2) }}>
                Include photos or videos from your Vault straight from within the dashboard!
            </Typography>

            <Typography variant="h6" sx={{ marginBottom: theme.spacing(2) }}>
                <strong>Include Usernames</strong>
            </Typography>

            <Typography variant="body1" sx={{ marginBottom: theme.spacing(2) }}>
                Edit the message to your liking right in the dashboard with support for all the OnlyFans available styling. You can even add
                automatic names for each user in the message!
            </Typography>

            <Typography variant="h6" sx={{ marginBottom: theme.spacing(2) }}>
                <strong>Unsend!!!</strong>
            </Typography>

            <Typography variant="body1" sx={{ marginBottom: theme.spacing(2) }}>
                Unsend any message that was sent within the last 24 hours!!! Each individual message for the whole session will be unsent at
                the click of a button!
            </Typography>

            <Typography variant="h6" sx={{ marginBottom: theme.spacing(2) }}>
                <strong>Analytics!!!</strong>
            </Typography>

            <Typography variant="body1" sx={{ marginBottom: theme.spacing(2) }}>
                Transparent Return on Investment (ROI)! For those who have the <strong>Sextforce</strong> service, you will see exactly how
                much each message session made you in revenue! See what the users who received your message spent in however many hours
                after they received the message.
            </Typography>

            <Typography variant="h6" sx={{ marginBottom: theme.spacing(2) }}>
                <strong>Price</strong>
            </Typography>

            <Typography variant="body1" sx={{ marginBottom: theme.spacing(2) }}>
                Message Users uses the same shared credit the Follow-Back service uses so you don’t need to worry about managing different
                budgets. And like the Follow-Back service, Message Users is charged as “pay as you go” at the same £0.012 GBP (about $0.0144
                USD) per successful message sent – you only pay for the messages they get delivered.
            </Typography>

            <Typography variant="body1" sx={{ marginBottom: theme.spacing(2) }}>
                <strong>
                    The service is available NOW in your dashboard –{' '}
                    <Link
                        to={`/subscribers/${'userId' in params && params.userId}/services/messageUsers/sendMessage`}
                        style={{ textDecoration: 'none', fontWeight: 'bold', color: theme.palette.primary.main }}
                    >
                        go try it
                    </Link>
                    !
                </strong>
            </Typography>
        </>
    );
};

export default MessageUsersServiceDescription;
