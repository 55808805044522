import axios from 'axios';
import { useDialog } from 'muibox';
import { useQuery } from 'react-query';
import { handleHttpError } from '../utils/common';

const useGlobalSettings = () => {
    const dialog = useDialog();

    // Fetch Follow-Back Overview
    const fetchGlobalSettings = async (): Promise<any> => {
        const query: string = `https://getdashboardsettings-muw7j6iauq-ew.a.run.app`;

        return axios
            .get(query)
            .then(async response => response.data)
            .catch(error => {
                console.error(error);
                handleHttpError(error, dialog);
            });
    };

    const subscriber = useQuery(
        ['globalSettings'],
        () => {
            return fetchGlobalSettings();
        },
        {
            refetchOnWindowFocus: false,
            // Stale for 24 hours
            staleTime: 1000 * 60 * 60 * 24,
            enabled: true,
        },
    );

    return {
        ...subscriber,
    };
};

export default useGlobalSettings;
