import axios from 'axios';
import { useDialog } from 'muibox';
import { useContext, useState } from 'react';
import { SettingsContext } from '../store/SettingsContext';
import { UserContext } from '../store/UserContext';
import { handleHttpError } from '../utils/common';

const useHostessStatus = () => {
    const userContext = useContext(UserContext);
    const settingsContext = useContext(SettingsContext);
    const dialog = useDialog();

    const [setSubscriberHostessStatusLoading, setSetSubscriberHostessStatusLoading] = useState<boolean>(false);

    const setSubscriberHostessStatus = async (subscriberId: string, action: 'add' | 'remove'): Promise<any> => {
        if (userContext.jwtToken && settingsContext.apiKey && userContext.user.username) {
            const query: string = `${settingsContext.routes.subscribers.base}${subscriberId}/hostess/status`;

            setSetSubscriberHostessStatusLoading(true);

            return axios(query, {
                method: action === 'add' ? 'POST' : 'DELETE',
                headers: {
                    Authorization: userContext.jwtToken,
                    apiKey: settingsContext.apiKey,
                },
            })
                .then(async response => {
                    setSetSubscriberHostessStatusLoading(false);

                    return response.data;
                })
                .catch(error => {
                    console.error('setSubscriberHostessStatus', error);
                    setSetSubscriberHostessStatusLoading(false);
                    handleHttpError(error, dialog);
                });
        }
    };

    return { setSubscriberHostessStatus, setSubscriberHostessStatusLoading };
};

export default useHostessStatus;
