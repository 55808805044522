import { Grid, ToggleButton, ToggleButtonGroup } from '@mui/material';

type Props = {
    loading: boolean;
    selectorType: { includeInactive: boolean };
    setSelectorType: (types: { includeInactive: boolean }) => void;
};

const SextforceMetricsCampaignsOverviewSelector = (props: Props) => {
    const { loading, selectorType, setSelectorType } = props;

    return (
        <Grid container spacing={1} alignItems="center">
            <Grid item xs={12} md="auto">
                <Grid container spacing={1} alignItems="center">
                    <Grid item xs>
                        <ToggleButtonGroup
                            value={selectorType.includeInactive ? 'true' : 'false'}
                            exclusive
                            fullWidth
                            color="secondary"
                            size={'small'}
                            disabled={loading}
                            onChange={(e, value: string) => {
                                if (!value) {
                                    return;
                                }

                                setSelectorType({
                                    includeInactive: value === 'true',
                                });

                                localStorage.setItem('sextforceMetricsCampaignsOverviewSelectorType', value);
                            }}
                            sx={{ marginTop: '4px', height: '39px' }}
                        >
                            <ToggleButton color="secondary" fullWidth value={'false'} sx={{ minWidth: '120px' }}>
                                Hide Deleted
                            </ToggleButton>
                            <ToggleButton color="secondary" fullWidth value={'true'} sx={{ minWidth: '120px' }}>
                                Show Deleted
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default SextforceMetricsCampaignsOverviewSelector;
