import { useDialog } from 'muibox';
import { useContext, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { SettingsContext } from '../store/SettingsContext';
import { UserContext } from '../store/UserContext';
import { handleHttpError } from '../utils/common';
import useAxios from './useAxios';
import { OnlyFansMetricsWithSubscriber } from './useSextforceMetricsCampaignsOverview';

export interface OnlyFansMetricsGroupBase {
    _id?: string;
    subscriberId: string;
    name: string;
    includesCampaigns: boolean;
    includesTrials: boolean;
    includesInactive: boolean;
    createdAt?: Date;
    updatedAt?: Date;
}

export interface OnlyFansMetricsGroup extends OnlyFansMetricsGroupBase {
    metricIds: string[];
}

export interface OnlyFansMetricsGroupWithSummary extends OnlyFansMetricsGroupBase {
    earningsTotal: object | number;
    subscribersTotal: number;
    claimsTotal: number;
    metrics: OnlyFansMetricsWithSubscriber[];
}

const useSextforceMetricsGroups = (metricType: 'trial' | 'campaign') => {
    const userContext = useContext(UserContext);
    const settingsContext = useContext(SettingsContext);
    const dialog = useDialog();
    const params = useParams();
    const queryClient = useQueryClient();
    const axios = useAxios();

    const [isGroupSaving, setIsGroupSaving] = useState<boolean>(false);

    // Fetch Fans Counters
    const fetchMetricsGroups = async (): Promise<OnlyFansMetricsGroupWithSummary[]> => {
        const query: string = `${settingsContext.routes.metrics.base}${params.userId}/groups?metricType=${metricType}`;

        const data = await axios
            .get(query)
            .then(async response => response.data as OnlyFansMetricsGroupWithSummary[])
            .catch(error => {
                console.error(error);
                handleHttpError(error, dialog);

                return [];
            });

        return data;
    };

    const metricsGroupCreate = async (group: OnlyFansMetricsGroup) => {
        const doSave = async () => {
            setIsGroupSaving(true);

            await axios
                .post(`${settingsContext.routes.metrics.base}${params.userId}/groups`, group)
                .then(response => {
                    setIsGroupSaving(false);

                    if (response.data && response.status === 200) {
                        // Update the cache (optimistic update)
                        queryClient.invalidateQueries(['metricsGroups', userContext.jwtToken, params.userId]);
                    } else {
                        dialog.alert({ title: 'Error', message: 'Failed to save group.' }).then(() => {});
                    }
                })
                .catch(error => {
                    setIsGroupSaving(false);

                    console.error(error);

                    dialog
                        .alert({
                            title: 'Error',
                            message: error.response?.data?.message || 'Failed to save group.',
                        })
                        .then(() => {});
                });
        };

        doSave();
    };

    const metricsGroupUpdate = async (group: OnlyFansMetricsGroup) => {
        const doSave = async () => {
            setIsGroupSaving(true);

            await axios
                .put(`${settingsContext.routes.metrics.base}${params.userId}/groups/${group._id}`, group)
                .then(response => {
                    setIsGroupSaving(false);

                    if (response.data && response.status === 200) {
                        // Invalidate the cache
                        queryClient.invalidateQueries(['metricsGroups', userContext.jwtToken, params.userId]);
                    } else {
                        dialog.alert({ title: 'Error', message: 'Failed to save group.' }).then(() => {});
                    }
                })
                .catch(error => {
                    setIsGroupSaving(false);

                    console.error(error);

                    dialog
                        .alert({
                            title: 'Error',
                            message: error.response?.data?.message || 'Failed to save group.',
                        })
                        .then(() => {});
                });
        };

        doSave();
    };

    const metricsGroupDelete = async (groupId: string) => {
        const doDelete = async () => {
            setIsGroupSaving(true);

            await axios
                .delete(`${settingsContext.routes.metrics.base}${params.userId}/groups/${groupId}`)
                .then(response => {
                    setIsGroupSaving(false);

                    if (response.status === 200) {
                        // Invalidate the cache
                        queryClient.invalidateQueries(['metricsGroups', userContext.jwtToken, params.userId]);
                    } else {
                        dialog.alert({ title: 'Error', message: 'Failed to delete group.' }).then(() => {});
                    }
                })
                .catch(error => {
                    setIsGroupSaving(false);

                    console.error(error);

                    dialog
                        .alert({
                            title: 'Error',
                            message: error.response?.data?.message || 'Failed to delete group.',
                        })
                        .then(() => {});
                });
        };

        doDelete();
    };

    const metricsGroupsQuery = useQuery(['metricsGroups', userContext.jwtToken, params.userId, metricType], () => fetchMetricsGroups(), {
        refetchOnWindowFocus: false,
        staleTime: 60 * 1000 * 5,
        enabled: userContext.jwtToken && settingsContext.apiKey && params.userId ? true : false,
    });

    return { ...metricsGroupsQuery, metricsGroupCreate, metricsGroupUpdate, metricsGroupDelete, isGroupSaving };
};

export default useSextforceMetricsGroups;
