import { LinearProgress, Theme, Typography } from '@mui/material';

const StyledCardTitleBar = ({ title, isLoading, theme }: { title: string; isLoading?: boolean; theme: Theme }) => {
    return (
        <>
            <div
                style={{
                    backgroundColor: theme.palette.primary.main,
                    minHeight: theme.spacing(6),
                    borderBottomWidth: 2,
                    borderBottomColor: theme.palette.secondary.main,
                    borderBottomStyle: 'solid',
                    textTransform: 'uppercase',
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    paddingLeft: theme.spacing(2),
                }}
            >
                <Typography variant="subtitle1" color="white">
                    {title}
                </Typography>
            </div>
            {isLoading && <LinearProgress />}
        </>
    );
};

export default StyledCardTitleBar;
