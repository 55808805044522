import { Container, SxProps, Theme } from '@mui/material';

const PageContainer = ({ children, sx }: { children: React.ReactNode; sx?: SxProps<Theme> }) => {
    return (
        <Container
            maxWidth={false}
            sx={{
                paddingTop: 2,
                paddingBottom: 2,
                paddingLeft: { xs: 1, sm: 2 },
                paddingRight: { xs: 1, sm: 2 },
                minHeight: '100%',
                ...sx,
            }}
        >
            {children}
        </Container>
    );
};

export default PageContainer;
