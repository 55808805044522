import { Checkbox, FormControlLabel, Grid, useTheme } from '@mui/material';
import { Theme } from '@mui/system';
import { ChangeEvent, useEffect, useState } from 'react';

type OptionsProps = {
    setOptions: (options: any) => void;
};

export const SextforceMetricsGroupsShareDialogOptions = (props: OptionsProps) => {
    const { setOptions } = props;
    const theme: Theme = useTheme();

    const [countSubscribers, setCountSubscribers] = useState<boolean>(true);
    const [countTransitions, setCountTransitions] = useState<boolean>(true);
    const [totalEarnings, setTotalEarnings] = useState<boolean>(false);
    const [subscribersGraph, setSubscribersGraph] = useState<boolean>(true);

    useEffect(() => {
        setOptions({
            ...(countSubscribers && { countSubscribers }),
            ...(countTransitions && { countTransitions }),
            ...(totalEarnings && { totalEarnings }),
            ...(subscribersGraph && { subscribersGraph }),
        });
    }, [countSubscribers, countTransitions, setOptions, subscribersGraph, totalEarnings]);

    return (
        <Grid container spacing={0} alignItems="center" flexGrow={1} sx={{ marginBottom: 2 }}>
            <Grid item xs={12}>
                <FormControlLabel
                    control={
                        <Checkbox
                            size="medium"
                            checked={countSubscribers}
                            onChange={(e: ChangeEvent, checked: boolean) => {
                                setCountSubscribers(checked);

                                if (!checked) {
                                    setSubscribersGraph(false);
                                }
                            }}
                        />
                    }
                    label="Total Subscribed/Claimed"
                />
            </Grid>
            <Grid item xs={12}>
                <FormControlLabel
                    control={
                        <Checkbox
                            size="medium"
                            checked={countTransitions}
                            onChange={(e: ChangeEvent, checked: boolean) => {
                                setCountTransitions(checked);
                            }}
                        />
                    }
                    label="Total Clicked/Subscribed"
                />
            </Grid>
            <Grid item xs={12}>
                <FormControlLabel
                    control={
                        <Checkbox
                            size="medium"
                            checked={totalEarnings}
                            onChange={(e: ChangeEvent, checked: boolean) => {
                                setTotalEarnings(checked);
                            }}
                        />
                    }
                    label={
                        <span>
                            Total Earned (<strong style={{ color: theme.palette.error.main }}>Careful!</strong>)
                        </span>
                    }
                />
            </Grid>
            <Grid item xs={12}>
                <FormControlLabel
                    control={
                        <Checkbox
                            size="medium"
                            disabled={!countSubscribers}
                            checked={subscribersGraph}
                            onChange={(e: ChangeEvent, checked: boolean) => {
                                setSubscribersGraph(checked);
                            }}
                        />
                    }
                    label="Subscribtions/Claims Count and Clicks Count by Date Graph"
                />
            </Grid>
        </Grid>
    );
};
