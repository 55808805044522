import EditIcon from '@mui/icons-material/Edit';
import ShareIcon from '@mui/icons-material/Share';
import WavingHandIcon from '@mui/icons-material/WavingHand';
import { Button, Card, CardContent, Grid, Stack, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { useEffect } from 'react';
import { OnlyFansMetrics } from '../../../../hooks/useSextforceMetricsCampaignsOverview';

type Props = {
    metricData: OnlyFansMetrics | null;
    showEarningsWithSubscriptions: boolean;
    setShowEarningsWithSubscriptions: (value: boolean) => void;
    showEarningsAsGross: boolean;
    setShowEarningsAsGross: (value: boolean) => void;
    setEditSettingsDialogOpen: (value: boolean) => void;
    setWelcomeMessageDialogOpen: (value: boolean) => void;
    setShareDialogOpen: (value: boolean) => void;
};

const SextforceMetricsEarningsSettingsCard = (props: Props) => {
    const {
        metricData,
        showEarningsWithSubscriptions,
        setShowEarningsWithSubscriptions,
        showEarningsAsGross,
        setShowEarningsAsGross,
        setEditSettingsDialogOpen,
        setWelcomeMessageDialogOpen,
        setShareDialogOpen,
    } = props;

    useEffect(() => {
        localStorage.setItem('showEarningsWithSubscriptions', showEarningsWithSubscriptions ? 'true' : 'false');
    }, [showEarningsWithSubscriptions]);

    useEffect(() => {
        localStorage.setItem('showEarningsAsGross', showEarningsAsGross ? 'true' : 'false');
    }, [showEarningsAsGross]);

    return (
        <Card>
            <CardContent>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12} sm="auto">
                        <Stack direction="column" justifyContent="center" spacing={0.5}>
                            <Typography variant="subtitle2">Earnings</Typography>
                            <ToggleButtonGroup
                                value={showEarningsAsGross ? 'gross' : 'net'}
                                exclusive
                                color="secondary"
                                size={'small'}
                                fullWidth
                                onChange={(_event, newValue) => {
                                    if (!newValue) {
                                        return;
                                    }

                                    setShowEarningsAsGross(newValue === 'gross');
                                }}
                            >
                                <ToggleButton value="gross" fullWidth sx={{ minWidth: '80px' }}>
                                    GROSS
                                </ToggleButton>
                                <ToggleButton value="net" fullWidth sx={{ minWidth: '80px' }}>
                                    NET
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm="auto">
                        <Stack direction="column" justifyContent="center" spacing={0.5}>
                            <Typography variant="subtitle2">Subscription Earnings</Typography>
                            <ToggleButtonGroup
                                value={showEarningsWithSubscriptions ? 'with' : 'without'}
                                exclusive
                                color="secondary"
                                size={'small'}
                                fullWidth
                                onChange={(_event, newValue) => {
                                    if (!newValue) {
                                        return;
                                    }

                                    setShowEarningsWithSubscriptions(newValue === 'with');
                                }}
                            >
                                <ToggleButton value="with" fullWidth sx={{ minWidth: '80px' }}>
                                    With
                                </ToggleButton>
                                <ToggleButton value="without" fullWidth sx={{ minWidth: '80px' }}>
                                    Without
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </Stack>
                    </Grid>
                    <Grid item xs={12} sm="auto">
                        <Stack direction="column" justifyContent="center" spacing={0.5}>
                            <Typography variant="subtitle2">Name and Goals</Typography>
                            <Button
                                variant="contained"
                                color="primary"
                                size="medium"
                                disabled={!metricData}
                                endIcon={<EditIcon />}
                                fullWidth
                                onClick={() => {
                                    setEditSettingsDialogOpen(true);
                                }}
                            >
                                Edit...
                            </Button>
                        </Stack>
                    </Grid>
                    {metricData?.type === 'trialLinkTrial' && (
                        <Grid item xs={12} sm="auto">
                            <Stack direction="column" justifyContent="center" spacing={0.5}>
                                <Typography variant="subtitle2">Welcome Message</Typography>
                                <Button
                                    variant={metricData && metricData.welcomeMessage ? 'contained' : 'outlined'}
                                    color="info"
                                    size="medium"
                                    disabled={!metricData}
                                    endIcon={<WavingHandIcon />}
                                    fullWidth
                                    onClick={() => {
                                        setWelcomeMessageDialogOpen(true);
                                    }}
                                >
                                    {metricData && metricData.welcomeMessage ? 'Edit' : 'Create'}
                                </Button>
                            </Stack>
                        </Grid>
                    )}
                    <Grid item xs={12} sm="auto">
                        <Stack direction="column" justifyContent="center" spacing={0.5}>
                            <Typography variant="subtitle2">Share Stats</Typography>
                            <Button
                                variant="contained"
                                color="secondary"
                                size="medium"
                                disabled={!metricData}
                                endIcon={<ShareIcon />}
                                fullWidth
                                onClick={() => {
                                    setShareDialogOpen(true);
                                }}
                            >
                                Share...
                            </Button>
                        </Stack>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default SextforceMetricsEarningsSettingsCard;
