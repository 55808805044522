import { useEffect, useState, useContext, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Typography, Grid, Button, Dialog, DialogActions, DialogContent, DialogTitle, Alert, Box } from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';
import { Theme } from '@mui/system';
import { UserContext } from '../../../../store/UserContext';
import { SettingsContext } from '../../../../store/SettingsContext';
import { copyTextToClipboard } from '../../../../utils/common';
import ErrorMessage from '../../../ErrorMessage';

type SextforceManageAgentsSalesProofLinkDialogProps = {
    agent: any | null;
    open: boolean;
    onCancel: () => void;
};

const SextforceManageAgentsSalesProofLinkDialog = (props: SextforceManageAgentsSalesProofLinkDialogProps) => {
    const { agent, open, onCancel } = props;
    const userContext = useContext(UserContext);
    const settingsContext = useContext(SettingsContext);
    const params = useParams();
    const theme: Theme = useTheme();
    const [error, setError] = useState<string | null>(null);
    const refError = useRef<HTMLDivElement | null>(null);
    const [urlId, setUrlId] = useState<string | null>(null);
    const [copied, setCopied] = useState<boolean>(false);

    useEffect(() => {
        if (open) {
            if (userContext.jwtToken && settingsContext.apiKey && 'userId' in params && params.userId) {
                const query: string = `${settingsContext.routes.sextforce.base}${params.userId}/agents/${agent._id}/urlId`;

                fetch(query, {
                    method: 'get',
                    headers: {
                        Authorization: userContext.jwtToken,
                        apiKey: settingsContext.apiKey,
                    },
                })
                    .then(async response => {
                        if (response.ok) {
                            setError(null);

                            return response.json();
                        } else {
                            const error = await response.json();

                            setError(`${response.status} - ${error.message} ${'extra' in error ? `(${error.extra})` : null}`);
                        }
                    })
                    .then(data => {
                        setUrlId(data.urlId);
                    })
                    .catch(error => {
                        setError(`${error.status} - ${error.message} ${'extra' in error ? `(${error.extra})` : null}`);
                    });
            }
        }
    }, [
        open,
        agent,
        userContext.jwtToken,
        settingsContext.apiKey,
        settingsContext.routes.sextforce.publicAgent,
        params,
        settingsContext.routes.sextforce.base,
    ]);

    useEffect(() => {
        if (open) {
            setCopied(false);
        }
    }, [open]);

    return (
        <Dialog fullWidth={true} maxWidth="md" open={open} onClose={onCancel}>
            <DialogTitle>Agent's Sales Proof Submission Link</DialogTitle>
            <DialogContent>
                <ErrorMessage error={error} refProp={refError} />
                <Grid container flexGrow={1} alignItems="center" spacing={2} sx={{ marginBottom: theme.spacing(4) }}>
                    <Grid item xs={12}>
                        <Alert variant="filled" color="warning">
                            <Typography variant="body1">
                                WARNING: The link below is <strong>private</strong> to each agent! Do not share the same link with more than
                                one agent and make sure to revoke it when the agents stops working for you.
                            </Typography>
                        </Alert>
                    </Grid>
                </Grid>
                <Grid
                    container
                    flexGrow={1}
                    alignItems="center"
                    spacing={2}
                    sx={{ marginTop: theme.spacing(4), marginBottom: theme.spacing(4) }}
                >
                    <Grid item xs={12}>
                        {agent && urlId && (
                            <Box
                                sx={{
                                    width: '100%',
                                    cursor: 'pointer',
                                    border: '1px solid',
                                    borderRadius: 1,
                                    padding: 1,
                                    background: copied ? theme.palette.success.light : theme.palette.grey['300'],
                                    ':hover': { backgroundColor: copied ? theme.palette.success.light : theme.palette.info.light },
                                }}
                                onClick={async () => {
                                    await copyTextToClipboard(`https://dashboard.onlystruggles.com/sextforce/agent/${urlId}`);
                                    setCopied(true);
                                }}
                            >
                                <Typography variant="body1" textAlign={'center'} noWrap>
                                    {`https://dashboard.onlystruggles.com/sextforce/agent/${urlId}`}
                                </Typography>
                            </Box>
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body1" textAlign={'center'}>
                            {copied ? 'Copied to Clipboard 👍' : 'Click on the link above to copy to clipboard'}
                        </Typography>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        setUrlId(null);
                        onCancel();
                    }}
                >
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default SextforceManageAgentsSalesProofLinkDialog;
