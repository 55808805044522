import { useDialog } from 'muibox';
import { useContext } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { SettingsContext } from '../store/SettingsContext';
import { UserContext } from '../store/UserContext';
import { OnlyFansSubscriberMessageUsersAutoMessageDetails } from '../types/messageUsersAutoSendWelcomeMessage';
import { handleHttpError } from '../utils/common';
import useAxios from './useAxios';

export interface OnlyFansMetricsWelcomeMessage {
    _id?: string;
    subscriberId: string;
    metricId: string;
    messageDetails: OnlyFansSubscriberMessageUsersAutoMessageDetails;
    createdAt?: Date;
    updatedAt?: Date;
}

const useSextforceMetricsWelcomeMessage = (metricId: string) => {
    const userContext = useContext(UserContext);
    const settingsContext = useContext(SettingsContext);
    const dialog = useDialog();
    const params = useParams();
    const axios = useAxios();

    // Fetch the welcome message for a specific metric
    const fetchMetricWelcomeMessage = async (metricId: string): Promise<OnlyFansMetricsWelcomeMessage | null> => {
        const query: string = `${settingsContext.routes.metrics.base}${params.userId}/trials/${metricId}/autoWelcomeMessage`;

        const data = await axios
            .get(query)
            .then(async response => response.data)
            .catch(error => {
                console.error(error);

                handleHttpError(error, dialog);

                return null;
            });

        return data;
    };

    return useQuery(['metricsWelcomeMessage', userContext.jwtToken, params.userId, metricId], () => fetchMetricWelcomeMessage(metricId), {
        refetchOnWindowFocus: false,
        staleTime: 60 * 1000 * 5,
        enabled: userContext.jwtToken && settingsContext.apiKey && params.userId && metricId ? true : false,
    });
};

export default useSextforceMetricsWelcomeMessage;
