import CurrencyPoundIcon from '@mui/icons-material/CurrencyPound';
import { Alert, Theme, useMediaQuery } from '@mui/material';

const MessageUsersPriceBar = (props: { unitCost: number; includedWith?: string; theme: Theme }) => {
    const { unitCost, includedWith, theme } = props;
    const matches = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Alert
            severity={unitCost === 0 ? 'success' : 'info'}
            icon={<CurrencyPoundIcon />}
            sx={{ marginTop: theme.spacing(1), fontSize: theme.typography.body1.fontSize }}
        >
            {unitCost === 0 && (
                <>
                    Free!
                    {includedWith && ` (included with ${includedWith})`}
                </>
            )}
            {unitCost > 0 && (
                <>
                    £{unitCost} GBP (about {unitCost * 1.2} USD)
                    {matches ? <br /> : ' '}
                    per each successful message delivered
                </>
            )}
        </Alert>
        // <Grid
        //     container
        //     spacing={1}
        //     flexGrow={0}
        //     alignItems="center"
        //     sx={{
        //         backgroundColor: unitCost === 0 ? theme.palette.success.light : theme.palette.info.main,
        //         borderColor: theme.palette.info.dark,
        //         borderStyle: 'solid',
        //         borderWidth: 1,
        //         borderRadius: theme.shape.borderRadius,
        //         marginTop: theme.spacing(1),
        //         paddingLeft: theme.spacing(1),
        //         paddingRight: theme.spacing(1),
        //     }}
        // >
        //     <Grid
        //         item
        //         xs={'auto'}
        //         sx={{
        //             paddingBottom: theme.spacing(1),
        //             paddingLeft: theme.spacing(1),
        //             paddingRight: theme.spacing(1),
        //         }}
        //     >
        //         <Typography textAlign={'center'} sx={{ fontWeight: 'bold', color: theme.palette.common.white }}>
        //             PRICE
        //         </Typography>
        //     </Grid>
        //     <Grid item xs sx={{ color: theme.palette.common.white, paddingBottom: theme.spacing(1) }}>
        //         {unitCost === 0 && (
        //             <>
        //                 Free!
        //                 {includedWith && ` (included with ${includedWith})`}
        //             </>
        //         )}
        //         {unitCost > 0 && (
        //             <>
        //                 £{unitCost} GBP (about ${unitCost * 1.2} USD)
        //                 {matches ? <br /> : ' '}
        //                 per each successful message sent
        //             </>
        //         )}
        //     </Grid>
        // </Grid>
    );
};

export default MessageUsersPriceBar;
