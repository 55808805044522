import { SvgIcon, SxProps, Theme } from '@mui/material';

type IconOnlyFansProps = {
    fontSize?: 'small' | 'inherit' | 'large' | 'medium' | undefined;
    sx?: SxProps<Theme>;
};

const IconOnlyFansAccount = (props: IconOnlyFansProps) => {
    const { fontSize, sx } = props;

    return (
        <SvgIcon fontSize={fontSize} sx={sx}>
            <path
                xmlns="http://www.w3.org/2000/svg"
                d="M12 2a10 10 0 0 0 0 20c.55 0 5-.3 5-1.94a1 1 0 0 0-1-1c-.64 0-1.52.94-4 .94a8 8 0 1 1 8-8c0 1.23-.14 3-1.59 3A1.41 1.41 0 0 1 17 13.59V12a5 5 0 1 0-1.42 3.49A3.43 3.43 0 0 0 18.41 17c2 0 3.59-1.38 3.59-5A10 10 0 0 0 12 2zm0 13a3 3 0 1 1 3-3 3 3 0 0 1-3 3z"
            />
        </SvgIcon>
    );
};

export default IconOnlyFansAccount;
