import { faCommentSms, faEnvelope, faUserSecret } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, Stack, Theme, ToggleButton, ToggleButtonGroup, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useEffect } from 'react';
import { OnlyFansLoginResponse } from '../../hooks/useSubscriberRelogin';

const countOtpOptions = (otpState: OnlyFansLoginResponse.OtpState): number => {
    let count = 0;

    if (otpState.appOtp) {
        count += 1;
    }

    if (otpState.email) {
        count += 1;
    }

    if (otpState.phoneOtp) {
        count += 1;
    }

    return count;
};

type Props = {
    otpState: OnlyFansLoginResponse.OtpState;
    selectedOtpOption: string | null;
    setSelectedOtpOption: (selectedOtpOption: string | null) => void;
    handleRequestOtpCode: (otpOption: string) => Promise<void>;
};

const OnlyFansOtpSelection = (props: Props) => {
    const { otpState, selectedOtpOption, setSelectedOtpOption, handleRequestOtpCode } = props;
    const theme: Theme = useTheme();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'));

    const otpOptionsCount = countOtpOptions(otpState);

    useEffect(() => {
        if (selectedOtpOption === null && otpOptionsCount === 1) {
            if (otpState.appOtp) {
                setSelectedOtpOption('appOtp');
            } else if (otpState.phoneOtp) {
                setSelectedOtpOption('phoneOtp');
            } else if (otpState.email) {
                setSelectedOtpOption('email');
            }
        }
    }, [otpOptionsCount, otpState.appOtp, otpState.email, otpState.phoneOtp, selectedOtpOption, setSelectedOtpOption]);

    return (
        <Grid container alignItems={'center'} justifyContent={'center'} spacing={1} sx={{ mt: 1 }}>
            {selectedOtpOption === null && (
                <Grid item xs={12}>
                    <Typography variant={'body2'}>Select an 2FA option</Typography>
                </Grid>
            )}
            <Grid item xs={12}>
                <ToggleButtonGroup
                    orientation={isLargeScreen ? 'horizontal' : 'vertical'}
                    value={selectedOtpOption}
                    exclusive
                    fullWidth
                    onChange={(event, newValue) => {
                        if (newValue === null) {
                            return;
                        }

                        setSelectedOtpOption(newValue);

                        if (newValue === 'phoneOtp' || newValue === 'email') {
                            handleRequestOtpCode(newValue);
                        }
                    }}
                >
                    {(selectedOtpOption === null || selectedOtpOption === 'appOtp') && (
                        <ToggleButton value="appOtp" disabled={!otpState.appOtp}>
                            <Stack direction={'row'} spacing={1} alignItems={'center'}>
                                <FontAwesomeIcon icon={faUserSecret} />
                                <span>Authenticator</span>
                            </Stack>
                        </ToggleButton>
                    )}
                    {(selectedOtpOption === null || selectedOtpOption === 'phoneOtp') && (
                        <ToggleButton value="phoneOtp" disabled={!otpState.phoneOtp}>
                            <Stack direction={'row'} spacing={1} alignItems={'center'}>
                                <FontAwesomeIcon icon={faCommentSms} />
                                <span>SMS</span>
                            </Stack>
                        </ToggleButton>
                    )}
                    {(selectedOtpOption === null || selectedOtpOption === 'email') && (
                        <ToggleButton value="email" disabled={!otpState.email}>
                            <Stack direction={'row'} spacing={1} alignItems={'center'}>
                                <FontAwesomeIcon icon={faEnvelope} />
                                <span>Email</span>
                            </Stack>
                        </ToggleButton>
                    )}
                    {/* {(selectedOtpOption === null || selectedOtpOption === 'faceOtp') && (
                        <ToggleButton value="faceOtp" disabled={!otpState.faceOtp}>
                            <Stack direction={'row'} spacing={1} alignItems={'center'}>
                                <FontAwesomeIcon icon={faFaceFrownOpen} />
                                <span>Selfie ID</span>
                            </Stack>
                        </ToggleButton>
                    )} */}
                </ToggleButtonGroup>
            </Grid>
        </Grid>
    );
};

export default OnlyFansOtpSelection;
