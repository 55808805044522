import {
    Button,
    Card,
    CardContent,
    Divider,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    SelectChangeEvent,
    TextField,
} from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';
import { Theme } from '@mui/system';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { getWeekRange } from '../../../../utils/common';
import SelectTimezone from '../../../forms/helpers/SelectTimezone';

// Generate Select menu items for year
const YearMenuItems = () => {
    const menuItems = [];

    for (let i = moment().year(); i > 2000; i -= 1) {
        menuItems.push(
            <MenuItem value={i} key={i}>
                {i}
            </MenuItem>,
        );
    }

    return menuItems;
};

// Generate Select menu items for week of the year
const YearWeekMenuItems = () => {
    const menuItems = [];

    for (let i = 1; i <= 52; i += 1) {
        menuItems.push(
            <MenuItem value={i} key={i}>
                {i}
            </MenuItem>,
        );
    }

    return menuItems;
};

type ReportSearchBarPropsSeachParams = {
    freeRange: boolean;
    setFreeRange: React.Dispatch<React.SetStateAction<boolean>>;
    week: number;
    setWeek: React.Dispatch<React.SetStateAction<number>>;
    dateFrom: Date;
    setDateFrom: React.Dispatch<React.SetStateAction<Date>>;
    dateTo: Date;
    setDateTo: React.Dispatch<React.SetStateAction<Date>>;
    filter: string;
    setFilter: React.Dispatch<React.SetStateAction<string>>;
    agent: string;
    setAgent: React.Dispatch<React.SetStateAction<string>>;
};

type SextforceManageSaleProofsSearchBarProps = {
    timezone: string;
    setTimezone: (timezone: string) => void;
    mainSearch: ReportSearchBarPropsSeachParams;
    isFetchingReport: boolean;
    agents: any[];
    requestReport: () => void;
};

const SextforceManageSaleProofsSearchBar = (props: SextforceManageSaleProofsSearchBarProps) => {
    const { timezone, setTimezone, mainSearch, isFetchingReport, requestReport, agents } = props;
    const {
        freeRange: mainFreeRange,
        week: mainWeek,
        setDateFrom: mainSetDateFrom,
        setDateTo: mainSetDateTo,
        filter,
        setFilter,
        agent,
        setAgent,
    } = mainSearch;
    const theme: Theme = useTheme();
    const [mainWeekStartsOn, setMainWeekStartsOn] = useState<'sunday' | 'monday'>('monday');
    const [mainReportYear, setMainReportYear] = useState<number>(moment().year());

    useEffect(() => {
        if (!mainFreeRange) {
            // Set global MomentJS locale
            // moment.locale('en-gb', {
            //     week: {
            //         dow: mainWeekStartsOn,
            //         doy: 0,
            //     },
            // });

            // const startOfWeek = moment().year(mainReportYear).week(mainWeek);

            // mainSetDateFrom(startOfWeek.startOf('week').toDate());
            // mainSetDateTo(startOfWeek.endOf('week').toDate());
            const weekRange = getWeekRange(mainReportYear, mainWeek, mainWeekStartsOn);

            mainSetDateFrom(weekRange.firstDayOfWeek);
            mainSetDateTo(weekRange.lastDayOfWeek);
            mainSetDateFrom(weekRange.firstDayOfWeek);
            mainSetDateTo(weekRange.lastDayOfWeek);
        }
    }, [mainFreeRange, mainWeek, mainSetDateFrom, mainSetDateTo, mainWeekStartsOn, mainReportYear]);

    const RowByWeek = () => (
        <Grid container spacing={1} alignItems={'center'} sx={{ marginBottom: theme.spacing(1) }}>
            <Grid item xs={'auto'}>
                <FormControlLabel
                    checked={!mainSearch.freeRange}
                    control={<Radio size="small" />}
                    label="By Week"
                    onChange={() => mainSearch.setFreeRange(false)}
                />
            </Grid>
            <Grid item xs={'auto'}>
                <FormControl fullWidth>
                    <InputLabel id="select-weekStartsOn-label">Week Starts On</InputLabel>
                    <Select
                        labelId="select-weekStartsOn-label"
                        id="weekStartsOn"
                        value={mainWeekStartsOn}
                        label="Week Starts On"
                        size="small"
                        disabled={mainSearch.freeRange}
                        onChange={(e: SelectChangeEvent<'sunday' | 'monday'>) => setMainWeekStartsOn(e.target.value as 'sunday' | 'monday')}
                    >
                        <MenuItem value={'sunday'}>Sunday</MenuItem>
                        <MenuItem value={'monday'}>Monday</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={'auto'}>
                <FormControl fullWidth>
                    <InputLabel id="select-weekStartsOn-label">Week Starts On</InputLabel>
                    <Select
                        labelId="select-weekStartsOn-label"
                        id="weekStartsOn"
                        value={mainWeekStartsOn}
                        label="Week Starts On"
                        disabled={mainSearch.freeRange}
                        size="small"
                        onChange={(e: SelectChangeEvent<'sunday' | 'monday'>) => setMainWeekStartsOn(e.target.value as 'sunday' | 'monday')}
                    >
                        <MenuItem value={'sunday'}>Sunday</MenuItem>
                        <MenuItem value={'monday'}>Monday</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={'auto'}>
                <FormControl fullWidth>
                    <InputLabel id="select-year-label">Year</InputLabel>
                    <Select
                        labelId="select-year-label"
                        id="reportYear"
                        value={mainReportYear}
                        label="Year"
                        disabled={mainSearch.freeRange}
                        size="small"
                        onChange={(e: SelectChangeEvent<number>) => setMainReportYear(e.target.value as number)}
                    >
                        {YearMenuItems()}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={'auto'}>
                <FormControl fullWidth>
                    <InputLabel id="select-week-label">Week</InputLabel>
                    <Select
                        labelId="select-week-label"
                        id="reportWeek"
                        value={mainSearch.week}
                        label="Week"
                        disabled={mainSearch.freeRange}
                        size="small"
                        onChange={(e: SelectChangeEvent<number>) => mainSearch.setWeek(e.target.value as number)}
                    >
                        {YearWeekMenuItems()}
                    </Select>
                </FormControl>
            </Grid>
        </Grid>
    );

    const RowByFreeRange = () => (
        <Grid container spacing={1} alignItems={'center'} sx={{ marginBottom: theme.spacing(1) }}>
            <Grid item xs={'auto'}>
                <FormControlLabel
                    checked={mainSearch.freeRange}
                    control={<Radio size="small" />}
                    label="Free Range"
                    onChange={() => mainSearch.setFreeRange(true)}
                />
            </Grid>
            <Grid item xs={2}>
                <DatePicker
                    label="Start Date"
                    inputFormat="DD/MM/YYYY"
                    mask={'__/__/____'}
                    disabled={!mainSearch.freeRange}
                    value={mainSearch.dateFrom}
                    onChange={newValue => {
                        if (newValue) {
                            mainSearch.setDateFrom(moment(newValue).startOf('day').toDate());
                        }
                    }}
                    renderInput={params => <TextField {...params} size="small" />}
                    showDaysOutsideCurrentMonth={true}
                />
            </Grid>
            <Grid item xs={2}>
                <DatePicker
                    label="End Date"
                    inputFormat="DD/MM/YYYY"
                    mask={'__/__/____'}
                    disabled={!mainSearch.freeRange}
                    value={mainSearch.dateTo}
                    onChange={newValue => {
                        if (newValue) {
                            mainSearch.setDateTo(moment(newValue).endOf('day').toDate());
                        }
                    }}
                    renderInput={params => <TextField {...params} size="small" />}
                    showDaysOutsideCurrentMonth={true}
                />
            </Grid>
        </Grid>
    );

    const RowFilter = () => (
        <Grid container spacing={1} alignItems={'center'}>
            <Grid item xs={'auto'}>
                <RadioGroup row defaultValue={filter} value={filter} name="filter" onChange={e => setFilter(e.target.value)}>
                    <FormControlLabel value="all" control={<Radio size="small" />} label="All Sale Proofs" />
                    <FormControlLabel value="unapproved" control={<Radio size="small" />} label="Only Unapproved" />
                    <FormControlLabel value="agent" control={<Radio size="small" />} label="Only by Agent" />
                </RadioGroup>
            </Grid>
            <Grid item xs={2}>
                <Select
                    id="agent"
                    value={agent}
                    disabled={filter !== 'agent'}
                    sx={{ width: '100%' }}
                    size="small"
                    onChange={(e, child) => setAgent(e.target.value)}
                >
                    {agents &&
                        agents.map((agent: any) => (
                            <MenuItem value={agent._id} key={agent._id}>
                                {agent.name}
                            </MenuItem>
                        ))}
                </Select>
            </Grid>
        </Grid>
    );

    return (
        <Card variant="elevation" sx={{ marginBottom: theme.spacing(4), displayPrint: 'none' }}>
            <CardContent style={{ padding: theme.spacing(1) }}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <Grid container spacing={1} alignItems={'center'} sx={{ marginTop: 0, marginLeft: 0 }}>
                        <Grid item xs={2}>
                            <SelectTimezone timezone={timezone} setTimezone={setTimezone} size="small" fullWidth={true} />
                        </Grid>
                        <Divider orientation="vertical" flexItem={true} sx={{ marginLeft: theme.spacing(1) }} />
                        <Grid item xs>
                            <Grid container spacing={0} flexGrow={1}>
                                <Grid item xs={12}>
                                    <RowByWeek />
                                </Grid>
                                <Grid item xs={12}>
                                    <RowByFreeRange />
                                </Grid>
                                <Grid item xs={12}>
                                    <RowFilter />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Divider orientation="vertical" flexItem={true} sx={{ marginLeft: theme.spacing(1) }} />
                        <Grid item xs={1}>
                            <Button
                                variant="contained"
                                disabled={isFetchingReport || (filter === 'agent' && agent === '')}
                                onClick={requestReport}
                                size="small"
                                sx={{ height: '104px' }}
                            >
                                Show
                            </Button>
                        </Grid>
                    </Grid>
                </LocalizationProvider>
            </CardContent>
        </Card>
    );
};

export default SextforceManageSaleProofsSearchBar;
