import { Theme } from '@mui/system';
import { Card, CardContent, Grid, Skeleton, Typography, useMediaQuery } from '@mui/material';
import { Bar, CartesianGrid, ComposedChart, Legend, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { useEffect, useState } from 'react';
import { titleCase, zeroPad } from '../../../../../utils/common';

const findMaximumProductCount = (d: any, products: string[]) => {
    let max: number = 0;

    products.forEach(product => {
        if (d[product] && d[product] && d[product] > max) {
            max = d[product];
        }
    });

    return max;
};

const generateData = (data: any[]) => {
    if (data.length === 0) {
        return [];
    }

    // Create an array of unique product names in the results
    const uniqueProdcuts: string[] = [];

    data.forEach(d => {
        if (d.products) {
            d.products.forEach((p: any) => {
                if (p.product && !uniqueProdcuts.includes(p.product)) {
                    uniqueProdcuts.push(p.product);
                }
            });
        }
    });

    const result = {
        data: data.map(d => {
            const products: any = {};

            uniqueProdcuts.forEach(uniqueProdcut => {
                if (!d.products) {
                    products[uniqueProdcut] = 0;
                } else {
                    const foundProduct = d.products.find((p: any) => p.product === uniqueProdcut);

                    if (!foundProduct) {
                        products[uniqueProdcut] = 0;
                    } else {
                        products[uniqueProdcut] = foundProduct.count;
                    }
                }
            });

            return {
                date: `${zeroPad(d._id.year, 4)}-${zeroPad(d._id.month, 2)}`,
                ...products,
                uniqueCount: d.uniqueCount,
            };
        }),
        products: uniqueProdcuts,
    };

    return result;
};

const CustomTooltip = ({ payload, products, productColors, theme }: any) => {
    if (payload && payload.length) {
        return (
            <div>
                <Grid
                    container
                    flexGrow={0}
                    alignItems="center"
                    spacing={1}
                    sx={{
                        backgroundColor: '#fff',
                        borderRadius: 4,
                        border: '1px solid #999',
                        maxWidth: 250,
                    }}
                >
                    <Grid item xs={12}>
                        <span style={{ fontWeight: 'bold' }}>{payload[0].payload.date}</span>
                    </Grid>
                    <Grid item xs={12}>
                        {products &&
                            products.map((product: string, index: number) => {
                                const productPayload = payload.find((p: any) => p.dataKey === product);

                                return (
                                    <Grid container flexGrow={1} alignItems="center" key={product}>
                                        <Grid item xs={4} sx={{ color: productColors[index] }}>
                                            {titleCase(product)}
                                        </Grid>
                                        <Grid item xs={8} textAlign={'right'} sx={{ color: productColors[index], paddingRight: '8px' }}>
                                            {productPayload ? productPayload.value.toLocaleString() : 0}
                                        </Grid>
                                    </Grid>
                                );
                            })}

                        <Grid container flexGrow={1} alignItems="center" sx={{ marginTop: theme.spacing(1) }}>
                            <Grid item xs={8}>
                                Uniqe Spenders:
                            </Grid>
                            <Grid item xs={4} textAlign={'right'} sx={{ paddingRight: '8px' }}>
                                {payload.find((p: any) => p.dataKey === 'uniqueCount').value.toLocaleString()}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        );
    }

    return null;
};

type Props = {
    monthlyUniqueSpenders: any;
    monthlyUniqueSpendersLoading: boolean;
    theme: Theme;
};

const SextforceMetricsAccountMonthlyUniqueSpenders = (props: Props) => {
    const { monthlyUniqueSpenders, monthlyUniqueSpendersLoading, theme } = props;
    const [dataMain, setDataMain] = useState<any>({ data: [], min: 0, max: 0, average: 0 });
    const largeScreen = useMediaQuery(theme.breakpoints.up('sm'));

    const barColors = [
        theme.palette.primary.main,
        theme.palette.secondary.main,
        theme.palette.success.main,
        theme.palette.info.main,
        theme.palette.warning.main,
        theme.palette.error.main,
    ];

    useEffect(() => {
        if (!monthlyUniqueSpendersLoading && monthlyUniqueSpenders && monthlyUniqueSpenders.length > 0) {
            setDataMain(generateData(monthlyUniqueSpenders));
        } else {
            setDataMain([]);
        }
    }, [monthlyUniqueSpenders, monthlyUniqueSpendersLoading]);

    const Chart = (chartData: any) => (
        <ResponsiveContainer width="100%" height="100%">
            <ComposedChart
                data={chartData && chartData.data ? chartData.data : []}
                margin={{
                    top: 0,
                    right: largeScreen ? 10 : 10,
                    left: largeScreen ? 20 : 10,
                    bottom: 40,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" stroke={theme.palette.grey[400]} />
                <XAxis
                    dataKey="date"
                    name="Time"
                    domain={chartData && chartData.data && chartData.data.length > 0 ? ['dataMin', 'dataMax'] : ['auto', 'auto']}
                    interval={'preserveStartEnd'}
                    tick={{ fontSize: 13 }}
                    angle={-90}
                    dy={35}
                    dx={-5}
                />
                <YAxis
                    yAxisId="counts"
                    dataKey={d =>
                        findMaximumProductCount(d, chartData && chartData.data && chartData.data.length > 0 ? chartData.products : [])
                    }
                    scale={'linear'}
                    name="Count"
                    domain={[0, (dataMax: number) => Math.abs(dataMax * 1.1)]}
                    tickFormatter={(value: number) => value.toLocaleString()}
                    tick={{ fill: theme.palette.primary.main, fontSize: 13 }}
                />
                {chartData &&
                    chartData.data &&
                    chartData.data.length > 0 &&
                    chartData.products.map((product: string, index: number) => (
                        <Bar
                            key={product}
                            yAxisId={'counts'}
                            type="monotone"
                            dataKey={`${product}`}
                            maxBarSize={20}
                            fill={barColors[index]}
                        />
                    ))}
                <YAxis
                    yAxisId="totalCount"
                    dataKey={'uniqueCount'}
                    scale={'linear'}
                    name="Total Unique Spenders"
                    domain={[0, (dataMax: number) => Math.abs(dataMax * 1.1)]}
                    tickFormatter={(value: number) => value.toLocaleString()}
                    tick={{ fill: theme.palette.primary.main, fontSize: 13 }}
                    orientation="right"
                />
                <Line
                    yAxisId="totalCount"
                    type="monotone"
                    dataKey="uniqueCount"
                    stroke={theme.palette.common.black}
                    activeDot={{ r: 3 }}
                    dot={{ r: 2 }}
                />
                <Tooltip
                    content={
                        <CustomTooltip
                            payload={chartData.data}
                            products={chartData && chartData.data && chartData.data.length > 0 && chartData.products}
                            productColors={barColors}
                            theme={theme}
                        />
                    }
                />
                <Legend
                    verticalAlign="top"
                    height={largeScreen ? 40 : 70}
                    formatter={(value: string) => (
                        <span style={{ fontSize: 13 }}>
                            {value === 'uniqueCount' ? 'Total Unique Spenders' : titleCase(value.split('.')[0])}
                        </span>
                    )}
                />
            </ComposedChart>
        </ResponsiveContainer>
    );

    return (
        <Card sx={{ width: '100%', marginBottom: theme.spacing(4) }}>
            <CardContent>
                <Grid container flexGrow={1} spacing={0} sx={{ marginBottom: theme.spacing(1) }}>
                    <Grid item xs={12}>
                        <Typography variant="h5" sx={{ marginBottom: theme.spacing(1) }}>
                            Unique Monthly Spenders (Last 12 Months)
                        </Typography>
                        <Typography variant="body1" sx={{ marginBottom: theme.spacing(1) }}>
                            The following graph shows how many unique fans spend monthly as a total (line) and broken down by product
                            (bars).
                        </Typography>
                        <Typography variant="body1">
                            Use these numbers to track how the your general reach to the bulk of your fans vs. the loyal returning spenders.
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container flexGrow={1} spacing={0} sx={{ padding: 0 }}>
                    <Grid item xs={12} sx={{ height: { xs: 350, sm: 500 } }}>
                        {monthlyUniqueSpendersLoading ? <Skeleton /> : Chart(dataMain)}
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default SextforceMetricsAccountMonthlyUniqueSpenders;
