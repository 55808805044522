import { Typography } from '@mui/material';

const NA = () => (
    <Typography
        variant="inherit"
        sx={{
            color: 'text.disabled',
        }}
        component="span"
    >
        N/A
    </Typography>
);

export default NA;
