import ChatIcon from '@mui/icons-material/Chat';
import HomeIcon from '@mui/icons-material/Home';
import MenuIcon from '@mui/icons-material/Menu';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import SystemUpdateIcon from '@mui/icons-material/SystemUpdate';
import {
    Avatar,
    Box,
    Divider,
    Drawer,
    Grid,
    IconButton,
    LinearProgress,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    Skeleton,
    Toolbar,
    Tooltip,
    Typography,
} from '@mui/material';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import { styled } from '@mui/material/styles';
import useTheme from '@mui/material/styles/useTheme';
import { Theme } from '@mui/system';
import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import useSubscribers, { SubscribersAdmin } from '../hooks/useSubscribers';
import useSystemStats from '../hooks/useSystemInfo';
import { UserContext, UserContextType } from '../store/UserContext';
import { getUserName } from '../utils/user';
import ProfileDialog from './account/ProfileDialog';
import HeaderSideMenuSubscriber from './HeaderSideMenuSubscriber';

const bytesToGbPrettyString = (bytes: number): string => {
    return (bytes / (1024 * 1024 * 1024)).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 });
};

const drawerWidth = 280;

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: prop => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));

const SideMenuDrawer = (props: {
    userContext: UserContextType;
    subscribers: any[] | SubscribersAdmin | undefined;
    subscribersLoading: boolean;
    open: any;
    drawerState: boolean;
    handleDrawerToggle: (event: React.KeyboardEvent | React.MouseEvent) => void;
    handleToggleSubscriberMenu: (username: string) => void;
    theme: Theme;
}) => {
    const { userContext, subscribers, subscribersLoading, open, drawerState, handleDrawerToggle, handleToggleSubscriberMenu, theme } =
        props;

    const { data: systemStats, isLoading: isSystemStatsLoading } = useSystemStats();

    const availableStoragePercentage = () => {
        if (!systemStats) {
            return 0;
        }

        return 100 - Math.round((systemStats.data.availableStorage / systemStats.data.totalStorage) * 100);
    };

    return (
        <Drawer
            anchor="left"
            open={drawerState}
            onClose={handleDrawerToggle}
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
            }}
        >
            <DrawerHeader sx={{ justifyContent: 'flex-start' }}>
                <Typography variant="h6" align="left">
                    OnlyStruggles
                </Typography>
            </DrawerHeader>

            <Divider />

            {userContext.isAdmin && (
                <>
                    <Box role="presentation" padding={2}>
                        <Typography variant="subtitle2">Storage</Typography>
                        <Grid container spacing={0.5} alignItems={'center'}>
                            <Grid item xs="auto">
                                <Typography variant="body2">
                                    {systemStats
                                        ? bytesToGbPrettyString(systemStats.data.totalStorage - systemStats.data.availableStorage)
                                        : 0}{' '}
                                    GB
                                </Typography>
                            </Grid>
                            <Grid item xs>
                                <LinearProgress
                                    variant={isSystemStatsLoading ? 'indeterminate' : 'determinate'}
                                    value={availableStoragePercentage()}
                                    color={availableStoragePercentage() > 90 ? 'error' : 'primary'}
                                />
                            </Grid>
                            <Grid item xs="auto">
                                <Typography variant="body2">
                                    {systemStats ? bytesToGbPrettyString(systemStats.data.totalStorage) : 0} GB
                                </Typography>
                            </Grid>
                        </Grid>
                    </Box>

                    <Divider />
                </>
            )}

            <Box role="presentation" paddingTop={0}>
                <List dense={true} disablePadding>
                    <ListItemButton component={Link} to={'/'} onClick={handleDrawerToggle} onKeyDown={handleDrawerToggle}>
                        <ListItemIcon
                            sx={{
                                minWidth: '32px',
                            }}
                        >
                            <HomeIcon />
                        </ListItemIcon>
                        <ListItemText primary="Home" />
                    </ListItemButton>

                    <Divider />

                    {userContext.isAdmin && (
                        <ListItemButton component={Link} to={`/subscribers`} onClick={handleDrawerToggle} onKeyDown={handleDrawerToggle}>
                            <ListItemIcon
                                sx={{
                                    minWidth: '32px',
                                }}
                            >
                                <PeopleAltIcon htmlColor={theme.palette.primary.main} />
                            </ListItemIcon>
                            <ListItemText primary="All Subscribers" />
                        </ListItemButton>
                    )}

                    {subscribersLoading ? (
                        <Skeleton />
                    ) : (
                        <>
                            {(!subscribers || (Array.isArray(subscribers) && subscribers.length === 0)) && (
                                <Typography variant="body1" sx={{ marginLeft: theme.spacing(2), marginTop: theme.spacing(2) }}>
                                    No Accounts Found
                                </Typography>
                            )}
                            {subscribers &&
                                Array.isArray(subscribers) &&
                                subscribers.map(subscriber => (
                                    <HeaderSideMenuSubscriber
                                        subscriber={subscriber}
                                        key={`menu_${subscriber.username}`}
                                        open={open}
                                        handleDrawerToggle={handleDrawerToggle}
                                        handleToggleSubscriberMenu={handleToggleSubscriberMenu}
                                    />
                                ))}
                        </>
                    )}
                </List>
            </Box>
        </Drawer>
    );
};

const Header = (props: any) => {
    const theme: Theme = useTheme();
    const userContext = useContext(UserContext);
    const navigate = useNavigate();
    const [drawerState, setDrawerState] = useState<boolean>(false);
    const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
    const [open, setOpen] = useState<any>({});
    const [profileDialogOpen, setProfileDialogOpen] = useState<boolean>(false);
    const { data: subscribers, isFetching: subscribersLoading } = useSubscribers();

    useEffect(() => {
        if (!userContext.isAdmin && subscribers && Array.isArray(subscribers)) {
            const newOpen: any = {};

            subscribers.forEach((subscriber: any) => {
                newOpen[subscriber.username] = false;
            });

            setOpen(newOpen);
        }
    }, [subscribers, userContext.isAdmin]);

    const handleDrawerToggle = (event: React.KeyboardEvent | React.MouseEvent) => {
        if (event.type === 'keydown' && ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')) {
            return;
        }

        setDrawerState(!drawerState);
    };

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleToggleSubscriberMenu = (username: string) => {
        const newOpen: any = {};

        Object.keys(open).forEach((u: string) => {
            if (u === username) {
                newOpen[u] = !open[u];
            } else {
                newOpen[u] = false;
            }
        });

        setOpen(newOpen);
    };

    const handleProfileDialogToggle = () => {
        setProfileDialogOpen(!profileDialogOpen);
    };

    return (
        <Box sx={{ display: 'flex' }} key="dashboard">
            <AppBar position="fixed" open={drawerState}>
                <Toolbar>
                    <IconButton color="inherit" aria-label="open drawer" onClick={handleDrawerToggle} edge="start" sx={{ mr: 2 }}>
                        <MenuIcon />
                    </IconButton>

                    <Typography variant="h6" color="inherit" component="div" sx={{ flexGrow: 1 }}>
                        OnlyStruggles
                    </Typography>

                    <Tooltip title="Contact Admin via Telegram">
                        <IconButton
                            href="https://t.me/adamcrowe"
                            target={'_blank'}
                            sx={{
                                color: theme.palette.common.white,
                                mr: theme.spacing(2),
                                ':hover': { color: theme.palette.secondary.light },
                            }}
                        >
                            <ChatIcon />
                        </IconButton>
                    </Tooltip>

                    <Tooltip title="View Service Reports on Telegram">
                        <IconButton
                            href="https://t.me/CroweTeleBot"
                            target={'_blank'}
                            sx={{
                                color: theme.palette.common.white,
                                mr: theme.spacing(2),
                                ':hover': { color: theme.palette.secondary.light },
                            }}
                        >
                            <SystemUpdateIcon />
                        </IconButton>
                    </Tooltip>

                    <Box sx={{ flexGrow: 0 }}>
                        <Tooltip title={getUserName(userContext.user)}>
                            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                <Avatar sx={{ color: theme.palette.common.white }}>{getUserName(userContext.user).charAt(0)}</Avatar>
                            </IconButton>
                        </Tooltip>
                        <Menu
                            sx={{ mt: '45px' }}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >
                            <MenuItem
                                onClick={() => {
                                    handleProfileDialogToggle();
                                    handleCloseUserMenu();
                                }}
                            >
                                <Typography textAlign="center">Profile</Typography>
                            </MenuItem>
                            <Divider />
                            <MenuItem
                                onClick={async () => {
                                    await userContext.signOut();
                                    navigate('/', { replace: true });
                                }}
                            >
                                <Typography textAlign="center">Logout</Typography>
                            </MenuItem>
                        </Menu>
                    </Box>
                </Toolbar>
            </AppBar>

            <SideMenuDrawer
                userContext={userContext}
                drawerState={drawerState}
                open={open}
                handleDrawerToggle={handleDrawerToggle}
                handleToggleSubscriberMenu={handleToggleSubscriberMenu}
                subscribers={subscribers}
                subscribersLoading={subscribersLoading}
                theme={theme}
            />

            <ProfileDialog open={profileDialogOpen} handleClose={handleProfileDialogToggle} />
        </Box>
    );
};

export default Header;
