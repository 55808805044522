import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Stack } from '@mui/material';
import { CalendarPicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import { useState } from 'react';

interface Props {
    open: boolean;
    onClose: () => void;
    date: Date | null;
    onGoToDate: (date: Date | null) => void;
}

const MessageUsersFormAddMediasSelectorGoToDateDialog = (props: Props) => {
    const { open, onClose, date, onGoToDate } = props;

    const [selectedDate, setSelectedDate] = useState<moment.Moment | null>(date ? moment(date) : null);

    const handleGoToDate = (clear?: boolean) => {
        if (selectedDate) {
            onGoToDate(selectedDate.toDate());
        }

        if (clear) {
            onGoToDate(null);
        }
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="xs">
            <DialogTitle>Go to Date</DialogTitle>
            <DialogContent>
                <Grid container flexGrow={1} spacing={2} alignItems="center">
                    <Grid item xs={12}>
                        <DialogContentText>Set the day to jump to a specific date in the Vault.</DialogContentText>
                    </Grid>
                    <Grid item xs={12}>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <CalendarPicker disableFuture date={selectedDate} onChange={newValue => setSelectedDate(newValue)} />
                        </LocalizationProvider>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Stack direction="row" spacing={2} flexGrow={1} alignItems="center" justifyContent="space-between">
                    <Button
                        variant="contained"
                        color="warning"
                        onClick={() => {
                            handleGoToDate(true);
                        }}
                    >
                        Clear
                    </Button>
                    <div>
                        <Stack direction="row" spacing={2} flexGrow={1} alignItems="center">
                            <Button onClick={onClose} color="primary">
                                Cancel
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    handleGoToDate();
                                }}
                            >
                                Go
                            </Button>
                        </Stack>
                    </div>
                </Stack>
            </DialogActions>
        </Dialog>
    );
};

export default MessageUsersFormAddMediasSelectorGoToDateDialog;
