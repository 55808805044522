import axios from 'axios';
import moment from 'moment';
import { useDialog } from 'muibox';
import { useContext } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { SettingsContext } from '../store/SettingsContext';
import { UserContext } from '../store/UserContext';
import { handleHttpError } from '../utils/common';

const useMetricsDailyStats = (startDate: Date, endDate: Date, timezone: string, userId?: string) => {
    const userContext = useContext(UserContext);
    const settingsContext = useContext(SettingsContext);
    const dialog = useDialog();
    const params = useParams();

    // Fetch online users count vs. total sales report
    const fetchData = async (startDate: Date, endDate: Date, timezone: string, userId?: string): Promise<any[]> => {
        if (!userContext.jwtToken || !settingsContext.apiKey) {
            return [];
        }

        if (!startDate || !endDate) {
            return [];
        }

        if (!userId && !params.userId) {
            return [];
        }

        if (userContext.jwtToken && settingsContext.apiKey) {
            const query: string = `${settingsContext.routes.metrics.base}${userId || params.userId}/dailystats?${new URLSearchParams({
                startDate: moment(startDate).tz(timezone, true).toISOString(),
                endDate: moment(endDate).tz(timezone, true).toISOString(),
            })}`;

            const data = await axios
                .get(query, {
                    headers: {
                        Authorization: userContext.jwtToken,
                        apiKey: settingsContext.apiKey,
                    },
                })
                .then(async response => response.data)
                .catch(error => {
                    handleHttpError(error, dialog);
                });

            return data;
        }

        return [];
    };

    return useQuery(
        ['metricsDailyStats', userId || params.userId, userContext.jwtToken, startDate, endDate, timezone],
        () => {
            return fetchData(startDate, endDate, timezone, userId);
        },
        {
            refetchOnWindowFocus: false,
            // Stale time 1 hour
            staleTime: 60 * 60 * 1000,
            enabled: userContext.jwtToken && (params.userId || userId) ? true : false,
        },
    );
};

export default useMetricsDailyStats;
