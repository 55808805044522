import { useDialog } from 'muibox';
import { useContext, useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { SettingsContext } from '../store/SettingsContext';
import { UserContext } from '../store/UserContext';
import { handleHttpError } from '../utils/common';
import useAxios from './useAxios';

export interface SextforceLiveStream {
    _id?: string;
    subscriberId: string;
    streamId: number;
    createdAt: Date;
    endedAt?: Date;
    listId?: number;
    title?: string;
    description?: string;
    stats?: OnlyFansStreamStats.Response;
}

export interface SextforceLiveStreamWithAmount extends SextforceLiveStream {
    totalAmount: number;
}

export namespace OnlyFansStreamStats {
    export interface Response {
        stream_followers_count: number;
        stream_tip_count: number;
        stream_tip_sum: number;
        stream_duration: number;
        stream_like_count: number;
        stream_look_count: number;
        stream_comment_count: number;
        stream_look_all: Date[];
        stream_like_all: Date[];
        stream_comment_all: Date[];
        stream_tip_all: Date[];
        stream_look_chart?: StreamChart[];
        stream_like_chart?: StreamChart[];
        stream_comment_chart?: StreamChart[];
        stream_tip_chart?: StreamChart[];
    }

    export interface StreamChart {
        point: number;
        count: number;
    }
}

const useSextforceLiveStreamAddTippersToList = (
    searchQuery: string,
    limit: number,
    skip: number,
    startDate: Date | null,
    endDate: Date | null,
    sortBy: 'createdAt' | 'name' | 'description' | 'stats.stream_tip_count' | 'stats.stream_tip_sum',
) => {
    const userContext = useContext(UserContext);
    const settingsContext = useContext(SettingsContext);
    const dialog = useDialog();
    const params = useParams();
    const axios = useAxios();

    const [isSextforceLiveStreamAddTippersToListLoading, setIsSextforceLiveStreamAddTippersToListLoading] = useState(false);
    const [isSextforceLiveStreamAddTippersToListEditLoading, setIsSextforceLiveStreamAddTippersToListEditLoading] = useState(false);

    // Fetch the restricted words
    const fetchLiveStreams = async (): Promise<{ list: SextforceLiveStreamWithAmount[]; totalAmount: number; totalCount: number }> => {
        if (userContext.jwtToken && settingsContext.apiKey && userContext.user.username) {
            const query: string = `${settingsContext.routes.sextforce.base}${
                params.userId
            }/liveStream/addTippersToList?${new URLSearchParams({
                query: searchQuery,
                limit: limit.toString(),
                skip: skip.toString(),
                startDate: startDate ? startDate.toISOString() : '',
                endDate: endDate ? endDate.toISOString() : '',
                sortBy,
            })}`;

            return axios
                .get(query)
                .then(response => response.data as { list: SextforceLiveStreamWithAmount[]; totalAmount: number; totalCount: number })
                .catch(error => {
                    console.error(error);
                    handleHttpError(error, dialog);

                    return { list: [], totalAmount: 0, totalCount: 0 };
                });
        }

        return { list: [], totalAmount: 0, totalCount: 0 };
    };

    const liveStreams = useQuery(
        ['liveStreams', userContext.jwtToken, params.userId, searchQuery, limit, skip, startDate, endDate, sortBy],
        () => {
            return fetchLiveStreams();
        },
        {
            refetchOnWindowFocus: false,
            // Stake time 5 minutes
            staleTime: 5 * 60 * 1000,
            enabled: userContext.jwtToken && settingsContext.apiKey && userContext.user.username && params.userId ? true : false,
        },
    );

    const sextforceLiveStreamAddTippersToListResetCounters = async (): Promise<{ addTippersToListCount: number }> => {
        if (userContext.jwtToken && settingsContext.apiKey && userContext.user.username) {
            const query: string = `${settingsContext.routes.sextforce.base}${params.userId}/liveStream/addTippersToList/counters`;

            setIsSextforceLiveStreamAddTippersToListLoading(true);

            return axios
                .delete(query, {
                    headers: {
                        Authorization: userContext.jwtToken,
                        apiKey: settingsContext.apiKey,
                    },
                })
                .then(async response => {
                    setIsSextforceLiveStreamAddTippersToListLoading(false);

                    return response.data as { addTippersToListCount: number };
                })
                .catch(error => {
                    console.error(error);
                    setIsSextforceLiveStreamAddTippersToListLoading(false);
                    handleHttpError(error, dialog);

                    return { addTippersToListCount: 0 };
                });
        }

        return { addTippersToListCount: 0 };
    };

    const sextforceLiveStreamAddTippersToListEditTitleAndDescription = async (
        streamId: number,
        title: string | undefined,
        description: string | undefined,
    ): Promise<SextforceLiveStream> => {
        if (userContext.jwtToken && settingsContext.apiKey && userContext.user.username) {
            const query: string = `${settingsContext.routes.sextforce.base}${params.userId}/liveStream/addTippersToList/${streamId}`;

            setIsSextforceLiveStreamAddTippersToListEditLoading(true);

            return axios
                .put(
                    query,
                    {
                        title,
                        description,
                    },
                    {
                        headers: {
                            Authorization: userContext.jwtToken,
                            apiKey: settingsContext.apiKey,
                        },
                    },
                )
                .then(async response => {
                    setIsSextforceLiveStreamAddTippersToListEditLoading(false);

                    // Return the updated stream
                    return response.data as SextforceLiveStream;
                })
                .catch(error => {
                    console.error(error);
                    setIsSextforceLiveStreamAddTippersToListEditLoading(false);
                    handleHttpError(error, dialog);

                    return {} as SextforceLiveStream;
                });
        }

        return {} as SextforceLiveStream;
    };

    return {
        ...liveStreams,
        isSextforceLiveStreamAddTippersToListLoading,
        sextforceLiveStreamAddTippersToListResetCounters,
        isSextforceLiveStreamAddTippersToListEditLoading,
        sextforceLiveStreamAddTippersToListEditTitleAndDescription,
    };
};

export default useSextforceLiveStreamAddTippersToList;
