import { SvgIcon, SxProps, Theme } from '@mui/material';

type IconOnlyFansProps = {
    fontSize?: 'small' | 'inherit' | 'large' | 'medium' | undefined;
    sx?: SxProps<Theme>;
};

const IconOnlyFansPrice = (props: IconOnlyFansProps) => {
    const { fontSize, sx } = props;

    return (
        <SvgIcon
            fontSize={fontSize}
            sx={sx}
            xmlns="http://www.w3.org/2000/svg"
            fillRule="evenodd"
            strokeLinejoin="round"
            strokeMiterlimit="2"
            clipRule="evenodd"
            viewBox="0 0 21 20"
        >
            <path
                fillRule="nonzero"
                d="M22 13a3.38 3.38 0 00-1-2.4l-7.41-7.43A4.062 4.062 0 0010.76 2H5C3.354 2 2 3.354 2 5v5.76a4 4 0 001.17 2.83L10.6 21a3.417 3.417 0 004.8 0l5.6-5.6a3.38 3.38 0 001-2.4zm-2.4 1L14 19.6c-.557.53-1.443.53-2 0l-7.41-7.43A2.002 2.002 0 014 10.76V5c0-.549.451-1 1-1h5.76a2.002 2.002 0 011.41.59L19.6 12a1.399 1.399 0 010 2zM7.7 6C6.767 6 6 6.767 6 7.7c0 .933.767 1.7 1.7 1.7.933 0 1.7-.767 1.7-1.7 0-.933-.767-1.7-1.7-1.7zm6.16 6.28c-1-.22-1.85-.3-1.85-.78 0-.48.43-.51 1.06-.51.355 0 .692.158.92.43.089.1.216.158.35.16h1.35a.231.231 0 00.21-.22c0-.71-.86-1.55-2-1.84v-.75a.272.272 0 00-.26-.27h-1.27a.272.272 0 00-.26.27v.74a2.315 2.315 0 00-2 2c0 2.81 4.07 1.85 4.07 2.89 0 .48-.47.53-1.27.53a1.3 1.3 0 01-1-.52.66.66 0 00-.4-.17h-1.28a.231.231 0 00-.2.22c0 1 1 1.72 2.08 2v.74c0 .144.116.265.26.27h1.25a.26.26 0 00.26-.26v-.72A2.189 2.189 0 0016 14.43c0-1.2-.86-1.88-2.14-2.15z"
                transform="translate(-2 -2)"
            ></path>
        </SvgIcon>
    );
};

export default IconOnlyFansPrice;
