import { useEffect, useState } from 'react';
import { Theme } from '@mui/system';
import { Grid, Typography } from '@mui/material';
import FollowBackGraphCard from '../FollowBackGraphCard';
import { parseFollowBackSessionData } from '../../../../utils/followBack';

type FollowBackOverviewGraphsProps = {
    data: any[];
    subscriber: any;
    loading: boolean;
    theme: Theme;
};

const FollowBackOverviewGraphs = (props: FollowBackOverviewGraphsProps) => {
    const { data, subscriber, loading, theme } = props;
    const [followingExpired, setFollowingExpired] = useState<any[]>([]);
    const [fansExpiredPriority, setFansExpiredPriority] = useState<any[]>([]);
    const [fansExpired, setFansExpired] = useState<any[]>([]);
    const [fansActive, setFansActive] = useState<any[]>([]);

    useEffect(() => {
        if (data) {
            setFollowingExpired(parseFollowBackSessionData('followingExpired', data));
            setFansExpired(parseFollowBackSessionData('fansExpired', data));
            setFansExpiredPriority(parseFollowBackSessionData('fansExpiredPriority', data));
            setFansActive(parseFollowBackSessionData('fansActive', data));
        }
    }, [data, theme]);

    return (
        <>
            <Typography variant="h5" sx={{ marginTop: theme.spacing(4), marginBottom: theme.spacing(2) }}>
                Last 7 Days
            </Typography>

            <Grid container flexGrow={0} justifyContent="center" spacing={1} sx={{ paddingBottom: theme.spacing(0) }}>
                <Grid item xs={12} md={6} lg={3}>
                    <FollowBackGraphCard title="Following->Expired Followed" data={followingExpired} loading={loading} theme={theme} />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <FollowBackGraphCard
                        title="Fans->Expired (Priority) Followed"
                        data={fansExpiredPriority}
                        loading={loading}
                        theme={theme}
                    />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <FollowBackGraphCard title="Fans->Expired Followed" data={fansExpired} loading={loading} theme={theme} />
                </Grid>
                <Grid item xs={12} md={6} lg={3}>
                    <FollowBackGraphCard title="Fans->Active Followed" data={fansActive} loading={loading} theme={theme} />
                </Grid>
            </Grid>
        </>
    );
};

export default FollowBackOverviewGraphs;
