import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import LinkIcon from '@mui/icons-material/Link';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import { Box, IconButton, Stack, Table, TableBody, TableCell, TableHead, TableRow, Theme, Tooltip, Typography } from '@mui/material';
import moment from 'moment';
import { useDialog } from 'muibox';
import { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import {
    SextforceAutoCampaignTipReply,
    SextforceAutoCampaignTipReplyMessageDetails,
    SextforceAutoCampaignTipReplyWithMessageDetails,
} from '../../../../hooks/useSextforceAutoCampaignTipReply';
import { SettingsContext } from '../../../../store/SettingsContext';
import { UserContext } from '../../../../store/UserContext';
import { handleHttpError } from '../../../../utils/common';
import NA from '../../../common/NA';
import SextforceAutoCampaignTipReplyEdit from './SextforceAutoCampaignTipReplyEditDialog';
import SextforceAutoCampaignTipReplyTableTipRow from './SextforceAutoCampaignTipReplyTableTipRow';
import SextforceAutoCampaignTipReplyTippersListDialog from './SextforceAutoCampaignTipReplyTippersListDialog';

type Props = {
    subscriber: any;
    autoCampaignTipReply: SextforceAutoCampaignTipReplyWithMessageDetails;
    theme: Theme;
    refetchAutoCampaignTipReplies: () => void;
    updateAutoCampaignTipReply: (
        id: string,
        autoCampaignReply: SextforceAutoCampaignTipReply,
    ) => Promise<SextforceAutoCampaignTipReply | null>;
    deleteAutoCampaignTipReply: (id: string) => Promise<SextforceAutoCampaignTipReply | null>;
    createAutoCampaignTipReplyTipMessageDetails: (
        autoCampaignTipReply: SextforceAutoCampaignTipReply,
        autoCampaignTipReplyTipMessageDetails: SextforceAutoCampaignTipReplyMessageDetails,
    ) => Promise<SextforceAutoCampaignTipReplyMessageDetails | null>;
    updateAutoCampaignTipReplyTipMessageDetails: (
        autoCampaignTipReply: SextforceAutoCampaignTipReply,
        autoCampaignTipReplyTipMessageDetails: SextforceAutoCampaignTipReplyMessageDetails,
    ) => Promise<SextforceAutoCampaignTipReplyMessageDetails | null>;
    deleteAutoCampaignTipReplyTipMessageDetails: (
        autoCampaignTipReply: SextforceAutoCampaignTipReply,
        autoCampaignTipReplyTipMessageDetails: SextforceAutoCampaignTipReplyMessageDetails,
    ) => Promise<SextforceAutoCampaignTipReplyMessageDetails | null>;
};

const SextforceAutoCampaignTipReplyTableRow = (props: Props) => {
    const {
        subscriber,
        autoCampaignTipReply,
        theme,
        refetchAutoCampaignTipReplies,
        updateAutoCampaignTipReply,
        deleteAutoCampaignTipReply,
        createAutoCampaignTipReplyTipMessageDetails,
        updateAutoCampaignTipReplyTipMessageDetails,
        deleteAutoCampaignTipReplyTipMessageDetails,
    } = props;
    const userContext = useContext(UserContext);
    const settingsContext = useContext(SettingsContext);
    const dialog = useDialog();
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [tippersListDialogOpen, setTippersListDialogOpen] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);

    const handleDelete = async (id: string) => {
        if (!userContext.jwtToken || !settingsContext.apiKey) {
            return;
        }

        setIsDeleting(true);

        const data = await deleteAutoCampaignTipReply(id)
            .then(async response => {
                setIsDeleting(false);

                return response;
            })
            .catch(error => {
                setIsDeleting(false);
                handleHttpError(error, dialog);

                return null;
            });

        return data;
    };

    const handleDeleteAutoCampaignTipReply = async (autoRepostId: string) => {
        handleDelete(autoRepostId).then(data => {
            if (!data) {
                return;
            }

            refetchAutoCampaignTipReplies();
        });
    };

    return (
        <>
            <TableRow
                sx={{
                    '& td': {
                        borderBottom: 'none',
                    },
                }}
            >
                <TableCell>
                    <Stack direction="column" spacing={0.5}>
                        <Stack direction="row" spacing={0.5} alignItems="center">
                            <LinkIcon fontSize="small" />
                            <Link
                                to={autoCampaignTipReply.campaignUrl}
                                target="_blank"
                                rel="noreferrer"
                                onClick={e => {
                                    e.stopPropagation();
                                }}
                            >
                                <Typography variant="inherit" color="textPrimary" noWrap>
                                    {autoCampaignTipReply.name}
                                </Typography>
                            </Link>
                        </Stack>
                        {autoCampaignTipReply.description && (
                            <Typography variant="inherit" color="textSecondary">
                                {autoCampaignTipReply.description}
                            </Typography>
                        )}
                    </Stack>
                </TableCell>
                <TableCell align="center">
                    <Stack direction="column" spacing={0.5}>
                        <Typography variant="inherit" color="textSecondary" noWrap>
                            {moment(autoCampaignTipReply.postDate).format('L hh:mma')}
                        </Typography>
                        {autoCampaignTipReply.postExpireDate ? (
                            <Typography variant="inherit" color="textSecondary" noWrap>
                                {moment(autoCampaignTipReply.postExpireDate).format('L hh:mma')}
                            </Typography>
                        ) : (
                            <NA />
                        )}
                    </Stack>
                </TableCell>
                <TableCell align="center">{(autoCampaignTipReply.replyCount || 0).toLocaleString()}</TableCell>
                <TableCell align="center">
                    <Stack direction="row" spacing={0.5} justifyContent="center">
                        <Tooltip title="Edit Auto Tip Campaign Settings">
                            <span>
                                <IconButton
                                    size="small"
                                    disabled={!autoCampaignTipReply.active}
                                    onClick={e => {
                                        e.stopPropagation();
                                        setEditDialogOpen(true);
                                    }}
                                >
                                    <EditIcon fontSize="small" />
                                </IconButton>
                            </span>
                        </Tooltip>

                        <Tooltip title="List of All Tippers">
                            <IconButton
                                size="small"
                                color="info"
                                onClick={e => {
                                    e.stopPropagation();
                                    setTippersListDialogOpen(true);
                                }}
                            >
                                <PeopleAltIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                        {tippersListDialogOpen && (
                            <SextforceAutoCampaignTipReplyTippersListDialog
                                subscriber={subscriber}
                                autoCampaignTipReply={autoCampaignTipReply}
                                amount={null}
                                open={tippersListDialogOpen}
                                onClose={() => setTippersListDialogOpen(false)}
                                theme={theme}
                            />
                        )}

                        <Tooltip title="Delete Auto Tip Campaign">
                            <span>
                                <IconButton
                                    color="error"
                                    size="small"
                                    disabled={!autoCampaignTipReply.active || isDeleting}
                                    onClick={e => {
                                        e.stopPropagation();

                                        dialog
                                            .confirm({
                                                title: 'Delete Auto Tip Campaign',
                                                message: 'Are you sure you want to delete this Auto Tip Campaign?',
                                                cancel: {
                                                    text: 'Cancel',
                                                    variant: 'outlined',
                                                },
                                                ok: {
                                                    text: 'Delete',
                                                    variant: 'contained',
                                                    color: 'error',
                                                },
                                            })
                                            .then(() => {
                                                if (autoCampaignTipReply._id) {
                                                    handleDeleteAutoCampaignTipReply(autoCampaignTipReply._id);
                                                }
                                            })
                                            .catch(() => {});
                                    }}
                                >
                                    <DeleteIcon fontSize="small" />
                                </IconButton>
                            </span>
                        </Tooltip>
                    </Stack>
                </TableCell>
            </TableRow>
            <TableRow
                sx={
                    {
                        // backgroundColor: theme.palette.grey[50],
                    }
                }
            >
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
                    <Box
                        sx={{
                            pl: 3,
                        }}
                    >
                        <Table
                            size="small"
                            sx={{
                                // No padding on the left and right of the table but only on the first and last cell
                                '& td': {
                                    '&:first-of-type': {
                                        paddingLeft: '0px',
                                        paddingRight: '0px',
                                    },
                                    '&:last-child': {
                                        paddingRight: '0px',
                                    },
                                },
                                '& th': {
                                    '&:first-of-type': {
                                        paddingLeft: '0px',
                                        paddingRight: '0px',
                                    },
                                    '&:last-child': {
                                        paddingRight: '0px',
                                    },
                                    borderBottom: 'none',
                                },
                                // Remove the bordder from the cells in the last row
                                '& tr:last-child td': {
                                    borderBottom: 'none',
                                },
                            }}
                        >
                            <TableHead>
                                <TableRow>
                                    <TableCell>Tip Amount</TableCell>
                                    <TableCell width={70} align="center">
                                        Replies
                                    </TableCell>
                                    <TableCell width={100} align="center">
                                        Actions
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {autoCampaignTipReply.tipsOptions.map((tipOption: number, index: number) => (
                                    <SextforceAutoCampaignTipReplyTableTipRow
                                        subscriber={subscriber}
                                        autoCampaignTipReply={autoCampaignTipReply}
                                        tipOption={tipOption}
                                        theme={theme}
                                        refetchAutoCampaignTipReplies={refetchAutoCampaignTipReplies}
                                        createAutoCampaignTipReplyTipMessageDetails={createAutoCampaignTipReplyTipMessageDetails}
                                        updateAutoCampaignTipReplyTipMessageDetails={updateAutoCampaignTipReplyTipMessageDetails}
                                        deleteAutoCampaignTipReplyTipMessageDetails={deleteAutoCampaignTipReplyTipMessageDetails}
                                        key={index}
                                    />
                                ))}
                            </TableBody>
                        </Table>
                    </Box>
                </TableCell>
            </TableRow>
            {editDialogOpen && (
                <SextforceAutoCampaignTipReplyEdit
                    subscriber={subscriber}
                    autoCampaignTipReply={autoCampaignTipReply}
                    updateAutoCampaignTipReply={updateAutoCampaignTipReply}
                    open={editDialogOpen}
                    onClose={() => setEditDialogOpen(false)}
                    refetchAutoCampaignTipReply={refetchAutoCampaignTipReplies}
                    theme={theme}
                />
            )}
        </>
    );
};

export default SextforceAutoCampaignTipReplyTableRow;
