import { useDialog } from 'muibox';
import { useContext } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { SettingsContext } from '../store/SettingsContext';
import { UserContext } from '../store/UserContext';
import { handleHttpError } from '../utils/common';
import useAxios from './useAxios';
import { OnlyFansMetricsTelegramNotifications } from './useSextforceMetricsTelegramInvitationLink';

const useSextforceMetricsGroupsTelegramInvitationLink = (groupId: string | null | void | undefined) => {
    const userContext = useContext(UserContext);
    const settingsContext = useContext(SettingsContext);
    const dialog = useDialog();
    const params = useParams();
    const axios = useAxios();

    // Fetch Fans Counters
    const fetchInvitationLinks = async (groupId: string | null | void | undefined): Promise<OnlyFansMetricsTelegramNotifications[]> => {
        if (!groupId) {
            return [];
        }

        const query: string = `${settingsContext.routes.metrics.base}${params.userId}/groups/${groupId}/invite`;

        // Update campaign settings
        const data = await axios
            .get(query)
            .then(async response => response.data as OnlyFansMetricsTelegramNotifications[])
            .catch(error => {
                console.error(error);
                handleHttpError(error, dialog);

                return [];
            });

        return data;
    };

    const metricsTelegramInvitationLinks = useQuery(
        ['metricsGroupsTelegramInvitationLinks', userContext.jwtToken, params.userId, groupId],
        () => fetchInvitationLinks(groupId),
        {
            refetchOnWindowFocus: false,
            staleTime: 0,
            enabled: userContext.jwtToken && settingsContext.apiKey && params.userId && groupId ? true : false,
        },
    );

    return { ...metricsTelegramInvitationLinks };
};

export default useSextforceMetricsGroupsTelegramInvitationLink;
