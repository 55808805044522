import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faArrowTrendDown, faArrowTrendUp, faEquals } from '@fortawesome/free-solid-svg-icons';
import AudioFileIcon from '@mui/icons-material/AudioFile';
import GifBoxIcon from '@mui/icons-material/GifBox';
import PhotoIcon from '@mui/icons-material/Photo';
import TheatersIcon from '@mui/icons-material/Theaters';
import { Theme } from '@mui/system';
import { ContentState, EditorState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import moment from 'moment';
import { Dialog } from 'muibox';
import { Platform } from '../store/SettingsContext';

/**
 * Object with key-value pairs of MomentJS periods
 */
export const momentPeriods: any = { hours: 'Hours', days: 'Days', weeks: 'Weeks', months: 'Months', years: 'Years' };

/**
 * Retrieves the nested member of an object as described by a string path
 * @param theObject Object to process
 * @param path Path to the object member to retrieve
 * @param separator Optional: Separator string between nested members
 * @returns Nested member or undefined if not found
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getNested = (theObject: any, path: string, separator: string = '.'): any => {
    try {
        return path
            .replace('[', separator)
            .replace(']', '')
            .split(separator)
            .reduce((obj, property) => {
                return obj[property];
            }, theObject);
    } catch (err) {
        return undefined;
    }
};

/**
 * Converts a BSON DECIMAL128 number object to JavaScript Number
 * @param d Decimal128 object
 * @returns Number
 */
export const d2f = (d: any): number => (d && d['$numberDecimal'] ? parseFloat(d['$numberDecimal']) : 0);

export const f2d = (f: number): any => ({ $numberDecimal: f.toString() });

/**
 * Adds two Decimal128 numbers
 * @param a First number
 * @param b Second number
 */
export const decimal128Add = (a: any, b: any): any => {
    const aNumber = d2f(a);
    const bNumber = d2f(b);

    const total = aNumber + bNumber;

    return f2d(total);
};

/**
 * Subtracts two Decimal128 numbers
 * @param a First number
 * @param b Second number
 */
export const decimal128Subtract = (a: any, b: any): any => {
    const aNumber = d2f(a);
    const bNumber = d2f(b);

    const total = aNumber - bNumber;

    return f2d(total);
};

/**
 * Adds two Decimal128 or number numbers
 * @param a First number
 * @param b Second number
 */
export const decimal128OrNumberAdd = (a: any | number, b: any | number): any => {
    let aTemp: any;
    let bTemp: any;

    if (typeof a === 'number') {
        aTemp = f2d(a);
    } else {
        aTemp = a;
    }

    if (typeof b === 'number') {
        bTemp = f2d(b);
    } else {
        bTemp = b;
    }

    return decimal128Add(aTemp, bTemp);
};

/**
 * Subtracts two Decimal128 or number numbers
 * @param a First number
 * @param b Second number
 */
export const decimal128OrNumberSubtract = (a: any | number, b: any | number): any => {
    let aTemp: any;
    let bTemp: any;

    if (typeof a === 'number') {
        aTemp = f2d(a);
    } else {
        aTemp = a;
    }

    if (typeof b === 'number') {
        bTemp = f2d(b);
    } else {
        bTemp = b;
    }

    return decimal128Subtract(aTemp, bTemp);
};

/**
 * Converts an Object ID string to a Date object
 * @param objectIdString A valid Object ID string
 * @returns Date object
 */
export const objectIdToDate = (objectIdString: string) => new Date(parseInt(objectIdString.substring(0, 8), 16) * 1000);

/**
 * Returns the trend color string based on which price is higher
 * @param mainPrice Main price
 * @param comparePrice Price to compare with
 * @param theme Current Theme
 * @returns Color string
 */
export const trendColor = (mainPrice: Dinero.Dinero, comparePrice: Dinero.Dinero, compare: boolean, theme: Theme) => {
    if (compare && theme) {
        if (mainPrice.greaterThan(comparePrice)) {
            return theme.palette.success.main;
        } else if (mainPrice.lessThan(comparePrice)) {
            return theme.palette.error.main;
        }
    }

    return '#999999';
};

/**
 * Returns the appropriate FontAwesome trend icon based on the price comparisson
 * @param mainPrice Main price
 * @param comparePrice Price to compare with
 * @param compare True is to compare mainPrice to comparePrice
 * @returns A FontAwesome Icon
 */
export const trendIcon = (mainPrice: Dinero.Dinero, comparePrice: Dinero.Dinero, compare: boolean): IconDefinition => {
    let icon: IconDefinition = faEquals;

    if (compare && mainPrice && comparePrice) {
        if (mainPrice.greaterThan(comparePrice)) {
            icon = faArrowTrendUp;
        } else {
            icon = faArrowTrendDown;
        }
    }

    return icon;
};

// This is the function we wrote earlier
export const copyTextToClipboard = async (text: string) => {
    if ('clipboard' in navigator) {
        return await navigator.clipboard.writeText(text).catch(error => {
            console.error(error);
        });
    }
};

/**
 * Parses the response error and displays an alert dialog
 * @param response Fetch Response object
 * @param dialog Dialog hook
 */
export const handleHttpErrorResponse = async (response: Response, dialog: Dialog) => {
    const error = await response.json().catch(error => console.error(error));

    if (response.status === 401 && error.message === 'jwt expired') {
        // dialog.alert({ title: 'Error', message: `Session expired. The page will refresh now.`, ok: { variant: 'contained' } });

        window.location.reload();
    } else {
        dialog.alert({
            title: 'Error',
            message: `${response.status} (${response.statusText}) - ${error && error.message}`,
            ok: { text: 'ok', variant: 'contained' },
        });
    }
};

/**
 * Parses the HTTP error and displays an alert dialog
 * @param error Fetch Error object
 * @param dialog Dialog hook
 */
export const handleHttpError = async (error: Error | any, dialog: Dialog) => {
    if (error.response && error.response.status && error.response.status === 401 && error.response.data.message === 'jwt expired') {
        // dialog.alert({ title: 'Error', message: `Session expired. The page will refresh now.`, ok: { variant: 'contained' } });

        window.location.reload();
    }

    let message = '';

    if (error.response && error.response.data && error.response.data.status) {
        message += `${error.response.data.status} - `;
    } else if (error.status) {
        message += `${error.status} - `;
    }

    if (error.response && error.response.data && error.response.data.message) {
        message += error.response.data.message;
    } else {
        message += error.message;
    }

    return dialog.alert({
        title: 'Error',
        message,
        ok: { text: 'ok', variant: 'contained' },
    });
};

export type MuiColorPalletName =
    | 'inherit'
    | 'disabled'
    | 'action'
    | 'primary'
    | 'secondary'
    | 'error'
    | 'info'
    | 'success'
    | 'warning'
    | undefined;

/**
 * Returns true if the given service is active
 * @param subscriber OnlyFansSubscriber object
 * @param serviceName Service key name inside subscriber object
 * @param alwaysActive True if service is always active
 * @returns True if service is active
 */
export const isServiceActive = (subscriber: any, serviceName: string, alwaysActive: boolean): boolean => {
    if (alwaysActive) {
        return true;
    }

    if (serviceName in subscriber && subscriber[serviceName] && subscriber[serviceName].active) {
        // Check the service's paid date
        if (subscriber[serviceName].paidDate) {
            const monthAgo = moment().subtract(1, 'months').subtract(1, 'days');

            if (monthAgo.isSameOrBefore(subscriber[serviceName].paidDate)) {
                return true;
            }

            return false;
        }
    }

    return false;
};

/**
 * Returns the MUI color name that represents the given service's status
 * @param subscriber OnlyFansSubscriber object
 * @param serviceName Service key name inside subscriber object
 * @param theme MUI Theme object
 * @returns MUI Pallet Color name
 */
export const getServiceStatusColor = (subscriber: any, serviceName: string, alwaysActive: boolean): MuiColorPalletName => {
    if (
        (alwaysActive ||
            (serviceName in subscriber && subscriber[serviceName] && subscriber[serviceName].active && subscriber[serviceName].paidDate)) &&
        subscriber.isLoggedIn
    ) {
        const monthAgo = moment().subtract(1, 'months').subtract(1, 'days');

        if (alwaysActive || monthAgo.isSameOrBefore(subscriber[serviceName].paidDate)) {
            return 'success';
        } else {
            return 'warning';
        }
    } else {
        return 'error';
    }
};

/**
 * Returns the descriptive string that represents the given service's status
 * @param subscriber OnlyFansSubscriber object
 * @param serviceName Service key name inside subscriber object
 * @returns String name
 */
export const getServiceStatusName = (subscriber: any, serviceName: string, alwaysActive: boolean): string => {
    if (!subscriber.isLoggedIn) {
        return 'Not Logged In';
    }

    if (alwaysActive || (serviceName in subscriber && subscriber[serviceName] && subscriber[serviceName].active)) {
        if (!alwaysActive) {
            if (subscriber[serviceName].paidDate) {
                const monthAgo = moment().subtract(1, 'months').subtract(1, 'days');

                if (monthAgo.isSameOrBefore(subscriber[serviceName].paidDate)) {
                    return 'Active';
                } else {
                    return 'Payment Due';
                }
            } else {
                return 'No Payment Found';
            }
        } else {
            return 'Available';
        }
    } else {
        return 'Not Active';
    }
};

/**
 * Returns the descriptive string that represents the given service's status
 * @param subscriber OnlyFansSubscriber object
 */
export const creditStatus = (amount: number): string => {
    if (amount === 0) {
        return 'none';
    }

    if (amount < 5) {
        return 'low';
    }

    return 'good';
};

/**
 * Returns a pretty string name that represents the given group of users
 * @param t Users group
 * @param filter Session filter object
 * @returns String name
 */
export const targetUsersName = (t: string, filter: any): string => {
    if (t === 'followingActive') {
        return 'Following→Active';
    }

    if (t === 'followingExpired') {
        return 'Following→Expired';
    }

    if (t === 'fansExpired' && filter && Object.keys(filter).length > 0) {
        return 'Fans→Expired (Priority)';
    }

    if (t === 'fansExpired' && (!filter || Object.keys(filter).length === 0)) {
        return 'Fans→Expired';
    }

    if (t === 'fansActive') {
        return 'Fans→Active';
    }

    if (t === 'fansAll') {
        return 'Fans & Following→Active';
    }

    if (t === 'onlineAll') {
        return 'Fans & Following→Active Online';
    }

    if (t === 'onlineActive') {
        return 'Fans→Active Online';
    }

    if (t === 'onlineFollowing') {
        return 'Following→Active Online';
    }

    if (t === 'onlineExpired') {
        return 'Fans→Expired Online';
    }

    if (t === 'lists') {
        return 'List';
    }

    if (t === 'recentSpenders') {
        return 'Recent Spenders';
    }

    return 'Unknown;';
};

/**
 * Converts a Sextforce Metric Type string to a user-friendly descriptive name string
 * @param type Metric type
 * @returns Descriptive name
 */
export const metricTypeName = (type: string): string => {
    switch (type) {
        case 'promoCampaign':
            return 'Campaign';

        case 'trialLinkPromo':
            return 'Promo';

        case 'trialLinkTrial':
            return 'Trial';

        default:
            return 'Unknown';
    }
};

/**
 * Converts a platform key string to a user-friendly descriptive name string from the given platforms object
 */
export const getPlatformName = (platforms: { [name: string]: Platform }, platform: string) => {
    return platforms[platform] ? platforms[platform].name : platform;
};

/**
 * Returns the platform icon from the given platforms object
 * @param platforms All available platforms
 * @param platform Platform key
 * @returns
 */
export const getPlatformIcon = (
    platforms: { [name: string]: Platform },
    platform: string,
    fontSize?: 'small' | 'inherit' | 'medium' | 'large' | undefined,
) => {
    return platforms[platform] && platforms[platform].icon && typeof platforms[platform].icon === 'function'
        ? platforms[platform].icon!(fontSize)
        : '';
};

/**
 * Checks if the input string is a valid email
 * @param value Input string to validate
 * @returns True is value is a valid email
 */
export const isValidEmail = (value: string): boolean => {
    return value.match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    )
        ? true
        : false;
};

export const comparrisonConditions: any = {
    eq: 'Equal To',
    lt: 'Less Than',
    lte: 'Less Than or Equal To',
    gt: 'Greater Than',
    gte: 'Greater Than or Equal To',
};

/**
 * Constructs a URL strong from the given basePath and path
 */
export const buildURL = (basePath: string, path: string): string => {
    if (basePath.endsWith('/')) {
        return `${basePath}${path}`;
    }

    return `${basePath}/${path}`;
};

/**
 * Capitalises the first letter of a
 * @param param0 String
 * @param locale Locale name
 * @returns Capitalised string
 */
export const titleCase = (str: string) => {
    var splitStr = str.toLowerCase().split(' ');

    for (var i = 0; i < splitStr.length; i++) {
        // You do not need to check if i is larger than splitStr length, as your for does that for you
        // Assign it back to the array
        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }

    // Directly return the joined string
    return splitStr.join(' ');
};

export const zeroPad = (num: number, places: number) => String(num).padStart(places, '0');

/**
 * Find the ranges of consecutive numbers from array
 * @param a Array of numbers
 * @returns Array of objects describing consectutive number ranges [{ first: 11, last: 14 }]
 */
export const consecutiveRanges = (arr: number[]): { first: number; last: number }[] => {
    // Sort the input array
    const a: number[] = arr.sort();
    let length = 1;
    const list: { first: number; last: number }[] = [];

    // If the array is empty, return the list
    if (a.length === 0) {
        return list;
    }

    // Traverse the array from first position
    for (let i = 1; i <= a.length; i += 1) {
        // Check the difference between the current and the previous elements
        // If the difference doesn't equal to 1 just increment the length variable.
        if (i === a.length || a[i] - a[i - 1] !== 1) {
            // If the range contains only one element, add it into the list.
            if (length > 1) {
                // Build the range between the first element of the range
                // and the current previous element as the last range.
                list.push({ first: a[i - length], last: a[i - 1] });
            }

            // After finding the first range initialize the length by 1 to build the next range.
            length = 1;
        } else {
            length += 1;
        }
    }

    return list;
};

/**
 * Returns the appropriate media type icon based on the given type
 */
export const mediaTypeIcon = (type: string, theme: Theme) => {
    if (type === 'video') {
        return <TheatersIcon htmlColor={theme.palette.common.white} />;
    }

    if (type === 'photo') {
        return <PhotoIcon htmlColor={theme.palette.common.white} />;
    }

    if (type === 'audio') {
        return <AudioFileIcon htmlColor={theme.palette.common.white} />;
    }

    if (type === 'gif') {
        return <GifBoxIcon htmlColor={theme.palette.common.white} />;
    }
};

/**
 * Formats the given number to a left-padded string
 * @param num Number to format
 * @param targetLength The needed padded length
 * @param padding [optional] Padding string. Default = " "
 * @returns Padded string
 */
export function leftFillNum(num: number, targetLength: number, padding: string = ' '): string {
    return num.toString().padStart(targetLength, padding);
}

/**
 * Returns the appropriate media type icon based on the given type
 * @param seconds Seconds to convert to minutes and seconds string
 * @returns String in the format of MM:SS
 */
export const secondsToMinutesAndSecondsString = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const secondsRemainder = seconds % 60;

    const secondsString = secondsRemainder < 10 ? `0${secondsRemainder}` : `${secondsRemainder}`;
    const minutesString = minutes < 10 ? `0${minutes}` : `${minutes}`;

    return `${minutesString}:${secondsString}`;
};

/**
 * Converts a number of seconds to a time string in the format of HH:MM:SS
 * @param seconds Seconds to convert to a time string
 * @returns Time string in the format of HH:MM:SS
 */
export const secondsToTimeString = (seconds: number): string => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const secs = Math.floor(seconds % 60);

    return `${leftFillNum(hours, 2, '0')}:${leftFillNum(minutes, 2, '0')}:${leftFillNum(secs, 2, '0')}`;
};

/**
 * Converts a time string in the format of HH:MM:SS to a number of seconds
 * @param timeString Time string in the format of HH:MM:SS
 * @returns Number of seconds
 */
export const getPrettyDateDiff = (date1: Date, date2: Date): string => {
    const diff = Math.abs(date2.getTime() - date1.getTime());
    const diffDays = Math.floor(diff / (1000 * 60 * 60 * 24));
    const diffHours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const diffMinutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));

    const parts: string[] = [];

    if (diffDays > 0) {
        parts.push(`${diffDays} day${diffDays !== 1 ? 's' : ''}`);
    }
    if (diffHours > 0) {
        parts.push(`${String(diffHours).padStart(2, '0')}h:${String(diffMinutes).padStart(2, '0')}m`);
    } else if (diffMinutes > 0) {
        parts.push(`00:${String(diffMinutes).padStart(2, '0')}m`);
    }

    if (parts.length === 0) {
        return '00:00';
    }

    return parts.join(' ');
};

/**
 * Check if the Sextforce is active and paid for
 * @param subscriber OnlyFansSubscriber
 * @returns boolean - true if the Sextforce is active
 */
export const isSextforceActive = (subscriber: any): boolean => {
    // Check if Sextforce is active and paid for
    if (!subscriber.sextforce || !subscriber.sextforce.active || !subscriber.sextforce.paidDate) {
        return false;
    }

    // Parse the paidDate using Moment.js
    const serviceEndDate = moment(subscriber.sextforce.paidDate);
    // const dayOfMonth = serviceEndDate.date();

    // How many days in this month
    // const daysInMonth = serviceEndDate.daysInMonth();

    // Add one month
    serviceEndDate.add(1, 'months');

    // Check if the day of the month has changed after adding a month
    // If it has, set it to the last day of the previous month
    // if (serviceEndDate.date() !== dayOfMonth) {
    //     serviceEndDate.date(0);
    // }

    // Add 1 day grace period
    serviceEndDate.add(1, 'days');

    // Compare with the current date
    return serviceEndDate.isSameOrAfter(moment());
};

export const getWeekRange = (year: number, weekNumber: number, startDay: 'sunday' | 'monday') => {
    const firstDayOfWeek: Date = moment(`${year}${leftFillNum(weekNumber, 2, '0')}`, 'YYYYWW').toDate();
    const lastDayOfWeek: Date = moment(firstDayOfWeek).endOf('week').toDate();

    return {
        firstDayOfWeek, // new Date(firstDayOfWeek.toISOString().split('T')[0]),
        lastDayOfWeek,
    };
};

/**
 * Extract the post ID from the post URL. Example: https://onlyfans.com/844770628/ginnypotter - 844770628
 * @param postUrl OnlyFans Post URL
 * @returns Post ID
 */
export const getPostIdFromPostUrl = (postUrl: string): number => {
    // Use RegEx to extract the post ID from the URL
    const postId = postUrl.match(/\/(\d+)\//)?.[1];

    if (!postId) {
        return 0;
    }

    return parseInt(postId, 10);
};

/**
 * Converts an HTML string to a DraftJS EditorState object
 * @param savedHtml HTML string to convert to DraftJS EditorState
 * @returns DraftJS EditorState object
 */
export const loadDraftsJSEditorStateFromHtml = (savedHtml: string) => {
    if (!savedHtml) {
        return EditorState.createEmpty();
    }

    const blocksFromHtml = htmlToDraft(savedHtml);
    const { contentBlocks, entityMap } = blocksFromHtml;

    if (!contentBlocks || !entityMap) {
        return EditorState.createEmpty();
    }

    if (contentBlocks.length === 0) {
        return EditorState.createEmpty();
    }

    const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
    const newEditorState = EditorState.createWithContent(contentState);

    return newEditorState;
};

/**
 * Strips the HTML tags from the given HTML string
 * @param html HTML string
 * @returns String without HTML tags
 */
export const stripHtml = (html: string) => {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || '';
};

/**
 * Strips the HTML tags from the given HTML string and converts <p> tags to newlines
 * @param html HTML string
 * @returns String without HTML tags
 */
export const stripHtmlAndConvertParagraphs = (html: string): string => {
    // First, replace <p> tags with newlines
    let text = html.replace(/<p[^>]*>/gi, '\n').replace(/<\/p>/gi, '\n');

    // Then remove all remaining HTML tags
    text = text.replace(/<[^>]*>/g, '');

    // Decode HTML entities
    text = text
        .replace(/&nbsp;/g, ' ')
        .replace(/&amp;/g, '&')
        .replace(/&lt;/g, '<')
        .replace(/&gt;/g, '>')
        .replace(/&quot;/g, '"')
        .replace(/&#039;/g, "'");

    // Remove extra whitespace
    text = text.trim();

    return text;
};

export const convertNewlinesToParagraphs = (text: string): string => {
    // Split the text into an array of lines
    const lines = text.split('\n');

    // Filter out empty lines and wrap each non-empty line with <p> tags
    const paragraphs = lines.filter(line => line.trim() !== '').map(line => `<p>${line.trim()}</p>`);

    // Join the paragraphs back into a single string
    return paragraphs.join('');
};
