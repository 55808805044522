import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Button, Dialog, DialogActions, DialogContent, Grid, Typography, useTheme } from '@mui/material';
import { LocalizationProvider, StaticDateTimePicker } from '@mui/x-date-pickers-pro';
import { AdapterMoment } from '@mui/x-date-pickers-pro/AdapterMoment';
import moment from 'moment';
import { useDialog } from 'muibox';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useAxios from '../../../../hooks/useAxios';
import { SextforceAutoRepost } from '../../../../hooks/useSextforceAutoRepost';
import { SettingsContext } from '../../../../store/SettingsContext';
import { UserContext } from '../../../../store/UserContext';
import { handleHttpError } from '../../../../utils/common';
import StyledCardTitleBar from '../../../common/StyledCardTitleBar';

type Props = {
    subscriber: any;
    autoRepost: SextforceAutoRepost;
    onClose: () => void;
    open: boolean;
    refetchAutoReposts: () => void;
};

const SextforceAutoRepostChangeNextAutoRepostDateDialog = (props: Props) => {
    const { subscriber, autoRepost, onClose, open, refetchAutoReposts } = props;
    const userContext = useContext(UserContext);
    const settingsContext = useContext(SettingsContext);
    const params = useParams();
    const dialog = useDialog();
    const axios = useAxios();
    const theme = useTheme();

    const [nextRepostDate, setNextRepostDate] = useState<Date | undefined>(autoRepost.nextRepostDate);
    const [isSaving, setIsSaving] = useState<boolean>(false);

    useEffect(() => {
        if (!open) {
            setIsSaving(false);
        } else {
            setNextRepostDate(autoRepost.nextRepostDate);
        }
    }, [autoRepost.nextRepostDate, open]);

    const handleClose = (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => {
        if (reason && reason === 'backdropClick') {
            return;
        }

        onClose();
    };

    const handleSave = () => {
        const updateAutoRepost = async () => {
            if (userContext.jwtToken && settingsContext.apiKey && 'userId' in params && params.userId && subscriber && autoRepost) {
                const query: string = `${settingsContext.routes.autoRepost.base}${params.userId}/${autoRepost._id}/nextRepostDate`;

                setIsSaving(true);

                // Update campaign settings
                const updatedAutoRepost = await axios
                    .put(query, {
                        nextRepostDate: nextRepostDate,
                    })
                    .then(async response => response.data)
                    .catch(error => {
                        console.error(error);
                        setIsSaving(false);
                        handleHttpError(error, dialog);
                    });

                if (updatedAutoRepost && updatedAutoRepost._id) {
                    dialog
                        .alert({
                            title: 'Auto Repost Next Repost Time',
                            message: `Auto Repost's next repost time updated successfully.`,
                        })
                        .then(() => {
                            onClose();
                            refetchAutoReposts();
                        })
                        .catch(() => {});
                } else {
                    dialog
                        .alert({
                            title: 'Auto Repost Next Repost Time',
                            message: 'Auto Repost could not be updated.',
                        })
                        .then(() => {})
                        .catch(() => {});
                }

                setIsSaving(false);
            }
        };

        updateAutoRepost();
    };

    return (
        <>
            <Dialog open={open} onClose={handleClose} disableEscapeKeyDown>
                <StyledCardTitleBar title="Change Next Repost Time" theme={theme} />
                <DialogContent>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <Grid container spacing={0.5} alignItems="center" justifyContent="center">
                            <Grid item xs={12}>
                                Next Repost Date/Time
                                <Typography variant="body2" color={'text.secondary'} marginTop={0.5}>
                                    When should the next repost happen? Use this to stagger your reposts so they don't all happen at the
                                    same.
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                <Box
                                    sx={{
                                        width: '320px',
                                    }}
                                >
                                    <StaticDateTimePicker
                                        value={moment(nextRepostDate)}
                                        onChange={date => setNextRepostDate(moment(date).toDate())}
                                        // renderInput={props => <TextField fullWidth size="medium" {...props} />}
                                        ampm={true}
                                        disablePast={true}
                                        minDateTime={moment()}
                                        maxDateTime={autoRepost.endDate ? moment(autoRepost.endDate) : undefined}
                                        sx={{
                                            // hide ok/cancel buttons
                                            '.MuiDialogActions-root': {
                                                display: 'none',
                                            },
                                            // hide "select date & time" text
                                        }}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </LocalizationProvider>
                </DialogContent>
                <DialogActions>
                    <Button
                        color="error"
                        size="medium"
                        disabled={isSaving}
                        onClick={() => {
                            handleClose({}, 'escapeKeyDown');
                        }}
                    >
                        Close
                    </Button>
                    <LoadingButton
                        color="secondary"
                        variant="contained"
                        size="medium"
                        loading={isSaving}
                        disabled={
                            moment(nextRepostDate).isSame(autoRepost.nextRepostDate) ||
                            !nextRepostDate ||
                            !moment(nextRepostDate).isValid() ||
                            !moment(nextRepostDate).isAfter(moment())
                        }
                        onClick={() => {
                            handleSave();
                        }}
                    >
                        Save Settings
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default SextforceAutoRepostChangeNextAutoRepostDateDialog;
