import { FormControlLabel, Switch, Typography } from '@mui/material';
import { Theme } from '@mui/system';

type FormikCardSectionHeadingWithSwitchProps = {
    theme: Theme;
    formik: any;
    fieldName: string;
    label: string;
    switchParam: boolean;
    setSwitchParam: React.Dispatch<React.SetStateAction<boolean>>;
};

const FormikCardSectionHeadingWithSwitch = (params: FormikCardSectionHeadingWithSwitchProps) => {
    const { theme, formik, fieldName, label, switchParam, setSwitchParam } = params;

    return (
        <Typography variant="h6" sx={{ marginBottom: theme.spacing(2) }}>
            <FormControlLabel
                control={
                    <Switch
                        onChange={(e, checked) => {
                            if (!checked) {
                                delete formik.values[fieldName];
                            } else {
                                formik.values[fieldName] = {};
                            }

                            setSwitchParam(checked);
                        }}
                        checked={switchParam}
                    />
                }
                label={label}
                disableTypography
            />
        </Typography>
    );
};

export default FormikCardSectionHeadingWithSwitch;
