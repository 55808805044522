import moment from 'moment-timezone';
import { useDialog } from 'muibox';
import { useContext } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { SettingsContext } from '../store/SettingsContext';
import { UserContext } from '../store/UserContext';
import { handleHttpError } from '../utils/common';
import useAxios from './useAxios';

export type SextforceMetricsCampaignsClaimedTodayOverview = { date: Date; data: { metricId: string; name: string; count: number }[] };

const useSextforceMetricsCampaignsClaimedTodayOverview = (subscriber: any, timezone: string, startDate: Date, endDate: Date) => {
    const userContext = useContext(UserContext);
    const settingsContext = useContext(SettingsContext);
    const params = useParams();
    const dialog = useDialog();
    const axios = useAxios();

    // Fetch Paginated Report
    const fetchData = async (): Promise<SextforceMetricsCampaignsClaimedTodayOverview[]> => {
        if (userContext.jwtToken && settingsContext.apiKey && 'userId' in params && params.userId && subscriber) {
            const query: string = `${settingsContext.routes.metrics.base}${
                params.userId
            }/campaignsSubscribedTodayOverview?${new URLSearchParams({
                startDate: moment(startDate).tz(timezone, true).startOf('day').format(),
                endDate: moment(endDate).tz(timezone, true).endOf('day').format(),
                timezone,
            })}`;

            const data = await axios
                .get(query)
                .then(response => response.data as SextforceMetricsCampaignsClaimedTodayOverview[])
                .catch(error => {
                    console.error(error);
                    handleHttpError(error, dialog);

                    return [];
                });

            return data;
        }

        return [];
    };

    return useQuery(['metricsCampaignsClaimedTodayOverview', subscriber, timezone, startDate, endDate], () => fetchData(), {
        refetchOnWindowFocus: false,
        staleTime: 60 * 60 * 1000,
        enabled: subscriber ? true : false,
    });
};

export default useSextforceMetricsCampaignsClaimedTodayOverview;
