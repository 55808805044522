import { Box, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Theme, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import { useState } from 'react';
import StyledCard from '../../../common/StyledCard';

type Props = {
    autoRepostsLoading: boolean;
    active: boolean;
    setActive: (active: boolean) => void;
    setQuery: (query: string) => void;
    startDate: Date;
    setStartDate: (startDate: Date) => void;
    endDate: Date;
    setEndDate: (endDate: Date) => void;
    sortBy: '_id' | 'name' | 'description' | 'nextRepostDate';
    setSortBy: (sortBy: '_id' | 'name' | 'description' | 'nextRepostDate') => void;
    theme: Theme;
};

const SextforceAutoRepostSearchBar = (props: Props) => {
    const { autoRepostsLoading, active, setActive, setQuery, startDate, setStartDate, endDate, setEndDate, sortBy, setSortBy, theme } =
        props;

    const [queryText, setQueryText] = useState<string>('');
    const [startDateField, setStartDateField] = useState<Date | null>(startDate); // Used for the date picker
    const [endDateField, setEndDateField] = useState<Date | null>(endDate); // Used for the date picker

    const handleQueryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        // Add a small delay to avoid too many requests
        setTimeout(() => {
            setQuery(event.target.value);
        }, 500);

        setQueryText(event.target.value);
    };

    return (
        <StyledCard noCard noBottomMargin>
            <Box
                sx={{
                    bgcolor: 'grey.50',
                    p: 2,
                    pt: 4,
                    // Elevation 1
                    boxShadow: theme.shadows[1],
                    borderRadius: theme.shape.borderRadius,
                }}
            >
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <Grid container spacing={1} alignItems="center" sx={{ marginBottom: theme.spacing(2) }}>
                        <Grid item xs={12}>
                            <Grid container spacing={1} alignItems="center">
                                <Grid item xs="auto">
                                    <ToggleButtonGroup
                                        color="primary"
                                        value={active}
                                        exclusive
                                        size="small"
                                        onChange={(event, value) => {
                                            if (value !== null) {
                                                setActive(value);
                                            }
                                        }}
                                        sx={{
                                            mt: '4px',
                                            backgroundColor: theme.palette.background.paper,
                                        }}
                                    >
                                        <ToggleButton value={true}>Active</ToggleButton>
                                        <ToggleButton value={false}>Inactive</ToggleButton>
                                    </ToggleButtonGroup>
                                </Grid>

                                <Grid item xs>
                                    <TextField
                                        fullWidth
                                        label="Search"
                                        value={queryText}
                                        onChange={handleQueryChange}
                                        onKeyDown={e => {
                                            // Clear on Escape
                                            if (e.key === 'Escape') {
                                                setQuery('');
                                                setQueryText('');
                                            }
                                        }}
                                        sx={{
                                            backgroundColor: theme.palette.background.paper,
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={6} sm="auto">
                            <MobileDatePicker
                                label="Start Date"
                                value={startDateField}
                                onAccept={(date: moment.Moment | null) => {
                                    if (date) {
                                        setStartDate(date.toDate());
                                    }
                                }}
                                onChange={(date: moment.Moment | null) => {
                                    if (date) {
                                        setStartDateField(date.toDate());
                                    }
                                }}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        sx={{
                                            backgroundColor: theme.palette.background.paper,
                                        }}
                                    />
                                )}
                            />
                        </Grid>

                        <Grid item xs={6} sm="auto">
                            <MobileDatePicker
                                label="End Date"
                                value={endDateField}
                                minDate={moment(startDate)}
                                onAccept={(date: moment.Moment | null) => {
                                    if (date) {
                                        setEndDate(date.toDate());
                                    }
                                }}
                                onChange={(date: moment.Moment | null) => {
                                    if (date) {
                                        setEndDateField(date.toDate());
                                    }
                                }}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        sx={{
                                            backgroundColor: theme.palette.background.paper,
                                        }}
                                    />
                                )}
                            />
                        </Grid>

                        <Grid item xs sm>
                            <FormControl fullWidth size="small">
                                <InputLabel id="sort-by-label">Sort By</InputLabel>
                                <Select
                                    labelId="sort-by-label"
                                    label="Sort By"
                                    fullWidth
                                    size="small"
                                    value={sortBy}
                                    onChange={e => {
                                        setSortBy(e.target.value as '_id' | 'name' | 'description' | 'nextRepostDate');
                                    }}
                                    sx={{
                                        backgroundColor: theme.palette.background.paper,
                                    }}
                                >
                                    <MenuItem value={'_id'}>Date Added</MenuItem>
                                    <MenuItem value={'name'}>Name</MenuItem>
                                    <MenuItem value={'description'}>Description</MenuItem>
                                    <MenuItem value={'nextRepostDate'}>Next Repost Date</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </LocalizationProvider>
            </Box>
        </StyledCard>
    );
};

export default SextforceAutoRepostSearchBar;
