import axios from 'axios';
import { useDialog } from 'muibox';
import { useContext, useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { SettingsContext } from '../store/SettingsContext';
import { UserContext } from '../store/UserContext';
import {
    createEmptyOnlyFansSubscriberMessageUsersAutoMessageDetails,
    OnlyFansSubscriberMessageUsersAutoMessageDetails,
} from '../types/messageUsersAutoSendWelcomeMessage';
import { handleHttpError } from '../utils/common';

const useSubscriberSettingsMessageUsersAutoWelcomeMessage = () => {
    const userContext = useContext(UserContext);
    const settingsContext = useContext(SettingsContext);
    const dialog = useDialog();
    const params = useParams();

    const [setServiceSettingsMessageUsersAutoWelcomeMessageLoading, setSetServiceSettingsMessageUsersAutoWelcomeMessageLoading] =
        useState<boolean>(false);

    const fetchServiceSettingsMessageUsersAutoWelcomeMessage = async (): Promise<{
        autoMessageNewSubscriber: OnlyFansSubscriberMessageUsersAutoMessageDetails | null;
        autoMessageReturningSubscriber: OnlyFansSubscriberMessageUsersAutoMessageDetails | null;
        autoMessageNewTrialSubscriber: OnlyFansSubscriberMessageUsersAutoMessageDetails | null;
    }> => {
        const query: string = `${settingsContext.routes.subscribers.base}${params.userId}/messageUsers.autoSendWelcomeMessage/settings`;

        return axios
            .get(query, {
                headers: {
                    Authorization: userContext.jwtToken,
                    apiKey: settingsContext.apiKey,
                },
            })
            .then(async response => {
                const data = response.data as {
                    autoMessageNewSubscriber: OnlyFansSubscriberMessageUsersAutoMessageDetails;
                    autoMessageReturningSubscriber: OnlyFansSubscriberMessageUsersAutoMessageDetails;
                    autoMessageNewTrialSubscriber: OnlyFansSubscriberMessageUsersAutoMessageDetails;
                };

                return {
                    autoMessageNewSubscriber:
                        data.autoMessageNewSubscriber || createEmptyOnlyFansSubscriberMessageUsersAutoMessageDetails(),
                    autoMessageReturningSubscriber:
                        data.autoMessageReturningSubscriber || createEmptyOnlyFansSubscriberMessageUsersAutoMessageDetails(),
                    autoMessageNewTrialSubscriber:
                        data.autoMessageNewTrialSubscriber || createEmptyOnlyFansSubscriberMessageUsersAutoMessageDetails(),
                };
            })
            .catch(async error => {
                console.error('getServiceSettingsMessageUsersAutoWelcomeMessage', error);
                await handleHttpError(error, dialog).then(() => {
                    return Promise.reject(error);
                });

                return {
                    autoMessageNewSubscriber: createEmptyOnlyFansSubscriberMessageUsersAutoMessageDetails(),
                    autoMessageReturningSubscriber: createEmptyOnlyFansSubscriberMessageUsersAutoMessageDetails(),
                    autoMessageNewTrialSubscriber: createEmptyOnlyFansSubscriberMessageUsersAutoMessageDetails(),
                };
            });
    };

    const serviceSettingsMessageUsersAutoWelcomeMessage = useQuery(
        ['serviceSettingsMessageUsersAutoWelcomeMessage', params.userId, userContext.jwtToken],
        () => fetchServiceSettingsMessageUsersAutoWelcomeMessage(),
        {
            refetchOnWindowFocus: false,
            staleTime: 100,
            enabled: userContext.jwtToken && settingsContext.apiKey && userContext.user.username && params.userId ? true : false,
        },
    );

    const setServiceSettingsMessageUsersAutoWelcomeMessage = async (
        autoMessageNewSubscriber: OnlyFansSubscriberMessageUsersAutoMessageDetails | null,
        saveTemplateNewSubscriber: boolean,
        saveTemplateNameNewSubscriber: string,
        autoMessageReturningSubscriber: OnlyFansSubscriberMessageUsersAutoMessageDetails | null,
        saveTemplateReturningSubscriber: boolean,
        saveTemplateNameReturningSubscriber: string,
        autoMessageNewTrialSubscriber: OnlyFansSubscriberMessageUsersAutoMessageDetails | null,
        saveTemplateNewTrialSubscriber: boolean,
        saveTemplateNameNewTrialSubscriber: string,
    ): Promise<{
        autoMessageNewSubscriber: OnlyFansSubscriberMessageUsersAutoMessageDetails | null;
        autoMessageReturningSubscriber: OnlyFansSubscriberMessageUsersAutoMessageDetails | null;
        autoMessageNewTrialSubscriber: OnlyFansSubscriberMessageUsersAutoMessageDetails | null;
    }> => {
        if (userContext.jwtToken && settingsContext.apiKey && userContext.user.username) {
            const query: string = `${settingsContext.routes.subscribers.base}${params.userId}/messageUsers.autoSendWelcomeMessage/settings`;

            setSetServiceSettingsMessageUsersAutoWelcomeMessageLoading(true);

            return axios
                .put(
                    query,
                    {
                        settings: {
                            autoMessageNewSubscriber,
                            autoMessageReturningSubscriber,
                            autoMessageNewTrialSubscriber,
                        },
                        saveTemplate: {
                            newSubscriber: {
                                enabled: saveTemplateNewSubscriber,
                                name: saveTemplateNameNewSubscriber,
                            },
                            returningSubscriber: {
                                enabled: saveTemplateReturningSubscriber,
                                name: saveTemplateNameReturningSubscriber,
                            },
                            newTrialSubscriber: {
                                enabled: saveTemplateNewTrialSubscriber,
                                name: saveTemplateNameNewTrialSubscriber,
                            },
                        },
                    },
                    {
                        headers: {
                            Authorization: userContext.jwtToken,
                            apiKey: settingsContext.apiKey,
                        },
                    },
                )
                .then(async response => {
                    setSetServiceSettingsMessageUsersAutoWelcomeMessageLoading(false);

                    return response.data;
                })
                .catch(async error => {
                    console.error('setServiceSettingsMessageUsersAutoWelcomeMessage', error);

                    setSetServiceSettingsMessageUsersAutoWelcomeMessageLoading(false);

                    await handleHttpError(error, dialog).then(() => {
                        return Promise.reject(error);
                    });
                });
        } else {
            return {
                autoMessageNewSubscriber: null,
                autoMessageReturningSubscriber: null,
                autoMessageNewTrialSubscriber: null,
            };
        }
    };

    const setServiceSettingsMessageUsersAutoWelcomeMessageNewSubscriber = async (
        autoMessageNewSubscriber: OnlyFansSubscriberMessageUsersAutoMessageDetails | null,
        saveTemplateNewSubscriber: boolean,
        saveTemplateNameNewSubscriber: string,
    ): Promise<{
        autoMessageNewSubscriber: OnlyFansSubscriberMessageUsersAutoMessageDetails | null;
        autoMessageReturningSubscriber: OnlyFansSubscriberMessageUsersAutoMessageDetails | null;
        autoMessageNewTrialSubscriber: OnlyFansSubscriberMessageUsersAutoMessageDetails | null;
    }> => {
        if (userContext.jwtToken && settingsContext.apiKey && userContext.user.username) {
            const query: string = `${settingsContext.routes.subscribers.base}${params.userId}/messageUsers.autoSendWelcomeMessageNewSubscribers/settings`;

            setSetServiceSettingsMessageUsersAutoWelcomeMessageLoading(true);

            return axios
                .put(
                    query,
                    {
                        settings: {
                            autoMessageNewSubscriber,
                        },
                        saveTemplate: {
                            enabled: saveTemplateNewSubscriber,
                            name: saveTemplateNameNewSubscriber,
                        },
                    },
                    {
                        headers: {
                            Authorization: userContext.jwtToken,
                            apiKey: settingsContext.apiKey,
                        },
                    },
                )
                .then(response => {
                    console.log('setServiceSettingsMessageUsersAutoWelcomeMessageNewSubscriber', response.data);
                    setSetServiceSettingsMessageUsersAutoWelcomeMessageLoading(false);

                    return response.data;
                })
                .catch(async error => {
                    console.error('setServiceSettingsMessageUsersAutoWelcomeMessageNewSubscriber', error);

                    setSetServiceSettingsMessageUsersAutoWelcomeMessageLoading(false);

                    await handleHttpError(error, dialog).then(() => {
                        return Promise.reject(error);
                    });
                });
        } else {
            return {
                autoMessageNewSubscriber: null,
                autoMessageReturningSubscriber: null,
                autoMessageNewTrialSubscriber: null,
            };
        }
    };

    const setServiceSettingsMessageUsersAutoWelcomeMessageReturningSubscriber = async (
        autoMessageReturningSubscriber: OnlyFansSubscriberMessageUsersAutoMessageDetails | null,
        saveTemplateReturningSubscriber: boolean,
        saveTemplateNameReturningSubscriber: string,
    ): Promise<{
        autoMessageNewSubscriber: OnlyFansSubscriberMessageUsersAutoMessageDetails | null;
        autoMessageReturningSubscriber: OnlyFansSubscriberMessageUsersAutoMessageDetails | null;
        autoMessageNewTrialSubscriber: OnlyFansSubscriberMessageUsersAutoMessageDetails | null;
    }> => {
        if (userContext.jwtToken && settingsContext.apiKey && userContext.user.username) {
            const query: string = `${settingsContext.routes.subscribers.base}${params.userId}/messageUsers.autoSendWelcomeMessageReturningSubscribers/settings`;

            setSetServiceSettingsMessageUsersAutoWelcomeMessageLoading(true);

            return axios
                .put(
                    query,
                    {
                        settings: {
                            autoMessageReturningSubscriber,
                        },
                        saveTemplate: {
                            enabled: saveTemplateReturningSubscriber,
                            name: saveTemplateNameReturningSubscriber,
                        },
                    },
                    {
                        headers: {
                            Authorization: userContext.jwtToken,
                            apiKey: settingsContext.apiKey,
                        },
                    },
                )
                .then(async response => {
                    setSetServiceSettingsMessageUsersAutoWelcomeMessageLoading(false);

                    return response.data;
                })
                .catch(async error => {
                    console.error('setServiceSettingsMessageUsersAutoWelcomeMessageReturningSubscriber', error);

                    setSetServiceSettingsMessageUsersAutoWelcomeMessageLoading(false);

                    await handleHttpError(error, dialog).then(() => {
                        return Promise.reject(error);
                    });
                });
        } else {
            return {
                autoMessageNewSubscriber: null,
                autoMessageReturningSubscriber: null,
                autoMessageNewTrialSubscriber: null,
            };
        }
    };

    const setServiceSettingsMessageUsersAutoWelcomeMessageNewTrialSubscriber = async (
        autoMessageNewTrialSubscriber: OnlyFansSubscriberMessageUsersAutoMessageDetails | null,
        saveTemplateNewTrialSubscriber: boolean,
        saveTemplateNameNewTrialSubscriber: string,
    ): Promise<{
        autoMessageNewSubscriber: OnlyFansSubscriberMessageUsersAutoMessageDetails | null;
        autoMessageReturningSubscriber: OnlyFansSubscriberMessageUsersAutoMessageDetails | null;
        autoMessageNewTrialSubscriber: OnlyFansSubscriberMessageUsersAutoMessageDetails | null;
    }> => {
        if (userContext.jwtToken && settingsContext.apiKey && userContext.user.username) {
            const query: string = `${settingsContext.routes.subscribers.base}${params.userId}/messageUsers.autoSendWelcomeMessageNewTrialSubscribers/settings`;

            setSetServiceSettingsMessageUsersAutoWelcomeMessageLoading(true);

            return axios
                .put(
                    query,
                    {
                        settings: {
                            autoMessageNewTrialSubscriber,
                        },
                        saveTemplate: {
                            enabled: saveTemplateNewTrialSubscriber,
                            name: saveTemplateNameNewTrialSubscriber,
                        },
                    },
                    {
                        headers: {
                            Authorization: userContext.jwtToken,
                            apiKey: settingsContext.apiKey,
                        },
                    },
                )
                .then(async response => {
                    setSetServiceSettingsMessageUsersAutoWelcomeMessageLoading(false);

                    return response.data;
                })
                .catch(async error => {
                    console.error('setServiceSettingsMessageUsersAutoWelcomeMessageNewTrialSubscriber', error);

                    setSetServiceSettingsMessageUsersAutoWelcomeMessageLoading(false);

                    await handleHttpError(error, dialog).then(() => {
                        return Promise.reject(error);
                    });
                });
        } else {
            return {
                autoMessageNewSubscriber: null,
                autoMessageReturningSubscriber: null,
                autoMessageNewTrialSubscriber: null,
            };
        }
    };

    return {
        ...serviceSettingsMessageUsersAutoWelcomeMessage,
        setServiceSettingsMessageUsersAutoWelcomeMessage,
        setServiceSettingsMessageUsersAutoWelcomeMessageNewSubscriber,
        setServiceSettingsMessageUsersAutoWelcomeMessageReturningSubscriber,
        setServiceSettingsMessageUsersAutoWelcomeMessageNewTrialSubscriber,
        setServiceSettingsMessageUsersAutoWelcomeMessageLoading,
    };
};

export default useSubscriberSettingsMessageUsersAutoWelcomeMessage;
