export type FirebaseConfig = {
    apiKey: string;
    authDomain: string;
    projectId: string;
    storageBucket: string;
    messagingSenderId: string;
    appId: string;
};

const firebaseConfig = {
    apiKey: 'AIzaSyDxORzN-AEMjqFgNnoFC7-sPrO6D2B7AwM',
    authDomain: 'onlystruggles-cd606.firebaseapp.com',
    projectId: 'onlystruggles-cd606',
    storageBucket: 'onlystruggles-cd606.appspot.com',
    messagingSenderId: '320152283076',
    appId: '1:320152283076:web:0d67d99a1366df7ab86691',
};

export default firebaseConfig;
