import React from 'react';
import { Card, CardContent, Divider, FormControlLabel, Grid, Typography } from '@mui/material';
import { Theme } from '@mui/system';
import Editor from '@monaco-editor/react';
import FormikCardSectionHeadingWithSwitch from '../FormikCardSectionHeadingWithSwitch';
import FormikCheckbox from '../FormikCheckBox';
import FormikDateTime from '../FormikDateTime';
import FormikTextField from '../FormikTextField';
import { housekeepingExamples, housekeepingInterface } from '../../pages/admin/AdminSubscriberEdit.Interfaces';

type AdminSubscriberEditHousekeepingProps = {
    formik: any;
    theme: Theme;
    showServiceHousekeeping: boolean;
    setShowServiceHousekeeping: React.Dispatch<React.SetStateAction<boolean>>;
};

const AdminSubscriberHousekeepingUsers = (props: AdminSubscriberEditHousekeepingProps) => {
    const { formik, theme, showServiceHousekeeping, setShowServiceHousekeeping } = props;

    return (
        <Card variant="elevation" sx={{ marginBottom: theme.spacing(4) }}>
            <CardContent>
                <FormikCardSectionHeadingWithSwitch
                    formik={formik}
                    theme={theme}
                    fieldName="housekeeping"
                    switchParam={showServiceHousekeeping}
                    setSwitchParam={setShowServiceHousekeeping}
                    label="Housekeeping"
                />
                <Grid container spacing={2} sx={{ display: showServiceHousekeeping ? 'flex' : 'none' }} columns={12}>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6">Housekeeping</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <FormikCheckbox formik={formik} fieldName="housekeeping.active" label="Active" />
                    </Grid>
                    <Grid item xs={12}>
                        <FormikDateTime formik={formik} fieldName="housekeeping.paidDate" label="Date Paid" />
                    </Grid>
                    <Grid item xs={12}>
                        <FormikCheckbox
                            formik={formik}
                            fieldName="housekeeping.addPrefixToUserDisplayName"
                            label="Add Prefix to User's Display Name (Global)"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormikTextField formik={formik} fieldName="userDisplayNamePrefix" label="User's Display Name Prefix (Global)" />
                    </Grid>
                    <Grid item xs={6}>
                        <FormControlLabel
                            label="Lists"
                            labelPlacement="top"
                            control={
                                <Editor
                                    height="550px"
                                    width="100%"
                                    language="json"
                                    defaultValue={
                                        'housekeeping' in formik.values && formik.values.housekeeping
                                            ? JSON.stringify(formik.values.housekeeping.lists)
                                            : ''
                                    }
                                    options={{
                                        autoIndent: 'full',
                                        formatOnPaste: true,
                                        minimap: { enabled: false },
                                    }}
                                    theme="vs-dark"
                                    onMount={(editor, context) => {
                                        setTimeout(() => {
                                            editor.getAction('editor.action.formatDocument').run();
                                        }, 1000);
                                    }}
                                    onChange={(value, ev) => {
                                        if (value) {
                                            try {
                                                const json = JSON.parse(value);

                                                formik.setFieldValue('housekeeping.lists', json);
                                            } catch (error) {
                                                // JSON isn't valid
                                            }
                                        }
                                    }}
                                />
                            }
                            sx={{ margin: 0, alignItems: 'flex-start', width: '100%' }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormControlLabel
                            label="Interface"
                            labelPlacement="top"
                            control={
                                <Editor
                                    height="250px"
                                    defaultLanguage="typescript"
                                    options={{
                                        autoIndent: 'full',
                                        formatOnPaste: true,
                                        minimap: { enabled: false },
                                    }}
                                    value={housekeepingInterface}
                                    theme="vs-dark"
                                    onMount={(editor, context) => {
                                        setTimeout(() => {
                                            editor.getAction('editor.action.formatDocument').run();
                                        }, 1000);
                                    }}
                                />
                            }
                            sx={{ margin: 0, alignItems: 'flex-start', width: '100%' }}
                        />
                        <br />
                        <br />
                        <FormControlLabel
                            label="Examples"
                            labelPlacement="top"
                            control={
                                <Editor
                                    height="250px"
                                    defaultLanguage="typescript"
                                    options={{
                                        autoIndent: 'full',
                                        formatOnPaste: true,
                                        minimap: { enabled: false },
                                    }}
                                    value={housekeepingExamples}
                                    theme="vs-dark"
                                    onMount={(editor, context) => {
                                        setTimeout(() => {
                                            editor.getAction('editor.action.formatDocument').run();
                                        }, 1000);
                                    }}
                                />
                            }
                            sx={{ margin: 0, alignItems: 'flex-start', width: '100%' }}
                        />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default AdminSubscriberHousekeepingUsers;
