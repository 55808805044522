import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Card, Grid, Tooltip } from '@mui/material';
import { Theme } from '@mui/system';
import { useState } from 'react';
import { copyTextToClipboard } from '../../utils/common';

const LinkWithCopyToClipboardBox = (props: { link: string; theme: Theme }) => {
    const { link, theme } = props;
    const [copied, setCopied] = useState<boolean>(false);

    return (
        <Tooltip title="Copy Link to Clipboard">
            <Card
                sx={{
                    border: '1px solid',
                    borderRadius: theme.shape.borderRadius,
                    cursor: 'pointer',
                    background: copied ? theme.palette.success.light : theme.palette.grey['200'],
                    ':hover': { backgroundColor: copied ? theme.palette.success.light : theme.palette.info.light },
                    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
                }}
                onClick={async () => {
                    await copyTextToClipboard(link);
                    setCopied(true);
                }}
            >
                <Grid container spacing={1} flexGrow={0} alignItems="center">
                    <Grid item xs="auto">
                        <ContentCopyIcon fontSize="small" />
                    </Grid>
                    <Grid
                        item
                        xs
                        sx={{
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                        }}
                    >
                        {link}
                    </Grid>
                </Grid>
            </Card>
        </Tooltip>
    );
};

export default LinkWithCopyToClipboardBox;
