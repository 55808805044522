import { Chip, Skeleton } from '@mui/material';
import dinero from 'dinero.js';
import useSextforceMetricsRecentEarnings from '../../../../hooks/useSextforceMetricsRecentEarnings';
import { d2f } from '../../../../utils/common';

const SextforceMetricsRecentEarchingsChip = ({
    metricId,
    metricType,
    amountType,
}: {
    metricId: string;
    metricType: string;
    amountType: 'gross' | 'net';
}) => {
    const { data: recentEarnings, isLoading: isLoadingRecentEarnings } = useSextforceMetricsRecentEarnings(metricId, metricType);

    const amount = recentEarnings ? Math.trunc(d2f(recentEarnings.total) * (amountType === 'gross' ? 1 : 0.8) * 100) : 0;

    return isLoadingRecentEarnings ? (
        <Skeleton width={'100%'} />
    ) : (
        <Chip
            label={dinero({
                amount: amount,
                currency: 'USD',
            }).toFormat()}
            color={amount > 0 ? 'success' : 'default'}
            sx={{
                width: '100%',
                fontFamily: 'monospace',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                justifyContent: 'flex-end',
            }}
        />
    );
};

export default SextforceMetricsRecentEarchingsChip;
