import { Grid, Stack, Theme, Typography, useTheme } from '@mui/material';
import moment from 'moment';
import { ScheduledTask } from '../../../../hooks/useMessageUsersScheduledSessions';
import { stripHtml, targetUsersName } from '../../../../utils/common';
import MessageUsersCurrentSessionExclude from '../sendMessage/MessageUsersCurrentSessionExclude';

const MessageUsersScheduledListRowDetails = ({
    row,
    timezone,
    smallScreen,
}: {
    row: ScheduledTask;
    timezone: string;
    smallScreen: boolean;
}) => {
    const theme: Theme = useTheme();

    return (
        <Grid
            container
            spacing={1}
            sx={{
                marginTop: smallScreen ? 0.5 : 1,
                marginBottom: smallScreen ? 0.5 : 1,
                marginLeft: smallScreen ? 0 : 1,
                marginRight: smallScreen ? 0 : 1,
            }}
        >
            <Grid item xs={12}>
                <Typography variant="h6">Message Details</Typography>
            </Grid>
            <Grid item xs={12}>
                <strong>Message</strong>
            </Grid>
            <Grid item xs={12} sx={{ marginLeft: theme.spacing(2) }}>
                {row.payload && 'message' in row.payload && row.payload.message
                    ? stripHtml(row.payload.message)
                          .split('\n')
                          .map((i: string, key: number) => {
                              return <div key={key}>{i}</div>;
                          })
                    : ''}
            </Grid>
            <Grid item xs={6}>
                <strong>%USERNAME% Replacement</strong>
            </Grid>
            <Grid item xs={6}>
                {row.payload.usernameReplacement || <div style={{ color: theme.palette.grey[500] }}>None</div>}
            </Grid>
            <Grid item xs={6}>
                <strong>To</strong>
            </Grid>
            <Grid item xs={6}>
                {targetUsersName(row.payload.usersType, {})}
            </Grid>
            {row.payload.usersType === 'lists' && (
                <>
                    <Grid item xs={6}>
                        <strong>List Name</strong>
                    </Grid>
                    <Grid item xs={6}>
                        {row.payload && row.payload.listName}
                    </Grid>
                </>
            )}
            {row.payload && row.payload.exclude && (
                <>
                    <Grid item xs={12}>
                        <strong>Excludes</strong>
                    </Grid>
                    <Grid item xs={12} sx={{ marginLeft: theme.spacing(2), marginRight: theme.spacing(2) }}>
                        <MessageUsersCurrentSessionExclude exclude={row.payload.exclude} theme={theme} />
                    </Grid>
                </>
            )}
            <Grid item xs={6}>
                <strong>Attachment</strong>
            </Grid>
            <Grid item xs={6}>
                {row.payload && row.payload.mediaFiles && row.payload.mediaFiles.length > 0
                    ? `${row.payload.mediaFiles.length} photo/video`
                    : 'None'}
            </Grid>
            {row.payload.taggedCreators && row.payload.taggedCreators.length > 0 && (
                <>
                    <Grid item xs={6}>
                        <strong>Tagged Creators</strong>
                    </Grid>
                    <Grid item xs={6}>
                        <Stack direction="column">
                            {row.payload.taggedCreators.map((taggedCreator: { id: number; name: string }, index: number) => {
                                return <div key={index}>{taggedCreator.name}</div>;
                            })}
                        </Stack>
                    </Grid>
                </>
            )}
            {row.payload.taggedReleaseForms && row.payload.taggedReleaseForms.length > 0 && (
                <>
                    <Grid item xs={6}>
                        <strong>Tagged Release Forms</strong>
                    </Grid>
                    <Grid item xs={6}>
                        <Stack direction="column">
                            {row.payload.taggedReleaseForms.map((taggedReleaseForm: { id: number; name: string }, index: number) => {
                                return <div key={index}>{taggedReleaseForm.name}</div>;
                            })}
                        </Stack>
                    </Grid>
                </>
            )}
            <Grid item xs={6}>
                <strong>Auto Unsend</strong>
            </Grid>
            <Grid item xs={6}>
                {row.payload.autoUnsendTimerPeriod && row.payload.autoUnsendTimerPeriodAmount ? (
                    `${row.payload.autoUnsendTimerPeriodAmount} ${row.payload.autoUnsendTimerPeriod} after session finished`
                ) : (
                    <div style={{ color: theme.palette.grey[500] }}>None</div>
                )}
            </Grid>
            <Grid item xs={6}>
                <strong>Repeat Every</strong>
            </Grid>
            <Grid item xs={6}>
                {row.repeatEveryAmount && row.repeatEveryUnit ? `${row.repeatEveryAmount} ${row.repeatEveryUnit}` : 'Does not repeat'}
            </Grid>
            {row.repeatEveryAmount && row.repeatEveryUnit && (
                <>
                    <Grid item xs={6}>
                        <strong>Following Scheduled For</strong>
                    </Grid>
                    <Grid item xs={6}>
                        {!row.endDate ||
                        (row.endDate &&
                            moment(row.scheduleDateTime)
                                .add(row.repeatEveryAmount, row.repeatEveryUnit as moment.DurationInputArg2)
                                .isBefore(row.endDate))
                            ? moment(row.scheduleDateTime)
                                  .add(row.repeatEveryAmount, row.repeatEveryUnit as moment.DurationInputArg2)
                                  .tz(timezone)
                                  .format('L LT')
                            : 'Will end after this session'}
                    </Grid>
                    <Grid item xs={6}>
                        <strong>Repeat Until</strong>
                    </Grid>
                    <Grid item xs={6}>
                        {row.endDate && !moment(row.endDate).isSame('1970-01-01T00:00:00.000Z')
                            ? moment(row.endDate).tz(timezone).format('L LT')
                            : 'Indefinitely'}
                    </Grid>
                </>
            )}
        </Grid>
    );
};

export default MessageUsersScheduledListRowDetails;
