import { faFileCsv, faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, CardContent, Grid, IconButton, Tooltip as MuiTooltip, Skeleton, Stack, Typography, useMediaQuery } from '@mui/material';
import { Theme } from '@mui/system';
import dinero from 'dinero.js';
import { parse as parseCsv } from 'json2csv';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Bar, CartesianGrid, ComposedChart, Legend, Line, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import * as XLSX from 'xlsx';
import { d2f } from '../../../../../../utils/common';

/**
 * Transforms the transactions data rows from the server to a format ready for export to CSV or Excel
 * @param rows Transactions rows
 * @param timezone Timezone string to convert the transaction time to
 * @returns Transformed data for export
 */
const transformTransactionsForExport = (rows: any[], timezone: string) => {
    const headers = [
        {
            label: `Subscribed At ${timezone}`,
            value: 'subscribedAt',
        },
        {
            label: 'Fans',
            value: 'count',
        },
    ];

    const filteredData = rows.filter(row => row._id && row._id.interval && moment(row._id.interval).isAfter(row._id.createdAt));

    // Iterate from the earliest date to the latest date and fill in missing dates with 0 counts
    const earliestDate = moment(filteredData[0]._id.interval);
    const latestDate = moment();

    const data: { subscribedAt: string; count: number }[] = [];

    for (let date = earliestDate.clone(); date.isBefore(latestDate); date.add(1, 'days')) {
        const foundData = filteredData.find(d => moment(d._id.interval).isSame(date, 'day'));

        if (!foundData) {
            data.push({
                subscribedAt: date.format('L'),
                count: 0,
            });
        } else {
            data.push({
                subscribedAt: moment(foundData._id.interval).tz(timezone).format('L'),
                count: foundData.count,
            });
        }
    }

    // Add up all the data that did not have a date or was not in the filtered data
    let unknownCount = 0;

    for (const row of rows) {
        if (!row._id.interval || !moment(row._id.interval).isAfter(row._id.createdAt)) {
            unknownCount += row.count;
        }
    }

    if (unknownCount > 0) {
        data.push({
            subscribedAt: 'Unknown',
            count: unknownCount,
        });
    }

    return { headers, rows: data };
};

const generateData = (data: any[], showEarningsWithSubscriptions: boolean, showEarningsAsGross: boolean) => {
    if (data.length === 0) {
        return [];
    }

    const dateThreshold = moment(data[0]._id.createdAt).startOf('day');

    const result = {
        data: data
            .slice()
            .filter(d => dateThreshold.isSameOrBefore(d._id.interval))
            .map(d => {
                let total: number = 0;

                if (showEarningsWithSubscriptions) {
                    total = d.total ? (typeof d.total === 'number' ? d.total : d2f(d.total)) : 0;
                } else {
                    total = d.totalWithoutSubscriptions
                        ? typeof d.totalWithoutSubscriptions === 'number'
                            ? d.totalWithoutSubscriptions
                            : d2f(d.totalWithoutSubscriptions)
                        : 0;
                }

                if (!showEarningsAsGross) {
                    total = total * 0.8;
                }

                return {
                    time: new Date(d._id.interval).valueOf(),
                    count: d.count,
                    total,
                };
            }),
        // min: Math.round(Math.min(...data.map((d: any) => d.count))),
        // max: Math.round(Math.max(...data.map((d: any) => d.count))),
        // average: Math.round(data.reduce((a: any, b: any) => a + b.count, 0) / data.length),
    };

    return result;
};

const CustomTooltip = ({ active, payload, showEarningsAsGross, showEarningsWithSubscriptions, theme }: any) => {
    if (active && payload && payload.length) {
        return (
            <div>
                <Grid
                    container
                    flexGrow={0}
                    alignItems="center"
                    spacing={1}
                    sx={{
                        backgroundColor: '#fff',
                        borderRadius: 4,
                        border: '1px solid #999',
                        maxWidth: 280,
                    }}
                >
                    <Grid item xs={12}>
                        <span style={{ fontWeight: 'bold' }}>{moment(payload[0].payload.time).format('L')}</span>
                    </Grid>
                    {payload.map((item: any, index: number) => (
                        <Grid item xs={12} key={index}>
                            {item.dataKey === 'count' && (
                                <Grid container flexGrow={1} alignItems="center">
                                    <Grid item xs={4} sx={{ color: theme.palette.primary.main }}>
                                        Subscribers:
                                    </Grid>
                                    <Grid item xs={8} textAlign={'right'} sx={{ color: theme.palette.primary.main, paddingRight: '8px' }}>
                                        {item.value && typeof item.value === 'number' ? item.value.toLocaleString() : 0}
                                    </Grid>
                                </Grid>
                            )}

                            {item.dataKey === 'total' && (
                                <Grid container flexGrow={1} alignItems="center">
                                    <Grid item xs={4} sx={{ color: theme.palette.secondary.main }}>
                                        Total Sales:
                                    </Grid>
                                    <Grid
                                        item
                                        xs={8}
                                        textAlign={'right'}
                                        sx={{ color: theme.palette.primary.main, paddingRight: '8px', fontFamily: 'monospace' }}
                                    >
                                        {dinero({
                                            amount: item.value ? Math.trunc(item.value * 100) : 0,
                                            currency: 'USD',
                                        }).toFormat()}{' '}
                                        <small>({showEarningsAsGross ? 'gross' : 'net'})</small>
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                    ))}
                </Grid>
            </div>
        );
    }

    return null;
};

type Props = {
    metricId: string;
    promoCampaignSubscribedAtVsSales: any;
    promoCampaignSubscribedAtVsSalesLoading: boolean;
    showEarnings: boolean;
    showEarningsWithSubscriptions: boolean;
    showEarningsAsGross: boolean;
    timezone: string;
    theme: Theme;
};

const SextforceMetricsCampaignSubscribedAtGraph = (props: Props) => {
    const {
        metricId,
        promoCampaignSubscribedAtVsSales,
        promoCampaignSubscribedAtVsSalesLoading,
        showEarnings,
        showEarningsAsGross,
        showEarningsWithSubscriptions,
        timezone,
        theme,
    } = props;
    const [dataMain, setDataMain] = useState<any>({ data: [], min: 0, max: 0, average: 0 });
    const largeScreen = useMediaQuery(theme.breakpoints.up('sm'));

    useEffect(() => {
        if (!promoCampaignSubscribedAtVsSalesLoading && promoCampaignSubscribedAtVsSales && promoCampaignSubscribedAtVsSales.length > 0) {
            setDataMain(generateData(promoCampaignSubscribedAtVsSales, showEarningsWithSubscriptions, showEarningsAsGross));
        } else {
            setDataMain([]);
        }
    }, [promoCampaignSubscribedAtVsSales, promoCampaignSubscribedAtVsSalesLoading, showEarningsAsGross, showEarningsWithSubscriptions]);

    // Convert report to CSV format and start file download
    const handleDownloadReportCsv = () => {
        const transformedData: any = transformTransactionsForExport(promoCampaignSubscribedAtVsSales, timezone);
        // Format JSON data to CSV
        const csv = parseCsv(transformedData.rows, {
            fields: transformedData.headers,
            transforms: [],
        });
        // Convert CSV to Blob
        const blob: Blob = new Blob([csv], { type: 'text/csv' });
        // Create a descriptive filename
        const filename: string = `subscribersByDateCount_${metricId}_${moment().tz(timezone, true).format('YYYY-MM-DD')}.csv`;
        // Create blob link to download
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        // Append to html link element page
        document.body.appendChild(link);
        // Start download
        link.click();
        // Clean up and remove the link
        link.parentNode && link.parentNode.removeChild(link);
    };

    // Convert to Excel format and start file download
    const handleDownloadReportExcel = () => {
        const transformedData: any = transformTransactionsForExport(promoCampaignSubscribedAtVsSales, timezone);
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet([]);
        XLSX.utils.sheet_add_aoa(worksheet, [transformedData.headers.map((header: any) => header.label)]);
        XLSX.utils.sheet_add_json(worksheet, transformedData.rows, {
            origin: 'A2',
            skipHeader: true,
            header: transformedData.headers.map((header: any) => header.value),
        });
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Transactions');
        // Create a descriptive filename
        const filename: string = `subscribersByDateCount_${metricId}_${moment().tz(timezone, true).format('YYYY-MM-DD')}.xlsx`;
        XLSX.writeFile(workbook, filename);
    };

    const Chart = (chartData: any) => (
        <ResponsiveContainer width="100%" height="100%">
            <ComposedChart
                data={chartData && chartData.data ? chartData.data : []}
                margin={{
                    top: 0,
                    right: largeScreen ? 10 : 0,
                    left: largeScreen ? -10 : -20,
                    bottom: 60,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" stroke={theme.palette.grey[400]} />
                <XAxis
                    dataKey="time"
                    name="Time"
                    tickFormatter={value => (value && value !== 'auto' ? moment(value).tz(timezone, true).format('L') : '')}
                    domain={chartData && chartData.data && chartData.data.length > 0 ? ['dataMin', 'dataMax'] : ['auto', 'auto']}
                    interval={'preserveStartEnd'}
                    tick={{ fontSize: 13 }}
                    angle={-90}
                    dy={35}
                    dx={-5}
                />
                <YAxis
                    yAxisId="count"
                    dataKey={'count'}
                    scale={'linear'}
                    name="Subscribers Count"
                    domain={[0, (dataMax: number) => Math.abs(dataMax * 1.1)]}
                    tickFormatter={(value: number) => value.toLocaleString()}
                    tick={{ fill: theme.palette.primary.main, fontSize: 13 }}
                />
                <Bar yAxisId="count" type="monotone" dataKey="count" barSize={20} maxBarSize={20} fill={theme.palette.primary.main} />
                {showEarnings && (
                    <YAxis
                        yAxisId="total"
                        dataKey={'total'}
                        scale={'linear'}
                        name="Total Sales"
                        domain={['dataMin', (dataMax: number) => Math.abs(dataMax * 1.1)]}
                        tickFormatter={(value: number) =>
                            dinero({
                                amount: value && value !== Infinity ? Math.trunc(value * 100) : 0,
                                currency: 'USD',
                            }).toFormat()
                        }
                        orientation="right"
                        tick={{ fill: theme.palette.secondary.main, fontSize: 13 }}
                    />
                )}
                {showEarnings && (
                    <Line
                        yAxisId="total"
                        type="linear"
                        dataKey="total"
                        stroke={theme.palette.secondary.main}
                        activeDot={{ r: 0 }}
                        dot={{ r: 0 }}
                    />
                )}
                <Tooltip
                    content={
                        <CustomTooltip
                            payload={chartData.data}
                            showEarningsAsGross={showEarningsAsGross}
                            showEarningsWithSubscriptions={showEarningsWithSubscriptions}
                            theme={theme}
                        />
                    }
                />
                <Legend
                    verticalAlign="top"
                    height={36}
                    formatter={(value: string) => (
                        <span style={{ fontSize: 13 }}>{value === 'count' ? 'Subscribers Count' : 'Total Sales'}</span>
                    )}
                />
            </ComposedChart>
        </ResponsiveContainer>
    );

    return (
        <Card sx={{ width: '100%', marginBottom: theme.spacing(2) }}>
            <CardContent>
                <Stack direction="column" spacing={1} sx={{ marginBottom: theme.spacing(1) }}>
                    <Stack direction="row" spacing={1} alignItems={'center'} justifyContent={'space-between'}>
                        <Typography variant="h5" sx={{ marginBottom: theme.spacing(1) }}>
                            Subscribtions Count{showEarnings && ' vs. Sales by Date'}
                        </Typography>
                        <Stack direction="row" spacing={1} alignItems={'center'}>
                            <MuiTooltip title="Download as CSV">
                                <span>
                                    <IconButton
                                        disabled={promoCampaignSubscribedAtVsSalesLoading}
                                        onClick={() => {
                                            handleDownloadReportCsv();
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faFileCsv} size="2x" color={theme.palette.info.main} />
                                    </IconButton>
                                </span>
                            </MuiTooltip>
                            <MuiTooltip title="Download as Excel">
                                <span>
                                    <IconButton
                                        disabled={promoCampaignSubscribedAtVsSalesLoading}
                                        onClick={() => {
                                            handleDownloadReportExcel();
                                        }}
                                    >
                                        <FontAwesomeIcon icon={faFileExcel} size="2x" color={theme.palette.info.main} />
                                    </IconButton>
                                </span>
                            </MuiTooltip>
                        </Stack>
                    </Stack>
                    <Typography variant="body1" sx={{ marginBottom: theme.spacing(1) }}>
                        The following graph shows how many fans subscribed via this campaign on each date
                        {showEarnings && ', and how much money these fans have spent since.'}
                    </Typography>
                    {showEarnings && (
                        <Typography variant="body1">
                            Use this graph to identify when the spenders subscribed vs. when the freeloaders came along.
                        </Typography>
                    )}
                </Stack>

                <Grid container flexGrow={1} spacing={0} sx={{ padding: 0 }}>
                    <Grid item xs={12} sx={{ height: { xs: 300, sm: 500 } }}>
                        {promoCampaignSubscribedAtVsSalesLoading ? <Skeleton /> : Chart(dataMain)}
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default SextforceMetricsCampaignSubscribedAtGraph;
