import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { OnlyFansSubscriberHostessAutoReplyFreeloaders } from '../../../../types/hostessAutoReplyFreeloaders';

/**
 * Object with key-value pairs of MomentJS periods
 */
export const momentPeriods: any = { minutes: 'Minutes', hours: 'Hours', days: 'Days', weeks: 'Weeks', months: 'Months', years: 'Years' };

const FormFollowingThresholdPeriodSelect = ({
    followingThresholdEnabled,
    formData,
    setFormData,
}: {
    followingThresholdEnabled: boolean;
    formData: OnlyFansSubscriberHostessAutoReplyFreeloaders;
    setFormData: (formData: OnlyFansSubscriberHostessAutoReplyFreeloaders) => void;
}) => {
    return (
        <FormControl fullWidth>
            <InputLabel id="period">Period</InputLabel>
            <Select
                labelId="condition"
                disabled={!followingThresholdEnabled}
                value={formData && formData.followingThresholdPeriod ? formData.followingThresholdPeriod : 'months'}
                onChange={e => {
                    setFormData({
                        ...formData,
                        followingThresholdPeriod: e.target.value === 'none' ? undefined : e.target.value,
                    });
                }}
                fullWidth
                size="medium"
                label="Period"
            >
                {Object.keys(momentPeriods).map(item => (
                    <MenuItem value={item} key={item}>
                        {momentPeriods[item]}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export default FormFollowingThresholdPeriodSelect;
