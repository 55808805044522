import {
    Alert,
    Box,
    Checkbox,
    CircularProgress,
    Grid,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Paper,
    Typography,
} from '@mui/material';
import { Theme } from '@mui/system';
import { OnlyFansLists } from '../../../../types/onlyFansTypes';

type Props = {
    listsLoading: boolean;
    lists: OnlyFansLists.List[] | void | undefined;
    excludedLists: { id: number | OnlyFansLists.Type; name: string }[];
    setExcludedLists: (excludedLists: { id: number | OnlyFansLists.Type; name: string }[]) => void;
    theme: Theme;
};

const MessageUsersFormExcludeList = (props: Props) => {
    const { theme, listsLoading, lists, excludedLists, setExcludedLists } = props;

    const handleListItemClicked = (id: number) => {
        const alreadyChecked = excludedLists.find(item => item.id === id);

        if (alreadyChecked) {
            const newCheckedLists = excludedLists.filter(item => item.id !== id);

            setExcludedLists(newCheckedLists);
        } else if (lists) {
            const listName: string | undefined = lists.find(item => item.id === id)?.name;

            if (listName) {
                setExcludedLists([...excludedLists, { id, name: listName }]);
            }
        }
    };

    return (
        <>
            <Grid container spacing={1} flexGrow={0} alignItems="center" justifyContent="center">
                <Grid item xs={12}>
                    <Grid
                        container
                        spacing={1}
                        flexGrow={0}
                        alignItems="center"
                        justifyContent="center"
                        sx={{ marginBottom: theme.spacing(2) }}
                    >
                        <Grid item xs={12}>
                            {listsLoading ? (
                                <Box textAlign={'center'}>
                                    <Typography variant="caption">
                                        Retrieving your Lists...
                                        <br />
                                        <br />
                                        This might take a while depending on how many Lists you have and OnlyFans servers speed 🤷‍♂️
                                        <br />
                                        <br />
                                        <CircularProgress size={24} />
                                    </Typography>
                                </Box>
                            ) : (
                                <>
                                    {lists ? (
                                        <Paper variant="outlined" sx={{ maxHeight: 200, overflow: 'auto' }}>
                                            <List dense sx={{ width: '100%' }}>
                                                {lists.map((list: any) => (
                                                    <ListItem
                                                        disablePadding
                                                        key={list.id}
                                                        sx={{
                                                            backgroundColor:
                                                                excludedLists && excludedLists.find(item => item.id === list.id)
                                                                    ? theme.palette.grey[200]
                                                                    : '',
                                                        }}
                                                    >
                                                        <ListItemButton
                                                            role={undefined}
                                                            dense
                                                            onClick={() => {
                                                                handleListItemClicked(list.id);
                                                            }}
                                                        >
                                                            <ListItemIcon>
                                                                <Checkbox
                                                                    tabIndex={-1}
                                                                    disableRipple
                                                                    checked={
                                                                        excludedLists && excludedLists.find(item => item.id === list.id)
                                                                            ? true
                                                                            : false
                                                                    }
                                                                />
                                                            </ListItemIcon>
                                                            <ListItemText
                                                                primary={list.name}
                                                                secondary={`Users: ${(list.usersCount as number).toLocaleString()}`}
                                                            />
                                                        </ListItemButton>
                                                    </ListItem>
                                                ))}
                                            </List>
                                        </Paper>
                                    ) : (
                                        <Alert severity="error">Could not load lists!</Alert>
                                    )}
                                </>
                            )}
                        </Grid>

                        {!listsLoading && (
                            <Grid item xs={12}>
                                <Alert severity="info">All the users in the selected lists will NOT receive the message</Alert>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default MessageUsersFormExcludeList;
