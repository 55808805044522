import { useDialog } from 'muibox';
import { useContext } from 'react';
import { useQuery } from 'react-query';
import { SettingsContext } from '../store/SettingsContext';
import { UserContext } from '../store/UserContext';
import { handleHttpError } from '../utils/common';
import useAxios from './useAxios';

export type SextforceTransactionsReportParams = {
    timezone: string;
    dateFrom: Date | null;
    dateTo: Date | null;
    isFreeRange: boolean;
    filter: string;
    agent: string;
};

const useSextforceTransactionsSummary = (
    userId: string | undefined,
    reportParams: SextforceTransactionsReportParams | undefined,
    reportSort: any[] | undefined,
) => {
    const userContext = useContext(UserContext);
    const settingsContext = useContext(SettingsContext);
    const dialog = useDialog();
    const axios = useAxios();

    // Fetch Report
    const fetchTransactionsSummary = async (): Promise<any> => {
        if (
            !userContext.jwtToken ||
            !settingsContext.apiKey ||
            !userId ||
            !reportParams ||
            !reportParams.dateFrom ||
            !reportParams.dateTo ||
            !reportSort
        ) {
            return [];
        }

        const query: string = `${settingsContext.routes.transactions.base}${userId}/list/summary?${new URLSearchParams({
            startDate: reportParams.dateFrom ? reportParams.dateFrom.toISOString() : '',
            endDate: reportParams.dateTo ? reportParams.dateTo.toISOString() : '',
            filter: reportParams.filter,
            agent: reportParams.agent,
            ...(reportSort && reportSort.length > 0 && { sortField: reportSort[0].field, sort: reportSort[0].sort }),
        })}`;

        const data = await axios
            .get(query)
            .then(async response => response.data)
            .catch(error => {
                console.error(error);
                handleHttpError(error, dialog);

                return [];
            });

        return data;
    };

    const query = useQuery(['transactionsSummary', userId, reportParams, reportSort], () => fetchTransactionsSummary(), {
        refetchOnWindowFocus: false,
        staleTime: 0,
        enabled:
            userContext.jwtToken &&
            settingsContext.apiKey &&
            userId &&
            reportParams &&
            reportParams.dateFrom &&
            reportParams.dateTo &&
            reportSort
                ? true
                : false,
    });

    return { ...query, fetchTransactions: fetchTransactionsSummary };
};

export default useSextforceTransactionsSummary;
