import { Grid, Typography } from '@mui/material';
import StyledCard from '../../../../components/common/StyledCard';

const AutoReplyToExpiredIntroCard = () => {
    return (
        <StyledCard>
            <Grid item xs={12}>
                <Typography variant="h6" sx={{ mb: 1 }}>
                    Auto Reply to Expired Fans
                </Typography>
                <Typography variant="body1" sx={{ mb: 1 }}>
                    Free up your sexters time by setting up automatic replies to expired fans to nudge them towards resubscribing and
                    spending some money.
                </Typography>
                <Typography variant="body1" sx={{ mb: 1 }}>
                    You can setup as many replies as you like. For each message the fan sends, they will receive a the next reply in the
                    script. On the last reply, the system will take the action you specified - do nothing, mute, restrict, or block the
                    user.
                </Typography>
                <Typography variant="body1" sx={{ mb: 1 }}>
                    Setup exactly whom you want the system to target and whom to skip.
                </Typography>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    This features requires a current Sextforce subscription.
                </Typography>
            </Grid>
        </StyledCard>
    );
};

export default AutoReplyToExpiredIntroCard;
