import moment from 'moment';
import { useDialog } from 'muibox';
import { useContext } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { SettingsContext } from '../store/SettingsContext';
import { UserContext } from '../store/UserContext';
import { OnlyFansSubscriberMessageUsersAutoMessageDetails } from '../types/messageUsersAutoSendWelcomeMessage';
import { handleHttpError } from '../utils/common';
import useAxios from './useAxios';

export interface OnlyFansMetricsSettings {
    platform?: string;
    goalSpent?: object | number;
    goalSpentNotificationSent?: boolean;
    goalSubs?: number;
    goalSubsNotificationSent?: boolean;
}

export type OnlyFansMetricType =
    | 'trackingLink'
    | 'promoCampaign'
    | 'trialLinkPromo'
    | 'trialLinkTrial'
    | 'countersAll'
    | 'earningsAll'
    | 'performerTop'
    | 'statisticsFansSubscriptionsOverview'
    | 'statisticsProfileVisitorsOverview';

export interface OnlyFansMetricsRoi {
    // totalEarnings: number;
    // totalSubscribers: number;
    amount: number | object | null;
    percentage: number | null;
}

export interface OnlyFansMetricsWelcomeMessage {
    _id?: string;
    subscriberId: string;
    metricId: string;
    messageDetails: OnlyFansSubscriberMessageUsersAutoMessageDetails;
    createdAt?: Date;
    updatedAt?: Date;
}

export interface OnlyFansMetrics {
    _id: string;
    subscriberId: string;
    type: OnlyFansMetricType;
    foreignId?: number;
    associatedUsername?: string;
    associatedSubscriberId?: string;
    settings?: OnlyFansMetricsSettings;
    reachedEndOfUsersList?: boolean;
    reachedEndOfUsersListCount?: number;
    notes?: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    payload?: any;
    roi?: OnlyFansMetricsRoi;
    welcomeMessage?: OnlyFansMetricsWelcomeMessage;
}

export interface OnlyFansMetricsWithSubscriber extends OnlyFansMetrics {
    subscriber: {
        _id: string;
        username: string;
    };
}

export interface OnlyFansMetricsCampaignsPaginated {
    data: OnlyFansMetrics[];
    metadata: { total: number; earningsTotal: number | object; subscribersTotal: number };
}

const useSextforceMetricsCampaignsOverview = (
    subscriber: any,
    timezone: string,
    date: Date | null,
    search: string,
    page: number,
    pageSize: number,
    sort: string,
    includeInactive: boolean,
) => {
    const userContext = useContext(UserContext);
    const settingsContext = useContext(SettingsContext);
    const params = useParams();
    const dialog = useDialog();
    const axios = useAxios();

    // Fetch Paginated Report
    const fetchCampaignsOverview = async (): Promise<OnlyFansMetricsCampaignsPaginated> => {
        if (userContext.jwtToken && settingsContext.apiKey && 'userId' in params && params.userId && subscriber) {
            const query: string = `${settingsContext.routes.metrics.base}${params.userId}/campaignsOverviewPaginated?${new URLSearchParams({
                includeInactive: includeInactive ? 'true' : 'false',
                startDate: moment(date).tz(timezone, true).startOf('day').format(),
                endDate: moment(date).tz(timezone, true).endOf('day').format(),
                search,
                page: page.toString(),
                pageSize: pageSize.toString(),
                sort,
            })}`;

            const data = await axios
                .get(query)
                .then(response => response.data)
                .catch(error => {
                    console.error(error);
                    handleHttpError(error, dialog);

                    return { data: [], metadata: { total: 0, earningsTotal: 0, subscribersTotal: 0 } };
                });

            return data;
        }

        return { data: [], metadata: { total: 0, earningsTotal: 0, subscribersTotal: 0 } };
    };

    return useQuery(
        ['metricsCampaignsOverviewPaginated', timezone, includeInactive, subscriber, search, page, pageSize, sort],
        () => fetchCampaignsOverview(),
        {
            refetchOnWindowFocus: false,
            staleTime: 60 * 60 * 1000,
            enabled: subscriber ? true : false,
        },
    );
};

export default useSextforceMetricsCampaignsOverview;
