import { useDialog } from 'muibox';
import { useContext } from 'react';
import { useQuery } from 'react-query';
import { SettingsContext } from '../store/SettingsContext';
import { UserContext } from '../store/UserContext';
import { handleHttpError } from '../utils/common';
import useAxios from './useAxios';

export type SextforceTransactionsReportParams = {
    timezone: string;
    dateFrom: Date | null;
    dateTo: Date | null;
    isFreeRange: boolean;
    filter: string;
    agent: string;
};

const useSextforceTransactions = (
    userId: string | undefined,
    reportParams: SextforceTransactionsReportParams | undefined,
    reportSort: any[] | undefined,
    offset: number,
    limit: number,
    exportMode: boolean = false,
) => {
    const userContext = useContext(UserContext);
    const settingsContext = useContext(SettingsContext);
    const dialog = useDialog();
    const axios = useAxios();

    // Fetch Report
    const fetchTransactions = async (
        userId: string | undefined,
        reportParams: SextforceTransactionsReportParams | undefined,
        reportSort: any[] | undefined,
        offset: number,
        limit: number,
        exportMode: boolean = false,
    ): Promise<any> => {
        if (
            !userContext.jwtToken ||
            !settingsContext.apiKey ||
            !userId ||
            !reportParams ||
            !reportParams.dateFrom ||
            !reportParams.dateTo ||
            !reportSort
        ) {
            return [];
        }

        const query: string = `${settingsContext.routes.transactions.base}${userId}/list?${new URLSearchParams({
            startDate: reportParams.dateFrom ? reportParams.dateFrom.toISOString() : '',
            endDate: reportParams.dateTo ? reportParams.dateTo.toISOString() : '',
            filter: reportParams.filter,
            agent: reportParams.agent,
            offset: offset.toString(),
            limit: limit.toString(),
            ...(reportSort && reportSort.length > 0 && { sortField: reportSort[0].field, sort: reportSort[0].sort }),
            ...(exportMode && { exportMode: 'true' }),
        })}`;

        const data = await axios
            .get(query)
            .then(async response => response.data)
            .catch(error => {
                console.error(error);
                handleHttpError(error, dialog);

                return [];
            });

        return data;
    };

    const query = useQuery(
        ['transactions', userId, reportParams, reportSort, offset, limit],
        () => fetchTransactions(userId, reportParams, reportSort, offset, limit, exportMode),
        {
            refetchOnWindowFocus: false,
            staleTime: 0,
            enabled:
                userContext.jwtToken &&
                settingsContext.apiKey &&
                userId &&
                reportParams &&
                reportParams.dateFrom &&
                reportParams.dateTo &&
                reportSort
                    ? true
                    : false,
        },
    );

    return { ...query, fetchTransactions };
};

export default useSextforceTransactions;
