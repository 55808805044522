import React from 'react';
import { Card, CardContent, Divider, Grid, Typography } from '@mui/material';
import { Theme } from '@mui/system';
import FormikCardSectionHeadingWithSwitch from '../FormikCardSectionHeadingWithSwitch';
import FormikCheckbox from '../FormikCheckBox';
import FormikDateTime from '../FormikDateTime';

type AdminSubscriberEditBlockUsersProps = {
    formik: any;
    theme: Theme;
    showServiceBlockUsers: boolean;
    setShowServiceBlockUsers: React.Dispatch<React.SetStateAction<boolean>>;
};

const AdminSubscriberEditBlockUsers = (props: AdminSubscriberEditBlockUsersProps) => {
    const { formik, theme, showServiceBlockUsers, setShowServiceBlockUsers } = props;

    return (
        <Card variant="elevation" sx={{ marginBottom: theme.spacing(4) }}>
            <CardContent>
                <FormikCardSectionHeadingWithSwitch
                    formik={formik}
                    theme={theme}
                    fieldName="blockUsers"
                    switchParam={showServiceBlockUsers}
                    setSwitchParam={setShowServiceBlockUsers}
                    label="Block Users"
                />
                <Grid container spacing={2} sx={{ display: showServiceBlockUsers ? 'flex' : 'none' }}>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6">Block Users</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <FormikCheckbox formik={formik} fieldName="blockUsers.active" label="Active" />
                    </Grid>
                    <Grid item xs={12}>
                        <FormikDateTime formik={formik} fieldName="blockUsers.paidDate" label="Date Paid" />
                    </Grid>
                    <Grid item xs={12}>
                        <FormikCheckbox formik={formik} fieldName="blockUsers.targetListName" label="Target List Name" />
                    </Grid>
                    <Grid item xs={12}>
                        <FormikCheckbox formik={formik} fieldName="blockUsers.blockUsersLimit" label="Block Users Limit" />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default AdminSubscriberEditBlockUsers;
