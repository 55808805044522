import AddLinkIcon from '@mui/icons-material/AddLink';
import ArchiveIcon from '@mui/icons-material/Archive';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import LinkIcon from '@mui/icons-material/Link';
import MoreTimeIcon from '@mui/icons-material/MoreTime';
import PostAddIcon from '@mui/icons-material/PostAdd';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import PushPinIcon from '@mui/icons-material/PushPin';
import QuickreplyIcon from '@mui/icons-material/Quickreply';
import ShareIcon from '@mui/icons-material/Share';
import ShieldIcon from '@mui/icons-material/Shield';
import { Collapse, IconButton, Stack, TableCell, TableRow, Theme, Tooltip, Typography } from '@mui/material';
import moment from 'moment';
import { useDialog } from 'muibox';
import { useContext, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import useAxios from '../../../../hooks/useAxios';
import { SextforceAutoRepost, SextforceAutoRepostWithLatestRepostLog } from '../../../../hooks/useSextforceAutoRepost';
import { SettingsContext } from '../../../../store/SettingsContext';
import { UserContext } from '../../../../store/UserContext';
import { handleHttpError } from '../../../../utils/common';
import NA from '../../../common/NA';
import SextforceAutoRepostChangeNextAutoRepostDateDialog from './SextforceAutoRepostChangeNextAutoRepostDateDialog';
import SextforceAutoRepostEditRepostDialog from './SextforceAutoRepostEditRepostDialog';
import SextforceAutoRepostShareDialog from './SextforceAutoRepostShareDialog';
import SextforceAutoRepostTableRepostsHistoryRowLogs from './SextforceAutoRepostTableRepostsHistoryRowLogs';

const momentPeriods: { [key: string]: string } = {
    minutes: 'Minutes',
    hours: 'Hours',
    days: 'Days',
};

type Props = {
    subscriber: any;
    autoRepost: SextforceAutoRepostWithLatestRepostLog;
    theme: Theme;
    refetchAutoReposts: () => void;
};

const SextforceAutoRepostTableRepostsHistoryRow = (props: Props) => {
    const { subscriber, autoRepost, theme, refetchAutoReposts } = props;
    const userContext = useContext(UserContext);
    const settingsContext = useContext(SettingsContext);
    const params = useParams();
    const dialog = useDialog();
    const axios = useAxios();
    const [open, setOpen] = useState(false);
    const [shareDialogOpen, setShareDialogOpen] = useState(false);
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [changeNextRepostDateDialogOpen, setChangeNextRepostDateDialogOpen] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const [isReactivatingAutoRepost, setIsReactivatingAutoRepost] = useState(false);

    const deleteAutoRepost = async (autoRepostId: string) => {
        if (!userContext.jwtToken || !settingsContext.apiKey) {
            return;
        }

        setIsDeleting(true);

        const data = await axios
            .delete(`${settingsContext.routes.autoRepost.base}${params.userId}/${autoRepostId}`)
            .then(async response => {
                return response.data as SextforceAutoRepost;
            })
            .catch(error => {
                setIsDeleting(false);
                handleHttpError(error, dialog);

                return null;
            });

        return data;
    };

    const handleDeleteAutoRepost = (autoRepostId: string) => {
        deleteAutoRepost(autoRepostId).then(data => {
            if (!data) {
                return;
            }

            dialog
                .alert({
                    title: 'Auto Repost',
                    message:
                        'Auto Repost has been successfully scheduled for deactivation. In a minute or two it will be deactivated and will send the final notification and summary on Telegram.',
                    ok: {
                        text: 'OK',
                        variant: 'contained',
                        color: 'primary',
                    },
                })
                .then(() => {
                    setIsDeleting(false);
                    refetchAutoReposts();
                });
        });
    };

    const handleActivateAutoRepost = async (autoRepostId: string) => {
        if (!userContext.jwtToken || !settingsContext.apiKey) {
            return;
        }

        setIsReactivatingAutoRepost(true);

        const data = await axios
            .put(`${settingsContext.routes.autoRepost.base}${params.userId}/${autoRepostId}/active`, {
                active: true,
            })
            .then(async response => {
                return response.data as SextforceAutoRepost;
            })
            .catch(error => {
                setIsReactivatingAutoRepost(false);
                handleHttpError(error, dialog);

                return null;
            });

        return data;
    };

    const postUrlForAutoReply = () => {
        if (autoRepost.type === 'link') {
            return autoRepost.originalPostUrl;
        }

        if (autoRepost.type === 'post') {
            if (!autoRepost.latestRepostLog) {
                return autoRepost.originalPostUrl;
            }

            const lastPostId = autoRepost.latestRepostLog.postId;
            const newPostUrl = `https://onlyfans.com/${lastPostId}/${subscriber.username}`;

            return newPostUrl;
        }
    };

    return (
        <>
            <TableRow
                sx={{
                    '& > *': { borderBottom: 'unset' },
                    cursor: 'pointer',
                    '&:hover': { backgroundColor: theme.palette.action.hover },
                }}
                onClick={e => {
                    setOpen(!open);
                }}
            >
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell>
                    <Stack direction="column" spacing={0.5}>
                        <Stack direction="row" spacing={0.5} alignItems="center">
                            <LinkIcon fontSize="small" />
                            {autoRepost.actionPreviousPost === 'delete' && autoRepost.type === 'post' && autoRepost.repostCount > 0 ? (
                                <Typography variant="inherit" color="textPrimary" noWrap>
                                    {autoRepost.name}
                                </Typography>
                            ) : (
                                <Link
                                    to={autoRepost.originalPostUrl}
                                    target="_blank"
                                    rel="noreferrer"
                                    onClick={e => {
                                        e.stopPropagation();
                                    }}
                                >
                                    <Typography variant="inherit" color="textPrimary" noWrap>
                                        {autoRepost.name}
                                    </Typography>
                                </Link>
                            )}
                        </Stack>
                        {autoRepost.description && (
                            <Typography variant="inherit" color="textSecondary">
                                {autoRepost.description}
                            </Typography>
                        )}
                        <Stack direction="row" spacing={0.5} alignItems="center">
                            <Typography variant="inherit" color="textSecondary" noWrap>
                                {autoRepost.type === 'link' && (
                                    <Tooltip title="Post Link to Original">
                                        <AddLinkIcon color="action" sx={{ fontSize: '12pt' }} />
                                    </Tooltip>
                                )}
                                {autoRepost.type === 'post' && (
                                    <Tooltip title="Post a Brand New Post">
                                        <PostAddIcon color="action" sx={{ fontSize: '12pt' }} />
                                    </Tooltip>
                                )}
                            </Typography>

                            <Typography variant="inherit" color="textSecondary" noWrap>
                                |
                            </Typography>

                            <Typography variant="inherit" color="textSecondary" noWrap>
                                <Tooltip title={autoRepost.pinPost ? 'Pin New Post' : `Don't Pin New Post`}>
                                    <PushPinIcon
                                        color={autoRepost.pinPost ? 'action' : 'disabled'}
                                        sx={{
                                            fontSize: '12pt',
                                            transform: 'rotate(270deg)',
                                        }}
                                    />
                                </Tooltip>
                            </Typography>

                            <Typography variant="inherit" color="textSecondary" noWrap>
                                |
                            </Typography>

                            <Typography variant="inherit" color="textSecondary" noWrap>
                                {autoRepost.actionPreviousPost === 'none' && (
                                    <Tooltip title="Leave Previous Post">
                                        <ArchiveIcon color="disabled" sx={{ fontSize: '12pt' }} />
                                    </Tooltip>
                                )}
                                {autoRepost.actionPreviousPost === 'delete' && (
                                    <Tooltip title="Delete Previous Post">
                                        <DeleteIcon color="action" sx={{ fontSize: '12pt' }} />
                                    </Tooltip>
                                )}
                                {autoRepost.actionPreviousPost === 'archive' && (
                                    <Tooltip title="Archive Previous Post">
                                        <ArchiveIcon
                                            color="action"
                                            sx={{
                                                fontSize: '12pt',
                                            }}
                                        />
                                    </Tooltip>
                                )}
                                {autoRepost.actionPreviousPost === 'privateArchive' && (
                                    <Tooltip title="Private Archive Previous Post">
                                        <ShieldIcon color="action" sx={{ fontSize: '12pt' }} />
                                    </Tooltip>
                                )}
                            </Typography>
                        </Stack>
                    </Stack>
                </TableCell>
                <TableCell align="center">
                    <Stack direction="row" spacing={0.5} alignItems="center">
                        <Stack direction="column" spacing={0.5}>
                            <Typography variant="inherit">
                                {autoRepost.repostEveryAmount} {momentPeriods[autoRepost.repostEveryUnit]}
                            </Typography>
                            <Typography variant="subtitle2" color="textSecondary">
                                {autoRepost.endDate ? moment(autoRepost.endDate).format('L') : 'Indefinitely'}
                            </Typography>
                        </Stack>
                    </Stack>
                </TableCell>
                <TableCell align="center">
                    <Stack direction="column" spacing={0.5}>
                        <Typography variant="inherit" color="textSecondary" noWrap>
                            {autoRepost.latestRepostDate ? moment(autoRepost.latestRepostDate).format('L hh:mma') : <NA />}
                        </Typography>
                        {autoRepost.nextRepostDate ? (
                            <Typography variant="inherit" color="textSecondary" noWrap>
                                {moment(autoRepost.nextRepostDate).format('L hh:mma')}
                            </Typography>
                        ) : (
                            <NA />
                        )}
                    </Stack>
                </TableCell>
                <TableCell align="center">{autoRepost.repostCount.toLocaleString()}</TableCell>
                <TableCell align="center">
                    <Stack direction="row" spacing={0.5} justifyContent={'center'} alignItems={'center'}>
                        {autoRepost.active && (
                            <>
                                <Tooltip title="Change Next Auto Repost Date & Time">
                                    <IconButton
                                        color="success"
                                        size="small"
                                        disabled={!autoRepost.active}
                                        onClick={e => {
                                            e.stopPropagation();
                                            setChangeNextRepostDateDialogOpen(true);
                                        }}
                                    >
                                        <MoreTimeIcon sx={{ fontSize: '12pt' }} />
                                    </IconButton>
                                </Tooltip>

                                <Tooltip title="Edit Auto Repost Settings">
                                    <IconButton
                                        size="small"
                                        disabled={!autoRepost.active}
                                        onClick={e => {
                                            e.stopPropagation();
                                            setEditDialogOpen(true);
                                        }}
                                    >
                                        <EditIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>

                                <Tooltip title="Setup Auto-Reply to Campaign Tip for this post and reposts">
                                    <IconButton
                                        color="info"
                                        size="small"
                                        disabled={!autoRepost.active}
                                        component={Link}
                                        to={`/subscribers/${
                                            params.userId
                                        }/services/hostess/autoCampaignTipReply?campaignUrl=${postUrlForAutoReply()}`}
                                    >
                                        <QuickreplyIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>

                                <Tooltip title="Share Auto Repost Updates & Stats">
                                    <IconButton
                                        color="secondary"
                                        size="small"
                                        disabled={!autoRepost.active}
                                        onClick={e => {
                                            e.stopPropagation();
                                            setShareDialogOpen(true);
                                        }}
                                    >
                                        <ShareIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>

                                <Tooltip title="Delete Auto Repost">
                                    <IconButton
                                        color="error"
                                        size="small"
                                        disabled={!autoRepost.active || isDeleting}
                                        onClick={e => {
                                            e.stopPropagation();

                                            dialog
                                                .confirm({
                                                    title: 'Delete Auto Repost',
                                                    message: 'Are you sure you want to delete this auto repost?',
                                                    cancel: {
                                                        text: 'Cancel',
                                                        variant: 'outlined',
                                                    },
                                                    ok: {
                                                        text: 'Delete',
                                                        variant: 'contained',
                                                        color: 'error',
                                                    },
                                                })
                                                .then(() => {
                                                    if (autoRepost._id) {
                                                        handleDeleteAutoRepost(autoRepost._id);
                                                    }
                                                })
                                                .catch(() => {});
                                        }}
                                    >
                                        <DeleteIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                            </>
                        )}
                        {!autoRepost.active && (
                            <Tooltip title="Reactivate Auto Repost">
                                <IconButton
                                    color="success"
                                    size="small"
                                    onClick={e => {
                                        e.stopPropagation();

                                        dialog
                                            .confirm({
                                                title: 'Reactivate Auto Repost',
                                                message: 'Are you sure you want to reactivate this auto repost?',
                                                cancel: {
                                                    text: 'Cancel',
                                                    variant: 'outlined',
                                                },
                                                ok: {
                                                    text: 'Activate',
                                                    variant: 'contained',
                                                    color: 'success',
                                                },
                                            })
                                            .then(async () => {
                                                if (autoRepost._id) {
                                                    handleActivateAutoRepost(autoRepost._id).then(() => {
                                                        refetchAutoReposts();
                                                    });
                                                }
                                            })
                                            .catch(() => {});
                                    }}
                                >
                                    <PowerSettingsNewIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        )}
                    </Stack>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <SextforceAutoRepostTableRepostsHistoryRowLogs subscriber={subscriber} autoRepost={autoRepost} theme={theme} />
                    </Collapse>
                </TableCell>
            </TableRow>
            <SextforceAutoRepostShareDialog
                subscriber={subscriber}
                autoRepost={autoRepost}
                open={shareDialogOpen}
                onClose={() => setShareDialogOpen(false)}
                theme={theme}
            />
            <SextforceAutoRepostEditRepostDialog
                subscriber={subscriber}
                autoRepost={autoRepost}
                open={editDialogOpen}
                onClose={() => setEditDialogOpen(false)}
                refetchAutoReposts={refetchAutoReposts}
            />
            <SextforceAutoRepostChangeNextAutoRepostDateDialog
                subscriber={subscriber}
                autoRepost={autoRepost}
                open={changeNextRepostDateDialogOpen}
                onClose={() => setChangeNextRepostDateDialogOpen(false)}
                refetchAutoReposts={refetchAutoReposts}
            />
        </>
    );
};

export default SextforceAutoRepostTableRepostsHistoryRow;
