import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import TelegramIcon from '@mui/icons-material/Telegram';
import { Box, IconButton, Stack, TableCell, TableRow, Theme, Tooltip, Typography, useTheme } from '@mui/material';
import moment from 'moment';
import { useState } from 'react';
import QRCode from 'react-qr-code';
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters } from 'react-query';
import { Link } from 'react-router-dom';
import {
    defaultSextforceOnlyFansTelegramNotificationOptions,
    SextforceOnlyFansTelegramNotification,
    SextforceOnlyFansTelegramNotificationOptions,
} from '../../../../../hooks/useSextforceOnlyFansTelegramNotifications';
import TextFieldClipboard from '../../../../common/TextFieldClipboard';
import SextforceOnlyFansTelegramNotificationOptionsEditForm from './SextforceOnlyFansTelegramNotificationOptionsEditForm';

const SextforceOnlyFansTelegramNotificationRow = ({
    sextforceOnlyFansTelegramNotification,
    handleSetSextforceOnlyFansTelegramNotificationActive,
    isSetSextforceOnlyFansTelegramNotificationActive,
    handleDeleteSextforceOnlyFansTelegramNotification,
    isDeletingSextforceOnlyFansTelegramNotification,
    isLoading,
    handleSave,
    refetchSextforceOnlyFansTelegramNotifications,
}: {
    sextforceOnlyFansTelegramNotification: SextforceOnlyFansTelegramNotification;
    handleSetSextforceOnlyFansTelegramNotificationActive: (notificationId: string, active: boolean) => Promise<void>;
    isSetSextforceOnlyFansTelegramNotificationActive: boolean;
    handleDeleteSextforceOnlyFansTelegramNotification: (notificationId: string) => Promise<void>;
    isDeletingSextforceOnlyFansTelegramNotification: boolean;
    isLoading: boolean;
    handleSave: (
        notificationId: string,
        options: SextforceOnlyFansTelegramNotificationOptions,
    ) => Promise<SextforceOnlyFansTelegramNotification>;
    refetchSextforceOnlyFansTelegramNotifications: <TPageData>(
        options?: (RefetchOptions & RefetchQueryFilters<TPageData>) | undefined,
    ) => Promise<QueryObserverResult<SextforceOnlyFansTelegramNotification[], unknown>>;
}) => {
    const theme: Theme = useTheme();
    const [showEditForm, setShowEditForm] = useState<boolean>(false);

    const [options, setOptions] = useState<SextforceOnlyFansTelegramNotificationOptions>(
        sextforceOnlyFansTelegramNotification.options || defaultSextforceOnlyFansTelegramNotificationOptions,
    );

    // Helpers
    const assembleInviteUrl = (inviteCode: string) => {
        return `https://t.me/OnlyStrugglesOFBot?start=${inviteCode}`;
    };

    return (
        <>
            <TableRow key={sextforceOnlyFansTelegramNotification._id}>
                <TableCell>
                    {sextforceOnlyFansTelegramNotification.telegramChatId ? (
                        <Stack direction={'row'} spacing={1} alignItems={'center'}>
                            <Tooltip title="Go to Telegram Chat">
                                <span>
                                    <IconButton
                                        component={Link}
                                        to={`https://t.me/${sextforceOnlyFansTelegramNotification.telegramUsername}`}
                                        target="_blank"
                                    >
                                        <TelegramIcon />
                                    </IconButton>
                                </span>
                            </Tooltip>
                            <div>{sextforceOnlyFansTelegramNotification.telegramUsername || 'Unknown'}</div>
                        </Stack>
                    ) : (
                        <Typography fontFamily="inherit" fontSize="inherit" color="text.secondary">
                            Not Registered
                        </Typography>
                    )}
                </TableCell>
                <TableCell align="center">
                    {sextforceOnlyFansTelegramNotification.registerDate
                        ? moment(sextforceOnlyFansTelegramNotification.registerDate).format('L LT')
                        : ' '}
                </TableCell>
                <TableCell align="center">
                    <Stack direction="row" spacing={1}>
                        <Tooltip
                            title={sextforceOnlyFansTelegramNotification.active ? 'Deactivate Notifications' : 'Activate Notifications'}
                        >
                            <span>
                                <IconButton
                                    color={sextforceOnlyFansTelegramNotification.active ? 'success' : 'default'}
                                    disabled={isSetSextforceOnlyFansTelegramNotificationActive}
                                    onClick={() => {
                                        handleSetSextforceOnlyFansTelegramNotificationActive(
                                            sextforceOnlyFansTelegramNotification._id,
                                            !sextforceOnlyFansTelegramNotification.active,
                                        );
                                    }}
                                >
                                    <PowerSettingsNewIcon />
                                </IconButton>
                            </span>
                        </Tooltip>
                        <Tooltip title="Edit Notification Settings">
                            <span>
                                <IconButton
                                    color="primary"
                                    disabled={!sextforceOnlyFansTelegramNotification.telegramChatId}
                                    size="small"
                                    onClick={() => {
                                        setShowEditForm(!showEditForm);
                                    }}
                                >
                                    <EditIcon />
                                </IconButton>
                            </span>
                        </Tooltip>
                        <Tooltip title="Delete Telegram Account">
                            <span>
                                <IconButton
                                    color="error"
                                    size="small"
                                    disabled={isDeletingSextforceOnlyFansTelegramNotification}
                                    onClick={() => {
                                        handleDeleteSextforceOnlyFansTelegramNotification(sextforceOnlyFansTelegramNotification._id);
                                    }}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </span>
                        </Tooltip>
                    </Stack>
                </TableCell>
            </TableRow>
            {!sextforceOnlyFansTelegramNotification.telegramChatId && (
                <TableRow key={`${sextforceOnlyFansTelegramNotification._id}-chatId`}>
                    <TableCell colSpan={3} align="center" sx={{ p: 2 }}>
                        <Stack direction="column" spacing={2} justifyContent="center">
                            <Typography variant="body1" color="error">
                                Invitation code not registered yet
                            </Typography>
                            <Typography variant="body1" color="primary">
                                Scan the QR code below to or copy the link to start the bot
                            </Typography>
                            <div>
                                <QRCode
                                    size={256}
                                    style={{
                                        height: 'auto',
                                        maxWidth: '256px',
                                        width: '256px',
                                    }}
                                    value={assembleInviteUrl(sextforceOnlyFansTelegramNotification.inviteCode)}
                                    viewBox={`0 0 256 256`}
                                />
                            </div>
                            <TextFieldClipboard
                                label={'Bot Link'}
                                value={assembleInviteUrl(sextforceOnlyFansTelegramNotification.inviteCode)}
                            />
                        </Stack>
                    </TableCell>
                </TableRow>
            )}
            {showEditForm && (
                <TableRow key={`${sextforceOnlyFansTelegramNotification._id}-edit`} sx={{ backgroundColor: theme.palette.grey[50] }}>
                    <TableCell colSpan={3}>
                        <Box sx={{ pl: 2, pr: 2, pt: 1, pb: 1 }}>
                            <SextforceOnlyFansTelegramNotificationOptionsEditForm
                                options={options}
                                setOptions={setOptions}
                                isLoading={isLoading}
                                handleSave={newOptions => {
                                    return handleSave(sextforceOnlyFansTelegramNotification._id, newOptions);
                                }}
                                refetchSextforceOnlyFansTelegramNotifications={refetchSextforceOnlyFansTelegramNotifications}
                                setOpen={setShowEditForm}
                            />
                        </Box>
                    </TableCell>
                </TableRow>
            )}
        </>
    );
};

export default SextforceOnlyFansTelegramNotificationRow;
