import axios from 'axios';
import { useDialog } from 'muibox';
import { useContext } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { SettingsContext } from '../store/SettingsContext';
import { UserContext } from '../store/UserContext';
import { handleHttpError } from '../utils/common';

const useSextforceOverview = () => {
    const userContext = useContext(UserContext);
    const settingsContext = useContext(SettingsContext);
    const dialog = useDialog();
    const params = useParams();

    // Fetch Report
    const fetchOverview = async (): Promise<any> => {
        const query: string = `${settingsContext.routes.sextforce.base}${params.userId}/overview`;

        return axios
            .get(query, {
                headers: {
                    Authorization: userContext.jwtToken,
                    apiKey: settingsContext.apiKey,
                },
            })
            .then(async response => response.data)
            .catch(error => {
                console.error(error);
                handleHttpError(error, dialog);
            });
    };

    return useQuery(
        ['sextforceOverview', userContext.jwtToken, params.userId],
        () => {
            return fetchOverview();
        },
        {
            refetchOnWindowFocus: false,
            staleTime: 1000 * 60 * 5,
            enabled: userContext.jwtToken && settingsContext.apiKey && params.userId ? true : false,
        },
    );
};

export default useSextforceOverview;
