import { Dialog, DialogProps, styled } from '@mui/material';

const DialogAdaptive = styled((props: DialogProps) => <Dialog {...props} />)(({ theme }) => ({
    // is small screen?
    [theme.breakpoints.down('sm')]: {
        // container
        '& .MuiDialog-paper': {
            marginLeft: 2,
            marginRight: 2,
            maxWidth: 'calc(100% - 16px)',
            width: 'calc(100% - 16px)',
        },
    },
}));

export default DialogAdaptive;
