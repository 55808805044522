import { MenuItem, Select, Skeleton, TextField, Theme, useTheme } from '@mui/material';
import { useParams } from 'react-router-dom';
import { SubscribersAdmin } from '../../../../hooks/useSubscribers';

type Props = {
    subscribers: any[] | SubscribersAdmin | undefined;
    subscribersLoading: boolean;
    crossReferenceSubscriberId: string;
    setCrossReferenceSubscriberId: (value: string) => void;
};

const SextforceMetricsCrossReferenceEarningsSubscriberSelector = (props: Props) => {
    const { subscribers, subscribersLoading, crossReferenceSubscriberId, setCrossReferenceSubscriberId } = props;
    const theme: Theme = useTheme();
    const params = useParams();

    return subscribersLoading ? (
        <Skeleton />
    ) : (
        <>
            {subscribers && Array.isArray(subscribers) ? (
                <Select
                    fullWidth
                    value={crossReferenceSubscriberId}
                    onChange={e => setCrossReferenceSubscriberId(e.target.value)}
                    size="small"
                    sx={{
                        marginTop: '4px',
                    }}
                >
                    <MenuItem value="none" disabled>
                        <span style={{ color: theme.palette.grey[800] }}>
                            <em>Select Account</em>
                        </span>
                    </MenuItem>
                    <MenuItem value="all">All Accounts</MenuItem>
                    <MenuItem value={undefined} disabled>
                        ----------
                    </MenuItem>
                    {subscribers
                        .filter(subscriber => subscriber._id !== params.userId)
                        .splice(0)
                        .sort((a, b) => a.username.localeCompare(b.username))
                        .map((subscriber: any) => (
                            <MenuItem key={subscriber._id} value={subscriber._id}>
                                {subscriber.username}
                            </MenuItem>
                        ))}
                </Select>
            ) : (
                <TextField
                    fullWidth
                    label="Cross-Pollination Account"
                    size="small"
                    placeholder="Enter a subscriber ID to cross-reference with"
                    value={crossReferenceSubscriberId === 'none' ? '' : crossReferenceSubscriberId}
                    onChange={e => {
                        // delay the search by 1 second
                        // setTimeout(() => {
                        setCrossReferenceSubscriberId(e.target.value);
                        // }, 1000);
                    }}
                />
            )}
        </>
    );
};

export default SextforceMetricsCrossReferenceEarningsSubscriberSelector;
