import { Theme } from '@mui/system';
import { Controller, Control } from 'react-hook-form';
import { FormControl, FormHelperText, InputLabel, MenuItem, Select, SxProps } from '@mui/material';

type FormSelectProps = {
    control: Control<any>;
    errors: any;
    name: string;
    label: string;
    required?: boolean;
    value?: any;
    size?: 'small' | 'medium';
    fullWidth?: boolean;
    options: any[];
    sx?: SxProps<Theme> | undefined;
};

const FormSelect = (props: FormSelectProps) => {
    const { control, errors, name, label, required, value, size, fullWidth, options, sx } = props;

    return (
        <Controller
            name={name}
            control={control}
            rules={{ required }}
            defaultValue={value}
            render={({ field }) => (
                <>
                    <FormControl error={errors[name] ? true : false} fullWidth={fullWidth}>
                        <InputLabel id={`label-${name}`}>{label}</InputLabel>
                        <Select {...field} labelId={`label-${name}`} label={label} defaultValue={value} size={size} sx={sx}>
                            {options.map(option => (
                                <MenuItem value={option.value} key={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </Select>
                        <FormHelperText>{errors[name] && errors[name]?.message}</FormHelperText>
                    </FormControl>
                </>
            )}
        />
    );
};

export default FormSelect;
