import { useDialog } from 'muibox';
import { useContext } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { SettingsContext } from '../store/SettingsContext';
import { UserContext } from '../store/UserContext';
import { handleHttpError } from '../utils/common';
import useAxios from './useAxios';

export interface SextforceAutoCampaignTipReplyLog {
    _id?: string;
    autoCampaignReplyId: string;
    notificationId: number;
    notificationDate: Date;
    tipAmount: number;
    user: {
        id: number;
        username: string;
        name: string;
    };
    messageId?: number;
}

const useSextforceAutoCampaignTipReplyLogs = (autoCampaignTipReplyId: string | undefined, amount: number | null) => {
    const userContext = useContext(UserContext);
    const settingsContext = useContext(SettingsContext);
    const axios = useAxios();
    const dialog = useDialog();
    const params = useParams();

    // Fetch online users count vs. total sales report
    const fetchData = async (
        autoCampaignTipReplyId: string | undefined,
        amount: number | null,
    ): Promise<SextforceAutoCampaignTipReplyLog[]> => {
        if (!userContext.jwtToken || !settingsContext.apiKey || !autoCampaignTipReplyId) {
            return [];
        }

        const url: string = `${settingsContext.routes.autoCampaignTipReply.base}${params.userId}/${autoCampaignTipReplyId}/logs?amount=${amount}`;

        const data = await axios
            .get(url)
            .then(async response => response.data as SextforceAutoCampaignTipReplyLog[])
            .catch(error => {
                handleHttpError(error, dialog);

                return [];
            });

        return data;
    };

    const autoCampaignTipReplyLogs = useQuery(
        ['autoCampaignTipReplyLogs', params.userId, userContext.jwtToken, autoCampaignTipReplyId, amount],
        () => {
            return fetchData(autoCampaignTipReplyId, amount);
        },
        {
            refetchOnWindowFocus: false,
            enabled: userContext.jwtToken && params.userId && settingsContext.apiKey && autoCampaignTipReplyId ? true : false,
        },
    );

    return {
        ...autoCampaignTipReplyLogs,
    };
};

export default useSextforceAutoCampaignTipReplyLogs;
