import { Button, Dialog, DialogActions, DialogContent, DialogContentText, FormControl, Grid, TextField, Theme } from '@mui/material';
import { useState } from 'react';
import { SextforceLiveStream } from '../../../../../hooks/useSextforceLiveStreamAddTippersToList';
import StyledCardTitleBar from '../../../../common/StyledCardTitleBar';

type Props = {
    subscriber: any;
    liveStream: SextforceLiveStream;
    open: boolean;
    onClose: () => void;
    handleSubmit: (title: string | undefined, description: string | undefined) => void;
    theme: Theme;
};

const SextforceLiveStreamAddTippersToListTippersEditDialog = (props: Props) => {
    const { subscriber, liveStream, open, onClose, handleSubmit, theme } = props;

    const [title, setTitle] = useState<string | undefined>(liveStream.title);
    const [description, setDescription] = useState<string | undefined>(liveStream.description);

    const handleClose = (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => {
        if (reason && reason === 'backdropClick') {
            return;
        }

        onClose();
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth disableEscapeKeyDown scroll="paper">
            <StyledCardTitleBar title={`Live Stream ${liveStream.streamId}`} theme={theme} />
            <DialogContent>
                <DialogContentText>Please enter a meaningful title and description for the live stream.</DialogContentText>
                <Grid container alignItems={'center'} justifyContent={'center'} spacing={1} sx={{ mt: 1 }}>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <TextField
                                id="title"
                                name="title"
                                label="Title"
                                defaultValue={liveStream.title}
                                variant="outlined"
                                onChange={event => {
                                    setTitle(event.target.value);
                                }}
                                value={title}
                                fullWidth
                                autoFocus
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <TextField
                                id="description"
                                name="description"
                                label="Description"
                                value={description}
                                defaultValue={liveStream.description}
                                variant="outlined"
                                onChange={event => {
                                    setDescription(event.target.value);
                                }}
                                fullWidth
                                multiline
                                rows={4}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions
                sx={{
                    backgroundColor: theme.palette.grey[100],
                }}
            >
                <Button
                    color="error"
                    size="medium"
                    onClick={() => {
                        handleClose({}, 'escapeKeyDown');
                    }}
                >
                    Close
                </Button>
                <Button
                    color="primary"
                    size="medium"
                    type="submit"
                    onClick={event => {
                        event.preventDefault();
                        handleSubmit(title, description);
                    }}
                >
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default SextforceLiveStreamAddTippersToListTippersEditDialog;
