import { ResponsiveBarCanvas } from '@nivo/bar';
import moment from 'moment';
import { useMemo } from 'react';
import { SextforceMetricsCampaignsClaimedTodayOverview } from '../../../../hooks/useSextforceMetricsCampaignsClaimedToday';

const groupedDataToNivoData = (data: SextforceMetricsCampaignsClaimedTodayOverview[]) => {
    const nivoData: { date: string; [item: string]: string | number }[] = [];
    const uniqueNames = Array.from(new Set(data.map(item => item.data.map(dataItem => dataItem.name)).flat()));

    // Add each data item with the name and metric ID and the count
    data.forEach(item => {
        const obj: any = {
            date: moment(item.date).format('L'),
        };

        item.data.forEach(dataItem => {
            obj[dataItem.name] = dataItem.count;
            obj[`${dataItem.name} metricId`] = dataItem.metricId; // Add metricId to the object
        });

        nivoData.push(obj);
    });

    return { nivoData, uniqueNames };
};

type Props = {
    data: SextforceMetricsCampaignsClaimedTodayOverview[];
    orientation: 'horizontal' | 'vertical';
    hoverMetricId: string;
    setHoveredMetricId: (metricId: string) => void;
    handleMetricClick: (metricId: string) => void;
};

const SextforceMetricsClaimsTodayOverviewStackedBarChartApex = (props: Props) => {
    const { data, orientation, handleMetricClick } = props;

    // Convert the transformedData object into an array of objects
    const { nivoData, uniqueNames } = useMemo(() => groupedDataToNivoData(data), [data]);

    return (
        <ResponsiveBarCanvas
            data={nivoData}
            keys={uniqueNames}
            indexBy="date"
            margin={{ top: 0, right: orientation === 'horizontal' ? 40 : 0, bottom: 50, left: orientation === 'horizontal' ? 70 : 40 }}
            padding={0.3}
            valueScale={{ type: 'linear' }}
            indexScale={{ type: 'band', round: true }}
            colors={{ scheme: 'nivo' }}
            borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legendPosition: 'middle',
                legendOffset: 32,
                format: (value: number) => {
                    if (orientation === 'horizontal') {
                        return value.toLocaleString();
                    }

                    return value;
                },
            }}
            axisLeft={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legendPosition: 'middle',
                legendOffset: -40,
                format: (value: string | number) => {
                    if (orientation === 'horizontal') {
                        return value;
                    }

                    return value.toLocaleString();
                },
            }}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
            valueFormat={(value: number) => {
                return value.toLocaleString();
            }}
            enableTotals={true}
            isInteractive={true}
            legends={[
                {
                    dataFrom: 'keys',
                    anchor: 'bottom',
                    direction: 'row',
                    justify: false,
                    translateX: 0,
                    translateY: 50,
                    itemsSpacing: 2,
                    itemWidth: 150,
                    itemHeight: 20,
                    itemDirection: 'left-to-right',
                    itemOpacity: 0.85,
                    symbolSize: 20,
                    effects: [
                        {
                            on: 'hover',
                            style: {
                                itemOpacity: 1,
                            },
                        },
                    ],
                },
            ]}
            layout={orientation}
            onClick={data => {
                const metricId = data.data[`${data.id} metricId`];

                if (metricId && typeof metricId === 'string') {
                    handleMetricClick(metricId);
                }
            }}
        />
    );
};

export default SextforceMetricsClaimsTodayOverviewStackedBarChartApex;
