import CheckIcon from '@mui/icons-material/Check';
import LoadingButton from '@mui/lab/LoadingButton';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    FormControl,
    Grid,
    LinearProgress,
    Stack,
    TextField,
    Theme,
    Typography,
} from '@mui/material';
import Joi from 'joi';
import { useDialog } from 'muibox';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { SextforceAutoCampaignTipReply, SextforceAutoCampaignTipReplySchema } from '../../../../hooks/useSextforceAutoCampaignTipReply';
import useSubscriberPosts from '../../../../hooks/useSubscriberPosts';
import { SettingsContext } from '../../../../store/SettingsContext';
import { UserContext } from '../../../../store/UserContext';
import { handleHttpError } from '../../../../utils/common';
import StyledCardTitleBar from '../../../common/StyledCardTitleBar';
import SextforceAutoCampaignTipReplyPostPreview from './SextforceAutoCampaignTipReplyPostPreview';

type Props = {
    subscriber: any;
    autoCampaignTipReply: SextforceAutoCampaignTipReply;
    updateAutoCampaignTipReply: (
        id: string,
        autoCampaignReply: SextforceAutoCampaignTipReply,
    ) => Promise<SextforceAutoCampaignTipReply | null>;
    refetchAutoCampaignTipReply: () => void;
    open: boolean;
    onClose: () => void;
    theme: Theme;
};

const SextforceAutoCampaignTipReplyEdit = (props: Props) => {
    const { subscriber, autoCampaignTipReply, updateAutoCampaignTipReply, refetchAutoCampaignTipReply, open, onClose, theme } = props;
    const userContext = useContext(UserContext);
    const settingsContext = useContext(SettingsContext);
    const params = useParams();
    const dialog = useDialog();

    const [formData, setFormData] = useState<SextforceAutoCampaignTipReply>({
        subscriberId: subscriber._id,
        name: autoCampaignTipReply.name,
        description: autoCampaignTipReply.description,
        active: autoCampaignTipReply.active,
        campaignId: autoCampaignTipReply.campaignId,
        campaignUrl: autoCampaignTipReply.campaignUrl,
        postDate: autoCampaignTipReply.postDate,
        postExpireDate: autoCampaignTipReply.postExpireDate,
        payload: autoCampaignTipReply.payload,
        tipsOptions: autoCampaignTipReply.tipsOptions,
        replyCount: autoCampaignTipReply.replyCount,
    });
    const { data: post, isLoading: isPostLoading } = useSubscriberPosts(formData.campaignId);
    const [isPostACampaign, setIsPostACampaign] = useState(false);
    const [isSaving, setIsSaving] = useState<boolean>(false);

    // Validate the form data
    const errors: Joi.ValidationErrorItem[] | null = SextforceAutoCampaignTipReplySchema.validate(formData).error?.details || null;

    // Check if the post is a campaign
    useEffect(() => {
        if (post && !isPostLoading) {
            if (post.fundRaising && post.fundRaising.presets.length > 0) {
                setIsPostACampaign(true);

                // Set the post ID, post date and post expire date
                setFormData({
                    ...formData,
                    postDate: new Date(post.postedAt),
                    postExpireDate: post.expiredAt ? new Date(post.expiredAt) : undefined,
                    tipsOptions: post.fundRaising.presets.map(preset => parseFloat(preset)),
                });
            } else {
                setIsPostACampaign(false);

                // Reset the post ID, post date and post expire date
                setFormData({
                    ...formData,
                    postDate: new Date(),
                    postExpireDate: undefined,
                    tipsOptions: [],
                });
            }
        } else if (!post && !isPostLoading && formData.campaignId !== 0) {
            // Reset the post ID, post date and post expire date
            setFormData({
                ...formData,
                postDate: new Date(),
                postExpireDate: undefined,
                tipsOptions: [],
            });
        }
    }, [post, isPostLoading]);

    const handleSave = () => {
        const updateAutoRepost = async () => {
            if (
                userContext.jwtToken &&
                settingsContext.apiKey &&
                'userId' in params &&
                params.userId &&
                subscriber &&
                autoCampaignTipReply &&
                autoCampaignTipReply._id
            ) {
                setIsSaving(true);

                // Update campaign settings
                const updatedAutoRepost = await updateAutoCampaignTipReply(autoCampaignTipReply._id, formData).catch(error => {
                    console.error(error);
                    setIsSaving(false);
                    handleHttpError(error, dialog);
                });

                if (updatedAutoRepost && updatedAutoRepost._id) {
                    dialog
                        .alert({
                            title: 'Auto Tip Campaign',
                            message: 'Auto Tip Campaign updated successfully.',
                        })
                        .then(() => {
                            onClose();
                            refetchAutoCampaignTipReply();
                        })
                        .catch(() => {});
                } else {
                    dialog
                        .alert({
                            title: 'Auto Tip Campaign',
                            message: 'Auto Tip Campaign could not be updated.',
                        })
                        .then(() => {})
                        .catch(() => {});
                }

                setIsSaving(false);
            }
        };

        updateAutoRepost();
    };

    const handleClose = (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => {
        if (reason && reason === 'backdropClick') {
            return;
        }

        onClose();
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth disableEscapeKeyDown>
            <StyledCardTitleBar title="Edit Auto Tip Campaign" theme={theme} />
            <DialogContent>
                <Grid container alignItems={'center'} justifyContent={'center'} spacing={1}>
                    <Grid item xs={12}>
                        Campaign Post's URL
                        <Typography variant="body2" color={'text.secondary'} marginTop={0.5}>
                            The URL to the campaign post you want to monitor.
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Stack direction={'column'} spacing={0} sx={{ mb: 1 }}>
                            <FormControl fullWidth sx={{ mb: 0 }}>
                                <TextField
                                    id="campaignUrl"
                                    name="campaignUrl"
                                    placeholder="https://onlyfans.com/1234567890/username"
                                    size="medium"
                                    disabled={true}
                                    value={formData.campaignUrl}
                                    onChange={e => setFormData({ ...formData, campaignUrl: e.target.value })}
                                    error={!!errors?.find(error => error.context?.key === 'campaignUrl')}
                                    helperText={errors ? errors.find(error => error.context?.key === 'campaignUrl')?.message : undefined}
                                    InputProps={{
                                        endAdornment: post && isPostACampaign && formData.campaignId !== 0 && (
                                            <CheckIcon color={'success'} />
                                        ),
                                    }}
                                    sx={{ mb: 0 }}
                                />
                            </FormControl>
                            {isPostLoading && <LinearProgress color="primary" />}
                            {post && !isPostACampaign && formData.campaignId !== 0 && (
                                <Typography variant="body2" color={'error'} marginTop={0.5}>
                                    The post is not a campaign
                                </Typography>
                            )}
                            {post && isPostACampaign && formData.campaignId !== 0 && (
                                <Typography variant="body2" color={'success'} marginTop={0.5}>
                                    Post is a campaign
                                </Typography>
                            )}
                        </Stack>
                    </Grid>
                    {post && isPostACampaign && formData.campaignId !== 0 && (
                        <Grid item xs={12}>
                            <SextforceAutoCampaignTipReplyPostPreview post={post} />
                        </Grid>
                    )}

                    <Grid item xs={12}>
                        Name
                        <Typography variant="body2" color={'text.secondary'} marginTop={0.5}>
                            Name of this campaign.
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <TextField
                                id="name"
                                name="name"
                                size="medium"
                                value={formData.name}
                                onChange={e => setFormData({ ...formData, name: e.target.value })}
                                error={!!errors?.find(error => error.context?.key === 'name')}
                                helperText={errors ? errors.find(error => error.context?.key === 'name')?.message : undefined}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        Description
                        <Typography variant="body2" color={'text.secondary'} marginTop={0.5}>
                            Any description or notes you would like to leave for this campaign.
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <TextField
                                id="description"
                                name="description"
                                size="medium"
                                value={formData.description}
                                onChange={e => setFormData({ ...formData, description: e.target.value })}
                                error={!!errors?.find(error => error.context?.key === 'description')}
                                helperText={errors ? errors.find(error => error.context?.key === 'description')?.message : undefined}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    color="error"
                    size="medium"
                    disabled={isSaving}
                    onClick={() => {
                        handleClose({}, 'escapeKeyDown');
                    }}
                >
                    Close
                </Button>
                <LoadingButton
                    color="secondary"
                    variant="contained"
                    size="medium"
                    loading={isSaving}
                    disabled={!!errors || !autoCampaignTipReply._id}
                    onClick={() => {
                        handleSave();
                    }}
                >
                    Save Settings
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};

export default SextforceAutoCampaignTipReplyEdit;
