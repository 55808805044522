import { Alert, AlertColor, AlertTitle, Collapse, IconButton, SxProps } from '@mui/material';
import { Box, Theme } from '@mui/system';
import React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

type AlertVariant = 'standard' | 'filled' | 'outlined';

type Props = {
    openInitially: boolean;
    title?: string;
    variant?: AlertVariant;
    severity?: AlertColor;
    sx?: SxProps<Theme>;
};

const AlertCollapsable: React.FC<Props> = ({ openInitially, title, variant, severity, sx, children }) => {
    const [open, setOpen] = React.useState<boolean>(openInitially);

    return (
        <Box sx={{ width: '100%' }}>
            <Alert
                variant={variant}
                severity={severity}
                sx={sx}
                action={
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => {
                            setOpen(!open);
                        }}
                    >
                        {open ? <ExpandLessIcon fontSize="inherit" /> : <ExpandMoreIcon fontSize="inherit" />}
                    </IconButton>
                }
            >
                {title && (
                    <AlertTitle
                        sx={{ cursor: 'pointer' }}
                        onClick={() => {
                            setOpen(!open);
                        }}
                    >
                        {title}
                    </AlertTitle>
                )}
                <Collapse in={open}>{children}</Collapse>
            </Alert>
        </Box>
    );
};

export default AlertCollapsable;
