import { Button, FormControl, Grid, MenuItem, Select, Stack, Switch, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import useSubscriberLists from '../../../../../hooks/useSubscriberLists';
import { AddTippersToListSettings } from '../../../../../pages/services/sextforce/SextforceLiveStramAddTippersToList';
import { OnlyFansLists } from '../../../../../types/onlyFansTypes';
import StyledCard from '../../../../common/StyledCard';
import StyledCardTitleBar from '../../../../common/StyledCardTitleBar';
import SextforceLiveStreamAddTippersToListCreateListDialog from './SextforceLiveStreamAddTippersToListCreateListDialog';

type Props = {
    settings: AddTippersToListSettings;
    handleUpdateSettings: (newSettings: any) => void;
    isSubscriberLoading: boolean;
    setServiceRunningLoading: boolean;
    theme: any;
};

const SextforceLiveStreamAddTippersSettings = (props: Props) => {
    const { settings, handleUpdateSettings, isSubscriberLoading, setServiceRunningLoading, theme } = props;

    const {
        data: lists,
        isLoading: listsLoading,
        createList,
        isCreatingList,
    } = useSubscriberLists(settings?.addTippersToAllTippersList ? true : false);

    const [sortedLists, setSortedLists] = useState<OnlyFansLists.List[] | void>([]);

    useEffect(() => {
        if (!lists) {
            return;
        }

        const sortedData = lists.slice().sort((a: any, b: any) => {
            let x = a.name.toLowerCase();
            let y = b.name.toLowerCase();

            if (x < y) {
                return -1;
            }

            if (x > y) {
                return 1;
            }

            return 0;
        });

        setSortedLists(sortedData);
    }, [lists]);

    const [isCreateListDialogOpen, setIsCreateListDialogOpen] = useState(false);

    const handleCreateList = (listName: string) => {
        createList(listName).then(newList => {
            handleUpdateSettings({ ...settings, allTippersListId: newList.id });
            setIsCreateListDialogOpen(false);
        });
    };

    return (
        <>
            <StyledCard noCard noBottomMargin>
                <StyledCardTitleBar title={`Settings`} isLoading={isSubscriberLoading} theme={theme} />
            </StyledCard>

            <StyledCard>
                <Stack direction={'column'} spacing={1}>
                    <Grid container spacing={1} flexGrow={1} alignItems="flex-start">
                        <Grid item xs>
                            <Stack direction={'column'} spacing={0}>
                                <Typography variant="body1">Create a list for each individual live stream</Typography>
                                <Typography variant="subtitle2" color="text.secondary">
                                    Each time you start a live stream, a new list called "Stream XXXXXX Tippers" will be created. You can
                                    rename the list after the stream is finished.
                                </Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs="auto">
                            <Switch
                                size="medium"
                                checked={settings.addTippersToIndividualList}
                                disabled={isSubscriberLoading || setServiceRunningLoading}
                                onChange={e => {
                                    handleUpdateSettings({ ...settings, addTippersToIndividualList: e.target.checked });
                                }}
                                color="primary"
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={1} flexGrow={1} alignItems="flex-start">
                        <Grid item xs>
                            <Stack direction={'column'} spacing={0}>
                                <Typography variant="body1">Add all tippers to one global list</Typography>
                                <Typography variant="subtitle2" color="text.secondary">
                                    The tippers of any live stream will be added to this one global list. Select the list below or create a
                                    new one, but DO NOT delete this list from OnlyFans or the feature will not work.
                                </Typography>
                            </Stack>
                        </Grid>
                        <Grid item xs="auto">
                            <Switch
                                size="medium"
                                checked={settings.addTippersToAllTippersList}
                                disabled={isSubscriberLoading || setServiceRunningLoading}
                                onChange={e => {
                                    handleUpdateSettings({ ...settings, addTippersToAllTippersList: e.target.checked });
                                }}
                                color="primary"
                            />
                        </Grid>
                    </Grid>
                    {settings.addTippersToAllTippersList && (
                        <Grid container spacing={1} flexGrow={1} alignItems="flex-start" sx={{ pr: 1 }}>
                            <Grid item xs={12}>
                                <Stack direction={'column'} spacing={0}>
                                    <Grid container spacing={1} alignItems="center" justifyContent="center">
                                        <Grid item xs>
                                            <Typography variant="body1" sx={{ marginBottom: theme.spacing(1) }}>
                                                Select Target List
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                    <Stack direction={'row'} spacing={1}>
                                        <FormControl fullWidth>
                                            <Select
                                                value={listsLoading || isCreatingList ? 0 : settings.allTippersListId || 0}
                                                onChange={e => {
                                                    handleUpdateSettings({ ...settings, allTippersListId: e.target.value });
                                                }}
                                                fullWidth
                                                size="small"
                                                disabled={isSubscriberLoading || setServiceRunningLoading}
                                            >
                                                {(listsLoading || isCreatingList) && (
                                                    <MenuItem value={0} disabled sx={{ opacity: 0.5 }}>
                                                        <em>Loading...</em>
                                                    </MenuItem>
                                                )}
                                                {!listsLoading && !isCreatingList && lists && lists.length === 0 && (
                                                    <MenuItem value={0} disabled sx={{ opacity: 0.5 }}>
                                                        <em>No Lists Found</em>
                                                    </MenuItem>
                                                )}
                                                {!listsLoading && !isCreatingList && lists && lists.length > 0 && (
                                                    <MenuItem value={0} disabled sx={{ opacity: 0.5 }}>
                                                        <em>Select a List</em>
                                                    </MenuItem>
                                                )}
                                                {!listsLoading &&
                                                    !isCreatingList &&
                                                    sortedLists &&
                                                    sortedLists.map((list: any) => (
                                                        <MenuItem value={list.id} key={list.id}>
                                                            <Grid container spacing={0} sx={{ margin: 0, padding: 0 }}>
                                                                <Grid item xs>
                                                                    {list.name}
                                                                </Grid>
                                                                <Grid item xs="auto" sx={{ paddingRight: theme.spacing(2) }}>
                                                                    <span style={{ color: theme.palette.grey[700] }}>
                                                                        {(list.usersCount as number).toLocaleString()} Users
                                                                    </span>
                                                                </Grid>
                                                            </Grid>
                                                        </MenuItem>
                                                    ))}
                                            </Select>
                                        </FormControl>
                                        <Button
                                            size="small"
                                            variant="outlined"
                                            disabled={isSubscriberLoading || setServiceRunningLoading || isCreatingList}
                                            onClick={() => {
                                                setIsCreateListDialogOpen(true);
                                            }}
                                        >
                                            Create
                                        </Button>
                                        <SextforceLiveStreamAddTippersToListCreateListDialog
                                            open={isCreateListDialogOpen}
                                            onClose={() => setIsCreateListDialogOpen(false)}
                                            handleSubmit={handleCreateList}
                                            theme={theme}
                                        />
                                    </Stack>
                                </Stack>
                            </Grid>
                        </Grid>
                    )}
                </Stack>
            </StyledCard>
        </>
    );
};

export default SextforceLiveStreamAddTippersSettings;
