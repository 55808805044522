import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const MessageUsersAutoSendWelcomeMessage = () => {
    const navigate = useNavigate();
    const params = useParams();

    useEffect(() => {
        navigate(`/subscribers/${params.userId}/services/messageUsers`);
    }, [navigate, params.userId]);

    return null;
};

export default MessageUsersAutoSendWelcomeMessage;
