import { Box, Checkbox, Grid, Skeleton, Table, TableBody, TableCell, TableHead, TableRow, Theme } from '@mui/material';
import moment from 'moment';
import useSextforceMetricsOnly from '../../../../hooks/useSextforceMetricsOnly';
import { metricTypeName } from '../../../../utils/common';

const MetricRow = ({
    metric,
    thisGroupedMetrics,
    onCheck,
    theme,
}: {
    metric: any;
    thisGroupedMetrics: string[];
    onCheck: (metricId: string, checked: boolean) => void;
    theme: Theme;
}) => {
    return (
        <TableRow
            key={metric._id}
            onClick={() => {
                onCheck(metric._id, !thisGroupedMetrics.includes(metric._id));
            }}
            sx={{
                cursor: 'pointer',
                '&:hover': {
                    backgroundColor: theme.palette.action.hover,
                },
            }}
        >
            <TableCell align="center">
                <Checkbox
                    id={`metric-${metric._id}`}
                    checked={thisGroupedMetrics.includes(metric._id)}
                    onChange={event => {
                        onCheck(metric._id, event.target.checked);
                    }}
                />
            </TableCell>
            <TableCell>{moment(metric.payload.createdAt).format('L LT')}</TableCell>
            <TableCell>{metric.payload.name || 'No Name'}</TableCell>
            <TableCell>{metricTypeName(metric.type)}</TableCell>
        </TableRow>
    );
};

type Props = {
    targetSubscriberId: string;
    includeCampaigns: boolean;
    includeTrials: boolean;
    includeInactive: boolean;
    groupedMetrics: { [subscriberId: string]: string[] };
    setGroupedMetrics: (groupedMetrics: { [subscriberId: string]: string[] }) => void;
    theme: Theme;
};

const SextforceMetricsGroupDialogSubscriberTab = (props: Props) => {
    const { targetSubscriberId, includeCampaigns, includeTrials, includeInactive, groupedMetrics, setGroupedMetrics, theme } = props;
    const { data: metricsOnly, isLoading: isLoadingMetricsOnly } = useSextforceMetricsOnly(
        targetSubscriberId,
        includeInactive,
        includeCampaigns,
        includeTrials,
        false,
    );

    const thisGroupedMetrics = groupedMetrics[targetSubscriberId] || [];

    const handleItemChecked = (metricId: string, checked: boolean) => {
        if (checked) {
            setGroupedMetrics({
                ...groupedMetrics,
                [targetSubscriberId]: [...thisGroupedMetrics, metricId],
            });
        } else {
            setGroupedMetrics({
                ...groupedMetrics,
                [targetSubscriberId]: thisGroupedMetrics.filter(id => id !== metricId),
            });
        }
    };

    return (
        <Grid container spacing={1} alignItems={'center'}>
            <Grid item xs={12}>
                {isLoadingMetricsOnly ? (
                    <Skeleton variant={'rectangular'} width={'100%'} height={50} />
                ) : (
                    <Box
                        component={'div'}
                        sx={{
                            // scrollable
                            overflow: 'auto',
                            maxHeight: 400,
                        }}
                    >
                        <Table
                            width="100%"
                            size="small"
                            sx={{
                                // no left/right padding on the first and last cells
                                '& td:first-of-type, & th:first-of-type': {
                                    paddingLeft: 0,
                                },
                                // Bold header
                                '& th': {
                                    fontWeight: 'bold',
                                },
                                // No wrapping
                                whiteSpace: 'nowrap',
                                '& td, & th': {
                                    // Small padding on the cells
                                    padding: theme.spacing(0.5),
                                },
                                // sticky header
                                '& thead': {
                                    position: 'sticky',
                                    top: 0,
                                    zIndex: 1,
                                    bgcolor: 'grey.100',
                                },
                            }}
                        >
                            <TableHead>
                                <TableRow>
                                    <TableCell width={45}>&nbsp;</TableCell>
                                    <TableCell width={125}>Created</TableCell>
                                    <TableCell>Name</TableCell>
                                    <TableCell width={100}>Type</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {metricsOnly &&
                                    metricsOnly.map(metric => (
                                        <MetricRow
                                            key={metric._id}
                                            metric={metric}
                                            thisGroupedMetrics={thisGroupedMetrics}
                                            onCheck={handleItemChecked}
                                            theme={theme}
                                        />
                                    ))}
                            </TableBody>
                        </Table>
                    </Box>
                )}
            </Grid>
        </Grid>
    );
};

export default SextforceMetricsGroupDialogSubscriberTab;
