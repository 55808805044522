import { Box, Grid, Typography } from '@mui/material';
import moment from 'moment';
import { OnlyFansStreamStats, SextforceLiveStreamWithAmount } from '../../../../../hooks/useSextforceLiveStreamAddTippersToList';
import { secondsToTimeString } from '../../../../../utils/common';
import NA from '../../../../common/NA';

const SummaryItem = (props: { label: string; value: number | string | React.ReactNode }) => {
    const { label, value } = props;

    return (
        <>
            <Grid item xs={8}>
                <Typography variant="subtitle2">{label}</Typography>
            </Grid>
            <Grid item xs={4} textAlign="right">
                <Typography variant="subtitle2">
                    {value ? (typeof value === 'number' ? value.toLocaleString() : typeof value === 'string' ? value : value) : 0}
                </Typography>
            </Grid>
        </>
    );
};

const SextforceLiveStreamSummaryBox = (props: { liveStream: SextforceLiveStreamWithAmount }) => {
    const { liveStream } = props;
    const stats: OnlyFansStreamStats.Response | undefined = liveStream.stats;

    const getTipsAmount = (): number | undefined => {
        if (liveStream.stats) {
            return liveStream.stats.stream_tip_sum;
        }

        if (liveStream.totalAmount) {
            return liveStream.totalAmount;
        }

        return undefined;
    };

    const tipsAmount = getTipsAmount();

    return (
        <Box
            component={'div'}
            sx={{
                width: '100%',
                backgroundColor: 'rgb(235, 246, 239)',
                padding: 2,
            }}
        >
            <Grid container alignItems="center" spacing={0} flexGrow={1}>
                <Grid item xs={12}>
                    <Typography variant="subtitle2" gutterBottom fontWeight="bold">
                        Summary
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Grid container alignItems="center" spacing={0} flexGrow={1}>
                        <SummaryItem
                            label="Tips"
                            value={
                                typeof tipsAmount !== 'undefined' ? (
                                    `$${tipsAmount.toLocaleString(undefined, {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                    })}`
                                ) : (
                                    <NA />
                                )
                            }
                        />
                        {stats && <SummaryItem label="Tippers" value={stats.stream_tip_count.toLocaleString() || <NA />} />}
                        {stats && <SummaryItem label="Views" value={stats.stream_look_count.toLocaleString()} />}
                        {stats && <SummaryItem label="Likes" value={stats.stream_like_count.toLocaleString()} />}
                        {stats && <SummaryItem label="Comments" value={stats.stream_comment_count.toLocaleString()} />}
                        {stats && <SummaryItem label="Followers" value={stats.stream_followers_count.toLocaleString()} />}
                        {liveStream.endedAt && !liveStream.stats && (
                            <SummaryItem
                                label="Duration"
                                value={secondsToTimeString(moment(liveStream.endedAt).diff(liveStream.createdAt, 'seconds'))}
                            />
                        )}
                        {!liveStream.endedAt && liveStream.stats && (
                            <SummaryItem label="Duration" value={secondsToTimeString(liveStream.stats.stream_duration)} />
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};

export default SextforceLiveStreamSummaryBox;
