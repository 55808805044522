import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SquareIcon from '@mui/icons-material/Square';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    List,
    ListItem,
    MenuItem,
    Paper,
    Select,
    Stack,
    TextField,
    Theme,
    Typography,
    useTheme,
} from '@mui/material';
import { useDialog } from 'muibox';
import { useContext, useState } from 'react';
import useSubscriberCategories, { OnlyFansSubscriberCategory, OnlyFansSubscriberCategorySchema } from '../../hooks/useSubscriberCategories';
import { UserContext } from '../../store/UserContext';

const primeColors: { name: string; color: string }[] = [
    { name: 'Blue', color: '#0072CE' },
    { name: 'Green', color: '#00875A' },
    { name: 'Orange', color: '#FF5800' },
    { name: 'Pink', color: '#FF006E' },
    { name: 'Purple', color: '#A600FF' },
    { name: 'Red', color: '#E81123' },
    { name: 'Teal', color: '#00B7C3' },
    { name: 'Yellow', color: '#FFB900' },
];

const AddOrEditCategory = ({
    editMode,
    newCategory,
    setNewCategory,
    handleAddCategory,
    handleUpdateCategory,
    validateNewCategory,
    categoriesLoading,
}: {
    editMode: 'add' | 'edit';
    newCategory: OnlyFansSubscriberCategory;
    setNewCategory: (newCategory: OnlyFansSubscriberCategory) => void;
    handleAddCategory: () => void;
    handleUpdateCategory: () => void;
    validateNewCategory: any;
    categoriesLoading: boolean;
}) => {
    return (
        <>
            <DialogContentText sx={{ mb: 1 }}>{editMode === 'add' ? 'Add a New' : 'Update'} Category</DialogContentText>
            <Grid container spacing={1} alignItems="center">
                <Grid item xs={12} md>
                    <TextField
                        id="add-category-id"
                        name="add-category-id"
                        label="Category Name"
                        variant="outlined"
                        fullWidth
                        size="small"
                        value={newCategory?.name ?? ''}
                        onChange={e => setNewCategory({ ...newCategory, name: e.target.value })}
                        error={validateNewCategory.error !== undefined}
                        onKeyDown={e => {
                            if (e.key === 'Enter') {
                                handleAddCategory();
                            }
                        }}
                    />
                </Grid>
                <Grid item xs md>
                    <FormControl fullWidth size="small" variant="outlined">
                        <InputLabel id="add-category-color-label">Color</InputLabel>
                        <Select
                            id="add-category-color"
                            name="add-category-color"
                            label="Color"
                            variant="outlined"
                            fullWidth
                            size="small"
                            value={newCategory?.color ?? ''}
                            onChange={e => setNewCategory({ ...newCategory, color: e.target.value })}
                            error={validateNewCategory.error !== undefined && validateNewCategory.error.details[0].path[0] === 'color'}
                            onKeyDown={e => {
                                if (e.key === 'Enter') {
                                    handleAddCategory();
                                }
                            }}
                        >
                            <MenuItem value="none">No Color</MenuItem>
                            {primeColors.map(c => (
                                <MenuItem key={c.name} value={c.color}>
                                    <Stack direction="row" spacing={1} alignItems="center">
                                        <SquareIcon sx={{ color: c.color, mr: 1 }} />
                                        {c.name}
                                    </Stack>
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs="auto">
                    <Button
                        variant="contained"
                        color="primary"
                        size="medium"
                        disabled={categoriesLoading || validateNewCategory.error !== undefined}
                        startIcon={<AddIcon />}
                        onClick={() => {
                            if (editMode === 'add') {
                                handleAddCategory();
                            } else {
                                handleUpdateCategory();
                            }
                        }}
                    >
                        {editMode === 'add' ? 'Add' : 'Update'}
                    </Button>
                </Grid>
            </Grid>
        </>
    );
};

type Props = {
    open: boolean;
    onClose: () => void;
};

const DashboardMyAccountsManageCategoriesDialog = (props: Props) => {
    const { open, onClose } = props;
    const userContext = useContext(UserContext);
    const theme: Theme = useTheme();
    const dialog = useDialog();
    const {
        data: categories,
        isFetching: categoriesLoading,
        refetch: refetchCategories,
        addCategory,
        updateCategoty,
        deleteCategory,
    } = useSubscriberCategories();
    const [newCategory, setNewCategory] = useState<OnlyFansSubscriberCategory>({
        username: userContext.user.username,
        name: '',
    });
    const [editingMode, setEditingMode] = useState<'add' | 'edit'>('add');

    const validateNewCategory = OnlyFansSubscriberCategorySchema.validate(newCategory);

    const handleAddCategory = () => {
        if (!categoriesLoading && validateNewCategory.error === undefined) {
            addCategory(newCategory).then(() => {
                setNewCategory({ username: userContext.user.username, name: '' });
                refetchCategories();
            });
        }
    };

    const handleDeleteCategory = (categoryId: string, name: string) => {
        if (!categoriesLoading && categoryId !== '') {
            dialog
                .confirm({
                    title: 'Delete Category',
                    message: `Are you sure you want to delete the category "${name}"?`,
                })
                .then(() => {
                    deleteCategory(categoryId).then(() => {
                        refetchCategories();
                    });
                })
                .catch(() => {});
        }
    };

    const handleUpdateCategory = () => {
        if (!categoriesLoading && validateNewCategory.error === undefined) {
            updateCategoty(newCategory).then(() => {
                setNewCategory({ username: userContext.user.username, name: '' });
                setEditingMode('add');
                refetchCategories();
            });
        }
    };

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
            <DialogTitle>Manage Categories</DialogTitle>
            <DialogContent>
                <Typography variant="body1" gutterBottom sx={{ mb: 2 }}>
                    You can sort your accounts into these categories for convenience.
                </Typography>
                <DialogContentText>
                    {categoriesLoading
                        ? 'Loading categories...'
                        : `You have ${categories?.length ?? 0} ${categories?.length === 1 ? 'category' : 'categories'}`}
                </DialogContentText>
                <Grid container spacing={2} alignItems="center" sx={{ mb: 2 }}>
                    <Grid item xs={12} textAlign="center">
                        <Paper variant="outlined">
                            <List
                                sx={{
                                    width: '100%',
                                    maxHeight: 300,
                                }}
                            >
                                {categories?.map(category => (
                                    <ListItem
                                        key={category._id}
                                        secondaryAction={
                                            <Stack direction="row" spacing={1} alignItems="center">
                                                <IconButton
                                                    edge="end"
                                                    aria-label="delete"
                                                    onClick={() => {
                                                        setNewCategory({
                                                            _id: category._id,
                                                            username: userContext.user.username,
                                                            name: category.name,
                                                            color: category.color,
                                                        });
                                                        setEditingMode('edit');
                                                    }}
                                                >
                                                    <EditIcon color="primary" />
                                                </IconButton>
                                                <IconButton
                                                    edge="end"
                                                    aria-label="delete"
                                                    onClick={() => handleDeleteCategory(category._id!, category.name)}
                                                >
                                                    <DeleteIcon color="error" />
                                                </IconButton>
                                            </Stack>
                                        }
                                        sx={{
                                            pt: 2,
                                            pb: 2,
                                        }}
                                    >
                                        <Stack direction="row" spacing={1} alignItems="center">
                                            {!category.color || category.color === 'none' ? (
                                                <SquareIcon
                                                    sx={{
                                                        color: theme.palette.background.paper,
                                                        border: `1px solid ${theme.palette.divider}`,
                                                        mr: 1,
                                                    }}
                                                />
                                            ) : (
                                                <SquareIcon sx={{ color: category.color, mr: 1 }} />
                                            )}
                                            {category.name}
                                        </Stack>
                                    </ListItem>
                                ))}
                            </List>
                        </Paper>
                    </Grid>
                </Grid>

                <AddOrEditCategory
                    editMode={editingMode}
                    newCategory={newCategory}
                    setNewCategory={setNewCategory}
                    handleAddCategory={handleAddCategory}
                    handleUpdateCategory={handleUpdateCategory}
                    validateNewCategory={validateNewCategory}
                    categoriesLoading={categoriesLoading}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="secondary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DashboardMyAccountsManageCategoriesDialog;
