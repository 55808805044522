import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, CardContent, Grid, Skeleton, Typography } from '@mui/material';
import { Theme } from '@mui/system';
import dinero from 'dinero.js';
import { useContext } from 'react';
import { SettingsContext } from '../../../../store/SettingsContext';
import { d2f, titleCase, trendColor, trendIcon } from '../../../../utils/common';

const ProductPriceCard = (title: string, mainData: any, compareData: any, compare: boolean, isFetching: boolean, theme: Theme) => {
    const zeroDinero = dinero({ amount: 0, currency: 'USD' });
    const mainTotal: dinero.Dinero = mainData ? dinero({ amount: Math.trunc(d2f(mainData.total) * 100), currency: 'USD' }) : zeroDinero;
    const mainCount: number = mainData ? mainData.count : 0;
    const compareTotal: dinero.Dinero = compareData
        ? dinero({ amount: Math.trunc(d2f(compareData.total) * 100), currency: 'USD' })
        : zeroDinero;
    const compareCount: number = compareData ? compareData.count : 0;
    const color: string = trendColor(mainTotal, compareTotal, compare, theme);
    let icon: IconDefinition = trendIcon(mainTotal, compareTotal, compare);

    return (
        <Card variant="elevation">
            <CardContent>
                <Grid container flexGrow={1} alignItems="center" spacing={2}>
                    <Grid item xs={1}>
                        {compare && compareData && <FontAwesomeIcon icon={icon} color={color} />}
                    </Grid>
                    <Grid item xs>
                        <Typography variant="subtitle1" align="right">
                            {title}
                        </Typography>
                    </Grid>
                </Grid>
                <Typography variant="h6" align="right" fontFamily={'monospace'} sx={{ ...(compare && { color }) }}>
                    {isFetching ? (
                        <Skeleton width="100%" />
                    ) : (
                        <>
                            <Typography variant="body1" display="inline" fontFamily={'monospace'} fontSize={'0.7em'}>
                                {mainCount.toLocaleString()}{' '}
                            </Typography>
                            {mainTotal.toFormat()}
                        </>
                    )}
                </Typography>
                {compare && (
                    <Typography variant="h6" align="right" fontFamily={'monospace'}>
                        {isFetching ? (
                            <Skeleton width="100%" />
                        ) : (
                            <>
                                <Typography variant="body1" display="inline" fontFamily={'monospace'} fontSize={'0.7em'}>
                                    {compareCount.toLocaleString()}{' '}
                                </Typography>
                                {compareTotal.toFormat()}
                            </>
                        )}
                    </Typography>
                )}
            </CardContent>
        </Card>
    );
};

type Props = {
    mainData: any[];
    compareData: any[];
    compare: boolean;
    isFetching: boolean;
    theme: Theme;
};

const ReportAccountTotalsByProduct = (props: Props) => {
    const { mainData, compareData, compare, isFetching, theme } = props;
    const settingsContext = useContext(SettingsContext);

    return (
        <Grid container flexGrow={1} justifyContent="center" spacing={1} sx={{ paddingBottom: theme.spacing(4) }}>
            {settingsContext &&
                settingsContext.services.sextforce.productTypes &&
                settingsContext.services.sextforce.productTypes.map((productType: string) => (
                    <Grid item xs key={productType}>
                        {ProductPriceCard(
                            titleCase(productType),
                            mainData.find(item => item._id === productType),
                            compareData.find(item => item._id === productType),
                            compare,
                            isFetching,
                            theme,
                        )}
                    </Grid>
                ))}
        </Grid>
    );
};

export default ReportAccountTotalsByProduct;
