import { Chip, Grid, Skeleton, Tooltip, Typography } from '@mui/material';

const ValueAndTrend = (props: {
    loading?: boolean;
    value: number;
    previousValue?: number;
    type: 'number' | 'money';
    size?: 'small' | 'medium';
    separateLines?: boolean;
    textAlign?: 'left' | 'center' | 'right';
}) => {
    const { loading, value, previousValue, type, size, separateLines, textAlign } = props;

    const diff = previousValue !== undefined ? value - previousValue : 0;

    return (
        <Grid container spacing={1} flexGrow={0} alignItems="center" justifyContent={'center'}>
            <Grid item xs={separateLines ? 12 : undefined}>
                <Typography
                    variant={size && size === 'small' ? 'body1' : 'h6'}
                    fontWeight={500}
                    textAlign={textAlign || 'right'}
                    color={'primary.main'}
                >
                    {loading ? (
                        <Skeleton />
                    ) : (
                        <>
                            {type === 'money' ? '$' : ''}
                            {value ? value.toLocaleString() : '-'}
                        </>
                    )}
                </Typography>
            </Grid>
            {previousValue !== undefined && (
                <Grid item xs={separateLines ? 12 : undefined} textAlign={textAlign || 'right'}>
                    {loading ? (
                        <Skeleton />
                    ) : (
                        <>
                            <Tooltip title="Change from yesterday" placement="top" arrow>
                                <Chip
                                    variant="filled"
                                    color={value === previousValue ? 'warning' : value > previousValue ? 'success' : 'error'}
                                    label={
                                        value
                                            ? diff === 0
                                                ? '0'
                                                : `${diff > 0 ? '+' : ''}${type === 'money' ? '$' : ''}${diff.toLocaleString()}`
                                            : 'n/a'
                                    }
                                />
                            </Tooltip>
                        </>
                    )}
                </Grid>
            )}
        </Grid>
    );
};

export default ValueAndTrend;
