import { Box, Card, CardContent, Grid, Slider, Typography } from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';
import { Theme } from '@mui/system';
import { useContext, useState } from 'react';
import BackNavigationButton from '../../../components/common/BackNavigationButton';
import useSubscriber from '../../../hooks/useSubscriber';
import { SettingsContext } from '../../../store/SettingsContext';

import * as d3 from 'd3';
import moment from 'moment';
import { useEffect, useRef } from 'react';
import PageContainer from '../../../components/common/PageContainter';
import useSubscriberPerformerTop from '../../../hooks/useSubscriberPerformerTop';
import PerformerRankingMyRankingCard from './PerformerRankingMyRankingCard';

const data: PerformerRankingDataPoint[] = [
    {
        ranking: '0.01',
        min: 331780.03,
        avg: 375917.98,
        max: 420055.93,
    },
    {
        ranking: '0.02',
        min: 217803.34,
        avg: 250257.34499999997,
        max: 282711.35,
    },
    {
        ranking: '0.03',
        min: 180444.73,
        avg: 203718.64500000002,
        max: 226992.56,
    },
    {
        ranking: '0.04',
        min: 154085.99,
        avg: 166588.695,
        max: 179091.4,
    },
    {
        ranking: '0.05',
        min: 143700.55,
        avg: 147139.005,
        max: 150577.46,
    },
    {
        ranking: '0.06',
        min: 128358.91,
        avg: 138205.41,
        max: 148051.91,
    },
    {
        ranking: '0.07',
        min: 121283.98,
        avg: 127968.75,
        max: 134653.52,
    },
    {
        ranking: '0.08',
        min: 114874.43,
        avg: 119584.83499999999,
        max: 124295.24,
    },
    {
        ranking: '0.09',
        min: 110130.38,
        avg: 113170.26000000001,
        max: 116210.14,
    },
    {
        ranking: '0.10',
        min: 68281.4,
        avg: 88507.345,
        max: 108733.29,
    },
    {
        ranking: '0.11',
        min: 94970.87,
        avg: 98884.135,
        max: 102797.4,
    },
    {
        ranking: '0.12',
        min: 92374.95,
        avg: 93984.315,
        max: 95593.68,
    },
    {
        ranking: '0.13',
        min: 63415.65,
        avg: 78048.985,
        max: 92682.32,
    },
    {
        ranking: '0.14',
        min: 83330.69,
        avg: 84469.83499999999,
        max: 85608.98,
    },
    {
        ranking: '0.15',
        min: 79182.33,
        avg: 82559.31,
        max: 85936.29,
    },
    {
        ranking: '0.16',
        min: 58551.71,
        avg: 70457,
        max: 82362.29,
    },
    {
        ranking: '0.17',
        min: 76338.22,
        avg: 77695.5,
        max: 79052.78,
    },
    {
        ranking: '0.18',
        min: 73483.74,
        avg: 76077.275,
        max: 78670.81,
    },
    {
        ranking: '0.19',
        min: 71237.83,
        avg: 74450.20999999999,
        max: 77662.59,
    },
    {
        ranking: '0.20',
        min: 69926.56,
        avg: 70729.04999999999,
        max: 71531.54,
    },
    {
        ranking: '0.21',
        min: 65861.7,
        avg: 68832.76000000001,
        max: 71803.82,
    },
    {
        ranking: '0.22',
        min: 64194.53,
        avg: 66776.05,
        max: 69357.57,
    },
    {
        ranking: '0.23',
        min: 62428.52,
        avg: 66372.3,
        max: 70316.08,
    },
    {
        ranking: '0.24',
        min: 61406.78,
        avg: 61406.78,
        max: 61406.78,
    },
    {
        ranking: '0.25',
        min: 61336.95,
        avg: 62715.975,
        max: 64095,
    },
    {
        ranking: '0.26',
        min: 25016.55,
        avg: 44206.425,
        max: 63396.3,
    },
    {
        ranking: '0.27',
        min: 54719.76,
        avg: 55659.2,
        max: 56598.64,
    },
    {
        ranking: '0.28',
        min: 56825.14,
        avg: 57106.08,
        max: 57387.02,
    },
    {
        ranking: '0.29',
        min: 53224.31,
        avg: 57459.505,
        max: 61694.7,
    },
    {
        ranking: '0.30',
        min: 51461.45,
        avg: 53887.305,
        max: 56313.16,
    },
    {
        ranking: '0.31',
        min: 49770.16,
        avg: 52331.33,
        max: 54892.5,
    },
    {
        ranking: '0.32',
        min: 36667.98,
        avg: 47047.735,
        max: 57427.49,
    },
    {
        ranking: '0.33',
        min: 49516.5,
        avg: 50456.555,
        max: 51396.61,
    },
    {
        ranking: '0.34',
        min: 48094.87,
        avg: 53034.285,
        max: 57973.7,
    },
    {
        ranking: '0.35',
        min: 48783.36,
        avg: 50464.28,
        max: 52145.2,
    },
    {
        ranking: '0.36',
        min: 46314.61,
        avg: 47899.815,
        max: 49485.02,
    },
    {
        ranking: '0.37',
        min: 32148.2,
        avg: 40155.39,
        max: 48162.58,
    },
    {
        ranking: '0.38',
        min: 45562.44,
        avg: 47419.65,
        max: 49276.86,
    },
    {
        ranking: '0.39',
        min: 43143.85,
        avg: 45986.195,
        max: 48828.54,
    },
    {
        ranking: '0.40',
        min: 43352.19,
        avg: 44571.770000000004,
        max: 45791.35,
    },
    {
        ranking: '0.41',
        min: 42077.84,
        avg: 43287.05499999999,
        max: 44496.27,
    },
    {
        ranking: '0.42',
        min: 41680.29,
        avg: 43782.865000000005,
        max: 45885.44,
    },
    {
        ranking: '0.43',
        min: 40140.67,
        avg: 41772.78,
        max: 43404.89,
    },
    {
        ranking: '0.44',
        min: 37687.48,
        avg: 40848.785,
        max: 44010.09,
    },
    {
        ranking: '0.45',
        min: 39554.28,
        avg: 39554.28,
        max: 39554.28,
    },
    {
        ranking: '0.46',
        min: 36716.99,
        avg: 39629.520000000004,
        max: 42542.05,
    },
    {
        ranking: '0.47',
        min: 38181.31,
        avg: 40131.28,
        max: 42081.25,
    },
    {
        ranking: '0.48',
        min: 37282.61,
        avg: 40659.685,
        max: 44036.76,
    },
    {
        ranking: '0.49',
        min: 38956.46,
        avg: 39787.615,
        max: 40618.77,
    },
    {
        ranking: '0.50',
        min: 35055.44,
        avg: 41825.315,
        max: 48595.19,
    },
    {
        ranking: '0.51',
        min: 36587.56,
        avg: 37217.47,
        max: 37847.38,
    },
    {
        ranking: '0.52',
        min: 35412.12,
        avg: 35412.12,
        max: 35412.12,
    },
    {
        ranking: '0.54',
        min: 35246.15,
        avg: 35501.435,
        max: 35756.72,
    },
    {
        ranking: '0.55',
        min: 33689.09,
        avg: 34975.975,
        max: 36262.86,
    },
    {
        ranking: '0.56',
        min: 33184.8,
        avg: 33907.11,
        max: 34629.42,
    },
    {
        ranking: '0.57',
        min: 33233.41,
        avg: 33766.43,
        max: 34299.45,
    },
    {
        ranking: '0.58',
        min: 32535.31,
        avg: 33188.995,
        max: 33842.68,
    },
    {
        ranking: '0.59',
        min: 32040.56,
        avg: 34460.585,
        max: 36880.61,
    },
    {
        ranking: '0.60',
        min: 31326.67,
        avg: 31980.79,
        max: 32634.91,
    },
    {
        ranking: '0.61',
        min: 33556.64,
        avg: 34006.19,
        max: 34455.74,
    },
    {
        ranking: '0.62',
        min: 20405.08,
        avg: 26204.285000000003,
        max: 32003.49,
    },
    {
        ranking: '0.63',
        min: 28934.76,
        avg: 31475.775,
        max: 34016.79,
    },
    {
        ranking: '0.64',
        min: 29519.84,
        avg: 30150.655,
        max: 30781.47,
    },
    {
        ranking: '0.65',
        min: 28878.53,
        avg: 30357.260000000002,
        max: 31835.99,
    },
    {
        ranking: '0.66',
        min: 30190.46,
        avg: 30826.07,
        max: 31461.68,
    },
    {
        ranking: '0.67',
        min: 29446.97,
        avg: 29696.43,
        max: 29945.89,
    },
    {
        ranking: '0.68',
        min: 30818.93,
        avg: 30818.93,
        max: 30818.93,
    },
    {
        ranking: '0.69',
        min: 28681.38,
        avg: 29314.205,
        max: 29947.03,
    },
    {
        ranking: '0.70',
        min: 26737.6,
        avg: 26737.6,
        max: 26737.6,
    },
    {
        ranking: '0.71',
        min: 29791.19,
        avg: 29791.19,
        max: 29791.19,
    },
    {
        ranking: '0.72',
        min: 26971.98,
        avg: 27302.465,
        max: 27632.95,
    },
    {
        ranking: '0.73',
        min: 26165.98,
        avg: 27212.11,
        max: 28258.24,
    },
    {
        ranking: '0.74',
        min: 24847.4,
        avg: 26804.515,
        max: 28761.63,
    },
    {
        ranking: '0.75',
        min: 22603.6,
        avg: 26313.845,
        max: 30024.09,
    },
    {
        ranking: '0.76',
        min: 24912,
        avg: 27113.845,
        max: 29315.69,
    },
    {
        ranking: '0.77',
        min: 24741.51,
        avg: 24741.51,
        max: 24741.51,
    },
    {
        ranking: '0.78',
        min: 25190.08,
        avg: 26012.63,
        max: 26835.18,
    },
    {
        ranking: '0.79',
        min: 23852.55,
        avg: 25232.25,
        max: 26611.95,
    },
    {
        ranking: '0.80',
        min: 3210.52,
        avg: 3210.52,
        max: 3210.52,
    },
    {
        ranking: '0.81',
        min: 24505.68,
        avg: 25259.315000000002,
        max: 26012.95,
    },
    {
        ranking: '0.82',
        min: 23299.94,
        avg: 25740.025,
        max: 28180.11,
    },
    {
        ranking: '0.83',
        min: 24209.05,
        avg: 24622.35,
        max: 25035.65,
    },
    {
        ranking: '0.84',
        min: 23917.41,
        avg: 24060.385000000002,
        max: 24203.36,
    },
    {
        ranking: '0.85',
        min: 24568.87,
        avg: 25091.805,
        max: 25614.74,
    },
    {
        ranking: '0.86',
        min: 23120.3,
        avg: 23965.415,
        max: 24810.53,
    },
    {
        ranking: '0.87',
        min: 23029.53,
        avg: 24693.785,
        max: 26358.04,
    },
    {
        ranking: '0.88',
        min: 22700.76,
        avg: 23965.065,
        max: 25229.37,
    },
    {
        ranking: '0.89',
        min: 23634.17,
        avg: 23716.68,
        max: 23799.19,
    },
    {
        ranking: '0.91',
        min: 22922.23,
        avg: 24080.915,
        max: 25239.6,
    },
    {
        ranking: '0.93',
        min: 21306.62,
        avg: 22148.25,
        max: 22989.88,
    },
    {
        ranking: '0.94',
        min: 21438.62,
        avg: 22734.364999999998,
        max: 24030.11,
    },
    {
        ranking: '0.95',
        min: 23048.52,
        avg: 23048.52,
        max: 23048.52,
    },
    {
        ranking: '0.96',
        min: 14821.27,
        avg: 14821.27,
        max: 14821.27,
    },
    {
        ranking: '0.97',
        min: 21661.35,
        avg: 22257.82,
        max: 22854.29,
    },
    {
        ranking: '0.98',
        min: 19992.35,
        avg: 21148.155,
        max: 22303.96,
    },
    {
        ranking: '0.99',
        min: 20220.84,
        avg: 20965.725,
        max: 21710.61,
    },
    {
        ranking: '1.00',
        min: 16731.02,
        avg: 19124.35,
        max: 21517.68,
    },
    {
        ranking: '1.10',
        min: 13772.61,
        avg: 17896.585,
        max: 22020.56,
    },
    {
        ranking: '1.20',
        min: 4319.91,
        avg: 14529.289999999999,
        max: 24738.67,
    },
    {
        ranking: '1.30',
        min: 6393.03,
        avg: 13679.039999999999,
        max: 20965.05,
    },
    {
        ranking: '1.40',
        min: 13570.92,
        avg: 15682.615000000002,
        max: 17794.31,
    },
    {
        ranking: '1.50',
        min: 6908.3,
        avg: 11947.675,
        max: 16987.05,
    },
    {
        ranking: '1.60',
        min: 11832.73,
        avg: 16444.655,
        max: 21056.58,
    },
    {
        ranking: '1.70',
        min: 11813.26,
        avg: 12520.279999999999,
        max: 13227.3,
    },
    {
        ranking: '1.80',
        min: 10656.99,
        avg: 12659.025,
        max: 14661.06,
    },
    {
        ranking: '1.90',
        min: 10601.15,
        avg: 12152.295,
        max: 13703.44,
    },
    {
        ranking: '2.00',
        min: 10096.99,
        avg: 11979.895,
        max: 13862.8,
    },
    {
        ranking: '2.10',
        min: 9437.4,
        avg: 10030.77,
        max: 10624.14,
    },
    {
        ranking: '2.20',
        min: 8628.91,
        avg: 9805.365,
        max: 10981.82,
    },
    {
        ranking: '2.30',
        min: 3,
        avg: 6532.46,
        max: 13061.92,
    },
    {
        ranking: '2.40',
        min: 5606.39,
        avg: 7948.74,
        max: 10291.09,
    },
    {
        ranking: '2.50',
        min: 7404.13,
        avg: 8150.775,
        max: 8897.42,
    },
    {
        ranking: '2.60',
        min: 7582.92,
        avg: 8260.43,
        max: 8937.94,
    },
    {
        ranking: '2.70',
        min: 7014.25,
        avg: 8005.865,
        max: 8997.48,
    },
    {
        ranking: '2.80',
        min: 5823.7,
        avg: 6923.135,
        max: 8022.57,
    },
    {
        ranking: '2.90',
        min: 6620.31,
        avg: 7092.925,
        max: 7565.54,
    },
    {
        ranking: '3.00',
        min: 6222.14,
        avg: 7678.0650000000005,
        max: 9133.99,
    },
    {
        ranking: '3.10',
        min: 5909.34,
        avg: 6670.745,
        max: 7432.15,
    },
    {
        ranking: '3.20',
        min: 5791.95,
        avg: 6241.424999999999,
        max: 6690.9,
    },
    {
        ranking: '3.30',
        min: 5639.09,
        avg: 6248.045,
        max: 6857,
    },
    {
        ranking: '3.40',
        min: 4341.54,
        avg: 5204.014999999999,
        max: 6066.49,
    },
    {
        ranking: '3.50',
        min: 5384.49,
        avg: 5784.695,
        max: 6184.9,
    },
    {
        ranking: '3.60',
        min: 4972.73,
        avg: 6678.775,
        max: 8384.82,
    },
    {
        ranking: '3.70',
        min: 3883.56,
        avg: 4680.12,
        max: 5476.68,
    },
    {
        ranking: '3.80',
        min: 4744.8,
        avg: 5456.535,
        max: 6168.27,
    },
    {
        ranking: '3.90',
        min: 2732.99,
        avg: 5220.34,
        max: 7707.69,
    },
    {
        ranking: '4.00',
        min: 4142.93,
        avg: 5206.085,
        max: 6269.24,
    },
    {
        ranking: '4.10',
        min: 4331.52,
        avg: 4564.135,
        max: 4796.75,
    },
    {
        ranking: '4.20',
        min: 4152.28,
        avg: 4580.54,
        max: 5008.8,
    },
    {
        ranking: '4.30',
        min: 4214.94,
        avg: 4406.165,
        max: 4597.39,
    },
    {
        ranking: '4.40',
        min: 3581.48,
        avg: 4086.74,
        max: 4592,
    },
    {
        ranking: '4.50',
        min: 3475.92,
        avg: 4080.565,
        max: 4685.21,
    },
    {
        ranking: '4.60',
        min: 3099.91,
        avg: 4423.4,
        max: 5746.89,
    },
    {
        ranking: '4.70',
        min: 3410.03,
        avg: 3609.5600000000004,
        max: 3809.09,
    },
    {
        ranking: '4.80',
        min: 2078.92,
        avg: 2883.6000000000004,
        max: 3688.28,
    },
    {
        ranking: '4.90',
        min: 2286.65,
        avg: 3672.08,
        max: 5057.51,
    },
    {
        ranking: '5.00',
        min: 3105.5,
        avg: 3559.83,
        max: 4014.16,
    },
    {
        ranking: '5.10',
        min: 2925.31,
        avg: 3522.62,
        max: 4119.93,
    },
    {
        ranking: '5.20',
        min: 3134.96,
        avg: 3953.225,
        max: 4771.49,
    },
    {
        ranking: '5.30',
        min: 3045.97,
        avg: 3188.6049999999996,
        max: 3331.24,
    },
    {
        ranking: '5.40',
        min: 2774.66,
        avg: 3100.41,
        max: 3426.16,
    },
    {
        ranking: '5.50',
        min: 2805.62,
        avg: 2969.175,
        max: 3132.73,
    },
    {
        ranking: '5.60',
        min: 3215.76,
        avg: 3295.4700000000003,
        max: 3375.18,
    },
    {
        ranking: '5.70',
        min: 2717.11,
        avg: 2834.5550000000003,
        max: 2952,
    },
    {
        ranking: '5.80',
        min: 2767.78,
        avg: 3182.995,
        max: 3598.21,
    },
    {
        ranking: '5.90',
        min: 2217.51,
        avg: 2639.445,
        max: 3061.38,
    },
    {
        ranking: '6.00',
        min: 2263.84,
        avg: 2263.84,
        max: 2263.84,
    },
    {
        ranking: '6.10',
        min: 2377.22,
        avg: 2721.4449999999997,
        max: 3065.67,
    },
    {
        ranking: '6.20',
        min: 2180,
        avg: 2596.56,
        max: 3013.12,
    },
    {
        ranking: '6.30',
        min: 2112.63,
        avg: 2112.63,
        max: 2112.63,
    },
    {
        ranking: '6.40',
        min: 2267,
        avg: 2306.75,
        max: 2346.5,
    },
    {
        ranking: '6.50',
        min: 2351.21,
        avg: 2407.76,
        max: 2464.31,
    },
    {
        ranking: '6.60',
        min: 1922.48,
        avg: 2169.865,
        max: 2417.25,
    },
    {
        ranking: '6.70',
        min: 2056.48,
        avg: 2332.96,
        max: 2609.44,
    },
    {
        ranking: '6.80',
        min: 2005.14,
        avg: 2097.695,
        max: 2190.25,
    },
    {
        ranking: '6.90',
        min: 2105.71,
        avg: 2232.085,
        max: 2358.46,
    },
    {
        ranking: '7.00',
        min: 2016.43,
        avg: 2016.43,
        max: 2016.43,
    },
    {
        ranking: '7.10',
        min: 1923.14,
        avg: 2142.245,
        max: 2361.35,
    },
    {
        ranking: '7.20',
        min: 1510.94,
        avg: 1808.205,
        max: 2105.47,
    },
    {
        ranking: '7.30',
        min: 1954.5,
        avg: 2034.08,
        max: 2113.66,
    },
    {
        ranking: '7.40',
        min: 2009.33,
        avg: 2057.4449999999997,
        max: 2105.56,
    },
    {
        ranking: '7.50',
        min: 1852.03,
        avg: 2002.705,
        max: 2153.38,
    },
    {
        ranking: '7.60',
        min: 1577.2,
        avg: 1875.71,
        max: 2174.22,
    },
    {
        ranking: '7.70',
        min: 1747.93,
        avg: 1792.125,
        max: 1836.32,
    },
    {
        ranking: '7.80',
        min: 1742.83,
        avg: 1812.755,
        max: 1882.68,
    },
    {
        ranking: '7.90',
        min: 1740.96,
        avg: 1754.78,
        max: 1768.6,
    },
    {
        ranking: '8.00',
        min: 1870.33,
        avg: 1954.665,
        max: 2039,
    },
    {
        ranking: '8.10',
        min: 1536.02,
        avg: 1929.395,
        max: 2322.77,
    },
    {
        ranking: '8.20',
        min: 1598.84,
        avg: 1802.105,
        max: 2005.37,
    },
    {
        ranking: '8.30',
        min: 1430.84,
        avg: 1501.1100000000001,
        max: 1571.38,
    },
    {
        ranking: '8.40',
        min: 1481.54,
        avg: 1554.895,
        max: 1628.25,
    },
    {
        ranking: '8.50',
        min: 1515.8,
        avg: 1617.81,
        max: 1719.82,
    },
    {
        ranking: '8.60',
        min: 1610.1,
        avg: 1632.435,
        max: 1654.77,
    },
    {
        ranking: '8.70',
        min: 1487.82,
        avg: 1679.4,
        max: 1870.98,
    },
    {
        ranking: '8.80',
        min: 995,
        avg: 995,
        max: 995,
    },
    {
        ranking: '9.00',
        min: 1494.86,
        avg: 1494.86,
        max: 1494.86,
    },
    {
        ranking: '9.10',
        min: 1493.36,
        avg: 2134.795,
        max: 2776.23,
    },
    {
        ranking: '9.20',
        min: 1299.87,
        avg: 1362.48,
        max: 1425.09,
    },
    {
        ranking: '9.30',
        min: 1391,
        avg: 1391,
        max: 1391,
    },
    {
        ranking: '9.40',
        min: 1357.5,
        avg: 1369.375,
        max: 1381.25,
    },
    {
        ranking: '9.50',
        min: 1094.47,
        avg: 1394.2350000000001,
        max: 1694,
    },
    {
        ranking: '9.70',
        min: 843.03,
        avg: 1147.425,
        max: 1451.82,
    },
    {
        ranking: '9.80',
        min: 1126.46,
        avg: 2348.0699999999997,
        max: 3569.68,
    },
    {
        ranking: '9.90',
        min: 1062.55,
        avg: 1178.19,
        max: 1293.83,
    },
    {
        ranking: '10.00',
        min: 1020.47,
        avg: 1530.845,
        max: 2041.22,
    },
    {
        ranking: '11.00',
        min: 830,
        avg: 1447.96,
        max: 2065.92,
    },
    {
        ranking: '12.00',
        min: 633.65,
        avg: 924.6949999999999,
        max: 1215.74,
    },
    {
        ranking: '13.00',
        min: 455.38,
        avg: 925.655,
        max: 1395.93,
    },
    {
        ranking: '14.00',
        min: 630,
        avg: 739.165,
        max: 848.33,
    },
    {
        ranking: '15.00',
        min: 475.39,
        avg: 773.74,
        max: 1072.09,
    },
    {
        ranking: '16.00',
        min: 468.57,
        avg: 655.035,
        max: 841.5,
    },
    {
        ranking: '17.00',
        min: 477.13,
        avg: 576.565,
        max: 676,
    },
    {
        ranking: '18.00',
        min: 165.95,
        avg: 908.965,
        max: 1651.98,
    },
    {
        ranking: '19.00',
        min: 266.93,
        avg: 388.88,
        max: 510.83,
    },
];

export interface PerformerRankingDataPoint {
    ranking: string;
    min: number;
    avg: number;
    max: number;
}

const OnlyFansRankingsChartLinear = ({ data }: { data: PerformerRankingDataPoint[] }) => {
    const theme: Theme = useTheme();
    const chartRef = useRef<HTMLDivElement>(null);
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

    useEffect(() => {
        const resizeObserver = new ResizeObserver(entries => {
            for (let entry of entries) {
                const { width, height } = entry.contentRect;
                setDimensions({ width, height });
            }
        });

        if (chartRef.current) {
            resizeObserver.observe(chartRef.current);
        }

        return () => {
            resizeObserver.disconnect();
        };
    }, []);

    useEffect(() => {
        if (chartRef.current && dimensions.width > 0 && dimensions.height > 0) {
            drawChart();
        }
    }, [dimensions]);

    const drawChart = (): void => {
        const margin = { top: 20, right: 30, bottom: 100, left: 65 };
        const width = dimensions.width - margin.left - margin.right;
        const height = dimensions.height - margin.top - margin.bottom;
        const brushHeight = 50;

        d3.select(chartRef.current).selectAll('svg').remove();

        const svg = d3
            .select(chartRef.current)
            .append('svg')
            .attr('width', width + margin.left + margin.right)
            .attr('height', height + margin.top + margin.bottom + brushHeight)
            .append('g')
            .attr('transform', `translate(${margin.left},${margin.top})`);

        // Parse ranking strings to numbers for scaling
        const rankingValues = data.map(d => parseFloat(d.ranking));

        // Changed to scaleLinear
        const x = d3
            .scaleLinear()
            .range([0, width])
            .domain([0, (d3.max(rankingValues) || 0.2) + 0.01]); // Add 5% extra space

        const y = d3
            .scaleLinear()
            .range([height, 0])
            .domain([0, (d3.max(data, d => d.max) || 0) * 1.1]); // Add 10% extra space

        const xAxis = d3.axisBottom(x).tickFormat(
            d =>
                `${d.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                })}%`,
        );
        const yAxis = d3.axisLeft(y).tickFormat(d => `$${d3.format('.2s')(d)}`);

        // Clip path
        svg.append('defs').append('clipPath').attr('id', 'clip').append('rect').attr('width', width).attr('height', height);

        // Main chart group
        const mainChart = svg.append('g').attr('clip-path', 'url(#clip)');

        // Add grid lines
        const gridGroup = mainChart.append('g').attr('class', 'grid-lines');

        function updateGrid() {
            gridGroup.selectAll('.grid-line').remove();
            const yTicks = y.ticks();
            gridGroup
                .selectAll('.grid-line')
                .data(yTicks.slice(1)) // Exclude the first tick
                .enter()
                .append('line')
                .attr('class', 'grid-line')
                .attr('x1', 0)
                .attr('x2', width)
                .attr('y1', d => y(d))
                .attr('y2', d => y(d))
                .attr('stroke', '#e0e0e0')
                .attr('stroke-width', 1);
        }

        const xAxisGroup = svg
            .append('g')
            .attr('class', 'x-axis')
            .style('font-size', '11px')
            .attr('transform', `translate(0,${height})`)
            .call(xAxis);

        const yAxisGroup = svg.append('g').attr('class', 'y-axis').style('font-size', '11px').call(yAxis);

        const minMaxLines = mainChart
            .selectAll('.minmax-line')
            .data(data)
            .enter()
            .append('line')
            .attr('class', 'minmax-line')
            .attr('stroke', 'black')
            .attr('stroke-width', 1);

        const maxLines = mainChart
            .selectAll('.max-line')
            .data(data)
            .enter()
            .append('line')
            .attr('class', 'max-line')
            .attr('stroke', 'black')
            .attr('stroke-width', 1);

        const minLines = mainChart
            .selectAll('.min-line')
            .data(data)
            .enter()
            .append('line')
            .attr('class', 'min-line')
            .attr('stroke', 'black')
            .attr('stroke-width', 1);

        // add ranking label above the max line
        const maxLabels = mainChart
            .selectAll('.max-label')
            .data(data)
            .enter()
            .append('text')
            .attr('class', 'max-label')
            .attr('text-anchor', 'middle')
            .attr('dy', '-1em')
            .attr('fill', 'black')
            .attr('font-size', '10px')
            .text(d => `${d.ranking}%`);

        const tooltip = d3
            .select(chartRef.current)
            .append('div')
            .attr('class', 'tooltip')
            .style('opacity', 0)
            .style('position', 'absolute')
            .style('background-color', 'white')
            .style('border', 'solid')
            .style('border-width', '1px')
            .style('border-radius', '5px')
            .style('padding', '10px');

        const avgDots = mainChart
            .selectAll('.avg-dot')
            .data(data)
            .enter()
            .append('circle')
            .attr('class', 'avg-dot')
            .attr('r', 3)
            .attr('fill', theme.palette.primary.main)
            .on('mouseover', (event: MouseEvent, d: PerformerRankingDataPoint) => {
                tooltip.transition().duration(200).style('opacity', 0.9);
                const tooltipWidth = 200; // Approximate width of the tooltip
                const tooltipHeight = 100; // Approximate height of the tooltip
                // const xPosition = x(d.ranking);
                // const yPosition = y(d.avg);

                let left = event.pageX + 10;
                let top = event.pageY - 28;

                // Adjust tooltip position if it would overflow the right side of the chart
                if (left + tooltipWidth > dimensions.width) {
                    left = event.pageX - tooltipWidth - 10;
                }

                // Adjust tooltip position if it would overflow the bottom of the chart
                if (top + tooltipHeight > dimensions.height) {
                    top = event.pageY - tooltipHeight - 10;
                }

                tooltip
                    .html(
                        `<table cellspacing="2" cellpadding="2" border="0">
                        <tr>
                            <td><strong>Ranking</strong></td>
                            <td><strong>${d.ranking}%</strong></td>
                        </tr>
                        <tr>
                            <td>Min:</td>
                            <td>$${d.min.toLocaleString(undefined, {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                            })}</td>
                        </tr>
                        <tr>
                            <td>Avg:</td>
                            <td>$${d.avg.toLocaleString(undefined, {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                            })}</td>
                        </tr>
                        <tr>
                            <td>Max:</td>
                            <td>$${d.max.toLocaleString(undefined, {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                            })}</td>
                        </tr>
                    </table>`,
                    )
                    .style('left', `${left}px`)
                    .style('top', `${top}px`);
            })
            .on('mouseout', () => {
                tooltip.transition().duration(500).style('opacity', 0);
            });

        // X-axis label
        svg.append('text')
            .attr('x', width / 2)
            .attr('y', height + margin.bottom - 60)
            .attr('text-anchor', 'middle')
            .style('font-size', '12px')
            .text('Ranking Percentage');

        // Y-axis label
        svg.append('text')
            .attr('transform', 'rotate(-90)')
            .attr('y', 0 - margin.left)
            .attr('x', 0 - height / 2)
            .attr('dy', '1em')
            .style('text-anchor', 'middle')
            .style('font-size', '12px')
            .text('Earnings per Month (Gross, USD)');

        // Brush
        const brush = d3
            .brushX()
            .extent([
                [0, 0],
                [width, brushHeight],
            ])
            .on('brush end', brushed);

        const context = svg
            .append('g')
            .attr('class', 'context')
            .attr('transform', `translate(0,${height + margin.top + 20})`);

        const xBrush = x.copy();
        const yBrush = d3.scaleLinear().range([brushHeight, 0]).domain(y.domain());

        // Add the brush overview area
        context
            .append('g')
            .selectAll('line')
            .data(data)
            .enter()
            .append('line')
            .attr('x1', (d: PerformerRankingDataPoint) => xBrush(parseFloat(d.ranking)))
            .attr('x2', (d: PerformerRankingDataPoint) => xBrush(parseFloat(d.ranking)))
            .attr('y1', (d: PerformerRankingDataPoint) => yBrush(d.min))
            .attr('y2', (d: PerformerRankingDataPoint) => yBrush(d.max))
            .attr('stroke', 'black')
            .attr('stroke-width', 1);

        context
            .append('g')
            .selectAll('circle')
            .data(data)
            .enter()
            .append('circle')
            .attr('cx', (d: PerformerRankingDataPoint) => xBrush(parseFloat(d.ranking)))
            .attr('cy', (d: PerformerRankingDataPoint) => yBrush(d.avg))
            .attr('r', 2)
            .attr('fill', theme.palette.primary.main);

        context
            .append('g')
            .attr('class', 'axis axis--x')
            .attr('transform', `translate(0,${brushHeight})`)
            .call(
                d3.axisBottom(xBrush).tickFormat(
                    d =>
                        `${d.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                        })}%`,
                ),
            );

        context
            .append('g')
            .attr('class', 'brush')
            .call(brush)
            .call(brush.move, [0, xBrush(0.2)]);

        function updateChart(xDomain: [number, number]) {
            x.domain(xDomain);

            const visibleData = data.filter(d => {
                const rankingValue = parseFloat(d.ranking);
                return rankingValue >= xDomain[0] && rankingValue <= xDomain[1];
            });
            const yDomain = [0, (d3.max(visibleData, d => d.max) || 0) * 1.1];

            y.domain(yDomain);

            xAxisGroup.call(xAxis as any);
            yAxisGroup.call(yAxis as any);

            updateGrid(); // Update grid lines

            minMaxLines
                .attr('x1', d => x(parseFloat(d.ranking)))
                .attr('x2', d => x(parseFloat(d.ranking)))
                .attr('y1', d => y(d.min))
                .attr('y2', d => y(d.max));

            maxLines
                .attr('x1', d => x(parseFloat(d.ranking)) - 5)
                .attr('x2', d => x(parseFloat(d.ranking)) + 5)
                .attr('y1', d => y(d.max))
                .attr('y2', d => y(d.max));

            minLines
                .attr('x1', d => x(parseFloat(d.ranking)) - 5)
                .attr('x2', d => x(parseFloat(d.ranking)) + 5)
                .attr('y1', d => y(d.min))
                .attr('y2', d => y(d.min));

            maxLabels.attr('x', d => x(parseFloat(d.ranking))).attr('y', d => y(d.max));

            avgDots.attr('cx', d => x(parseFloat(d.ranking))).attr('cy', d => y(d.avg));
        }

        function brushed(event: d3.D3BrushEvent<unknown>) {
            if (event.selection) {
                // Get the selected range
                const [x0, x1] = event.selection as [number, number];
                const newXDomain: [number, number] = [xBrush.invert(x0), xBrush.invert(x1)];

                updateChart(newXDomain);
            }
        }
    };

    return <div ref={chartRef} style={{ width: '100%', height: '100%' }} />;
};

const RankingSelector = ({
    selectedRanking,
    setSelectedRanking,
    data,
}: {
    selectedRanking: number;
    setSelectedRanking: (ranking: number) => void;
    data: PerformerRankingDataPoint[];
}) => {
    const theme: Theme = useTheme();

    const handleChange = (event: Event, value: number | number[]) => {
        if (typeof value === 'number') {
            setSelectedRanking(value);
        }
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Slider
                        value={selectedRanking}
                        onChange={handleChange}
                        valueLabelDisplay="auto"
                        step={0.1}
                        min={parseFloat(data[0].ranking)}
                        max={parseFloat(data[data.length - 1].ranking)}
                        marks={[
                            { value: 0, label: '0%' },
                            { value: 5, label: '5%' },
                            { value: 10, label: '10%' },
                            { value: 15, label: '15%' },
                            { value: 20, label: '20%' },
                        ]}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body2" color="textSecondary">
                        Selected ranking: {selectedRanking.toFixed(2)}%
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    );
};

const PerformerRanking = () => {
    const settingsContext = useContext(SettingsContext);
    const theme: Theme = useTheme();

    const { data: performerTop, isLoading: isPerformerTopLoading } = useSubscriberPerformerTop();
    const { data: subscriber, isLoading: isSubscriberLoading } = useSubscriber();

    const [myRanking, setMyRanking] = useState<number>(0);
    const [selectedRanking, setSelectedRanking] = useState<number>(0);

    useEffect(() => {
        if (performerTop && performerTop.performerTop < parseFloat(data[data.length - 1].ranking)) {
            setSelectedRanking(performerTop.performerTop);
            setMyRanking(performerTop.performerTop);
        } else {
            setSelectedRanking(0);
        }
    }, [performerTop]);

    return (
        <PageContainer>
            {subscriber && '_id' in subscriber && subscriber._id && (
                <>
                    <Typography variant="h5" sx={{ marginBottom: 2 }}>
                        <BackNavigationButton
                            url={`/subscribers/${subscriber._id || ''}/${settingsContext.services.messageUsers.homeUrl}`}
                        />{' '}
                        OnlyFans Performer Ranking
                    </Typography>

                    <PerformerRankingMyRankingCard myRanking={myRanking} isPerformerTopLoading={isPerformerTopLoading} data={data} />

                    <Card sx={{ width: '100%', marginBottom: 2 }}>
                        <CardContent>
                            <Grid container flexGrow={1} spacing={2} sx={{ marginBottom: 1 }}>
                                <Grid item xs={12}>
                                    <Typography variant="h5">Current Rankings</Typography>
                                    <Typography variant="caption" color="textSecondary">
                                        Updated {moment().format('LL')}
                                    </Typography>
                                    <Typography variant="body1" gutterBottom sx={{ mt: 1 }}>
                                        The following graph shows an approximation of what level of earnings a month is required to reach a
                                        certain ranking on OnlyFans.
                                    </Typography>
                                    <Typography variant="body1" gutterBottom>
                                        Please bear in mind that these are figures that are based on a sample of OnlyFans performers and may
                                        not be 100% accurate as we don't know exactly how OnlyFans calculates their rankings. But
                                        nevertheless, it should give you a rough idea of what you need to earn to reach a certain ranking.
                                    </Typography>
                                    <Typography variant="body1">
                                        Each data point below represents a ranking percentage (X axis), the minimum (lower horizontal line),
                                        average (blue dot), and maximum (upper horizontal line) earnings (Y axis) for a given ranking.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <RankingSelector
                                        selectedRanking={selectedRanking}
                                        setSelectedRanking={setSelectedRanking}
                                        data={data}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Box sx={{ height: '500px' }}>
                                        <OnlyFansRankingsChartLinear data={data} />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} textAlign="center">
                                    <Typography variant="body2" color="textSecondary">
                                        Use the slider above to zoom in on a specific ranking range.
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </>
            )}
        </PageContainer>
    );
};

export default PerformerRanking;
