import CircleIcon from '@mui/icons-material/Circle';
import { FormControl, Grid, InputLabel, MenuItem, Select, Skeleton, Stack, ToggleButton, useMediaQuery } from '@mui/material';
import { Theme } from '@mui/system';
import moment from 'moment';
import { useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { SettingsContext } from '../../../../../../store/SettingsContext';
import { getPlatformIcon } from '../../../../../../utils/common';

type Props = {
    promoCampaigns: any[];
    promoCampaignsLoading: boolean;
    promoCampaignId: string;
    setPromoCampaignId: (id: string) => void;
    showInactive: boolean;
    setShowInactive: (showInactive: boolean) => void;
    timezone: string;
    theme: Theme;
};

const SextforceMetricsCampaignSelector = (props: Props) => {
    const { promoCampaigns, promoCampaignsLoading, promoCampaignId, setPromoCampaignId, showInactive, setShowInactive, timezone, theme } =
        props;
    const settings = useContext(SettingsContext);
    const largeScreen = useMediaQuery(theme.breakpoints.up('sm'));
    const params = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (!params.metricId) {
            if (promoCampaigns && promoCampaigns.length > 0) {
                setPromoCampaignId(promoCampaigns[0]._id);
            } else {
                setPromoCampaignId('');
            }
        }
    }, [params.metricId, promoCampaigns, setPromoCampaignId, showInactive]);

    return (
        <Grid container spacing={1} alignItems="center">
            <Grid item xs={12} md>
                {promoCampaignsLoading ? (
                    <Skeleton />
                ) : (
                    <FormControl fullWidth>
                        {promoCampaigns && promoCampaigns.length > 0 && <InputLabel id="platform">Select Campaign</InputLabel>}
                        <Select
                            labelId="promoCampaignId"
                            value={promoCampaignId}
                            onChange={e => {
                                setPromoCampaignId(e.target.value);

                                if (e.target.value !== params.metricId) {
                                    return navigate(
                                        `/subscribers/${params.userId}/services/sextforce/metrics/campaigns/details/${e.target.value}`,
                                    );
                                }
                            }}
                            disabled={!promoCampaigns || promoCampaigns.length === 0}
                            fullWidth
                            size="medium"
                            label="Select Campaign"
                            displayEmpty
                            renderValue={
                                promoCampaigns && promoCampaigns.length > 0 ? undefined : () => <div>No Promo Campaigns Found</div>
                            }
                        >
                            {promoCampaigns &&
                                promoCampaigns.length > 0 &&
                                promoCampaigns.map((promoCampaign: any) => (
                                    <MenuItem value={promoCampaign._id} key={promoCampaign._id}>
                                        <Grid container spacing={0} alignItems="center" sx={{ margin: 0, padding: 0 }}>
                                            <Grid item xs="auto">
                                                <CircleIcon
                                                    color={promoCampaign.payload.active ? 'success' : 'disabled'}
                                                    sx={{ fontSize: 10, marginRight: theme.spacing(1) }}
                                                />
                                            </Grid>
                                            <Grid item xs sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                                {promoCampaign.payload.name || 'No Name'}
                                            </Grid>
                                            {promoCampaign.associatedUsername && (
                                                <Grid item xs="auto" sx={{ marginRight: theme.spacing(1) }}>
                                                    <Stack direction="row" spacing={0.5} alignItems="center">
                                                        {promoCampaign.settings &&
                                                            promoCampaign.settings.platform &&
                                                            getPlatformIcon(settings.platforms, promoCampaign.settings.platform, 'small')}
                                                        <div
                                                            style={{
                                                                overflow: 'hidden',
                                                                textOverflow: 'ellipsis',
                                                                color: theme.palette.grey[700],
                                                            }}
                                                        >
                                                            {promoCampaign.associatedUsername}
                                                        </div>
                                                    </Stack>
                                                </Grid>
                                            )}
                                            <Grid item xs="auto" sx={{ paddingRight: theme.spacing(2) }}>
                                                <span style={{ color: theme.palette.grey[700], fontFamily: 'monospace' }}>
                                                    {moment(promoCampaign.payload.createdAt).tz(timezone).format('L').toLocaleString()}
                                                </span>
                                            </Grid>
                                        </Grid>
                                    </MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                )}
            </Grid>
            <Grid item xs={12} md="auto">
                <Grid container spacing={largeScreen ? 0 : 2} alignItems="center">
                    <Grid item>
                        <ToggleButton
                            value={showInactive ? 'true' : 'false'}
                            selected={showInactive}
                            color="secondary"
                            size={largeScreen ? 'large' : 'small'}
                            onChange={(e, value: string) => {
                                setShowInactive(!showInactive);

                                localStorage.setItem('sextforceMetricsCampaignsOverviewSelectorType', value);
                            }}
                            sx={{ marginTop: theme.spacing(0.5) }}
                        >
                            Show Inactive
                        </ToggleButton>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default SextforceMetricsCampaignSelector;
