import { Card, CardContent, Grid, Skeleton, Theme, Typography, useTheme } from '@mui/material';
import moment from 'moment';
import StatsChart from './StatsChart';
import ValueAndTrend from './ValueAndTrend';

const StatsCardFollowingActive = (props: { dailyStats: any[] | undefined; dailyStatsLoading: boolean }) => {
    const { dailyStats, dailyStatsLoading } = props;
    const theme: Theme = useTheme();

    return (
        <Card variant="elevation" sx={{ pb: 0 }}>
            <CardContent sx={{ height: 150 }}>
                {dailyStatsLoading ? (
                    <Skeleton />
                ) : (
                    <Grid container spacing={1} flexGrow={0} justifyContent="center" sx={{ height: '100%' }}>
                        <Grid item xs={12}>
                            <Grid container spacing={1} flexGrow={0} alignItems="center">
                                <Grid item xs>
                                    <Typography variant="h6" textAlign={'left'}>
                                        Following
                                    </Typography>
                                </Grid>
                                <Grid item xs="auto">
                                    <ValueAndTrend
                                        value={
                                            dailyStats &&
                                            dailyStats[0] &&
                                            dailyStats[0].counters &&
                                            dailyStats[0].counters.subscriptions &&
                                            dailyStats[0].counters.subscriptions.active !== undefined
                                                ? dailyStats[0].counters.subscriptions.active
                                                : undefined
                                        }
                                        previousValue={
                                            dailyStats &&
                                            dailyStats.length > 1 &&
                                            dailyStats[1].counters &&
                                            dailyStats[1].counters.subscriptions &&
                                            dailyStats[1].counters.subscriptions.active !== undefined
                                                ? dailyStats[1].counters.subscriptions.active
                                                : undefined
                                        }
                                        type={'number'}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <StatsChart
                                data={
                                    dailyStats
                                        ? dailyStats.map(d => {
                                              return {
                                                  time: moment(d._id).valueOf(),
                                                  count: d.counters ? d.counters.subscriptions.active : undefined,
                                              };
                                          })
                                        : []
                                }
                                color={theme.palette.primary.main}
                            />
                        </Grid>
                    </Grid>
                )}
            </CardContent>
        </Card>
    );
};

export default StatsCardFollowingActive;
