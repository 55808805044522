import axios from 'axios';
import { useDialog } from 'muibox';
import { useContext } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { SettingsContext } from '../store/SettingsContext';
import { UserContext } from '../store/UserContext';
import { handleHttpError } from '../utils/common';

export interface OnlyFansMetricsTelegramNotifications {
    _id?: string;
    metricId: string;
    inviteCode: string;
    type: 'metric' | 'metricsGroup';
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    options: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    lastPayload: any;
    active: boolean;
    telegramChatId?: number;
    telegramUsername?: string;
    registerDate?: Date;
    lastSentDate?: Date;
    deletedDate?: Date;
}

const useSextforceMetricsTelegramInvitationLink = (metricId: string, metricType: string) => {
    const userContext = useContext(UserContext);
    const settingsContext = useContext(SettingsContext);
    const dialog = useDialog();
    const params = useParams();

    // Fetch Fans Counters
    const fetchInvitationLinks = async (metricId: string, metricType: string): Promise<OnlyFansMetricsTelegramNotifications[]> => {
        const query: string = `${settingsContext.routes.metrics.base}${params.userId}/${
            metricType === 'promoCampaign' ? 'promocampaigns' : 'trials'
        }/${metricId}/invite`;

        // Update campaign settings
        const data = await axios
            .get(query, {
                headers: {
                    Authorization: userContext.jwtToken,
                    apiKey: settingsContext.apiKey,
                    'Content-Type': 'application/json',
                },
            })
            .then(async response => response.data as OnlyFansMetricsTelegramNotifications[])
            .catch(error => {
                console.error(error);
                handleHttpError(error, dialog);

                return [];
            });

        return data;
    };

    const metricsTelegramInvitationLinks = useQuery(
        ['metricsTelegramInvitationLinks', userContext.jwtToken, params.userId, metricId],
        () => fetchInvitationLinks(metricId, metricType),
        {
            refetchOnWindowFocus: false,
            staleTime: 0,
            enabled: userContext.jwtToken && settingsContext.apiKey && params.userId ? true : false,
        },
    );

    return { ...metricsTelegramInvitationLinks };
};

export default useSextforceMetricsTelegramInvitationLink;
