import { Chip, Theme, useTheme } from '@mui/material';
import { GridRenderCellParams, GridTreeNodeWithRender } from '@mui/x-data-grid-pro';

const claimsGoalCellBackgroundStyle = (theme: Theme, settings: any, value: number) => {
    const goal = settings && settings.goalSubs ? settings.goalSubs : 0;
    let percent: number = value && value !== 0 && goal !== 0 ? (value / goal) * 100 : 0;

    if (percent > 100) {
        percent = 100;
    }

    if (percent === 0) {
        return theme.palette.error.main;
    }

    const color = percent === 100 ? theme.palette.success.main : theme.palette.warning.light;
    const css = `-webkit-linear-gradient(left, ${color} 0%, ${color} ${percent}%, white ${percent}%); -moz-linear-gradient(left, ${color} 0%, ${color} ${percent}%, white ${percent}%); -ms-linear-gradient(left, ${color} 0%, ${color} ${percent}%, white ${percent}%); linear-gradient(left, ${color} 0%, ${color} ${percent}%, white ${percent}%);`;

    return css;
};

const getColor = (params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>, theme: Theme) => {
    if (params.row.settings && params.row.settings.goalSubs) {
        const goalSubs = params.row.settings.goalSubs;

        if (params.value === 0) {
            return theme.palette.common.white;
        }

        if (params.value < goalSubs) {
            return theme.palette.common.black;
        } else {
            return theme.palette.common.white;
        }
    } else {
        if (params.value > 0) {
            return theme.palette.common.white;
        } else {
            return theme.palette.common.black;
        }
    }
};

const SextforceMetricsClaimsCountChip = ({ params }: { params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender> }) => {
    const theme: Theme = useTheme();

    const background =
        params.row.settings && params.row.settings.goalSubs
            ? claimsGoalCellBackgroundStyle(theme, params.row.settings, params.value)
            : params.value > 0
            ? theme.palette.success.main
            : theme.palette.grey[300];
    const color = getColor(params, theme);

    return (
        <Chip
            label={(params.value || 0).toLocaleString()}
            color={params.value > 0 ? 'success' : 'default'}
            sx={{
                width: '100%',
                fontFamily: 'monospace',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                justifyContent: 'flex-end',
                color,
                background,
                '& .MuiChip-root': {},
            }}
        />
    );
};

export default SextforceMetricsClaimsCountChip;
