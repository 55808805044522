import { useContext, useState, useEffect, useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import useTheme from '@mui/material/styles/useTheme';
import { Theme } from '@mui/system';
import { Container, Typography, Grid, Box, Tooltip, Card, CardContent } from '@mui/material';
import Grid3x3Icon from '@mui/icons-material/Grid3x3';
import InfoIcon from '@mui/icons-material/Info';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Dinero from 'dinero.js';
import moment from 'moment-timezone';
import 'moment/locale/en-gb';
import { UserContext } from '../../../store/UserContext';
import { SettingsContext } from '../../../store/SettingsContext';
import { OnlyFansSubscriber } from '../../../../../src/libs/onlyFans/onlyFansDbTypes';
import { DataGrid, GridActionsCellItem, GridColumns, GridRowParams, GridSortModel, GridValueGetterParams } from '@mui/x-data-grid';
import { d2f } from '../../../utils/common';
import SelectTimezone from '../../../components/forms/helpers/SelectTimezone';
import { useConfirm } from 'material-ui-confirm';
import AlertCollapsable from '../../../components/common/AlertCollapsable';
import { useDialog } from 'muibox';
import { handleHttpError, handleHttpErrorResponse } from '../../../utils/common';
import SextforceManageSaleProofsSearchBar from '../../../components/services/sextforce/manageSaleProofs/SextforceManageSaleProofsSearchBar';
import SextforceManageSaleProofsDetailsDialog from '../../../components/services/sextforce/manageSaleProofs/SextforceManageSaleProofsDetailsDialog';
import BackNavigationButton from '../../../components/common/BackNavigationButton';
import useDashboardAccount from '../../../hooks/useDashboardAccount';

const moneyCell = (params: any) => (
    <div style={{ fontFamily: 'monospace' }}>{Dinero({ amount: Math.round(d2f(params.value) * 100), currency: 'USD' }).toFormat()}</div>
);

type ReportParams = {
    timezone: string;
    dateFrom: Date | null;
    dateTo: Date | null;
    isFreeRange: boolean;
    filter: string;
    agent: string;
};

const SextforceManageSaleProofs = () => {
    const userContext = useContext(UserContext);
    const settingsContext = useContext(SettingsContext);
    const { dashboardAccount, dashboardAccountLoading } = useDashboardAccount(true);
    const theme: Theme = useTheme();
    const dialog = useDialog();
    const params = useParams();
    const queryClient = useQueryClient();
    const confirm = useConfirm();
    const [subscriber, setSubscriber] = useState<OnlyFansSubscriber | null>(null);

    const [agents, setAgents] = useState<any[]>([]);

    // Search Bar
    const [searchFreeRange, setSearchFreeRange] = useState<boolean>(false);
    const [searchWeek, setSearchWeek] = useState<number>(moment().week());
    const [searchDateFrom, setSearchDateFrom] = useState<Date>(moment().startOf('week').toDate());
    const [searchDateTo, setSearchDateTo] = useState<Date>(moment().endOf('week').toDate());
    const [searchFilter, setSearchFilter] = useState<string>('all');
    const [searchAgent, setSearchAgent] = useState<string>('');
    const [searchTimezone, setSearchTimezone] = useState<string>(moment.tz.guess());

    // Report
    const [offset, setOffset] = useState<number>(0);
    const [limit, setLimit] = useState<number>(20);
    const [reportParams, setReportParams] = useState<ReportParams>({
        timezone: moment.tz.guess(),
        dateFrom: null,
        dateTo: null,
        isFreeRange: false,
        filter: 'all',
        agent: '',
    });
    const [reportSort, setReportSort] = useState<any[]>([{ field: 'createdAt', sort: 'desc' }]);
    const [timezone, setTimezone] = useState<string>(moment.tz.guess());

    // Review Sales Proof
    const [infoSaleProofDialogOpen, setInfoSaleProofDialogOpen] = useState<boolean>(false);
    const [infoSaleProof, setInfoSaleProof] = useState<any | null>(null);

    // Set global MomentJS locale
    moment.locale('en-gb');

    // Close Create Sales Proof Link dialog
    const handleCloseInfoSalesProofDialog = () => {
        setInfoSaleProof(null);
        setInfoSaleProofDialogOpen(false);
    };

    // Open Review Sales Proof Dialog
    const handleOpenInfoSaleProofDialog = (transaction: any) => {
        setInfoSaleProof(transaction);
        setInfoSaleProofDialogOpen(true);
    };

    useEffect(() => {
        if (!dashboardAccountLoading && dashboardAccount && dashboardAccount.timezone) {
            setSearchTimezone(dashboardAccount.timezone);
        }
    }, [dashboardAccountLoading, dashboardAccount]);

    /**
     * Delete sale proof
     */

    const deleteSaleProof = async (saleProofId: string) => {
        if (userContext.jwtToken && settingsContext.apiKey && 'userId' in params && params.userId) {
            const query: string = `${settingsContext.routes.sextforce.base}${params.userId}/saleproofs/${saleProofId}`;

            return fetch(query, {
                method: 'delete',
                headers: {
                    Authorization: userContext.jwtToken,
                    apiKey: settingsContext.apiKey,
                    'Content-Type': 'application/json',
                },
            })
                .then(async response => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        handleHttpErrorResponse(response, dialog);
                    }
                })
                .catch(error => {
                    console.error(error);
                    handleHttpError(error, dialog);
                });
        }

        return null;
    };

    const mutateSaleProofs = useMutation(deleteSaleProof, {
        onSuccess: data => {
            if (data && data._id) {
                // Find the sale proof that was just deleted, and remove it in from the sale proofs array
                const newSaleProofs = {
                    rows: [...saleProofs.rows.filter((saleProof: any) => saleProof._id !== data._id)],
                    metadata: {
                        total: saleProofs.metadata.total - 1,
                    },
                };

                // Update the transactions cache and rerender components
                queryClient.setQueryData(['saleProofs', reportParams, reportSort, offset, limit], newSaleProofs);

                handleCloseInfoSalesProofDialog();
            }
        },
        onError: error => {
            dialog.alert((error as any).message as string);
        },
    });

    const handleDeleteSaleProof = useCallback(
        (saleProofId: string) => {
            // Double check the user wants to delete this sale proof
            confirm({
                title: 'WARNING!!!',
                description: 'Are you sure you want to delete this sale proof? This operation CANNOT be undone!',
            })
                .then(() => {
                    // Delete
                    mutateSaleProofs.mutate(saleProofId);
                })
                .catch(() => {});
        },
        [confirm, mutateSaleProofs],
    );

    /**
     * Sextforce Agents
     */

    // Fetch Sextforce Agents
    useEffect(() => {
        if (userContext.jwtToken.length > 0 && settingsContext.apiKey.length > 0 && 'userId' in params) {
            fetch(`${settingsContext.routes.sextforce.base}${params.userId}/agents`, {
                method: 'get',
                headers: {
                    Authorization: userContext.jwtToken,
                    apiKey: settingsContext.apiKey,
                },
            })
                .then(async response => {
                    if (response.ok) {
                        setAgents(await response.json());
                    } else {
                        handleHttpErrorResponse(response, dialog);
                    }
                })
                .catch(error => {
                    console.error(error);
                    handleHttpError(error, dialog);

                    setAgents([]);
                });
        } else {
            setAgents([]);
        }
    }, [dialog, params, settingsContext.apiKey, settingsContext.routes.sextforce.base, userContext.jwtToken]);

    /**
     * DataGrid Columns
     */

    // Define agents DataGrid columns
    const columns = useMemo<GridColumns>(
        () => [
            {
                field: 'dateTime',
                headerName: 'Date & Time',
                type: 'dateTime',
                width: 200,
                renderCell: params => (
                    <div>
                        <Grid container alignItems="center">
                            <Grid item xs>
                                <Tooltip title={params.row._id}>
                                    <Grid3x3Icon fontSize="small" />
                                </Tooltip>
                            </Grid>
                            <Grid item xs sx={{ fontFamily: 'monospace' }}>
                                {moment(params.row.dateTime).tz(timezone).format('L hh:mm a')}
                            </Grid>
                        </Grid>
                    </div>
                ),
            },
            {
                field: 'agentId',
                headerName: 'Agent',
                width: 200,
                valueGetter: (params: GridValueGetterParams) => agents && agents.find(agent => agent._id === params.row.agentId)?.name,
            },
            {
                field: 'amount',
                headerName: 'Amount',
                width: 100,
                align: 'right',
                headerAlign: 'right',
                renderCell: params => moneyCell(params),
            },
            {
                field: 'notes',
                headerName: 'Notes',
                width: 200,
                flex: 1,
                renderCell: ({ value }) => <span style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{value}</span>,
            },
            {
                field: 'dateCreated',
                headerName: 'Submitted On',
                type: 'dateTime',
                width: 190,
                renderCell: params => (
                    <span style={{ fontFamily: 'monospace' }}>{moment(params.row.dateCreated).tz(timezone).format('L hh:mm a')}</span>
                ),
            },
            {
                field: 'assignedTransaction',
                headerName: 'Status',
                width: 110,
                sortable: false,
                renderCell: params =>
                    params.row.assignedTransaction ? <div style={{ color: theme.palette.success.main }}>APPROVED</div> : '',
            },
            {
                field: 'actions',
                type: 'actions',
                width: 120,
                getActions: (params: GridRowParams) => [
                    /* @ts-ignore */
                    <GridActionsCellItem
                        icon={
                            <Tooltip title="More Info">
                                <InfoIcon color="primary" />
                            </Tooltip>
                        }
                        label="More Info"
                        onClick={() => {
                            handleOpenInfoSaleProofDialog(params.row);
                        }}
                    />,
                    /* @ts-ignore */
                    <GridActionsCellItem
                        icon={
                            <Tooltip title="Delete">
                                <DeleteForeverIcon color="error" />
                            </Tooltip>
                        }
                        label="Delete"
                        onClick={() => {
                            handleDeleteSaleProof(params.row._id);
                        }}
                    />,
                ],
            },
        ],
        [agents, handleDeleteSaleProof, theme, timezone],
    );

    // Retrieve subscriber data
    useEffect(() => {
        if (userContext.jwtToken && settingsContext.apiKey && 'userId' in params && params.userId) {
            const query: string = `${settingsContext.routes.subscribers.find}${params.userId}`;

            fetch(query, {
                method: 'get',
                headers: {
                    Authorization: userContext.jwtToken,
                    apiKey: settingsContext.apiKey,
                },
            })
                .then(async response => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        handleHttpErrorResponse(response, dialog);
                    }
                })
                .then(data => {
                    setSubscriber(data);
                })
                .catch(error => {
                    console.error(error);
                    handleHttpError(error, dialog);
                });
        }
    }, [userContext, settingsContext, params, dialog]);

    // Reset report when switching subscriber accounts
    useEffect(() => {
        setReportParams({
            timezone: moment.tz.guess(),
            dateFrom: null,
            dateTo: null,
            isFreeRange: false,
            filter: 'all',
            agent: '',
        });

        queryClient.setQueryData('saleproofs', null);
        queryClient.setQueryData('agents', null);
    }, [queryClient, subscriber]);

    // Fetch Sale Proofs
    const fetchSaleProofs = async (reportParams: ReportParams, sort: any[], dataOffset: number, dataLimit: number): Promise<any> => {
        if (userContext.jwtToken && settingsContext.apiKey && 'userId' in params && params.userId && subscriber) {
            const query: string = `${settingsContext.routes.sextforce.base}${params.userId}/saleproofs?${new URLSearchParams({
                startDate: reportParams.dateFrom ? reportParams.dateFrom.toISOString() : '',
                endDate: reportParams.dateTo ? reportParams.dateTo.toISOString() : '',
                filter: reportParams.filter,
                agent: reportParams.agent,
                offset: dataOffset.toString(),
                limit: dataLimit.toString(),
                ...(sort && sort.length > 0 && { sortField: sort[0].field, sort: sort[0].sort }),
            })}`;

            const data = await fetch(query, {
                method: 'get',
                headers: {
                    Authorization: userContext.jwtToken,
                    apiKey: settingsContext.apiKey,
                },
            })
                .then(async response => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        handleHttpErrorResponse(response, dialog);
                    }
                })
                .catch(error => {
                    console.error(error);
                    handleHttpError(error, dialog);
                });

            return data;
        }

        return { rows: [], metadata: { total: 0 } };
    };

    const {
        data: saleProofs,
        isLoading: saleProofsLoading,
        refetch: refetchReport,
    } = useQuery(
        ['saleProofs', reportParams, reportSort, offset, limit],
        () => {
            if (reportParams.dateFrom && reportParams.dateTo) {
                return fetchSaleProofs(reportParams, reportSort, offset, limit);
            }
        },
        {
            refetchOnWindowFocus: false,
            staleTime: 0,
            enabled:
                userContext.jwtToken &&
                settingsContext.apiKey &&
                'userId' in params &&
                params.userId &&
                reportParams.dateFrom &&
                reportParams.dateTo
                    ? true
                    : false,
        },
    );

    // Retrieve the sales report from the server
    const requestReport = useCallback(async () => {
        // Update report date range
        setReportParams({
            timezone: searchTimezone,
            dateFrom: moment(searchDateFrom).tz(searchTimezone, true).toDate(),
            dateTo: moment(searchDateTo).tz(searchTimezone, true).toDate(),
            isFreeRange: searchFreeRange,
            filter: searchFilter,
            agent: searchAgent,
        });

        refetchReport();
    }, [searchTimezone, searchDateFrom, searchDateTo, searchFreeRange, searchFilter, searchAgent, refetchReport]);

    return (
        <Container
            maxWidth={false}
            sx={{ paddingTop: theme.spacing(4), paddingBottom: theme.spacing(4), backgroundColor: '#e7ebf0', minHeight: '100%' }}
        >
            <Typography variant="h5" sx={{ paddingBottom: theme.spacing(4) }}>
                <BackNavigationButton
                    {...(subscriber && { url: `/subscribers/${subscriber._id}/${settingsContext.services.sextforce.homeUrl}` })}
                />{' '}
                Manage Sale Proofs for {subscriber?.username}
            </Typography>

            <AlertCollapsable
                openInitially={false}
                title="How It Works"
                variant="filled"
                severity="info"
                sx={{
                    marginBottom: theme.spacing(4),
                }}
            >
                <p>
                    Find all Agents' or specific Agent's sale proofs submissions, view their details and delete submissions with mistakes so
                    they don't confuse the Assign Transactions feed.
                </p>

                <p>
                    <strong>NOTE: Deleting a sale proof submission is PERMENANT and cannot be undone!</strong>
                </p>

                <p>This page is useful for finding submissions that were not matched/assigned to any transaction.</p>
            </AlertCollapsable>

            <SextforceManageSaleProofsSearchBar
                timezone={searchTimezone}
                setTimezone={setSearchTimezone}
                mainSearch={{
                    freeRange: searchFreeRange,
                    setFreeRange: setSearchFreeRange,
                    week: searchWeek,
                    setWeek: setSearchWeek,
                    dateFrom: searchDateFrom,
                    setDateFrom: setSearchDateFrom,
                    dateTo: searchDateTo,
                    setDateTo: setSearchDateTo,
                    filter: searchFilter,
                    setFilter: setSearchFilter,
                    agent: searchAgent,
                    setAgent: setSearchAgent,
                }}
                isFetchingReport={saleProofsLoading}
                agents={agents ? agents : []}
                requestReport={requestReport}
            />

            <div style={{ paddingBottom: theme.spacing(4) }}>
                <Typography variant="subtitle1">
                    {reportParams.dateFrom && reportParams.dateTo
                        ? `Results for ${
                              reportParams.isFreeRange || !reportParams.dateFrom ? '' : `Week ${moment(reportParams.dateFrom).week()} - `
                          } ${reportParams.dateFrom.toLocaleDateString()} - ${reportParams.dateTo.toLocaleDateString()} (${searchTimezone})`
                        : 'No Dates Selected'}
                </Typography>
            </div>

            <Card sx={{ width: '100%', marginBottom: theme.spacing(4) }}>
                <CardContent style={{ padding: theme.spacing(1) }}>
                    <Grid container flexGrow={0} spacing={1} alignItems="center" sx={{ marginTop: 0, marginLeft: 0 }}>
                        <Grid item xs={'auto'}>
                            <Typography variant="body1">Translate transactions time to timezone:</Typography>
                        </Grid>
                        <Grid item xs={2}>
                            <SelectTimezone timezone={timezone} setTimezone={setTimezone} size="small" fullWidth={true} />
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>

            <Box
                sx={{
                    width: '100%',
                    '.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
                        outline: 'none',
                    },
                    marginBottom: theme.spacing(4),
                }}
            >
                <DataGrid
                    rows={saleProofs && 'rows' in saleProofs && saleProofs.rows ? saleProofs.rows : []}
                    columns={columns}
                    density={'compact'}
                    autoHeight={true}
                    disableColumnFilter
                    rowCount={saleProofs && saleProofs.metadata && saleProofs.metadata.total ? saleProofs.metadata.total : 0}
                    getRowId={row => row._id}
                    pageSize={limit}
                    onPageSizeChange={newPageSize => setLimit(newPageSize)}
                    rowsPerPageOptions={[10, 20, 40, 80, 100]}
                    page={offset / limit}
                    onPageChange={newPage => setOffset(limit * newPage)}
                    paginationMode={'server'}
                    sortingMode={'server'}
                    sortModel={reportSort}
                    onSortModelChange={(model: GridSortModel) => {
                        setReportSort(model);
                    }}
                    loading={saleProofsLoading}
                    sx={{ backgroundColor: '#ffffff' }}
                />
            </Box>

            <SextforceManageSaleProofsDetailsDialog
                open={infoSaleProofDialogOpen}
                onCancel={handleCloseInfoSalesProofDialog}
                onDelete={handleDeleteSaleProof}
                saleProof={infoSaleProof}
                timezone={timezone}
                agents={agents}
            />
        </Container>
    );
};

export default SextforceManageSaleProofs;
