import axios from 'axios';
import moment from 'moment';
import { useDialog } from 'muibox';
import { useContext } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { SettingsContext } from '../store/SettingsContext';
import { UserContext } from '../store/UserContext';
import { handleHttpError } from '../utils/common';

const useSextforceMetricsAccountFansCountersAll = (startDate: Date, endDate: Date, timezone: string) => {
    const userContext = useContext(UserContext);
    const settingsContext = useContext(SettingsContext);
    const dialog = useDialog();
    const params = useParams();

    // Fetch Fans Counters
    const fetchAccountFansCountersAll = async (startDate: Date, endDate: Date, timezone: string): Promise<any> => {
        const query: string = `${settingsContext.routes.metrics.base}${params.userId}/fanscounters/all?${new URLSearchParams({
            startDate: moment(startDate).tz(timezone, true).toISOString(),
            endDate: moment(endDate).tz(timezone, true).toISOString(),
        })}`;

        const data = await axios
            .get(query, {
                headers: {
                    Authorization: userContext.jwtToken,
                    apiKey: settingsContext.apiKey,
                },
            })
            .then(async response => response.data)
            .catch(error => {
                console.error(error);
                handleHttpError(error, dialog);

                return [];
            });

        return data;
    };

    return useQuery(
        ['metricsFansCountersAll', userContext.jwtToken, params.userId],
        () => fetchAccountFansCountersAll(startDate, endDate, timezone),
        {
            refetchOnWindowFocus: false,
            staleTime: 60 * 1000 * 60 * 12,
            enabled: userContext.jwtToken && settingsContext.apiKey && params.userId ? true : false,
        },
    );
};

export default useSextforceMetricsAccountFansCountersAll;
