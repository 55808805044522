import { Card, CardContent, Checkbox, FormControlLabel, FormGroup, Grid, Skeleton, Stack, Typography } from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';
import { Theme } from '@mui/system';
import dinero from 'dinero.js';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { CartesianGrid, Legend, Line, LineChart, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { d2f } from '../../../../utils/common';
import ToggleGraphType from '../../../common/ToggleGraphType';
import OnlineCountHeatMapGraph, { groupByDay } from '../../../dashboard/OnlineCountHeatMapGraph';

const CustomTooltip = ({ active, payload, theme }: any) => {
    if (active && payload && payload.length) {
        return (
            <div>
                <Grid
                    container
                    flexGrow={0}
                    alignItems="center"
                    spacing={1}
                    sx={{
                        backgroundColor: '#fff',
                        borderRadius: 4,
                        border: '1px solid #999',
                        maxWidth: 200,
                    }}
                >
                    <Grid item xs={12}>
                        <span style={{ fontWeight: 'bold' }}>{moment(payload[0].payload.time).format('ddd Do, hh:mm A')}</span>
                    </Grid>
                    {payload.map((item: any, index: number) => (
                        <Grid item xs={12} key={index}>
                            {item.dataKey === 'count' && (
                                <Grid container flexGrow={1} alignItems="center">
                                    <Grid item xs={4} sx={{ color: theme.palette.primary.main }}>
                                        Count:
                                    </Grid>
                                    <Grid item xs={8} textAlign={'right'} sx={{ color: theme.palette.primary.main, paddingRight: '8px' }}>
                                        {item.value && typeof item.value === 'number' ? item.value.toLocaleString() : 0}
                                    </Grid>
                                </Grid>
                            )}

                            {item.dataKey === 'totalAmountCents' && (
                                <Grid container flexGrow={1} alignItems="center">
                                    <Grid item xs={4} sx={{ color: theme.palette.secondary.main }}>
                                        Total:
                                    </Grid>
                                    <Grid item xs={8} textAlign={'right'} sx={{ color: theme.palette.secondary.main, paddingRight: '8px' }}>
                                        {dinero({
                                            amount: item.value && typeof item.value === 'number' ? item.value : 0,
                                            currency: 'USD',
                                        }).toFormat()}
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                    ))}
                </Grid>
            </div>
        );
    }

    return null;
};

const Chart = (props: { data: any; showCount: boolean; showMinMax: boolean; showTotalAmount: boolean }) => {
    const { data, showCount, showMinMax, showTotalAmount } = props;
    const theme: Theme = useTheme();

    return (
        <ResponsiveContainer width="100%" height="100%">
            <LineChart
                data={data && data.data ? data.data : []}
                margin={{
                    top: 10,
                    right: 30,
                    left: 0,
                    bottom: 85,
                }}
                syncId="onlineUsersChart"
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                    dataKey="time"
                    type="number"
                    scale={'time'}
                    name="Time"
                    tickFormatter={value => moment(value).format('ddd Do, hh a')}
                    fontSize={12}
                    domain={
                        data && data.data && data.data.length > 0
                            ? [data.data[0].time, data.data[data.data.length - 1].time]
                            : ['auto', 'auto']
                    }
                    interval={4}
                    angle={-90}
                    dy={50}
                />
                {showCount && (
                    <>
                        <YAxis
                            yAxisId="count"
                            dataKey={'count'}
                            scale={'linear'}
                            name="Count"
                            fontSize={12}
                            domain={[data && data.min ? data.min * 0.95 : 'auto', data && data.max ? data.max * 1.05 : 'auto']}
                            tickFormatter={(value: number) => value.toLocaleString()}
                        />
                        <Line yAxisId="count" type="monotone" dataKey="count" stroke={theme.palette.primary.main} activeDot={{ r: 8 }} />
                    </>
                )}
                {showTotalAmount && (
                    <>
                        <YAxis
                            yAxisId="totalAmount"
                            dataKey={'totalAmountCents'}
                            scale={'linear'}
                            name="Total Amount"
                            fontSize={12}
                            domain={['auto', 'auto']}
                            tickFormatter={(value: number) => dinero({ amount: value, currency: 'USD' }).toFormat()}
                            orientation="right"
                        />
                        <Line
                            yAxisId="totalAmount"
                            type="monotone"
                            dataKey="totalAmountCents"
                            stroke={theme.palette.secondary.main}
                            activeDot={{ r: 8 }}
                        />
                    </>
                )}
                <Tooltip content={<CustomTooltip payload={data && data.data ? data.data : null} theme={theme} />} />
                <Legend
                    y={80}
                    formatter={value => (value === 'count' ? 'Online Users Count' : 'Total Sales Amount')}
                    wrapperStyle={{ position: 'relative', top: -30 }}
                />
                {showCount && showMinMax && (
                    <>
                        <ReferenceLine
                            yAxisId="count"
                            y={data.max}
                            label={data && data.max && typeof data.max === 'number' ? `Maximum - ${data.max.toLocaleString()}` : ''}
                            stroke={theme.palette.success.dark}
                        />
                        <ReferenceLine
                            yAxisId="count"
                            y={data.min}
                            label={data && data.min && typeof data.min === 'number' ? `Minimum - ${data.min.toLocaleString()}` : ''}
                            stroke={theme.palette.error.main}
                        />
                        <ReferenceLine
                            yAxisId="count"
                            y={data.average}
                            label={
                                data && data.average && typeof data.average === 'number'
                                    ? `Averange - ${data.average.toLocaleString()}`
                                    : ''
                            }
                            stroke={theme.palette.warning.main}
                        />
                    </>
                )}
            </LineChart>
        </ResponsiveContainer>
    );
};

const generateData = (data: any[]) => {
    const result = {
        data: data.map(d => {
            return {
                time: moment(d._id).valueOf(),
                count: Math.round(d.count),
                totalAmountCents: d.totalAmount ? Math.round(d2f(d.totalAmount) * 100) : 0,
            };
        }),
        min: Math.round(Math.min(...data.map((d: any) => d.count))),
        max: Math.round(Math.max(...data.map((d: any) => d.count))),
        average: Math.round(data.reduce((a: any, b: any) => a + b.count, 0) / data.length),
    };

    return result;
};

type Props = {
    onlineUsersCountMain: any[] | null;
    onlineUsersCountCompare: any[] | null;
    compare: boolean;
    reportMainIsFreeRange: boolean;
    reportCompareIsFreeRange: boolean;
    isFetching: boolean;
};

const ReportAccountOnlineUsersCountVsTotalSales = (props: Props) => {
    const { onlineUsersCountMain, onlineUsersCountCompare, compare, reportMainIsFreeRange, reportCompareIsFreeRange, isFetching } = props;
    const theme: Theme = useTheme();
    const [dataMain, setDataMain] = useState<any>({ data: [], min: 0, max: 0, average: 0 });
    const [dataCompare, setDataCompare] = useState<any>({ data: [], min: 0, max: 0, average: 0 });
    const [showMinMax, setShowMinMax] = useState<boolean>(false);
    const [showCount, setShowCount] = useState<boolean>(true);
    const [showTotalAmount, setShowTotalAmount] = useState<boolean>(true);
    const [graphType, setGraphType] = useState<'line' | 'heatmap'>('line');

    useEffect(() => {
        if (onlineUsersCountMain) {
            setDataMain(generateData(onlineUsersCountMain));
        } else {
            setDataMain([]);
        }

        if (compare && onlineUsersCountCompare) {
            setDataCompare(generateData(onlineUsersCountCompare));
        } else {
            setDataCompare([]);
        }
    }, [compare, theme, onlineUsersCountMain, onlineUsersCountCompare]);

    const onlineHeatMapDataMain = useMemo(() => {
        if (!onlineUsersCountMain) {
            return [];
        }

        const groupedByDay = groupByDay(onlineUsersCountMain as { _id: string; count: number }[]);

        const refactoredData: { time: number; count: number }[] = [];

        for (let i = 0; i < groupedByDay.length; i++) {
            const day = groupedByDay[i];

            for (let j = 0; j < day.length; j++) {
                const hour = day[j];

                refactoredData.push({
                    time: moment(hour._id).valueOf(),
                    count: Math.round(hour.count),
                });
            }
        }

        // Remove members from refactoredData from the front and the end that have 0 count
        while (refactoredData.length > 0 && refactoredData[0].count === 0) {
            refactoredData.shift();
        }

        while (refactoredData.length > 0 && refactoredData[refactoredData.length - 1].count === 0) {
            refactoredData.pop();
        }

        return refactoredData;
    }, [onlineUsersCountMain]);

    const onlineHeatMapDataCompare = useMemo(() => {
        if (!onlineUsersCountCompare) {
            return [];
        }

        const groupedByDay = groupByDay(onlineUsersCountCompare as { _id: string; count: number }[]);

        const refactoredData: { time: number; count: number }[] = [];

        for (let i = 0; i < groupedByDay.length; i++) {
            const day = groupedByDay[i];

            for (let j = 0; j < day.length; j++) {
                const hour = day[j];

                refactoredData.push({
                    time: moment(hour._id).valueOf(),
                    count: Math.round(hour.count),
                });
            }
        }

        // Remove members from refactoredData from the front and the end that have 0 count
        while (refactoredData.length > 0 && refactoredData[0].count === 0) {
            refactoredData.shift();
        }

        while (refactoredData.length > 0 && refactoredData[refactoredData.length - 1].count === 0) {
            refactoredData.pop();
        }

        return refactoredData;
    }, [onlineUsersCountCompare]);

    return (
        <Card variant="elevation" sx={{ marginBottom: theme.spacing(4) }}>
            <CardContent>
                <Stack direction="row" spacing={1} alignItems="center" sx={{ marginBottom: theme.spacing(2) }}>
                    <Typography variant="h6">Online Users Count vs. Total Sales</Typography>
                    <ToggleGraphType graphType={graphType} setGraphType={setGraphType} />
                </Stack>
                <Typography variant="body1" sx={{ marginBottom: theme.spacing(2) }}>
                    This reports shows how many users were online on average each hour, and how much sales were generated in total for each
                    hour. The date and time are translated to your local time zone. Use this report to find the best to stay online and
                    reach out to fans, and when it's worth taking a break.
                </Typography>

                <Grid container flexGrow={1} alignItems="center" sx={{ marginBottom: theme.spacing(2) }}>
                    <Grid item xs={'auto'}>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={showCount}
                                        onClick={() => {
                                            setShowCount(!showCount);
                                        }}
                                    />
                                }
                                label="Show online users count"
                            />
                        </FormGroup>
                    </Grid>
                    <Grid item xs={'auto'}>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={showTotalAmount}
                                        onClick={() => {
                                            setShowTotalAmount(!showTotalAmount);
                                        }}
                                    />
                                }
                                label="Show total amount"
                            />
                        </FormGroup>
                    </Grid>
                    <Grid item xs={'auto'}>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={showMinMax}
                                        onClick={() => {
                                            setShowMinMax(!showMinMax);
                                        }}
                                    />
                                }
                                label="Show min/max/average lines"
                            />
                        </FormGroup>
                    </Grid>
                </Grid>

                <Grid container flexGrow={1} sx={{ paddingBottom: theme.spacing(1) }}>
                    <Grid item xs={12} sx={{ height: 400 }}>
                        {reportMainIsFreeRange ? (
                            <Typography variant="body1" textAlign="center" color={theme.palette.warning.main}>
                                This report is only available when searching by week
                            </Typography>
                        ) : isFetching ? (
                            <Skeleton />
                        ) : (
                            <>
                                {graphType === 'line' ? (
                                    <Chart
                                        data={dataMain}
                                        showCount={showCount}
                                        showMinMax={showMinMax}
                                        showTotalAmount={showTotalAmount}
                                    />
                                ) : (
                                    <OnlineCountHeatMapGraph data={onlineHeatMapDataMain} />
                                )}
                            </>
                        )}
                        {compare && (
                            <Typography variant="subtitle1" textAlign="center" color={theme.palette.text.secondary}>
                                Main Search
                            </Typography>
                        )}
                    </Grid>
                    {compare && dataCompare && dataCompare.data && dataCompare.data.length > 0 && (
                        <Grid item xs={12} sx={{ height: 400, marginTop: theme.spacing(4) }}>
                            {reportCompareIsFreeRange ? (
                                <Typography variant="body1" textAlign="center" color={theme.palette.warning.main}>
                                    This report is only available when searching by week
                                </Typography>
                            ) : isFetching ? (
                                <Skeleton />
                            ) : (
                                <>
                                    {graphType === 'line' ? (
                                        <Chart
                                            data={dataCompare}
                                            showCount={showCount}
                                            showMinMax={showMinMax}
                                            showTotalAmount={showTotalAmount}
                                        />
                                    ) : (
                                        <OnlineCountHeatMapGraph data={onlineHeatMapDataCompare} />
                                    )}
                                </>
                            )}
                            {compare && (
                                <Typography variant="subtitle1" textAlign="center" color={theme.palette.text.secondary}>
                                    Comparisson Search
                                </Typography>
                            )}
                        </Grid>
                    )}
                </Grid>
            </CardContent>
        </Card>
    );
};

export default ReportAccountOnlineUsersCountVsTotalSales;
