import React, { useContext, useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {
    Grid,
    Avatar,
    Button,
    Card,
    CardContent,
    CardHeader,
    Container,
    Typography,
    FormControlLabel,
    Backdrop,
    CircularProgress,
} from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';
import { Theme } from '@mui/system';
import Editor from '@monaco-editor/react';
import { UserContext } from '../../store/UserContext';
import { SettingsContext } from '../../store/SettingsContext';
import { OnlyFansSubscriber } from '../../../../src/libs/onlyFans/onlyFansDbTypes';
import ErrorMessage from '../../components/ErrorMessage';
import FormikTextField from '../../components/FormikTextField';
import FormikSelect from '../../components/FormikSelect';
import FormikDateTime from '../../components/FormikDateTime';
import FormikCheckbox from '../../components/FormikCheckBox';
import { followBackFilterInterface } from './AdminSubscriberEdit.Interfaces';
import ConfirmationDialog from '../../components/ConfirmationDialog';
import AdminSubscriberEditScheduledPost from '../../components/admin/AdminSubscriberEditScheduledPost';
import AdminSubscriberEditUnblockUsers from '../../components/admin/AdminSubscriberEditUnblockUsers';
import AdminSubscriberEditBlockUsers from '../../components/admin/AdminSubscriberEditBlockUsers';
import AdminSubscriberEditRenameUsers from '../../components/admin/AdminSubscriberEditRenameUsers';
import AdminSubscriberHousekeepingUsers from '../../components/admin/AdminSubscriberEditHousekeeping';

const validationSchema = yup.object({
    username: yup.string().required('Username is required'),
    contactEmail: yup.string().email('Enter a valid email').required('Email is required'),
    email: yup.string().email('Enter a valid email').required('Email is required'),
    password: yup.string().required('Password is required'),
    realName: yup.string(),
    telegramChatId: yup.string().required('Telegram Chat ID is required'),
    notes: yup.string(),
    header: yup.object().shape({
        'User-Agent': yup.string().required('User-Agent string is required'),
        Cookie: yup.string().required('Cookie string is required'),
        'x-bc': yup.string().required('x-bc string is required'),
    }),
    paymentMethod: yup.string(),
    active: yup.boolean().default(false),
    paidDate: yup.date(),
    followBackLimit: yup.number().default(-1),
    followFansActive: yup.boolean().default(true),
    ignoreCreators: yup.boolean().default(false),
    followBackFilter: yup.object().nullable().default({}),
    housekeeping: yup
        .object()
        .notRequired()
        .shape({
            active: yup.boolean().default(false),
            paidDate: yup.date().default(null).nullable(),
            addPrefixToUserDisplayName: yup.boolean().default(false).notRequired(),
            userDisplayNamePrefix: yup.string().notRequired(),
            lists: yup.array().default([]),
        }),
    renameUsers: yup
        .object()
        .notRequired()
        .shape({
            active: yup.boolean().default(false),
            paidDate: yup.date(),
        }),
    blockUsers: yup
        .object()
        .notRequired()
        .shape({
            active: yup.boolean().default(false),
            paidDate: yup.date().default(null).nullable(),
            targetListName: yup.string(),
            blockUsersLimit: yup.number().default(-1),
        }),
    unblockUsers: yup
        .object()
        .notRequired()
        .shape({
            active: yup.boolean().default(false),
            paidDate: yup.date().default(null).nullable(),
            followUsers: yup.boolean().default(false),
        }),
    scheduledPosts: yup
        .object()
        .notRequired()
        .shape({
            active: yup.boolean().default(false),
            paidDate: yup.date().default(null).nullable(),
            sourceVaultAlbum: yup.string(),
            postImages: yup.boolean().default(false),
            postVideos: yup.boolean().default(false),
        }),
    lastFollowBackDate: yup.date().notRequired(),
    lastHousekeepingDate: yup.date().notRequired(),
    lastUnblockUsersDate: yup.date().notRequired(),
    lastBlockUsersDate: yup.date().notRequired(),
    lastDeletePostsDate: yup.date().notRequired(),
    lastSendUserPromoDate: yup.date().notRequired(),
    onlyFansEarnings: yup.object().notRequired(),
});

const AdminSubscriberEdit = () => {
    const userContext = useContext(UserContext);
    const settingsContext = useContext(SettingsContext);
    const theme: Theme = useTheme();
    const params = useParams();
    const navigate = useNavigate();
    const [error, setError] = useState<string | null>(null);
    const refError = useRef<HTMLDivElement | null>(null);
    const [showBackdrop, setShowBackdrop] = useState<boolean>(false);
    const [showConfirmationDialog, setShowConfirmationDialog] = useState<boolean>(false);
    const [subscriber, setSubscriber] = useState<OnlyFansSubscriber | null>(null);

    // Show/Hide Sections
    const [showServiceHousekeeping, setShowServiceHousekeeping] = useState<boolean>(true);
    const [showServiceRenameUsers, setShowServiceRenameUsers] = useState<boolean>(true);
    const [showServiceBlockUsers, setShowServiceBlockUsers] = useState<boolean>(true);
    const [showServiceUnblockUsers, setShowServiceUnblockUsers] = useState<boolean>(true);
    const [showServiceScheduledPosts, setShowServiceScheduledPosts] = useState<boolean>(true);

    const formik = useFormik({
        initialValues: subscriber ? subscriber : ({} as OnlyFansSubscriber),
        enableReinitialize: true,
        validationSchema: validationSchema,
        onSubmit: values => {
            console.log(values);

            setShowBackdrop(true);

            const isUpdateSubscriber: boolean = 'userId' in params;
            let query: string = `${settingsContext.routes.subscribers.find}${isUpdateSubscriber ? params.userId : ''}`;

            console.log(isUpdateSubscriber);
            fetch(query, {
                method: isUpdateSubscriber ? 'put' : 'post',
                headers: {
                    Authorization: userContext.jwtToken,
                    apiKey: settingsContext.apiKey,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(values),
            })
                .then(async response => {
                    if (response.ok) {
                        setError(null);

                        return response.json();
                    } else {
                        const error = await response.json();
                        const errorMessage: string = `${response.status} - ${error.message} ${
                            'extra' in error ? `(${error.extra})` : null
                        }`;

                        setError(errorMessage);
                        refError.current?.scrollIntoView();

                        return Promise.reject(errorMessage);
                    }
                })
                .then(data => {
                    if (!isUpdateSubscriber && '_id' in data && data._id) {
                        navigate(`/subscribers/${data._id}/edit`);
                    }

                    setSubscriber(data);
                    setShowBackdrop(false);
                })
                .catch(error => {
                    setShowBackdrop(false);
                    console.error(error);
                });
        },
    });

    useEffect(() => {
        // Fetch users count
        if (userContext.jwtToken && settingsContext.apiKey && 'userId' in params && params.userId) {
            setShowBackdrop(true);

            let query: string = `${settingsContext.routes.subscribers.find}${params.userId}`;

            fetch(query, {
                method: 'get',
                headers: {
                    Authorization: userContext.jwtToken,
                    apiKey: settingsContext.apiKey,
                },
            })
                .then(async response => {
                    if (response.ok) {
                        setError(null);

                        return response.json();
                    } else {
                        const error = await response.json();

                        setError(`${response.status} - ${error.message} ${'extra' in error ? `(${error.extra})` : null}`);

                        if (refError.current) {
                            refError.current.scrollIntoView();
                        }
                    }
                })
                .then(data => {
                    setSubscriber(data);
                    setShowBackdrop(false);
                })
                .catch(error => {
                    console.error(error);
                    setShowBackdrop(false);
                });
        } else {
            setShowServiceHousekeeping(false);
            setShowServiceRenameUsers(false);
            setShowServiceBlockUsers(false);
            setShowServiceUnblockUsers(false);
            setShowServiceScheduledPosts(false);
        }
    }, [userContext, settingsContext, params]);

    useEffect(() => {
        if (subscriber) {
            setShowServiceHousekeeping('housekeeping' in subscriber);
            setShowServiceRenameUsers('renameUsers' in subscriber);
            setShowServiceBlockUsers('blockUsers' in subscriber);
            setShowServiceUnblockUsers('unblockUsers' in subscriber);
            setShowServiceScheduledPosts('scheduledPosts' in subscriber);
        }
    }, [subscriber]);

    return (
        <Container maxWidth={'xl'} sx={{ paddingTop: theme.spacing(4), backgroundColor: '#e7ebf0' }}>
            <ErrorMessage error={error} refProp={refError} />
            {subscriber && '_id' in subscriber && subscriber._id && (
                <Card variant="elevation" sx={{ marginBottom: theme.spacing(4) }}>
                    <CardHeader
                        avatar={<Avatar>{subscriber.username.charAt(0).toUpperCase()}</Avatar>}
                        title={subscriber.username}
                        subheader={subscriber._id}
                    />
                </Card>
            )}

            <form
                onSubmit={e => {
                    e.preventDefault();
                    setShowConfirmationDialog(true);
                }}
            >
                <Card variant="elevation" sx={{ marginBottom: theme.spacing(4) }}>
                    <CardContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="h6">Account</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <FormikTextField fieldName="username" label="Username" formik={formik} />
                            </Grid>
                            <Grid item xs={12}>
                                <FormikTextField fieldName="contactEmail" label="Contact Email" formik={formik} />
                            </Grid>
                            <Grid item xs={12}>
                                <FormikTextField fieldName="email" label="Email" formik={formik} />
                            </Grid>
                            <Grid item xs={12}>
                                <FormikTextField fieldName="password" label="Password" formik={formik} />
                            </Grid>
                            <Grid item xs={12}>
                                <FormikTextField fieldName="realName" label="Real Name" formik={formik} />
                            </Grid>
                            <Grid item xs={12}>
                                <FormikTextField fieldName="notes" label="Notes" formik={formik} multiline={true} />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h6">Telegram</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <FormikTextField fieldName="telegramChatId" label="Telegram Chat ID" formik={formik} />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h6">Header</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <FormikTextField fieldName="header.User-Agent" label="User-Agent" formik={formik} />
                            </Grid>
                            <Grid item xs={12}>
                                <FormikTextField fieldName="header.Cookie" label="Cookie" formik={formik} />
                            </Grid>
                            <Grid item xs={12}>
                                <FormikTextField fieldName="header.x-bc" label="x-bc" formik={formik} />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h6">Payment</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <FormikSelect
                                    fieldName="paymentMethod"
                                    label="Payment Method"
                                    formik={formik}
                                    options={['PayPal', 'Bank Transfer', 'Bank Transfer (Subscription)', 'Stripe', 'Stripe (Subscription)']}
                                />
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>

                <Card variant="elevation" sx={{ marginBottom: theme.spacing(4) }}>
                    <CardContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="h6">Follow-Back</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <FormikCheckbox formik={formik} fieldName="active" label="Active" />
                            </Grid>
                            <Grid item xs={12}>
                                <FormikDateTime formik={formik} fieldName="paidDate" label="Date Paid" />
                            </Grid>
                            <Grid item xs={12}>
                                <FormikTextField formik={formik} fieldName="followBackLimit" label="Follow-Back Limit" />
                            </Grid>
                            <Grid item xs={12}>
                                <FormikCheckbox formik={formik} fieldName="followFansActive" label="Follow Active Fans?" />
                            </Grid>
                            <Grid item xs={12}>
                                <FormikCheckbox formik={formik} fieldName="ignoreCreators" label="Ignore Creators?" />
                            </Grid>
                            <Grid item xs={6}>
                                <FormControlLabel
                                    label="Follow-Back Filter"
                                    labelPlacement="top"
                                    control={
                                        <Editor
                                            height="250px"
                                            width="100%"
                                            language="json"
                                            defaultValue={
                                                formik.values.followBack && JSON.stringify(formik.values.followBack.followBackFilter)
                                            }
                                            options={{
                                                autoIndent: 'full',
                                                formatOnPaste: true,
                                                minimap: { enabled: false },
                                            }}
                                            theme="vs-dark"
                                            onMount={(editor, context) => {
                                                setTimeout(() => {
                                                    editor.getAction('editor.action.formatDocument').run();
                                                }, 1000);
                                            }}
                                            onChange={(value, ev) => {
                                                if (value) {
                                                    try {
                                                        const json = JSON.parse(value);

                                                        formik.setFieldValue('followBackFilter', json);
                                                    } catch (error) {
                                                        // JSON isn't valid
                                                    }
                                                }
                                            }}
                                        />
                                    }
                                    sx={{ margin: 0, alignItems: 'flex-start', width: '100%' }}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormControlLabel
                                    label="Interface"
                                    labelPlacement="top"
                                    control={
                                        <Editor
                                            height="250px"
                                            defaultLanguage="typescript"
                                            options={{
                                                autoIndent: 'full',
                                                formatOnPaste: true,
                                                minimap: { enabled: false },
                                            }}
                                            value={followBackFilterInterface}
                                            theme="vs-dark"
                                            onMount={(editor, context) => {
                                                setTimeout(() => {
                                                    editor.getAction('editor.action.formatDocument').run();
                                                }, 1000);
                                            }}
                                        />
                                    }
                                    sx={{ margin: 0, alignItems: 'flex-start', width: '100%' }}
                                />
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>

                <AdminSubscriberHousekeepingUsers
                    formik={formik}
                    theme={theme}
                    setShowServiceHousekeeping={setShowServiceHousekeeping}
                    showServiceHousekeeping={showServiceHousekeeping}
                />

                <AdminSubscriberEditRenameUsers
                    formik={formik}
                    theme={theme}
                    setShowServiceRenameUsers={setShowServiceRenameUsers}
                    showServiceRenameUsers={showServiceRenameUsers}
                />

                <AdminSubscriberEditBlockUsers
                    formik={formik}
                    theme={theme}
                    setShowServiceBlockUsers={setShowServiceBlockUsers}
                    showServiceBlockUsers={showServiceBlockUsers}
                />

                <AdminSubscriberEditUnblockUsers
                    formik={formik}
                    theme={theme}
                    setShowServiceUnblockUsers={setShowServiceUnblockUsers}
                    showServiceUnblockUsers={showServiceUnblockUsers}
                />

                <AdminSubscriberEditScheduledPost
                    formik={formik}
                    theme={theme}
                    setShowServiceScheduledPosts={setShowServiceScheduledPosts}
                    showServiceScheduledPosts={showServiceScheduledPosts}
                />

                <Button color="primary" variant="contained" fullWidth type="submit" sx={{ marginBottom: theme.spacing(4) }}>
                    Submit
                </Button>

                <ConfirmationDialog
                    open={showConfirmationDialog}
                    setOpen={setShowConfirmationDialog}
                    onConfirmation={formik.handleSubmit}
                    title={'userId' in params ? 'Update Subscriber' : 'Add Subscriber'}
                    message={'userId' in params ? 'Are you sure? Existing record will be overwritten' : 'Save new subscriber?'}
                />

                <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }} open={showBackdrop}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </form>
        </Container>
    );
};

export default AdminSubscriberEdit;
