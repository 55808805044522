import LoadingButton from '@mui/lab/LoadingButton';
import {
    AppBar,
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    Grid,
    Stack,
    Tab,
    Tabs,
    TextField,
    Theme,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { useDialog } from 'muibox';
import { useEffect, useState } from 'react';
import useSextforceMetricsGroups, {
    OnlyFansMetricsGroup,
    OnlyFansMetricsGroupWithSummary,
} from '../../../../hooks/useSextforceMetricsGroups';
import useSubscribers from '../../../../hooks/useSubscribers';
import SextforceMetricsGroupDialogSubscriberTab from './SextforceMetricsGroupDialogSubscriberTab';

interface TabPanelProps {
    children?: React.ReactNode;
    dir?: string;
    index: number;
    value: number;
    theme: Theme;
}

const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, theme, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box
                    component={'div'}
                    sx={{
                        p: 2,
                        pl: 0,
                        pr: 0,
                        bgcolor: 'grey.100',
                        borderBottomColor: theme.palette.primary.main,
                        borderBottomStyle: 'solid',
                        borderBottomWidth: 1,
                    }}
                >
                    <span>{children}</span>
                </Box>
            )}
        </div>
    );
};

function a11yProps(index: number) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

type Props = {
    open: boolean;
    onClose: () => void;
    subscriber: any | null;
    metricType: 'trial' | 'campaign';
    metricsGroup: OnlyFansMetricsGroupWithSummary | null;
};

const SextforceMetricsGroupDialog = (props: Props) => {
    const { open, onClose, subscriber, metricType, metricsGroup } = props;
    const dialog = useDialog();
    const theme: Theme = useTheme();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'));

    // Subscribers
    const { data: subscribers, isLoading: isSubscribersLoading } = useSubscribers();
    const [sortedSubscribers, setSortedSubscribers] = useState<any[]>([]);

    // Settings
    const [includeCampaigns, setIncludeCampaigns] = useState<boolean>(false);
    const [includeTrials, setIncludeTrials] = useState<boolean>(false);
    const [includeInactive, setIncludeInactive] = useState<boolean>(false);

    // Tab
    const [tabValue, setTabValue] = useState<number>(0);

    // Selection
    const [groupName, setGroupName] = useState<string | undefined | null>();
    const [groupedMetrics, setGroupedMetrics] = useState<{
        [subscriberId: string]: string[];
    }>({});
    const { metricsGroupCreate, metricsGroupUpdate, isGroupSaving } = useSextforceMetricsGroups(metricType);

    useEffect(() => {
        if (!open) {
            setTabValue(0);
            setGroupedMetrics({});
            setGroupName(undefined);
        }
    }, [open]);

    useEffect(() => {
        if (metricsGroup) {
            const metricIds: { [subscriberId: string]: string[] } = {};

            metricsGroup.metrics.forEach(metric => {
                if (!metricIds[metric.subscriberId]) {
                    metricIds[metric.subscriberId] = [];
                }

                metricIds[metric.subscriberId].push(metric._id!);
            });

            setGroupedMetrics(metricIds);

            setGroupName(metricsGroup.name);
            setIncludeCampaigns(metricsGroup.includesCampaigns || metricType === 'campaign');
            setIncludeTrials(metricsGroup.includesTrials || metricType === 'trial');
            setIncludeInactive(metricsGroup.includesInactive);
        } else {
            setGroupName(undefined);
            setIncludeCampaigns(false);
            setIncludeTrials(false);
            setIncludeInactive(false);
        }
    }, [metricType, metricsGroup]);

    useEffect(() => {
        if (subscribers && Array.isArray(subscribers) && subscriber && subscriber._id) {
            const filteredSubscribers = subscribers.filter((sub: any) => sub._id !== subscriber._id);

            setSortedSubscribers(filteredSubscribers.slice().sort((a, b) => a.username.localeCompare(b.username)));

            // Prepare grouped metrics
            const groupedMetrics: {
                [subscriberId: string]: string[];
            } = {};

            groupedMetrics[subscriber._id] = [];

            filteredSubscribers.forEach(sub => {
                groupedMetrics[sub._id] = [];
            });

            setGroupedMetrics(groupedMetrics);
        } else {
            setSortedSubscribers([]);

            if (subscriber && subscriber._id) {
                // Prepare grouped metrics
                setGroupedMetrics({
                    [subscriber._id]: [],
                });
            } else {
                setGroupedMetrics({});
            }
        }
    }, [subscriber, subscribers]);

    useEffect(() => {
        setIncludeCampaigns(metricType === 'campaign');
        setIncludeTrials(metricType === 'trial');
        setIncludeInactive(false);
    }, [metricType]);

    const handleClose = (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => {
        if (reason && reason === 'backdropClick') {
            return;
        }

        onClose();
    };

    const handleSave = async () => {
        const newGroup: OnlyFansMetricsGroup = {
            subscriberId: subscriber._id,
            name: groupName || '',
            includesCampaigns: includeCampaigns,
            includesTrials: includeTrials,
            includesInactive: includeInactive,
            metricIds: Object.values(groupedMetrics).reduce((acc, val) => acc.concat(val), []),
        };

        if (metricsGroup) {
            newGroup._id = metricsGroup._id;

            dialog
                .confirm({
                    title: 'Update Group',
                    message: 'Are you sure you want to update this group?',
                })
                .then(() => {
                    metricsGroupUpdate(newGroup).then(() => {
                        onClose();
                    });
                })
                .catch(() => {});
        } else {
            metricsGroupCreate(newGroup).then(() => {
                onClose();
            });
        }
    };

    return (
        <>
            {subscriber ? (
                <Dialog open={open} onClose={handleClose} disableEscapeKeyDown fullWidth maxWidth="sm">
                    <DialogTitle>Group Stats</DialogTitle>
                    <Box
                        component={'div'}
                        sx={{
                            pl: 3,
                            pr: 3,
                        }}
                    >
                        <Stack direction="column" spacing={1}>
                            <Typography variant="body1">
                                Select the promos to group to show their earnings together. You can select any tracking or trial link from
                                any of the accounts on your dashboard.
                            </Typography>
                            <FormControl fullWidth>
                                <TextField
                                    id="group-name"
                                    value={groupName}
                                    onChange={event => {
                                        setGroupName(event.target.value);
                                    }}
                                    label="Group Name"
                                    variant="outlined"
                                    size="small"
                                    error={!groupName || groupName.trim().length === 0}
                                />
                            </FormControl>

                            <Grid container spacing={1} alignItems="center" justifyContent={isLargeScreen ? 'space-between' : 'flex-start'}>
                                <Grid item xs={12} md="auto">
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                id="includeCampaigns"
                                                checked={includeCampaigns}
                                                onChange={event => {
                                                    setIncludeCampaigns(event.target.checked);
                                                }}
                                            />
                                        }
                                        label="Include Tracking Links"
                                    />
                                </Grid>
                                <Grid item xs={12} md="auto">
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                id="includeTrials"
                                                checked={includeTrials}
                                                onChange={event => {
                                                    setIncludeTrials(event.target.checked);
                                                }}
                                            />
                                        }
                                        label="Include Trials"
                                    />
                                </Grid>
                                <Grid item xs={12} md="auto">
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                id="includeInactive"
                                                checked={includeInactive}
                                                onChange={event => {
                                                    setIncludeInactive(event.target.checked);
                                                }}
                                            />
                                        }
                                        label="Include Inactive"
                                    />
                                </Grid>
                            </Grid>
                        </Stack>
                    </Box>

                    <DialogContent
                        sx={{
                            pl: 0,
                            pr: 0,
                            overflow: 'hidden',
                            pb: 0,
                        }}
                    >
                        <AppBar position="static">
                            <Tabs
                                value={tabValue}
                                onChange={(event: React.SyntheticEvent, newValue: number) => {
                                    setTabValue(newValue);
                                }}
                                indicatorColor="secondary"
                                textColor="inherit"
                                variant="scrollable"
                                scrollButtons="auto"
                            >
                                <Tab
                                    label={`${subscriber.username} (${groupedMetrics[subscriber._id]?.length.toLocaleString()})`}
                                    {...a11yProps(0)}
                                />
                                {sortedSubscribers.map((sub: any, index) => (
                                    <Tab
                                        key={sub._id}
                                        label={`${sub.username} (${groupedMetrics[sub._id]?.length.toLocaleString() || 0})`}
                                        {...a11yProps(index + 1)}
                                    />
                                ))}
                            </Tabs>
                        </AppBar>
                        <TabPanel value={tabValue} index={0} dir={theme.direction} theme={theme}>
                            <SextforceMetricsGroupDialogSubscriberTab
                                targetSubscriberId={subscriber._id}
                                includeCampaigns={includeCampaigns}
                                includeTrials={includeTrials}
                                includeInactive={includeInactive}
                                groupedMetrics={groupedMetrics}
                                setGroupedMetrics={setGroupedMetrics}
                                theme={theme}
                            />
                        </TabPanel>
                        {sortedSubscribers.map((sub: any, index) => (
                            <TabPanel key={sub._id} value={tabValue} index={index + 1} dir={theme.direction} theme={theme}>
                                <SextforceMetricsGroupDialogSubscriberTab
                                    targetSubscriberId={sub._id}
                                    includeCampaigns={includeCampaigns}
                                    includeTrials={includeTrials}
                                    includeInactive={includeInactive}
                                    groupedMetrics={groupedMetrics}
                                    setGroupedMetrics={setGroupedMetrics}
                                    theme={theme}
                                />
                            </TabPanel>
                        ))}
                    </DialogContent>

                    <DialogActions>
                        <Button
                            color="secondary"
                            size="medium"
                            disabled={isSubscribersLoading}
                            onClick={() => {
                                handleClose({}, 'escapeKeyDown');
                            }}
                        >
                            Close
                        </Button>
                        <LoadingButton
                            color="primary"
                            size="medium"
                            loading={isSubscribersLoading || isGroupSaving}
                            disabled={!groupName || groupName.trim().length === 0}
                            onClick={handleSave}
                        >
                            {metricsGroup ? 'Update' : 'Create'}
                        </LoadingButton>
                    </DialogActions>
                </Dialog>
            ) : (
                <Dialog open={open} onClose={handleClose}>
                    No Data
                    <DialogActions>
                        <Button
                            size="medium"
                            onClick={() => {
                                handleClose({}, 'escapeKeyDown');
                            }}
                        >
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </>
    );
};

export default SextforceMetricsGroupDialog;
