import { Card, Grid, Skeleton, Typography } from '@mui/material';
import { SxProps, Theme } from '@mui/system';

const earningsGoalCellBackgroundStyle = (theme: Theme, percent: number) => {
    if (percent === 0) {
        return theme.palette.common.white;
    }

    const color = percent === 100 ? theme.palette.success.light : theme.palette.warning.light;

    return `-webkit-linear-gradient(left, ${color} 0%, ${color} ${percent}%, white ${percent}%); -moz-linear-gradient(left, ${color} 0%, ${color} ${percent}%, white ${percent}%); -ms-linear-gradient(left, ${color} 0%, ${color} ${percent}%, white ${percent}%); linear-gradient(left, ${color} 0%, ${color} ${percent}%, white ${percent}%);`;
};

// Output the right value based on it being a number, string, or ReactNode
const Value = (props: { value: number | string | React.ReactNode }) => {
    const { value } = props;

    return (
        <>
            {typeof value === 'number' || typeof value === 'string'
                ? `${typeof value === 'number' ? value.toLocaleString() : value}`
                : value
                ? value
                : 0}
        </>
    );
};

type OverviewCardProps = {
    title: string | React.ReactNode;
    value: number | string | React.ReactNode;
    showOutOfSymbol?: boolean;
    maxValue?: number | string | React.ReactNode;
    loading: boolean;
    icon?: React.ReactNode;
    theme: Theme;
    goal?: number;
    sx?: SxProps<Theme>;
};

const OverviewCard = (props: OverviewCardProps) => {
    const { title, value, showOutOfSymbol, maxValue, loading, icon, goal, theme, sx } = props;
    let percent: number = 0;

    if (typeof value === 'number' && goal !== undefined) {
        percent = value && value !== 0 && goal !== 0 ? (value / goal) * 100 : 0;

        if (percent > 100) {
            percent = 100;
        }
    }

    return (
        <Card
            variant="elevation"
            sx={{
                marginBottom: theme.spacing(0),
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                padding: '16px',
                ...(goal !== undefined && {
                    background: earningsGoalCellBackgroundStyle(theme, percent),
                }),
                ...(sx || {}),
            }}
        >
            <Grid container flexGrow={1} alignItems="center" spacing={2}>
                {icon && (
                    <Grid item xs={'auto'}>
                        {icon}
                    </Grid>
                )}
                <Grid item xs>
                    <Grid container flexGrow={1} alignItems="center" spacing={2}>
                        <Grid item xs={12}>
                            <Typography variant="subtitle1" align="right">
                                {title}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography
                                variant="h5"
                                align="right"
                                {...((!value || typeof value === 'number') && { fontFamily: 'monospace' })}
                            >
                                {loading ? <Skeleton /> : <Value value={value} />}
                                {!loading && maxValue !== undefined && (
                                    <>
                                        {typeof showOutOfSymbol === 'undefined' || showOutOfSymbol ? ' / ' : ' '}
                                        <Value value={maxValue} />
                                    </>
                                )}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Card>
    );
};

export default OverviewCard;
