import { Grid, Typography } from '@mui/material';
import SelectTimezone from '../forms/helpers/SelectTimezone';

type Props = {
    timezone: string;
    setTimezone: (timezone: string) => void;
};

const ProfileDialogAccount = (props: Props) => {
    const { timezone, setTimezone } = props;

    return (
        <Grid container spacing={1} alignItems="center">
            <Grid item xs={12}>
                <Typography variant="h6">Account</Typography>
            </Grid>
            <Grid item xs={12}>
                Select your default time zone. This will be used as the default time zone for Sextforce reports and other OnlyStruggles
                functions.
            </Grid>
            <Grid item xs={12}>
                <SelectTimezone timezone={timezone} setTimezone={setTimezone} fullWidth />
            </Grid>
        </Grid>
    );
};

export default ProfileDialogAccount;
