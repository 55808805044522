import AccountBoxIcon from '@mui/icons-material/AccountBox';
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    FormControl,
    Grid,
    IconButton,
    MenuItem,
    Select,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Theme,
    Tooltip,
    Typography,
} from '@mui/material';
import dinero from 'dinero.js';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import useSextforceAgents from '../../../../../hooks/useSextforceAgents';
import { SextforceLiveStream } from '../../../../../hooks/useSextforceLiveStreamAddTippersToList';
import useSextforceLiveStreamAddTippersToListTippers, {
    SextforceLiveStreamTipper,
} from '../../../../../hooks/useSextforceLiveStreamAddTippersToListTippers';
import { d2f } from '../../../../../utils/common';
import StyledCardTitleBar from '../../../../common/StyledCardTitleBar';

type Props = {
    subscriber: any;
    liveStream: SextforceLiveStream;
    open: boolean;
    onClose: () => void;
    theme: Theme;
};

const SextforceLiveStreamAddTippersToListTippersListDialog = (props: Props) => {
    const { subscriber, liveStream, open, onClose, theme } = props;

    const { data: liveStreamTippers, isLoading: isLiveStreamTippersLoading } = useSextforceLiveStreamAddTippersToListTippers(
        liveStream.streamId,
    );
    const [filteredLiveStreamTippers, setFilteredLiveStreamTippers] = useState<SextforceLiveStreamTipper[]>([]);

    const [filterFraction, setFilterFraction] = useState<number>(-1);
    const { data: agents, isLoading: isAgentsLoading } = useSextforceAgents(subscriber._id);

    useEffect(() => {
        if (liveStreamTippers) {
            if (filterFraction === -1) {
                setFilteredLiveStreamTippers(liveStreamTippers);
            } else {
                setFilteredLiveStreamTippers(
                    liveStreamTippers.filter(liveStreamTipper => {
                        // Get the .xx part of the amount
                        const amountFraction = liveStreamTipper.fraction
                            ? d2f(liveStreamTipper.fraction)
                            : liveStreamTipper.amount - Math.floor(liveStreamTipper.amount);

                        return amountFraction === filterFraction;
                    }),
                );
            }
        } else {
            setFilteredLiveStreamTippers([]);
        }
    }, [filterFraction, liveStreamTippers]);

    const handleClose = (event: {}, reason: 'backdropClick' | 'escapeKeyDown') => {
        if (reason && reason === 'backdropClick') {
            return;
        }

        onClose();
    };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth disableEscapeKeyDown scroll="paper">
            <StyledCardTitleBar
                title={`Live Stream Tippers${
                    !isLiveStreamTippersLoading && liveStreamTippers && ` (${liveStreamTippers.length.toLocaleString()})`
                }`}
                theme={theme}
            />
            <DialogContent>
                <DialogContentText>
                    <Stack direction={'column'} spacing={2}>
                        <div>
                            The following users have tipped the during the <strong>{liveStream.title || liveStream.streamId}</strong> live
                            stream.
                        </div>
                        <Stack direction={'row'} spacing={1} alignItems="center" justifyContent={'flex-start'}>
                            <Typography variant="body1">Filter by Sextforce Agent:</Typography>
                            <FormControl variant="outlined">
                                <Select
                                    value={filterFraction}
                                    onChange={event => {
                                        setFilterFraction(event.target.value as number);
                                    }}
                                    size="small"
                                >
                                    <MenuItem value={-1}>All</MenuItem>
                                    <MenuItem disabled>──────────</MenuItem>
                                    <MenuItem value={0}>Anonymous (x.00)</MenuItem>
                                    {isAgentsLoading && <MenuItem disabled>Loading...</MenuItem>}
                                    {!isAgentsLoading &&
                                        agents &&
                                        agents.map(agent => (
                                            <MenuItem value={d2f(agent.fraction)}>
                                                {agent.name} (x.
                                                {d2f(agent.fraction).toLocaleString(undefined, {
                                                    minimumIntegerDigits: 2,
                                                })}
                                                ){' '}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                        </Stack>
                    </Stack>
                </DialogContentText>
                <Grid container alignItems={'center'} justifyContent={'center'} spacing={1} sx={{ mt: 1 }}>
                    {isLiveStreamTippersLoading && (
                        <Grid item xs={12} textAlign="center">
                            <Stack direction={'column'} spacing={1}>
                                <CircularProgress />
                                <Typography variant="body1">Loading...</Typography>
                            </Stack>
                        </Grid>
                    )}
                    {!isLiveStreamTippersLoading && liveStreamTippers && filteredLiveStreamTippers.length === 0 && (
                        <Grid item xs={12} textAlign="center">
                            <Typography variant="body1" color={theme.palette.text.disabled}>
                                No tippers found
                            </Typography>
                        </Grid>
                    )}
                    {!isLiveStreamTippersLoading && liveStreamTippers && filteredLiveStreamTippers.length > 0 && (
                        <Grid item xs={12}>
                            <Box sx={{ overflowX: 'auto' }}>
                                <Table
                                    width="100%"
                                    sx={{
                                        // no left/right padding on the first and last cells
                                        '& td:first-of-type, & th:first-of-type': {
                                            paddingLeft: 0,
                                        },
                                        // no right padding on the last cell
                                        '& td:last-of-type, & th:last-of-type': {
                                            paddingRight: 0,
                                        },
                                        // Bold header
                                        '& th': {
                                            fontWeight: 'bold',
                                        },
                                        // No wrapping
                                        whiteSpace: 'nowrap',
                                        // make last column sticky
                                        '& th, & td': {
                                            paddingLeft: 0,
                                            paddingRight: 1,

                                            '&:last-child': {
                                                position: 'sticky',
                                                right: 0,
                                                backgroundColor: theme.palette.background.paper,
                                            },
                                        },
                                    }}
                                >
                                    <TableHead>
                                        <TableRow>
                                            <TableCell width={120}>Date</TableCell>
                                            <TableCell width={85}>User ID</TableCell>
                                            <TableCell>Username</TableCell>
                                            <TableCell>Name</TableCell>
                                            <TableCell width={50}>Tip</TableCell>
                                            <TableCell width={50} align="center">
                                                Actions
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {filteredLiveStreamTippers.map(liveStreamTipper => (
                                            <TableRow key={liveStreamTipper._id}>
                                                <TableCell>{moment(liveStreamTipper.createdAt).format('L LT')}</TableCell>
                                                <TableCell>{liveStreamTipper.user.id}</TableCell>
                                                <TableCell>{liveStreamTipper.user.username}</TableCell>
                                                <TableCell>{liveStreamTipper.user.name}</TableCell>
                                                <TableCell align="right">
                                                    {dinero({
                                                        amount: Math.trunc(liveStreamTipper.amount * 100),
                                                        currency: 'USD',
                                                    }).toFormat()}
                                                </TableCell>
                                                <TableCell align="center">
                                                    <Stack direction={'row'} spacing={0.5} alignItems="center" justifyContent={'center'}>
                                                        <Tooltip title="Go to user's profile" arrow placement="top">
                                                            <IconButton
                                                                component={Link}
                                                                to={`https://onlyfans.com/${liveStreamTipper.user.username}`}
                                                                target="_blank"
                                                                color="secondary"
                                                            >
                                                                <AccountBoxIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Stack>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Box>
                        </Grid>
                    )}
                </Grid>
            </DialogContent>
            <DialogActions
                sx={{
                    backgroundColor: theme.palette.grey[100],
                }}
            >
                <Button
                    color="error"
                    size="medium"
                    onClick={() => {
                        handleClose({}, 'escapeKeyDown');
                    }}
                >
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default SextforceLiveStreamAddTippersToListTippersListDialog;
