import useTheme from '@mui/material/styles/useTheme';
import { Theme } from '@mui/system';
import { Card, CardContent, Grid, TextField } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';
import SelectTimezone from '../../../forms/helpers/SelectTimezone';

type Props = {
    timezone: string;
    setTimezone: (timezone: string) => void;
    date: Date;
    setDate: (date: Date) => void;
    isFetchingReport: boolean;
    requestReport: () => void;
};

const MessageUsersHistorySearchBar = (props: Props) => {
    const { timezone, setTimezone, date, setDate, isFetchingReport, requestReport } = props;
    const theme: Theme = useTheme();

    return (
        <Card variant="elevation" sx={{ marginBottom: theme.spacing(2), displayPrint: 'none' }}>
            <CardContent style={{ padding: theme.spacing(1) }}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <Grid container spacing={1} alignItems={'center'}>
                        <Grid item xs={12} md="auto">
                            <SelectTimezone timezone={timezone} setTimezone={setTimezone} size="small" fullWidth={true} />
                        </Grid>
                        <Grid item xs={12} md="auto">
                            <DatePicker
                                label="Date"
                                inputFormat="DD/MM/YYYY"
                                mask={'__/__/____'}
                                disabled={!date}
                                value={date}
                                onChange={newValue => {
                                    if (newValue) {
                                        setDate(moment(newValue).startOf('day').toDate());
                                    }
                                }}
                                renderInput={params => <TextField size="small" {...params} />}
                                showDaysOutsideCurrentMonth={true}
                            />
                        </Grid>
                        <Grid item xs={12} md={1}>
                            <LoadingButton
                                variant="contained"
                                disabled={isFetchingReport}
                                loading={isFetchingReport}
                                fullWidth
                                size="medium"
                                onClick={requestReport}
                            >
                                Show
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </LocalizationProvider>
            </CardContent>
        </Card>
    );
};

export default MessageUsersHistorySearchBar;
