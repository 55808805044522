import { Auth } from 'aws-amplify';
import { Button, Grid, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDialog } from 'muibox';
import PasswordStrengthBar from 'react-password-strength-bar';

const ProfileDialogChangePassword = () => {
    const dialog = useDialog();

    const [oldPassword, setOldPassword] = useState<string>('');
    const [newPassword, setNewPassword] = useState<string>('');
    const [repearNewPassword, setRepearNewPassword] = useState<string>('');
    const [isPasswordValid, setIsPasswordValid] = useState<boolean>(false);

    useEffect(() => {
        setIsPasswordValid(newPassword === repearNewPassword);
    }, [newPassword, repearNewPassword]);

    const handleChangePassword = () => {
        Auth.currentAuthenticatedUser()
            .then(user => {
                return Auth.changePassword(user, oldPassword, newPassword);
            })
            .then(data => {
                if (data === 'SUCCESS') {
                    setOldPassword('');
                    setNewPassword('');
                    setRepearNewPassword('');

                    dialog
                        .alert({
                            title: 'Success',
                            message: 'Password changed successfully',
                            ok: {
                                text: 'OK',
                                color: 'primary',
                                variant: 'contained',
                            },
                        })
                        .then(() => {})
                        .catch(() => {});
                }
            })
            .catch(err => {
                dialog
                    .alert({
                        title: 'Error',
                        message: 'Could not change password',
                        ok: {
                            text: 'OK',
                            color: 'error',
                            variant: 'contained',
                        },
                    })
                    .then(() => {})
                    .catch(() => {});
            });
    };

    return (
        <Grid container spacing={1} alignItems="center">
            <Grid item xs={12}>
                <Typography variant="h6">Change Password</Typography>
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label="Old Password"
                    name="oldPassword"
                    type="text"
                    fullWidth
                    value={oldPassword}
                    InputProps={{ type: 'password' }}
                    onChange={e => setOldPassword(e.target.value)}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label="New Password"
                    name="newPassword"
                    type="text"
                    fullWidth
                    value={newPassword}
                    InputProps={{ type: 'password' }}
                    onChange={e => setNewPassword(e.target.value)}
                />
                <PasswordStrengthBar password={newPassword} />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label="Repeat New Password"
                    name="repearNewPassword"
                    type="text"
                    fullWidth
                    value={repearNewPassword}
                    InputProps={{ type: 'password' }}
                    onChange={e => setRepearNewPassword(e.target.value)}
                    error={!isPasswordValid}
                    helperText={!isPasswordValid ? 'Passwords do not match' : ''}
                />
            </Grid>
            <Grid item xs={12}>
                <Button
                    variant="contained"
                    color="secondary"
                    fullWidth
                    size="large"
                    disabled={
                        oldPassword.trim().length === 0 ||
                        newPassword.trim().length === 0 ||
                        repearNewPassword.trim().length === 0 ||
                        !isPasswordValid
                    }
                    onClick={handleChangePassword}
                >
                    Change
                </Button>
            </Grid>
        </Grid>
    );
};

export default ProfileDialogChangePassword;
