import { Card, CardContent, Theme, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';
import moment from 'moment-timezone';
import 'moment/locale/en-gb';
import { useContext, useEffect, useState } from 'react';
import { Route, Routes, useNavigate, useParams } from 'react-router-dom';
import BackNavigationButton from '../../../components/common/BackNavigationButton';
import PageContainer from '../../../components/common/PageContainter';
import SextforceMetricsCampaign from '../../../components/services/sextforce/metrics/campaigns/details/SextforceMetricsCampaign';
import SextforceMetricsCampaignsOverview from '../../../components/services/sextforce/metrics/campaigns/overview/SextforceMetricsCampaignsOverview';
import useDashboardAccount from '../../../hooks/useDashboardAccount';
import useSubscriber from '../../../hooks/useSubscriber';
import { SettingsContext } from '../../../store/SettingsContext';

const SextforceMetricsCampaigns = () => {
    const settingsContext = useContext(SettingsContext);
    const { dashboardAccount, dashboardAccountLoading } = useDashboardAccount(true);
    const theme: Theme = useTheme();
    const params = useParams();
    const navigate = useNavigate();
    const [view, setView] = useState<'details' | 'overview'>(params.metricId ? 'details' : 'overview');
    const [timezone, setTimezone] = useState<string>(moment.tz.guess());

    const { data: subscriber } = useSubscriber();

    // Set global MomentJS locale
    moment.locale('en-gb');

    useEffect(() => {
        if (!dashboardAccountLoading && dashboardAccount) {
            if (dashboardAccount && dashboardAccount.timezone) {
                setTimezone(dashboardAccount.timezone);
            }
        }
    }, [dashboardAccountLoading, dashboardAccount]);

    useEffect(() => {
        if (params['*']?.includes('details')) {
            setView('details');
        } else if (params['*']?.includes('overview')) {
            setView('overview');
        } else {
            navigate(`/subscribers/${params.userId}/services/sextforce/metrics/campaigns/overview`);
        }
    }, [navigate, params]);

    return (
        <PageContainer>
            <div style={{ paddingBottom: theme.spacing(2) }}>
                <Typography variant="h5">
                    <BackNavigationButton
                        {...(subscriber && { url: `/subscribers/${subscriber._id}/${settingsContext.services.sextforce.homeUrl}` })}
                    />{' '}
                    Tracking Links Metrics for {subscriber?.username}
                </Typography>
            </div>

            <Card sx={{ width: '100%', marginBottom: 2 }}>
                <CardContent style={{ padding: 0 }}>
                    <ToggleButtonGroup
                        color="primary"
                        fullWidth
                        value={view}
                        exclusive
                        onChange={(e, value) => {
                            if (value !== null) {
                                setView(value);

                                // Navigate the router to the correct view but make sure we are not already on this URL
                                if (value === 'overview') {
                                    return navigate(`/subscribers/${params.userId}/services/sextforce/metrics/campaigns/overview`);
                                } else if (value === 'details') {
                                    return navigate(`/subscribers/${params.userId}/services/sextforce/metrics/campaigns/details`);
                                }
                            }
                        }}
                    >
                        <ToggleButton value="overview">Overview</ToggleButton>
                        <ToggleButton value="details">Detailed</ToggleButton>
                    </ToggleButtonGroup>
                </CardContent>
            </Card>

            <Routes>
                <Route
                    path="overview"
                    element={
                        <SextforceMetricsCampaignsOverview
                            subscriber={subscriber}
                            timezone={timezone}
                            setTimezone={setTimezone}
                            theme={theme}
                        />
                    }
                />
                <Route path="details" element={<SextforceMetricsCampaign subscriber={subscriber} timezone={timezone} theme={theme} />} />
                <Route
                    path="details/:metricId"
                    element={<SextforceMetricsCampaign subscriber={subscriber} timezone={timezone} theme={theme} />}
                />
            </Routes>
        </PageContainer>
    );
};

export default SextforceMetricsCampaigns;
