import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import SaveIcon from '@mui/icons-material/Save';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import {
    Button,
    Card,
    CardContent,
    CircularProgress,
    FormControl,
    Grid,
    InputAdornment,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    SelectChangeEvent,
    Stack,
    TextField,
    Theme,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
} from '@mui/material';
import { useDialog } from 'muibox';
import { useContext, useEffect, useState } from 'react';
import { OnlyFansMetrics } from '../../../../hooks/useSextforceMetricsCampaignsOverview';
import { SettingsContext } from '../../../../store/SettingsContext';
import { d2f } from '../../../../utils/common';

type Props = {
    subscriber: any | null;
    metricData: OnlyFansMetrics;
    saveSettings: (platform: string, associatedUsername: string, goalSpent: number, goalSubs: number) => void;
    isSavingSettings: boolean;
    theme: Theme;
};

export const SextforceMetricsBoughtFromSettingsContent = (props: {
    metricData: any | null;
    platform: string;
    setPlatform: (platform: string) => void;
    associatedUsername: string;
    setAssociatedUsername: (associatedUsername: string) => void;
    amountType: 'gross' | 'net';
    setAmountType: (amountType: 'gross' | 'net') => void;
    setGoalSpent: (goalSpent: number) => void;
    goalSpentString: string;
    setGoalSpentString: (goalSpentString: string) => void;
    setGoalSubs: (goalSubs: number) => void;
    goalSubsString: string;
    setGoalSubsString: (goalSubsString: string) => void;
    setHasChanged: (hasChanged: boolean) => void;
}) => {
    const {
        metricData,
        platform,
        setPlatform,
        associatedUsername,
        setAssociatedUsername,
        amountType,
        setAmountType,
        setGoalSpent,
        goalSpentString,
        setGoalSpentString,
        setGoalSubs,
        goalSubsString,
        setGoalSubsString,
        setHasChanged,
    } = props;
    const settings = useContext(SettingsContext);

    return (
        <Grid container flexGrow={1} spacing={2} alignItems="center">
            <Grid item xs={12}>
                <Grid container flexGrow={1} spacing={1} alignItems="center">
                    <Grid item xs={12}>
                        Enter the username of the creator or account owner you shared the campaign/trial with for improved tracking and
                        easier search. Select the platform they are active on and enter their username.
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <InputLabel htmlFor="platform">Platform</InputLabel>
                            <Select
                                id="platform"
                                // labelId="platform"
                                size="small"
                                label="Platform"
                                disabled={!metricData}
                                displayEmpty
                                renderValue={
                                    platform !== ''
                                        ? undefined
                                        : (selected: string) => {
                                              if (selected && selected.length === 0) {
                                                  return <em>Platform</em>;
                                              }

                                              return selected;
                                          }
                                }
                                value={platform}
                                onChange={(e: SelectChangeEvent<string>) => {
                                    setPlatform(e.target.value);
                                    setHasChanged(true);
                                }}
                            >
                                {Object.keys(settings.platforms).map((p: string) => (
                                    <MenuItem key={p} value={p}>
                                        <Grid container spacing={1} flexGrow={0} alignItems="center" justifyContent="left">
                                            <Grid item xs="auto">
                                                {settings.platforms[p].icon!('small')}
                                            </Grid>
                                            <Grid item xs>
                                                {settings.platforms[p].name}
                                            </Grid>
                                        </Grid>
                                    </MenuItem>
                                ))}
                                <MenuItem value="other">Other</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            id="platform_username"
                            disabled={!metricData}
                            label="Username"
                            value={associatedUsername}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <AlternateEmailIcon />
                                    </InputAdornment>
                                ),
                            }}
                            onChange={e => {
                                setAssociatedUsername(e.target.value);
                                setHasChanged(true);
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12}>
                Enter the promo's $$$ goal - this can be how much the promo had cost you or what you are hoping to earn from it - to see the
                promo progress and get alerted via Telegram when the goal is reached!
            </Grid>

            <Grid item xs={12}>
                <Stack direction="row" spacing={1} flexGrow={1} alignItems="center">
                    <FormControl fullWidth>
                        <InputLabel htmlFor="goalSpent">Earnings Goal</InputLabel>
                        <OutlinedInput
                            id="goalSpent"
                            label="Earnings Goal"
                            disabled={!metricData}
                            value={goalSpentString}
                            startAdornment={
                                <InputAdornment position="start">
                                    <AttachMoneyIcon />
                                </InputAdornment>
                            }
                            endAdornment={<InputAdornment position="end">USD</InputAdornment>}
                            inputProps={{
                                inputMode: 'text',
                                pattern: /([0-9]+([.][0-9]*)?|[.][0-9]+)/,
                            }}
                            onChange={e => {
                                e.preventDefault();

                                if (/^([\d]*[,.]?[\d]{0,2})$/.test(e.target.value)) {
                                    const parsed = parseFloat(e.target.value.replaceAll(',', '.'));

                                    if (isNaN(parsed) || parsed === 0) {
                                        setGoalSpent(0);
                                        setGoalSpentString('0');
                                    } else {
                                        setGoalSpent(parsed);
                                        setGoalSpentString(e.target.value.replace(/^0+/, ''));
                                    }

                                    setHasChanged(true);
                                }
                            }}
                        />
                    </FormControl>
                    <ToggleButtonGroup
                        disabled={!metricData}
                        exclusive
                        value={amountType}
                        size="small"
                        onChange={(e, value) => {
                            if (value) {
                                setAmountType(value);
                                setHasChanged(true);

                                localStorage.setItem('showEarningsAsGross', value === 'gross' ? 'true' : 'false');
                            }
                        }}
                    >
                        <ToggleButton value="gross" fullWidth sx={{ width: '60px', height: '38px' }}>
                            Gross
                        </ToggleButton>
                        <ToggleButton value="net" fullWidth sx={{ width: '60px', height: '38px' }}>
                            Net
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Stack>
            </Grid>

            <Grid item xs={12}>
                Enter the promo's subscribers/claims goal - this can be how many fans you bought, swapped or were hoping to gain from this
                promo - to see the progress and get alerted via Telegram when the goal is reached!
            </Grid>
            <Grid item xs={12}>
                <FormControl fullWidth>
                    <InputLabel htmlFor="goalSubs">Subs Goal</InputLabel>
                    <OutlinedInput
                        id="subsGoal"
                        label="Subs Goal"
                        disabled={!metricData}
                        value={goalSubsString}
                        startAdornment={
                            <InputAdornment position="start">
                                <SubscriptionsIcon />
                            </InputAdornment>
                        }
                        inputProps={{
                            inputMode: 'text',
                            pattern: /^[0-9]*$/,
                        }}
                        onChange={e => {
                            e.preventDefault();

                            if (/^[0-9]*$/.test(e.target.value)) {
                                const parsed = parseFloat(e.target.value.replaceAll(',', '.'));

                                if (isNaN(parsed) || parsed === 0) {
                                    setGoalSubs(0);
                                    setGoalSubsString('0');
                                } else {
                                    setGoalSubs(parsed);
                                    setGoalSubsString(e.target.value.replace(/^0+/, ''));
                                }

                                setHasChanged(true);
                            }
                        }}
                    />
                </FormControl>
            </Grid>
        </Grid>
    );
};

const SextforceMetricsBoughtFromSettings = (props: Props) => {
    const { subscriber, metricData, saveSettings, isSavingSettings, theme } = props;
    const dialog = useDialog();

    const [platform, setPlatform] = useState<string>('');
    const [associatedUsername, setAssociatedUsername] = useState<string>('');
    const [amountType, setAmountType] = useState<'gross' | 'net'>('net');
    const [goalSpent, setGoalSpent] = useState<number>(0);
    const [goalSpentString, setGoalSpentString] = useState<string>('');
    const [goalSubs, setGoalSubs] = useState<number>(0);
    const [goalSubsString, setGoalSubsString] = useState<string>('');
    const [hasChanged, setHasChanged] = useState<boolean>(false);

    useEffect(() => {
        if (metricData) {
            if (metricData.associatedUsername) {
                setAssociatedUsername(metricData.associatedUsername);
            } else {
                setAssociatedUsername('');
            }

            if (metricData.settings) {
                setPlatform(metricData.settings.platform || '');
                setGoalSpent(metricData.settings.goalSpent ? d2f(metricData.settings.goalSpent) : 0);
                setGoalSpentString(metricData.settings.goalSpent ? d2f(metricData.settings.goalSpent).toString() : '');
                setGoalSubs(metricData.settings.goalSubs || 0);
                setGoalSubsString(
                    metricData.settings.goalSubs && metricData.settings.goalSubs !== 0 ? metricData.settings.goalSubs.toString() : '',
                );
            } else {
                setPlatform('');
                setGoalSpent(0);
                setGoalSpentString('');
                setGoalSubs(0);
                setGoalSubsString('');
            }

            setHasChanged(false);
        }
    }, [metricData]);

    const handleSaveSettings = () => {
        dialog
            .confirm({
                message: 'Are you sure you want to save these settings?',
                title: 'Save Settings',
                ok: { text: 'Save', variant: 'contained', color: 'secondary' },
                cancel: { text: 'Cancel' },
            })
            .then(() => {
                saveSettings(platform.trim(), associatedUsername.trim(), goalSpent, goalSubs);
            })
            .catch(() => {});
    };

    const handleResetSettings = () => {
        setAssociatedUsername('');
        setPlatform('');
        setGoalSpent(0);
        setGoalSpentString('');
        setGoalSubs(0);
        setGoalSubsString('');
        setHasChanged(true);
    };

    return (
        <Card sx={{ width: '100%', marginBottom: theme.spacing(2) }}>
            <CardContent>
                <Typography variant="h6" gutterBottom>
                    Settings
                </Typography>

                <SextforceMetricsBoughtFromSettingsContent
                    metricData={metricData}
                    platform={platform}
                    associatedUsername={associatedUsername}
                    setHasChanged={setHasChanged}
                    amountType={amountType}
                    setAmountType={setAmountType}
                    goalSpentString={goalSpentString}
                    goalSubsString={goalSubsString}
                    setAssociatedUsername={setAssociatedUsername}
                    setGoalSpent={setGoalSpent}
                    setGoalSpentString={setGoalSpentString}
                    setGoalSubs={setGoalSubs}
                    setGoalSubsString={setGoalSubsString}
                    setPlatform={setPlatform}
                />

                <Grid container spacing={1} flexGrow={1} alignItems="center" justifyContent="center">
                    <Grid item xs={12}>
                        <Grid container spacing={2} flexGrow={0} alignItems="center" justifyContent="left">
                            <Grid item xs={6} md="auto">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="medium"
                                    disabled={isSavingSettings || !metricData || !hasChanged}
                                    endIcon={isSavingSettings ? <CircularProgress size={14} /> : <SaveIcon />}
                                    fullWidth
                                    onClick={() => {
                                        handleSaveSettings();
                                    }}
                                >
                                    Save
                                </Button>
                            </Grid>
                            <Grid item xs={6} md="auto">
                                <Button
                                    variant="outlined"
                                    color="secondary"
                                    size="medium"
                                    disabled={isSavingSettings || !metricData}
                                    endIcon={<RestartAltIcon />}
                                    fullWidth
                                    onClick={() => {
                                        handleResetSettings();
                                    }}
                                >
                                    Clear
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default SextforceMetricsBoughtFromSettings;
