import { useState } from 'react';
import { Theme } from '@mui/system';
import { FormControl, Grid, InputLabel, OutlinedInput } from '@mui/material';

type Props = {
    count: number;
    setCount: (count: number) => void;
    theme: Theme;
};

const MessageUsersFormExcludeLimit = (props: Props) => {
    const { theme, count, setCount } = props;
    const [countString, setCountString] = useState<string>(count.toString());

    return (
        <>
            <Grid container spacing={1} flexGrow={0} alignItems="center" justifyContent="center">
                <Grid item xs={12}>
                    <Grid
                        container
                        spacing={1}
                        flexGrow={0}
                        alignItems="center"
                        justifyContent="center"
                        sx={{ marginBottom: theme.spacing(2) }}
                    >
                        <Grid item xs="auto">
                            Only send
                        </Grid>
                        <Grid item xs="auto">
                            <FormControl>
                                <InputLabel htmlFor="count">Count</InputLabel>
                                <OutlinedInput
                                    id="count"
                                    size="medium"
                                    inputProps={{ inputMode: 'text', pattern: /^\d*$$/ }}
                                    value={countString}
                                    onChange={e => {
                                        e.preventDefault();

                                        if (/^\d*$/.test(e.target.value)) {
                                            const parsed = parseFloat(e.target.value.replaceAll(',', '.'));

                                            if (isNaN(parsed) || parsed === 0) {
                                                setCount(0);
                                                setCountString('0');
                                            } else {
                                                setCount(parsed);
                                                setCountString(e.target.value.replace(/^0+/, ''));
                                            }
                                        }
                                    }}
                                    label="Count"
                                    error={count < 1}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs="auto">
                            messages
                        </Grid>
                        <Grid item xs></Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default MessageUsersFormExcludeLimit;
