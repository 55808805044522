import { Controller, Control } from 'react-hook-form';
import { Checkbox, FormControlLabel, FormGroup, FormHelperText } from '@mui/material';

type FormTextFieldProps = {
    control: Control<any>;
    errors: any;
    name: string;
    label: string;
    required?: boolean;
    value?: any;
    size?: 'small' | 'medium';
    disabled?: boolean;
    sx?: any;
};

const FormCheckBox = (props: FormTextFieldProps) => {
    const { control, errors, name, label, required, value, size, disabled, sx } = props;

    return (
        <Controller
            name={name}
            control={control}
            rules={{ required }}
            defaultValue={value}
            render={({ field }) => (
                <FormGroup>
                    <FormControlLabel
                        control={<Checkbox {...field} checked={field.value} size={size} disabled={disabled} sx={sx} />}
                        label={label}
                    />
                    <FormHelperText>{errors[name] && errors[name]?.message}</FormHelperText>
                </FormGroup>
            )}
        />
    );
};

export default FormCheckBox;
