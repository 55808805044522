import { Box, LinearProgress, Stack, Typography } from '@mui/material';
import { LoginSessionStatePayload } from '../../hooks/useSubscriberRelogin';

type Props = {
    session: LoginSessionStatePayload | null;
    submitting: boolean;
};

const OnlyFansReloginStatus = (props: Props) => {
    const { session, submitting } = props;

    return (
        <Box>
            <Stack direction={'column'} spacing={0}>
                <Box
                    sx={{
                        pt: 1,
                        pl: 3,
                        pr: 3,
                        pb: 1,
                        backgroundColor: 'grey.50',
                    }}
                >
                    <Typography variant={'body2'} color="text.secondary">
                        {(session && session.message) || 'Please wait...'}
                    </Typography>
                </Box>
                <LinearProgress
                    variant={session?.result === '2fa' && !submitting ? 'determinate' : 'indeterminate'}
                    value={session?.result === '2fa' ? 100 : undefined}
                    sx={{
                        width: '100%',
                    }}
                />
            </Stack>
        </Box>
    );
};

export default OnlyFansReloginStatus;
