import axios from 'axios';
import { useDialog } from 'muibox';
import { useContext } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { SettingsContext } from '../store/SettingsContext';
import { UserContext } from '../store/UserContext';
import { handleHttpError } from '../utils/common';

export interface SextforceLiveStreamTipper {
    _id?: string;
    streamId: string;
    user: {
        id: number;
        username: string;
        name?: string;
    };
    createdAt: Date;
    amount: number;
    fraction?: object;
}

const useSextforceLiveStreamAddTippersToListTippers = (streamId: number | undefined | null) => {
    const userContext = useContext(UserContext);
    const settingsContext = useContext(SettingsContext);
    const dialog = useDialog();
    const params = useParams();

    // Fetch the restricted words
    const fetchLiveStreamTippers = async (): Promise<SextforceLiveStreamTipper[]> => {
        if (userContext.jwtToken && settingsContext.apiKey && userContext.user.username) {
            const query: string = `${settingsContext.routes.sextforce.base}${params.userId}/liveStream/addTippersToList/${streamId}/tippers`;

            return axios
                .get(query, {
                    headers: {
                        Authorization: userContext.jwtToken,
                        apiKey: settingsContext.apiKey,
                    },
                })
                .then(response => response.data as SextforceLiveStreamTipper[])
                .catch(error => {
                    console.error(error);
                    handleHttpError(error, dialog);

                    return [];
                });
        }

        return [];
    };

    const liveStreams = useQuery(
        ['liveStreamTippers', userContext.jwtToken, params.userId, streamId],
        () => {
            return fetchLiveStreamTippers();
        },
        {
            refetchOnWindowFocus: false,
            // Stake time 5 minutes
            staleTime: 5 * 60 * 1000,
            enabled:
                userContext.jwtToken && settingsContext.apiKey && userContext.user.username && params.userId && streamId ? true : false,
        },
    );

    return {
        ...liveStreams,
    };
};

export default useSextforceLiveStreamAddTippersToListTippers;
