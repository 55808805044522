export const followBackFilterInterface: string = `export interface OnlyFanyUsersFilterOptions {
    // eslint-disable-next-line camelcase
    total_spent?: number;
    tips?: number;
    duration?: number;
    inactive?: number;
    recommenders?: boolean;
    online?: boolean;
    thresholdMonths?: number;
    thresholdMinTotalSpent?: number;
}`;

export const housekeepingInterface: string = `import moment from 'moment';

export interface HousekeepingListCriteriaConditionRange {
    lt?: number | Date;
    lte?: number | Date;
    gt?: number | Date;
    gte?: number | Date;
    within?: null;
    amount?: number;
    unit?: moment.unitOfTime.DurationConstructor;
}

export interface HousekeepingListCriteriaIncludes {
    // eslint-disable-next-line no-use-before-define
    [key: string]: HousekeepingListCriteriaCondition;
}

export interface HousekeepingListCriteriaCondition {
    eq?: any;
    ne?: any;
    range?: HousekeepingListCriteriaConditionRange;
    includes?: HousekeepingListCriteriaIncludes[];
    excludes?: HousekeepingListCriteriaIncludes[];
}

export interface HousekeepingListCriteria {
    subscribedOnData?: any;
    // $or?: HousekeepingListCriteria;
    [key: string]: HousekeepingListCriteriaCondition | HousekeepingListCriteria | undefined;
}

export interface HousekeepingListExportResults {
    googleSpreadsheetId: string;
    googleSpreadheetName: string;
}

export interface HousekeepingList {
    listName: string;
    addPrefixToUserDisplayName?: boolean;
    userDisplayNamePrefix?: string;
    exportResults?: HousekeepingListExportResults;
    criteria: HousekeepingListCriteria | HousekeepingListCriteria[];
}`;

export const housekeepingExamples: string = `[{
            "listName": "OS - Expired Spenders",
            "criteria": {
                "isBlocked": {
                    "eq": false
                },
                "canReceiveChatMessage": {
                    "eq": true
                },
                "subscribedOn": {
                    "eq": null
                },
                "subscribedOnData.totalSumm": {
                    "range": {
                        "gt": 0
                    }
                }
            }
        }, {
            "listName": "OS - Restricted Me (I Follow)",
            "criteria": {
                "isBlocked": {
                    "eq": false
                },
                "subscribedByData": {
                    "ne": null
                },
                "canReceiveChatMessage": {
                    "eq": false
                }
            }
        }, {
            "listName": "OS - Restricted Me (I Don't Follow)",
            "criteria": {
                "isBlocked": {
                    "eq": false
                },
                "subscribedByData": {
                    "eq": null
                },
                "canRestrict": {
                    "eq": false
                }
            }
        }, {
            "listName": "OS - Freeloaders",
            "criteria": {
                "isPerformer": {
                    "eq": false
                },
                "isBlocked": {
                    "eq": false
                },
                "canReceiveChatMessage": {
                    "eq": true
                },
                "subscribedOnData.totalSumm": {
                    "eq": 0
                }
            }
        }, {
            "listName": "OS - Freeloaders not on Trial",
            "criteria": {
                "isBlocked": {
                    "eq": false
                },
                "isPerformer": {
                    "eq": false
                },
                "subscribedOnData.totalSumm": {
                    "eq": 0
                },
                "subscribedOn": {
                    "ne": null
                },
                "subscribedOnData.subscribes": {
                    "excludes": {
                        "type": {
                            "eq": "trial"
                        },
                        "isCurrent": {
                            "eq": true
                        }
                    }
                }
            }
        }, {
            "listName": "OS - Freeloaders on Trial (3 Months)",
            "criteria": {
                "isBlocked": {
                    "eq": false
                },
                "subscribedOnData.totalSumm": {
                    "eq": 0
                },
                "subscribedOnData.subscribes": {
                    "includes": {
                        "type": {
                            "eq": ["trial", "promo"]
                        },
                        "price": {
                            "eq": 0
                        },
                        "isCurrent": {
                            "eq": true
                        },
                        "startDate": {
                            "range": {
                                "lt": null,
                                "amount": -3,
                                "unit": "months"
                            }
                        }
                    }
                }
            }
        }, {
            "listName": "OS - Spenders $1000+",
            "criteria": {
                "isBlocked": {
                    "eq": false
                },
                "canReceiveChatMessage": {
                    "eq": true
                },
                "subscribedOn": {
                    "ne": null
                },
                "subscribedOnData.totalSumm": {
                    "range": {
                        "gt": 1000
                    }
                }
            }
        }, {
            "listName": "OS - Spenders $500-$1000",
            "criteria": {
                "isBlocked": {
                    "eq": false
                },
                "canReceiveChatMessage": {
                    "eq": true
                },
                "subscribedOn": {
                    "ne": null
                },
                "subscribedOnData.totalSumm": {
                    "range": {
                        "gt": 500,
                        "lte": 1000
                    }
                }
            }
        }, {
            "listName": "OS - About To Expire",
            "criteria": {
                "isBlocked": {
                    "eq": false
                },
                "canReceiveChatMessage": {
                    "eq": true
                },
                "subscribedOnData.expiredAt": {
                    "range": {
                        "within": null,
                        "amount": 7,
                        "unit": "days"
                    }
                }
            }
        }, {
            "listName": "OS - About To Expire without Rebill",
            "criteria": {
                "isBlocked": {
                    "eq": false
                },
                "canReceiveChatMessage": {
                    "eq": true
                },
                "subscribedOnData.renewedAt": {
                    "eq": null
                },
                "subscribedOnData.expiredAt": {
                    "range": {
                        "within": null,
                        "amount": 7,
                        "unit": "days"
                    }
                }
            }
        }, {
            "listName": "OS - Subscribers Club",
            "criteria": {
                "subscribedOnData.subscribes": {
                    "includes": {
                        "type": {
                            "eq": ["regular", "promo"]
                        },
                        "price": {
                            "eq": 30
                        },
                        "isCurrent": {
                            "eq": true
                        }
                    }
                }
            }
        }, {
            "listName": "OS - Freeloaders on Subscription",
            "criteria": {
                "subscribedOnData.subscribes": {
                    "includes": {
                        "type": {
                            "eq": ["regular", "promo"]
                        },
                        "isCurrent": {
                            "eq": true
                        }
                    }
                },
                "subscribedOnData.subscribesSumm": {
                    "ne": 0
                },
                "subscribedOnData.tipsSumm": {
                    "eq": 0
                },
                "subscribedOnData.messagesSumm": {
                    "eq": 0
                },
                "subscribedOnData.postsSumm": {
                    "eq": 0
                },
                "subscribedOnData.streamsSumm": {
                    "eq": 0
                }
            }
        }, {
            "listName": "OS - Freeloaders on Trial",
            "criteria": {
                "isBlocked": {
                    "eq": false
                },
                "subscribedOnData.totalSumm": {
                    "eq": 0
                },
                "subscribedOnData.subscribes": {
                    "includes": {
                        "type": {
                            "eq": ["trial", "promo"]
                        },
                        "price": {
                            "eq": 0
                        },
                        "isCurrent": {
                            "eq": true
                        }
                    }
                }
            }
        }]`;
