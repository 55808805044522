import DeleteIcon from '@mui/icons-material/Delete';
import TelegramIcon from '@mui/icons-material/Telegram';
import { Button, Grid, IconButton, Link, Skeleton, Stack, Table, TableCell, TableHead, TableRow, Theme, Typography } from '@mui/material';
import axios from 'axios';
import moment from 'moment';
import { Dialog, useDialog } from 'muibox';
import { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import useSextforceMetricsTelegramInvitationLink, {
    OnlyFansMetricsTelegramNotifications,
} from '../../../../hooks/useSextforceMetricsTelegramInvitationLink';
import { SettingsContext } from '../../../../store/SettingsContext';
import { UserContext } from '../../../../store/UserContext';
import { handleHttpError, metricTypeName } from '../../../../utils/common';
import LinkWithCopyToClipboardBox from '../../../common/LinkWithCopyToClipboardBox';

type InvitationLinkTableRowProps = {
    invitationLink: OnlyFansMetricsTelegramNotifications;
    handleDeleteTelegramInviteLink: (metricId: string) => void;
    dialog: Dialog;
    theme: Theme;
};

const InvitationLinkTableRow = (props: InvitationLinkTableRowProps) => {
    const { invitationLink, handleDeleteTelegramInviteLink, dialog, theme } = props;

    return (
        <TableRow>
            <TableCell>
                {invitationLink.telegramChatId ? (
                    <Stack direction="row" alignItems="center" spacing={1}>
                        <TelegramIcon />
                        <Link href={`https://t.me/${invitationLink.telegramUsername}`} target="_blank">
                            {invitationLink.telegramUsername}
                        </Link>
                    </Stack>
                ) : (
                    <span style={{ color: theme.palette.grey[600] }}>Not Registered</span>
                )}
            </TableCell>
            <TableCell>{moment(invitationLink.registerDate).format('L')}</TableCell>
            <TableCell>{invitationLink.lastSentDate ? moment(invitationLink.lastSentDate).format('L') : '-'}</TableCell>
            <TableCell align="center">
                <IconButton
                    color="error"
                    onClick={() => {
                        dialog
                            .confirm({
                                title: 'Delete Telegram Invitation Link',
                                message:
                                    'Are you sure you want to delete this invitation link? Your colleague will no longer receive updates.',
                                cancel: {
                                    text: 'Cancel',
                                    variant: 'outlined',
                                },
                                ok: {
                                    text: 'Delete',
                                    variant: 'contained',
                                    color: 'error',
                                },
                            })
                            .then(() => {
                                if (invitationLink._id) {
                                    handleDeleteTelegramInviteLink(invitationLink._id);
                                }
                            })
                            .catch(() => {});
                    }}
                >
                    <DeleteIcon />
                </IconButton>
            </TableCell>
        </TableRow>
    );
};

type Props = {
    subscriber: any | null;
    metricData: any;
    telegramLink: string | null;
    setTelegramLink: (telegramLink: string) => void;
    theme: Theme;
};

const SextforceMetricsShareDialogTelegram = (props: Props) => {
    const { subscriber, metricData, telegramLink, setTelegramLink, theme } = props;
    const userContext = useContext(UserContext);
    const settingsContext = useContext(SettingsContext);
    const params = useParams();
    const dialog = useDialog();

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const {
        data: invitationLinks,
        isLoading: isInvitationLinksLoading,
        refetch: refetchInvitationLinks,
    } = useSextforceMetricsTelegramInvitationLink(metricData._id, metricData.type);

    const handleCreateTelegramInviteLink = () => {
        const createShareLink = async () => {
            if (userContext.jwtToken && settingsContext.apiKey && 'userId' in params && params.userId && subscriber && metricData) {
                const query: string = `${settingsContext.routes.metrics.base}${params.userId}/${
                    metricData.type === 'promoCampaign' ? 'promocampaigns' : 'trials'
                }/${metricData._id}/invite`;

                setIsLoading(true);

                // Update campaign settings
                const invitationLink = await axios
                    .post(
                        query,
                        {},
                        {
                            headers: {
                                Authorization: userContext.jwtToken,
                                apiKey: settingsContext.apiKey,
                                'Content-Type': 'application/json',
                            },
                        },
                    )
                    .then(async response => response.data)
                    .catch(error => {
                        console.error(error);
                        setIsLoading(false);
                        handleHttpError(error, dialog);
                    });

                if (invitationLink && invitationLink.inviteCode) {
                    setTelegramLink(`https://t.me/OnlyStrugglesBot?start=-promo_-${invitationLink.inviteCode}`);

                    refetchInvitationLinks();
                }

                setIsLoading(false);
            }
        };

        createShareLink();
    };

    const handleDeleteTelegramInviteLink = (inviteId: string) => {
        const deleteLink = async (inviteId: string) => {
            if (userContext.jwtToken && settingsContext.apiKey && 'userId' in params && params.userId && subscriber && metricData) {
                const query: string = `${settingsContext.routes.metrics.base}${params.userId}/${
                    metricData.type === 'promoCampaign' ? 'promocampaigns' : 'trials'
                }/${metricData._id}/invite/${inviteId}`;

                setIsLoading(true);

                // Update campaign settings
                const deletedLink = await axios
                    .delete(query, {
                        headers: {
                            Authorization: userContext.jwtToken,
                            apiKey: settingsContext.apiKey,
                            'Content-Type': 'application/json',
                        },
                    })
                    .then(async response => response.data)
                    .catch(error => {
                        console.error(error);
                        setIsLoading(false);
                        handleHttpError(error, dialog);
                    });

                if (deletedLink && deletedLink._id) {
                    refetchInvitationLinks();
                }

                setIsLoading(false);
            }
        };

        deleteLink(inviteId);
    };

    return (
        <>
            {telegramLink && telegramLink.length > 0 && (
                <>
                    <Typography component={'span'} variant="body2" gutterBottom sx={{ display: 'block' }}>
                        Click on the link below to copy it to the clipboard.
                    </Typography>
                    <LinkWithCopyToClipboardBox link={telegramLink} theme={theme} />
                    <Typography component={'span'} variant="body2" sx={{ display: 'block' }}>
                        You will receive a notification on Telegram whenever someone registers with this link.
                    </Typography>
                </>
            )}

            {!telegramLink && (
                <Grid container spacing={2} flexGrow={1}>
                    <Grid item xs={12}>
                        <Typography component={'span'} variant="body2" gutterBottom sx={{ display: 'block' }}>
                            To receive daily updated on the progress of this {metricData && metricTypeName(metricData.type)}, your colleague
                            needs to register with the bot on Telegram.
                        </Typography>
                        <Typography component={'span'} variant="body2" gutterBottom sx={{ display: 'block' }}>
                            Create a person invitation link by clicking on the button below.
                        </Typography>
                        <Typography component={'span'} variant="body2" sx={{ display: 'block' }}>
                            Once registered, your colleague will receive daily updates for as long as there is activity on this{' '}
                            {metricData && metricTypeName(metricData.type)}.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} textAlign={'center'}>
                        {!telegramLink && (
                            <Button
                                variant="contained"
                                size="medium"
                                disabled={isLoading}
                                onClick={handleCreateTelegramInviteLink}
                                sx={{ mt: 2 }}
                            >
                                Create Invitation Link
                            </Button>
                        )}
                    </Grid>
                </Grid>
            )}

            <Grid container spacing={0.5} flexGrow={1} alignItems={'center'} sx={{ mt: 2 }}>
                <Grid item xs={12}>
                    <Typography component={'span'} variant="h6" gutterBottom sx={{ display: 'block' }}>
                        Manage Invitation Links
                    </Typography>
                </Grid>
                {isInvitationLinksLoading ? (
                    <Grid item xs={12}>
                        <Skeleton variant="text" width={'100%'} height={100} sx={{ mt: 2, mb: 2 }} />
                    </Grid>
                ) : (
                    <>
                        {invitationLinks && invitationLinks.length > 0 ? (
                            <Grid
                                item
                                xs={12}
                                sx={{
                                    display: 'block',
                                    overflowX: 'auto',
                                }}
                            >
                                <Table
                                    size="small"
                                    sx={{
                                        // No padding on the left and right of the table
                                        '& td': {
                                            '&:first-child': {
                                                paddingLeft: '0px',
                                            },
                                            '&:last-child': {
                                                paddingRight: '0px',
                                            },
                                        },
                                        '& th': {
                                            '&:first-child': {
                                                paddingLeft: '0px',
                                            },
                                            '&:last-child': {
                                                paddingRight: '0px',
                                            },
                                        },
                                    }}
                                >
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Telegram Username</TableCell>
                                            <TableCell width={100}>Registered</TableCell>
                                            <TableCell width={100}>Last Sent</TableCell>
                                            <TableCell width={60} align="center">
                                                Delete
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    {invitationLinks.map(invitationLink => (
                                        <InvitationLinkTableRow
                                            key={invitationLink._id}
                                            invitationLink={invitationLink}
                                            handleDeleteTelegramInviteLink={handleDeleteTelegramInviteLink}
                                            dialog={dialog}
                                            theme={theme}
                                        />
                                    ))}
                                </Table>
                            </Grid>
                        ) : (
                            <Grid item xs={12}>
                                <Typography component={'span'} variant="body2" sx={{ display: 'block' }}>
                                    No invitation links found.
                                </Typography>
                            </Grid>
                        )}
                    </>
                )}
            </Grid>
        </>
    );
};

export default SextforceMetricsShareDialogTelegram;
