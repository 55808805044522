import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControlLabel,
    Grid,
    InputAdornment,
    Radio,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import { green } from '@mui/material/colors';
import useTheme from '@mui/material/styles/useTheme';
import { Theme } from '@mui/system';
import Dinero from 'dinero.js';
import moment from 'moment-timezone';
import { useEffect, useState } from 'react';
import { d2f, titleCase } from '../../../../utils/common';

type SextforceAssignTransactionsReviewProofDialogProps = {
    transaction: any | null;
    timezone: string;
    agents: any[];
    open: boolean;
    onCancel: () => void;
    onApprove: (
        id: string,
        assignedAgent: string,
        salesProofs: any[] | undefined,
        showWarning: boolean,
        proofId: string | null,
        overrideCommission: boolean,
        commissionPercentOverride: number | null,
    ) => void;
};

const SextforceAssignTransactionsReviewProofDialog = (props: SextforceAssignTransactionsReviewProofDialogProps) => {
    const { transaction, timezone, agents, open, onCancel, onApprove } = props;
    const theme: Theme = useTheme();
    const [overrideCommission, setOverrideCommission] = useState<boolean>(
        transaction && transaction.commissionPercentOverride ? true : false,
    );
    const [commissionPercentOverride, setCommissionPercentOverride] = useState<number | null>(null);
    const [commissionError, setCommissionError] = useState<string | null>(null);

    useEffect(() => {
        if (transaction) {
            // Load the commission override value
            setOverrideCommission(transaction.commissionPercentOverride ? true : false);
            setCommissionPercentOverride(transaction.commissionPercentOverride ? transaction.commissionPercentOverride : null);
        }
    }, [transaction]);

    return (
        <Dialog fullWidth={true} maxWidth="md" open={open} scroll="paper" onClose={onCancel}>
            <DialogTitle>Review Agent's Sales Proof</DialogTitle>
            <DialogContent dividers={true}>
                <Typography variant="body1">
                    Find the proof of sale in the list below that matches this transaction and click APPROVE to assign this sale to the
                    agent who submitted the sales proof.
                </Typography>

                <Grid
                    container
                    flexGrow={1}
                    alignItems="center"
                    spacing={1}
                    sx={{ marginTop: theme.spacing(2), marginBottom: theme.spacing(1) }}
                >
                    <Grid item xs={12}>
                        <Typography variant="h6">
                            Transaction Details - {transaction && transaction.product && titleCase(transaction.product)}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={10}>
                        <Grid container flexGrow={1} alignItems="center" spacing={1} sx={{ marginLeft: theme.spacing(0.5) }}>
                            <Grid item xs={12}>
                                <Grid container spacing={1} alignItems="center">
                                    <Grid item xs={4} md={2}>
                                        Date:
                                    </Grid>
                                    <Grid item xs={8} md={4} style={{ fontFamily: 'monospace' }}>
                                        <Box sx={{ fontWeight: 'bold' }}>
                                            {transaction && moment(transaction.createdAt).tz(timezone).format('L hh:mm a')}
                                        </Box>
                                    </Grid>
                                    <Grid item xs={4} md={2}>
                                        Fraction:
                                    </Grid>
                                    <Grid item xs={8} md={4} style={{ fontFamily: 'monospace' }}>
                                        {transaction && d2f(transaction.fraction).toLocaleString(undefined, { minimumFractionDigits: 2 })}
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={1} alignItems="center">
                                    <Grid item xs={4} md={2}>
                                        Name:
                                    </Grid>
                                    <Grid item xs={8} md={4}>
                                        {transaction && transaction.user.name}
                                    </Grid>
                                    <Grid item xs={4} md={2}>
                                        Username:
                                    </Grid>
                                    <Grid item xs={8} md={4}>
                                        {transaction && transaction.user.username}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={12} md={2}>
                        <Box
                            sx={{
                                backgroundColor: green[100],
                                borderRadius: '4px',
                                padding: theme.spacing(1),
                                textAlign: 'center',
                            }}
                        >
                            Amount
                            <br />
                            <Typography variant="h6" style={{ fontFamily: 'monospace' }}>
                                {transaction &&
                                    Dinero({
                                        amount: Math.round(d2f(transaction.amount) * 100),
                                        currency: 'USD',
                                    }).toFormat()}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>

                <Grid
                    container
                    flexGrow={1}
                    alignItems="center"
                    spacing={0}
                    sx={{ marginTop: theme.spacing(2), marginBottom: theme.spacing(1) }}
                >
                    <Grid item xs={12}>
                        <Typography variant="h6">Commission</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={0} alignItems="center" sx={{ paddingLeft: theme.spacing(0.5) }}>
                            <Grid item xs={12} md={'auto'}>
                                <FormControlLabel
                                    checked={!overrideCommission}
                                    control={<Radio size="small" />}
                                    label="Agent's Default"
                                    onChange={() => setOverrideCommission(false)}
                                />
                            </Grid>
                            <Grid item xs="auto" md="auto">
                                <FormControlLabel
                                    checked={overrideCommission}
                                    control={<Radio size="small" />}
                                    label="Override:"
                                    onChange={() => setOverrideCommission(true)}
                                />
                            </Grid>
                            <Grid item xs md>
                                <TextField
                                    name="commissionPercentOverride"
                                    label="Commission"
                                    type={'number'}
                                    value={commissionPercentOverride || undefined}
                                    required={true}
                                    helperText={commissionError}
                                    error={overrideCommission && commissionError ? true : false}
                                    size="small"
                                    disabled={!overrideCommission}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                    }}
                                    onChange={e => {
                                        if (e.target.value.length === 0) {
                                            setCommissionPercentOverride(null);
                                            setCommissionError('Please enter a valid number');
                                        } else {
                                            try {
                                                setCommissionPercentOverride(Number.parseFloat(e.target.value));
                                                setCommissionError(null);
                                            } catch (err) {
                                                setCommissionPercentOverride(null);
                                                setCommissionError('Please enter a valid number');
                                            }
                                        }
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid
                    container
                    flexGrow={1}
                    alignItems="center"
                    spacing={1}
                    sx={{ marginTop: theme.spacing(1), marginBottom: theme.spacing(1) }}
                >
                    <Grid item xs={12}>
                        <Typography variant="h6" display={'inline'}>
                            Sales Proof Details
                        </Typography>{' '}
                        ({transaction && transaction.salesProof.length} found)
                    </Grid>
                    <Grid item xs={12}>
                        {transaction &&
                            transaction.salesProof &&
                            transaction.salesProof.map((proof: any, index: number) => (
                                <Grid
                                    container
                                    flexGrow={1}
                                    alignItems="stretch"
                                    spacing={0}
                                    sx={{ margin: theme.spacing(1) }}
                                    key={proof._id}
                                >
                                    <Grid item xs={12} md={6}>
                                        <Grid container flexGrow={0} alignItems="baseline" spacing={1}>
                                            <Grid item xs={6}>
                                                Match Level:
                                            </Grid>
                                            <Grid item xs={6} style={{ fontWeight: 'bold' }}>
                                                {moment(transaction.createdAt)
                                                    .startOf('minute')
                                                    .isSame(moment(proof.dateTime).startOf('minute')) ? (
                                                    <span style={{ color: theme.palette.success.dark }}>STRONG</span>
                                                ) : (
                                                    <Tooltip
                                                        title={`Difference of ${moment(transaction.createdAt).diff(
                                                            proof.dateTime,
                                                            'seconds',
                                                            true,
                                                        )} seconds`}
                                                    >
                                                        <span
                                                            style={{
                                                                color: theme.palette.warning.main,
                                                                textDecorationStyle: 'dashed',
                                                                textDecorationLine: 'underline',
                                                            }}
                                                        >
                                                            WEAK
                                                        </span>
                                                    </Tooltip>
                                                )}{' '}
                                                <Tooltip
                                                    title={
                                                        <span>
                                                            STRONG match level means the date and time of the proof match the transaction
                                                            exactly.
                                                            <br />
                                                            <br />
                                                            WEAK match level means there are some minutes difference between the date and
                                                            time of the proof and the transaction - please check carefully.
                                                        </span>
                                                    }
                                                >
                                                    <InfoOutlinedIcon sx={{ fontSize: 14 }} />
                                                </Tooltip>
                                            </Grid>
                                            <Grid item xs={6}>
                                                Date & Time:
                                            </Grid>
                                            <Grid item xs={6} style={{ fontFamily: 'monospace' }}>
                                                <Box sx={{ fontWeight: 'bold' }}>
                                                    {moment(proof.dateTime).tz(timezone).format('L hh:mm a')}
                                                </Box>
                                            </Grid>
                                            {proof.timezone && (
                                                <>
                                                    <Grid item xs={6}>
                                                        Agent's Date & Time:
                                                    </Grid>
                                                    <Grid item xs={6} style={{ fontFamily: 'monospace' }}>
                                                        <Box sx={{ fontWeight: 'bold' }}>
                                                            {moment(proof.dateTime).tz(proof.timezone).format('L hh:mm a')}
                                                        </Box>
                                                    </Grid>
                                                </>
                                            )}
                                            <Grid item xs={6}>
                                                Amount:
                                            </Grid>
                                            <Grid
                                                item
                                                xs={6}
                                                style={{ fontFamily: 'monospace', color: theme.palette.success.dark, fontWeight: 'bold' }}
                                            >
                                                {transaction &&
                                                    Dinero({
                                                        amount: Math.round(d2f(proof.amount) * 100),
                                                        currency: 'USD',
                                                    }).toFormat()}
                                            </Grid>
                                            <Grid item xs={6}>
                                                Agent:
                                            </Grid>
                                            <Grid item xs={6}>
                                                {agents && agents.find(agent => agent._id === proof.agentId) ? (
                                                    <>
                                                        {agents.find(agent => agent._id === proof.agentId).name}{' '}
                                                        <Tooltip title={proof.agentId}>
                                                            <InfoOutlinedIcon sx={{ fontSize: 14 }} />
                                                        </Tooltip>
                                                    </>
                                                ) : (
                                                    'Agent Not Found!!!'
                                                )}
                                            </Grid>
                                            <Grid item xs={6}>
                                                Agent Commission:
                                            </Grid>
                                            <Grid item xs={6} sx={{ fontFamily: 'monospace' }}>
                                                {`${agents.find(agent => agent._id === proof.agentId)?.commissionPercent}%`}
                                            </Grid>
                                            <Grid item xs={12}>
                                                Notes:
                                            </Grid>
                                            <Grid item xs={12}>
                                                <div style={{ paddingLeft: theme.spacing(1), paddingRight: theme.spacing(1) }}>
                                                    {proof.notes}
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} textAlign="center">
                                                <Button
                                                    variant="contained"
                                                    color={proof.assignedTransaction ? 'success' : 'primary'}
                                                    size="large"
                                                    disabled={overrideCommission && typeof commissionPercentOverride !== 'number'}
                                                    onClick={() => {
                                                        onApprove(
                                                            transaction._id,
                                                            proof.agentId,
                                                            transaction.salesProof,
                                                            false,
                                                            proof._id,
                                                            overrideCommission,
                                                            commissionPercentOverride,
                                                        );
                                                        onCancel();
                                                    }}
                                                    sx={{ marginBottom: theme.spacing(1) }}
                                                >
                                                    {proof.assignedTransaction ? 'Approved!' : 'Approve'}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        {proof.imageUrl && <img src={proof.imageUrl} width="100%" alt={proof._id} />}
                                    </Grid>
                                    {index < transaction.salesProof.length - 1 && (
                                        <Grid item xs={12}>
                                            <Divider />
                                        </Grid>
                                    )}
                                </Grid>
                            ))}
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        onCancel();
                    }}
                >
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default SextforceAssignTransactionsReviewProofDialog;
