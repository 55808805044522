import axios from 'axios';
import Joi from 'joi';
import { useDialog } from 'muibox';
import { useContext } from 'react';
import { useQuery } from 'react-query';
import { SettingsContext } from '../store/SettingsContext';
import { UserContext } from '../store/UserContext';
import { handleHttpError } from '../utils/common';

export interface OnlyFansSubscriberCategory {
    _id?: string;
    username: string;
    name: string;
    description?: string;
    color?: string;
}

export const OnlyFansSubscriberCategorySchema = Joi.object<OnlyFansSubscriberCategory>({
    _id: Joi.string().optional(),
    username: Joi.string().required(),
    name: Joi.string().required(),
    description: Joi.string().optional(),
    color: Joi.string().optional(),
})
    .options({ stripUnknown: true })
    .required();

const useSubscriberCategories = () => {
    const userContext = useContext(UserContext);
    const settingsContext = useContext(SettingsContext);
    const dialog = useDialog();

    // Fetch Follow-Back Overview
    const fetchSubscriberCategories = async (): Promise<OnlyFansSubscriberCategory[] | void> => {
        if (userContext.jwtToken && settingsContext.apiKey && userContext.user.username) {
            const query: string = `${settingsContext.routes.categories.base}`;

            return axios
                .get(query, {
                    headers: {
                        Authorization: userContext.jwtToken,
                        apiKey: settingsContext.apiKey,
                    },
                })
                .then(async response => response.data as OnlyFansSubscriberCategory[])
                .catch(error => {
                    console.error(error);
                    handleHttpError(error, dialog);
                });
        }
    };

    const categories = useQuery(
        ['subscriberCategories', userContext.jwtToken],
        () => {
            return fetchSubscriberCategories();
        },
        {
            refetchOnWindowFocus: false,
            // Stake time 1 hour
            staleTime: 60 * 60 * 1000,
            enabled: userContext.jwtToken ? true : false,
        },
    );

    const addCategory = async (category: OnlyFansSubscriberCategory) => {
        if (userContext.jwtToken && settingsContext.apiKey && userContext.user.username) {
            const query: string = `${settingsContext.routes.categories.base}`;

            return axios
                .post(query, category, {
                    headers: {
                        Authorization: userContext.jwtToken,
                        apiKey: settingsContext.apiKey,
                    },
                })
                .then(async response => response.data as OnlyFansSubscriberCategory)
                .catch(error => {
                    console.error(error);
                    handleHttpError(error, dialog);
                });
        }
    };

    const updateCategoty = async (category: OnlyFansSubscriberCategory) => {
        if (userContext.jwtToken && settingsContext.apiKey && userContext.user.username) {
            const query: string = `${settingsContext.routes.categories.base}${category._id}`;

            return axios
                .put(query, category, {
                    headers: {
                        Authorization: userContext.jwtToken,
                        apiKey: settingsContext.apiKey,
                    },
                })
                .then(async response => response.data as OnlyFansSubscriberCategory)
                .catch(error => {
                    console.error(error);
                    handleHttpError(error, dialog);
                });
        }
    };

    const deleteCategory = async (categoryId: string) => {
        if (userContext.jwtToken && settingsContext.apiKey && userContext.user.username) {
            const query: string = `${settingsContext.routes.categories.base}${categoryId}`;

            return axios
                .delete(query, {
                    headers: {
                        Authorization: userContext.jwtToken,
                        apiKey: settingsContext.apiKey,
                    },
                })
                .then(async response => response.data as OnlyFansSubscriberCategory)
                .catch(error => {
                    console.error(error);
                    handleHttpError(error, dialog);
                });
        }
    };

    return {
        ...categories,
        addCategory,
        updateCategoty,
        deleteCategory,
    };
};

export default useSubscriberCategories;
