import axios from 'axios';
import { useDialog } from 'muibox';
import { useContext } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { SettingsContext } from '../store/SettingsContext';
import { UserContext } from '../store/UserContext';
import { handleHttpError } from '../utils/common';

const useSextforceMetricsOnly = (
    targetSubscriberId: string,
    includeInactive: boolean,
    includeCampaigns: boolean,
    includeTrials: boolean,
    includePromos: boolean,
) => {
    const userContext = useContext(UserContext);
    const settingsContext = useContext(SettingsContext);
    const dialog = useDialog();
    const params = useParams();

    // Fetch Fans Counters
    const fetchAccountFansCountersAll = async (): Promise<any[]> => {
        const query: string = `${settingsContext.routes.metrics.base}${params.userId}/allPromos/${targetSubscriberId}?${new URLSearchParams(
            {
                includeInactive: includeInactive ? 'true' : 'false',
                includeCampaigns: includeCampaigns ? 'true' : 'false',
                includeTrials: includeTrials ? 'true' : 'false',
                includePromos: includePromos ? 'true' : 'false',
            },
        )}`;

        const data = await axios
            .get(query, {
                headers: {
                    Authorization: userContext.jwtToken,
                    apiKey: settingsContext.apiKey,
                },
            })
            .then(async response => response.data)
            .catch(error => {
                console.error(error);
                handleHttpError(error, dialog);

                return [];
            });

        return data;
    };

    return useQuery(
        ['metricsOnly', userContext.jwtToken, params.userId, targetSubscriberId, includeCampaigns, includeTrials, includePromos],
        () => fetchAccountFansCountersAll(),
        {
            refetchOnWindowFocus: false,
            staleTime: 60 * 1000 * 5,
            enabled: userContext.jwtToken && settingsContext.apiKey && params.userId && targetSubscriberId ? true : false,
        },
    );
};

export default useSextforceMetricsOnly;
