import { Card, CardContent, Grid, Skeleton, Typography, useMediaQuery } from '@mui/material';
import { Theme } from '@mui/system';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { objectIdToDate } from '../../../../../../utils/common';

const generateData = (data: any[]) => {
    const result = {
        data: data.map(d => {
            return {
                time: objectIdToDate(d._id).valueOf(),
                subscribeCounts: d.payload.subscribeCounts,
                claimsCount: d.payload.claimsCount,
            };
        }),
        // min: Math.round(Math.min(...data.map((d: any) => d.count))),
        // max: Math.round(Math.max(...data.map((d: any) => d.count))),
        // average: Math.round(data.reduce((a: any, b: any) => a + b.count, 0) / data.length),
    };

    return result;
};

const CustomTooltip = ({ active, payload, theme }: any) => {
    if (active && payload && payload.length) {
        return (
            <div>
                <Grid
                    container
                    flexGrow={0}
                    alignItems="center"
                    spacing={1}
                    sx={{
                        backgroundColor: '#fff',
                        borderRadius: 4,
                        border: '1px solid #999',
                        maxWidth: 200,
                    }}
                >
                    <Grid item xs={12}>
                        <span style={{ fontWeight: 'bold' }}>{moment(payload[0].payload.time).format('ddd Do, hh:mm A')}</span>
                    </Grid>
                    {payload.map((item: any, index: number) => (
                        <Grid item xs={12} key={index}>
                            {item.dataKey === 'claimsCount' && (
                                <Grid container flexGrow={1} alignItems="center">
                                    <Grid item xs={4} sx={{ color: theme.palette.primary.main }}>
                                        Claims:
                                    </Grid>
                                    <Grid item xs={8} textAlign={'right'} sx={{ color: theme.palette.primary.main, paddingRight: '8px' }}>
                                        {item.value && typeof item.value === 'number' ? item.value.toLocaleString() : 0}
                                    </Grid>
                                </Grid>
                            )}

                            {item.dataKey === 'subscribeCounts' && (
                                <Grid container flexGrow={1} alignItems="center">
                                    <Grid item xs={4} sx={{ color: theme.palette.secondary.main }}>
                                        Subscriptions:
                                    </Grid>
                                    <Grid item xs={8} textAlign={'right'} sx={{ color: theme.palette.primary.main, paddingRight: '8px' }}>
                                        {item.value && typeof item.value === 'number' ? item.value.toLocaleString() : 0}
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                    ))}
                </Grid>
            </div>
        );
    }

    return null;
};

type Props = {
    trialCounters: any;
    trialCountersLoading: boolean;
    timezone: string;
    theme: Theme;
};

const SextforceMetricsTrialCountersGraph = (props: Props) => {
    const { trialCounters, trialCountersLoading, timezone, theme } = props;
    const [dataMain, setDataMain] = useState<any>({ data: [], min: 0, max: 0, average: 0 });
    const largeScreen = useMediaQuery(theme.breakpoints.up('sm'));

    useEffect(() => {
        if (!trialCountersLoading && trialCounters && trialCounters.metricsDatas && trialCounters.metricsDatas.length > 0) {
            setDataMain(generateData(trialCounters.metricsDatas));
        } else {
            setDataMain([]);
        }
    }, [trialCounters, trialCountersLoading]);

    const Chart = (chartData: any) => (
        <ResponsiveContainer width="100%" height="100%">
            <LineChart
                data={chartData && chartData.data ? chartData.data : []}
                margin={{
                    top: 0,
                    right: largeScreen ? -10 : -20,
                    left: largeScreen ? -10 : -20,
                    bottom: 50,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                    dataKey="time"
                    type="number"
                    scale={'time'}
                    name="Time"
                    tickFormatter={value => (value && value !== 'auto' ? moment(value).tz(timezone).format('L') : '')}
                    domain={chartData && chartData.data && chartData.data.length > 0 ? ['dataMin', 'dataMax'] : ['auto', 'auto']}
                    interval={'preserveStartEnd'}
                    tick={{ fontSize: 13 }}
                    angle={-90}
                    dy={35}
                    dx={-5}
                />
                <YAxis
                    yAxisId="claimsCount"
                    dataKey={'claimsCount'}
                    scale={'linear'}
                    name="Claims Count"
                    domain={[(dataMin: number) => Math.abs(dataMin * 0.9), (dataMax: number) => Math.abs(dataMax * 1.1)]}
                    tickFormatter={(value: number) => value.toLocaleString()}
                    tick={{ fill: theme.palette.primary.main, fontSize: 13 }}
                />
                <Line
                    yAxisId="claimsCount"
                    type="linear"
                    dataKey="claimsCount"
                    stroke={theme.palette.primary.main}
                    activeDot={{ r: 0 }}
                    dot={{ r: 0 }}
                />
                <YAxis
                    yAxisId="subscribeCounts"
                    dataKey={'subscribeCounts'}
                    scale={'linear'}
                    name="Subscribers Count"
                    domain={[0, (dataMax: number) => Math.abs(dataMax * 1.1)]}
                    tickFormatter={(value: number) => value.toLocaleString()}
                    orientation="right"
                    tick={{ fill: theme.palette.secondary.main, fontSize: 13 }}
                />
                <Line
                    yAxisId="subscribeCounts"
                    type="linear"
                    dataKey="subscribeCounts"
                    stroke={theme.palette.secondary.main}
                    activeDot={{ r: 0 }}
                    dot={{ r: 0 }}
                />
                <Tooltip content={<CustomTooltip payload={chartData.data} theme={theme} />} />
                <Legend
                    verticalAlign="top"
                    height={36}
                    formatter={(value: string) => (
                        <span style={{ fontSize: 13 }}>{value === 'subscribeCounts' ? 'Subscribers Count' : 'Claims Count'}</span>
                    )}
                />
            </LineChart>
        </ResponsiveContainer>
    );

    return (
        <Card sx={{ width: '100%', marginBottom: theme.spacing(4) }}>
            <CardContent>
                <Grid container flexGrow={1} spacing={0} sx={{ marginBottom: theme.spacing(1) }}>
                    <Grid item xs={12}>
                        <Typography variant="h5" sx={{ marginBottom: theme.spacing(1) }}>
                            Claims Count and Subscribtions Count by Date
                        </Typography>
                        <Typography variant="body1" sx={{ marginBottom: theme.spacing(1) }}>
                            The following graph shows how many fans claimed the trial or promo, and how many subscribed via this link,
                            sorted by date since the start of the campaign.
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container flexGrow={1} spacing={0} sx={{ padding: 0 }}>
                    <Grid item xs={12} sx={{ height: { xs: 300, sm: 500 } }}>
                        {trialCountersLoading ? <Skeleton /> : Chart(dataMain)}
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default SextforceMetricsTrialCountersGraph;
