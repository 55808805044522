import axios from 'axios';
import { useContext } from 'react';
import { SettingsContext } from '../store/SettingsContext';
import { UserContext } from '../store/UserContext';

const useAxios = () => {
    const userContext = useContext(UserContext);
    const settingsContext = useContext(SettingsContext);

    const instance = axios.create({
        headers: {
            Authorization: userContext.jwtToken,
            apiKey: settingsContext.apiKey,
        },
    });

    return instance;
};

export default useAxios;
