import React from 'react';
import { Card, CardContent, Divider, Grid } from '@mui/material';
import { Theme } from '@mui/system';
import FormikCardSectionHeadingWithSwitch from '../FormikCardSectionHeadingWithSwitch';
import FormikCheckbox from '../FormikCheckBox';
import FormikDateTime from '../FormikDateTime';

type AdminSubscriberEditScheduledPostProps = {
    formik: any;
    theme: Theme;
    showServiceScheduledPosts: boolean;
    setShowServiceScheduledPosts: React.Dispatch<React.SetStateAction<boolean>>;
};

const AdminSubscriberEditScheduledPost = (props: AdminSubscriberEditScheduledPostProps) => {
    const { formik, theme, showServiceScheduledPosts, setShowServiceScheduledPosts } = props;

    return (
        <Card variant="elevation" sx={{ marginBottom: theme.spacing(4) }}>
            <CardContent>
                <FormikCardSectionHeadingWithSwitch
                    formik={formik}
                    theme={theme}
                    fieldName="scheduledPosts"
                    switchParam={showServiceScheduledPosts}
                    setSwitchParam={setShowServiceScheduledPosts}
                    label="Scheduled Posts"
                />
                <Grid container spacing={2} sx={{ display: showServiceScheduledPosts ? 'flex' : 'none' }}>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    <Grid item xs={12}>
                        <FormikCheckbox formik={formik} fieldName="scheduledPosts.active" label="Active" />
                    </Grid>
                    <Grid item xs={12}>
                        <FormikDateTime formik={formik} fieldName="scheduledPosts.paidDate" label="Date Paid" />
                    </Grid>
                    <Grid item xs={12}>
                        <FormikCheckbox formik={formik} fieldName="scheduledPosts.postImages" label="Post Images" />
                    </Grid>
                    <Grid item xs={12}>
                        <FormikCheckbox formik={formik} fieldName="scheduledPosts.postVideos" label="Post Videos" />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default AdminSubscriberEditScheduledPost;
