import axios from 'axios';
import { useDialog } from 'muibox';
import { useContext, useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { SettingsContext } from '../store/SettingsContext';
import { UserContext } from '../store/UserContext';
import { handleHttpError } from '../utils/common';

const useSextforceBigBrotherRestrictedWords = () => {
    const userContext = useContext(UserContext);
    const settingsContext = useContext(SettingsContext);
    const dialog = useDialog();
    const params = useParams();

    const [sextforceBigBrotherRestrictedWordsWhiteListLoading, setSextforceBigBrotherRestrictedWordsWhiteListLoading] = useState(false);
    const [sextforceBigBrotherRestrictedWordsResetCountersLoading, setSextforceBigBrotherRestrictedWordsResetCountersLoading] =
        useState(false);

    // Fetch the restricted words
    const fetchRestrictedWords = async (): Promise<Set<string> | void> => {
        if (userContext.jwtToken && settingsContext.apiKey && userContext.user.username) {
            const query: string = `${settingsContext.routes.sextforce.base}${params.userId}/bigBrother/restrictedWords`;

            return axios
                .get(query, {
                    headers: {
                        Authorization: userContext.jwtToken,
                        apiKey: settingsContext.apiKey,
                    },
                })
                .then(async response => new Set(response.data as string[]))
                .catch(error => {
                    console.error(error);
                    handleHttpError(error, dialog);
                });
        }
    };

    const restrictedWordsMaster = useQuery(
        ['restrictedWordsMaster', userContext.jwtToken, params.userId],
        () => {
            return fetchRestrictedWords();
        },
        {
            refetchOnWindowFocus: false,
            // Stake time 1 hour
            staleTime: 60 * 60 * 1000,
            enabled: userContext.jwtToken && settingsContext.apiKey && userContext.user.username && params.userId ? true : false,
        },
    );

    /**
     * Sets the white list of restricted words
     * @param whiteList Array of strings
     * @returns
     */
    const setSextforceBigBrotherRestrictedWordsWhiteList = async (whiteList: string[]): Promise<any> => {
        if (userContext.jwtToken && settingsContext.apiKey && userContext.user.username) {
            const query: string = `${settingsContext.routes.sextforce.base}${params.userId}/bigBrother/restrictedWords/whiteList`;

            setSextforceBigBrotherRestrictedWordsWhiteListLoading(true);

            return axios
                .put(
                    query,
                    {
                        whiteList,
                    },
                    {
                        headers: {
                            Authorization: userContext.jwtToken,
                            apiKey: settingsContext.apiKey,
                        },
                    },
                )
                .then(async response => {
                    setSextforceBigBrotherRestrictedWordsWhiteListLoading(false);

                    return response.data;
                })
                .catch(error => {
                    console.error(error);
                    setSextforceBigBrotherRestrictedWordsWhiteListLoading(false);
                    handleHttpError(error, dialog);
                });
        }
    };

    const setSextforceBigBrotherRestrictedWordsResetCounters = async (): Promise<any> => {
        if (userContext.jwtToken && settingsContext.apiKey && userContext.user.username) {
            const query: string = `${settingsContext.routes.sextforce.base}${params.userId}/bigBrother/restrictedWords/counters`;

            setSextforceBigBrotherRestrictedWordsResetCountersLoading(true);

            return axios
                .delete(query, {
                    headers: {
                        Authorization: userContext.jwtToken,
                        apiKey: settingsContext.apiKey,
                    },
                })
                .then(async response => {
                    setSextforceBigBrotherRestrictedWordsResetCountersLoading(false);

                    return response.data;
                })
                .catch(error => {
                    console.error(error);
                    setSextforceBigBrotherRestrictedWordsResetCountersLoading(false);
                    handleHttpError(error, dialog);
                });
        }
    };

    return {
        ...restrictedWordsMaster,
        setSextforceBigBrotherRestrictedWordsWhiteList,
        sextforceBigBrotherRestrictedWordsWhiteListLoading,
        setSextforceBigBrotherRestrictedWordsResetCounters,
        SextforceBigBrotherRestrictedWordsResetCountersLoading: sextforceBigBrotherRestrictedWordsResetCountersLoading,
    };
};

export default useSextforceBigBrotherRestrictedWords;
