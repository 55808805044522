import EditIcon from '@mui/icons-material/Edit';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import { Box, Chip, Collapse, IconButton, Stack, TableCell, TableRow, Theme, Tooltip, Typography } from '@mui/material';
import dinero from 'dinero.js';
import moment from 'moment';
import { useState } from 'react';
import {
    OnlyFansStreamStats,
    SextforceLiveStream,
    SextforceLiveStreamWithAmount,
} from '../../../../../hooks/useSextforceLiveStreamAddTippersToList';
import { secondsToTimeString } from '../../../../../utils/common';
import NA from '../../../../common/NA';
import SextforceLiveStreamAddTippersToListTippersEditDialog from './SextforceLiveStreamAddTippersToListEditDialog';
import SextforceLiveStreamAddTippersToListTippersListDialog from './SextforceLiveStreamAddTippersToListTippersListDialog';
import SextforceLiveStreamStatsGraph from './SextforceLiveStreamStatsGraph';
import SextforceLiveStreamSummaryBox from './SextforceLiveStreamSummaryBox';
import SextforceLiveStreamTopTippersBox from './SextforceLiveStreamTopTippersBox';

type Props = {
    subscriber: any;
    liveStream: SextforceLiveStreamWithAmount;
    referchLiveStreams: () => void;
    sextforceLiveStreamAddTippersToListEditTitleAndDescription: (
        streamId: number,
        title: string | undefined,
        description: string | undefined,
    ) => Promise<SextforceLiveStream>;
    theme: Theme;
};

const SextforceLiveStreamAddTippersToListTableStremsRow = (props: Props) => {
    const { subscriber, liveStream, referchLiveStreams, sextforceLiveStreamAddTippersToListEditTitleAndDescription, theme } = props;
    const stats: OnlyFansStreamStats.Response | undefined = liveStream.stats;

    const [open, setOpen] = useState(false);

    const [streamEditDialogOpen, setStreamEditDialogOpen] = useState(false);
    const [tippersListDialogOpen, setTippersListDialogOpen] = useState(false);

    const getTipsAmount = (): number | undefined => {
        if (liveStream.stats) {
            return liveStream.stats.stream_tip_sum;
        }

        if (liveStream.totalAmount) {
            return liveStream.totalAmount;
        }

        return undefined;
    };

    const tipsAmount = getTipsAmount();

    return (
        <>
            <TableRow
                sx={{
                    '& > *': { borderBottom: 'unset' },
                    cursor: 'pointer',
                    '&:hover': { backgroundColor: theme.palette.action.hover },
                }}
            >
                <TableCell>
                    <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell>
                    <Stack direction="column" spacing={0.5}>
                        <div>{liveStream.streamId}</div>
                        <Typography variant="inherit" color="textSecondary">
                            {moment(liveStream.createdAt).format('L LT')}
                        </Typography>
                    </Stack>
                </TableCell>
                <TableCell>
                    <Stack direction="column" spacing={0.5}>
                        <Typography variant="inherit" color="textPrimary" noWrap>
                            {liveStream.title || 'Untitled'}
                        </Typography>
                        {liveStream.description && (
                            <Typography variant="inherit" color="textSecondary">
                                {liveStream.description}
                            </Typography>
                        )}
                    </Stack>
                </TableCell>
                <TableCell align="center">
                    <Stack direction="row" spacing={0.5} alignItems="center" justifyContent={'center'}>
                        <Stack direction="column" spacing={0.5}>
                            <Typography variant="inherit">{stats?.stream_look_count.toLocaleString() || 0}</Typography>
                            {/* <Typography variant="subtitle2" color="textSecondary">
                                {autoRepost.endDate ? moment(autoRepost.endDate).format('L') : 'Indefinitely'}
                            </Typography> */}
                        </Stack>
                    </Stack>
                </TableCell>
                <TableCell align="center">
                    <Typography variant="inherit" color="textSecondary" noWrap>
                        {stats?.stream_tip_count.toLocaleString() || <NA />}
                    </Typography>
                </TableCell>
                <TableCell align="center">
                    <Chip
                        label={dinero({
                            amount: typeof tipsAmount !== 'undefined' ? Math.trunc(tipsAmount * 100) : 0,
                            currency: 'USD',
                        }).toFormat()}
                        color={typeof tipsAmount !== 'undefined' && tipsAmount !== 0 ? 'success' : 'default'}
                        sx={{
                            width: '100%',
                            fontFamily: 'monospace',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            justifyContent: 'flex-end',
                            color: theme.palette.common.white,
                            background:
                                typeof tipsAmount !== 'undefined' && tipsAmount !== 0
                                    ? theme.palette.success.main
                                    : theme.palette.grey[300],
                            '& .MuiChip-root': {},
                        }}
                    />
                </TableCell>
                <TableCell align="center">
                    <Typography variant="inherit" noWrap fontFamily={'monospace'}>
                        {!liveStream.endedAt && !liveStream.stats && 'Live'}
                        {liveStream.endedAt &&
                            !liveStream.stats &&
                            secondsToTimeString(moment(liveStream.endedAt).diff(liveStream.createdAt, 'seconds'))}
                        {liveStream.stats && secondsToTimeString(liveStream.stats.stream_duration)}
                    </Typography>
                </TableCell>
                <TableCell align="center">
                    <Stack direction="row" spacing={0.5} justifyContent={'center'} alignItems={'center'}>
                        <Tooltip title="Edit Stream Title and Description">
                            <IconButton
                                size="small"
                                color="primary"
                                onClick={e => {
                                    e.stopPropagation();
                                    setStreamEditDialogOpen(true);
                                }}
                            >
                                <EditIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                        {streamEditDialogOpen && (
                            <SextforceLiveStreamAddTippersToListTippersEditDialog
                                subscriber={subscriber}
                                liveStream={liveStream}
                                open={streamEditDialogOpen}
                                onClose={() => setStreamEditDialogOpen(false)}
                                handleSubmit={(title, description) => {
                                    sextforceLiveStreamAddTippersToListEditTitleAndDescription(
                                        liveStream.streamId,
                                        title,
                                        description,
                                    ).then(() => {
                                        referchLiveStreams();
                                        setStreamEditDialogOpen(false);
                                    });
                                }}
                                theme={theme}
                            />
                        )}
                        <Tooltip title="List of Tippers">
                            <IconButton
                                size="small"
                                color="info"
                                onClick={e => {
                                    e.stopPropagation();
                                    setTippersListDialogOpen(true);
                                }}
                            >
                                <PeopleAltIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                        {tippersListDialogOpen && (
                            <SextforceLiveStreamAddTippersToListTippersListDialog
                                subscriber={subscriber}
                                liveStream={liveStream}
                                open={tippersListDialogOpen}
                                onClose={() => setTippersListDialogOpen(false)}
                                theme={theme}
                            />
                        )}
                    </Stack>
                </TableCell>
            </TableRow>
            <TableRow
                sx={{
                    bgcolor: 'grey.50',
                }}
            >
                <TableCell style={{ paddingLeft: 0, paddingRight: 0, paddingTop: 0, paddingBottom: 0 }} colSpan={8}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box
                            component={'div'}
                            sx={
                                {
                                    // margin: 1,
                                }
                            }
                        >
                            <Stack direction="column" spacing={0.5}>
                                <SextforceLiveStreamStatsGraph liveStream={liveStream} />
                                <SextforceLiveStreamSummaryBox liveStream={liveStream} />
                                <SextforceLiveStreamTopTippersBox liveStream={liveStream} />
                            </Stack>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};

export default SextforceLiveStreamAddTippersToListTableStremsRow;
