import { Chip } from '@mui/material';
import dinero from 'dinero.js';

const SextforceMetricsROIAmountChip = ({ amount, amountType }: { amount: number | undefined; amountType: 'gross' | 'net' }) => {
    return typeof amount === 'undefined' ? undefined : (
        <Chip
            label={dinero({
                amount: Math.trunc(amount * 100),
                currency: 'USD',
            }).toFormat()}
            color={amount < 0 ? 'error' : amount > 0 ? 'success' : 'default'}
            sx={{
                width: '100%',
                fontFamily: 'monospace',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                justifyContent: 'flex-end',
            }}
        />
    );
};

export default SextforceMetricsROIAmountChip;
