import DeleteIcon from '@mui/icons-material/Delete';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import MoreTimeIcon from '@mui/icons-material/MoreTime';
import { Badge, Divider, IconButton, MenuItem } from '@mui/material';
import { useDialog } from 'muibox';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import useMessageUsersScheduledSessions, { ScheduledTask } from '../../../../hooks/useMessageUsersScheduledSessions';
import StyledLocalMenu from '../../../common/StyledLocalMenu';
import MessageUsersScheduledTaskChangeSettingsDialog from './MessageUsersScheduledTaskChangeSettingsDialog';

const MessageUsersScheduledListRowLocalMenu = ({ scheduledTask }: { scheduledTask: ScheduledTask }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const sortMenuOpen = Boolean(anchorEl);

    const dialog = useDialog();
    const { enqueueSnackbar } = useSnackbar();

    const { deleteScheduledMessage } = useMessageUsersScheduledSessions();

    const [openEditDialog, setOpenEditDialog] = useState<boolean>(false);

    const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        event.preventDefault();
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleDeleteScheduledMessage = (id: string) => {
        dialog
            .confirm({
                message: 'Are you sure you want to delete this scheduled message?',
                ok: { text: 'Delete', variant: 'contained', color: 'error' },
                cancel: { text: 'Cancel' },
                title: 'Delete Scheduled Message',
            })
            .then(async () => {
                const result = await deleteScheduledMessage(id);

                if (result && result.success) {
                    enqueueSnackbar('Scheduled message deleted.', { variant: 'success' });
                } else {
                    enqueueSnackbar('Failed to delete scheduled message.', { variant: 'error' });
                }
            })
            .catch(() => {});
    };

    return (
        <>
            <IconButton onClick={handleMenuClick}>
                <Badge color="success" variant="dot" invisible={!(scheduledTask.repeatEveryAmount && scheduledTask.repeatEveryUnit)}>
                    <MoreHorizIcon />
                </Badge>
            </IconButton>
            <StyledLocalMenu
                id="menu"
                MenuListProps={{
                    'aria-labelledby': 'button',
                }}
                anchorEl={anchorEl}
                open={sortMenuOpen}
                onClose={handleMenuClose}
            >
                <MenuItem
                    color={scheduledTask.repeatEveryAmount && scheduledTask.repeatEveryUnit ? 'success' : 'disabled'}
                    onClick={() => {
                        setOpenEditDialog(true);
                        handleMenuClose();
                    }}
                    disableRipple
                >
                    <MoreTimeIcon color={scheduledTask.repeatEveryAmount && scheduledTask.repeatEveryUnit ? 'success' : 'disabled'} />
                    Edit Repeat Settings
                </MenuItem>
                <Divider />
                <MenuItem
                    onClick={() => {
                        handleDeleteScheduledMessage(scheduledTask._id!);
                        handleMenuClose();
                    }}
                    disableRipple
                >
                    <DeleteIcon color="error" />
                    Delete Scheduled Message
                </MenuItem>
            </StyledLocalMenu>
            <MessageUsersScheduledTaskChangeSettingsDialog
                open={openEditDialog}
                onClose={() => setOpenEditDialog(false)}
                scheduledTask={scheduledTask}
            />
        </>
    );
};

export default MessageUsersScheduledListRowLocalMenu;
