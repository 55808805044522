import { useState } from 'react';
import { Theme } from '@mui/system';
import { FormControl, Grid, InputLabel, MenuItem, OutlinedInput, Select } from '@mui/material';
import { momentPeriods } from '../../../../utils/common';

type Props = {
    period: string;
    setPeriod: (period: string) => void;
    periodAmount: number;
    setPeriodAmount: (amount: number) => void;
    suffixMessage?: string;
    theme: Theme;
};

const MessageUsersFormExcludePeriod = (props: Props) => {
    const { theme, periodAmount, setPeriodAmount, period, setPeriod, suffixMessage } = props;
    const [periodAmountString, setPeriodAmountString] = useState<string>(periodAmount.toString());

    return (
        <>
            <Grid container spacing={1} flexGrow={0} alignItems="center" justifyContent="center">
                <Grid item xs={12}>
                    <Grid
                        container
                        spacing={1}
                        flexGrow={0}
                        alignItems="center"
                        justifyContent="center"
                        sx={{ marginBottom: theme.spacing(2) }}
                    >
                        <Grid item xs="auto">
                            <FormControl>
                                <InputLabel htmlFor="periodAmount">Length</InputLabel>
                                <OutlinedInput
                                    id="periodAmount"
                                    size="medium"
                                    inputProps={{ inputMode: 'text', pattern: /^\d*$$/ }}
                                    value={periodAmountString}
                                    onChange={e => {
                                        e.preventDefault();

                                        if (/^\d*$/.test(e.target.value)) {
                                            const parsed = parseFloat(e.target.value.replaceAll(',', '.'));

                                            if (isNaN(parsed) || parsed === 0) {
                                                setPeriodAmount(0);
                                                setPeriodAmountString('0');
                                            } else {
                                                setPeriodAmount(parsed);
                                                setPeriodAmountString(e.target.value.replace(/^0+/, ''));
                                            }
                                        }
                                    }}
                                    label="Length"
                                    error={periodAmount === 0}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs="auto">
                            <FormControl fullWidth>
                                <InputLabel id="period">Period</InputLabel>
                                <Select
                                    labelId="condition"
                                    defaultValue={period}
                                    value={period}
                                    onChange={e => {
                                        setPeriod(e.target.value);
                                    }}
                                    fullWidth
                                    size="medium"
                                    label="Period"
                                >
                                    {Object.keys(momentPeriods).map(item => (
                                        <MenuItem value={item} key={item}>
                                            {momentPeriods[item]}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs>
                            {suffixMessage}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default MessageUsersFormExcludePeriod;
