import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    IconButton,
    MenuItem,
    Select,
    Skeleton,
    Tooltip,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import { Theme } from '@mui/system';
import { useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useDialog } from 'muibox';
import moment from 'moment';
import ClearIcon from '@mui/icons-material/Clear';
import { UserContext } from '../../../../store/UserContext';
import { SettingsContext } from '../../../../store/SettingsContext';
import { handleHttpError, handleHttpErrorResponse, objectIdToDate } from '../../../../utils/common';

interface MessagePreviewProps {
    message: string;
    theme: Theme;
}

const MessagePreview = (props: MessagePreviewProps) => {
    const { message, theme } = props;

    return (
        <div
            id="bubble-preview"
            style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginBottom: '2px',
                flexWrap: 'wrap',
                font: '16px/1.3334 Roboto,sans-serif',
                color: theme.palette.common.black,
            }}
        >
            <div
                id="bubble-preview-content"
                style={{
                    // paddingLeft: '28px',
                    display: 'flex',
                    flexDirection: 'row',
                    alignContent: 'flex-end',
                    alignItems: 'flex-end',
                    position: 'relative',
                }}
            >
                <div
                    id="bubble-preview-body"
                    style={{
                        maxWidth: '100%',
                        position: 'relative',
                        display: 'flex',
                        flexDirection: 'column',
                        overflow: 'hidden',
                    }}
                >
                    <div
                        id="bubble-preview-text-wrapper"
                        style={{
                            flexDirection: 'row-reverse',
                            display: 'flex',
                            position: 'relative',
                        }}
                    >
                        <div
                            style={{
                                backgroundColor: 'rgba(0,175,240,.12)',
                                marginLeft: 'auto',
                                padding: '10px 12px',
                                overflowWrap: 'break-word',
                                wordBreak: 'break-word',
                                hyphens: 'auto',
                                textAlign: 'start',
                                lineHeight: '20px',
                                boxSizing: 'border-box',
                            }}
                        >
                            {message}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

type MessageUsersFormEditorTemplateLoadDialogProps = {
    subscriber: any;
    loadTemplateDialogOpen: boolean;
    setLoadTemplateDialogOpen: (loadTemplateDialogOpen: boolean) => void;
    handleLoadTemplate: (template: any) => void;
};

const MessageUsersFormEditorTemplateLoadDialog = (props: MessageUsersFormEditorTemplateLoadDialogProps) => {
    const { subscriber, loadTemplateDialogOpen, setLoadTemplateDialogOpen, handleLoadTemplate } = props;
    const userContext = useContext(UserContext);
    const settingsContext = useContext(SettingsContext);
    const dialog = useDialog();
    const theme: Theme = useTheme();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'));

    const [selectedTemplate, setSelectedTemplate] = useState<any>(null);

    const handleClose = () => {
        setLoadTemplateDialogOpen(false);
    };

    const handleSubmit = () => {
        if (!selectedTemplate) return;

        handleLoadTemplate(templates.find((t: any) => t._id === selectedTemplate));
        handleClose();
    };

    // Fetch Subscriber's Templates Lists
    const fetchTemplates = async (): Promise<any> => {
        if (subscriber && userContext.jwtToken && settingsContext.apiKey) {
            const query: string = `${settingsContext.routes.messageUsers.base}${subscriber._id}/templates`;

            return fetch(query, {
                method: 'get',
                headers: {
                    Authorization: userContext.jwtToken,
                    apiKey: settingsContext.apiKey,
                },
            })
                .then(async response => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        handleHttpErrorResponse(response, dialog);
                    }
                })
                .catch(error => {
                    console.error(error);
                    handleHttpError(error, dialog);
                });
        }
    };

    const {
        data: templates,
        isLoading: templatesLoading,
        isFetching: templatesFetching,
        refetch: refetchTemplates,
    } = useQuery(
        ['messageUsersTemplates', subscriber],
        () => {
            if (subscriber) {
                return fetchTemplates();
            }
        },
        {
            refetchOnWindowFocus: false,
            staleTime: Infinity,
            enabled: subscriber && loadTemplateDialogOpen ? true : false,
            onSuccess: data => {
                if (data && data.length > 0) {
                    setSelectedTemplate(data[0]._id);
                }
            },
        },
    );

    useEffect(() => {
        if (loadTemplateDialogOpen) {
            refetchTemplates();
        }
    }, [loadTemplateDialogOpen, refetchTemplates]);

    // Delete Subscriber's Template
    const handleDeleteSelectedTemplate = () => {
        if (!selectedTemplate) return;

        const deleteTemplate = async (): Promise<any> => {
            if (subscriber && userContext.jwtToken && settingsContext.apiKey) {
                const query: string = `${settingsContext.routes.messageUsers.base}${subscriber._id}/templates/${selectedTemplate}`;

                return fetch(query, {
                    method: 'delete',
                    headers: {
                        Authorization: userContext.jwtToken,
                        apiKey: settingsContext.apiKey,
                    },
                })
                    .then(async response => {
                        if (response.ok) {
                            return response.json();
                        } else {
                            handleHttpErrorResponse(response, dialog);
                        }
                    })
                    .catch(error => {
                        console.error(error);
                        handleHttpError(error, dialog);
                    });
            }
        };

        dialog
            .confirm({
                title: 'Delete Template',
                message: 'Are you sure you want to delete this template?',
                ok: { text: 'Yes', color: 'error', variant: 'contained' },
                cancel: { text: 'No', color: 'secondary', variant: 'outlined' },
            })
            .then(() => {
                deleteTemplate().then((result: any) => {
                    if (result && result.success) {
                        refetchTemplates();
                    }
                });
            })
            .catch(() => {});
    };

    return (
        <Dialog maxWidth="sm" fullWidth open={loadTemplateDialogOpen} onClose={() => handleClose()}>
            <DialogTitle sx={{ backgroundColor: theme.palette.grey[200], marginBottom: theme.spacing(2) }}>
                Load Message Template
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={1} alignItems={'center'}>
                    <Grid item xs={12}>
                        Select a message template to load.
                        <br />
                        This will overwrite the current message.
                    </Grid>
                    {templatesLoading || templatesFetching ? (
                        <Skeleton />
                    ) : (
                        <>
                            {templates && templates.length > 0 && (
                                <>
                                    <Grid item xs={12} md>
                                        <FormControl fullWidth>
                                            <Select
                                                fullWidth
                                                autoFocus
                                                defaultValue={undefined}
                                                value={selectedTemplate || undefined}
                                                size="medium"
                                                onChange={e => {
                                                    setSelectedTemplate(e.target.value);
                                                }}
                                            >
                                                {!templatesLoading &&
                                                    !templatesFetching &&
                                                    templates &&
                                                    templates.map((template: any) => (
                                                        <MenuItem key={template._id} value={template._id}>
                                                            <Grid container spacing={2} alignItems={'center'}>
                                                                <Grid item xs style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                                                    {template.name}
                                                                </Grid>
                                                                <Grid item xs="auto">
                                                                    {moment(objectIdToDate(template._id)).format('L')}
                                                                </Grid>
                                                            </Grid>
                                                        </MenuItem>
                                                    ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    {isLargeScreen && (
                                        <Grid item md="auto">
                                            <Tooltip title="Delete Selected Template">
                                                <IconButton
                                                    disabled={!selectedTemplate}
                                                    onClick={() => {
                                                        handleDeleteSelectedTemplate();
                                                    }}
                                                >
                                                    <ClearIcon fontSize="medium" color={!selectedTemplate ? 'disabled' : 'error'} />
                                                </IconButton>
                                            </Tooltip>
                                        </Grid>
                                    )}
                                </>
                            )}
                        </>
                    )}
                    {!templatesLoading && !templatesFetching && (!templates || templates.length === 0) && (
                        <Grid
                            item
                            xs={12}
                            style={{
                                color: theme.palette.grey[500],
                                textAlign: 'center',
                                marginTop: theme.spacing(2),
                                marginBottom: theme.spacing(2),
                            }}
                        >
                            No templates found
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <strong>Replacement for %USERNAME%</strong>
                    </Grid>
                    <Grid item xs={12}>
                        {!templatesLoading &&
                        !templatesFetching &&
                        selectedTemplate &&
                        templates &&
                        templates.find((template: any) => template._id === selectedTemplate) ? (
                            templates.find((template: any) => template._id === selectedTemplate).usernameReplacement
                        ) : (
                            <div style={{ color: theme.palette.grey[500] }}>none</div>
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <strong>Message</strong>
                    </Grid>
                    <Grid item xs={12}>
                        <MessagePreview
                            message={
                                !templatesLoading &&
                                !templatesFetching &&
                                selectedTemplate &&
                                templates &&
                                templates.find((template: any) => template._id === selectedTemplate)?.message
                            }
                            theme={theme}
                        />
                    </Grid>
                    {!isLargeScreen && (
                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                color="error"
                                disabled={!selectedTemplate}
                                fullWidth
                                onClick={() => handleDeleteSelectedTemplate()}
                            >
                                Delete Selected Template
                            </Button>
                        </Grid>
                    )}
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary" size="medium">
                    Cancel
                </Button>
                <LoadingButton
                    onClick={handleSubmit}
                    variant="contained"
                    color="primary"
                    size="medium"
                    loading={templatesLoading}
                    disabled={!templates || templates.length === 0 || !selectedTemplate}
                >
                    Load
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};

export default MessageUsersFormEditorTemplateLoadDialog;
