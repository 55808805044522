import axios from 'axios';
import { useDialog } from 'muibox';
import { useContext } from 'react';
import { QueryClient, useQuery } from 'react-query';
import { SettingsContext } from '../store/SettingsContext';
import { UserContext } from '../store/UserContext';
import { handleHttpError } from '../utils/common';

const useSubscribersAdmin = (offset: number, limit: number, filter?: string) => {
    const userContext = useContext(UserContext);
    const settingsContext = useContext(SettingsContext);
    const dialog = useDialog();
    const queryClient = new QueryClient();

    // Fetch Follow-Back Overview
    const fetchsubscribers = async (
        offset: number,
        limit: number,
        filter?: string,
    ): Promise<{ rows: any[]; metadata: { total: number }; _config?: any }> => {
        let query: string = `${settingsContext.routes.subscribers.find}?`;

        if (offset && typeof offset === 'number' && limit && typeof limit === 'number') {
            query += `offset=${offset}&limit=${limit}`;
        }

        if (filter && typeof filter === 'string' && filter !== '') {
            query += `&filter=${filter}`;
        }

        return axios
            .get(query, {
                headers: {
                    Authorization: userContext.jwtToken,
                    apiKey: settingsContext.apiKey,
                },
            })
            .then(async response => response.data)
            .catch(error => {
                console.error(error);
                handleHttpError(error, dialog);
            });
    };

    const subscribers = useQuery(
        ['subscribers', userContext.jwtToken, offset, limit, filter],
        () => fetchsubscribers(offset, limit, filter),
        {
            refetchOnWindowFocus: false,
            // Stale time 24 hours
            staleTime: 60 * 60 * 1000 * 24,
            enabled: userContext.jwtToken && settingsContext.apiKey && userContext.user.username ? true : false,
            placeholderData: () => {
                const data: { rows: any[]; metadata: { total: number }; _config?: any } | undefined = queryClient.getQueryData([
                    'subscribers',
                ]);

                return data ? data : { rows: [], metadata: { total: 0 } };
            },
        },
    );

    return {
        ...subscribers,
    };
};

export default useSubscribersAdmin;
