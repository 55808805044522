import { Chip } from '@mui/material';

const SextforceMetricsROIPercentageChip = ({ value, amountType }: { value: number | undefined; amountType: 'gross' | 'net' }) => {
    return typeof value === 'undefined' ? undefined : (
        <Chip
            label={`${(value as number).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            })}%`}
            color={value < 0 ? 'error' : value > 0 ? 'success' : 'default'}
            sx={{
                width: '100%',
                fontFamily: 'monospace',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                justifyContent: 'flex-end',
            }}
        />
    );
};

export default SextforceMetricsROIPercentageChip;
