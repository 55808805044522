import { Theme } from '@mui/system';
import useTheme from '@mui/material/styles/useTheme';
import {
    Button,
    Card,
    CardContent,
    Grid,
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    useMediaQuery,
} from '@mui/material';
import MessageUsersHistorySessionsListRow from './MessageUsersHistorySessionsListRow';
import MessageUsersHistorySessionsAnalytics from './MessageUsersHistorySessionsAnalytics';

type Props = {
    subscriber: any;
    data: any[];
    isFetching: boolean;
    currentUnsendMessageSessions: any;
    isLoadingCurrentUnsendMessageSessions: boolean;
    timezone: string;
    handleUnsendMessage: (sessionId: string) => void;
    sextforceActive: boolean;
    hoursSinceMessage: number;
    setHoursSinceMessage: (hours: number) => void;
    showSalesMode: string;
    setShowSalesMode: (mode: string) => void;
    sessionsSalesData: any[];
    sessionsSalesDataLoading: boolean;
};

const MessageUsersHistorySessionsList = (props: Props) => {
    const {
        subscriber,
        data,
        isFetching,
        currentUnsendMessageSessions,
        isLoadingCurrentUnsendMessageSessions,
        timezone,
        handleUnsendMessage,
        sextforceActive,
        hoursSinceMessage,
        setHoursSinceMessage,
        showSalesMode,
        setShowSalesMode,
        sessionsSalesData,
        sessionsSalesDataLoading,
    } = props;
    const theme: Theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.up('sm'));

    return (
        <>
            <Card variant="elevation" sx={{ marginBottom: theme.spacing(2) }}>
                <CardContent>
                    <Typography variant="h6" sx={{ marginBottom: theme.spacing(2) }}>
                        Sessions
                    </Typography>

                    <Typography variant="body1" sx={{ marginBottom: theme.spacing(2) }}>
                        This report shows all the session that ran during the searched period. Each row represents a single session and it
                        shows the target users it was scanning, the number of users that were followed in that session and the last status
                        the session ended with.
                    </Typography>

                    <Typography variant="body1" sx={{ marginBottom: theme.spacing(2) }}>
                        If you have an active <strong>Sextforce</strong> subscription, the report will also show how much revenue was
                        generated from each session. The system will automatically calculate how much each user who received the message has
                        spent in the selected number of hours that followed.
                    </Typography>

                    <Typography variant="body1" sx={{ marginBottom: theme.spacing(2) }}>
                        Click on the session row below to see more details. Click on{' '}
                        <Button
                            variant="contained"
                            color="primary"
                            disableFocusRipple
                            disableRipple
                            disableTouchRipple
                            disableElevation
                            sx={{ cursor: 'text' }}
                        >
                            Repeat This Message
                        </Button>{' '}
                        to repeat a message with the same settings, and click on{' '}
                        <Button
                            variant="contained"
                            color="warning"
                            disableFocusRipple
                            disableRipple
                            disableTouchRipple
                            disableElevation
                            sx={{ cursor: 'text' }}
                        >
                            Unsend Message
                        </Button>{' '}
                        to unsend each individual message in the session. Only one session can be unsent at a time.
                    </Typography>

                    <Typography variant="body1" sx={{ marginBottom: theme.spacing(2) }}>
                        Please note: Only individual messages that were sent within the last 24 hours can be unsent, so please take into
                        account it's taken for the message to be sent since the session started and how long it will take to unsend all the
                        individual messages when starting to unsend.
                    </Typography>
                </CardContent>
            </Card>

            <MessageUsersHistorySessionsAnalytics
                sextforceActive={sextforceActive}
                hoursSinceMessage={hoursSinceMessage}
                setHoursSinceMessage={setHoursSinceMessage}
                showSalesMode={showSalesMode}
                setShowSalesMode={setShowSalesMode}
                sessionsSalesData={sessionsSalesData}
                sessionsSalesDataLoading={sessionsSalesDataLoading}
                theme={theme}
            />

            <Card variant="elevation" sx={{ marginBottom: theme.spacing(2) }}>
                <CardContent>
                    <Grid container flexGrow={1}>
                        <Grid item xs={12}>
                            {isFetching ? (
                                <Skeleton />
                            ) : (
                                <>
                                    <Table size="small">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell width={24} sx={{ paddingLeft: theme.spacing(1), paddingRight: 0 }} />
                                                <TableCell
                                                    sx={{
                                                        fontWeight: 'bold',
                                                        width: { xs: '100px', sm: '150px' },
                                                        paddingLeft: theme.spacing(1),
                                                        paddingRight: theme.spacing(1),
                                                    }}
                                                >
                                                    Date
                                                </TableCell>
                                                <TableCell
                                                    width={150}
                                                    sx={{ fontWeight: 'bold', paddingLeft: theme.spacing(1), paddingRight: 0 }}
                                                >
                                                    Target
                                                </TableCell>
                                                <TableCell
                                                    sx={{
                                                        fontWeight: 'bold',
                                                        width: '80px',
                                                        paddingLeft: theme.spacing(1),
                                                        paddingRight: 0,
                                                    }}
                                                    align="right"
                                                >
                                                    Sent
                                                </TableCell>
                                                <TableCell
                                                    sx={{
                                                        fontWeight: 'bold',
                                                        width: '100px',
                                                        paddingLeft: theme.spacing(1),
                                                        paddingRight: smallScreen ? 0 : theme.spacing(1),
                                                    }}
                                                    align="right"
                                                >
                                                    Sales {showSalesMode === 'net' ? 'Net' : 'Gross'}
                                                </TableCell>
                                                {smallScreen && (
                                                    <>
                                                        <TableCell
                                                            sx={{
                                                                textAlign: 'right',
                                                                fontWeight: 'bold',
                                                                width: '100px',
                                                                display: { xs: 'none', sm: 'table-cell' },
                                                                paddingLeft: theme.spacing(1),
                                                                paddingRight: 0,
                                                            }}
                                                        >
                                                            ROI (Net)
                                                        </TableCell>
                                                        <TableCell
                                                            sx={{
                                                                fontWeight: 'bold',
                                                                display: { xs: 'none', sm: 'table-cell' },
                                                                paddingLeft: theme.spacing(1),
                                                                paddingRight: theme.spacing(1),
                                                            }}
                                                        >
                                                            Last Status
                                                        </TableCell>
                                                    </>
                                                )}
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {data.map(row => (
                                                <MessageUsersHistorySessionsListRow
                                                    subscriber={subscriber}
                                                    row={row}
                                                    currentUnsendMessageSessions={currentUnsendMessageSessions}
                                                    isLoadingCurrentUnsendMessageSessions={isLoadingCurrentUnsendMessageSessions}
                                                    timezone={timezone}
                                                    sextforceActive={sextforceActive}
                                                    hoursSinceMessage={hoursSinceMessage}
                                                    showSalesMode={showSalesMode}
                                                    sessionsSalesData={sessionsSalesData.find(
                                                        sessionSalesData => sessionSalesData._id === row._id,
                                                    )}
                                                    sessionsSalesDataLoading={
                                                        sessionsSalesDataLoading &&
                                                        !sessionsSalesData.find(sessionSalesData => sessionSalesData._id === row._id)
                                                    }
                                                    handleUnsendMessage={handleUnsendMessage}
                                                    smallScreen={smallScreen}
                                                    theme={theme}
                                                    key={row._id}
                                                />
                                            ))}
                                        </TableBody>
                                    </Table>
                                </>
                            )}
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </>
    );
};

export default MessageUsersHistorySessionsList;
