import { faArrowUpRightFromSquare, faClipboard } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, IconButton, Stack, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { useCopyToClipboard } from '../../hooks/useCopyToClipboard';

type Props = {
    selfieIdLink: string;
};

const OnlyFansSelfieIDLink = (props: Props) => {
    const { selfieIdLink } = props;
    const [copiedText, copy] = useCopyToClipboard();
    const [copied, setCopied] = useState(false);

    const isMobilePhone = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

    const handleCopy = () => {
        copy(selfieIdLink);

        setCopied(true);

        setTimeout(() => {
            setCopied(false);
        }, 2000);
    };

    return (
        <Grid container alignItems={'center'} justifyContent={'center'} spacing={1} sx={{ mt: 1 }}>
            <Grid item xs={12}>
                Use the link below to verify your identity and complete the login process. Please note that the link is valid for 5 minutes.
            </Grid>
            <Grid item xs={12}>
                <Stack direction={'row'} spacing={1} alignItems="center">
                    <TextField
                        id="otpLink"
                        type={'text'}
                        label="Selfie ID Link"
                        required
                        value={selfieIdLink}
                        disabled={true}
                        fullWidth
                        autoFocus
                    />
                    <IconButton
                        size="medium"
                        color={copied ? 'success' : 'primary'}
                        onClick={() => {
                            handleCopy();
                        }}
                    >
                        <FontAwesomeIcon icon={faClipboard} />
                    </IconButton>
                    {isMobilePhone && (
                        <IconButton size="medium" color="secondary" href={selfieIdLink} target="_blank">
                            <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                        </IconButton>
                    )}
                </Stack>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="caption" color="textSecondary">
                    Click on the clipboard icon to copy the link, then paste it in your browser to varify your identity.
                </Typography>
            </Grid>
        </Grid>
    );
};

export default OnlyFansSelfieIDLink;
