import { Card, Skeleton } from '@mui/material';
import { OnlyFansSubscriberCategory } from '../../hooks/useSubscriberCategories';
import UsernameWithAvatar from '../common/UsernameWithAvatar';

type SubscriberCardProps = {
    subscriber: any;
    category: OnlyFansSubscriberCategory | void;
    refetchSubscribers: () => void;
};

const SubscriberCard = (props: SubscriberCardProps) => {
    const { subscriber, category, refetchSubscribers } = props;

    return (
        <>
            <Card
                variant="elevation"
                sx={{
                    height: '100%',
                    display: 'flex',
                    alignItems: 'stretch',
                    justifyContent: 'space-between',
                    pt: 1,
                    pb: 1,
                    pl: 2,
                    pr: 2,
                    ...(category &&
                        category.color && {
                            backgroundImage: `linear-gradient(90deg, ${category.color} 0%, ${category.color} 8px, transparent 8px, transparent 100%);`,
                        }),
                }}
            >
                {subscriber ? (
                    <UsernameWithAvatar subscriber={subscriber} category={category} refetchSubscribers={refetchSubscribers} />
                ) : (
                    <Skeleton />
                )}
            </Card>
        </>
    );
};

export default SubscriberCard;
