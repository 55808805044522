import { useDialog } from 'muibox';
import { useContext } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { SettingsContext } from '../store/SettingsContext';
import { UserContext } from '../store/UserContext';
import { handleHttpError } from '../utils/common';
import useAxios from './useAxios';
import { OnlyFansStreamStats } from './useSextforceLiveStreamAddTippersToList';

const useSextforceLiveStreamAddTippersToListStats = (streamId: number | undefined | null) => {
    const userContext = useContext(UserContext);
    const settingsContext = useContext(SettingsContext);
    const dialog = useDialog();
    const params = useParams();
    const axios = useAxios();

    // Fetch the restricted words
    const fetchData = async (): Promise<OnlyFansStreamStats.Response | null> => {
        if (userContext.jwtToken && settingsContext.apiKey && userContext.user.username) {
            const query: string = `${settingsContext.routes.sextforce.base}${params.userId}/liveStream/addTippersToList/${streamId}/stats`;

            return axios
                .get(query)
                .then(response => response.data)
                .catch(error => {
                    console.error(error);
                    handleHttpError(error, dialog);

                    return null;
                });
        }

        return null;
    };

    const liveStreams = useQuery(
        ['liveStreamStats', userContext.jwtToken, params.userId, streamId],
        () => {
            return fetchData();
        },
        {
            refetchOnWindowFocus: false,
            // Stake time 5 minutes
            staleTime: 5 * 60 * 1000,
            enabled:
                userContext.jwtToken && settingsContext.apiKey && userContext.user.username && params.userId && streamId ? true : false,
        },
    );

    return {
        ...liveStreams,
    };
};

export default useSextforceLiveStreamAddTippersToListStats;
