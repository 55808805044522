'muibox';
import { useDialog } from 'muibox';
import { useContext, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { SettingsContext } from '../store/SettingsContext';
import { UserContext } from '../store/UserContext';
import { OnlyFansLists } from '../types/onlyFansTypes';
import { handleHttpError } from '../utils/common';
import useAxios from './useAxios';
import useSubscriber from './useSubscriber';

const useSubscriberLists = (enabled: boolean) => {
    const userContext = useContext(UserContext);
    const settingsContext = useContext(SettingsContext);
    const { data: subscriber } = useSubscriber();
    const queryClient = useQueryClient();
    const dialog = useDialog();
    const axios = useAxios();

    const [isCreatingList, setIsCreatingList] = useState<boolean>(false);

    // Fetch Subscriber's Lists
    const fetchLists = async (): Promise<OnlyFansLists.List[] | void> => {
        const query: string = `${settingsContext.routes.onlyFans.base}${subscriber._id}/lists`;

        return (
            axios
                .get(query)
                .then(response => response.data as OnlyFansLists.List[])
                // .then(lists => {
                //     const sortedData = lists.sort((a: any, b: any) => {
                //         let x = a.name.toLowerCase();
                //         let y = b.name.toLowerCase();

                //         if (x < y) {
                //             return -1;
                //         }

                //         if (x > y) {
                //             return 1;
                //         }

                //         return 0;
                //     });

                //     return sortedData;
                // })
                .catch(error => {
                    console.error(error);
                    handleHttpError(error, dialog);
                })
        );
    };

    // Create a List
    const createList = async (name: string): Promise<any> => {
        const query: string = `${settingsContext.routes.onlyFans.base}${subscriber._id}/lists`;

        setIsCreatingList(true);

        return axios
            .post(query, { name })
            .then(response => {
                // Add the new list to the cache
                queryClient.setQueryData(['subscriberLists', subscriber?._id], (oldData: any) => {
                    return [...oldData, response.data];
                });

                setIsCreatingList(false);

                return response.data;
            })
            .catch(error => {
                console.error(error);
                setIsCreatingList(false);
                handleHttpError(error, dialog);
            });
    };

    const subscriberLists = useQuery(['subscriberLists', subscriber?._id], () => fetchLists(), {
        refetchOnWindowFocus: false,
        staleTime: Infinity,
        enabled: userContext.jwtToken && settingsContext.apiKey && subscriber && enabled ? true : false,
    });

    return { ...subscriberLists, createList, isCreatingList };
};

export default useSubscriberLists;
