import CircleIcon from '@mui/icons-material/Circle';
import {
    Button,
    Checkbox,
    Chip,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Skeleton,
    TextField,
    ToggleButton,
    ToggleButtonGroup,
    useMediaQuery,
} from '@mui/material';
import { Theme } from '@mui/system';
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { SettingsContext } from '../../../../../../store/SettingsContext';
import { getPlatformIcon } from '../../../../../../utils/common';
import SelectTimezone from '../../../../../forms/helpers/SelectTimezone';

const lastPartOfURL = (url: string | null) => {
    if (!url) {
        return '';
    }

    const pathname = new URL(url).pathname;

    return pathname.substring(pathname.lastIndexOf('/') + 1);
};

type Props = {
    trialsLoading: boolean;
    trialsSelectorType: { includeInactive: boolean; type: string };
    setTrialsSelectorType: (types: { includeInactive: boolean; type: string }) => void;
    trials: any[];
    trialId: { trialId: string; enabled: boolean; startDate: Date | null; endDate: Date | null };
    setTrialId: (selectedTrial: { trialId: string; enabled: boolean; startDate: Date | null; endDate: Date | null }) => void;
    setTrialUrl: (url: string | null) => void;
    timezone: string;
    setTimezone: (timezone: string) => void;
    theme: Theme;
};

const SextforceMetricsTrialSelector = (props: Props) => {
    const {
        trialsLoading,
        trialsSelectorType,
        setTrialsSelectorType,
        trials,
        trialId,
        setTrialId,
        setTrialUrl,
        timezone,
        setTimezone,
        theme,
    } = props;
    const settings = useContext(SettingsContext);
    const largeScreen = useMediaQuery(theme.breakpoints.up('sm'));
    const params = useParams();
    const navigate = useNavigate();
    // const [showInactive, setShowInactive] = useState<boolean>(false);
    // const [showType, setShowType] = useState<string>('all');
    // const [trialsList, setTrialsList] = useState<any[]>([]);
    const [selectedTrial, setSelectedTrial] = useState<any | null>(null);
    const [enableFilterDates, setEnableFilterDates] = useState<boolean>(false);
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(new Date());
    const [requiresUpdate, setRequiresUpdate] = useState<boolean>(false);
    const [isStartDateAfterEndDate, setIsStartDateAfterEndDate] = useState<boolean>(false);

    // Filter select list options
    useEffect(() => {
        if (!params.metricId) {
            if (!trialsLoading && trials && trials.length > 0) {
                setTrialId({ trialId: trials[0]._id, enabled: false, startDate: null, endDate: null });
            } else {
                setTrialId({ trialId: '', enabled: false, startDate: null, endDate: null });
                setTrialUrl(null);
            }
        }
    }, [params.metricId, setTrialId, setTrialUrl, trials, trialsLoading]);

    // Trial selected, set selected trial data
    useEffect(() => {
        if (!trialsLoading && trials && trials.length > 0 && trialId && trialId.trialId !== '') {
            setSelectedTrial(trials.find((t: any) => t._id === trialId.trialId));
        } else {
            setSelectedTrial(null);
        }
    }, [trialId, trials, trialsLoading]);

    // Set URL when selected trial changes
    useEffect(() => {
        if (selectedTrial && selectedTrial.payload && selectedTrial.payload.url) {
            setTrialUrl(selectedTrial.payload.url);
        } else {
            setTrialUrl(null);
        }
    }, [selectedTrial, setTrialUrl]);

    // Set filter dates when selected trial changes
    useEffect(() => {
        if (selectedTrial && selectedTrial.payload && selectedTrial.payload.createdAt) {
            setStartDate(selectedTrial.payload.createdAt);
            setEndDate(new Date());
            setEnableFilterDates(false);
        }
    }, [selectedTrial, setEnableFilterDates, trials]);

    // Check that the start date is not after the end date
    useEffect(() => {
        if (startDate && endDate) {
            setIsStartDateAfterEndDate(startDate > endDate);
        } else {
            setIsStartDateAfterEndDate(false);
        }
    }, [endDate, startDate]);

    // Check if the filter dates have changed
    useEffect(() => {
        if (trialId.enabled !== enableFilterDates) {
            setRequiresUpdate(true);

            return;
        }

        if (trialId.startDate !== startDate) {
            setRequiresUpdate(true);

            return;
        }

        if (trialId.endDate !== endDate) {
            setRequiresUpdate(true);

            return;
        }

        setRequiresUpdate(false);
    }, [enableFilterDates, endDate, startDate, trialId.enabled, trialId.endDate, trialId.startDate]);

    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <Grid container spacing={1} alignItems="center">
                <Grid item xs={12} md>
                    {trialsLoading ? (
                        <Skeleton />
                    ) : (
                        <FormControl fullWidth>
                            {!trialsLoading && trials && trials.length > 0 && (
                                <InputLabel id="promoCampaignId">Select Trial/Promo</InputLabel>
                            )}
                            <Select
                                labelId="promoCampaignId"
                                value={!trialsLoading && trialId && trialId.trialId && trialId.trialId !== '' ? trialId.trialId : ''}
                                onChange={async e => {
                                    setEnableFilterDates(false);
                                    setTrialId({
                                        trialId: e.target.value as string,
                                        enabled: false,
                                        startDate: null,
                                        endDate: null,
                                    });

                                    return navigate(
                                        `/subscribers/${params.userId}/services/sextforce/metrics/trials/details/${e.target.value}`,
                                    );
                                }}
                                disabled={!trials || trials.length === 0}
                                fullWidth
                                size="medium"
                                label="Select Trial/Promo"
                                displayEmpty
                                renderValue={
                                    !trialsLoading && trials && trials.length > 0 ? undefined : () => <div>No Promos/Trials Found</div>
                                }
                            >
                                {trials &&
                                    trials.length > 0 &&
                                    trials.map((trial: any) => (
                                        <MenuItem value={trial._id} key={trial._id} disableRipple>
                                            <Grid container spacing={0} alignItems="center" sx={{ margin: 0, padding: 0 }}>
                                                <Grid item xs="auto">
                                                    <CircleIcon
                                                        color={trial.payload.active ? 'success' : 'disabled'}
                                                        sx={{ fontSize: 10, marginRight: theme.spacing(1) }}
                                                    />
                                                </Grid>
                                                <Grid item xs="auto" sx={{ marginRight: theme.spacing(1) }}>
                                                    <Chip
                                                        label={trial.type === 'trialLinkTrial' ? 'Trial' : 'Promo'}
                                                        size="small"
                                                        variant="outlined"
                                                        color="primary"
                                                        sx={{ width: '60px' }}
                                                    />
                                                </Grid>
                                                <Grid item xs sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                                    {trial.payload.name || 'No Name'}
                                                </Grid>
                                                {trial.associatedUsername && (
                                                    <Grid item xs="auto" sx={{ marginRight: theme.spacing(1) }}>
                                                        <Grid container spacing={1} alignItems="center">
                                                            {trial.settings && trial.settings.platform && (
                                                                <Grid item xs="auto">
                                                                    {getPlatformIcon(settings.platforms, trial.settings.platform, 'small')}
                                                                </Grid>
                                                            )}
                                                            <Grid item xs sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                                                <span style={{ color: theme.palette.grey[700] }}>
                                                                    {trial.associatedUsername}
                                                                </span>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                )}
                                                {largeScreen && (
                                                    <Grid item xs="auto" sx={{ marginRight: theme.spacing(2) }}>
                                                        <span style={{ color: theme.palette.grey[700], fontFamily: 'monospace' }}>
                                                            {lastPartOfURL(trial.payload.url)}
                                                        </span>
                                                    </Grid>
                                                )}
                                                <Grid item xs="auto" sx={{ paddingRight: theme.spacing(1) }}>
                                                    <span style={{ color: theme.palette.grey[700], fontFamily: 'monospace' }}>
                                                        {trial.payload.createdAt
                                                            ? moment(trial.payload.createdAt).tz(timezone).format('L').toLocaleString()
                                                            : 'Unknown'}
                                                    </span>
                                                </Grid>
                                            </Grid>
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                    )}
                </Grid>
                <Grid item xs={12} md="auto">
                    <Grid container spacing={1} alignItems="center">
                        <Grid item xs>
                            <ToggleButtonGroup
                                color="secondary"
                                size={largeScreen ? 'large' : 'small'}
                                fullWidth
                                exclusive={true}
                                value={trialsSelectorType.type}
                                onChange={(e, value: string) => {
                                    if (!value) {
                                        return;
                                    }

                                    setTrialsSelectorType({ includeInactive: trialsSelectorType.includeInactive, type: value });
                                }}
                                sx={{ marginTop: '4px' }}
                            >
                                <ToggleButton value="all" key="left">
                                    All
                                </ToggleButton>
                                <ToggleButton value="trialLinkTrial" key="center">
                                    Trials
                                </ToggleButton>
                                <ToggleButton value="trialLinkPromo" key="right">
                                    Promos
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </Grid>
                        <Grid item>
                            <ToggleButton
                                value={trialsSelectorType.includeInactive ? 'true' : 'false'}
                                selected={trialsSelectorType.includeInactive}
                                color="secondary"
                                size={largeScreen ? 'large' : 'small'}
                                onChange={(e, value: string) =>
                                    setTrialsSelectorType({
                                        includeInactive: !trialsSelectorType.includeInactive,
                                        type: trialsSelectorType.type,
                                    })
                                }
                                sx={{ marginTop: theme.spacing(0.5) }}
                            >
                                Show Inactive
                            </ToggleButton>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12}>
                <Grid container spacing={1} alignItems="center" sx={{ marginTop: theme.spacing(1) }}>
                    <Grid item xs={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    size="medium"
                                    checked={enableFilterDates}
                                    disabled={!trialId}
                                    onChange={(e, checked) => {
                                        setEnableFilterDates(checked);
                                    }}
                                    sx={{ marginLeft: 0 }}
                                />
                            }
                            label="Limit resuts to fans who claimed the Trial/Promo on these dates"
                        />
                    </Grid>
                    <Grid item xs={12} md="auto">
                        <Grid container spacing={1} alignItems="center">
                            <Grid item xs="auto">
                                Timezone
                            </Grid>
                            <Grid item xs>
                                <div style={{ textAlign: 'right' }}>
                                    <SelectTimezone
                                        timezone={timezone}
                                        setTimezone={setTimezone}
                                        fullWidth={false}
                                        disabled={!enableFilterDates}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md="auto">
                        <Grid container spacing={1} alignItems="center">
                            <Grid item xs="auto">
                                Start Date
                            </Grid>
                            <Grid item xs>
                                <div style={{ textAlign: 'right' }}>
                                    <MobileDatePicker
                                        renderInput={props => <TextField {...props} error={isStartDateAfterEndDate} />}
                                        label="Start Date"
                                        disabled={!enableFilterDates}
                                        value={moment(startDate)}
                                        minDate={
                                            selectedTrial &&
                                            selectedTrial.payload &&
                                            selectedTrial.payload.createdAt &&
                                            moment(selectedTrial.payload.createdAt)
                                        }
                                        onChange={newValue => {
                                            setStartDate((newValue as moment.Moment).startOf('day').tz(timezone, true).toDate());
                                        }}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md="auto">
                        <Grid container spacing={1} alignItems="center">
                            <Grid item xs="auto">
                                End Date
                            </Grid>
                            <Grid item xs>
                                <div style={{ textAlign: 'right' }}>
                                    <MobileDatePicker
                                        renderInput={props => <TextField {...props} />}
                                        label="End Date"
                                        disabled={!enableFilterDates}
                                        value={moment(endDate)}
                                        onChange={newValue => {
                                            setEndDate((newValue as moment.Moment).startOf('day').tz(timezone, true).toDate());
                                        }}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md="auto">
                        <Button
                            variant="contained"
                            color={requiresUpdate ? 'secondary' : 'primary'}
                            disabled={!trialId || !startDate || isStartDateAfterEndDate}
                            size="medium"
                            onClick={() => {
                                if (startDate && endDate && selectedTrial && selectedTrial.payload && selectedTrial.payload.createdAt) {
                                    setTrialId({
                                        trialId: trialId.trialId,
                                        enabled: enableFilterDates,
                                        startDate: startDate < selectedTrial.payload.createdAt ? startDate : moment(startDate).toDate(),
                                        endDate: moment(endDate).toDate(),
                                    });
                                }
                            }}
                            fullWidth={!largeScreen}
                        >
                            Apply
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </LocalizationProvider>
    );
};

export default SextforceMetricsTrialSelector;
