import MessageUsersSVG from '../../assets/messageUsers/messageUsers.svg';

type IconOnlyFansProps = {
    width?: string | number;
    height?: string | number;
    style?: React.CSSProperties;
};

const LogoMessageUsers = (props: IconOnlyFansProps) => {
    const { width, height, style } = props;

    return <img src={MessageUsersSVG} width={width} height={height} alt="Message Users Logo" style={style} />;
};

export default LogoMessageUsers;
