import LoadingButton from '@mui/lab/LoadingButton';
import { Alert, Button, Grid, Theme, Typography, useTheme } from '@mui/material';
import { useDialog } from 'muibox';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import BackNavigationButton from '../../../components/common/BackNavigationButton';
import PageContainer from '../../../components/common/PageContainter';
import SharedCreditCard from '../../../components/common/SharedCreditCard';
import StyledCard from '../../../components/common/StyledCard';
import StyledCardTitleBar from '../../../components/common/StyledCardTitleBar';
import AutoSendWelcomeMessageDetailsForm from '../../../components/services/messageUsers/autoSendWelcomeMessage/AutoSendWelcomeMessageDetailsForm';
import MessageUsersPriceBar from '../../../components/services/messageUsers/MessageUsersPriceBar';
import useSubscriber from '../../../hooks/useSubscriber';
import useSubscriberSettingsMessageUsersAutoWelcomeMessage from '../../../hooks/useSubscriberSettingsMessageUsersAutoWelcomeMessage';
import useSubscriberSettingsNotifications, {
    OnlyFansSettingsNotificationsTransport,
} from '../../../hooks/useSubscriberSettingsNotifications';
import { SettingsContext } from '../../../store/SettingsContext';
import { OnlyFansSubscriberMessageUsersAutoMessageDetails } from '../../../types/messageUsersAutoSendWelcomeMessage';
import { d2f, isSextforceActive } from '../../../utils/common';

const MessageUsersAutoSendWelcomeMessageReturningSubscriber = () => {
    const settingsContext = useContext(SettingsContext);
    const theme: Theme = useTheme();
    const { enqueueSnackbar } = useSnackbar();
    const dialog = useDialog();

    const { data: subscriber, isLoading: subscriberLoading } = useSubscriber();
    const {
        data: serviceSettings,
        isLoading: serviceSettingsLoading,
        setServiceSettingsMessageUsersAutoWelcomeMessageReturningSubscriber,
        setServiceSettingsMessageUsersAutoWelcomeMessageLoading,
    } = useSubscriberSettingsMessageUsersAutoWelcomeMessage();
    const [unitCost, setUnitCost] = useState<number>(0);

    // OnlyFans Notifications Settings
    const { data: onlyFansNotificationSettings, isLoading: onlyFansNotificationSettingsLoading } = useSubscriberSettingsNotifications();
    const youAndYourPosts: OnlyFansSettingsNotificationsTransport.Response | undefined = onlyFansNotificationSettings?.find(
        n => n.code === 'you_and_your_posts',
    );
    const returningSubscriberNotifications: OnlyFansSettingsNotificationsTransport.Item | undefined = youAndYourPosts?.items.find(
        n => n.code === 'returning_subscriber',
    );
    const siteNotificationsOn: boolean = returningSubscriberNotifications?.isEnabled ?? false;

    // Returning Subscriber data
    const [autoMessageReturningSubscriber, setAutoMessageReturningSubscriber] =
        useState<OnlyFansSubscriberMessageUsersAutoMessageDetails | null>(null);
    const [autoMessageReturningSubscriberInitialValues, setAutoMessageReturningSubscriberInitialValues] =
        useState<OnlyFansSubscriberMessageUsersAutoMessageDetails | null>(null);
    const [vaultAlbumNameReturningSubscriber, setVaultAlbumNameReturningSubscriber] = useState<string | undefined>();
    const [saveTemplateReturningSubscriber, setSaveTemplateReturningSubscriber] = useState<boolean>(false);
    const [saveTemplateNameReturningSubscriber, setSaveTemplateNameReturningSubscriber] = useState<string>('');
    const [autoMessageReturningSubscriberIsFormValid, setAutoMessageReturningSubscriberIsFormValid] = useState<boolean>(false);

    useEffect(() => {
        if (subscriber) {
            setUnitCost(isSextforceActive(subscriber) ? 0 : d2f(subscriber._config.messageUsers.prices.unitCost));
        }
    }, [subscriber]);

    useEffect(() => {
        if (serviceSettings) {
            if (!autoMessageReturningSubscriber) {
                setAutoMessageReturningSubscriber(serviceSettings.autoMessageReturningSubscriber);
            }

            if (!autoMessageReturningSubscriberInitialValues) {
                setAutoMessageReturningSubscriberInitialValues(serviceSettings.autoMessageReturningSubscriber || null);
            }
        } else {
            setAutoMessageReturningSubscriber(null);
            setAutoMessageReturningSubscriberInitialValues(null);
        }
    }, [autoMessageReturningSubscriber, autoMessageReturningSubscriberInitialValues, serviceSettings]);

    const handleSaveSettings = () => {
        const doSave = async () => {
            await setServiceSettingsMessageUsersAutoWelcomeMessageReturningSubscriber(
                autoMessageReturningSubscriber,
                saveTemplateReturningSubscriber,
                saveTemplateNameReturningSubscriber,
            )
                .then(newData => {
                    enqueueSnackbar('Settings saved successfully!', { variant: 'success' });

                    setAutoMessageReturningSubscriber(newData.autoMessageReturningSubscriber);
                    setAutoMessageReturningSubscriberInitialValues(newData.autoMessageReturningSubscriber);

                    // Scroll to top
                    window.scrollTo(0, 0);
                })
                .catch(() => {
                    enqueueSnackbar('Failed to save settings!', { variant: 'error' });

                    setAutoMessageReturningSubscriberInitialValues(null);
                });
        };

        doSave();
    };

    const resetForm = () => {
        setAutoMessageReturningSubscriber(null);
    };

    return (
        <PageContainer>
            {subscriber && '_id' in subscriber && subscriber._id && (
                <>
                    <Typography variant="h5" sx={{ mb: 2 }}>
                        <BackNavigationButton url={`/subscribers/${subscriber._id}/${settingsContext.services.messageUsers.homeUrl}`} />{' '}
                        Auto Welcome Message for {subscriber.username}
                    </Typography>

                    {unitCost !== 0 && (
                        <Grid container spacing={1} flexGrow={0} justifyContent="center" sx={{ marginBottom: theme.spacing(2) }}>
                            <Grid item xs={12} md={6}>
                                <SharedCreditCard showTopUpButton={false} />
                            </Grid>
                        </Grid>
                    )}

                    <StyledCard noCard noBottomMargin>
                        <StyledCardTitleBar title="Auto Message Returning Subscriber" theme={theme} />
                    </StyledCard>
                    <StyledCard>
                        <Typography gutterBottom>
                            Use the forms below to setup a message that will be sent automatically to a fan when they resubscribe. The fan
                            will receive a regular message from you which will appear in their Priority inbox at a random time 1 to 2
                            minutes after one of these events. Creators will be skipped automatically.
                        </Typography>
                        <Typography gutterBottom>
                            Use this service to send a thank you message to your returning fans. Use the opportunity to offer them a free
                            treat or a PPV to unlock. Try to end the message on a question to encourage a reply.
                        </Typography>
                        <MessageUsersPriceBar unitCost={unitCost} includedWith="Sextforce" theme={theme} />
                    </StyledCard>

                    <StyledCard noCard>
                        {!onlyFansNotificationSettingsLoading && !siteNotificationsOn && (
                            <Alert severity="warning" sx={{ mb: 2 }}>
                                <Typography variant="body1">
                                    <strong>Important:</strong> For this service to work, you must have the "Returning Subscriber"
                                    notification turned on in{' '}
                                    <Link to={'https://onlyfans.com/my/settings/notifications/site'} target="_blank">
                                        OnlyFans Notifications Settings
                                    </Link>
                                    .
                                </Typography>
                            </Alert>
                        )}
                    </StyledCard>

                    <StyledCard noCard noBottomMargin>
                        <StyledCardTitleBar title="Message Settings" theme={theme} />
                    </StyledCard>

                    {/* {serviceSettingsLoading ? <Skeleton height={400} sx={{ mb: 1 }} /> : null} */}
                    {autoMessageReturningSubscriber && (
                        <AutoSendWelcomeMessageDetailsForm
                            title="Auto Message Returning Subscriber"
                            description="This message will be sent to each returning subscriber as soon as they resubscribe to your page. You can use this message to thank them for being a returning customer, and to send them some free treat or a PPV to unlock."
                            subscriber={subscriber}
                            initialValues={autoMessageReturningSubscriberInitialValues}
                            messageDetails={autoMessageReturningSubscriber}
                            setMessageDetails={setAutoMessageReturningSubscriber}
                            vaultAlbumName={vaultAlbumNameReturningSubscriber}
                            setVaultAlbumName={setVaultAlbumNameReturningSubscriber}
                            saveTemplate={saveTemplateReturningSubscriber}
                            setSaveTemplate={setSaveTemplateReturningSubscriber}
                            saveTemplateName={saveTemplateNameReturningSubscriber}
                            setSaveTemplateName={setSaveTemplateNameReturningSubscriber}
                            setIsFormValid={setAutoMessageReturningSubscriberIsFormValid}
                        />
                    )}

                    <StyledCard noCard>
                        <Grid
                            container
                            spacing={1}
                            flexGrow={0}
                            alignItems="center"
                            justifyContent="center"
                            sx={{ marginBottom: theme.spacing(2) }}
                        >
                            <Grid item xs={8}>
                                <LoadingButton
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    loading={
                                        setServiceSettingsMessageUsersAutoWelcomeMessageLoading ||
                                        subscriberLoading ||
                                        serviceSettingsLoading
                                    }
                                    fullWidth
                                    disabled={
                                        setServiceSettingsMessageUsersAutoWelcomeMessageLoading ||
                                        subscriberLoading ||
                                        serviceSettingsLoading ||
                                        !autoMessageReturningSubscriberIsFormValid
                                    }
                                    onClick={() => {
                                        dialog
                                            .confirm({
                                                message: `Are you sure you want to save these settings?`,
                                                title: 'Confirm',
                                                ok: { text: 'Save', variant: 'contained' },
                                                cancel: { text: 'Cancel' },
                                            })
                                            .then(async () => {
                                                await handleSaveSettings();
                                            })
                                            .catch(() => {});
                                    }}
                                >
                                    Save Settings
                                </LoadingButton>
                            </Grid>
                            <Grid item xs={4}>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    size="large"
                                    fullWidth
                                    disabled={
                                        setServiceSettingsMessageUsersAutoWelcomeMessageLoading ||
                                        subscriberLoading ||
                                        serviceSettingsLoading
                                    }
                                    onClick={() => {
                                        dialog
                                            .confirm({
                                                message: `Are you sure you want to reset the whole form? Everything you've entered will be cleared.`,
                                                title: 'Confirm',
                                                ok: {
                                                    text: 'Reset',
                                                    variant: 'contained',
                                                    color: 'secondary',
                                                },
                                                cancel: { text: 'Cancel' },
                                            })
                                            .then(() => {
                                                resetForm();
                                            })
                                            .catch(() => {});
                                    }}
                                >
                                    Clear
                                </Button>
                            </Grid>
                        </Grid>
                    </StyledCard>
                </>
            )}
        </PageContainer>
    );
};

export default MessageUsersAutoSendWelcomeMessageReturningSubscriber;
