import { Checkbox, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper } from '@mui/material';
import { Theme } from '@mui/system';

interface Props {
    sortedReleaseForms: any[];
    taggedReleaseForms: { id: number; name: string }[];
    handleListItemClicked: (id: number) => void;
    theme: Theme;
    disabled?: boolean;
}

const MessageUsersTagReleaseFormsList = (props: Props) => {
    const { sortedReleaseForms, taggedReleaseForms, handleListItemClicked, theme, disabled } = props;

    return (
        <Paper variant="outlined" sx={{ height: 200, overflow: 'auto', width: '100%' }}>
            <List dense sx={{ width: '100%' }}>
                {sortedReleaseForms.map((list: any) => (
                    <ListItem
                        disablePadding
                        key={list.id}
                        sx={{
                            backgroundColor:
                                taggedReleaseForms && taggedReleaseForms.find(item => item.id === list.id) ? theme.palette.grey[200] : '',
                        }}
                    >
                        <ListItemButton
                            role={undefined}
                            dense
                            disableRipple={disabled}
                            disabled={disabled}
                            onClick={() => {
                                if (!disabled) {
                                    handleListItemClicked(list.id);
                                }
                            }}
                        >
                            <ListItemIcon>
                                <Checkbox
                                    tabIndex={-1}
                                    disableRipple
                                    checked={taggedReleaseForms && taggedReleaseForms.find(item => item.id === list.id) ? true : false}
                                    disabled={disabled}
                                />
                            </ListItemIcon>
                            <ListItemText primary={list.name} secondary={`Status: ${list.status} | Type: ${list.type}`} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Paper>
    );
};

export default MessageUsersTagReleaseFormsList;
