import dinero from 'dinero.js';
import { Theme } from '@mui/system';
import { Card, CardContent, Grid, MenuItem, RadioGroup, Select, Skeleton, Typography } from '@mui/material';
import { d2f } from '../../../../utils/common';
import { useEffect, useState } from 'react';

type Props = {
    sextforceActive: boolean;
    hoursSinceMessage: number;
    setHoursSinceMessage: (hours: number) => void;
    showSalesMode: string;
    setShowSalesMode: (mode: string) => void;
    sessionsSalesData: any[];
    sessionsSalesDataLoading: boolean;
    theme: Theme;
};

const MessageUsersHistorySessionsAnalytics = (props: Props) => {
    const {
        sextforceActive,
        hoursSinceMessage,
        setHoursSinceMessage,
        showSalesMode,
        setShowSalesMode,
        sessionsSalesData,
        sessionsSalesDataLoading,
        theme,
    } = props;

    const [totalSales, setTotalSales] = useState<number>(0);

    useEffect(() => {
        if (sessionsSalesDataLoading || !sextforceActive) {
            return;
        }

        let total: number = 0;

        for (let i = 0; i < sessionsSalesData.length; i += 1) {
            if (sessionsSalesData[i]) {
                total += d2f(showSalesMode === 'net' ? sessionsSalesData[i].totalNet : sessionsSalesData[i].totalAmount);
            }
        }

        setTotalSales(Math.trunc(total * 100));
    }, [sessionsSalesData, sessionsSalesDataLoading, sextforceActive, showSalesMode]);

    return (
        <Card variant="elevation" sx={{ marginBottom: theme.spacing(2) }}>
            <CardContent>
                <Typography variant="h6" sx={{ marginBottom: theme.spacing(2) }}>
                    Analytics
                </Typography>

                <Grid container flexGrow={1} spacing={1} alignItems="center" sx={{ maxWidth: theme.breakpoints.values.sm }}>
                    <Grid item xs={12}>
                        <Grid container flexGrow={1} spacing={1} alignItems="center">
                            <Grid item xs={8}>
                                Show sales made within
                            </Grid>
                            <Grid item xs={4}>
                                <Select
                                    size="small"
                                    value={hoursSinceMessage}
                                    disabled={!sextforceActive}
                                    onChange={e => {
                                        setHoursSinceMessage(e.target.value as number);
                                    }}
                                >
                                    {[1, 2, 3, 4, 5, 6, 7, 8, 10, 12, 16, 24].map(hours => (
                                        <MenuItem value={hours} key={hours}>
                                            {hours} hours
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <RadioGroup
                            value={showSalesMode}
                            onChange={e => {
                                setShowSalesMode(e.currentTarget.value);
                            }}
                            sx={{ paddingBottom: theme.spacing(1) }}
                        >
                            <Grid container flexGrow={1} spacing={1} alignItems="center">
                                <Grid item xs={8}>
                                    Show total as
                                </Grid>
                                <Grid item xs={4}>
                                    <Select
                                        size="small"
                                        value={showSalesMode}
                                        disabled={!sextforceActive}
                                        onChange={e => {
                                            setShowSalesMode(e.target.value);
                                        }}
                                    >
                                        <MenuItem value={'gross'}>Gross</MenuItem>
                                        <MenuItem value={'net'}>Net</MenuItem>
                                    </Select>
                                </Grid>
                            </Grid>
                        </RadioGroup>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container flexGrow={1} spacing={1} alignItems="center">
                            <Grid item xs={8}>
                                Total Sales in Report
                            </Grid>
                            <Grid item xs={4}>
                                {sextforceActive ? (
                                    sessionsSalesDataLoading ? (
                                        <Skeleton />
                                    ) : (
                                        <span style={{ fontFamily: 'monospace', color: theme.palette.success.dark }}>
                                            {dinero({
                                                amount: totalSales,
                                                currency: 'USD',
                                            }).toFormat()}
                                        </span>
                                    )
                                ) : (
                                    'N/A'
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default MessageUsersHistorySessionsAnalytics;
