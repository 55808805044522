import LoadingButton from '@mui/lab/LoadingButton';
import {
    Card,
    CardContent,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    MenuItem,
    Radio,
    Select,
    SelectChangeEvent,
    TextField,
} from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';
import { Theme } from '@mui/system';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import SelectTimezone from '../../../forms/helpers/SelectTimezone';

// Generate Select menu items for year
const YearMenuItems = () => {
    const menuItems = [];

    for (let i = moment().year(); i > 2000; i -= 1) {
        menuItems.push(
            <MenuItem value={i} key={i}>
                {i}
            </MenuItem>,
        );
    }

    return menuItems;
};

// Generate Select menu items for week of the year
const YearWeekMenuItems = () => {
    const menuItems = [];

    for (let i = 1; i <= 52; i += 1) {
        menuItems.push(
            <MenuItem value={i} key={i}>
                {i}
            </MenuItem>,
        );
    }

    return menuItems;
};

type ReportSearchBarPropsSeachParams = {
    freeRange: boolean;
    setFreeRange: React.Dispatch<React.SetStateAction<boolean>>;
    week: number;
    setWeek: React.Dispatch<React.SetStateAction<number>>;
    dateFrom: Date;
    setDateFrom: React.Dispatch<React.SetStateAction<Date>>;
    dateTo: Date;
    setDateTo: React.Dispatch<React.SetStateAction<Date>>;
};

type FollowBackReportSearchBarProps = {
    timezone: string;
    setTimezone: (timezone: string) => void;
    mainSearch: ReportSearchBarPropsSeachParams;
    isFetchingReport: boolean;
    requestReport: () => void;
};

const FollowBackReportSearchBar = (props: FollowBackReportSearchBarProps) => {
    const { timezone, setTimezone, mainSearch, isFetchingReport, requestReport } = props;
    const { freeRange: mainFreeRange, week: mainWeek, setDateFrom: mainSetDateFrom, setDateTo: mainSetDateTo } = mainSearch;
    const theme: Theme = useTheme();
    const [mainWeekStartsOn, setMainWeekStartsOn] = useState<number>(1);
    const [mainReportYear, setMainReportYear] = useState<number>(moment().year());

    // Set global MomentJS locale
    moment.locale('en-gb', {
        week: {
            dow: mainWeekStartsOn,
            doy: 0,
        },
    });

    useEffect(() => {
        if (!mainFreeRange) {
            const startOfWeek = moment().year(mainReportYear).week(mainWeek);

            mainSetDateFrom(startOfWeek.startOf('week').toDate());
            mainSetDateTo(startOfWeek.endOf('week').toDate());
        }
    }, [mainFreeRange, mainWeek, mainSetDateFrom, mainSetDateTo, mainWeekStartsOn, mainReportYear]);

    return (
        <Card variant="elevation" sx={{ marginBottom: theme.spacing(2), displayPrint: 'none' }}>
            <CardContent style={{ padding: theme.spacing(1) }}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <Grid container spacing={1} alignItems={'center'}>
                        <Grid item xs>
                            <SelectTimezone timezone={timezone} setTimezone={setTimezone} size="small" fullWidth={true} />
                        </Grid>
                        <Grid item xs={'auto'}>
                            <FormControlLabel
                                checked={!mainSearch.freeRange}
                                control={<Radio size="small" />}
                                label="By Week"
                                onChange={() => mainSearch.setFreeRange(false)}
                            />
                        </Grid>
                        <Grid item xs={'auto'}>
                            <FormControl fullWidth>
                                <InputLabel id="select-weekStartsOn-label">Week Starts On</InputLabel>
                                <Select
                                    labelId="select-weekStartsOn-label"
                                    id="weekStartsOn"
                                    value={mainWeekStartsOn}
                                    label="Week Starts On"
                                    size="small"
                                    disabled={mainSearch.freeRange}
                                    onChange={(e: SelectChangeEvent<number>) => setMainWeekStartsOn(e.target.value as number)}
                                >
                                    <MenuItem value={0}>Sunday</MenuItem>
                                    <MenuItem value={1}>Monday</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={'auto'}>
                            <FormControl fullWidth>
                                <InputLabel id="select-year-label">Year</InputLabel>
                                <Select
                                    labelId="select-year-label"
                                    id="reportYear"
                                    value={mainReportYear}
                                    label="Year"
                                    size="small"
                                    disabled={mainSearch.freeRange}
                                    onChange={(e: SelectChangeEvent<number>) => setMainReportYear(e.target.value as number)}
                                >
                                    {YearMenuItems()}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={'auto'}>
                            <FormControl fullWidth>
                                <InputLabel id="select-week-label">Week</InputLabel>
                                <Select
                                    labelId="select-week-label"
                                    id="reportWeek"
                                    value={mainSearch.week}
                                    label="Week"
                                    size="small"
                                    disabled={mainSearch.freeRange}
                                    onChange={(e: SelectChangeEvent<number>) => mainSearch.setWeek(e.target.value as number)}
                                >
                                    {YearWeekMenuItems()}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={'auto'}>
                            <FormControlLabel
                                checked={mainSearch.freeRange}
                                control={<Radio size="small" />}
                                label="Free Range"
                                onChange={() => mainSearch.setFreeRange(true)}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <DatePicker
                                label="Start Date"
                                inputFormat="DD/MM/YYYY"
                                mask={'__/__/____'}
                                disabled={!mainSearch.freeRange}
                                value={mainSearch.dateFrom}
                                onChange={newValue => {
                                    if (newValue) {
                                        mainSearch.setDateFrom(moment(newValue).startOf('day').toDate());
                                    }
                                }}
                                renderInput={params => <TextField size="small" {...params} />}
                                showDaysOutsideCurrentMonth={true}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <DatePicker
                                label="End Date"
                                inputFormat="DD/MM/YYYY"
                                mask={'__/__/____'}
                                disabled={!mainSearch.freeRange}
                                value={mainSearch.dateTo}
                                onChange={newValue => {
                                    if (newValue) {
                                        mainSearch.setDateTo(moment(newValue).endOf('day').toDate());
                                    }
                                }}
                                renderInput={params => <TextField size="small" {...params} />}
                                showDaysOutsideCurrentMonth={true}
                            />
                        </Grid>
                        <Grid item xs={1}>
                            <LoadingButton
                                variant="contained"
                                disabled={isFetchingReport}
                                loading={isFetchingReport}
                                size="medium"
                                onClick={requestReport}
                            >
                                Show
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </LocalizationProvider>
            </CardContent>
        </Card>
    );
};

export default FollowBackReportSearchBar;
