import { useDialog } from 'muibox';
import { useContext, useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { SettingsContext } from '../store/SettingsContext';
import { UserContext } from '../store/UserContext';
import { OnlyFansBotSession } from '../types/onlyFansDbTypes';
import { handleHttpError } from '../utils/common';
import useAxios from './useAxios';
import useSubscriber from './useSubscriber';

const useMessageUsersCurrentSessions = () => {
    const userContext = useContext(UserContext);
    const settingsContext = useContext(SettingsContext);
    const dialog = useDialog();
    const params = useParams();
    const axios = useAxios();

    const { data: subscriber } = useSubscriber();

    const [refreshCurrentSession, setRefreshCurrentSession] = useState<boolean>(false);
    const [isMessageUsersSendSessionRunning, setMessageUsersSendSessionRunning] = useState<boolean>(false);
    const [isMessageUsersUnendSessionRunning, setMessageUsersUnsendSessionRunning] = useState<boolean>(false);
    const [isMessageUsersSendJobInQueue, setMessageUsersSendJobInQueue] = useState<boolean>(false);
    const [isMessageUsersUnsendJobInQueue, setMessageUsersUnsendJobInQueue] = useState<boolean>(false);

    // Fetch Follow-Back Overview
    const fetchCurrentSession = async (): Promise<{
        messageUsers: {
            sessions: OnlyFansBotSession[];
            queue: boolean;
        };
        unsendMessage: {
            sessions: OnlyFansBotSession[];
            queue: boolean;
        };
    }> => {
        const query: string = `${settingsContext.routes.messageUsers.base}${params.userId}/currentSessions`;

        return axios
            .get(query)
            .then(response => response.data)
            .catch(error => {
                console.error(error);
                handleHttpError(error, dialog);
            });
    };

    const messageUsersCurrentSessions = useQuery(['messageUsersCurrentSessions', subscriber], () => fetchCurrentSession(), {
        onSuccess: data => {
            if (data) {
                const sendSessionRunning = data.messageUsers.sessions.length > 0 ? true : false;
                const unsendSessionRunning = data.unsendMessage.sessions.length > 0 ? true : false;
                const messageUsersSendJobInQueue = data && data.messageUsers.queue ? true : false;
                const messageUsersUnsendJobInQueue = data && data.unsendMessage.queue ? true : false;

                setMessageUsersSendSessionRunning(sendSessionRunning);
                setMessageUsersUnsendSessionRunning(unsendSessionRunning);
                setMessageUsersSendJobInQueue(messageUsersSendJobInQueue);
                setMessageUsersUnsendJobInQueue(messageUsersUnsendJobInQueue);

                setRefreshCurrentSession(
                    sendSessionRunning || unsendSessionRunning || messageUsersSendJobInQueue || messageUsersUnsendJobInQueue ? true : false,
                );
            } else {
                setMessageUsersSendSessionRunning(false);
                setMessageUsersUnsendSessionRunning(false);
                setMessageUsersSendJobInQueue(false);
                setRefreshCurrentSession(false);
                setMessageUsersUnsendJobInQueue(false);
            }

            //  setIsSendingMessage(false);
        },
        refetchOnWindowFocus: false,
        staleTime: 0,
        enabled:
            userContext.jwtToken && settingsContext.apiKey && 'userId' in params && params.userId && subscriber && subscriber._id
                ? true
                : false,
        refetchInterval: refreshCurrentSession ? 5000 : 0,
    });

    return {
        ...messageUsersCurrentSessions,
        isMessageUsersSendSessionRunning,
        isMessageUsersUnendSessionRunning,
        isMessageUsersSendJobInQueue,
        isMessageUsersUnsendJobInQueue,
    };
};

export default useMessageUsersCurrentSessions;
