import axios from 'axios';
import { useDialog } from 'muibox';
import { useContext } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { SettingsContext } from '../store/SettingsContext';
import { UserContext } from '../store/UserContext';
import { handleHttpError } from '../utils/common';

export declare module OnlyFansPosts {
    export interface Author {
        id: number;
        _view: string;
    }

    export interface MentionedUser {
        id: number;
        _view: string;
    }

    export interface Author2 {
        id: number;
        _view: string;
    }

    export interface Source {
        source: string;
        width: number;
        height: number;
        size: number;
        duration: number;
    }

    export interface Preview {
        width: number;
        height: number;
        size: number;
    }

    export interface Info {
        source: Source;
        preview: Preview;
    }

    export interface Source2 {
        source: string;
        width: number;
        height: number;
        size: number;
        duration: number;
    }

    export interface Option {
        id: number;
        url: string;
        selected: boolean;
    }

    export interface Preview2 {
        url: string;
        options: Option[];
    }

    export interface Files {
        preview: Preview2;
    }

    export interface VideoSources {
        720: string;
        240: string;
    }

    export interface Medium {
        id: number;
        type: string;
        convertedToVideo: boolean;
        canView: boolean;
        hasError: boolean;
        createdAt: Date;
        info: Info;
        source: Source2;
        squarePreview: string;
        full: string;
        preview: string;
        thumb: string;
        files: Files;
        videoSources: VideoSources;
    }

    export interface AvatarThumbs {
        c50: string;
        c144: string;
    }

    export interface User {
        view: string;
        id: number;
        name: string;
        username: string;
        isVerified: boolean;
        avatar: string;
        avatarThumbs: AvatarThumbs;
        ivStatus: string;
        isFromGuest: boolean;
    }

    export interface ReleaseForm {
        id: number;
        name: string;
        partnerSource: string;
        type: string;
        user: User;
    }

    export interface LinkedPost {
        responseType: string;
        id: number;
        postedAt: Date;
        postedAtPrecise: string;
        expiredAt?: number;
        author: Author2;
        text: string;
        rawText: string;
        lockedText: boolean;
        isFavorite: boolean;
        canReport: boolean;
        canDelete: boolean;
        canComment: boolean;
        canEdit: boolean;
        isPinned: boolean;
        favoritesCount: number;
        mediaCount: number;
        isMediaReady: boolean;
        voting: unknown[];
        isOpened: boolean;
        canToggleFavorite: boolean;
        streamId?: unknown;
        price?: unknown;
        hasVoting: boolean;
        isAddedToBookmarks: boolean;
        isArchived: boolean;
        isDeleted: boolean;
        hasUrl: boolean;
        commentsCount: number;
        mentionedUsers: unknown[];
        linkedUsers: unknown[];
        linkedPosts: unknown[];
        tipsAmount: string;
        tipsAmountRaw: number;
        isPublishedWithPeriod: boolean;
        hasPurchases: boolean;
        media: Medium[];
        canViewMedia: boolean;
        preview: unknown[];
    }

    export interface Source3 {
        source: string;
        width: number;
        height: number;
        size: number;
        duration: number;
    }

    export interface Preview3 {
        width: number;
        height: number;
        size: number;
    }

    export interface Info2 {
        source: Source3;
        preview: Preview3;
    }

    export interface Source4 {
        source: string;
        width: number;
        height: number;
        size: number;
        duration: number;
    }

    export interface Option2 {
        id: number;
        url: string;
        selected: boolean;
    }

    export interface Preview4 {
        url: string;
        options: Option2[];
    }

    export interface Files2 {
        preview: Preview4;
    }

    export interface VideoSources2 {
        720: string;
        240: string;
    }

    export interface Medium2 {
        id: number;
        type: string;
        convertedToVideo: boolean;
        canView: boolean;
        hasError: boolean;
        createdAt: Date;
        info: Info2;
        source: Source4;
        squarePreview: string;
        full: string;
        preview: string;
        thumb: string;
        files: Files2;
        videoSources: VideoSources2;
    }

    export interface FundRaising {
        target?: number;
        targetProgress: number;
        presets: string[];
    }

    export interface Voting {
        finishedAt: Date;
        options: VotingOption[];
        total: number;
    }

    export interface VotingOption {
        id: number;
        name: string;
        count: number;
        isVoted: boolean;
        isCorrect?: boolean;
    }

    export interface List {
        responseType: string;
        id: number;
        postedAt: Date;
        postedAtPrecise: string;
        expiredAt: Date | null;
        author: Author;
        text: string;
        rawText: string;
        lockedText: boolean;
        isFavorite: boolean;
        canReport: boolean;
        canDelete: boolean;
        canComment: boolean;
        canEdit: boolean;
        isPinned: boolean;
        favoritesCount: number;
        mediaCount: number;
        isMediaReady: boolean;
        voting: Voting;
        votingType?: number;
        isOpened: boolean;
        canToggleFavorite: boolean;
        streamId: null;
        price: number;
        hasVoting: boolean;
        isAddedToBookmarks: boolean;
        isArchived: boolean;
        isPrivateArchived: boolean;
        isDeleted: boolean;
        hasUrl: boolean;
        isCouplePeopleMedia: boolean;
        releaseForms: ReleaseForm[];
        commentsCount: number;
        mentionedUsers: MentionedUser[];
        linkedUsers: unknown[];
        linkedPosts: LinkedPost[];
        tipsAmount: string;
        tipsAmountRaw: number;
        isPublishedWithPeriod: boolean;
        hasPurchases: boolean;
        media: Medium2[];
        canViewMedia: boolean;
        preview: number[];
        labelStates: LabelState[];
        fundRaising?: FundRaising;
    }

    export interface Counters {
        audiosCount: number;
        photosCount: number;
        videosCount: number;
        mediasCount: number;
        postsCount: number;
        archivedPostsCount: number;
    }

    export interface DeletePostResponse {
        counters: Counters;
        success: boolean;
    }

    export interface ArchivePostResponse {
        counters: Counters;
        labelStates: LabelState[];
    }

    export interface LabelState {
        id: number;
        name: string;
        type: string;
        isClearInProgress: boolean;
        postsCount: number;
        posts: Post[];
    }

    export interface Post {
        type: string;
        postId: number;
        url: string;
    }

    export interface Posts {
        list: List[];
        hasMore: boolean;
        headMarker: string;
        tailMarker: string;
        counters: Counters;
    }

    export interface PinPostResponse {
        success: boolean;
    }
}

const useSubscriberPosts = (postId: number) => {
    const userContext = useContext(UserContext);
    const settingsContext = useContext(SettingsContext);
    const dialog = useDialog();
    const params = useParams();

    const fetchPost = async (postId: number | null | void): Promise<OnlyFansPosts.List> => {
        const query: string = `${settingsContext.routes.onlyFans.base}${params.userId}/posts/${postId}`;

        return axios
            .get(query, {
                headers: {
                    Authorization: userContext.jwtToken,
                    apiKey: settingsContext.apiKey,
                },
            })
            .then(async response => response.data)
            .catch(async error => {
                console.error('fetchSettingsNotificationsSite', error);

                await handleHttpError(error, dialog).then(() => {
                    return Promise.reject(error);
                });
            });
    };

    const onlyFansPosts = useQuery(['onlyFansPosts', params.userId, userContext.jwtToken, postId], () => fetchPost(postId), {
        refetchOnWindowFocus: false,
        // 5 minutes
        staleTime: 300000,
        retry: (failureCount, error: any) => {
            if (error.response.status === 400) {
                return false;
            }

            return failureCount < 3;
        },
        enabled: userContext.jwtToken && settingsContext.apiKey && userContext.user.username && params.userId && postId ? true : false,
    });

    return {
        ...onlyFansPosts,
    };
};

export default useSubscriberPosts;
