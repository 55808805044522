import { faMoneyBillTransfer } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AddIcon from '@mui/icons-material/Add';
import { Button, Grid, Stack, Typography } from '@mui/material';
import { green, orange, red } from '@mui/material/colors';
import dinero from 'dinero.js';
import { Link } from 'react-router-dom';
import { creditStatus, d2f } from '../../utils/common';

interface Props {
    subscriber: any;
    openTransferCreditDialog: (subscriber: any) => void;
}

const DashboardMyAccountsSharedCredit = (props: Props) => {
    const { subscriber, openTransferCreditDialog } = props;
    const sharedCredit: number = subscriber.financial && subscriber.financial.sharedCredit ? d2f(subscriber.financial.sharedCredit) : 0;
    const creditStatusName: string = creditStatus(sharedCredit);

    const status: any = {
        good: {
            color: green[900],
            background: green[100],
        },
        low: {
            color: orange[900],
            background: orange[100],
        },
        none: {
            color: red[900],
            background: red[100],
        },
    };

    return (
        <Grid container alignItems="center" spacing={1}>
            <Grid item xs={12} textAlign={'center'}>
                <Typography variant="body2" color={'text.secondary'}>
                    Available Credit
                </Typography>
            </Grid>
            {/* <Grid item xs="auto">
                                                <CurrencyPoundIcon htmlColor={status[creditStatusName].color} />
                                            </Grid> */}
            {/* <Grid item xs>
            </Grid> */}
            <Grid item xs={12} textAlign="center">
                <Typography variant="h6" color={status[creditStatusName].color} sx={{ display: 'inline' }}>
                    {dinero({
                        amount: Math.trunc(sharedCredit * 100),
                        currency: 'GBP',
                    }).toFormat()}
                </Typography>
            </Grid>
            <Grid item xs={12} textAlign="center">
                <Stack direction="row" spacing={0}>
                    <Button
                        variant="contained"
                        endIcon={<AddIcon />}
                        component={Link}
                        to={`/subscribers/${subscriber._id}/account/sharedCreditCheckout`}
                        color="success"
                    >
                        Top Up
                    </Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        size="large"
                        onClick={() => {
                            openTransferCreditDialog(subscriber);
                        }}
                        disabled={sharedCredit === 0}
                    >
                        <FontAwesomeIcon icon={faMoneyBillTransfer} />
                    </Button>
                </Stack>
            </Grid>
        </Grid>
    );
};

export default DashboardMyAccountsSharedCredit;
