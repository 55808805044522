import { Card, CardContent, Container, Typography, useTheme } from '@mui/material';
import { Theme } from '@mui/system';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import BackNavigationButton from '../../../components/common/BackNavigationButton';
import SextforceMetricsAccountFansCounters from '../../../components/services/sextforce/metrics/account/SextforceMetricsAccountFansCounters';
import SextforceMetricsAccountMonthlySalesGraph from '../../../components/services/sextforce/metrics/account/SextforceMetricsAccountMonthlySalesGraph';
import SextforceMetricsAccountMonthlyUniqueSpenders from '../../../components/services/sextforce/metrics/account/SextforceMetricsAccountMonthlyUniqueSpenders';
import useDashboardAccount from '../../../hooks/useDashboardAccount';
import useSextforceMetricsAccountFansCountersAll from '../../../hooks/useSextforceMetricsAccountFansCountersAll';
import useSextforceMetricsAccountMonthlySales from '../../../hooks/useSextforceMetricsAccountMonthlySales';
import useSextforceMetricsAccountMonthlyUniqueSpenders from '../../../hooks/useSextforceMetricsAccountMonthlyUniqueSpenders';
import useSubscriber from '../../../hooks/useSubscriber';
import { SettingsContext } from '../../../store/SettingsContext';

const SextforceMetricsAccount = () => {
    const settingsContext = useContext(SettingsContext);
    const theme: Theme = useTheme();
    const { data: subscriber } = useSubscriber();
    const { dashboardAccount, dashboardAccountLoading } = useDashboardAccount(true);

    const [timezone, setTimezone] = useState<string>(moment.tz.guess());
    const [startDate, setStartDate] = useState<Date>(moment().tz(timezone).subtract(1, 'year').startOf('month').toDate());
    const [endDate, setEndDate] = useState<Date>(moment().tz(timezone).endOf('month').toDate());

    const { data: monthlySales, isLoading: monthlySalesLoading } = useSextforceMetricsAccountMonthlySales(startDate, endDate, timezone);
    const { data: monthlyUniqueSpenders, isLoading: monthlyUniqueSpendersLoading } = useSextforceMetricsAccountMonthlyUniqueSpenders(
        startDate,
        endDate,
        timezone,
    );
    const { data: fansCountersAll, isLoading: fansCountersAllLoading } = useSextforceMetricsAccountFansCountersAll(
        startDate,
        endDate,
        timezone,
    );

    // Set global MomentJS locale
    moment.locale('en-gb');

    useEffect(() => {
        if (!dashboardAccountLoading && dashboardAccount) {
            if (dashboardAccount && dashboardAccount.timezone) {
                setTimezone(dashboardAccount.timezone);
            }
        }
    }, [dashboardAccountLoading, dashboardAccount]);

    return (
        <Container
            maxWidth={false}
            sx={{ paddingTop: theme.spacing(4), paddingBottom: theme.spacing(4), backgroundColor: '#e7ebf0', minHeight: '100%' }}
        >
            <div style={{ paddingBottom: theme.spacing(4) }}>
                <Typography variant="h5">
                    <BackNavigationButton
                        {...(subscriber && { url: `/subscribers/${subscriber._id}/${settingsContext.services.sextforce.homeUrl}` })}
                    />{' '}
                    Metrics for {subscriber?.username}
                </Typography>
            </div>

            <Card sx={{ marginBottom: theme.spacing(2) }}>
                <CardContent>Here you will find overall account insight and statistics and a 12-month sales analysis.</CardContent>
            </Card>

            <SextforceMetricsAccountMonthlySalesGraph monthlySales={monthlySales} monthlySalesLoading={monthlySalesLoading} theme={theme} />

            <SextforceMetricsAccountMonthlyUniqueSpenders
                monthlyUniqueSpenders={monthlyUniqueSpenders}
                monthlyUniqueSpendersLoading={monthlyUniqueSpendersLoading}
                theme={theme}
            />

            <SextforceMetricsAccountFansCounters counters={fansCountersAll} countersLoading={fansCountersAllLoading} theme={theme} />
        </Container>
    );
};

export default SextforceMetricsAccount;
