import FollowBackSVG from '../../assets/followBack/followBack.svg';

type IconOnlyFansProps = {
    width?: string | number;
    height?: string | number;
    style?: React.CSSProperties;
};

const LogoFollowBack = (props: IconOnlyFansProps) => {
    const { width, height, style } = props;

    return <img src={FollowBackSVG} width={width} height={height} alt="Follow-Back Logo" style={style} />;
};

export default LogoFollowBack;
