import { Controller, Control } from 'react-hook-form';
import { TextField } from '@mui/material';

type FormTextFieldProps = {
    control: Control<any>;
    errors: any;
    name: string;
    label: string;
    required?: boolean;
    value?: any;
    InputProps?: any;
    fullWidth?: boolean;
    size?: 'small' | 'medium';
    disabled?: boolean;
    sx?: any;
};

const FormTextField = (props: FormTextFieldProps) => {
    const { control, errors, name, label, required, value, InputProps, fullWidth, size, disabled, sx } = props;

    return (
        <Controller
            name={name}
            control={control}
            rules={{ required }}
            {...(value && { defaultValue: value })}
            render={({ field }) => (
                <TextField
                    {...field}
                    fullWidth={fullWidth}
                    label={label}
                    size={size}
                    disabled={disabled}
                    error={errors[name] ? true : false}
                    helperText={errors[name] && errors[name]?.message}
                    InputProps={InputProps}
                    sx={sx}
                />
            )}
        />
    );
};

export default FormTextField;
