import { Chip, Skeleton } from '@mui/material';
import useSextforceMetricsClaimsCountToday from '../../../../hooks/useSextforceMetricsClaimsCountToday';

const SextforceMetricsClaimedTodayChip = ({
    metricId,
    metricType,
    startDate,
    endDate,
    timezone,
}: {
    metricId: string;
    metricType: string;
    startDate: Date;
    endDate: Date;
    timezone: string;
}) => {
    const { data: claimedToday, isLoading } = useSextforceMetricsClaimsCountToday(metricId, metricType, startDate, endDate, timezone);

    return isLoading ? (
        <Skeleton width={'100%'} />
    ) : (
        <Chip
            label={claimedToday ? (claimedToday.claimedToday || 0).toLocaleString() : 'n/a'}
            color={claimedToday && claimedToday.claimedToday && claimedToday.claimedToday > 0 ? 'success' : 'default'}
            sx={{
                width: '100%',
                fontFamily: 'monospace',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                justifyContent: 'flex-end',
            }}
        />
    );
};

export default SextforceMetricsClaimedTodayChip;
