import dinero from 'dinero.js';
import { useState } from 'react';
import { Theme } from '@mui/system';
import { Button, Card, CardContent, FormControl, Grid, InputAdornment, TextField, Typography } from '@mui/material';
import { isValidEmail } from '../../utils/common';

type SharedCreditCheckoutCardProps = {
    showPaymentForm: boolean;
    setShowPaymentForm: (state: boolean) => void;
    email: string;
    setEmail: (email: string) => void;
    emailError: string;
    setEmailError: (emailError: string) => void;
    amount: number | void;
    setAmount: (amount: number | void) => void;
    amountError: string;
    setAmountError: (amountError: string) => void;
    theme: Theme;
};

const SharedCreditCheckoutCard = (props: SharedCreditCheckoutCardProps) => {
    const {
        showPaymentForm,
        setShowPaymentForm,
        email,
        setEmail,
        emailError,
        setEmailError,
        amount,
        setAmount,
        amountError,
        setAmountError,
        theme,
    } = props;
    const [amountString, setAmountString] = useState<string>('50');

    return (
        <Grid container spacing={1} flexGrow={0} alignItems="center" justifyContent="center" sx={{ marginBottom: theme.spacing(2) }}>
            <Grid item xs={12} md={6}>
                <Card variant="elevation">
                    <CardContent>
                        <Grid container spacing={1} flexGrow={0} alignItems="center" justifyContent="center">
                            <Grid item xs={12}>
                                <Typography variant="h6" sx={{ marginBottom: theme.spacing(1) }}>
                                    Buy More Credit
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid
                            container
                            spacing={1}
                            flexGrow={0}
                            alignItems="center"
                            justifyContent="center"
                            sx={{ marginBottom: theme.spacing(2) }}
                        >
                            <Grid item xs={12}>
                                <TextField
                                    id="email"
                                    fullWidth
                                    size="medium"
                                    inputProps={{ inputMode: 'email' }}
                                    disabled={showPaymentForm}
                                    value={email}
                                    autoComplete="email"
                                    onChange={e => {
                                        setEmail(e.target.value);

                                        if (isValidEmail(e.target.value)) {
                                            setEmailError('');
                                        } else {
                                            setEmailError('Not a valid email');
                                        }
                                    }}
                                    label={emailError.length > 0 ? `Email - ${emailError}` : 'Email'}
                                    error={emailError.length > 0}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <TextField
                                        id="amount"
                                        size="medium"
                                        inputProps={{ inputMode: 'text', pattern: /([0-9]+([.][0-9]*)?|[.][0-9]+)/ }}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">£</InputAdornment>,
                                            endAdornment: (
                                                <InputAdornment position="end" sx={{}}>
                                                    GBP
                                                </InputAdornment>
                                            ),
                                        }}
                                        disabled={showPaymentForm}
                                        value={amountString}
                                        onChange={e => {
                                            e.preventDefault();

                                            if (/^([\d]*[,.]?[\d]{0,2})$/.test(e.target.value)) {
                                                setAmountString(e.target.value);
                                                const parsed = parseFloat(e.target.value.replaceAll(',', '.'));

                                                if (isNaN(parsed) || parsed < 1) {
                                                    setAmount();
                                                    setAmountError('Minimum amount is £1');
                                                } else {
                                                    setAmount(parsed);
                                                    setAmountError('');
                                                }
                                            }
                                        }}
                                        label={amountError.length > 0 ? `Email - ${amountError}` : 'Amount'}
                                        error={amountError.length > 0}
                                        sx={{ color: theme.palette.primary.main, fontWeight: 'bold' }}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>

                        <Grid
                            container
                            spacing={1}
                            flexGrow={0}
                            alignItems="center"
                            justifyContent="center"
                            sx={{ marginBottom: theme.spacing(2) }}
                        >
                            <Grid item xs={12}>
                                That's roughly{' '}
                                <strong>
                                    {dinero({ amount: amount ? Math.trunc(amount * 1.2) * 100 : 0, currency: 'USD' }).toFormat()}
                                </strong>{' '}
                                and it will give you <strong>{amount ? Math.trunc(amount / 0.012).toLocaleString() : 0}</strong>{' '}
                                follows/sent messages
                            </Grid>
                        </Grid>

                        <Grid
                            container
                            spacing={1}
                            flexGrow={0}
                            alignItems="center"
                            justifyContent="center"
                            sx={{ marginBottom: theme.spacing(2) }}
                        >
                            {!showPaymentForm && (
                                <Grid item xs={12}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                        sx={{ width: '100%' }}
                                        disabled={showPaymentForm}
                                        onClick={() => {
                                            setShowPaymentForm(true);
                                        }}
                                    >
                                        Proceed to Checkout
                                    </Button>
                                </Grid>
                            )}
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
};

export default SharedCreditCheckoutCard;
