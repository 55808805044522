import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ClearIcon from '@mui/icons-material/Clear';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import TodayIcon from '@mui/icons-material/Today';
import { Grid, InputAdornment, Paper, Stack, styled, TextField, Theme, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import { green } from '@mui/material/colors';
import IconButton from '@mui/material/IconButton';
import {
    DataGridPro,
    GridColDef,
    GridColumnGroupHeaderParams,
    GridColumnGroupingModel,
    GridPaginationModel,
    GridSortModel,
} from '@mui/x-data-grid-pro';
import dinero from 'dinero.js';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { OnlyFansMetrics } from '../../../../../../hooks/useSextforceMetricsCampaignsOverview';
import { Platform, SettingsContext } from '../../../../../../store/SettingsContext';
import { d2f, getPlatformName, metricTypeName } from '../../../../../../utils/common';
import DataGridColumnHeaderCell from '../../../../../common/DataGridColumnHeaderCell';
import OverviewCard from '../../../../../common/OverviewCard';
import PriceCardWithGoal from '../../../../../common/PriceCardWithGoal';
import SextforceMetricsClaimedTodayChip from '../../SextforceMetricsClaimedTodayChip';
import SextforceMetricsClaimsCountChip from '../../SextforceMetricsClaimsCountChip';
import SextforceMetricsClaimsTodayDateSelectorDialog from '../../SextforceMetricsClaimsTodayDateSelectorDialog';
import SextforceCrossReferenceEarningsLabel from '../../SextforceMetricsCrossReferenceEarningsLabel';
import SextforceMetricsEarrnigsTotalChip from '../../SextforceMetricsEarningsTotalChip';
import SextforceMetricsRecentEarchingsChip from '../../SextforceMetricsRecentEarningsChip';
import SextforceMetricsROIAmountChip from '../../SextforceMetricsROIAmountChip';
import SextforceMetricsROIPercentageChip from '../../SextforceMetricsROIPercentageChip';
import SextforceMetricsShareDialog from '../../SextforceMetricsShareDialog';
import SextforceMetricsTrialsWelcomeMessageDialog from '../SextforceMetricsTrialsWelcomeMessageDialog';
import { OnlyFansMetricsTrialsPaginated } from './SextforceMetricsTrialsOverview';
import SextforceMetricsTrialsOverviewGridEdit from './SextforceMetricsTrialsOverviewGridEdit';
import SextfprceMetricsTrialsOverviewGridLocalMenu from './SextfprceMetricsTrialsOverviewGridLocalMenu';

const StyledDataGrid = styled(DataGridPro)(({ theme }) => ({
    '& .dataGrid-row-highlight': {
        // Create a left hand side background color bar using a gradient for the highlighted row
        backgroundColor: theme.palette.primary.light,
        backgroundImage: `linear-gradient(to right, ${theme.palette.primary.light} 15%, ${theme.palette.common.white} 15%)`,

        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center left',
    },
    '& .dataGrid-row-normal': {
        backgroundColor: theme.palette.common.white,
    },
}));

interface Props {
    subscriber: any;
    metrics: OnlyFansMetricsTrialsPaginated | undefined;
    metricsType: string;
    amountType: 'gross' | 'net';
    isLoading: boolean;
    saveSettings: (
        trialId: string,
        platform: string,
        associatedUsername: string,
        goalSpent: number,
        goalSubs: number,
        callBack: () => void,
    ) => void;
    isSavingSettings: boolean;
    reportSort: GridSortModel;
    setReportSort: (model: GridSortModel) => void;
    // filter: GridFilterModel;
    // setFilter: (model: GridFilterModel) => void;
    search: string;
    setSearch: (search: string) => void;
    paginationModel: GridPaginationModel;
    setPaginationModel: (model: GridPaginationModel) => void;
    startDate: Date;
    endDate: Date;
    claimedTodayDate: Date | null;
    setClaimedTodayDate: (date: Date | null) => void;
    timezone: string;
    setTimezone: (timezone: string) => void;
    crossReferenceSubscriberId: string;
    hoverMetricId: string;
}

const ColumnGroupHeaderCell = (params: GridColumnGroupHeaderParams) => (
    <div
        style={{
            fontWeight: 'bold',
        }}
    >
        {params.groupId}
    </div>
);

const columnGroupingModel = (metricsType: string): GridColumnGroupingModel => [
    {
        groupId: 'Metric',
        headerAlign: 'center',
        children: [
            { field: 'foreignId' },
            ...(metricsType === 'all' ? [{ field: 'type' }] : []),
            { field: 'payload.active' },
            { field: 'payload.createdAt' },
            { field: 'payload.name' },
        ],
        renderHeaderGroup: params => ColumnGroupHeaderCell(params),
    },
    {
        groupId: 'Bought From / Advertised On',
        headerAlign: 'center',
        children: [{ field: 'settings.platform' }, { field: 'associatedUsername' }],
        renderHeaderGroup: params => ColumnGroupHeaderCell(params),
    },
    {
        groupId: 'Claims',
        headerAlign: 'center',
        children: [{ field: 'claimedToday' }, { field: 'payload.counters.claimsCount' }, { field: 'settings.goalSubs' }],
        renderHeaderGroup: params => ColumnGroupHeaderCell(params),
    },
    {
        groupId: 'Earnings',
        headerAlign: 'center',
        children: [
            { field: 'earningsToday' },
            { field: 'payload.cpf' },
            { field: 'payload.earningsTotal' },
            { field: 'settings.goalSpent' },
            { field: 'roi.amount' },
            { field: 'roi.percentage' },
            { field: 'crossReferenceEarnings' },
        ],
        renderHeaderGroup: params => ColumnGroupHeaderCell(params),
    },
];

const columns = (
    searchParams: any,
    amountType: 'gross' | 'net',
    openEditDialog: (trialId: string) => void,
    openWelcomeMessageDialog: (trialId: string) => void,
    openShareDialog: (trialId: string) => void,
    openClaimedTodayDateDialog: () => void,
    platforms: { [name: string]: Platform },
    metricsType: string,
    crossReferenceSubscriberId: string,
    startDate: Date,
    endDate: Date,
    timezone: string,
    hoverMetricId: string,
    theme: Theme,
    isLargeScreen: boolean,
): GridColDef[] => {
    return [
        {
            field: 'foreignId',
            headerName: 'ID',
            width: 100,
            align: 'right',
            headerAlign: 'right',
            cellClassName: (params: any) => (params.row._id === hoverMetricId ? 'dataGrid-row-highlight' : 'dataGrid-row-normal'),
            renderCell: params => (
                <Link
                    to={`/subscribers/${searchParams.userId}/services/sextforce/metrics/trials/details/${params.row._id}?type=${
                        params.row.type
                    }${params.row.payload && !params.row.payload.active ? '&includeInactive=true' : ''}`}
                    style={{ fontFamily: 'monospace', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}
                >
                    {params.value}
                </Link>
            ),
            renderHeader: params => DataGridColumnHeaderCell(params),
        },
        ...(metricsType === 'all'
            ? [
                  {
                      field: 'type',
                      headerName: 'Type',
                      width: 80,
                      align: 'center',
                      headerAlign: 'center',
                      renderCell: params => (
                          <div style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>{metricTypeName(params.value)}</div>
                      ),
                      renderHeader: params => DataGridColumnHeaderCell(params),
                  } as GridColDef<any, any, any>,
              ]
            : []),
        {
            field: 'payload.active',
            headerName: 'Active',
            width: 90,
            align: 'center',
            headerAlign: 'center',
            renderCell: params => (params.row.payload.active === true ? 'Yes' : 'No'),
            renderHeader: params => DataGridColumnHeaderCell(params),
        },
        {
            field: 'payload.createdAt',
            headerName: 'Created At',
            width: 130,
            headerAlign: 'right',
            align: 'right',
            valueGetter: params => (params.row.payload && params.row.payload.createdAt) || null,
            renderCell: params =>
                params.row.payload &&
                params.row.payload.createdAt && (
                    <div
                        title={params.value ? moment(params.value).format('L LT') : ''}
                        style={{ fontFamily: 'monospace', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}
                    >
                        {params.value ? moment(params.value).format('L') : ''}
                    </div>
                ),
            sortComparator: (v1: Date, v2: Date) => (v1 && v2 ? (v1.valueOf() > v2.valueOf() ? 1 : -1) : 0),
            renderHeader: params => DataGridColumnHeaderCell(params),
        },
        {
            field: 'payload.name',
            headerName: 'OnlyFans Name',
            flex: 1,
            minWidth: 200,
            valueGetter: params =>
                params.row.payload && params.row.payload.name && params.row.payload.name.length > 0 ? params.row.payload.name : 'No Name',
            renderCell: params => (
                <div title={params.row.payload.name} style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
                    {params.row.payload.name || 'No Name'}
                </div>
            ),
            renderHeader: params => DataGridColumnHeaderCell(params),
        },
        {
            field: 'settings.platform',
            headerName: 'Platform',
            width: 120,
            renderCell: params => (
                <div style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>
                    {params.row.settings && params.row.settings.platform ? getPlatformName(platforms, params.row.settings.platform) : ''}
                </div>
            ),
            renderHeader: params => DataGridColumnHeaderCell(params),
        },
        {
            field: 'associatedUsername',
            headerName: '@',
            width: 150,
            renderCell: params => <div style={{ textOverflow: 'ellipsis', overflow: 'hidden' }}>{params.row.associatedUsername || ''}</div>,
            renderHeader: params => DataGridColumnHeaderCell(params),
        },
        {
            field: 'claimedToday',
            headerName: 'Today',
            width: 90,
            align: 'right',
            headerAlign: 'center',
            renderCell: params => (
                <SextforceMetricsClaimedTodayChip
                    metricId={params.row._id!}
                    metricType={params.row.type}
                    startDate={startDate}
                    endDate={endDate}
                    timezone={timezone}
                />
            ),
            renderHeader: params => (
                <>
                    <Stack direction="row" spacing={0.5} alignItems="center">
                        {DataGridColumnHeaderCell(params)}
                        <Tooltip title="Change the date to see how many fans claimed a promo on this day">
                            <span>
                                <IconButton
                                    size="small"
                                    onClick={() => {
                                        openClaimedTodayDateDialog();
                                    }}
                                >
                                    <TodayIcon />
                                </IconButton>
                            </span>
                        </Tooltip>
                    </Stack>
                </>
            ),
            sortComparator: (v1: any, v2: any) => (v1 > v2 ? 1 : -1),
            sortable: false,
        },
        {
            field: 'payload.counters.claimsCount',
            headerName: 'Total',
            width: 110,
            align: 'right',
            headerAlign: 'center',
            valueGetter: params => (params.row.payload && params.row.payload.counters && params.row.payload.counters.claimsCount) || 0,
            renderCell: params => <SextforceMetricsClaimsCountChip params={params} />,
            renderHeader: params => DataGridColumnHeaderCell(params),
            sortComparator: (v1: any, v2: any) => (v1 > v2 ? 1 : -1),
            sortable: true,
        },
        {
            field: 'settings.goalSubs',
            headerName: 'Goal',
            width: 90,
            align: 'right',
            headerAlign: 'center',
            valueGetter: params => params.row.settings && params.row.settings.goalSubs,
            renderCell: params => {
                return (
                    params.value && (
                        <div style={{ fontFamily: 'monospace', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>
                            {params.value.toLocaleString()}
                        </div>
                    )
                );
            },
            renderHeader: params => DataGridColumnHeaderCell(params),
            sortComparator: (v1: any, v2: any) => (v1 > v2 ? 1 : -1),
        },
        {
            field: 'earningsToday',
            headerName: `Last 24H  ${amountType === 'gross' ? 'Gross' : 'Net'}`,
            width: 120,
            align: 'right',
            headerAlign: 'center',
            renderCell: params => (
                <SextforceMetricsRecentEarchingsChip metricId={params.row._id} metricType={params.row.type} amountType={amountType} />
            ),
            renderHeader: params => DataGridColumnHeaderCell(params),
            sortComparator: (v1: any, v2: any) => (v1 > v2 ? 1 : -1),
            sortable: false,
        },
        {
            field: 'payload.cpf',
            headerName: `CPF Net`,
            width: 130,
            headerAlign: 'center',
            valueGetter: params =>
                params.row.payload && params.row.payload.cpf && typeof params.row.payload.cpf === 'number'
                    ? (params.row.payload.cpf as number)
                    : 0,
            renderCell: params => (
                <Grid container alignItems="center" justifyItems="flex-end" flexGrow={1}>
                    <Grid
                        item
                        xs={12}
                        sx={{
                            fontFamily: 'monospace',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            textAlign: 'right',
                        }}
                    >
                        {dinero({
                            amount: Math.trunc(params.value * 100),
                            currency: 'USD',
                        }).toFormat()}
                    </Grid>
                </Grid>
            ),
            renderHeader: params => DataGridColumnHeaderCell(params, 'Cost Per Fan (net)'),
            sortComparator: (v1: any, v2: any) => (v1 > v2 ? 1 : -1),
            sortable: true,
        },
        {
            field: 'payload.earningsTotal',
            headerName: `Total ${amountType === 'gross' ? 'Gross' : 'Net'}`,
            width: 140,
            align: 'right',
            headerAlign: 'center',
            valueGetter: params =>
                params.row.payload && params.row.payload.earningsTotal
                    ? d2f(params.row.payload.earningsTotal) * (amountType === 'gross' ? 1 : 0.8)
                    : 0,
            renderCell: params => <SextforceMetricsEarrnigsTotalChip params={params} />,
            renderHeader: params => DataGridColumnHeaderCell(params),
            sortComparator: (v1: any, v2: any) => (v1 > v2 ? 1 : -1),
            sortable: true,
        },
        {
            field: 'settings.goalSpent',
            headerName: `Goal ${amountType === 'gross' ? 'Gross' : 'Net'}`,
            width: 120,
            align: 'right',
            headerAlign: 'center',
            valueGetter: params => (params.row.settings && params.row.settings.goalSpent ? d2f(params.row.settings.goalSpent) : undefined),
            renderCell: params => {
                return (
                    params.value && (
                        <div style={{ fontFamily: 'monospace', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>
                            {dinero({
                                amount: Math.trunc(params.value * 100 * (amountType === 'gross' ? 1 : 0.8)),
                                currency: 'USD',
                            }).toFormat()}
                        </div>
                    )
                );
            },
            renderHeader: params => DataGridColumnHeaderCell(params),
            sortComparator: (v1: any, v2: any) => (v1 > v2 ? 1 : -1),
        },
        {
            field: 'roi.amount',
            headerName: `ROI $ Net`,
            width: 140,
            align: 'right',
            headerAlign: 'center',
            valueGetter: params => (params.row.roi && params.row.roi.amount ? d2f(params.row.roi.amount) : undefined),
            renderCell: params => SextforceMetricsROIAmountChip({ amount: params.value, amountType }),
            renderHeader: params => DataGridColumnHeaderCell(params, 'The earnings minus the goal/cost of the campaign'),
            sortComparator: (v1: any, v2: any) => (v1 > v2 ? 1 : -1),
        },
        {
            field: 'roi.percentage',
            headerName: `ROI % Net`,
            width: 140,
            align: 'right',
            headerAlign: 'center',
            valueGetter: params => (params.row.roi && params.row.roi.percentage ? d2f(params.row.roi.percentage) : undefined),
            renderCell: params => SextforceMetricsROIPercentageChip({ value: params.value, amountType }),
            renderHeader: params => DataGridColumnHeaderCell(params, 'The earnings minus the goal/cost of the campaign as a percentage'),
            sortComparator: (v1: any, v2: any) => (v1 > v2 ? 1 : -1),
        },
        {
            field: 'crossReferenceEarnings',
            headerName: 'Cross-Poll. Earnings',
            width: 150,
            align: 'right',
            headerAlign: 'center',
            renderCell: params =>
                params.row.type === 'trialLinkTrial' ? (
                    <SextforceCrossReferenceEarningsLabel
                        metricId={params.row._id}
                        type="trial"
                        crossReferenceSubscriberId={crossReferenceSubscriberId}
                        displayMode={amountType}
                    />
                ) : (
                    <></>
                ),
            renderHeader: params => DataGridColumnHeaderCell(params),
            sortable: false,
        },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 60,
            sortable: false,
            disableColumnMenu: true,
            headerAlign: 'center',
            align: 'center',
            renderCell: params => {
                return (
                    <SextfprceMetricsTrialsOverviewGridLocalMenu
                        params={params}
                        openEditDialog={openEditDialog}
                        openWelcomeMessageDialog={openWelcomeMessageDialog}
                        openShareDialog={openShareDialog}
                    />
                );
            },
            renderHeader: params => DataGridColumnHeaderCell(params),
        },
        // {
        //     field: 'edit',
        //     headerName: 'Edit',
        //     width: 60,
        //     sortable: false,
        //     disableColumnMenu: true,
        //     headerAlign: 'center',
        //     align: 'center',
        //     renderCell: params => {
        //         return (
        //             <IconButton onClick={() => openEditDialog(params.row._id)}>
        //                 <EditIcon />
        //             </IconButton>
        //         );
        //     },
        //     renderHeader: params => DataGridColumnHeaderCell(params),
        // },
        // {
        //     field: 'welcomeMessage',
        //     headerName: 'Welcome',
        //     width: 60,
        //     sortable: false,
        //     disableColumnMenu: true,
        //     headerAlign: 'center',
        //     align: 'center',
        //     renderCell: params => {
        //         return (
        //             <IconButton onClick={() => openWelcomeMessageDialog(params.row._id)}>
        //                 <WavingHandIcon color={params.row.welcomeMessage ? 'info' : 'disabled'} />
        //             </IconButton>
        //         );
        //     },
        //     renderHeader: params => DataGridColumnHeaderCell(params),
        // },
        // {
        //     field: 'share',
        //     headerName: 'Share',
        //     width: 60,
        //     sortable: false,
        //     disableColumnMenu: true,
        //     headerAlign: 'center',
        //     align: 'center',
        //     renderCell: params => {
        //         return (
        //             <IconButton color="secondary" onClick={() => openShareDialog(params.row._id)}>
        //                 <ShareIcon />
        //             </IconButton>
        //         );
        //     },
        //     renderHeader: params => DataGridColumnHeaderCell(params),
        // },
    ];
};

const SextforceMetricsTrialsOverviewGrid: React.FC<Props> = (props: Props) => {
    const {
        subscriber,
        metrics,
        metricsType,
        amountType,
        isLoading,
        saveSettings,
        isSavingSettings,
        reportSort,
        setReportSort,
        // filter,
        // setFilter,
        search,
        setSearch,
        paginationModel,
        setPaginationModel,
        startDate,
        endDate,
        claimedTodayDate,
        setClaimedTodayDate,
        timezone,
        setTimezone,
        crossReferenceSubscriberId,
        hoverMetricId,
    } = props;
    const theme: Theme = useTheme();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'));
    const params = useParams();
    const settings = useContext(SettingsContext);

    // Dialogs
    const [editDialogOpen, setEditDialogOpen] = useState<boolean>(false);
    const [editMetric, setEditMetric] = useState<OnlyFansMetrics | null>(null);
    const [welcomeMessageDialogOpen, setWelcomeMessageDialogOpen] = useState<boolean>(false);
    const [welcomeMessageMetric, setWelcomeMessageMetric] = useState<OnlyFansMetrics | null>(null);
    const [shareDialogOpen, setShareDialogOpen] = useState<boolean>(false);
    const [shareMetric, setShareMetric] = useState<OnlyFansMetrics | null>(null);
    const [claimedTodayDateDialogOpen, setClaimedTodayDateDialogOpen] = useState<boolean>(false);

    const openEditDialog = (trialId: string) => {
        if (!metrics || !metrics.data) {
            return;
        }

        const foundMetricData = metrics.data.find(trial => trial._id === trialId);

        if (foundMetricData) {
            setEditDialogOpen(true);
            setEditMetric(foundMetricData);
        }
    };

    const openWelcomeMessageDialog = (trialId: string) => {
        if (!metrics || !metrics.data) {
            return;
        }

        const foundMetricData = metrics.data.find(trial => trial._id === trialId);

        if (foundMetricData) {
            setWelcomeMessageDialogOpen(true);
            setWelcomeMessageMetric(foundMetricData);
        }
    };

    const openShareDialog = (trialId: string) => {
        if (!metrics || !metrics.data) {
            return;
        }

        const foundMetricData = metrics.data.find(trial => trial._id === trialId);

        if (foundMetricData) {
            setShareDialogOpen(true);
            setShareMetric(foundMetricData);
        }
    };

    const openClaimedTodayDateDialog = () => {
        setClaimedTodayDateDialogOpen(true);
    };

    const handleShareDialogClose = () => {
        setShareDialogOpen(false);
        setShareMetric(null);
    };

    const [searchFieldValue, setSearchFieldValue] = useState<string>('');
    const [earningsTotal, setEarningsTotal] = useState<number>(0);
    const [claimsTotal, setClaimsTotal] = useState<number>(0);

    // Wait 100ms before updating search after searchFieldValue has been updated
    useEffect(() => {
        const timeout = setTimeout(() => {
            setSearch(searchFieldValue);
        }, 400);

        return () => {
            clearTimeout(timeout);
        };
    }, [searchFieldValue, setSearch]);

    useEffect(() => {
        if (metrics && metrics.metadata) {
            if (metrics.metadata.earningsTotal) {
                setEarningsTotal(
                    typeof metrics.metadata.earningsTotal === 'number'
                        ? metrics.metadata.earningsTotal
                        : d2f(metrics.metadata.earningsTotal),
                );
            } else {
                setEarningsTotal(0);
            }

            if (metrics.metadata.claimsTotal) {
                setClaimsTotal(metrics.metadata.claimsTotal);
            } else {
                setClaimsTotal(0);
            }
        } else {
            setEarningsTotal(0);
            setClaimsTotal(0);
        }
    }, [metrics]);

    return (
        <>
            <Grid container flexGrow={0} justifyContent="center" spacing={1} sx={{ paddingBottom: 1 }}>
                <Grid item xs={12} md>
                    <PriceCardWithGoal
                        title={
                            <Typography variant="inherit" textAlign="right">
                                Total Earnings{' '}
                                <small style={{ color: theme.palette.text.secondary }}>
                                    {amountType === 'gross' ? '(gross)' : '(net)'}
                                </small>
                            </Typography>
                        }
                        value={dinero({ amount: Math.trunc(earningsTotal * 100 * (amountType === 'gross' ? 1 : 0.8)), currency: 'USD' })}
                        loading={isLoading}
                        icon={<AttachMoneyIcon fontSize="large" htmlColor={theme.palette.success.dark} />}
                        theme={theme}
                        sx={{
                            backgroundColor: green[100],
                        }}
                    />
                </Grid>
                <Grid item xs={12} md>
                    <OverviewCard
                        title="Total Claims"
                        value={claimsTotal}
                        loading={isLoading}
                        icon={<SubscriptionsIcon fontSize="large" htmlColor={theme.palette.success.dark} />}
                        theme={theme}
                        sx={{
                            backgroundColor: green[100],
                        }}
                    />
                </Grid>
            </Grid>

            <Paper sx={{ padding: 0, marginBottom: 1, boxShadow: 'none' }}>
                <Grid container spacing={0}>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Search"
                            value={searchFieldValue}
                            onChange={e => {
                                setSearchFieldValue(e.currentTarget.value);
                            }}
                            sx={{ margin: 0 }}
                            onKeyDown={e => {
                                if (e.key === 'Escape') {
                                    setSearchFieldValue('');
                                }
                            }}
                            InputProps={{
                                endAdornment: search.length > 0 && (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => {
                                                setSearchFieldValue('');
                                            }}
                                        >
                                            <ClearIcon color="error" />
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                </Grid>
            </Paper>

            <Paper>
                <StyledDataGrid
                    rows={(metrics && metrics.data) || []}
                    rowCount={metrics && metrics.metadata ? metrics.metadata.total : 0}
                    columns={columns(
                        params,
                        amountType,
                        openEditDialog,
                        openWelcomeMessageDialog,
                        openShareDialog,
                        openClaimedTodayDateDialog,
                        settings.platforms,
                        metricsType,
                        crossReferenceSubscriberId,
                        startDate,
                        endDate,
                        timezone,
                        hoverMetricId,
                        theme,
                        isLargeScreen,
                    )}
                    columnGroupingModel={columnGroupingModel(metricsType)}
                    experimentalFeatures={{ columnGrouping: true }}
                    getRowId={row => row._id}
                    sortingMode={'server'}
                    sortModel={reportSort}
                    sortingOrder={['desc', 'asc', null]}
                    onSortModelChange={(model: GridSortModel) => {
                        setReportSort(model);
                    }}
                    filterMode="server"
                    // filterModel={filter}
                    pagination
                    paginationMode="server"
                    pageSizeOptions={[20]}
                    paginationModel={paginationModel}
                    onPaginationModelChange={(model: GridPaginationModel) => {
                        setPaginationModel(model);
                    }}
                    autoHeight
                    disableColumnFilter
                    disableColumnMenu
                    disableRowSelectionOnClick
                    components={{
                        NoRowsOverlay: () => (
                            <Stack height="100%" alignItems="center" justifyContent="center">
                                No Results Found
                            </Stack>
                        ),
                    }}
                    initialState={{
                        pinnedColumns: {
                            // left: ['foreignId'],
                            right: [
                                'actions',
                                // 'edit', 'welcomeMessage', 'share'
                            ],
                        },
                        pagination: {
                            paginationModel: {
                                pageSize: 20,
                                page: 0,
                            },
                        },
                    }}
                    loading={isLoading}
                    sx={{
                        '& .boldHeader': {
                            fontWeight: 'bold',
                        },
                        '& .MuiDataGrid-cell': {
                            backgroundColor: theme.palette.common.white,
                        },
                        '& .MuiDataGrid-columnHeader': {
                            backgroundColor: theme.palette.common.white,
                        },
                    }}
                />
                {editMetric && editDialogOpen && (
                    <SextforceMetricsTrialsOverviewGridEdit
                        metricData={editMetric}
                        onClose={() => {
                            setEditDialogOpen(false);
                        }}
                        open={editDialogOpen}
                        saveSettings={saveSettings}
                        isSavingSettings={isSavingSettings}
                    />
                )}
                {welcomeMessageMetric && welcomeMessageDialogOpen && (
                    <SextforceMetricsTrialsWelcomeMessageDialog
                        subscriber={subscriber}
                        trial={welcomeMessageMetric}
                        open={welcomeMessageDialogOpen}
                        onClose={() => {
                            setWelcomeMessageDialogOpen(false);
                        }}
                    />
                )}
                {shareMetric && shareDialogOpen && (
                    <SextforceMetricsShareDialog
                        open={shareDialogOpen}
                        onClose={handleShareDialogClose}
                        subscriber={subscriber}
                        metricData={shareMetric}
                    />
                )}
                {claimedTodayDateDialogOpen && (
                    <SextforceMetricsClaimsTodayDateSelectorDialog
                        open={claimedTodayDateDialogOpen}
                        onClose={() => setClaimedTodayDateDialogOpen(false)}
                        date={claimedTodayDate}
                        setDate={setClaimedTodayDate}
                        timezone={timezone}
                        setTimezone={setTimezone}
                    />
                )}
            </Paper>
        </>
    );
};

export default SextforceMetricsTrialsOverviewGrid;
