import { faMoneyBillTransfer } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AddIcon from '@mui/icons-material/Add';
import { Button, Grid, Stack, styled, TableCell, TableRow, Theme, Tooltip, Typography, useTheme } from '@mui/material';
import { green, orange, red } from '@mui/material/colors';
import dinero from 'dinero.js';
import moment from 'moment';
import { useContext, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import useMetricsDailyStats from '../../hooks/useMetricsDailyStats';
import useSubscriberCategories from '../../hooks/useSubscriberCategories';
import { SettingsContext } from '../../store/SettingsContext';
import { buildURL, creditStatus, d2f, getServiceStatusColor } from '../../utils/common';
import UsernameWithAvatar from '../common/UsernameWithAvatar';
import ValueAndTrend from '../subscriber/ValueAndTrend';

const TableCellStyles = styled(TableCell)(({ theme }) => ({
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
}));

const SubscriberServicesStatus = ({ subscriber }: any) => {
    const settingsContext = useContext(SettingsContext);
    const theme: Theme = useTheme();

    return (
        <Grid container spacing={2} alignItems="center" justifyContent="center">
            {settingsContext &&
                settingsContext.services &&
                Object.keys(settingsContext.services)
                    .filter(
                        (key: string) => settingsContext.services[key].visible === true,
                        // &&
                        // isServiceActive(subscriber, key, settingsContext.services[key].alwaysActive),
                    )
                    .map((key: string) => (
                        <Grid item xs key={`${subscriber._id}_${key}`}>
                            <Grid container flexGrow={1} spacing={1} alignItems="center">
                                <Grid item xs={'auto'}>
                                    <Tooltip title={settingsContext.services[key].name.toUpperCase()} placement="top">
                                        <Link
                                            to={`${buildURL(
                                                `/subscribers/${subscriber._id}`,
                                                settingsContext.services[key].homeUrl || '',
                                            )}`}
                                            style={{ textDecoration: 'none', color: theme.palette.primary.main }}
                                        >
                                            {settingsContext.services[key].icon(
                                                getServiceStatusColor(subscriber, key, settingsContext.services[key].alwaysActive),
                                            )}
                                        </Link>
                                    </Tooltip>
                                </Grid>
                                {/* <Grid item xs={10}>
                                    <Grid container flexGrow={1} spacing={0}>
                                        <Grid item xs={12}>
                                            <span
                                                style={{
                                                    fontSize: '10pt',
                                                    color: `${
                                                        getServiceStatusColor(
                                                            subscriber,
                                                            key,
                                                            settingsContext.services[key].alwaysActive,
                                                        ) || 'primary'
                                                    }.main`,
                                                }}
                                            >
                                                {getServiceStatusName(subscriber, key, settingsContext.services[key].alwaysActive)}
                                            </span>
                                        </Grid>
                                    </Grid>
                                </Grid> */}
                            </Grid>
                        </Grid>
                    ))}
        </Grid>
    );
};

const status: any = {
    good: {
        color: green[900],
        background: green[100],
    },
    low: {
        color: orange[900],
        background: orange[100],
    },
    none: {
        color: red[900],
        background: red[100],
    },
};

type Props = {
    subscriber: any;
    setStats: (stats: any) => void;
    refetchSubscribers: () => void;
    openTransferCreditDialog: (subscriber: any) => void;
};

const DashboardMyAccountsSubscriberCardLarge = (props: Props) => {
    const { subscriber, setStats, refetchSubscribers, openTransferCreditDialog } = props;
    const sharedCredit: number = subscriber.financial && subscriber.financial.sharedCredit ? d2f(subscriber.financial.sharedCredit) : 0;
    const creditStatusName: string = creditStatus(sharedCredit);
    const timezone: string = useMemo(() => moment.tz.guess(), []);
    const startDate: Date = useMemo(() => moment().subtract(2, 'days').startOf('day').toDate(), []);
    const endDate: Date = useMemo(() => moment().endOf('day').toDate(), []);
    const { data: dailyStats, isFetching: dailyStatsLoading } = useMetricsDailyStats(startDate, endDate, timezone, subscriber._id);
    const { data: categories, isFetching: categoriesLoading } = useSubscriberCategories();

    const category = useMemo(() => {
        if (categoriesLoading || !subscriber.cognito || !subscriber.cognito.category) {
            return undefined;
        }

        return categories && categories.find(c => c._id === subscriber.cognito.category);
    }, [categories, categoriesLoading, subscriber.cognito]);

    useEffect(() => {
        if (dailyStats) {
            setStats((prevStats: any[]) => {
                return {
                    ...prevStats,
                    [subscriber._id]: dailyStats,
                };
            });
        }
    }, [dailyStats, setStats, subscriber._id]);

    return (
        <TableRow
            sx={{
                ...(category &&
                    category.color && {
                        backgroundImage: `linear-gradient(90deg, ${category.color} 0%, ${category.color} 8px, transparent 8px);`,
                    }),
            }}
        >
            <TableCellStyles>
                <UsernameWithAvatar subscriber={subscriber} category={category} refetchSubscribers={refetchSubscribers} />
            </TableCellStyles>
            <TableCellStyles width={130}>
                <Grid container spacing={1} flexGrow={0} alignItems="center" justifyContent={'center'}>
                    <Grid item xs={12} textAlign="center">
                        <Typography variant="h6" color={status[creditStatusName].color}>
                            {dinero({
                                amount: Math.trunc(sharedCredit * 100),
                                currency: 'GBP',
                            }).toFormat()}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Stack direction="row" spacing={0}>
                            <Tooltip title="Top Up Credit" placement="top">
                                <Button
                                    variant="contained"
                                    component={Link}
                                    to={`/subscribers/${subscriber._id}/account/sharedCreditCheckout`}
                                    color="success"
                                >
                                    <AddIcon />
                                </Button>
                            </Tooltip>
                            <Tooltip title="Transfer Credit" placement="top">
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    size="large"
                                    onClick={() => {
                                        openTransferCreditDialog(subscriber);
                                    }}
                                    disabled={sharedCredit === 0}
                                >
                                    <FontAwesomeIcon icon={faMoneyBillTransfer} />
                                </Button>
                            </Tooltip>
                        </Stack>
                    </Grid>
                </Grid>
            </TableCellStyles>
            <TableCellStyles width={70}>
                <ValueAndTrend
                    loading={dailyStatsLoading}
                    value={dailyStats && dailyStats[0] && dailyStats[0].counters ? dailyStats[0].counters.subscribers.active : undefined}
                    previousValue={
                        dailyStats && dailyStats.length > 1 && dailyStats[1] && dailyStats[1].counters
                            ? dailyStats[1].counters.subscribers.active
                            : undefined
                    }
                    type={'number'}
                />
            </TableCellStyles>
            <TableCellStyles width={70}>
                <ValueAndTrend
                    loading={dailyStatsLoading}
                    value={dailyStats && dailyStats[0] && dailyStats[0].counters ? dailyStats[0].counters.subscriptions.active : undefined}
                    previousValue={
                        dailyStats &&
                        dailyStats.length > 1 &&
                        dailyStats[1].counters &&
                        dailyStats[1].counters.subscriptions &&
                        dailyStats[1].counters.subscriptions.active
                            ? dailyStats[1].counters.subscriptions.active
                            : undefined
                    }
                    type={'number'}
                />
            </TableCellStyles>
            <TableCellStyles width={70}>
                <ValueAndTrend
                    loading={dailyStatsLoading}
                    value={dailyStats && dailyStats[0] && dailyStats[0].performerTop ? dailyStats[0].performerTop : undefined}
                    previousValue={
                        dailyStats && dailyStats.length > 1 && dailyStats[1].performerTop ? dailyStats[1].performerTop : undefined
                    }
                    type={'number'}
                />
            </TableCellStyles>
            <TableCellStyles width={150}>
                <ValueAndTrend
                    loading={dailyStatsLoading}
                    value={
                        dailyStats &&
                        dailyStats[0] &&
                        dailyStats[0].earnings &&
                        dailyStats[0].earnings.list &&
                        dailyStats[0].earnings.list.total &&
                        dailyStats[0].earnings.list.total.all &&
                        dailyStats[0].earnings.list.total.all.total_gross
                            ? dailyStats[0].earnings.list.total.all.total_gross
                            : undefined
                    }
                    previousValue={
                        dailyStats &&
                        dailyStats.length > 1 &&
                        dailyStats[1].earnings &&
                        dailyStats[1].earnings.list &&
                        dailyStats[1].earnings.list.total &&
                        dailyStats[1].earnings.list.total.all &&
                        dailyStats[1].earnings.list.total.all.total_gross
                            ? dailyStats[1].earnings.list.total.all.total_gross
                            : undefined
                    }
                    type={'money'}
                />
            </TableCellStyles>
            <TableCellStyles width={370}>
                <SubscriberServicesStatus subscriber={subscriber} />
            </TableCellStyles>
        </TableRow>
    );
};

export default DashboardMyAccountsSubscriberCardLarge;
