import { Container } from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';
import { Theme } from '@mui/system';
import { Outlet } from 'react-router-dom';
import DashboardMyAccounts from '../components/dashboard/DashboardMyAccounts';

function DashboardHome(props: any) {
    const theme: Theme = useTheme();

    return (
        <Container maxWidth={false} sx={{ paddingTop: theme.spacing(4), paddingBottom: theme.spacing(4), minHeight: '100%' }}>
            <Outlet />

            <DashboardMyAccounts />
        </Container>
    );
}

export default DashboardHome;
