import { Alert, Card, CardContent, Grid, Theme } from '@mui/material';
import { useDialog } from 'muibox';
import { useContext, useEffect, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { SettingsContext } from '../../../../../../store/SettingsContext';
import { UserContext } from '../../../../../../store/UserContext';
import { consecutiveRanges, d2f, handleHttpError, handleHttpErrorResponse } from '../../../../../../utils/common';
import SextforceMetricsCampaignUsers from '../../SextforceMetricsUsersList';
import SextforceMetricsTrialOverview from './SextforceMetricsTrialOverview';
import SextforceMetricsTrialSelector from './SextforceMetricsTrialSelector';
// import SextforceMetricsSuspiciousUsersList from '../../SextforceMetricsSuspiciousUsersList';
import useAxios from '../../../../../../hooks/useAxios';
import { TotalSales } from '../../campaigns/details/SextforceMetricsCampaign';
import SextforceMetricsBoughtFromSettings from '../../SextforceMetricsBoughtFromSettings';
import SextforceMetricsCrossReferenceEarnings from '../../SextforceMetricsCrossReferenceEarnings';
import SextforceMetricsEarningsByIntervalGraph from '../../SextforceMetricsEarningsByIntervalGraph';
import SextforceMetricsSalesByProductGraph from '../../SextforceMetricsSalesByProductGraph';
import SextforceMetricsUsersIdScatterGraph from '../../SextforceMetricsUsersIdScatterGraph';
import SextforceMetricsTrialCountersGraph from './SextforceMetricsTrialCountersGraph';
import SextforceMetricsTrialLink from './SextforceMetricsTrialLink';
import SextforceMetricsTrialSubscribedAtGraph from './SextforceMetricsTrialSubscribedAtGraph';

type Props = {
    subscriber: any | null;
    timezone: string;
    setTimezone: (timezone: string) => void;
    theme: Theme;
};

const SextforceMetricsTrial = (props: Props) => {
    const { subscriber, timezone, setTimezone, theme } = props;

    const userContext = useContext(UserContext);
    const settingsContext = useContext(SettingsContext);
    const params = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const dialog = useDialog();
    const axios = useAxios();
    const queryClient = useQueryClient();

    const [selectedTrial, setSelectedTrial] = useState<{ trialId: string; enabled: boolean; startDate: Date | null; endDate: Date | null }>(
        {
            trialId: params.metricId || '',
            enabled: false,
            startDate: null,
            endDate: null,
        },
    );
    const [trialUrl, setTrialUrl] = useState<string | null>(null);
    const [trialsSelectorType, setTrialsSelectorType] = useState<{ includeInactive: boolean; type: string }>({
        includeInactive: searchParams.has('includeInactive')
            ? searchParams.get('includeInactive') === 'true'
            : localStorage.getItem('sextforceMetricsCampaignsOverviewSelectorType') === 'true' || false,
        type: searchParams.has('type') ? searchParams.get('type')! : 'trialLinkTrial',
    });
    const [trialData, setTrialData] = useState<any | null>();
    const [trialTotalSales, setTrialTotalSales] = useState<TotalSales>({
        total: 0,
        totalWithoutSubscriptions: 0,
        resubscription: 0,
        resubscriptionCount: 0,
        subscription: 0,
        subscriptionCount: 0,
        tip: 0,
        tipCount: 0,
        message: 0,
        messageCount: 0,
        post: 0,
        postCount: 0,
        stream: 0,
        streamCount: 0,
    });
    const [showEarningsWithSubscriptions, setShowEarningsWithSubscriptions] = useState<boolean>(
        localStorage.getItem('showEarningsWithSubscriptions') !== null
            ? localStorage.getItem('showEarningsWithSubscriptions') === 'true'
            : true,
    );
    const [showEarningsAsGross, setShowEarningsAsGross] = useState<boolean>(
        localStorage.getItem('showEarningsAsGross') !== null ? localStorage.getItem('showEarningsAsGross') === 'true' : true,
    );

    const [suspiciousUsers, setSuspiciousUsers] = useState<any[]>([]);
    const [closeUsers, setCloseUsers] = useState<{ [key: number]: boolean }>({});
    const closeUsersThreshold = 4000; // Define how close points should be to be considered a cluster

    const [isSavingSettings, setIsSavingSettings] = useState<boolean>(false);

    // useEffect(() => {
    //     if ('metricId' in params && params.metricId) {
    //         setSelectedTrial({
    //             trialId: params.metricId,
    //             enabled: false,
    //             startDate: null,
    //             endDate: null,
    //         });
    //     }
    // }, [params]);

    // Fetch Report
    const fetchPromoCampgains = async (includeInactive: boolean, type: string): Promise<any> => {
        if (userContext.jwtToken && settingsContext.apiKey && 'userId' in params && params.userId && subscriber) {
            const query: string = `${settingsContext.routes.metrics.base}${params.userId}/trials?${new URLSearchParams({
                includeInactive: includeInactive ? 'true' : 'false',
                type,
            })}`;

            const data = await axios
                .get(query)
                .then(response => {
                    return response.data;
                })
                .catch(error => {
                    console.error(error);
                    handleHttpError(error, dialog);
                });

            return data;
        }

        return [];
    };

    const { data: trials, isLoading: trialsLoading } = useQuery(
        ['metricsTrials', trialsSelectorType, params.userId],
        () => fetchPromoCampgains(trialsSelectorType.includeInactive, trialsSelectorType.type),
        {
            refetchOnWindowFocus: false,
            // Stale time 5 minutes
            staleTime: 60 * 1000 * 5,
            enabled: subscriber ? true : false,
        },
    );

    useEffect(() => {
        if (trials && selectedTrial && selectedTrial.trialId !== '') {
            const trial = trials.find((campaign: any) => campaign._id === selectedTrial.trialId);

            setTrialData(trial || null);
        }
    }, [navigate, selectedTrial, trials]);

    // Fetch Promo Campaign Counters
    const fetchTrialCounters = async (): Promise<any> => {
        if (
            userContext.jwtToken &&
            settingsContext.apiKey &&
            'userId' in params &&
            params.userId &&
            subscriber &&
            selectedTrial &&
            selectedTrial.trialId &&
            selectedTrial.trialId !== ''
        ) {
            const query: string = `${settingsContext.routes.metrics.base}${params.userId}/trials/${selectedTrial.trialId}/counters`;

            const data = await fetch(query, {
                method: 'get',
                headers: {
                    Authorization: userContext.jwtToken,
                    apiKey: settingsContext.apiKey,
                },
            })
                .then(async response => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        handleHttpErrorResponse(response, dialog);
                    }
                })
                .catch(error => {
                    console.error(error);
                    handleHttpError(error, dialog);
                });

            return data;
        }

        return [];
    };

    const { data: trialCounters, isLoading: trialCountersLoading } = useQuery(
        [`metricsTrialCounters`, selectedTrial, params.userId, selectedTrial.trialId],
        () => fetchTrialCounters(),
        {
            refetchOnWindowFocus: false,
            // Stale time 5 minutes
            staleTime: 60 * 1000 * 5,
            enabled: subscriber && selectedTrial.trialId !== '' ? true : false,
        },
    );

    // Fetch Trial Sales
    const fetchTrialSales = async (): Promise<
        {
            _id: string;
            foreignId: number;
            userId: number;
            userName: string;
            name?: string;
            subscribedAt: string;
            total?: object;
            totalMessage?: object | number;
            totalPost?: object | number;
            totalSubscription?: object | number;
            totalTip?: object | number;
            totalResubscription?: object | number;
            totalStream?: object | number;
            totalWithoutSubscriptions?: object | number;
        }[]
    > => {
        if (
            userContext.jwtToken &&
            settingsContext.apiKey &&
            'userId' in params &&
            params.userId &&
            subscriber &&
            selectedTrial &&
            selectedTrial.trialId &&
            selectedTrial.trialId !== ''
        ) {
            const query: string = `${settingsContext.routes.metrics.base}${params.userId}/trials/${selectedTrial.trialId}/sales?${
                selectedTrial.enabled && selectedTrial.startDate && selectedTrial.endDate
                    ? new URLSearchParams({
                          startDate: (selectedTrial.startDate as Date).toISOString(),
                          endDate: (selectedTrial.endDate as Date).toISOString(),
                      })
                    : ''
            }`;

            const data = await fetch(query, {
                method: 'get',
                headers: {
                    Authorization: userContext.jwtToken,
                    apiKey: settingsContext.apiKey,
                },
            })
                .then(async response => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        handleHttpErrorResponse(response, dialog);
                    }
                })
                .catch(error => {
                    console.error(error);
                    handleHttpError(error, dialog);
                });

            return data;
        }

        return [];
    };

    const { data: trialSales, isLoading: trialSalesLoading } = useQuery(
        [`metricsTrialSales`, selectedTrial, params.userId, selectedTrial.trialId],
        () => fetchTrialSales(),
        {
            refetchOnWindowFocus: false,
            // Stale time 5 minutes
            staleTime: 60 * 1000 * 5,
            enabled: subscriber && selectedTrial.trialId !== '' ? true : false,
        },
    );

    // Find users with close User IDs
    useEffect(() => {
        if (trialSales && !trialSalesLoading) {
            const c: { [key: number]: boolean } = {};

            // Find close users
            trialSales.forEach((u, i: number) => {
                for (let j = i + 1; j < trialSales.length; j++) {
                    if (!u.total && !trialSales[j].total) {
                        if (Math.abs(u.userId - trialSales[j].userId) < closeUsersThreshold) {
                            c[u.userId] = true;
                            c[trialSales[j].userId] = true;
                        }
                    }
                }
            });

            setCloseUsers(c);
        }
    }, [trialSales, trialSalesLoading]);

    // Fetch Trial Subscribed At vs. Sales details
    const fetchTrialSubscribedAtVsSales = async (): Promise<any> => {
        if (
            userContext.jwtToken &&
            settingsContext.apiKey &&
            'userId' in params &&
            params.userId &&
            subscriber &&
            selectedTrial &&
            selectedTrial.trialId &&
            selectedTrial.trialId !== ''
        ) {
            const query: string = `${settingsContext.routes.metrics.base}${params.userId}/trials/${
                selectedTrial.trialId
            }/subscribedat?${new URLSearchParams({
                timezone,
                ...(selectedTrial.enabled &&
                    selectedTrial.startDate &&
                    selectedTrial.endDate && {
                        startDate: (selectedTrial.startDate as Date).toISOString(),
                        endDate: (selectedTrial.endDate as Date).toISOString(),
                    }),
            })}`;

            const data = await fetch(query, {
                method: 'get',
                headers: {
                    Authorization: userContext.jwtToken,
                    apiKey: settingsContext.apiKey,
                },
            })
                .then(async response => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        handleHttpErrorResponse(response, dialog);
                    }
                })
                .catch(error => {
                    console.error(error);
                    handleHttpError(error, dialog);
                });

            return data;
        }

        return [];
    };

    const { data: trialSubscribedAtVsSales, isLoading: trialSubscribedAtVsSalesLoading } = useQuery(
        [`metricsTrialIdSubscribedAtVsSales`, selectedTrial, params.userId, selectedTrial.trialId, timezone],
        () => fetchTrialSubscribedAtVsSales(),
        {
            refetchOnWindowFocus: false,
            // Stale time 5 minutes
            staleTime: 60 * 1000 * 5,
            enabled: subscriber && selectedTrial.trialId !== '' ? true : false,
        },
    );

    // Calculate total sales for the selected campaign
    useEffect(() => {
        const count = (array: any[], key: string): number => {
            return array.reduce((partialSum: number, a: any) => partialSum + (a[key] && d2f(a[key]) > 0 ? 1 : 0), 0);
        };

        const sum = (array: any[], key: string): number => {
            return array.reduce((partialSum: number, a: any) => partialSum + (a[key] ? d2f(a[key]) : 0), 0);
        };

        if (trialSales && !trialSalesLoading) {
            const newTotalSales: TotalSales = {
                total: Math.trunc(sum(trialSales, 'total') * 100),
                totalWithoutSubscriptions: Math.trunc(sum(trialSales, 'totalWithoutSubscriptions') * 100),
                resubscription: Math.trunc(sum(trialSales, 'totalResubscription') * 100),
                resubscriptionCount: count(trialSales, 'totalResubscription'),
                subscription: Math.trunc(sum(trialSales, 'totalSubscription') * 100),
                subscriptionCount: count(trialSales, 'totalSubscription'),
                message: Math.trunc(sum(trialSales, 'totalMessage') * 100),
                messageCount: count(trialSales, 'totalMessage'),
                post: Math.trunc(sum(trialSales, 'totalPost') * 100),
                postCount: count(trialSales, 'totalPost'),
                stream: Math.trunc(sum(trialSales, 'totalStream') * 100),
                streamCount: count(trialSales, 'totalStream'),
                tip: Math.trunc(sum(trialSales, 'totalTip') * 100),
                tipCount: count(trialSales, 'totalTip'),
            };

            setTrialTotalSales(newTotalSales);
        } else {
            setTrialTotalSales({
                total: 0,
                totalWithoutSubscriptions: 0,
                resubscription: 0,
                resubscriptionCount: 0,
                subscription: 0,
                subscriptionCount: 0,
                message: 0,
                messageCount: 0,
                post: 0,
                postCount: 0,
                stream: 0,
                streamCount: 0,
                tip: 0,
                tipCount: 0,
            });
        }
    }, [trialSales, trialSalesLoading]);

    // Work out if there are any suspicious subscribers in this campaign
    useEffect(() => {
        if (trialSales && !trialSalesLoading) {
            // Create an initial list of all those users who were found to be suspicious by the AI and have the suspicious flag set to true
            const suspiciousCollection: any[] = trialSales.filter((user: any) => user.suspicious && d2f(user.total) === 0);

            // Create a list of user IDs of those who haven't spent yet
            const freeloaders: number[] = [];

            trialSales.forEach((user: any) => {
                if (!user.total || d2f(user.total) === 0) {
                    freeloaders.push(user.userId);
                }
            });

            // Detect consecutive user IDs
            const suspiciousUserIds = consecutiveRanges(freeloaders);

            // Suspicious users found
            suspiciousUserIds.forEach(user => {
                for (let i = user.first; i <= user.last; i += 1) {
                    if (!suspiciousCollection.find((item: any) => item.userId === i)) {
                        suspiciousCollection.push(trialSales.find((item: any) => item.userId === i));
                    }
                }
            });

            // Search trialSales for any userName starting with 'u' with only digits in the middle and ending with the letter 'l'
            const suspiciousUserNames = trialSales.filter((user: any) => {
                if (user.userName && user.userName.length > 2) {
                    const userName = user.userName.toLowerCase();

                    if (userName.startsWith('u') && userName.substring(1, userName.length - 1).match(/^\d+$/) && userName.endsWith('l')) {
                        return user;
                    }

                    return undefined;
                }

                return undefined;
            });

            // Add any suspecial users that were not already added to the collection
            suspiciousUserNames.forEach((user: any) => {
                if (!suspiciousCollection.find((item: any) => item.userId === user.userId)) {
                    suspiciousCollection.push(user);
                }
            });

            // Find users who don't have a userName
            const noUserName = trialSales.filter((user: any) => {
                if (!user.userName && d2f(user.total) === 0) {
                    return user;
                }

                return undefined;
            });

            // Add any suspecial users that were not already added to the collection
            noUserName.forEach((user: any) => {
                if (!suspiciousCollection.find((item: any) => item.userId === user.userId)) {
                    suspiciousCollection.push(user);
                }
            });

            setSuspiciousUsers(suspiciousCollection);
        } else {
            setSuspiciousUsers([]);
        }
    }, [trialSales, trialSalesLoading]);

    const saveSettings = (platform: string, associatedUsername: string, goalSpent: number, goalSubs: number) => {
        const doUpdate = async () => {
            if (
                userContext.jwtToken &&
                settingsContext.apiKey &&
                'userId' in params &&
                params.userId &&
                subscriber &&
                selectedTrial &&
                selectedTrial.trialId &&
                selectedTrial.trialId !== ''
            ) {
                const query: string = `${settingsContext.routes.metrics.base}${params.userId}/trials/${selectedTrial.trialId}`;

                setIsSavingSettings(true);

                const body: any = {
                    ...(platform !== '' && { platform }),
                    ...(associatedUsername !== '' && { associatedUsername }),
                    ...(goalSpent !== 0 && { goalSpent }),
                    ...(goalSubs !== 0 && { goalSubs }),
                };

                // Update campaign settings
                const updatedMetric = await fetch(query, {
                    method: 'put',
                    headers: {
                        Authorization: userContext.jwtToken,
                        apiKey: settingsContext.apiKey,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(body),
                })
                    .then(async response => {
                        if (response.ok) {
                            return response.json();
                        } else {
                            handleHttpErrorResponse(response, dialog);
                        }
                    })
                    .catch(error => {
                        console.error(error);
                        setIsSavingSettings(false);
                        handleHttpError(error, dialog);
                    });

                const newTrials = [...trials];
                const updatedTrialIndex: number = newTrials.findIndex((trial: any) => trial._id === updatedMetric._id);

                if (updatedTrialIndex) {
                    if (updatedMetric.associatedUsername) {
                        newTrials[updatedTrialIndex].associatedUsername = updatedMetric.associatedUsername;
                    }

                    if (updatedMetric.settings) {
                        newTrials[updatedTrialIndex].settings = updatedMetric.settings;
                    }
                }

                queryClient.setQueryData(['metricsTrials', selectedTrial], newTrials);

                setTrialData(updatedMetric);

                setIsSavingSettings(false);
            }
        };

        doUpdate();
    };

    return (
        <>
            <Card sx={{ width: '100%', marginBottom: theme.spacing(1) }}>
                <CardContent>
                    <Grid container flexGrow={1} spacing={1} alignItems="center">
                        <Grid item xs={12} sm>
                            <SextforceMetricsTrialSelector
                                trialsLoading={trialsLoading}
                                trialsSelectorType={trialsSelectorType}
                                setTrialsSelectorType={setTrialsSelectorType}
                                trials={trials}
                                trialId={selectedTrial}
                                setTrialId={setSelectedTrial}
                                setTrialUrl={setTrialUrl}
                                timezone={timezone}
                                setTimezone={setTimezone}
                                theme={theme}
                            />
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>

            <SextforceMetricsTrialOverview
                subscriber={subscriber}
                trialData={trialData}
                trialCounters={trialCounters}
                trialCountersLoading={trialCountersLoading}
                trialTotalSales={trialTotalSales}
                trialSales={trialSales}
                trialSalesLoading={trialSalesLoading}
                showEarningsWithSubscriptions={showEarningsWithSubscriptions}
                setShowEarningsWithSubscriptions={setShowEarningsWithSubscriptions}
                showEarningsAsGross={showEarningsAsGross}
                setShowEarningsAsGross={setShowEarningsAsGross}
                suspiciousUsersCount={suspiciousUsers.length}
                theme={theme}
            />

            <SextforceMetricsTrialLink trialUrl={trialUrl} theme={theme} />

            {trialData && trialData.type === 'trialLinkTrial' && (
                <SextforceMetricsCrossReferenceEarnings
                    metric={trialData}
                    metricType="trial"
                    showEarningsWithSubscriptions={showEarningsWithSubscriptions}
                    showEarningsAsGross={showEarningsAsGross}
                    theme={theme}
                />
            )}

            <Grid container spacing={1} flexGrow={0} justifyContent="center" sx={{ marginBottom: 1 }}>
                <Grid item xs={12}>
                    <Alert variant="filled" severity="warning">
                        Please note that only trials that started after you started using Sextforce are guaranteed to give an accurate total
                        results. Because trials can be consumed by existing fans, there could be a situation that the user that consumed a
                        trial is long gone so their information caanot be retrieved in retrospect.
                        <br />
                        <br />
                        Similarly, because a user can already exist on the account when they consume a trial link, the total revenue made
                        from the trial, and the fan's individual amount spent is shown below, though completely accurate, it could also
                        overlap with another trial link if that same user consumed another link.
                        <br />
                        <br />
                        All earnings are calculated from when the trial/promo started and onwards. Any previous sales to the users who
                        consumed the trial/promo are ignored.
                    </Alert>
                </Grid>
            </Grid>

            <SextforceMetricsBoughtFromSettings
                subscriber={subscriber}
                metricData={trialData}
                saveSettings={saveSettings}
                isSavingSettings={isSavingSettings}
                theme={theme}
            />

            <SextforceMetricsTrialCountersGraph
                trialCounters={trialCounters}
                trialCountersLoading={trialCountersLoading}
                timezone={timezone}
                theme={theme}
            />

            <SextforceMetricsTrialSubscribedAtGraph
                metricId={trialData && trialData._id ? trialData._id : ''}
                trialSubscribedAtVsSales={trialSubscribedAtVsSales}
                trialSubscribedAtVsSalesLoading={trialSubscribedAtVsSalesLoading}
                showEarnings={true}
                showEarningsAsGross={showEarningsAsGross}
                showEarningsWithSubscriptions={showEarningsWithSubscriptions}
                timezone={timezone}
                theme={theme}
            />

            <SextforceMetricsEarningsByIntervalGraph
                subscriber={subscriber}
                metricId={trialData && trialData._id ? trialData._id : ''}
                metricType={trialData && trialData.type ? trialData.type : undefined}
                showEarningsAsGross={showEarningsAsGross}
                showEarningsWithSubscriptions={showEarningsWithSubscriptions}
                timezone={timezone}
                theme={theme}
            />

            <SextforceMetricsSalesByProductGraph
                totalSales={trialTotalSales}
                totalSalesLoading={trialSalesLoading}
                showEarningsAsGross={showEarningsAsGross}
                theme={theme}
            />

            <SextforceMetricsUsersIdScatterGraph
                users={
                    trialSales
                        ? trialSales.map((user: any) => {
                              return {
                                  id: user.userId,
                                  date: user.subscribedAt ? new Date(user.subscribedAt) : undefined,
                                  userName: user.userName,
                              };
                          })
                        : []
                }
                closeUsers={closeUsers}
            />

            <SextforceMetricsCampaignUsers
                metricId={selectedTrial.trialId}
                promoCampaignTotalSales={trialSales}
                promoCampaignTotalSalesLoading={trialSalesLoading}
                showEarningsAsGross={showEarningsAsGross}
                showEarningsWithSubscriptions={showEarningsWithSubscriptions}
                metricStartDate={trialData && trialData.payload && trialData.payload.createdAt}
                metricType={trialData && trialData.type ? trialData.type : undefined}
                closeUsers={closeUsers}
                timezone={timezone}
                theme={theme}
            />

            {/* <SextforceMetricsSuspiciousUsersList
                metricId={selectedTrial.trialId}
                suspiciousUsers={suspiciousUsers}
                suspiciousUsersLoading={trialCountersLoading}
                timezone={timezone}
                theme={theme}
            /> */}
        </>
    );
};

export default SextforceMetricsTrial;
