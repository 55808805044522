import { TextField } from '@mui/material';
import { useContext, useState } from 'react';
import { SettingsContext } from '../../../../store/SettingsContext';

const FormAutoReplyMessageEditor = (props: {
    message: string;
    setMessage: (message: string) => void;
    messageHasRestrictedWords: boolean;
    setMessageHasRestrictedWords: (messageHasRestrictedWords: boolean) => void;
}) => {
    const { message, setMessage, messageHasRestrictedWords, setMessageHasRestrictedWords } = props;
    const settingsContext = useContext(SettingsContext);
    const [messageFoundRestrictedWords, setMessageFoundRestrictedWords] = useState<string[]>([]);

    const checkForRestrictedWords = (message: string) => {
        // Check the message doesn't contain any OnlyFans restricted words
        if (message && settingsContext.services.messageUsers.restrictedWords) {
            const lowerCaseMessage = message.toLowerCase();
            const restrictedWordsFound: string[] = [];

            // Check if lowerCaseMessage contains any restricted words separated by spaces or new lines or slashes or commas or dots
            const wordsInMessage: string[] = lowerCaseMessage.split(/[\s\n/,.]+/);
            wordsInMessage.forEach((word: string) => {
                if (settingsContext.services.messageUsers.restrictedWords.includes(word)) {
                    restrictedWordsFound.push(word);
                }
            });

            setMessageHasRestrictedWords(restrictedWordsFound.length > 0 ? true : false);
            setMessageFoundRestrictedWords(restrictedWordsFound);
        } else {
            setMessageHasRestrictedWords(false);
            setMessageFoundRestrictedWords([]);
        }
    };

    return (
        <TextField
            value={message}
            onChange={e => {
                setMessage(e.target.value);
                checkForRestrictedWords(e.target.value);
            }}
            fullWidth
            multiline
            rows={4}
            variant="outlined"
            error={messageHasRestrictedWords || message.trim() === '' ? true : false}
            helperText={messageHasRestrictedWords ? 'Message contains restricted words: ' + messageFoundRestrictedWords.join(', ') : ''}
            inputProps={{
                style: {
                    fontFamily: 'Roboto,sans-serif',
                    fontSize: '16px/1.3334',
                },
            }}
            sx={{
                backgroundColor: 'rgba(0,175,240,.12)',
                marginLeft: 'auto',
                overflowWrap: 'break-word',
                wordBreak: 'break-word',
                hyphens: 'auto',
                textAlign: 'start',
                lineHeight: '20px',
                boxSizing: 'border-box',
            }}
        />
    );
};

export default FormAutoReplyMessageEditor;
