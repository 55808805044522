import { Card, CardContent, Grid, Skeleton, Theme } from '@mui/material';
import { useDialog } from 'muibox';
import { useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useParams, useSearchParams } from 'react-router-dom';
import SextforceMetricsCampaignCountersGraph from './SextforceMetricsCampaignCountersGraph';
import SextforceMetricsCampaignOverview from './SextforceMetricsCampaignOverview';
import SextforceMetricsCampaignSelector from './SextforceMetricsCampaignSelector';
import SextforceMetricsCampaignSubscribedAtGraph from './SextforceMetricsCampaignSubscribedAtGraph';
// import SextforceMetricsSuspiciousUsersList from '../../SextforceMetricsSuspiciousUsersList';
import { SettingsContext } from '../../../../../../store/SettingsContext';
import { UserContext } from '../../../../../../store/UserContext';
import { consecutiveRanges, d2f, handleHttpError, handleHttpErrorResponse } from '../../../../../../utils/common';
import SextforceMetricsCrossReferenceEarnings from '../../SextforceMetricsCrossReferenceEarnings';
import SextforceMetricsEarningsByIntervalGraph from '../../SextforceMetricsEarningsByIntervalGraph';
import SextforceMetricsSalesByProductGraph from '../../SextforceMetricsSalesByProductGraph';
import SextforceMetricsUsersIdScatterGraph from '../../SextforceMetricsUsersIdScatterGraph';
import SextforceMetricsCampaignUsers from '../../SextforceMetricsUsersList';

export interface TotalSales {
    total: number;
    totalWithoutSubscriptions: number;
    resubscription: number;
    resubscriptionCount: number;
    subscription: number;
    subscriptionCount: number;
    tip: number;
    tipCount: number;
    message: number;
    messageCount: number;
    post: number;
    postCount: number;
    stream: number;
    streamCount: number;
}

type Props = {
    subscriber: any | null;
    timezone: string;
    theme: Theme;
};

const SextforceMetricsCampaign = (props: Props) => {
    const { subscriber, timezone, theme } = props;

    const userContext = useContext(UserContext);
    const settingsContext = useContext(SettingsContext);
    const params = useParams();
    const [searchParams] = useSearchParams();
    const dialog = useDialog();
    // const queryClient = useQueryClient();

    const [promoCampaignId, setPromoCampaignId] = useState<string>(params.metricId || '');
    const [promoCampaignsShowInactive, setPromoCampaignsShowInactive] = useState<boolean>(
        searchParams.has('includeInactive')
            ? searchParams.get('includeInactive') === 'true'
            : localStorage.getItem('sextforceMetricsCampaignsOverviewSelectorType') === 'true' || false,
    );
    const [promoCampaignData, setPromoCampaignData] = useState<any | null>();
    const [promoCampaignTotalSales, setPromoCampaignTotalSales] = useState<TotalSales>({
        total: 0,
        totalWithoutSubscriptions: 0,
        resubscription: 0,
        resubscriptionCount: 0,
        subscription: 0,
        subscriptionCount: 0,
        tip: 0,
        tipCount: 0,
        message: 0,
        messageCount: 0,
        post: 0,
        postCount: 0,
        stream: 0,
        streamCount: 0,
    });
    const [showEarningsWithSubscriptions, setShowEarningsWithSubscriptions] = useState<boolean>(
        localStorage.getItem('showEarningsWithSubscriptions') !== null
            ? localStorage.getItem('showEarningsWithSubscriptions') === 'true'
            : true,
    );
    const [showEarningsAsGross, setShowEarningsAsGross] = useState<boolean>(
        localStorage.getItem('showEarningsAsGross') !== null ? localStorage.getItem('showEarningsAsGross') === 'true' : true,
    );
    const [suspiciousUsers, setSuspiciousUsers] = useState<any[]>([]);
    const [closeUsers, setCloseUsers] = useState<{ [key: number]: boolean }>({});
    const closeUsersThreshold = 4000; // Define how close points should be to be considered a cluster

    // const [isSavingSettings, setIsSavingSettings] = useState<boolean>(false);

    // Fetch Report
    const fetchPromoCampgains = async (status: boolean): Promise<any> => {
        if (userContext.jwtToken && settingsContext.apiKey && 'userId' in params && params.userId && subscriber) {
            const query: string = `${settingsContext.routes.metrics.base}${params.userId}/promocampaigns?${
                typeof status === 'boolean'
                    ? new URLSearchParams({
                          status: status ? 'true' : 'false',
                      })
                    : ''
            }`;

            const data = await fetch(query, {
                method: 'get',
                headers: {
                    Authorization: userContext.jwtToken,
                    apiKey: settingsContext.apiKey,
                },
            })
                .then(async response => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        handleHttpErrorResponse(response, dialog);
                    }
                })
                .catch(error => {
                    console.error(error);
                    handleHttpError(error, dialog);
                });

            return data;
        }

        return [];
    };

    const { data: promoCampaigns, isLoading: promoCampaignsLoading } = useQuery(
        ['metricsPromoCampaings', promoCampaignsShowInactive, params.userId],
        () => fetchPromoCampgains(promoCampaignsShowInactive),
        {
            refetchOnWindowFocus: false,
            // Stale time 1 hour
            staleTime: 60 * 1000 * 60,
            enabled: subscriber ? true : false,
        },
    );

    useEffect(() => {
        if (promoCampaigns && promoCampaignId !== '') {
            const selectedCampaign = promoCampaigns.find((campaign: any) => campaign._id === promoCampaignId);

            setPromoCampaignData(selectedCampaign || null);
        }
    }, [promoCampaignId, promoCampaigns]);

    // Fetch Promo Campaign Counters
    const fetchPromoCampgainCounters = async (): Promise<any> => {
        if (userContext.jwtToken && settingsContext.apiKey && 'userId' in params && params.userId && subscriber) {
            const query: string = `${settingsContext.routes.metrics.base}${params.userId}/promocampaigns/${promoCampaignId}/counters`;

            const data = await fetch(query, {
                method: 'get',
                headers: {
                    Authorization: userContext.jwtToken,
                    apiKey: settingsContext.apiKey,
                },
            })
                .then(async response => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        handleHttpErrorResponse(response, dialog);
                    }
                })
                .catch(error => {
                    console.error(error);
                    handleHttpError(error, dialog);
                });

            return data;
        }

        return [];
    };

    const { data: promoCampaignCounters, isLoading: promoCampaignCountersLoading } = useQuery(
        [`metricsPromoCampaingCounters_${promoCampaignId}`, promoCampaignId, params.userId],
        () => fetchPromoCampgainCounters(),
        {
            refetchOnWindowFocus: false,
            // Stale time 1 hour
            staleTime: 60 * 1000 * 60,
            enabled: subscriber && promoCampaignId !== '' ? true : false,
        },
    );

    // Fetch Promo Campaign Sales
    const fetchPromoCampgainSales = async (): Promise<
        {
            _id: string;
            foreignId: number;
            userId: number;
            userName: string;
            name?: string;
            subscribedAt: string;
            total?: object;
            totalMessage?: object | number;
            totalPost?: object | number;
            totalSubscription?: object | number;
            totalTip?: object | number;
            totalResubscription?: object | number;
            totalStream?: object | number;
            totalWithoutSubscriptions?: object | number;
        }[]
    > => {
        if (userContext.jwtToken && settingsContext.apiKey && 'userId' in params && params.userId && subscriber) {
            const query: string = `${settingsContext.routes.metrics.base}${params.userId}/promocampaigns/${promoCampaignId}/sales`;

            const data = await fetch(query, {
                method: 'get',
                headers: {
                    Authorization: userContext.jwtToken,
                    apiKey: settingsContext.apiKey,
                },
            })
                .then(async response => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        handleHttpErrorResponse(response, dialog);
                    }
                })
                .catch(error => {
                    console.error(error);
                    handleHttpError(error, dialog);
                });

            return data;
        }

        return [];
    };

    const { data: promoCampaignSales, isLoading: promoCampaignSalesLoading } = useQuery(
        [`metricsPromoCampaingSales_${promoCampaignId}`, promoCampaignId, params.userId],
        () => fetchPromoCampgainSales(),
        {
            refetchOnWindowFocus: false,
            // Stale time 1 hour
            staleTime: 60 * 1000 * 60,
            enabled: subscriber && promoCampaignId !== '' ? true : false,
        },
    );

    // Find users with close User IDs
    useEffect(() => {
        if (promoCampaignSales && !promoCampaignSalesLoading) {
            const c: { [key: number]: boolean } = {};

            // Find close users
            promoCampaignSales.forEach((u, i: number) => {
                for (let j = i + 1; j < promoCampaignSales.length; j++) {
                    if (!u.total && !promoCampaignSales[j].total) {
                        if (Math.abs(u.userId - promoCampaignSales[j].userId) < closeUsersThreshold) {
                            c[u.userId] = true;
                            c[promoCampaignSales[j].userId] = true;
                        }
                    }
                }
            });

            setCloseUsers(c);
        }
    }, [promoCampaignSales, promoCampaignSalesLoading]);

    // Fetch Promo Campaign Subscribed At vs. Sales details
    const fetchPromoCampgainSubscribedAtVsSales = async (): Promise<any> => {
        if (userContext.jwtToken && settingsContext.apiKey && 'userId' in params && params.userId && subscriber) {
            const query: string = `${settingsContext.routes.metrics.base}${params.userId}/promocampaigns/${promoCampaignId}/subscribedat?timezone=${timezone}`;

            const data = await fetch(query, {
                method: 'get',
                headers: {
                    Authorization: userContext.jwtToken,
                    apiKey: settingsContext.apiKey,
                },
            })
                .then(async response => {
                    if (response.ok) {
                        return response.json();
                    } else {
                        handleHttpErrorResponse(response, dialog);
                    }
                })
                .catch(error => {
                    console.error(error);
                    handleHttpError(error, dialog);
                });

            return data;
        }

        return [];
    };

    const { data: promoCampaignSubscribedAtVsSales, isLoading: promoCampaignSubscribedAtVsSalesLoading } = useQuery(
        [`metricsPromoCampaingSubscribedAtVsSales_${promoCampaignId}`, promoCampaignId, params.userId, timezone],
        () => fetchPromoCampgainSubscribedAtVsSales(),
        {
            refetchOnWindowFocus: false,
            // Stale time 1 hour
            staleTime: 60 * 1000 * 60,
            enabled: subscriber && promoCampaignId !== '' ? true : false,
        },
    );

    // Calculate total sales for the selected campaign
    useEffect(() => {
        const count = (array: any[], key: string): number => {
            return array.reduce((partialSum: number, a: any) => partialSum + (a[key] && d2f(a[key]) > 0 ? 1 : 0), 0);
        };

        const sum = (array: any[], key: string): number => {
            return array.reduce((partialSum: number, a: any) => partialSum + (a[key] ? d2f(a[key]) : 0), 0);
        };

        if (promoCampaignSales && !promoCampaignSalesLoading) {
            const newTotalSales: TotalSales = {
                total: Math.trunc(sum(promoCampaignSales, 'total') * 100),
                totalWithoutSubscriptions: Math.trunc(sum(promoCampaignSales, 'totalWithoutSubscriptions') * 100),
                resubscription: Math.trunc(sum(promoCampaignSales, 'totalResubscription') * 100),
                resubscriptionCount: count(promoCampaignSales, 'totalResubscription'),
                subscription: Math.trunc(sum(promoCampaignSales, 'totalSubscription') * 100),
                subscriptionCount: count(promoCampaignSales, 'totalSubscription'),
                message: Math.trunc(sum(promoCampaignSales, 'totalMessage') * 100),
                messageCount: count(promoCampaignSales, 'totalMessage'),
                post: Math.trunc(sum(promoCampaignSales, 'totalPost') * 100),
                postCount: count(promoCampaignSales, 'totalPost'),
                stream: Math.trunc(sum(promoCampaignSales, 'totalStream') * 100),
                streamCount: count(promoCampaignSales, 'totalStream'),
                tip: Math.trunc(sum(promoCampaignSales, 'totalTip') * 100),
                tipCount: count(promoCampaignSales, 'totalTip'),
            };

            setPromoCampaignTotalSales(newTotalSales);
        } else {
            setPromoCampaignTotalSales({
                total: 0,
                totalWithoutSubscriptions: 0,
                resubscription: 0,
                resubscriptionCount: 0,
                subscription: 0,
                subscriptionCount: 0,
                message: 0,
                messageCount: 0,
                post: 0,
                postCount: 0,
                stream: 0,
                streamCount: 0,
                tip: 0,
                tipCount: 0,
            });
        }
    }, [promoCampaignSales, promoCampaignSalesLoading]);

    // Work out if there are any suspicious subscribers in this campaign
    useEffect(() => {
        if (promoCampaignSales && !promoCampaignSalesLoading) {
            // Create an initial list of all those users who were found to be suspicious by the AI and have the suspicious flag set to true
            const suspiciousCollection: any[] = promoCampaignSales.filter((user: any) => user.suspicious && d2f(user.total) === 0);

            // Create a list of user IDs of those who haven't spent yet
            const freeloaders: number[] = [];

            promoCampaignSales.forEach((user: any) => {
                if (!user.total || d2f(user.total) === 0) {
                    freeloaders.push(user.userId);
                }
            });

            // Detect consecutive user IDs
            const suspiciousUserIds = consecutiveRanges(freeloaders);

            // Suspicious users found
            suspiciousUserIds.forEach(user => {
                for (let i = user.first; i <= user.last; i += 1) {
                    if (!suspiciousCollection.find((item: any) => item.userId === i)) {
                        suspiciousCollection.push(promoCampaignSales.find((item: any) => item.userId === i));
                    }
                }
            });

            // Search trialSales for any userName starting with 'u' with only digits in the middle and ending with the letter 'l'
            const suspiciousUserNames = promoCampaignSales.filter((user: any) => {
                if (user.userName && user.userName.length > 2) {
                    const userName = user.userName.toLowerCase();

                    if (userName.startsWith('u') && userName.substring(1, userName.length - 1).match(/^\d+$/) && userName.endsWith('l')) {
                        return user;
                    }

                    return undefined;
                }

                return undefined;
            });

            // Add any suspecial users that were not already added to the collection
            suspiciousUserNames.forEach((user: any) => {
                if (!suspiciousCollection.find((item: any) => item.userId === user.userId)) {
                    suspiciousCollection.push(user);
                }
            });

            // Find users who don't have a userName
            const noUserName = promoCampaignSales.filter((user: any) => {
                if (!user.userName && d2f(user.total) === 0) {
                    return user;
                }

                return undefined;
            });

            // Add any suspecial users that were not already added to the collection
            noUserName.forEach((user: any) => {
                if (!suspiciousCollection.find((item: any) => item.userId === user.userId)) {
                    suspiciousCollection.push(user);
                }
            });

            setSuspiciousUsers(suspiciousCollection);
        } else {
            setSuspiciousUsers([]);
        }
    }, [promoCampaignSales, promoCampaignSalesLoading]);

    // const saveSettings = (platform: string, associatedUsername: string, goalSpent: number, goalSubs: number) => {
    //     const doUpdate = async () => {
    //         if (userContext.jwtToken && settingsContext.apiKey && 'userId' in params && params.userId && subscriber) {
    //             const query: string = `${settingsContext.routes.metrics.base}${params.userId}/promocampaigns/${promoCampaignId}`;

    //             setIsSavingSettings(true);

    //             const body: any = {
    //                 ...(platform !== '' && { platform }),
    //                 ...(associatedUsername !== '' && { associatedUsername }),
    //                 ...(goalSpent !== 0 && { goalSpent }),
    //                 ...(goalSubs !== 0 && { goalSubs }),
    //             };

    //             // Update campaign settings
    //             const updatedMetric = await fetch(query, {
    //                 method: 'put',
    //                 headers: {
    //                     Authorization: userContext.jwtToken,
    //                     apiKey: settingsContext.apiKey,
    //                     'Content-Type': 'application/json',
    //                 },
    //                 body: JSON.stringify(body),
    //             })
    //                 .then(async response => {
    //                     if (response.ok) {
    //                         return response.json();
    //                     } else {
    //                         handleHttpErrorResponse(response, dialog);
    //                     }
    //                 })
    //                 .catch(error => {
    //                     console.error(error);
    //                     setIsSavingSettings(false);
    //                     handleHttpError(error, dialog);
    //                 });

    //             const newPromoCampaigns = [...promoCampaigns];
    //             const updatedCampaignIndex: number = newPromoCampaigns.findIndex(
    //                 (promoCampaign: any) => promoCampaign._id === updatedMetric._id,
    //             );

    //             if (updatedCampaignIndex) {
    //                 if (updatedMetric.associatedUsername) {
    //                     newPromoCampaigns[updatedCampaignIndex].associatedUsername = updatedMetric.associatedUsername;
    //                 }

    //                 if (updatedMetric.settings) {
    //                     newPromoCampaigns[updatedCampaignIndex].settings = updatedMetric.settings;
    //                 }
    //             }

    //             queryClient.setQueryData(['metricsPromoCampaings', promoCampaignId], newPromoCampaigns);

    //             setPromoCampaignData(updatedMetric);

    //             setIsSavingSettings(false);
    //         }
    //     };

    //     doUpdate();
    // };

    return (
        <>
            <Card sx={{ width: '100%', marginBottom: 1 }}>
                <CardContent style={{ padding: theme.spacing(1) }}>
                    <Grid container flexGrow={1} spacing={1} alignItems="center">
                        <Grid item xs={12} sm>
                            {promoCampaignsLoading ? (
                                <Skeleton />
                            ) : (
                                <SextforceMetricsCampaignSelector
                                    promoCampaigns={promoCampaigns}
                                    promoCampaignsLoading={promoCampaignsLoading}
                                    promoCampaignId={promoCampaignId}
                                    setPromoCampaignId={setPromoCampaignId}
                                    showInactive={promoCampaignsShowInactive}
                                    setShowInactive={setPromoCampaignsShowInactive}
                                    timezone={timezone}
                                    theme={theme}
                                />
                            )}
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>

            <SextforceMetricsCampaignOverview
                subscriber={subscriber}
                promoCampaign={promoCampaignData}
                promoCampaignCounters={promoCampaignCounters}
                promoCampaignCountersLoading={promoCampaignCountersLoading}
                promoCampaignTotalSales={promoCampaignTotalSales}
                promoCampaignSales={promoCampaignSales}
                promoCampaignSalesLoading={promoCampaignSalesLoading}
                showEarningsWithSubscriptions={showEarningsWithSubscriptions}
                setShowEarningsWithSubscriptions={setShowEarningsWithSubscriptions}
                showEarningsAsGross={showEarningsAsGross}
                setShowEarningsAsGross={setShowEarningsAsGross}
                suspiciousUsersCount={suspiciousUsers.length}
                goalSubs={promoCampaignData && promoCampaignData.settings ? promoCampaignData.settings.goalSubs : undefined}
                goalEarnings={promoCampaignData && promoCampaignData.settings ? promoCampaignData.settings.goalSpent : undefined}
                theme={theme}
            />

            <SextforceMetricsCrossReferenceEarnings
                metric={promoCampaignData}
                metricType="campaign"
                showEarningsWithSubscriptions={showEarningsWithSubscriptions}
                showEarningsAsGross={showEarningsAsGross}
                theme={theme}
            />

            {/* <SextforceMetricsBoughtFromSettings
                subscriber={subscriber}
                metricData={promoCampaignData}
                saveSettings={saveSettings}
                isSavingSettings={isSavingSettings}
                theme={theme}
            /> */}

            <SextforceMetricsCampaignCountersGraph
                promoCampaignCounters={promoCampaignCounters}
                promoCampaignCountersLoading={promoCampaignCountersLoading}
                timezone={timezone}
                theme={theme}
            />

            <SextforceMetricsCampaignSubscribedAtGraph
                metricId={promoCampaignId}
                promoCampaignSubscribedAtVsSales={promoCampaignSubscribedAtVsSales}
                promoCampaignSubscribedAtVsSalesLoading={promoCampaignSubscribedAtVsSalesLoading}
                showEarnings={true}
                showEarningsAsGross={showEarningsAsGross}
                showEarningsWithSubscriptions={showEarningsWithSubscriptions}
                timezone={timezone}
                theme={theme}
            />

            <SextforceMetricsEarningsByIntervalGraph
                subscriber={subscriber}
                metricId={promoCampaignId}
                metricType={promoCampaignData && promoCampaignData.type ? promoCampaignData.type : undefined}
                showEarningsAsGross={showEarningsAsGross}
                showEarningsWithSubscriptions={showEarningsWithSubscriptions}
                timezone={timezone}
                theme={theme}
            />

            <SextforceMetricsSalesByProductGraph
                totalSales={promoCampaignTotalSales}
                totalSalesLoading={promoCampaignSalesLoading}
                showEarningsAsGross={showEarningsAsGross}
                theme={theme}
            />

            <SextforceMetricsUsersIdScatterGraph
                users={
                    promoCampaignSales && !promoCampaignSalesLoading
                        ? promoCampaignSales.map((user: any) => {
                              return {
                                  id: user.userId,
                                  date: user.subscribedAt ? new Date(user.subscribedAt) : undefined,
                                  userName: user.userName,
                              };
                          })
                        : []
                }
                closeUsers={closeUsers}
            />

            <SextforceMetricsCampaignUsers
                metricId={promoCampaignId}
                promoCampaignTotalSales={promoCampaignSales}
                promoCampaignTotalSalesLoading={promoCampaignSalesLoading}
                showEarningsAsGross={showEarningsAsGross}
                showEarningsWithSubscriptions={showEarningsWithSubscriptions}
                metricStartDate={
                    promoCampaignData && promoCampaignData.payload && promoCampaignData.payload.createdAt
                        ? promoCampaignData.payload.createdAt
                        : undefined
                }
                metricType={promoCampaignData && promoCampaignData.type ? promoCampaignData.type : undefined}
                closeUsers={closeUsers}
                timezone={timezone}
                theme={theme}
            />

            {/* <SextforceMetricsSuspiciousUsersList
                metricId={promoCampaignId}
                suspiciousUsers={suspiciousUsers}
                suspiciousUsersLoading={promoCampaignCountersLoading}
                timezone={timezone}
                theme={theme}
            /> */}
        </>
    );
};

export default SextforceMetricsCampaign;
