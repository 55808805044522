import { Grid, Skeleton, Stack, Theme, Typography, useMediaQuery, useTheme } from '@mui/material';
import dinero from 'dinero.js';
import { d2f } from '../../../../utils/common';

// Output the right value based on it being a number, string, or ReactNode
const Value = (props: { value: number | string | React.ReactNode }) => {
    const { value } = props;

    return (
        <>
            {typeof value === 'number' || typeof value === 'string'
                ? `${typeof value === 'number' ? value.toLocaleString() : value}`
                : value
                ? value
                : 0}
        </>
    );
};

type Props = {
    sextforceMetricsCrossReferenceMetricEarningsLoading: boolean;
    totalBefore: number;
    totalAfter: number;
    totalBeforeAndAfter: number;
};

const SextforceMetricsCrossReferenceEarningsAmounts = (props: Props) => {
    const { sextforceMetricsCrossReferenceMetricEarningsLoading, totalBefore, totalAfter, totalBeforeAndAfter } = props;
    const theme: Theme = useTheme();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'));

    return (
        <Grid container spacing={2} alignItems={'center'}>
            <Grid item xs={12} sm="auto">
                <Stack direction="column" spacing={0}>
                    <Typography variant="subtitle1" textAlign={isLargeScreen ? 'left' : 'center'} flexGrow={1}>
                        Total
                    </Typography>
                    <Typography variant="h5" align="right" fontFamily="monospace" textAlign={isLargeScreen ? 'left' : 'center'}>
                        {sextforceMetricsCrossReferenceMetricEarningsLoading ? (
                            <Skeleton width={'100%'} height={30} />
                        ) : (
                            <Value
                                value={dinero({
                                    amount: Math.trunc(d2f(totalBeforeAndAfter) * 100),
                                    currency: 'USD',
                                }).toFormat()}
                            />
                        )}
                    </Typography>
                </Stack>
            </Grid>
            {isLargeScreen && (
                <Grid item xs={12} sm="auto">
                    <Typography variant="h5" align="center">
                        =
                    </Typography>
                </Grid>
            )}
            <Grid item xs={12} sm="auto">
                <Stack direction="row" spacing={2} alignItems="center" justifyContent={'space-between'}>
                    <Stack direction="column" spacing={0} alignItems="flex-start">
                        <Typography variant="subtitle1">Before</Typography>
                        <Typography variant="h5" align="right" fontFamily="monospace">
                            {sextforceMetricsCrossReferenceMetricEarningsLoading ? (
                                <Skeleton width={'100%'} height={30} />
                            ) : (
                                <Value
                                    value={dinero({
                                        amount: Math.trunc(d2f(totalBefore) * 100),
                                        currency: 'USD',
                                    }).toFormat()}
                                />
                            )}
                        </Typography>
                    </Stack>
                    <Typography variant="h5" align="center">
                        +
                    </Typography>
                    <Stack direction="column" spacing={0} alignItems="flex-start">
                        <Typography variant="subtitle1">After</Typography>
                        <Typography variant="h5" align="right" fontFamily="monospace">
                            {sextforceMetricsCrossReferenceMetricEarningsLoading ? (
                                <Skeleton width={'100%'} height={30} />
                            ) : (
                                <Value
                                    value={dinero({
                                        amount: Math.trunc(d2f(totalAfter) * 100),
                                        currency: 'USD',
                                    }).toFormat()}
                                />
                            )}
                        </Typography>
                    </Stack>
                </Stack>
            </Grid>
        </Grid>
    );
};

export default SextforceMetricsCrossReferenceEarningsAmounts;
