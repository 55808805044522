import Dinero from 'dinero.js';
import moment from 'moment-timezone';
import { Typography, Grid, Button, Dialog, DialogActions, DialogContent, DialogTitle, Box, Tooltip, Divider } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import useTheme from '@mui/material/styles/useTheme';
import { Theme } from '@mui/system';
import { green } from '@mui/material/colors';
import { d2f } from '../../../../utils/common';

type SextforceManageSaleProofsDetailsDialogProps = {
    saleProof: any;
    timezone: string;
    agents: any[];
    open: boolean;
    onCancel: () => void;
    onDelete: (id: string) => void;
};

const SextforceManageSaleProofsDetailsDialog = (props: SextforceManageSaleProofsDetailsDialogProps) => {
    const { saleProof, timezone, agents, open, onCancel, onDelete } = props;
    const theme: Theme = useTheme();

    return (
        <Dialog fullWidth={true} maxWidth="md" open={open} scroll="paper" onClose={onCancel}>
            <DialogTitle>Sale Proof Details</DialogTitle>
            <DialogContent dividers={true}>
                <Grid
                    container
                    flexGrow={1}
                    alignItems="center"
                    spacing={0}
                    sx={{ marginTop: theme.spacing(2), marginBottom: theme.spacing(2) }}
                >
                    <Grid item xs={12}>
                        <Typography variant="h6">Transaction Details</Typography>
                    </Grid>
                    <Grid item xs={10}>
                        <Grid container flexGrow={1} alignItems="stretch" spacing={0} sx={{ margin: theme.spacing(1) }}>
                            <Grid item xs={4}>
                                Date:
                            </Grid>
                            <Grid item xs={4} style={{ fontFamily: 'monospace' }}>
                                <span style={{ fontWeight: 'bold', color: theme.palette.primary.main }}>
                                    {saleProof && moment(saleProof.dateTime).tz(timezone).format('L hh:mm a')}
                                </span>
                            </Grid>
                            <Grid item xs={2}>
                                Agent:
                            </Grid>
                            <Grid item xs={2}>
                                {saleProof && agents && agents.find(agent => agent._id === saleProof.agentId) ? (
                                    <>
                                        {agents.find(agent => agent._id === saleProof.agentId).name}{' '}
                                        <Tooltip title={saleProof.agentId}>
                                            <InfoOutlinedIcon sx={{ fontSize: 14 }} />
                                        </Tooltip>
                                    </>
                                ) : (
                                    'Agent Not Found!!!'
                                )}
                            </Grid>
                            <Grid item xs={4}>
                                Agent's Local Date:
                            </Grid>
                            <Grid item xs={4} style={{ fontFamily: 'monospace' }}>
                                <span style={{ fontWeight: 'bold', color: theme.palette.secondary.main }}>
                                    {saleProof && saleProof.timezone && (
                                        <Tooltip title={saleProof.timezone}>
                                            <span style={{ textDecoration: 'dotted underline' }}>
                                                {moment(saleProof.dateTime).tz(saleProof.timezone).format('L hh:mm a')}
                                            </span>
                                        </Tooltip>
                                    )}
                                </span>
                            </Grid>
                            <Grid item xs={2}>
                                Status:
                            </Grid>
                            <Grid item xs={2}>
                                {saleProof && saleProof.assignedTransaction ? (
                                    <div style={{ color: theme.palette.success.main }}>APPROVED</div>
                                ) : (
                                    ''
                                )}
                            </Grid>
                            <Grid item xs={4}>
                                Submitted On:
                            </Grid>
                            <Grid item xs={4} style={{ fontFamily: 'monospace' }}>
                                {saleProof && moment(saleProof.dateCreated).tz(timezone).format('L hh:mm a')}
                            </Grid>
                            <Grid item xs={4}></Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={2}>
                        <Box
                            sx={{
                                backgroundColor: green[100],
                                borderRadius: '4px',
                                padding: theme.spacing(1),
                                textAlign: 'center',
                            }}
                        >
                            Amount
                            <br />
                            <Typography variant="h6" style={{ fontFamily: 'monospace' }}>
                                {saleProof &&
                                    Dinero({
                                        amount: Math.round(d2f(saleProof.amount) * 100),
                                        currency: 'USD',
                                    }).toFormat()}
                            </Typography>
                        </Box>
                    </Grid>
                    {saleProof && saleProof.notes && (
                        <>
                            <Grid item xs={12}>
                                <Typography variant="h6">Notes</Typography>
                            </Grid>
                            <Grid item xs={12} sx={{ marginTop: theme.spacing(1) }}>
                                <div style={{ marginLeft: theme.spacing(1) }}>{saleProof.notes}</div>
                            </Grid>
                        </>
                    )}
                </Grid>

                {saleProof && saleProof.imageUrl && (
                    <>
                        <Grid item xs={12}>
                            <Typography variant="h6">Proof</Typography>
                        </Grid>
                        <Grid item xs={12} sx={{ marginTop: theme.spacing(1) }}>
                            <div style={{ marginLeft: theme.spacing(1) }}>
                                <img src={saleProof.imageUrl} width="100%" alt={saleProof._id} style={{ marginBottom: theme.spacing(2) }} />
                            </div>
                        </Grid>
                    </>
                )}
            </DialogContent>
            <DialogActions>
                <Button
                    color="error"
                    onClick={() => {
                        onDelete(saleProof._id);
                    }}
                >
                    Delete
                </Button>
                <Divider orientation="vertical" flexItem={true} sx={{ marginLeft: theme.spacing(1) }} />
                <Button
                    onClick={() => {
                        onCancel();
                    }}
                >
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default SextforceManageSaleProofsDetailsDialog;
