import useTheme from '@mui/material/styles/useTheme';
import { Theme } from '@mui/system';
import LoadingButton from '@mui/lab/LoadingButton';
import { Grid, Card, CardContent, Typography, Tooltip, useMediaQuery } from '@mui/material';
import { useDialog } from 'muibox';
import SelectTimezone from '../../../forms/helpers/SelectTimezone';

type SextforceAssignTransactionsActionsBarProps = {
    transactions: any;
    timezone: string;
    setTimezone: React.Dispatch<React.SetStateAction<string>>;
    transactionsLoading: boolean;
    selectionModel: any[];
    handleApproveBulkTransaction: (transactionsIds: string[]) => void;
};

const SextforceAssignTransactionsActionsBar = (props: SextforceAssignTransactionsActionsBarProps) => {
    const { transactions, timezone, setTimezone, transactionsLoading, selectionModel, handleApproveBulkTransaction } = props;

    const theme: Theme = useTheme();
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'));
    const dialog = useDialog();

    return (
        <Card sx={{ width: '100%', marginBottom: theme.spacing(1) }}>
            <CardContent style={{ padding: theme.spacing(1) }}>
                <Grid container flexGrow={0} spacing={1} alignItems="center">
                    <Grid item xs={12} md={'auto'}>
                        <Typography variant="body1">Translate transactions time to timezone:</Typography>
                    </Grid>
                    <Grid item xs={12} md="auto">
                        <SelectTimezone timezone={timezone} setTimezone={setTimezone} size="small" fullWidth={true} />
                    </Grid>
                    <Grid item xs={12} md>
                        <Grid container flexGrow={0} spacing={1} alignItems="center" justifyContent="right">
                            <Grid item xs={6} md={'auto'}>
                                <Tooltip title="Bulk approve all the selected yet-to-be approved Sale Proofs">
                                    <span>
                                        <LoadingButton
                                            variant="contained"
                                            color="primary"
                                            fullWidth={!isLargeScreen}
                                            disabled={transactionsLoading || selectionModel.length === 0}
                                            loading={transactionsLoading}
                                            onClick={() => {
                                                dialog
                                                    .confirm({
                                                        title: 'Are you sure?',
                                                        message: 'Bulk approve all the selected sale proofs?',
                                                        ok: { text: 'Yes' },
                                                        cancel: { text: 'Cancel' },
                                                    })
                                                    .then(() => {
                                                        handleApproveBulkTransaction(selectionModel as string[]);
                                                    })
                                                    .catch(() => {});
                                            }}
                                        >
                                            Approve Selected
                                        </LoadingButton>
                                    </span>
                                </Tooltip>
                            </Grid>
                            <Grid item xs={6} md={'auto'}>
                                <Tooltip title="Bulk approve all the yet-to-be approved Sale Proofs that are visible on this page">
                                    <span>
                                        <LoadingButton
                                            variant="contained"
                                            color="secondary"
                                            fullWidth={!isLargeScreen}
                                            disabled={
                                                transactionsLoading ||
                                                !transactions ||
                                                transactions.metadata.total === 0 ||
                                                !transactions.rows.find(
                                                    (transaction: any) =>
                                                        transaction.salesProof &&
                                                        transaction.salesProof.length === 1 &&
                                                        !transaction.salesProof[0].assignedTransaction,
                                                )
                                            }
                                            loading={transactionsLoading}
                                            onClick={() => {
                                                dialog
                                                    .confirm({
                                                        title: 'Are you sure?',
                                                        message: 'Bulk approve all the pending visible sale proofs on this page?',
                                                        ok: { text: 'Yes' },
                                                        cancel: { text: 'Cancel' },
                                                    })
                                                    .then(() => {
                                                        handleApproveBulkTransaction(
                                                            transactions.rows
                                                                .filter((transaction: any) => transaction.salesProof.length === 1)
                                                                .map((transaction: any) => transaction._id),
                                                        );
                                                    })
                                                    .catch(() => {});
                                            }}
                                        >
                                            Approve All Visible
                                        </LoadingButton>
                                    </span>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};

export default SextforceAssignTransactionsActionsBar;
