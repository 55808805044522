import { Grid, Skeleton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { SextforceLiveStreamWithAmount } from '../../../../../hooks/useSextforceLiveStreamAddTippersToList';
import useSextforceLiveStreamAddTippersToListTopTippers from '../../../../../hooks/useSextforceLiveStreamAddTippersToListTopTippers';

const SextforceLiveStreamTopTippersBox = (props: { liveStream: SextforceLiveStreamWithAmount }) => {
    const { liveStream } = props;

    const { data: topTippers, isLoading: isTopTippersLoading } = useSextforceLiveStreamAddTippersToListTopTippers(liveStream.streamId);

    return (
        <Grid
            container
            alignItems="center"
            spacing={0}
            flexGrow={1}
            sx={{
                padding: 2,
            }}
        >
            <Grid item xs={12}>
                <Typography variant="subtitle2" gutterBottom fontWeight="bold">
                    Top 10 Tippers
                </Typography>
            </Grid>

            {isTopTippersLoading && (
                <Grid item xs={12}>
                    <Skeleton variant="rectangular" height={100} />
                </Grid>
            )}

            {!isTopTippersLoading && topTippers && topTippers.length === 0 && (
                <Grid item xs={12}>
                    <Typography variant="subtitle2" color="textSecondary">
                        No tippers found
                    </Typography>
                </Grid>
            )}

            <Grid item xs={12}>
                <Table
                    size="small"
                    sx={{
                        width: '100%',
                        '& td': {
                            borderBottom: 'unset',
                            padding: 0.5,
                        },
                    }}
                >
                    <TableHead>
                        <TableRow>
                            <TableCell width={40}>
                                <Typography variant="subtitle2">#</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="subtitle2">Tipper</Typography>
                            </TableCell>
                            <TableCell width={100} align="right">
                                <Typography variant="subtitle2">This Stream</Typography>
                            </TableCell>
                            <TableCell width={100} align="right">
                                <Typography variant="subtitle2">Total Tips</Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {!isTopTippersLoading &&
                            topTippers &&
                            topTippers.map((tipper, index) => (
                                <TableRow key={index}>
                                    <TableCell>
                                        <Typography variant="subtitle2">{index + 1}</Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Link
                                            to={`https://onlyfans.com/${tipper.user.username}`}
                                            style={{
                                                textDecoration: 'none',
                                            }}
                                        >
                                            {tipper.user.name || tipper.user.username}
                                        </Link>
                                    </TableCell>
                                    <TableCell align="right">
                                        <Typography variant="subtitle2" fontFamily={'monospace'}>
                                            {`$${tipper.streamTotal.toLocaleString(undefined, {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                            })}`}
                                        </Typography>
                                    </TableCell>
                                    <TableCell align="right">
                                        <Typography variant="subtitle2" fontFamily={'monospace'}>
                                            {`$${tipper.tipsTotal.toLocaleString(undefined, {
                                                minimumFractionDigits: 2,
                                                maximumFractionDigits: 2,
                                            })}`}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </Grid>
        </Grid>
    );
};

export default SextforceLiveStreamTopTippersBox;
