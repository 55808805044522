import 'moment/locale/en-gb';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import PageContainer from '../../../components/common/PageContainter';

const SextforceMetrics = () => {
    const params = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        navigate(`/subscribers/${params.userId}/services/sextforce/metrics/account`, { replace: true });
    }, [navigate, params]);

    return (
        <PageContainer>
            <></>
        </PageContainer>
    );
};

export default SextforceMetrics;
