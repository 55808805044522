import { Card, CardContent, Grid, Skeleton, Typography } from '@mui/material';
import { PerformerRankingDataPoint } from './PerformerRanking';

type Props = {
    myRanking: number;
    isPerformerTopLoading: boolean;
    data: PerformerRankingDataPoint[];
};

const PerformerRankingMyRankingCard = (props: Props) => {
    const { myRanking, isPerformerTopLoading, data } = props;

    return (
        <Card sx={{ width: '100%', marginBottom: 2 }}>
            <CardContent>
                <Grid container flexGrow={1} spacing={2} sx={{ marginBottom: 1 }}>
                    <Grid item xs={12}>
                        <Typography variant="h5">My Ranking</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        {isPerformerTopLoading ? (
                            <Skeleton variant="text" width="100%" />
                        ) : (
                            <Typography variant="body1">
                                Your current ranking on OnlyFans is <strong>{myRanking.toFixed(2)}%</strong>.
                            </Typography>
                        )}
                    </Grid>
                    {myRanking > 0 && parseFloat(data[data.length - 1].ranking) >= myRanking && (
                        <Grid item xs={12}>
                            <Typography variant="body2" color="textSecondary">
                                Your ranking group is between{' '}
                                <strong>
                                    $
                                    {data
                                        .find(
                                            d =>
                                                d.ranking ===
                                                myRanking.toLocaleString(undefined, {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2,
                                                }),
                                        )
                                        ?.min.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                </strong>
                                {' - '}
                                <strong>
                                    $
                                    {data
                                        .find(
                                            d =>
                                                d.ranking ===
                                                myRanking.toLocaleString(undefined, {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2,
                                                }),
                                        )
                                        ?.max.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                </strong>
                                .
                            </Typography>
                        </Grid>
                    )}
                </Grid>
            </CardContent>
        </Card>
    );
};

export default PerformerRankingMyRankingCard;
