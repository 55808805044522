import LoadingButton from '@mui/lab/LoadingButton';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import useSextforceMetricsCampaignsCreate from '../../../../../hooks/useSextforceMetricsCampaignsCreate';
import useSubscriber from '../../../../../hooks/useSubscriber';
import TextFieldClipboard from '../../../../common/TextFieldClipboard';

interface Props {
    open: boolean;
    onClose: () => void;
    campaignsRefetch: () => void;
}

const SextforceMetricsCampaignsCreateCampaignDialog = (props: Props) => {
    const { open, onClose, campaignsRefetch } = props;

    const { data: subscriber } = useSubscriber();

    const [name, setName] = useState<string>('');
    const [trackingLink, setTrackingLink] = useState<string>('');

    const { createCampaign, isCreateCampaignLoading } = useSextforceMetricsCampaignsCreate();

    const handleCreateCampaign = async () => {
        if (name.trim().length === 0) {
            return;
        }

        const newCampaign = await createCampaign(name);

        if (newCampaign) {
            setName('');
            setTrackingLink(`https://onlyfans.com/${subscriber.username}/c${newCampaign.payload.campaignCode}`);
        }
    };

    useEffect(() => {
        if (open) {
            setName('');
            setTrackingLink('');
        } else {
            if (trackingLink) {
                campaignsRefetch();
            }
        }
    }, [campaignsRefetch, open]);

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Create New Tracking Link</DialogTitle>
            <DialogContent>
                <Grid container flexGrow={1} spacing={2} alignItems="center">
                    <Grid item xs={12}>
                        <DialogContentText>
                            Creating the tracking link here and not on the OnlyFans website makes the system more accurate because it will
                            be aware of the new tracking link from the start and won't need to wait for the next sync.
                        </DialogContentText>
                    </Grid>
                    {!trackingLink && (
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <TextField
                                    label="Name"
                                    value={name}
                                    disabled={isCreateCampaignLoading}
                                    onChange={e => setName(e.target.value)}
                                    fullWidth
                                    required
                                    autoFocus
                                    error={name.trim().length === 0}
                                    helperText={name.trim().length === 0 ? 'Name is required' : ''}
                                />
                            </FormControl>
                        </Grid>
                    )}
                    {!trackingLink && (
                        <Grid item xs={12}>
                            <DialogContentText>The new link will be shown here after it's created.</DialogContentText>
                        </Grid>
                    )}
                    {trackingLink && (
                        <>
                            <Grid item xs={12}>
                                <DialogContentText>Click below to copy the new tracking link.</DialogContentText>
                            </Grid>
                            <Grid item xs={12}>
                                <TextFieldClipboard value={trackingLink} label="Tracking Link" />
                            </Grid>
                        </>
                    )}
                </Grid>
            </DialogContent>

            <DialogActions
                sx={{
                    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
                }}
            >
                <Button
                    color="secondary"
                    size="medium"
                    onClick={() => {
                        onClose();
                    }}
                >
                    Close
                </Button>
                <LoadingButton
                    variant="contained"
                    color="primary"
                    size="medium"
                    loading={isCreateCampaignLoading}
                    disabled={!subscriber || name.trim().length === 0}
                    onClick={() => {
                        handleCreateCampaign();
                    }}
                >
                    Create
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};

export default SextforceMetricsCampaignsCreateCampaignDialog;
