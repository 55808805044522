import { useDialog } from 'muibox';
import { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { SettingsContext } from '../store/SettingsContext';
import { UserContext } from '../store/UserContext';
import { handleHttpError } from '../utils/common';
import useAxios from './useAxios';
import { OnlyFansMetrics } from './useSextforceMetricsCampaignsOverview';
import useSubscriber from './useSubscriber';

const useSextforceMetricsTrialsCreate = () => {
    const userContext = useContext(UserContext);
    const settingsContext = useContext(SettingsContext);
    const params = useParams();
    const dialog = useDialog();
    const axios = useAxios();
    const { data: subscriber } = useSubscriber();

    const [isCreateTrialLoading, setIsCreateTrialLoading] = useState<boolean>(false);

    const createTrial = async (
        trialLinkName: string,
        subscribeCounts: number,
        expireDays: number,
        subscribeDays: number,
    ): Promise<OnlyFansMetrics | null> => {
        if (userContext.jwtToken && settingsContext.apiKey && 'userId' in params && params.userId && subscriber) {
            const query: string = `${settingsContext.routes.onlyFans.base}${params.userId}/trials`;

            setIsCreateTrialLoading(true);

            const data = await axios
                .post(query, { trialLinkName, subscribeCounts, expireDays, subscribeDays })
                .then(response => {
                    if (response.status === 200) {
                        return response.data;
                    } else {
                        return Promise.reject(response.data);
                    }
                })
                .then(newCampaign => {
                    setIsCreateTrialLoading(false);

                    return newCampaign as OnlyFansMetrics;
                })
                .catch(error => {
                    console.error(error);

                    setIsCreateTrialLoading(false);

                    handleHttpError(error, dialog);

                    return null;
                });

            return data;
        }

        return null;
    };

    return { createTrial, isCreateTrialLoading };
};

export default useSextforceMetricsTrialsCreate;
