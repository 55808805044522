import { faArrowUpRightFromSquare, faClipboard } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton, TextField, Theme } from '@mui/material';
import { Stack, SxProps } from '@mui/system';
import { useState } from 'react';
import { useCopyToClipboard } from '../../hooks/useCopyToClipboard';

interface Props {
    value: string;
    label: string;
    sx?: SxProps<Theme>;
}

const TextFieldClipboard = (props: Props) => {
    const { label, value, sx } = props;
    const [copiedText, copy] = useCopyToClipboard();
    const [copied, setCopied] = useState(false);

    const isMobilePhone = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

    const handleCopy = () => {
        copy(value);

        setCopied(true);

        setTimeout(() => {
            setCopied(false);
        }, 2000);
    };

    return (
        <Stack direction={'row'} spacing={1} alignItems="center">
            <TextField
                id="otpLink"
                type={'text'}
                label={label}
                required
                value={value}
                disabled={true}
                fullWidth
                autoFocus
                InputProps={{
                    endAdornment: (
                        <IconButton
                            size="medium"
                            color={copied ? 'success' : 'primary'}
                            onClick={e => {
                                e.preventDefault();
                                e.stopPropagation();
                                handleCopy();
                            }}
                        >
                            <FontAwesomeIcon icon={faClipboard} />
                        </IconButton>
                    ),
                }}
                sx={{
                    ...(sx || {}),
                }}
            />

            {isMobilePhone && (
                <IconButton size="medium" color="secondary" href={value} target="_blank">
                    <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
                </IconButton>
            )}
        </Stack>
    );
};

export default TextFieldClipboard;
