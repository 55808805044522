import moment from 'moment';
import { useEffect, useState } from 'react';
import { Theme } from '@mui/system';
import useTheme from '@mui/material/styles/useTheme';
import { Alert, Card, CardContent, FormControlLabel, Grid, Radio, Skeleton, Typography } from '@mui/material';
import { CartesianGrid, LabelList, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { followBackParsedDatatotalFollowed, parseFollowBackSessionData } from '../../../../utils/followBack';

const targetUsersName = (t: string): string => {
    if (t === 'followingExpired') {
        return 'Following→Expired';
    }

    if (t === 'fansExpired') {
        return 'Fans→Expired';
    }

    if (t === 'fansActive') {
        return 'Fans→Active';
    }

    if (t === 'lists') {
        return 'Follow Me First';
    }

    return 'Unknown;';
};

const CustomTooltip = ({ active, payload, theme }: any) => {
    if (active && payload && payload.length) {
        return (
            <div>
                <Grid
                    container
                    flexGrow={0}
                    alignItems="center"
                    spacing={1}
                    sx={{
                        backgroundColor: '#fff',
                        borderRadius: 4,
                        border: '1px solid #999',
                        maxWidth: 300,
                    }}
                >
                    <Grid item xs={12}>
                        <span style={{ fontWeight: 'bold' }}>{moment(payload[0].payload.time).format('ddd Do, HH:mm')}</span>
                    </Grid>
                    <Grid item xs={5} sx={{ color: theme.palette.info.main }}>
                        Target Users:
                    </Grid>
                    <Grid item xs={7} textAlign={'right'} sx={{ color: theme.palette.info.main, paddingRight: '8px' }}>
                        {targetUsersName(payload[0].payload.targetUsers)}
                    </Grid>
                    <Grid item xs={5} sx={{ color: theme.palette.info.main }}>
                        Priority:
                    </Grid>
                    <Grid item xs={7} textAlign={'right'} sx={{ color: theme.palette.info.main, paddingRight: '8px' }}>
                        {payload[0].payload.priority ? 'Yes' : 'No'}
                    </Grid>
                    {payload.map((item: any, index: number) => (
                        <Grid item xs={12} key={index}>
                            {item.dataKey === 'followed' && (
                                <Grid container flexGrow={1} alignItems="center">
                                    <Grid item xs={4} sx={{ color: theme.palette.primary.main }}>
                                        Followed:
                                    </Grid>
                                    <Grid item xs={8} textAlign={'right'} sx={{ color: theme.palette.primary.main, paddingRight: '8px' }}>
                                        {item && item.value && typeof item.value === 'number' ? item.value.toLocaleString() : 0}
                                    </Grid>
                                </Grid>
                            )}

                            {item.dataKey === 'following' && (
                                <Grid container flexGrow={1} alignItems="center">
                                    <Grid item xs={4} sx={{ color: theme.palette.secondary.main }}>
                                        Following:
                                    </Grid>
                                    <Grid item xs={8} textAlign={'right'} sx={{ color: theme.palette.secondary.main, paddingRight: '8px' }}>
                                        {item && item.value && typeof item.value === 'number' ? item.value.toLocaleString() : 0}
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                    ))}
                </Grid>
            </div>
        );
    }

    return null;
};

const CustomizedDot = (props: any) => {
    const { cx, cy, payload, theme, active } = props;
    let color: string = 'black';

    switch (payload.targetUsers) {
        case 'followingExpired':
            color = theme.palette.primary.main;
            break;

        case 'fansExpired':
            color = payload.priority ? theme.palette.secondary.main : theme.palette.warning.main;
            break;

        case 'fansActive':
            color = theme.palette.error.main;
            break;

        case 'lists':
            color = theme.palette.success.main;
            break;
    }

    return <circle cx={cx} cy={cy} r={4} stroke={color} strokeWidth={2} fill={active ? color : 'white'} />;
};

type TargetUserRadioButtonProps = {
    targetUsers: string;
    setTargetUsers: (targetUsers: string) => void;
    label: string;
    value: string;
    color: string;
};

const TargetUserRadioButton = (props: TargetUserRadioButtonProps) => {
    const { targetUsers, setTargetUsers, label, value, color } = props;

    return (
        <FormControlLabel
            checked={targetUsers === value}
            control={
                <Radio
                    size="small"
                    sx={{
                        color,
                        '&.Mui-checked': {
                            color,
                        },
                    }}
                />
            }
            label={label}
            onChange={() => setTargetUsers(value)}
        />
    );
};

type FollowBackReportFollowingGraphProps = {
    mainData: any[];
    isFetching: boolean;
};

const FollowBackReportFollowingGraph = (props: FollowBackReportFollowingGraphProps) => {
    const { mainData, isFetching } = props;
    const theme: Theme = useTheme();
    const [targetUsers, setTargetUsers] = useState<string>('all');
    const [data, setData] = useState<any[]>([]);
    const [ticks, setTicks] = useState<number[]>([]);

    useEffect(() => {
        if (mainData) {
            setData(parseFollowBackSessionData(targetUsers, mainData));
        }
    }, [mainData, targetUsers, setData, theme]);

    useEffect(() => {
        if (!data || data.length === 0) {
            return;
        }

        const firstDay = moment(data[0].time).startOf('day');
        const lastDay = moment(data[data.length - 1].time);

        const totalDays: number = lastDay.diff(firstDay, 'days');

        const result: number[] = [firstDay.valueOf()];

        for (let i = 0; i <= totalDays; i += 1) {
            firstDay.add(1, 'days');

            result.push(firstDay.valueOf());
        }

        setTicks(result);
    }, [data]);

    return (
        <Card variant="elevation" sx={{ marginBottom: theme.spacing(4) }}>
            <CardContent>
                <Typography variant="h6" sx={{ marginBottom: theme.spacing(2) }}>
                    Followed vs. Following
                </Typography>

                <Typography variant="body1" sx={{ marginBottom: theme.spacing(2) }}>
                    The graphs below show the Follow-Back results for the searched period. The top graph shows how many users were followed
                    in each session, while the bottow graph shows how many users the account was following at the end of each respective
                    session.
                </Typography>

                <Typography variant="body1" sx={{ marginBottom: theme.spacing(2) }}>
                    Use the selector below to filter out what sessions to show in the graphs. For example, to see only the results for the
                    Fans→Expired list, select Fans→Expired below.
                </Typography>

                <Grid container flexGrow={0} spacing={1} alignItems="center">
                    <Grid item xs="auto">
                        Show:
                    </Grid>
                    <Grid item xs="auto">
                        <TargetUserRadioButton
                            targetUsers={targetUsers}
                            setTargetUsers={setTargetUsers}
                            label="All"
                            value="all"
                            color={theme.palette.grey[800]}
                        />
                    </Grid>
                    <Grid item xs="auto">
                        <TargetUserRadioButton
                            targetUsers={targetUsers}
                            setTargetUsers={setTargetUsers}
                            label="Follow Me First"
                            value="lists"
                            color={theme.palette.success.main}
                        />
                    </Grid>
                    <Grid item xs="auto">
                        <TargetUserRadioButton
                            targetUsers={targetUsers}
                            setTargetUsers={setTargetUsers}
                            label="Following→Expired"
                            value="followingExpired"
                            color={theme.palette.primary.main}
                        />
                    </Grid>
                    <Grid item xs="auto">
                        <TargetUserRadioButton
                            targetUsers={targetUsers}
                            setTargetUsers={setTargetUsers}
                            label="Fans→Expired Priority"
                            value="fansExpiredPriority"
                            color={theme.palette.secondary.main}
                        />
                    </Grid>
                    <Grid item xs="auto">
                        <TargetUserRadioButton
                            targetUsers={targetUsers}
                            setTargetUsers={setTargetUsers}
                            label="Fans→Expired"
                            value="fansExpired"
                            color={theme.palette.warning.main}
                        />
                    </Grid>
                    <Grid item xs="auto">
                        <TargetUserRadioButton
                            targetUsers={targetUsers}
                            setTargetUsers={setTargetUsers}
                            label="Fans→Active"
                            value="fansActive"
                            color={theme.palette.error.main}
                        />
                    </Grid>
                </Grid>

                <Grid container flexGrow={1}>
                    {isFetching ? (
                        <Skeleton />
                    ) : (
                        <>
                            <Grid item xs={12} sx={{ height: 400 }}>
                                <ResponsiveContainer width="100%" height="100%">
                                    <LineChart
                                        data={data || []}
                                        margin={{
                                            top: 20,
                                            right: 0,
                                            left: 30,
                                            bottom: 60,
                                        }}
                                        syncId="followBackSync"
                                    >
                                        <CartesianGrid strokeDasharray="4 4" />
                                        <XAxis
                                            dataKey="time"
                                            type="number"
                                            scale={'time'}
                                            name="Time"
                                            ticks={ticks}
                                            tickFormatter={value => moment(value).format('L').substring(0, 5)}
                                            domain={['auto', 'auto']}
                                            interval={'preserveStartEnd'}
                                            angle={-90}
                                            dy={25}
                                        />
                                        <YAxis
                                            yAxisId="followed"
                                            dataKey={'followed'}
                                            scale={'linear'}
                                            name="Count"
                                            domain={[0, 'dataMax + 100']}
                                            tickFormatter={(value: number) => value.toLocaleString()}
                                            label={{ value: 'Users Followed', angle: -90, position: 'insideLeft', dx: -25 }}
                                        />
                                        <Line
                                            yAxisId="followed"
                                            type="linear"
                                            dataKey="followed"
                                            connectNulls
                                            stroke={theme.palette.primary.main}
                                            dot={<CustomizedDot theme={theme} active={false} />}
                                            activeDot={<CustomizedDot theme={theme} active={true} />}
                                        >
                                            <LabelList
                                                dataKey="followed"
                                                position="top"
                                                offset={10}
                                                formatter={(value: number) => value.toLocaleString()}
                                            />
                                        </Line>
                                        <Tooltip content={<CustomTooltip payload={data} theme={theme} />} />
                                    </LineChart>
                                </ResponsiveContainer>
                            </Grid>

                            <Grid item xs={12} sx={{ paddingBottom: theme.spacing(2) }}>
                                <Alert variant="filled" severity="info">
                                    Total Followed: {data && data.length > 0 ? followBackParsedDatatotalFollowed(data).toLocaleString() : 0}
                                </Alert>
                            </Grid>

                            <Grid item xs={12} sx={{ height: 400 }}>
                                <ResponsiveContainer width="100%" height="100%">
                                    <LineChart
                                        data={data || []}
                                        margin={{
                                            top: 30,
                                            right: 0,
                                            left: 30,
                                            bottom: 60,
                                        }}
                                        syncId="followBackSync"
                                    >
                                        <CartesianGrid strokeDasharray="4 4" />
                                        <XAxis
                                            dataKey="time"
                                            type="number"
                                            scale={'time'}
                                            name="Time"
                                            ticks={ticks}
                                            tickFormatter={value => moment(value).format('L').substring(0, 5)}
                                            domain={['auto', 'auto']}
                                            interval={'preserveStartEnd'}
                                            angle={-90}
                                            dy={25}
                                        />
                                        <YAxis
                                            yAxisId="following"
                                            dataKey={'following'}
                                            scale={'linear'}
                                            name="Count"
                                            domain={['dataMin - 100', 'dataMax + 100']}
                                            tickFormatter={(value: number) => value.toLocaleString()}
                                            label={{ value: 'Total Following', angle: -90, position: 'insideLeft', dx: -25 }}
                                        />
                                        <Line
                                            yAxisId="following"
                                            type="linear"
                                            dataKey="following"
                                            stroke={theme.palette.secondary.main}
                                            dot={<CustomizedDot theme={theme} active={false} />}
                                            activeDot={<CustomizedDot theme={theme} active={true} />}
                                        >
                                            <LabelList
                                                dataKey="following"
                                                position="top"
                                                offset={10}
                                                formatter={(value: number) => value.toLocaleString()}
                                            />
                                        </Line>
                                        <Tooltip content={<CustomTooltip payload={data} theme={theme} />} />
                                    </LineChart>
                                </ResponsiveContainer>
                            </Grid>

                            <Grid item xs={12} sx={{ paddingBottom: theme.spacing(2) }}>
                                <Alert variant="filled" severity="info">
                                    Total Following at End of Period:{' '}
                                    {data && data.length > 0 ? data.at(data.length - 1).following.toLocaleString() : 0}
                                </Alert>
                            </Grid>
                        </>
                    )}
                </Grid>
            </CardContent>
        </Card>
    );
};

export default FollowBackReportFollowingGraph;
