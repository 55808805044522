import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { Typography } from '@mui/material';
import { Theme } from '@mui/system';

type FollowBackServiceDescriptionProps = {
    theme: Theme;
};

const FollowBackServiceDescription = (props: FollowBackServiceDescriptionProps) => {
    const { theme } = props;
    const params = useParams();

    return (
        <>
            <Typography variant="body1" sx={{ marginBottom: theme.spacing(2) }}>
                The new daily limit on OnlyFans works in a strange way - it is different for different accounts, but it is no longer 100
                follow per day! For most, it is 400 follows/day, but for some, it is quite a bit higher.
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: theme.spacing(2) }}>
                To keep pricing fair for larger accounts as well as for smaller accounts, the Follow-Back service is billed on a “metered”
                or a “pay-as-you-go” basis. At £0.012 per follow, the system will keep following as many users as the website will allow it
                or until it exhausts the credit. You can buy as much or as little credit as you like. And when the credit runs out, simply
                “add some more money to the meter”.
            </Typography>

            <Typography variant="body1" sx={{ marginBottom: theme.spacing(2) }}>
                So for example, £50 GBP / $70 USD gives you 4,150 follows. It’s possible the website will let you follow all these in one or
                two days, or in a couple of weeks. We don’t know until we start. But it’s up to you just how many follows it will do in
                total.
            </Typography>

            <Typography variant="body1" sx={{ marginBottom: theme.spacing(2) }}>
                As another example, assuming it does 400 follows a day, that’s about £150 GBP / $210 USD for a whole month worth of follows.
            </Typography>

            <Typography variant="body1" sx={{ marginBottom: theme.spacing(2) }}>
                <strong>
                    By default, the system gives priority to following those who spent money in the past first so the targeting and the
                    likelihood of getting the fans back are very high!
                </strong>
            </Typography>

            <Typography variant="body1" sx={{ marginBottom: theme.spacing(2) }}>
                After a while, it will go through the whole list of expired Fans and expired Following, at which point it will proceed to
                following all the Active Fans that are following you but you are currently not following, as well continue following daily
                those who had expired in the last day. It will simply always stay on top of the expired list, always following the newly
                expired users.
            </Typography>

            <Typography variant="body1" sx={{ marginBottom: theme.spacing(2) }}>
                Every day, you will get an automatic report from the system via Telegram saying how many users it has followed so you always
                know what the system is doing for you.
            </Typography>

            <Typography variant="h6" sx={{ marginBottom: theme.spacing(2) }}>
                <strong>Features</strong>
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: theme.spacing(2) }}>
                <ul>
                    <li style={{ marginBottom: theme.spacing(1) }}>
                        The service does <strong>NOT</strong> follow any paid, restricted or blocked accounts! It simply skips them.
                    </li>
                    <li style={{ marginBottom: theme.spacing(1) }}>
                        Option: Not follow at all any expired fans who have not spent any money in the past. Please use the{' '}
                        <Link
                            to={`/subscribers/${'userId' in params && params.userId}/services/followBack/settings`}
                            style={{ textDecoration: 'none', fontWeight: 'bold', color: theme.palette.primary.main }}
                        >
                            Settings
                        </Link>{' '}
                        page to configure the Follow-Back filter.
                    </li>
                    <li style={{ marginBottom: theme.spacing(1) }}>
                        Tip 1: Create a List called “Do Not Follow” and add any user to this list that you want the system to skip and never
                        follow.
                    </li>
                    <li style={{ marginBottom: theme.spacing(1) }}>
                        Tip 2: Create a List called “Follow Me First” and add any user to this list that you want the system to follow first
                        before anyone else.
                    </li>
                </ul>
            </Typography>
            <Typography variant="body1" sx={{ marginBottom: theme.spacing(2) }}>
                You can see some feedback in my{' '}
                <Link
                    to="https://t.me/+4o6EKLNYq3M0NjRk"
                    target={'_blank'}
                    rel="noreferrer"
                    style={{ textDecoration: 'none', fontWeight: 'bold', color: theme.palette.primary.main }}
                >
                    Telegram review group
                </Link>
                .
            </Typography>
        </>
    );
};

export default FollowBackServiceDescription;
