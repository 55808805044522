import axios from 'axios';
import { useDialog } from 'muibox';
import { useContext } from 'react';
import { QueryClient, useQuery } from 'react-query';
import { SettingsContext } from '../store/SettingsContext';
import { UserContext } from '../store/UserContext';
import { handleHttpError } from '../utils/common';

export interface SubscribersAdmin {
    metadata: { total: number; totalPages: number; currentPage: number };
    rows: any[];
    _config: object;
}

const useSubscribers = (offset?: number, limit?: number) => {
    const userContext = useContext(UserContext);
    const settingsContext = useContext(SettingsContext);
    const dialog = useDialog();
    const queryClient = new QueryClient();

    // Fetch Follow-Back Overview
    const fetchsubscribers = async (offset?: number, limit?: number): Promise<any[] | SubscribersAdmin> => {
        let query: string = `${settingsContext.routes.subscribers.find}`;

        if (offset && typeof offset === 'number' && limit && typeof limit === 'number') {
            query += `?offset=${offset}&limit=${limit}`;
        }

        return axios
            .get(query, {
                headers: {
                    Authorization: userContext.jwtToken,
                    apiKey: settingsContext.apiKey,
                },
            })
            .then(async response => response.data)
            .catch(error => {
                console.error(error);
                handleHttpError(error, dialog);
            });
    };

    const subscribers = useQuery(['subscribers', userContext.jwtToken, offset, limit], () => fetchsubscribers(offset, limit), {
        refetchOnWindowFocus: false,
        // Stale time 24 hours
        staleTime: 60 * 60 * 1000 * 24,
        enabled: userContext.jwtToken && settingsContext.apiKey && userContext.user.username ? true : false,
        placeholderData: () => {
            const data: any[] | undefined = queryClient.getQueryData(['subscribers']);

            return data ? (data as any[]) : [];
        },
    });

    return {
        ...subscribers,
    };
};

export default useSubscribers;
