import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import moment from 'moment-timezone';

const SelectTimezone = (props: {
    timezone: string;
    setTimezone: (timezone: string) => void;
    size?: 'small' | 'medium';
    fullWidth: boolean;
    disabled?: boolean;
}) => (
    <FormControl fullWidth={props.fullWidth}>
        <InputLabel id="select-timezone">Time Zone</InputLabel>
        <Select
            labelId="select-timezone"
            id="timezone"
            value={props.timezone}
            label="Time Zone"
            size={props.size}
            disabled={props.disabled}
            onChange={(e: SelectChangeEvent<string>) => props.setTimezone(e.target.value as string)}
        >
            {moment.tz.names().map(zone => (
                <MenuItem value={zone} key={zone}>
                    {zone}
                </MenuItem>
            ))}
        </Select>
    </FormControl>
);

export default SelectTimezone;
