import { CognitoUserAmplify } from '@aws-amplify/ui';

export const getUserName = (user: CognitoUserAmplify): string => {
    if (!user) {
        return '';
    }

    if (!user.attributes) {
        return '';
    }

    if ('custom:name' in user.attributes) {
        return user.attributes['custom:name'];
    }

    if ('email' in user.attributes) {
        return user.attributes.email;
    }

    return '';
};
