import { Card, CardContent, Grid, Skeleton, Tooltip, Typography } from '@mui/material';
import { Theme } from '@mui/system';
import useTheme from '@mui/material/styles/useTheme';
import { TransactionsTotals } from '../../../../pages/services/sextforce/SextforceReports';
import { Dinero } from 'dinero.js';
import { trendColor } from '../../../../utils/common';
import OverviewPriceCard from './ReportAccountOverviewPriceCard';

const dineroPercentage = (price1: Dinero.Dinero, price2: Dinero.Dinero): number =>
    price2.toUnit() !== 0 ? Math.round((price1.toUnit() / price2.toUnit() + Number.EPSILON) * 10000) / 100 : 0;

type ReportAccountTotalsOverviewProps = {
    mainTotals: TransactionsTotals;
    compareTotals: TransactionsTotals;
    compare: boolean;
    isFetching: boolean;
};

const ReportAccountTotalsOverview = (props: ReportAccountTotalsOverviewProps) => {
    const { mainTotals, compareTotals, compare, isFetching } = props;
    const theme: Theme = useTheme();

    return (
        <Grid container flexGrow={0} justifyContent="center" spacing={1} sx={{ paddingBottom: theme.spacing(2) }}>
            <Grid item xs>
                {OverviewPriceCard('Total Gross', mainTotals.totalAmount, compareTotals.totalAmount, compare, isFetching, theme)}
            </Grid>
            <Grid item xs>
                {OverviewPriceCard('Total OF Fees', mainTotals.totalFee, compareTotals.totalFee, compare, isFetching, theme)}
            </Grid>
            <Grid item xs>
                {OverviewPriceCard('Total Net', mainTotals.totalNet, compareTotals.totalNet, compare, isFetching, theme)}
            </Grid>
            <Grid item xs>
                {OverviewPriceCard('Total VAT', mainTotals.totalVat, compareTotals.totalVat, compare, isFetching, theme)}
            </Grid>
            <Grid item xs>
                <Card variant="elevation">
                    <CardContent>
                        <Typography variant="subtitle1" align="right">
                            Total Commission
                        </Typography>
                        <div style={{ textAlign: 'right' }}>
                            {isFetching ? (
                                <Skeleton />
                            ) : (
                                <>
                                    <Typography
                                        variant="h6"
                                        display="inline"
                                        fontFamily={'monospace'}
                                        sx={{
                                            ...(compare && {
                                                color: trendColor(
                                                    mainTotals.totalCommission,
                                                    compareTotals.totalCommission,
                                                    compare,
                                                    theme,
                                                ),
                                            }),
                                        }}
                                    >
                                        {mainTotals.totalCommission.toFormat()}
                                    </Typography>
                                    <Typography variant="body1" display="inline" fontFamily={'monospace'}>
                                        {' '}
                                        {mainTotals.totalNet.toUnit() !== 0 && (
                                            <Tooltip title="How many percent the total commission is of the total net">
                                                <span style={{ textDecoration: 'dotted underline' }}>
                                                    {dineroPercentage(mainTotals.totalCommission, mainTotals.totalNet)}%
                                                </span>
                                            </Tooltip>
                                        )}
                                    </Typography>
                                </>
                            )}
                        </div>
                        {compare && (
                            <div style={{ textAlign: 'right' }}>
                                {isFetching ? (
                                    <Skeleton />
                                ) : (
                                    <>
                                        <Typography variant="h6" display="inline" fontFamily={'monospace'}>
                                            {compareTotals.totalCommission.toFormat()}
                                        </Typography>
                                        <Typography variant="body1" display="inline" fontFamily={'monospace'}>
                                            {' '}
                                            {compareTotals.totalNet.toUnit() !== 0 && (
                                                <Tooltip title="How many percent the total commission is of the total net">
                                                    <span style={{ textDecoration: 'dotted underline' }}>
                                                        {dineroPercentage(compareTotals.totalCommission, compareTotals.totalNet)}%
                                                    </span>
                                                </Tooltip>
                                            )}
                                        </Typography>
                                    </>
                                )}
                            </div>
                        )}
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
};

export default ReportAccountTotalsOverview;
