import InfoIcon from '@mui/icons-material/Info';
import { Card, CardContent, CircularProgress, Grid, Skeleton, Tooltip, Typography } from '@mui/material';
import { Theme } from '@mui/system';
import moment from 'moment-timezone';
import { targetUsersName } from '../../../../utils/common';

const refreshBackgroundStyle = { animation: 'backgroundRefresh .5s ease forwards' };

type FollowBackOverviewStatusProps = {
    subscriber: any;
    currentSession: any;
    loading: boolean;
    theme: Theme;
};

const FollowBackOverviewStatus = (props: FollowBackOverviewStatusProps) => {
    const { subscriber, currentSession, loading, theme } = props;
    const timezone: string = moment.tz.guess();

    return (
        <>
            <Typography variant="h5" sx={{ marginTop: theme.spacing(4), marginBottom: theme.spacing(2) }}>
                Status
            </Typography>

            {loading ? (
                <Skeleton />
            ) : (
                <>
                    {currentSession && !Array.isArray(currentSession) ? (
                        <Card
                            variant="elevation"
                            sx={{
                                marginBottom: theme.spacing(2),
                            }}
                        >
                            <CardContent>
                                <Typography variant="h6" sx={{ marginBottom: theme.spacing(2) }}>
                                    Session Currently Running <CircularProgress size={14} />
                                </Typography>
                                <Grid container spacing={1}>
                                    <Grid item xs>
                                        <Grid container alignItems={'center'} spacing={1}>
                                            <Grid item xs={6}>
                                                Latest Update
                                            </Grid>
                                            <Grid item xs={6}>
                                                <span key={currentSession.lastStatusUpdate} style={refreshBackgroundStyle}>
                                                    {moment(currentSession.lastStatusUpdate).tz(timezone).format('L LT')}
                                                </span>
                                            </Grid>
                                            <Grid item xs={6}>
                                                Users Group
                                            </Grid>
                                            <Grid item xs={6}>
                                                <span key={currentSession.lastStatusUpdate} style={refreshBackgroundStyle}>
                                                    {currentSession.target === 'lists'
                                                        ? 'Follow Me First'
                                                        : targetUsersName(currentSession.targetUsers, currentSession.filter)}
                                                </span>
                                            </Grid>
                                            <Grid item xs={6}>
                                                Latest Status
                                            </Grid>
                                            <Grid item xs={6}>
                                                <span key={currentSession.lastStatusUpdate} style={refreshBackgroundStyle}>
                                                    {currentSession.status}
                                                </span>
                                            </Grid>
                                            <Grid item xs={6}>
                                                Followed So Far
                                            </Grid>
                                            <Grid item xs={6}>
                                                <span key={currentSession.lastStatusUpdate} style={refreshBackgroundStyle}>
                                                    {currentSession.followBackSessionSummary
                                                        ? currentSession.followBackSessionSummary.totalFollowed
                                                        : 0}
                                                </span>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    ) : (
                        <Card variant="elevation" sx={{ marginBottom: theme.spacing(2) }}>
                            <CardContent>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Typography variant="h6">
                                            Last Session Run{' '}
                                            <Tooltip title={`Time Zone: ${timezone}`}>
                                                <InfoIcon fontSize="small" />
                                            </Tooltip>
                                        </Typography>

                                        {subscriber.followBack && subscriber.followBack.lastFollowBackDate ? (
                                            <>{moment(subscriber.followBack.lastFollowBackDate).tz(timezone).format('L hh:mm a')} </>
                                        ) : (
                                            'Follow-Back has not run yet on this account'
                                        )}
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    )}
                </>
            )}
        </>
    );
};

export default FollowBackOverviewStatus;
