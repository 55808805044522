import React, { useState } from 'react';
import useTheme from '@mui/material/styles/useTheme';
import { Theme } from '@mui/system';
import { Card, CardContent, Checkbox, FormControlLabel, Grid, Tab, Tabs, useMediaQuery } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import SelectTimezone from '../../../forms/helpers/SelectTimezone';
import { ReportSearchBarPropsSeachParams, SearchGroup } from '../SearchGroup';

type ReportSearchBarProps = {
    timezone: string;
    setTimezone: (timezone: string) => void;
    mainSearch: ReportSearchBarPropsSeachParams;
    compareSearch: ReportSearchBarPropsSeachParams;
    enableCompare: boolean;
    setEnableCompare: React.Dispatch<React.SetStateAction<boolean>>;
    isFetchingReport: boolean;
    requestReport: () => void;
};

const ReportSearchBar = (props: ReportSearchBarProps) => {
    const { timezone, setTimezone, mainSearch, compareSearch, enableCompare, setEnableCompare, isFetchingReport, requestReport } = props;
    const theme: Theme = useTheme();
    const largeScreen = useMediaQuery(theme.breakpoints.up('sm'));
    const [tabValue, setTabValue] = useState<number>(0);

    return (
        <Card variant="elevation" sx={{ marginBottom: theme.spacing(4), displayPrint: 'none' }}>
            <CardContent>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <Grid container spacing={2} alignItems={'center'} justifyContent={'center'}>
                        <Grid item xs={12}>
                            <SelectTimezone timezone={timezone} setTimezone={setTimezone} size="small" fullWidth={!largeScreen} />
                        </Grid>

                        <Grid item xs={12}>
                            <Tabs
                                value={tabValue}
                                variant={largeScreen ? 'standard' : 'fullWidth'}
                                onChange={(e, value: number) => setTabValue(value)}
                            >
                                <Tab label="Main Search" />
                                <Tab label="Compare With" />
                            </Tabs>
                        </Grid>

                        <Grid item xs={12} sx={{ display: tabValue === 0 ? 'block' : 'none' }}>
                            <SearchGroup params={mainSearch} enabled={true} theme={theme} />
                        </Grid>

                        <Grid item xs={12} sx={{ display: tabValue === 1 ? 'block' : 'none' }}>
                            <FormControlLabel
                                checked={enableCompare}
                                control={<Checkbox size="small" />}
                                label="Compare With:"
                                onChange={(e, checked) => setEnableCompare(checked)}
                            />
                        </Grid>
                        <Grid item xs={12} sx={{ display: tabValue === 1 ? 'block' : 'none' }}>
                            <SearchGroup params={compareSearch} enabled={enableCompare} theme={theme} />
                        </Grid>

                        <Grid item xs={12}>
                            <LoadingButton
                                variant="contained"
                                disabled={isFetchingReport}
                                loading={isFetchingReport}
                                size={'large'}
                                onClick={requestReport}
                                fullWidth
                            >
                                Show
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </LocalizationProvider>
            </CardContent>
        </Card>
    );
};

export default ReportSearchBar;
