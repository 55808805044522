import {
    Card,
    CardContent,
    Chip,
    FormControl,
    Grid,
    MenuItem,
    Select,
    SelectChangeEvent,
    Skeleton,
    Stack,
    Typography,
} from '@mui/material';
import { LocalizationProvider, MobileDateRangePicker } from '@mui/x-date-pickers-pro';
import { AdapterMoment } from '@mui/x-date-pickers-pro/AdapterMoment';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { OnlyFansSubscriptionsUserType } from '../../../../hooks/useSubscriber';
import useSubscriberUsersCount from '../../../../hooks/useSubscriberUsersCount';

type RecentPeriod = '1d' | '3d' | '7d' | '14d' | '21d' | '1m' | '3m' | '6m' | 'custom';

type Props = {
    usersType: OnlyFansSubscriptionsUserType;
    targetRecentSpendersDateFrom: Date | null;
    setTargetRecentSpendersDateFrom: (date: Date | null) => void;
    targetRecentSpendersDateTo: Date | null;
    setTargetRecentSpendersDateTo: (date: Date | null) => void;
    session?: any;
};

const MessageUsersFormTargetRecentSpenders = (props: Props) => {
    const {
        usersType,
        targetRecentSpendersDateFrom,
        setTargetRecentSpendersDateFrom,
        targetRecentSpendersDateTo,
        setTargetRecentSpendersDateTo,
        session,
    } = props;

    const { data: usersCount, isLoading: usersCountLoading } = useSubscriberUsersCount(
        usersType,
        targetRecentSpendersDateFrom,
        targetRecentSpendersDateTo,
    );
    const [recentPeriod, setRecentPeriod] = useState<RecentPeriod>('1m');

    useEffect(() => {
        if (recentPeriod === 'custom') {
            return; // Don't update dates for custom period
        }

        const now = moment();
        let fromDate: Date | null = null;

        // Set the date range based on the selected period
        switch (recentPeriod) {
            case '1d':
                fromDate = moment().subtract(1, 'days').startOf('day').toDate();
                break;
            case '3d':
                fromDate = moment().subtract(3, 'days').startOf('day').toDate();
                break;
            case '7d':
                fromDate = moment().subtract(7, 'days').startOf('day').toDate();
                break;
            case '14d':
                fromDate = moment().subtract(14, 'days').startOf('day').toDate();
                break;
            case '21d':
                fromDate = moment().subtract(21, 'days').startOf('day').toDate();
                break;
            case '1m':
                fromDate = moment().subtract(1, 'months').startOf('day').toDate();
                break;
            case '3m':
                fromDate = moment().subtract(3, 'months').startOf('day').toDate();
                break;
            case '6m':
                fromDate = moment().subtract(6, 'months').startOf('day').toDate();
                break;
            default:
                break;
        }

        if (fromDate) {
            setTargetRecentSpendersDateFrom(fromDate);
            setTargetRecentSpendersDateTo(now.toDate());
        }

        return () => {
            // Only clear dates if component is unmounting
            if (!fromDate) {
                setTargetRecentSpendersDateFrom(null);
                setTargetRecentSpendersDateTo(null);
            }
        };
    }, [recentPeriod, setTargetRecentSpendersDateFrom, setTargetRecentSpendersDateTo]);

    useEffect(() => {
        if (session?.payload?.targetRecentSpendersDateFrom && session?.payload?.targetRecentSpendersDateTo) {
            setTargetRecentSpendersDateFrom(new Date(session.payload.targetRecentSpendersDateFrom));
            setTargetRecentSpendersDateTo(new Date(session.payload.targetRecentSpendersDateTo));
        } else {
            setRecentPeriod('1m');
        }
    }, [session]);

    return (
        <>
            <Grid container spacing={1} flexGrow={0} alignItems="center" justifyContent="center" sx={{ marginBottom: 1 }}>
                <Grid item xs={12}>
                    <Card variant="elevation">
                        <CardContent>
                            <Grid container spacing={1} flexGrow={0} alignItems="center" justifyContent="center">
                                <Grid item xs={12}>
                                    <Typography variant="h6" sx={{ marginBottom: 1 }}>
                                        Recent Spenders Period
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container spacing={2} flexGrow={0} alignItems="center" justifyContent="center" sx={{ marginBottom: 1 }}>
                                <Grid item xs={12}>
                                    <FormControl fullWidth>
                                        <Select<RecentPeriod>
                                            value={recentPeriod}
                                            onChange={(event: SelectChangeEvent<RecentPeriod>) =>
                                                setRecentPeriod(event.target.value as RecentPeriod)
                                            }
                                            displayEmpty
                                            fullWidth
                                        >
                                            <MenuItem value="1d">Last 1 day</MenuItem>
                                            <MenuItem value="3d">Last 3 days</MenuItem>
                                            <MenuItem value="7d">Last 7 days</MenuItem>
                                            <MenuItem value="14d">Last 14 days</MenuItem>
                                            <MenuItem value="21d">Last 21 days</MenuItem>
                                            <MenuItem value="1m">Last 1 month</MenuItem>
                                            <MenuItem value="3m">Last 3 months</MenuItem>
                                            <MenuItem value="6m">Last 6 months</MenuItem>
                                            <MenuItem value="custom">Custom</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                {recentPeriod === 'custom' && (
                                    <Grid item xs={12}>
                                        <LocalizationProvider
                                            dateAdapter={AdapterMoment}
                                            localeText={{ start: 'From Date', end: 'To Date' }}
                                        >
                                            <MobileDateRangePicker
                                                value={[moment(targetRecentSpendersDateFrom), moment(targetRecentSpendersDateTo)]}
                                                onChange={newValue => {
                                                    if (newValue && newValue.length === 2) {
                                                        setTargetRecentSpendersDateFrom(newValue[0]?.toDate() || null);
                                                        setTargetRecentSpendersDateTo(newValue[1]?.toDate() || null);
                                                    }
                                                }}
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                )}
                                <Grid item xs={12}>
                                    <Stack direction="row" spacing={1} alignItems="center">
                                        <Typography component={'span'} variant="body2" color="textSecondary">
                                            Target users found:
                                        </Typography>
                                        {usersCount && !usersCountLoading && (
                                            <Chip variant="filled" color="success" label={(usersCount.count || 0).toLocaleString()} />
                                        )}
                                        {usersCountLoading && <Skeleton variant="circular" width={40} height={32} />}
                                    </Stack>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </>
    );
};

export default MessageUsersFormTargetRecentSpenders;
